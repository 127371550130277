import React from 'react'
import { connect } from 'react-redux'
import { renderField } from "../Utils/renderField/renderField"
import { Field, reduxForm, formValueSelector } from 'redux-form'
import FileUploader from "../Utils/FileUploader/FileUploader"
import { icons } from "icons";

const validate = values => {
    const errors = {};
    if (!values.old_password)
        errors.old_password = 'Campo requerido'
    if (!values.password)
        errors.password = 'Campo requerido'
    if (!values.rep_password)
        errors.rep_password = 'Campo requerido'
    
    if (values.password && values.rep_password) {
        if (values.password != values.rep_password)
            errors.rep_password = 'Las contraseñas no coinciden'
    }
    return errors;
}

const Form = (props) => {
     // bind
     const { handleSubmit, cambioContrasena, cambioEdicionContra } = props;
    return (
        <div>
            <form>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="row-fields">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="old_password">Contraseña actual *</label>
                                    <Field name="old_password" component={renderField} type="password" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="row-fields">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="password">Contraseña nueva *</label>
                                    <Field name="password" component={renderField} type="password" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="row-fields">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="rep_password">Repetir contraseña *</label>
                                    <Field name="rep_password" component={renderField} type="password" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-row">
                            <button type="button" className="btn btn-tertiary m-1 align-self-center" onClick={() => {
                                cambioEdicionContra();
                            }}>
                                <img src={icons.cancelar} alt=""/> Cancelar
                            </button>
                            <button type="button" className="btn btn-primary m-1 align-self-center" onClick={handleSubmit(values => {
                                cambioContrasena();
                            })}>
                                <em className="fa fa-refresh"></em> Cambiar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
let CambioContraForm = reduxForm({
    form: 'CambioContraForm',
    validate
})(Form)

export default CambioContraForm;