import { connect } from 'react-redux'
import Detalle from './DetalleOrdenOnline'
import { actions } from '../../../../redux/modules/orden_online/orden_online'
import {actions as actionsMostrador} from '../../../../redux/modules/punto_venta/mostrador'
import { actions as actionsVenta } from '../../../../redux/modules/venta/venta'

const ms2p = (state) => {
    const {
        sucursales
    } = state.usuario.me;
    let lista_sucursales = [];
    if (sucursales) {
        sucursales.forEach(sucursal => {
            if (sucursal.id != "empresa") {
                lista_sucursales.push({
                    id: sucursal.id,
                    value: sucursal.id,
                    label: sucursal.nombre
                })
            }
        });
    }

    return {
        ...state.punto_venta,
        caja: state.caja.ultimo_cierre,
        ...state.ordenes_online,
        sucursal: state.usuario.sucursal,
        lista_sucursales,
        utiliza_fel: state.usuario.utiliza_fel,
        conf_empresa: state.usuario,
        loader_cliente: state.punto_venta_mostrador.loader_cliente,
    };

};

const md2p = {
  ...actions,
  ...actionsMostrador,
  agregar: (item, tipo) => (dispatch) => dispatch(actionsVenta.agregar(item, tipo)),
}

export default connect(ms2p, md2p)(Detalle)
