import { icons } from "icons";
import moment from "moment";
import React, { Component } from "react";
import PropTypes from "prop-types";
import ReporteNotaEnvio from "Utils/NotaEnvio";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { PrintContainer, PrintComponent } from "Utils/Print";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import DatePicker from "Utils/DatePicker";

class ReporteDespachos extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {}

    componentDidMount() {
        this.props.listar();
    }

    render() {
        //  State
        const {
            loader,
            data,
            item,
            estado_despacho,
            fecha_inicial,
            fecha_final,
            estado_descarga,
            print_state,
            conf_mostrar_precio_impresion,
        } = this.props;

        // Bind
        const {
            changeEstado,
            descargarListado,
            seleccionImpresion,
        } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.onSortChange = this.props.sortChange;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;

        const accionesAdicionales = (id) => {
            return (
                <span>
                    <img
                        onClick={(e) => {
                            e.preventDefault();
                            seleccionImpresion(id);
                        }}
                        className="action-img"
                        title="Reimprimir nota"
                        src={icons.recibo}
                        alt="Reimprimir nota"
                        style={{ maxWidth: "22px" }}
                    />
                </span>
            );
        };

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                            <div className="negro-dark2" style={{ flex: "1" }}>
                                <h3>
                                    <b>Listado</b>
                                </h3>
                            </div>
                            <div
                                className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                style={{ flex: "3" }}
                            >
                                <div>
                                    <label>Fecha Inicio</label>
                                    <DatePicker
                                        value={fecha_inicial}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            this.props.setFecha(
                                                "Inicial",
                                                date
                                            );
                                        }}
                                    />
                                </div>
                                &nbsp; &nbsp;
                                <div>
                                    <label>Fecha Fin</label>
                                    <DatePicker
                                        value={fecha_final}
                                        minDate={
                                            new Date(
                                                moment(
                                                    fecha_inicial,
                                                    "YYYY-MM-DD"
                                                )
                                            )
                                        }
                                        onChange={(date) => {
                                            this.props.setFecha("Final", date);
                                        }}
                                    />
                                </div>
                                &nbsp; &nbsp;
                                <div>
                                    <label htmlFor="gasto_fijo">Estado</label>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row">
                                            <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <input
                                                        name="gasto_fijo"
                                                        type="radio"
                                                        value="10"
                                                        checked={
                                                            estado_despacho ===
                                                            10
                                                        }
                                                        onChange={() => {
                                                            changeEstado(10);
                                                        }}
                                                    />
                                                    <span />
                                                    Pendientes
                                                </label>
                                            </div>
                                            <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <input
                                                        name="gasto_fijo"
                                                        type="radio"
                                                        value="20"
                                                        checked={
                                                            estado_despacho ===
                                                            20
                                                        }
                                                        onChange={() => {
                                                            changeEstado(20);
                                                        }}
                                                    />
                                                    <span />
                                                    Completadas
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row w-100 mt-10">
                                <button
                                    className="mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                    disabled={estado_descarga}
                                    onClick={() => {
                                        descargarListado();
                                    }}
                                >
                                    <em
                                        className={`fa ${
                                            estado_descarga
                                                ? "fa-spinner fa-pulse"
                                                : "fa-download"
                                        }`}
                                    ></em>
                                    &nbsp;Descargar Listado
                                </button>
                            </div>
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="codigo"
                                    dataFormat={(cell, row) => (
                                        <div>{cell ? cell : ""}</div>
                                    )}
                                    dataSort={true}
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="sucursal_destino__nombre"
                                    dataFormat={(cell, row) => (
                                        <div>{row.sucursal_destino.nombre}</div>
                                    )}
                                    dataSort
                                >
                                    Enviado a
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="usuario__first_name"
                                    dataFormat={(cell, row) => (
                                        <div>{row.usuario.first_name}</div>
                                    )}
                                    dataSort
                                >
                                    Enviado por
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="responsable_transporte"
                                    dataSort
                                >
                                    Transportado por
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="recepcionado_por__first_name"
                                    dataFormat={(cell, row) => (
                                        <div>
                                            {row.recepcionado_por
                                                ? row.recepcionado_por
                                                      .first_name
                                                : ""}
                                        </div>
                                    )}
                                    dataSort
                                >
                                    Recibido por
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="creado"
                                    dataFormat={(cell) => (
                                        <RenderDateTime fecha={cell} />
                                    )}
                                    dataSort
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="detalles"
                                    dataAlign="right"
                                    dataFormat={(cell) => {
                                        const detalles = cell ? cell : []
                                        let recibidos = 0
                                        detalles.forEach(detalle => {
                                            recibidos += detalle.cantidad_recibido ? detalle.cantidad_recibido : 0
                                        })
                                        return Number(recibidos).toFixed(3)
                                    }}
                                >
                                    Recibidos
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="detalles"
                                    dataAlign="right"
                                    dataFormat={(cell) => {
                                        const detalles = cell ? cell : []
                                        let enviados = 0
                                        detalles.forEach(detalle => {
                                            enviados += detalle.cantidad ? detalle.cantidad : 0
                                        })
                                        return Number(enviados).toFixed(3)
                                    }}
                                >
                                    Enviados
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="precio_monto_total"
                                    dataFormat={(cell) => (
                                        <RenderMoneda monto={cell} />
                                    )}
                                >
                                    Monto Total
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    isKey
                                    dataField="id"
                                    dataAlign="center"
                                    dataFormat={activeFormatter({
                                        ver:
                                            "/reporte_de_despachos/visualizacion",
                                        adicional: accionesAdicionales,
                                    })}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>

                    {print_state.flag && <PrintComponent />}
                    <div className="w100 print-only">
                        <PrintContainer name="reporteDespachoReimpresionNota">
                            {item && (
                                <ReporteNotaEnvio
                                    despacho={item}
                                    conf_mostrar_precio_impresion={
                                        conf_mostrar_precio_impresion
                                    }
                                />
                            )}
                        </PrintContainer>
                    </div>
                </div>
            </div>
        );
    }
}

ReporteDespachos.propTypes = {};

export default ReporteDespachos;
