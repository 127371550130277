import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "../Crear/DestinoCrearForm";
import LoadMask from "Utils/LoadMask/LoadMask";

export default class DestinoEditar extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        editar: PropTypes.func.isRequired,
        leer: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render() {
        // state
        const { loader } = this.props;

        // bind
        const { editar } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12">
                    <LoadMask loading={loader} light blur radius>
                        <div className="form-group grid-container">
                            <div className="grid-titulo padding-15">
                                Nuevo Destino
                            </div>
                            <div className="">
                                <Form onSubmit={editar} />
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </div>
        );
    }
}
