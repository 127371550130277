import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { PrintRootContainer } from 'Utils/Print'

// CSS
require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

export default class Root extends Component {
    get content() {
        return (
            <PrintRootContainer>
                <Router>
                    {this.props.routes}
                </Router>
            </PrintRootContainer>
        );
    }

    render() {
        return (
            <Provider store={this.props.store}>
                {this.content}
            </Provider>
        );
    }
}

Root.propTypes = {
    routes: PropTypes.element.isRequired,
    store: PropTypes.object.isRequired,
};
