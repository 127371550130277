import _ from "lodash";
import { api } from "api";
import PropTypes from "prop-types";
import CajaForm from "./CajasCrearForm";
import React, { Component } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";

export const listaCajas = [];

class CajaCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    render() {
        // state

        // bind
        const { crear, loader } = this.props;
        const getSucursales = (search) => {
            return api
                .get("sucursales", { search })
                .catch(() => {})
                .then((data) => {
                    data.results.forEach((item) => {
                        if (!_.find(listaCajas, { id: item.id }))
                            listaCajas.push(item);
                    });
                    return { options: listaCajas };
                });
        };

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={loader} light blur radius>
                        <CajaForm
                            onSubmit={crear}
                            getSucursales={getSucursales}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CajaCrear;
