import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { pago_total, FACTURADO } from "utility/variables";
const logo_tierra_verde = require("../../../../../assets/img/logo-recibos/tierra_verde.png");
import moment from "moment";

export default class Recibo extends Component {
    static propTypes = {
        sucursal: PropTypes.object.isRequired,
        movimiento: PropTypes.object.isRequired,
        footerText: PropTypes.string.isRequired,
        tipo: PropTypes.oneOf(["Ingreso", "Venta"]).isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const {
            sucursal,
            movimiento,
            usuario,
            utiliza_fel,
            conf_habilitar_no_factura,
        } = this.props;
                    const datos_empresa = sucursal.datos_empresa
            ? sucursal.datos_empresa
            : {};
        const datos_fel = movimiento ? movimiento.datos_fel : {};
        const detalles = movimiento ? movimiento.detalles : [];
        const { footerText, tipo } = this.props;
        return (
            <div
                className={`print-shadow font-arial fnt-11 ${
                    tipo == "Venta" ? "vista-recibo-80mm" : ""
                }`}
            >
                {/* IMPRIMIR TIERRA VERDE */}
                {movimiento &&
                movimiento.sucursal &&
                movimiento.sucursal.empresa &&
                movimiento.sucursal.empresa == 72 ? (
                    <React.Fragment>
                        <div className="d-flex flex-row fnt-12">
                            {/* LOGO */}
                            <div className="flex-1 d-flex flex-column justify-content-center align-items-center">
                                <img
                                    style={{ width: "100%" }}
                                    src={logo_tierra_verde}
                                ></img>
                            </div>
                            {/* DATOS EMPRESA */}
                            <div className="flex-2 d-flex flex-column justify-content-center align-items-center">
                                {/* nombre empresa */}
                                {utiliza_fel ? (
                                    <div className="w-100 text-center fnt-16">
                                        <b className="text-uppercase">
                                            {sucursal.nombre_comercial
                                                ? sucursal.nombre_comercial
                                                : datos_empresa.nombre_comercial}
                                        </b>
                                    </div>
                                ) : (
                                    <div className="w-100 text-center fnt-16">
                                        <b className="text-uppercase">
                                            {datos_empresa.nombre}
                                        </b>
                                    </div>
                                )}
                                <div>Sembrando para Vivir</div>
                                {/* direccion */}
                                <div className="w-100 text-center text-uppercase">
                                    {sucursal.direccion}
                                </div>
                                {/* telefono */}
                                {(sucursal.telefono ||
                                    sucursal.telefono != "") && (
                                    <div className="w-100 text-center">
                                        Tel. {sucursal.telefono}
                                    </div>
                                )}
                                <div className="w-100 text-center">
                                    Tel. 56529011
                                </div>

                                {/* url / contacto */}
                                <div className="d-flex justify-content-center align-items-center">
                                    https://compralo.gt/tienda/tierra-verde
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    www.proyectotierraverde.org
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    {
                                        movimiento.sucursal.datos_empresa
                                            .correo_tienda
                                    }
                                </div>
                            </div>
                            {/* FECHA Y NO. ENVIO */}
                            <div className="flex-1 d-flex flex-column justify-content-center align-items-center">
                                {/* fecha */}
                                <div>
                                    {utiliza_fel && datos_fel
                                        ? moment(
                                              datos_fel.fecha_emision
                                          ).format("DD/MM/YYYY")
                                        : moment(movimiento.creado).format(
                                              "DD/MM/YYYY"
                                          )}
                                </div>
                                {/* envio */}
                                <div>ENVIO</div>
                                <div
                                    className="font-weight-bold"
                                    style={{ color: "red" }}
                                >
                                    No.&nbsp;{movimiento.correlativo_envio}
                                </div>
                            </div>
                        </div>
                        {/* DATOS DEL CLIENTE */}
                        <div style={{ border: "1px solid black" }}>
                            <div
                                className="d-flex flex-row fnt-12"
                                style={{ borderBottom: "1px solid black" }}
                            >
                                {/* cliente */}
                                <div
                                    className="flex-1 d-flex"
                                    style={{ margin: "0px 3px 0px 3px" }}
                                >
                                    <b>Cliente</b>&nbsp;&nbsp;
                                    {movimiento.cliente_proveedor &&
                                    movimiento.cliente_proveedor.nombre
                                        ? movimiento.cliente_proveedor.nombre
                                        : movimiento.proveedor &&
                                          movimiento.proveedor.nombre
                                        ? movimiento.proveedor.nombre
                                        : "-----"}
                                </div>
                                {/* telefono */}
                                <div
                                    className="flex-1 d-flex justify-content-center"
                                    style={{ margin: "0px 3px 0px 3px" }}
                                >
                                    <b>NIT</b>&nbsp;&nbsp;
                                    {movimiento.cliente_proveedor &&
                                    movimiento.cliente_proveedor.nit
                                        ? movimiento.cliente_proveedor.nit
                                        : movimiento.proveedor &&
                                          movimiento.proveedor.nit
                                        ? movimiento.proveedor.nit
                                        : "CF"}
                                </div>
                            </div>
                            <div
                                className="d-flex flex-row fnt-12"
                                style={{ margin: "0px 3px 0px 3px" }}
                            >
                                {/* direccion */}
                                <div className="flex-1 d-flex">
                                    <b>Dirección</b>&nbsp;&nbsp;
                                    {movimiento.cliente_proveedor
                                        ? movimiento.cliente_proveedor.direccion
                                            ? movimiento.cliente_proveedor
                                                  .direccion
                                            : "Ciudad"
                                        : "Ciudad"}
                                </div>
                            </div>
                        </div>

                        {/* detalle venta */}
                        <table
                            style={{ marginTop: "5px", width: "100%" }}
                            border={1}
                        >
                            <thead>
                                <tr style={{ borderBottom: "black 1px solid" }}>
                                    <th>Cant</th>
                                    <th>Descripción</th>
                                    <th>P.U.</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detalles.map((detalle) => {
                                    return (
                                        <tr>
                                            <td>
                                                {detalle.fraccion.producto
                                                    .a_granel ? (
                                                    <RenderNumber
                                                        value={detalle.cantidad}
                                                        decimalScale={3}
                                                    />
                                                ) : (
                                                    <RenderNumber
                                                        value={detalle.cantidad}
                                                    />
                                                )}
                                            </td>
                                            <td>
                                                {detalle.producto}

                                                {!detalle.es_vale ? (
                                                    <div className="width100">
                                                        {/* region DESCUENTOS SOBRE CADA PRODUCTO */}
                                                        {detalle.precio_original &&
                                                            detalle.desc_porcentaje >
                                                                0 && (
                                                                <React.Fragment>
                                                                    Descuento (
                                                                    {
                                                                        detalle.desc_porcentaje
                                                                    }
                                                                    %)
                                                                    <span className="mr-1">
                                                                        -{" "}
                                                                        <RenderCurrency
                                                                            value={
                                                                                detalle.desc_monto
                                                                            }
                                                                        />
                                                                    </span>
                                                                </React.Fragment>
                                                            )}
                                                        {/* endregion DESCUENTOS SOBRE CADA PRODUCTO */}
                                                    </div>
                                                ) : (
                                                    <div className="width100">
                                                        <div className="d-flex flex-column">
                                                            <div className="d-flex flex-row">
                                                                Vale de
                                                                descuento{" "}
                                                                {
                                                                    detalle.vale
                                                                        .codigo
                                                                }
                                                            </div>
                                                            <div className="d-flex flex-row">
                                                                <RenderCurrency
                                                                    value={
                                                                        detalle.sub_total *
                                                                        -1
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <RenderCurrency
                                                    value={
                                                        tipo === "Ingreso"
                                                            ? detalle.costo
                                                            : detalle.precio_original &&
                                                              detalle.desc_porcentaje
                                                            ? detalle.precio_original
                                                            : detalle.precio
                                                    }
                                                />
                                            </td>
                                            <td>
                                                {detalle.precio_original &&
                                                detalle.desc_porcentaje ? (
                                                    <React.Fragment>

                                                        {/* <div>
                                                            <RenderCurrency
                                                                value={
                                                                    detalle.cantidad *
                                                                    detalle.precio_original
                                                                }
                                                            />
                                                        </div> */}

                                                        {!detalle.es_vale ? (
                                                            <div>
                                                                {/* region DESCUENTOS SOBRE CADA PRODUCTO */}
                                                                {detalle.precio_original &&
                                                                    detalle.desc_porcentaje >
                                                                        0 && (
                                                                        <span className="ml-auto">
                                                                            {" "}
                                                                            <RenderCurrency
                                                                                value={
                                                                                    ((detalle.cantidad *
                                                                                    detalle.precio_original) -
                                                                                    (detalle.cantidad *
                                                                                    detalle.desc_monto))
                                                                                }
                                                                            />
                                                                        </span>
                                                                    )}
                                                                {/* endregion DESCUENTOS SOBRE CADA PRODUCTO */}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <RenderCurrency
                                                                
                                                                    value={
                                                                        ((detalle.cantidad *
                                                                        detalle.precio_original) +
                                                                        (detalle.sub_total * -1))
                                                                    }
                                                                    className={
                                                                        "ml-auto"
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <div>
                                                            <RenderCurrency
                                                                value={
                                                                    detalle.sub_total
                                                                }
                                                            />
                                                        </div>
                                                        {!detalle.es_vale ? (
                                                            <div>
                                                                {/* region DESCUENTOS SOBRE CADA PRODUCTO */}
                                                                {detalle.precio_original &&
                                                                    detalle.desc_porcentaje >
                                                                        0 && (
                                                                        <span className="ml-auto">
                                                                            -{" "}
                                                                            <RenderCurrency
                                                                                value={
                                                                                    detalle.cantidad *
                                                                                    detalle.desc_monto
                                                                                }
                                                                            />
                                                                        </span>
                                                                    )}
                                                                {/* endregion DESCUENTOS SOBRE CADA PRODUCTO */}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <RenderCurrency
                                                                    value={
                                                                        detalle.sub_total *
                                                                        -1
                                                                    }
                                                                    className={
                                                                        "ml-auto"
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        <table
                            style={{ marginTop: "-1px", width: "100%" }}
                            border={1}
                        >
                            <tfoot>
                                {movimiento.reciduo_redondeo != 0 && (
                                    <tr
                                        style={{ borderTop: "black 1px solid" }}
                                    >
                                        <td
                                            style={{ paddingTop: "5px" }}
                                            colSpan={"3"}
                                        >
                                            <b>REDONDEO</b>
                                        </td>
                                        <td>
                                            <b>
                                                <RenderCurrency
                                                    value={
                                                        movimiento.reciduo_redondeo
                                                            ? movimiento.reciduo_redondeo
                                                            : 0
                                                    }
                                                />
                                            </b>
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <td colSpan={"3"}>
                                        <b>{footerText}</b>
                                    </td>
                                    <td>
                                        <b>
                                            <RenderCurrency
                                                value={
                                                    movimiento.tipo_pago ==
                                                    pago_total
                                                        ? movimiento.total
                                                        : movimiento.total_cobrar_pagar
                                                        ? movimiento.total_cobrar_pagar
                                                        : movimiento.total
                                                }
                                            />
                                        </b>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={"3"}>
                                        <b>VUELTO</b>
                                    </td>
                                    <td>
                                        <b>
                                            <RenderCurrency
                                                value={
                                                    movimiento.vuelto_efectivo !==
                                                        null &&
                                                    movimiento.vuelto_efectivo !==
                                                        0 &&
                                                    movimiento.tipo_pago ==
                                                        pago_total &&
                                                    movimiento.vuelto_efectivo
                                                }
                                            />
                                        </b>
                                    </td>
                                </tr>

                                <tr style={{ borderBottom: "black 1px solid" }}>
                                    <td colSpan={"3"}>
                                        <b>PAGO RECIBIDO</b>
                                    </td>
                                    <td>
                                        <b>
                                            {movimiento.tipo_pago ==
                                            pago_total ? (
                                                <RenderCurrency
                                                    value={
                                                        movimiento.pago_recibido
                                                            ? movimiento.pago_recibido
                                                            : 0
                                                    }
                                                />
                                            ) : (
                                                <RenderCurrency
                                                    value={
                                                        movimiento.pago_recibido
                                                            ? movimiento.pago_recibido
                                                            : 0
                                                    }
                                                />
                                            )}
                                        </b>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        {/* firmas */}
                        <div className="d-flex flex-row justify-content-center aling-items-center mt-4">
                            <div className="flex-2">
                                <span>{"f)."}</span>
                                <div
                                    style={{
                                        width: "90%",
                                        borderBottom: "1px solid black",
                                    }}
                                ></div>
                                <span>{"¿Quien recibe?"}</span>
                            </div>
                            <div className="flex-2">
                                <span>{"f)."}</span>
                                <div
                                    style={{
                                        width: "90%",
                                        borderBottom: "1px solid black",
                                    }}
                                ></div>
                                <span>{"Por proyecto tierra verde"}</span>
                            </div>
                        </div>

                        {/* pago recibido */}
                        {tipo === "Venta" && (
                            <div className="fnt-16">
                                {utiliza_fel ? (
                                    <div className="w-100 mt-3">
                                        {/* DENOMINACION */}
                                        {datos_fel && (
                                            <div className="d-flex flex-row justify-content-center fnt-14 mb-3 text-center font-weight-bold text-uppercase">
                                                {datos_fel.frase_fel}
                                            </div>
                                        )}
                                        {datos_fel &&
                                            (datos_fel.tipo == "FCAM" ||
                                                datos_fel.tipo == "FCAP") && (
                                                <div className="d-flex flex-row fnt-12 mb05">
                                                    <div className="flex1 d-flex flex-column align-items-center">
                                                        <b>COMPLEMENTOS</b>
                                                        {datos_fel.tipo ==
                                                        "FCAM" ? (
                                                            <p className="text-uppercase">
                                                                Abonos de
                                                                Factura
                                                                Cambiaria
                                                            </p>
                                                        ) : (
                                                            <p className="text-uppercase">
                                                                Abonos de
                                                                Factura
                                                                Cambiaria de
                                                                Pequeño
                                                                Contribuyente
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        {datos_fel && (
                                            <div className="w-100">
                                                <div className="d-flex flex-row fnt-12 mb05">
                                                    <div className="flex1 d-flex flex-column align-items-center text-center">
                                                        <b>AUTORIZACIÓN FEL</b>
                                                        {datos_fel.uuid
                                                            ? datos_fel.uuid
                                                            : ""}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row fnt-12">
                                                    <div className="flex1 d-flex flex-column align-items-center">
                                                        <b>
                                                            FECHA CERTIFICACIÓN
                                                        </b>
                                                        <RenderDateTime
                                                            value={
                                                                datos_fel.fechaCertificacion
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* DATOS CERTIFICADOR */}
                                        <div className="d-flex flex-row fnt-12 mt-3">
                                            <div className="flex1 d-flex flex-column align-items-center">
                                                <b>CERTIFICADOR</b>
                                                INFILE, S.A. -- NIT: 12521337
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-column fnt-14 align-items-center text-center mt-5 mb-2">
                                        <span>ESTE DOCUMENTO</span>
                                        <span>NO ES UNA FACTURA</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <div>
                        {/* IMRIMIR RESTO EMPRESAR*/}
                        <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                            {datos_empresa.logo_ticket && tipo == "Venta" && (
                                <img
                                    className="mb-3"
                                    src={datos_empresa.logo_ticket}
                                    width="100"
                                />
                            )}
                            {utiliza_fel ? (
                                <div className="w-100 text-center">
                                    <b className="text-uppercase">
                                        {sucursal.nombre_comercial
                                            ? sucursal.nombre_comercial
                                            : datos_empresa.nombre_comercial}
                                    </b>
                                </div>
                            ) : (
                                <div className="w-100 text-center">
                                    <b className="text-uppercase">
                                        {datos_empresa.nombre}
                                    </b>
                                </div>
                            )}
                            {utiliza_fel ? (
                                <div className="w-100 text-center">
                                    <b className="text-uppercase">
                                        {datos_empresa.nombre_emisor}
                                    </b>
                                </div>
                            ) : (
                                <div className="w-100 text-center">
                                    <b className="text-uppercase">
                                        {sucursal.nombre}
                                    </b>
                                </div>
                            )}

                            <div className="w-100 text-center text-uppercase">
                                {sucursal.direccion}
                            </div>
                            {/* telefonos */}
                            {(sucursal.telefono || sucursal.telefono != "") && (
                                <div className="w-100 text-center">
                                    Tel. {sucursal.telefono}
                                </div>
                            )}

                            {utiliza_fel && (
                                <div className="w-100 text-center">
                                    <div className="w-100 text-center">
                                        Nit. {datos_empresa.nit}
                                    </div>
                                </div>
                            )}
                            {utiliza_fel && datos_fel && (
                                <div className="w-100 text-center mt-3">
                                    {datos_fel.tipo == "FACT" ||
                                    datos_fel.tipo == "FPEQ" ? (
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                FEL - DOCUMENTO TRIBUTARIO
                                                ELECTRÓNICO
                                            </b>
                                        </div>
                                    ) : (
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                FACTURA CAMBIARIA
                                            </b>
                                            <br />
                                            <b className="text-uppercase">
                                                {datos_fel.tipo} - DOCUMENTO
                                                TRIBUTARIO ELECTRÓNICO
                                            </b>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {utiliza_fel && datos_fel && (
                            <div className="w-100">
                                {datos_fel.estado_factura == FACTURADO && (
                                    <div className="w-100">
                                        <div className="d-flex flex-row fnt-12 mb05">
                                            <div className="flex1 d-flex flex-column">
                                                <b>Serie</b>
                                                {datos_fel.serie
                                                    ? datos_fel.serie
                                                    : ""}
                                            </div>
                                            <div className="flex1 d-flex flex-column">
                                                <b>Número</b>
                                                {datos_fel.numero
                                                    ? datos_fel.numero
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="d-flex flex-row fnt-12 mb05">
                            {tipo === "Venta" && (
                                <div className="flex1 d-flex flex-column">
                                    <b>Código de venta</b>
                                    {movimiento.codigo_venta
                                        ? movimiento.codigo_venta
                                        : ""}
                                </div>
                            )}
                            <div className="flex1 d-flex flex-column">
                                <b>Fecha y hora</b>
                                {utiliza_fel && datos_fel ? (
                                    <RenderDateTime
                                        value={datos_fel.fecha_emision}
                                    />
                                ) : (
                                    <RenderDateTime value={movimiento.creado} />
                                )}
                            </div>
                        </div>
                        {movimiento.no_factura && conf_habilitar_no_factura ? (
                            <div className="d-flex flex-column fnt-12 mb">
                                <div className="flex1 d-flex flex-column">
                                    <b>No. Factura</b>
                                    {movimiento.no_factura}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="d-flex flex-row fnt-12 mb05">
                            <div className="flex1 d-flex flex-column">
                                <b>Nombre</b>
                                {movimiento.cliente_proveedor &&
                                movimiento.cliente_proveedor.nombre
                                    ? movimiento.cliente_proveedor.nombre
                                    : movimiento.proveedor &&
                                      movimiento.proveedor.nombre
                                    ? movimiento.proveedor.nombre
                                    : "-----"}
                            </div>
                            <div className="flex1 d-flex flex-column">
                                <b>NIT</b>
                                {movimiento.cliente_proveedor &&
                                movimiento.cliente_proveedor.nit
                                    ? movimiento.cliente_proveedor.nit
                                    : movimiento.proveedor &&
                                      movimiento.proveedor.nit
                                    ? movimiento.proveedor.nit
                                    : "CF"}
                            </div>
                        </div>
                        <div className="d-flex flex-column fnt-12 mb">
                            <div className="flex1 d-flex flex-column mb05">
                                <b>Dirección</b>
                                {movimiento.cliente_proveedor
                                    ? movimiento.cliente_proveedor.direccion
                                        ? movimiento.cliente_proveedor.direccion
                                        : "Ciudad"
                                    : "Ciudad"}
                            </div>
                            {usuario ? (
                                <div className="flex1 d-flex flex-column">
                                    <b>Usuario</b>
                                    {usuario}
                                </div>
                            ) : null}
                        </div>
                        {movimiento.referencia ? (
                            <div className="d-flex flex-column fnt-12 mb">
                                <div className="flex1 d-flex flex-column mb05">
                                    <b>Referencia</b>
                                    {movimiento.referencia}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div
                            className="d-flex flex-row justify-content-between fnt-16"
                            style={{
                                borderTop: "dotted 1px",
                                paddingTop: "10px",
                            }}
                        >
                            <b>ARTÍCULOS</b>
                            <b>SUB TOTAL</b>
                        </div>
                        {detalles &&
                            detalles.map((detalle) => {
                                return (
                                    <div
                                        className="d-flex mb fnt-11"
                                        key={detalle.id}
                                    >
                                        {!detalle.es_vale ? (
                                            <div className="width100">
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex flex-row">
                                                        {`
                                                    ${
                                                        detalle.fraccion
                                                            .codigo_barras
                                                            ? `${detalle.fraccion.codigo_barras} / `
                                                            : ""
                                                    }
                                                    ${detalle.producto} / ${
                                                            detalle.fraccion
                                                                .producto
                                                                .a_granel &&
                                                            detalle.unidad_de_medida
                                                                ? detalle
                                                                      .unidad_de_medida
                                                                      .nombre
                                                                : detalle
                                                                      .fraccion
                                                                      .nombre
                                                        }
                                                    `}
                                                    </div>
                                                    <div className="d-flex flex-row">
                                                        <RenderCurrency
                                                            value={
                                                                tipo ===
                                                                "Ingreso"
                                                                    ? detalle.costo
                                                                    : detalle.precio_original &&
                                                                      detalle.desc_porcentaje
                                                                    ? detalle.precio_original
                                                                    : detalle.precio
                                                            }
                                                            className={"mr-1"}
                                                        />
                                                        <div className="mr-1">
                                                            X
                                                        </div>
                                                        {detalle.fraccion
                                                            .producto
                                                            .a_granel ? (
                                                            <RenderNumber
                                                                value={
                                                                    detalle.cantidad
                                                                }
                                                                decimalScale={3}
                                                                className={
                                                                    "mr-1"
                                                                }
                                                            />
                                                        ) : (
                                                            <RenderNumber
                                                                value={
                                                                    detalle.cantidad
                                                                }
                                                                className={
                                                                    "mr-1"
                                                                }
                                                            />
                                                        )}
                                                        {detalle.precio_original &&
                                                        detalle.desc_porcentaje ? (
                                                            <RenderCurrency
                                                                value={
                                                                    detalle.cantidad *
                                                                    detalle.precio_original
                                                                }
                                                                className={
                                                                    "ml-auto"
                                                                }
                                                            />
                                                        ) : (
                                                            <RenderCurrency
                                                                value={
                                                                    detalle.sub_total
                                                                }
                                                                className={
                                                                    "ml-auto"
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                {/* region DESCUENTOS SOBRE CADA PRODUCTO */}
                                                {detalle.precio_original &&
                                                    detalle.desc_porcentaje >
                                                        0 && (
                                                        <div className="d-flex flex-column">
                                                            <div className="d-flex flex-row pl-20">
                                                                Descuento (
                                                                {
                                                                    detalle.desc_porcentaje
                                                                }
                                                                %)
                                                            </div>
                                                            <div className="d-flex flex-row pl-20">
                                                                <span className="mr-1">
                                                                    -{" "}
                                                                    <RenderCurrency
                                                                        value={
                                                                            detalle.desc_monto
                                                                        }
                                                                    />
                                                                </span>
                                                                <div className="mr-1">
                                                                    X
                                                                </div>
                                                                {detalle
                                                                    .fraccion
                                                                    .producto
                                                                    .a_granel ? (
                                                                    <RenderNumber
                                                                        value={
                                                                            detalle.cantidad
                                                                        }
                                                                        decimalScale={
                                                                            3
                                                                        }
                                                                        className={
                                                                            "mr-1"
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <RenderNumber
                                                                        value={
                                                                            detalle.cantidad
                                                                        }
                                                                        className={
                                                                            "mr-1"
                                                                        }
                                                                    />
                                                                )}
                                                                <span className="ml-auto">
                                                                    -{" "}
                                                                    <RenderCurrency
                                                                        value={
                                                                            detalle.cantidad *
                                                                            detalle.desc_monto
                                                                        }
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                {/* endregion DESCUENTOS SOBRE CADA PRODUCTO */}
                                            </div>
                                        ) : (
                                            <div className="width100">
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex flex-row">
                                                        Vale de descuento{" "}
                                                        {detalle.vale.codigo}
                                                    </div>
                                                    <div className="d-flex flex-row">
                                                        <RenderCurrency
                                                            value={
                                                                detalle.sub_total *
                                                                -1
                                                            }
                                                        />{" "}
                                                        X 1
                                                        <RenderCurrency
                                                            value={
                                                                detalle.sub_total *
                                                                -1
                                                            }
                                                            className={
                                                                "ml-auto"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        {movimiento.reciduo_redondeo != 0 && (
                            <div className="width100 mb">
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <div className="d-flex flex3 flex-row">
                                        Redondeo
                                    </div>
                                    <div className="d-flex flex1 flex-row align-items-center justify-content-end">
                                        <RenderCurrency
                                            value={movimiento.reciduo_redondeo}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className="d-flex flex-row justify-content-between fnt-16"
                            style={{
                                borderTop: "dotted 1px",
                                paddingTop: "15px",
                            }}
                        >
                            <b>{footerText}</b>
                            <b>
                                <RenderCurrency
                                    value={
                                        movimiento.tipo_pago == pago_total
                                            ? movimiento.total
                                            : movimiento.total_cobrar_pagar
                                            ? movimiento.total_cobrar_pagar
                                            : movimiento.total
                                    }
                                    className={"ml-auto"}
                                />
                            </b>
                        </div>
                        {tipo === "Venta" && (
                            <div className="fnt-16">
                                {movimiento.tipo_pago == pago_total ? (
                                    <div className="d-flex flex-row justify-content-between">
                                        <b>PAGO RECIBIDO</b>
                                        <b>
                                            <RenderCurrency
                                                value={movimiento.pago_recibido}
                                                className={"ml-auto"}
                                            />
                                        </b>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-row justify-content-between">
                                        <b>ANTICIPO</b>
                                        <b>
                                            <RenderCurrency
                                                value={movimiento.pago_recibido}
                                                className={"ml-auto"}
                                            />
                                        </b>
                                    </div>
                                )}
                                {movimiento.vuelto_efectivo !== null &&
                                    movimiento.vuelto_efectivo !== 0 &&
                                    movimiento.tipo_pago == pago_total && (
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>VUELTO</b>
                                            <b>
                                                <RenderCurrency
                                                    value={
                                                        movimiento.vuelto_efectivo
                                                    }
                                                    className={"ml-auto"}
                                                />
                                            </b>
                                        </div>
                                    )}
                                {utiliza_fel ? (
                                    <div className="w-100 mt-3">
                                        {/* DENOMINACION */}
                                        {datos_fel && (
                                            <div className="d-flex flex-row justify-content-center fnt-14 mb-3 text-center font-weight-bold text-uppercase">
                                                {datos_fel.frase_fel}
                                            </div>
                                        )}
                                        {datos_fel &&
                                            (datos_fel.tipo == "FCAM" ||
                                                datos_fel.tipo == "FCAP") && (
                                                <div className="d-flex flex-row fnt-12 mb05">
                                                    <div className="flex1 d-flex flex-column align-items-center">
                                                        <b>COMPLEMENTOS</b>
                                                        {datos_fel.tipo ==
                                                        "FCAM" ? (
                                                            <p className="text-uppercase">
                                                                Abonos de
                                                                Factura
                                                                Cambiaria
                                                            </p>
                                                        ) : (
                                                            <p className="text-uppercase">
                                                                Abonos de
                                                                Factura
                                                                Cambiaria de
                                                                Pequeño
                                                                Contribuyente
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        {datos_fel && (
                                            <div className="w-100">
                                                <div className="d-flex flex-row fnt-12 mb05">
                                                    <div className="flex1 d-flex flex-column align-items-center text-center">
                                                        <b>AUTORIZACIÓN FEL</b>
                                                        {datos_fel.uuid
                                                            ? datos_fel.uuid
                                                            : ""}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row fnt-12">
                                                    <div className="flex1 d-flex flex-column align-items-center">
                                                        <b>
                                                            FECHA CERTIFICACIÓN
                                                        </b>
                                                        <RenderDateTime
                                                            value={
                                                                datos_fel.fechaCertificacion
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* DATOS CERTIFICADOR */}
                                        <div className="d-flex flex-row fnt-12 mt-3">
                                            <div className="flex1 d-flex flex-column align-items-center">
                                                <b>CERTIFICADOR</b>
                                                INFILE, S.A. -- NIT: 12521337
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-column fnt-14 align-items-center text-center mt-5 mb-2">
                                        <span>ESTE DOCUMENTO</span>
                                        <span>NO ES UNA FACTURA</span>
                                        <br></br>
                                        {movimiento.usuario.subdominio === "mercamuebles" &&(
                                            <span>
                                                EN  CASO  DE GARANTÍA, MERCAMUEBLES NO CUBRE LOS GASTOS DE TRANSPORTE. 
                                                PARA QUE LA GARANTÍA SEA VÁLIDA, NO DEBE MANIPULAR EL PRODUCTO.
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
