import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/despacho/despacho";
import CrearDespacho from './CrearDespacho';


const ms2p = (state) => {
    return {
        ...state.despacho,
        sucursal: state.usuario.sucursal,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(CrearDespacho);
