import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/caja/caja";
import Cierre from "./Cierre";
import { goBack } from "react-router-redux";
import { tipo_movimientos } from "variables";
import { getCierreCaja } from "../../../../../redux/modules/usuario/usuario.js";

const ms2p = (state) => {
    let data = state.form.CajaCierre ? state.form.CajaCierre.values : {};
    data = data ? data : {};
    let total = 0;
    total = data.b1000 ? total + parseInt(data.b1000) * 1000 : total;
    total = data.b500 ? total + parseInt(data.b500) * 500 : total;
    total = data.b200 ? total + parseInt(data.b200) * 200 : total;
    total = data.b100 ? total + parseInt(data.b100) * 100 : total;
    total = data.b50 ? total + parseInt(data.b50) * 50 : total;
    total = data.b20 ? total + parseInt(data.b20) * 20 : total;
    total = data.b10 ? total + parseInt(data.b10) * 10 : total;
    total = data.b5 ? total + parseInt(data.b5) * 5 : total;
    total = data.b1 ? total + parseInt(data.b1) : total;
    total = data.m2000 ? total + parseInt(data.m2000) * 20 : total;
    total = data.m1000 ? total + parseInt(data.m1000) * 10 : total;
    total = data.m500 ? total + parseInt(data.m500) * 5 : total;
    total = data.m200 ? total + parseInt(data.m200) * 2 : total;
    total = data.m100 ? total + parseInt(data.m100) : total;
    total = data.m50 ? total + parseInt(data.m50) * 0.5 : total;
    total = data.m25 ? total + parseInt(data.m25) * 0.25 : total;
    total = data.m20 ? total + parseInt(data.m20) * 0.2 : total;
    total = data.m10 ? total + parseInt(data.m10) * 0.1 : total;
    total = data.m5 ? total + parseInt(data.m5) * 0.05 : total;
    total = data.m1 ? total + parseInt(data.m1) * 0.01 : total;
    total = parseFloat(total.toFixed(2));
    const config = state.configuracion.config;
    return {
        ...state.caja,
        total,
        config,
        pais: state.usuario.me.pais,
        sucursal: state.usuario.sucursal,
    };
};

const md2p = {
    ...actions,
    regresar: () => (dispatch) => {
        dispatch(goBack());
    },
    getCierreCaja,
};

export default connect(ms2p, md2p)(Cierre);
