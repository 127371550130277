import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "./DestinoCrearForm";
import LoadMask from "Utils/LoadMask/LoadMask";

export default class DestinoCrear extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        crear: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        // state
        const { loader } = this.props;

        // bind
        const { crear } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12">
                    <LoadMask loading={loader} light blur radius>
                        <div className="form-group grid-container">
                            <div className="grid-titulo padding-15">
                                Nuevo Destino
                            </div>
                            <div className="">
                                <Form onSubmit={crear} />
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </div>
        );
    }
}
