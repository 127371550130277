import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";
import { RenderDateTime, RenderMoneda } from "Utils/renderField/renderTableField";
import { icons } from "icons";

class DetalleGasto extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };
    constructor(props){
        super(props);
    }
    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render () {
        const { loader, item } = this.props;
        const usuario = item.usuario ? item.usuario : {};

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container pb-1">
                        <LoadMask loading={loader} light blur radius>
                            <div className="form-group">
                                <div className="grid-titulo padding-15">
                                    Detalles del gasto
                                </div>
                                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1 mt">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label className="label-view">Responsable</label>
                                            <div className="input-view">
                                                {usuario.first_name}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label className="label-view">Fecha y hora del registro</label>
                                            <div className="input-view">
                                                <RenderDateTime fecha={item.creado}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <label className="label-view">Concepto</label>
                                            <div className="input-view">
                                                {item.concepto}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label className="label-view">No. Documento</label>
                                            <div className="input-view">
                                                {item.num_documento}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label className="label-view">Tipo de gasto</label>
                                            <div className="input-view">
                                                {`${item.gasto_fijo ? "Gasto Fijo" : "Gasto Variable"}`}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label className="label-view">Monto</label>
                                            <div className="input-view">
                                                <RenderMoneda monto={item.total}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LoadMask>
                        <div className="d-flex justify-content-center flex-row">
                            <Link className="btn btn-tertiary align-self-center m-1" to="/gastos"><i className="fa fa-arrow-left" aria-hidden="true" /> Regresar</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetalleGasto;
