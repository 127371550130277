import React, { Component } from 'react';
// Draft
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
export default class Factura extends Component {
    render() {
        // state
        const {formato, className} = this.props;
        // bind
        const {marcarVisto} = this.props;

        // tranformacion de html
        const contentBlock = htmlToDraft(formato);
        let editorState  = EditorState.createEmpty();
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);
        }
        return(
            <div className={`${ className ? className : ''}`}>
                <Editor
                    toolbarHidden
                    editorState={editorState}
                    readOnly={true}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                />
                <div className="mt-1 text-center">
                    <button type="button" className="btn btn-primary" onClick={() => {
                        marcarVisto();
                    }}>
                        <em className="fa fa-check mr"></em>
                        <span>Aceptar</span>
                    </button>
                </div>
            </div>
        )
    }
}
