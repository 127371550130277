import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderTextArea } from "Utils/renderField/renderField";

// VALIDACIONES CUSTOMS
const validate = (values, props) => {
    const errors = {}
    if (!values.nota)
        errors.nota = "Campo requerido"
    return errors;
}

const Form = (props) => {
    const { handleSubmit, close, loader } = props;

    return (
        <form onSubmit={handleSubmit} className="col-sm-12 pt-2">
            <div className="form-group">
                <div className="row">
                    <div className="col-12 form-group">
                        <label htmlFor="tipo">Tipo</label>
                        <div className="d-flex">
                        <div className="radio c-radio c-radio-nofont d-flex ml-3">
                            <label className="negro-dark2 font-weight-normal">
                                <Field name="tipo" component="input" type="radio" value="10" />
                                <span />
                                Privado
                            </label>
                        </div>
                        <div className="radio c-radio c-radio-nofont d-flex ml-3">
                            <label className="negro-dark2 font-weight-normal">
                                <Field name="tipo" component="input" type="radio" value="20" />
                                <span />
                                Para el cliente
                            </label>
                        </div>
                    </div>
                    </div>
                    <div className="col-12 form-group">
                        <label htmlFor="nota">Escribe la Nota</label>
                        <Field name="nota" label="Escribe aquí" component={renderTextArea} type="text" className="form-control" autoFocus={true}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center flex-row">
                    <button type="button" className="btn btn-tertiary m-1 align-self-center" onClick={(e) => {
                        e.stopPropagation();
                        close({});
                    }}>Cancelar</button>
                    <button type="submit" className="btn btn-primary m-1 align-self-center" disabled={loader}>Confirmar</button>
                </div>
            </div>
        </form>
    )
}

const NotaOrdenOnlineForm = reduxForm({
    // a unique name for the form
    form: 'NotaOrdenOnlineForm',
    validate
})(Form)

export default NotaOrdenOnlineForm;