import PropTypes from "prop-types";
import Barcode from "react-barcode";
import React, { Component } from "react";
import {
    RenderDateTime,
    RenderDate,
    RenderNumber,
    RenderCurrency,
} from "Utils/renderField/renderReadField";

export default class TicketCotizacion extends Component {
    static propTypes = {
        cotizacion: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const { cotizacion } = this.props;
        const movimiento = cotizacion ? cotizacion.movimiento : null;
        const sucursal = movimiento.sucursal;
        const datos_empresa = sucursal.datos_empresa
            ? sucursal.datos_empresa
            : {};
        const detalles = movimiento ? movimiento.detalles : [];
        const usuario = movimiento.usuario ? movimiento.usuario.first_name : "";

        return (
            <div className="print-shadow vista-recibo-80mm">
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{datos_empresa.nombre}</b>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.nombre}</b>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.direccion}</b>
                    </div>
                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                Tel. {sucursal.telefono}
                            </b>
                        </div>
                    )}
                    <div className="w-100 mt-2 d-flex align-items-center justify-content-center">
                        <div className="border border-dark font-weight-bold text-center pt-2 pr-5 pb-2 pl-5">
                            COTIZACIÓN
                        </div>
                    </div>
                    <div className="w-100 mt-2 d-flex align-items-center justify-content-center">
                        <Barcode
                            width={1}
                            height={65}
                            value={cotizacion.codigo}
                            marginBottom={4}
                        />
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-column">
                        <b>Nombre</b>
                        {cotizacion.nombre ? cotizacion.nombre : "-----"}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>NIT</b>
                        {cotizacion.nit ? cotizacion.nit : "-----"}
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-column">
                        <b>Fecha y hora</b>
                        <RenderDateTime value={cotizacion.creado} />
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Fecha que expira</b>
                        <RenderDate value={cotizacion.fecha_expiracion} />
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb">
                    <div className="flex1 d-flex flex-column">
                        <b>Código de cotización</b>
                        {cotizacion.codigo ? cotizacion.codigo : ""}
                    </div>
                    {usuario ? (
                        <div className="flex1 d-flex flex-column">
                            <b>Usuario</b>
                            {usuario}
                        </div>
                    ) : null}
                </div>

                <div
                    className="d-flex flex-row justify-content-between fnt-16"
                    style={{ borderTop: "dotted 1px", paddingTop: "10px" }}
                >
                    <b>ARTÍCULOS</b>
                    <b>SUB TOTAL</b>
                </div>
                {detalles.map((detalle) => {
                    return (
                        <div className="d-flex mb fnt-11" key={detalle.id}>
                            {!detalle.es_vale && (
                                <div className="width100">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row">
                                            {`
                                                    ${
                                                        detalle.fraccion
                                                            .codigo_barras
                                                            ? `${detalle.fraccion.codigo_barras} / `
                                                            : ""
                                                    }
                                                    ${detalle.producto} / ${
                                                detalle.fraccion.producto
                                                    .a_granel
                                                    ? detalle.unidad_de_medida
                                                          .nombre
                                                    : detalle.fraccion.nombre
                                            }
                                                    `}
                                        </div>
                                        <div className="d-flex flex-row">
                                            <RenderCurrency
                                                value={
                                                    detalle.precio_original &&
                                                    detalle.desc_porcentaje
                                                        ? detalle.precio_original
                                                        : detalle.precio
                                                }
                                                className={"mr-1"}
                                            />
                                            <div className="mr-1">X</div>
                                            {detalle.fraccion.producto
                                                .a_granel ? (
                                                <RenderNumber
                                                    value={detalle.cantidad}
                                                    decimalScale={3}
                                                    className={"mr-1"}
                                                />
                                            ) : (
                                                <RenderNumber
                                                    value={detalle.cantidad}
                                                    className={"mr-1"}
                                                />
                                            )}
                                            {detalle.precio_original &&
                                            detalle.desc_porcentaje ? (
                                                <RenderCurrency
                                                    value={
                                                        detalle.cantidad *
                                                        detalle.precio_original
                                                    }
                                                    className={"ml-auto"}
                                                />
                                            ) : (
                                                <RenderCurrency
                                                    value={detalle.sub_total}
                                                    className={"ml-auto"}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {/* region DESCUENTOS SOBRE CADA PRODUCTO */}
                                    {detalle.precio_original &&
                                        detalle.desc_porcentaje > 0 && (
                                            <div className="d-flex flex-column">
                                                <div className="d-flex flex-row pl-20">
                                                    Descuento (
                                                    {detalle.desc_porcentaje}%)
                                                </div>
                                                <div className="d-flex flex-row pl-20">
                                                    <span className="mr-1">
                                                        -{" "}
                                                        <RenderCurrency
                                                            value={
                                                                detalle.desc_monto
                                                            }
                                                        />
                                                    </span>
                                                    <div className="mr-1">
                                                        X
                                                    </div>
                                                    {detalle.fraccion.producto
                                                        .a_granel ? (
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                            decimalScale={3}
                                                            className={"mr-1"}
                                                        />
                                                    ) : (
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                            className={"mr-1"}
                                                        />
                                                    )}
                                                    <span className="ml-auto">
                                                        -{" "}
                                                        <RenderCurrency
                                                            value={
                                                                detalle.cantidad *
                                                                detalle.desc_monto
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    {/* endregion DESCUENTOS SOBRE CADA PRODUCTO */}
                                </div>
                            )}
                        </div>
                    );
                })}

                <div
                    className="d-flex flex-row justify-content-between fnt-16"
                    style={{ borderTop: "dotted 1px", paddingTop: "15px" }}
                >
                    <b>TOTAL</b>
                    <b>
                        <RenderCurrency
                            value={movimiento.total}
                            className={"ml-auto"}
                        />
                    </b>
                </div>
            </div>
        );
    }
}
