import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CrearDespachoForm from "./CrearDespachoForm";
import { SeleccionSucursal } from "Utils/SeleccionSucursal";
class CrearDespacho extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ver_buscador: false,
        }

    }

    componentWillMount() {
        this.props.listarProductos(this.props.page_productos);
        this.props.getSucursalOrigen()
    }

    cambioBuscador = () => {
        this.setState({ver_buscador: !this.state.ver_buscador});
    }

    render() {
        // state
        const {crear, sucursal} = this.props
        const { ver_buscador } = this.state;
        if (sucursal === undefined) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo padding-5">
                                Para realizar un despacho primero elija una sucursal
                            </div>
                            <div className="padding-15">
                                <SeleccionSucursal />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <CrearDespachoForm
                    {...this.props}
                    onSubmit={crear}
                    ver_buscador={ver_buscador}
                    cambioBuscador={this.cambioBuscador}/>
            </div>
        );
    }
}

CrearDespacho.propTypes = {

};

export default CrearDespacho;
