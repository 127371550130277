import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ItemDetalleVenta from "Utils/Venta/ItemDetalleVenta";
import ItemEditableDetalleVenta from "Utils/Venta/ItemEditableDetalleVenta";
import { RenderDateTime, RenderDate } from "Utils/renderField/renderReadField";
export class DetalleVentaCotizacion extends Component {
    static propTypes = {};

    render() {
        // State
        const {
            seleccionados,
            venta_permitir_descuento,
            pais,
            mostrar_alerta_inventario,
            texto_sin_detalles,
            conf_porcentaje_precio,
        } = this.props;
        // State Cotizacion
        const { cotizacion_cargada } = this.props;
        // Bind
        const {
            quitar,
            ingresoCantidad,
            desplegarItem,
            restar,
            sumar,
            cambiarPrecio,
            cambiarDescuento,
            cambiarPrecioFinal,
        } = this.props;

        const tiene_nuevos_prod = _.find(seleccionados, function (i) {
            return i.detalle_id == undefined;
        });
        return (
            <div className="grid-container mt0 border-radius-buttom-squa">
                <div className="w-100 p-3 d-flex flex-column align-items-center justify-content-center">
                    <div className="w-100 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                        <div className="w-100 flex-1">
                            <label className="pr-2" htmlFor="">
                                Nombre:
                            </label>
                            {cotizacion_cargada.nombre}
                        </div>
                        <div className="w-100 flex-1">
                            <label className="pr-2" htmlFor="">
                                Código:
                            </label>
                            {cotizacion_cargada.codigo}
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                        <div className="w-100 flex-1">
                            <label className="pr-2" htmlFor="">
                                Fecha:
                            </label>
                            <RenderDateTime value={cotizacion_cargada.creado} />
                        </div>
                        <div className="w-100 flex-1">
                            <label className="pr-2" htmlFor="">
                                Fecha que expira:
                            </label>
                            <RenderDate
                                value={cotizacion_cargada.fecha_expiracion}
                            />
                        </div>
                    </div>
                </div>

                <div className="r-encajar">
                    <div className="w-100 font-weight-bold text-white pl-3 pr-2 pt-1 pb-1 bg-naranja">
                        COTIZACION CARGADA
                    </div>
                    {seleccionados.length === 0 ? (
                        texto_sin_detalles ? (
                            <h4 className="negro mr-2 ml-2">
                                {texto_sin_detalles}
                            </h4>
                        ) : (
                            <h4 className="negro mr-2 ml-2">
                                Agregue un producto a la venta
                            </h4>
                        )
                    ) : (
                        <div className="w-100 margin-left-5-naranja pt-2">
                            {seleccionados.map((item, index) => {
                                if (item.detalle_id) {
                                    return (
                                        <ItemDetalleVenta
                                            key={index}
                                            index={index}
                                            item={item}
                                            mostrar_alerta_inventario={
                                                mostrar_alerta_inventario
                                            }
                                            quitar={quitar}
                                        />
                                    );
                                }
                            })}
                        </div>
                    )}
                    {tiene_nuevos_prod && (
                        <div className="w-100">
                            <div className="w-100 font-weight-bold text-white pl-3 pr-2 pt-1 pb-1 bg-container-new-prod-cotizacion">
                                PRODUCTOS AGREGADOS A LA COTIZACIÓN
                            </div>
                            {seleccionados.length === 0 ? (
                                texto_sin_detalles ? (
                                    <h4 className="negro mr-2 ml-2">
                                        {texto_sin_detalles}
                                    </h4>
                                ) : (
                                    <h4 className="negro mr-2 ml-2">
                                        Agregue un producto a la venta
                                    </h4>
                                )
                            ) : (
                                <div className="w-100 margin-left-5-container-new-prod-cotizacion pt-2">
                                    {seleccionados.map((item, index) => {
                                        if (item.detalle_id == undefined) {
                                            return (
                                                <ItemEditableDetalleVenta
                                                    key={index}
                                                    index={index}
                                                    item={item}
                                                    mostrar_alerta_inventario={
                                                        mostrar_alerta_inventario
                                                    }
                                                    venta_permitir_descuento={
                                                        venta_permitir_descuento
                                                    }
                                                    pais={pais}
                                                    sumar={sumar}
                                                    restar={restar}
                                                    quitar={quitar}
                                                    conf_porcentaje_precio={
                                                        conf_porcentaje_precio
                                                    }
                                                    desplegarItem={
                                                        desplegarItem
                                                    }
                                                    ingresoCantidad={
                                                        ingresoCantidad
                                                    }
                                                    cambiarDescuento={
                                                        cambiarDescuento
                                                    }
                                                    cambiarPrecio={
                                                        cambiarPrecio
                                                    }
                                                    cambiarPrecioFinal={
                                                        cambiarPrecioFinal
                                                    }
                                                />
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
