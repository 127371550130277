import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/caja/caja";
import CajasCrear from './CajasCrear';


const ms2p = (state) => {
  return {
    ...state.caja,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CajasCrear);
