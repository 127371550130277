import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form';
import { renderField } from "Utils/renderField/renderField";
import { icons } from "icons";

const Form = props => {
    const { handleSubmit, close, tipo } = props;
    return (
        <form onSubmit={handleSubmit} className="col-sm-12 pt-2">
            <div className="form-group">
                <div className="padding-15 p-sm-0 pt-sm-1">
                    <div className="d-flex justify-content-around flex-column">
                        <div className="d-flex flex-column form-group">
                            <label className="d-lg-none" htmlFor="nombre">Ingresar vale</label>
                            <Field name="codigo" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    <br/>
                    <div className="d-flex justify-content-center flex-row">
                        <button type="button" className="btn btn-secondary m-1 align-self-center" onClick={close}><img src={icons.cancelar} alt=""/>Cancelar</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center"><img src={icons.mas} alt=""/>Agregar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

const ValeForm = reduxForm({
    // a unique name for the form
    form: 'ValeForm',
    initialValues: { tipo_retiro: "100" },
    validate: data => {
        return validate(data, {
            codigo: validators.exists()("Campo requerido"),
        })
    }
})(Form);

export default ValeForm
