import _ from 'lodash';
import { icons } from "icons";
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { RenderDateTime, RenderMoneda } from "Utils/renderField/renderTableField";

class ListadoCotizacion extends Component {
    static propTypes = {
    }

    componentWillMount() {
        this.props.listar();
    }

    render() {
        // State
        const { data, search, page, loader } = this.props;
        // Bind
        const { listar, buscar, cargarCotizacionPuntoVenta, eliminarCotizacion, escanearCodigoBarras } = this.props;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        // options.onSortChange = sortChange;

        const formatoAcciones = (cell, row) => {

            return(
                <div>
                    {
                        row.movimiento && (
                            <div className="d-flex flex-row align-items-center">
                                {/* <img onClick={() => {
                                    
                                }} style={{maxWidth: "20px"}} className="action-img" title="Leer código de barras" src={icons.codigo_barras} alt="codigo barras"/> */}
                                <img onClick={() => {
                                    cargarCotizacionPuntoVenta(row);
                                }} style={{maxWidth: "20px"}} className="action-img" title="Cargar cotización" src={icons.cargar} alt="cargar"/>
                                <img onClick={() => {
                                    eliminarCotizacion(cell)
                                }} style={{maxWidth: "20px"}} className="action-img" title="Eliminar cotización" src={icons.eliminar} alt="eliminar"/>
                            </div>
                        )
                    }
                </div>
            );
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar titulo={"Cotizaciones pendientes"} buscar={buscar} search={search} agregarPrimer={escanearCodigoBarras} autoFocus={true} />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote pagination hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="nombre"
                                    dataFormat={(cell, row) => <span>{cell}</span>}
                                >Nombre/Cliente</TableHeaderColumn>
                                <TableHeaderColumn tdStyle={BreakLine} thStyle={BreakLine} dataField='creado' dataFormat={(cell) => <RenderDateTime fecha={cell} />} dataSort >Fecha</TableHeaderColumn>
                                <TableHeaderColumn tdStyle={BreakLine} thStyle={BreakLine} dataField='fecha_expiracion' dataFormat={(cell) => <RenderDateTime fecha={cell} />} dataSort >Fecha expiración</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="codigo"
                                    dataFormat={(cell, row) => <span>{cell}</span>}
                                >Código</TableHeaderColumn>
                                <TableHeaderColumn tdStyle={BreakLine} thStyle={BreakLine} dataField='movimiento_total' dataFormat={(cell, row) => <RenderMoneda monto={row.movimiento.total} />} dataSort dataAlign="right">Total</TableHeaderColumn>
                                <TableHeaderColumn dataField='id' isKey dataAlign="left"
                                    dataFormat={formatoAcciones}>Acciones</TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}

export default ListadoCotizacion;
