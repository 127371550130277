import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import {ToastStore} from 'react-toasts';
import { initialize as initializeForm } from "redux-form"
import Swal from "sweetalert2";
import moment from 'moment'
import { api } from "api";

const endpoint = "gastos";
const cuentasPagarEndpoint = "cuenta_pagar";

// ------------------------------------
// Constants
// ------------------------------------

export const DATA = "GASTOS_DATA";
export const ITEM = "GASTOS_ITEM";
export const SORT = "GASTOS_SORT";
export const PAGE = "GASTOS_PAGE";
export const SET_LOADER_RETIROS = "GASTOS_SET_LOADER_RETIROS";
export const SET_ORDERING_RETIROS = "GASTOS_SET_ORDERING_RETIROS";
export const SET_MOVIMIENTOS_RETIROS = "GASTOS_SET_MOVIMIENTOS_RETIROS";
export const SET_PAGE_RETIROS = "GASTOS_SET_PAGE_RETIROS";
export const SET_FECHA_I_RETIROS = "GASTOS_SET_FECHA_I_RETIROS";
export const SET_MOVIMIENTO_CAJA_GASTOS = "GASTOS_SET_MOVIMIENTO_CAJA";
export const SET_FECHA_F_RETIROS = "GASTOS_SET_FECHA_F_RETIROS";
export const BUSCADOR = "GASTOS_BUSCADOR";
export const LOADER = "GASTOS_LOADER";


// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const params = store.gastos;
    const { ordering, search } = params;
    api.get(endpoint, { page, ordering, search }).catch((err) => { }).then((data) => {
        if (data) {
            dispatch(seData(data));
        }
        dispatch(setPage(page));
        dispatch(setLoader(false));
    });
};

export const crear = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const data = store.form.GastoCrear.values;
    let caja = store.gastos.movimiento_caja;
    if(caja)
        caja = store.gastos.movimiento_caja.id;
    else 
        caja = {}
    data.gasto_fijo = data.gasto_fijo === "true";

    let data2 = {
        data,
        caja
    }
    
    api.post(endpoint, data2).catch(() => {
        console.log(store.usuario.sucursal);
        if (store.usuario.sucursal==null){
            Swal(
                'ERROR',
                'Ha ocurrido un error, seleccione una sucursal',
                'error'
            );
        }
        else {
            Swal(
                'ERROR',
                'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
                'error'
            );
        }
    }).then((resp) => {
        if (resp){
            ToastStore.success("Datos almacenados correctamente");
            dispatch(limpiarDatosPago());
            dispatch(push('/gastos'));
            
       }
       dispatch(setLoader(false));
    });
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).catch(() => {
        Swal(
            'ERROR',
            'Ha ocurrido un error, espere un momento y vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data){
            ToastStore.success("Registro anulado");
            dispatch(listar());
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).catch((err) => {
        dispatch(push("/gastos"));
    }).then((resp) => {
        if (resp){
            dispatch(setItem(resp));
       }
    }).finally(() => {
       dispatch(setLoader(false));
    });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === 'asc') {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

//OBTENER LISTADOR DE MOVIMIENTOS CAJA
// DETALLE OC
export const listarMovimientos = (page=1) => (dispatch, getStore) => {
    dispatch(setLoaderRetiros(true));
    const store = getStore();
    let { ordering_retiros, fecha_inicial, fecha_final } = store.gastos;

    api.get(`${cuentasPagarEndpoint}/retiros_caja`, {page, ordering: ordering_retiros, fecha_inicial, fecha_final}).catch((err) => {
        ToastStore.error("Error al obtener los movimientos de caja")
    }).then((resp) => {
        if(resp) {
            dispatch(setMovimientosRetiros(resp));
            console.log(resp);
        }
        dispatch(setPageRetiros(page))
    }).finally(() => {
        dispatch(setLoaderRetiros(false))
    })
}

export const sortChangeRetiros = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === 'asc') {
        dispatch(setSortRetiros(sortName));
    } else {
        dispatch(setSortRetiros(`-${sortName}`));
    }
    const store = getStore();
    const { page_retiros } = store.ordenes_compra;
    dispatch(listarMovimientos(page_retiros));
};

export const changeFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const gastos = store.gastos;
    if (key === "Inicial"){
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(gastos.fecha_final);
        if (fecha_final >= fecha_inicial){
            dispatch(setFechaIRetiros(value));
        }
    } else {
        const fecha_inicial = new Date(gastos.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) {
            dispatch(setFechaFRetiros(value));
        }
    }

    dispatch(listarMovimientos());
}

export const limpiarDatosPago = () => (dispatch) => {
    dispatch(setMovimientosRetiros({}));
    dispatch(setPageRetiros(1));
    dispatch(setSortRetiros(""));
    dispatch(setFechaIRetiros(moment().format("YYYY-MM-DD")))
    dispatch(setFechaFRetiros(moment().format("YYYY-MM-DD")))
    dispatch(setMovimientoCaja(null));
}


// ------------------------------------
// PureActions
// ------------------------------------

export const seData = data => ({
    type: DATA,
    data,
});

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setBuscador = search => ({
    type: BUSCADOR,
    search,
});

export const setSort = ordering => ({
    type: SORT,
    ordering,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

//RETIROS DE CAJA
export const setLoaderRetiros = loader_retiros => ({
    type: SET_LOADER_RETIROS,
    loader_retiros,
});

export const setMovimientosRetiros = retiros => ({
    type: SET_MOVIMIENTOS_RETIROS,
    retiros,
});

export const setPageRetiros = page_retiros => ({
    type: SET_PAGE_RETIROS,
    page_retiros,
});

export const setSortRetiros = ordering_retiros => ({
    type: SET_ORDERING_RETIROS,
    ordering_retiros,
});

export const setFechaIRetiros = fecha_inicial => ({
    type: SET_FECHA_I_RETIROS,
    fecha_inicial,
});

export const setFechaFRetiros = fecha_final => ({
    type: SET_FECHA_F_RETIROS,
    fecha_final,
});

export const setMovimientoCaja = movimiento_caja => ({
    type: SET_MOVIMIENTO_CAJA_GASTOS,
    movimiento_caja
})


export const actions = {
    sortChange,
    buscar,
    listar,
    crear,
    leer,
    eliminar,
    listarMovimientos,
    sortChangeRetiros,
    changeFecha,
    setMovimientoCaja,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SET_MOVIMIENTOS_RETIROS]: (state, { retiros }) => {
        return {
            ...state,
            retiros,
        };
    },
    [SET_PAGE_RETIROS]: (state, { page_retiros }) => {
        return {
            ...state,
            page_retiros,
        };
    },
    [SET_ORDERING_RETIROS]: (state, { ordering_retiros }) => {
        return {
            ...state,
            ordering_retiros,
        };
    },
    [SET_LOADER_RETIROS]: (state, { loader_retiros }) => {
        return {
            ...state,
            loader_retiros,
        };
    },
    [SET_FECHA_I_RETIROS]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [SET_FECHA_F_RETIROS]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [SET_MOVIMIENTO_CAJA_GASTOS]: (state, { movimiento_caja }) => {
        return {
            ...state,
            movimiento_caja,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    item: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "-creado",

    // RETIROS
    retiros: {},
    page_retiros: 1,
    loader_retiros: false,
    ordering_retiros: "",
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    movimiento_caja: null,
};

export default handleActions(reducers, initialState);
