import _ from "lodash";
import {push} from "react-router-redux";
import Swal from 'sweetalert2';
import {ToastStore} from 'react-toasts';
import {setLoader, setSeleccionados} from "./bodega";
import { api } from "api";

const endpoint = "bodegas";

export const asignarReajuste = (producto, reajuste) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.bodega.seleccionados);
    const item = _.find(seleccionados, {id: producto.id});
    const index = seleccionados.indexOf(item);
    item.reajuste = reajuste;
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const finReajuste = () => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.bodega.seleccionados;
    const data = store.form.Reajuste.values;
    dispatch(setLoader(true));
    api.post(`${endpoint}/reajuste`, {productos: seleccionados, motivo: data.motivo}).catch((err) => {
        if (err) {
            if (err.detail) {
                Swal(
                    'ERROR',
                    err.detail,
                    'error'
                );
            } else {
                Swal(
                    'ERROR',
                    'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
                    'error'
                );
            }
        } else {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        }
    }).then((resp) => {
        if (resp){
            dispatch(setSeleccionados([]));
            ToastStore.success("Datos almacenados correctamente");
            dispatch(push(`/bodega/resumen_de_ingreso/${resp.id}`));
       }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



export const actions = {
    asignarReajuste,
    finReajuste,
};
