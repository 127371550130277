import { connect } from 'react-redux'
import Listado from './ListadoOrdenOnline'
import { actions } from '../../../../redux/modules/orden_online/orden_online'

const ms2p = (state) => ({
    ...state.ordenes_online,
    sucursal: state.usuario.sucursal,
    config: state.configuracion.config
});

const md2p = {
  ...actions
}

export default connect(ms2p, md2p)(Listado)
