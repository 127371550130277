import React from "react";
import { icons } from "icons";
import {
    RenderDateTime as DateTime,
    RenderCurrency,
    RenderNumber,
    RenderDate as RDate,
    RenderPercentaje,
} from "./renderReadField";

export const RenderImage = ({ src, alt }) => {
    if (src !== null && src !== "")
        return (
            <img
                style={{ maxWidth: "38px", borderRadius: "50%" }}
                src={src}
                alt={alt}
            />
        );
    return (
        <img
            style={{ maxWidth: "38px", borderRadius: "50%" }}
            src={icons.img_placeholder}
            alt={alt}
        />
    );
};

export const RenderDateTime = ({ fecha, className }) => {
    if (fecha) return <DateTime value={fecha} className={className} />;
    return <span className={className}>Sin registro</span>;
};

export const RenderDate = ({ fecha, className }) => {
    if (fecha) return <RDate value={fecha} className={className} />;
    return <span className={className}>Sin registro</span>;
};

export const RenderMoneda = ({ monto, className, decimalScale=2 }) => {
    if (!!monto) {
        monto = parseFloat(monto).toFixed(decimalScale);
    }
    if (monto || monto === 0)
        return (
            <RenderCurrency
                value={parseFloat(monto).toFixed(decimalScale)}
                className={className}
                decimalScale={decimalScale}
            />
        );
    return <span className={className}>Sin registro</span>;
};

export const RenderNumero = ({ monto, className, decimalScale = 2 }) => {
    if (!!monto) {
        monto = parseFloat(monto).toFixed(decimalScale);
    }
    if (monto || monto === 0)
        return (
            <RenderNumber
                value={monto}
                className={className}
                decimalScale={decimalScale}
            />
        );
    return <span className={className}>Sin registro</span>;
};

export const RenderNumeroDecimales = ({ monto, className, decimalScale = 3 }) => {
    if (typeof monto === 'number' && !isNaN(monto)) {
        // Redondea el monto al número deseado de decimales
        monto = parseFloat(monto).toFixed(decimalScale);

        return (
            <RenderNumber
                value={monto}
                className={className}
                decimalScale={decimalScale}
            />
        );
    } else if (monto === 0 || monto === '0') {
        // Si monto es 0, devuelve 0 con el número de decimales especificado
        return (
            <RenderNumber
                value={0}
                className={className}
                decimalScale={decimalScale}
            />
        );
    } else {
        // Si monto no es un número válido, muestra 'Sin registro'
        return <span className={className}>Sin registro</span>;
    }
};


export const RenderPorcentaje = ({ monto, className, decimalScale }) => {
    if (monto || monto === 0)
        return (
            <RenderPercentaje
                value={monto}
                className={className}
                decimalScale={decimalScale}
            />
        );
    return <span className={className}>Sin registro</span>;
};
