import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/bodega/bodega";
import { actions  as bajasActions } from "../../../../redux/modules/bodega/bajas";
import Bajas from './Bajas';


const ms2p = (state) => {
    return {
        ...state.bodega,
        sucursal: state.usuario.sucursal,
    };
};

const md2p = { ...actions, ...bajasActions };

export default connect(ms2p, md2p)(Bajas);
