import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import "./breadcrumbs.css"


class Breadcrums extends Component {
    static propTypes = {
        ruta: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    };
    constructor(props){
        super(props);
    }
    render() {
        const { ruta, match } = this.props;
        const pathname = ruta.pathname ? ruta.pathname : "";
        const path = match ? match.path : {};
        let identifiers = path.path ? path.path : "";
        if (identifiers === "" && (typeof path) === "string")
            identifiers = path;
        const matches = identifiers.split("/");
        const crumbs = [];
        let final_path = "";
        const sections = pathname.split("/");
        if (pathname.includes("punto_de_venta") == false) {
            sections.forEach((parte, index) => {
                if (parte !== "" && isNaN(parte)) {
                    let nombre = "";
                    parte.split("_").forEach((seccion) => {
                        nombre += " " + seccion;
                    });
                    final_path = final_path + "/" + parte;
                    if (index < (matches.length - 1)) {
                        if (matches[index + 1][0] === ":") {
                            final_path = final_path + "/" + sections[index + 1];
                        }
                    }
                    crumbs.push({nombre, ruta: final_path});
                }
            });
        }
        return (
            <div className="breadcrumb-container col-12 d-flex flex-wrap font-weight-bold">
                {crumbs.map((crumb, index) => {
                    if (index === crumbs.length - 1){
                        return (
                            <div key={crumb.ruta} className="d-flex breadcrumb-activo align-items-center">
                                <div className="crumb-item-activo">
                                    {crumb.nombre}
                                </div>
                                <div className="flecha-activa"></div>
                            </div>
                        )
                    }
                    return (
                        <div key={crumb.ruta} className="breadcrumb d-flex align-items-center">
                            <Link to={crumb.ruta} className="crumb-item">{crumb.nombre}</Link>
                            <div className="flecha"></div>
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default Breadcrums;
