import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import LoadMask from "Utils/LoadMask/LoadMask";
import {BreakLine, tableOptions} from "Utils/tableOptions";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {RenderDateTime as RDateTime, RenderCurrency} from "Utils/renderField/renderReadField";
import {RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField";
import { ReporteIngreso } from "../../../Bodega/Ingreso";

class ResumenReporteBodega extends Component {
    static propTypes = {
        // cierre: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.getMovimiento(this.props.match.params.id);
    }

    render(){
        //  state
        const {movimiento, loader} = this.props
        const data_movimiento = movimiento ? movimiento : {detalles: [], usuario: {}}

        //  bind

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="mb-1 mt-1">
                            <LoadMask loading={loader} dark blur>
                                <div className="d-flex flex-column padding-15">
                                    <h3><b>Detalle de {data_movimiento.tipo_movimiento}</b></h3>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Usuario</b>
                                            {data_movimiento.usuario.first_name}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Fecha / hora</b>
                                            <RDateTime value={data_movimiento.creado} />
                                        </div>
                                    </div>
                                    {
                                        data_movimiento.tipo == 20 && (
                                            <div className="d-flex flex-column flex-sm-row justify-content-between">
                                                <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                                    <b>proveedor</b>
                                                    { data_movimiento.cliente_proveedor ? data_movimiento.cliente_proveedor.nombre: '-----'}
                                                </div>
                                                <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                                    <b>NIT</b>
                                                    { data_movimiento.cliente_proveedor ? (data_movimiento.cliente_proveedor.nit ? data_movimiento.cliente_proveedor.nit : "C/F"): '-----' }
                                                </div>
                                            </div>

                                        )
                                    }
                                    {
                                        data_movimiento.tipo == 120 && (
                                            <div className="d-flex flex-column flex-sm-row justify-content-between">
                                                <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                                    <b>proveedor</b>
                                                    { data_movimiento.cliente_proveedor ? data_movimiento.cliente_proveedor.nombre: '-----'}
                                                </div>
                                                <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                                    <b>NIT</b>
                                                    { data_movimiento.cliente_proveedor ? (data_movimiento.cliente_proveedor.nit ? data_movimiento.cliente_proveedor.nit : "C/F"): '-----' }
                                                </div>
                                            </div>

                                        )
                                    }
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Motivo</b>
                                            {data_movimiento.concepto}
                                        </div>
                                    </div>
                                </div>
                                <BootstrapTable
                                    data={loader ? [] : data_movimiento.detalles}
                                    hover
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        width="0%"
                                        dataField="id"
                                        isKey
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="nombre"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <div><b>{row.producto }</b><div>{row.fraccion.nombre}</div></div>
                                            )
                                        }}
                                    >
                                        Producto
                                    </TableHeaderColumn>

                                    {
                                        (data_movimiento.tipo === 20 || data_movimiento.tipo === 50 || data_movimiento.tipo === 120) && (
                                            <TableHeaderColumn
                                                dataField="cantidad"
                                                dataAlign="right"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataFormat={(cell, row) => {
                                                    return (<RenderNumero monto={cell} />)
                                                }}
                                            >
                                                Cantidad
                                            </TableHeaderColumn>
                                        )
                                    }

                                    {
                                        (data_movimiento.tipo === 20 || data_movimiento.tipo === 120) && (
                                            <TableHeaderColumn
                                                dataField="costo"
                                                dataAlign="right"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataFormat={(cell, row) => {
                                                    return (<RenderMoneda monto={cell} />)
                                                }}
                                            >
                                                Costo
                                            </TableHeaderColumn>

                                        )
                                    }
                                    {
                                        (data_movimiento.tipo === 20 || data_movimiento.tipo === 120) && (
                                            <TableHeaderColumn
                                                dataField="sub_total"
                                                dataAlign="right"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataFormat={(cell, row) => {
                                                    return (<RenderMoneda monto={cell} />)
                                                }}
                                            >
                                                Subtotal
                                            </TableHeaderColumn>
                                        )
                                    }
                                    {
                                        data_movimiento.tipo === 60 && (
                                            <TableHeaderColumn
                                                dataField="existencias_antes"
                                                dataAlign="right"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataFormat={(cell, row) => {
                                                    return (<RenderNumero monto={cell} />)
                                                }}
                                            >
                                                Existencia antes
                                            </TableHeaderColumn>
                                        )
                                    }
                                    {
                                        data_movimiento.tipo === 60 && (
                                            <TableHeaderColumn
                                                dataField="existencias_despues"
                                                dataAlign="right"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataFormat={(cell, row) => {
                                                    return (<RenderNumero monto={cell} />)
                                                }}
                                            >
                                                Existencia después
                                            </TableHeaderColumn>
                                        )
                                    }
                                </BootstrapTable>
                                {
                                    (data_movimiento.tipo === 20 || data_movimiento.tipo === 120) && (
                                        <div className="border-bottom w-100 pl-3 pr-3 mt-2 d-flex justify-content-between flex-row">
                                            <h3><b>Total</b></h3>
                                            <h3><b><RenderCurrency value={data_movimiento.total} /></b></h3>
                                        </div>
                                    )
                                }
                                {(data_movimiento.tipo === 20 || data_movimiento.tipo === 50 || data_movimiento.tipo === 60 || data_movimiento.tipo === 120) ? (
                                    <ReporteIngreso {...this.props} regresar={"/reporte_bodega"} />
                                ) : (
                                    <div className="d-flex justify-content-center flex-row w-100 mt-3 mb-3">
                                        <Link className="btn btn-tertiary m-1"  to={"/reporte_bodega"}><i className="fa fa-arrow-left" aria-hidden="true" /> Regresar</Link>
                                    </div>
                                )}
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResumenReporteBodega;
