import _ from "lodash";
import { setLoader, setSeleccionados, setMovimiento } from "./bodega";
import { initialize as initializeForm } from "redux-form";
import { goBack, push } from "react-router-redux";
import { ToastStore } from "react-toasts";
import { api } from "api";
import Swal from "sweetalert2";
import { listaProveedores } from "../../../common/components/Bodega/Ingreso/IngresoForm";

const endpoint = "bodegas";

export const crearProveedor = (data) => (dispatch, getStore) => {
    api.post("proveedores", data)
        .then((data) => {
            ToastStore.success("Nuevo proveedor almacenado");
            const form = getStore().form.IngresoProducto.values;
            listaProveedores.push({ ...data });
            dispatch(
                initializeForm("IngresoProducto", {
                    ...form,
                    proveedor: data.id,
                })
            );
        })
        .catch((err) => {
            console.log(err);
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            // dispatch(setLoader(false));
        });
};

export const asignarIngreso = (producto, ingreso) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.bodega.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (ingreso < 0) {
        item.ingreso = 0;
    } else {
        item.ingreso = ingreso;
    }
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const asignarCosto = (producto, costo) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.bodega.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (costo < 0) {
        item.costo = 0;
    } else {
        item.costo = costo;
    }
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const asignarPrecio = (producto, precio) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.bodega.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (precio < 0) {
        item.precio = 0;
    } else {
        item.precio = precio;
    }
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const asignarUnidadDeMedida = (producto, id_unidad_medida) => (
    dispatch,
    getStore
) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.bodega.seleccionados);
    let item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    item.id_unidad_de_medida = parseInt(id_unidad_medida);
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const efectuarIngreso = () => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.bodega.seleccionados;
    seleccionados.forEach((item) => {
        item.costo = item.costo != "" && item.costo != null ? item.costo : 0;
        item.precio =
            item.precio != "" && item.precio != null ? item.precio : 0;
    });
    const data = store.form.IngresoProducto.values;
    Swal({
        title: "¿Guardar?",
        text: "¿Confirma que desea guardar la información?",
        type: "question",
        showCancelButton: true,
        confirmButtonText: "Sí, continuar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.post(`${endpoint}/ingreso`, {
                productos: seleccionados,
                motivo: data.motivo,
                proveedor: data.proveedor,
                no_factura: data.no_factura
            })
            .catch((err) => {
                if (err) {
                    if (err.detail) {
                        Swal("ERROR", err.detail, "error");
                    } else {
                        Swal(
                            "ERROR",
                            "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                            "error"
                        );
                    }
                } else {
                    Swal(
                        "ERROR",
                        "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                        "error"
                    );
                }
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setSeleccionados([]));
                    ToastStore.success("Datos almacenados correctamente");
                    dispatch(setMovimiento(resp));
                    dispatch(push(`/bodega/resumen_de_ingreso/${resp.id}`));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
        }
    });
};

export const actions = {
    asignarIngreso,
    asignarCosto,
    asignarPrecio,
    efectuarIngreso,
    crearProveedor,
    asignarUnidadDeMedida,
};
