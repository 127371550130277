import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { DESPACHO_PENDIENTE, DESPACHO_COMPLETO } from "utility/variables";

class ReporteNotaEnvio extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {}

    render() {
        const { despacho, conf_mostrar_precio_impresion } = this.props;
        let total_despacho = 0;
        return (
            <div className="print-shadow">
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                    <div className="w-100 text-center">
                        <b className="text-uppercase">
                            {despacho.empresa.nombre}
                        </b>
                    </div>
                    <div className="w-100 text-center">
                        {despacho.estado_despacho == DESPACHO_PENDIENTE ? (
                            <b className="text-uppercase">
                                {despacho.sucursal.nombre}
                            </b>
                        ) : (
                            <b className="text-uppercase">
                                {despacho.sucursal_destino.nombre}
                            </b>
                        )}
                    </div>
                    <div className="w-100 text-center">
                        {despacho.estado_despacho == DESPACHO_PENDIENTE ? (
                            <b className="text-uppercase">
                                {despacho.sucursal.direccion}
                            </b>
                        ) : (
                            <b className="text-uppercase">
                                {despacho.sucursal_destino.direccion}
                            </b>
                        )}
                    </div>
                    {despacho.estado_despacho == DESPACHO_PENDIENTE ? (
                        <div className="w-100 text-center">
                            {(despacho.sucursal.telefono ||
                                despacho.sucursal.telefono != "") && (
                                <b className="text-uppercase">
                                    Tel. {despacho.sucursal.telefono}
                                </b>
                            )}
                        </div>
                    ) : (
                        <div className="w-100 text-center">
                            {(despacho.sucursal_destino.telefono ||
                                despacho.sucursal_destino.telefono != "") && (
                                <b className="text-uppercase">
                                    Tel. {despacho.sucursal_destino.telefono}
                                </b>
                            )}
                        </div>
                    )}
                    {despacho.empresa.utiliza_fel && (
                        <div className="w-100 text-center">
                            <div className="w-100 text-center">
                                <b className="text-uppercase">
                                    Nit. {despacho.empresa.nit}
                                </b>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt mb d-flex flex-row justify-content-center fnt-14">
                    {despacho.estado_despacho == DESPACHO_PENDIENTE && (
                        <span>NOTA DE ENVÍO</span>
                    )}
                    {despacho.estado_despacho == DESPACHO_COMPLETO && (
                        <span>NOTA DE RECEPCIÓN DE DESPACHO</span>
                    )}
                </div>
                {despacho.codigo && (
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>Código</b>
                            {despacho.codigo}
                        </div>
                    </div>
                )}
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-column">
                        {despacho.estado_despacho == DESPACHO_PENDIENTE ? (
                            <b>Enviado a</b>
                        ) : (
                            <b>Enviado desde</b>
                        )}
                        {despacho.estado_despacho == DESPACHO_PENDIENTE
                            ? despacho.sucursal_destino.nombre
                            : despacho.sucursal.nombre}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Fecha y hora</b>
                        <RenderDateTime
                            value={
                                despacho.estado_despacho == DESPACHO_PENDIENTE
                                    ? despacho.creado
                                    : despacho.fecha_recepcion
                            }
                        />
                    </div>
                </div>
                {despacho.estado_despacho == DESPACHO_PENDIENTE && (
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>Dirección</b>
                            {despacho.sucursal_destino.direccion
                                ? despacho.sucursal_destino.direccion
                                : "-----"}
                        </div>
                        <div className="flex1 d-flex flex-column">
                            <b>Teléfono</b>
                            {despacho.sucursal_destino.telefono
                                ? despacho.sucursal_destino.telefono
                                : "-----"}
                        </div>
                    </div>
                )}
                <div className="d-flex flex-row fnt-12 mb">
                    <div className="flex1 d-flex flex-column">
                        <b>Generado por</b>
                        {despacho.estado_despacho == DESPACHO_PENDIENTE ? (
                            <span>
                                {despacho.usuario.first_name
                                    ? despacho.usuario.first_name
                                    : "-----"}
                            </span>
                        ) : (
                            <span>
                                {despacho.recepcionado_por.first_name
                                    ? despacho.recepcionado_por.first_name
                                    : "-----"}
                            </span>
                        )}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Responsable de transporte</b>
                        {despacho.responsable_transporte
                            ? despacho.responsable_transporte
                            : "-----"}
                    </div>
                </div>
                <div
                    className="d-flex flex-row justify-content-between fnt-12 mt-4 mb"
                    style={{ borderBottom: "dotted 1px", paddingTop: "10px" }}
                >
                    <b className="flex1">ARTÍCULOS</b>
                    {despacho.estado_despacho == DESPACHO_COMPLETO && (
                        <b style={{ width: "80px" }}>RECIBIDOS</b>
                    )}
                    {despacho.estado_despacho == DESPACHO_PENDIENTE ? (
                        <b style={{ width: "80px" }}>CANTIDAD</b>
                    ) : (
                        <b style={{ width: "80px" }}>ENVIADOS</b>
                    )}
                    {conf_mostrar_precio_impresion && (
                        <b style={{ width: "80px" }}>SUBTOTAL</b>
                    )}
                </div>
                {despacho.detalles.map((detalle) => {
                    const precio_detalle = detalle.precio
                        ? detalle.precio
                        : detalle.fraccion.precio;
                    total_despacho += parseFloat(
                        (precio_detalle * detalle.cantidad).toFixed(2)
                    );
                    return (
                        <div className="d-flex mb fnt-11" key={detalle.id}>
                            <div className="width100">
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <div className="flex1">
                                        <div className="d-flex flex-row">
                                            {`
                                                ${
                                                    detalle.fraccion
                                                        .codigo_barras
                                                        ? `${detalle.fraccion.codigo_barras} / `
                                                        : ""
                                                }
                                                ${
                                                    detalle.fraccion.producto
                                                        .nombre
                                                } / ${
                                                detalle.fraccion.producto
                                                    .a_granel
                                                    ? detalle.fraccion
                                                          .unidad_de_medida
                                                          .nombre
                                                    : detalle.fraccion.nombre
                                            }
                                                `}
                                        </div>
                                        {conf_mostrar_precio_impresion && (
                                            <div>
                                                <span className="mr-2">
                                                    Precio:
                                                </span>
                                                <RenderCurrency
                                                    value={precio_detalle}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {despacho.estado_despacho ==
                                        DESPACHO_COMPLETO && (
                                        <div
                                            style={{ width: "80px" }}
                                            className="d-flex flex-row align-items-center justify-content-end"
                                        >
                                            {detalle.fraccion.producto
                                                .a_granel ? (
                                                <RenderNumber
                                                    value={
                                                        detalle.cantidad_recibido
                                                    }
                                                    decimalScale={3}
                                                    className={"mr-1"}
                                                />
                                            ) : (
                                                <RenderNumber
                                                    value={
                                                        detalle.cantidad_recibido
                                                    }
                                                    className={"mr-1"}
                                                />
                                            )}
                                        </div>
                                    )}
                                    <div
                                        style={{ width: "80px" }}
                                        className="d-flex flex-row align-items-center justify-content-end"
                                    >
                                        {detalle.fraccion.producto.a_granel ? (
                                            <RenderNumber
                                                value={detalle.cantidad}
                                                decimalScale={3}
                                                className={"mr-1"}
                                            />
                                        ) : (
                                            <RenderNumber
                                                value={detalle.cantidad}
                                                className={"mr-1"}
                                            />
                                        )}
                                    </div>
                                    {conf_mostrar_precio_impresion && (
                                        <div
                                            style={{ width: "80px" }}
                                            className="d-flex flex-row align-items-center justify-content-end"
                                        >
                                            <RenderCurrency
                                                value={
                                                    precio_detalle *
                                                    detalle.cantidad
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}

                {conf_mostrar_precio_impresion && (
                    <div className="fnt-16">
                        <div className="d-flex flex-row justify-content-between">
                            <b>TOTAL</b>
                            <b>
                                <RenderCurrency
                                    value={
                                        total_despacho != 0 ? total_despacho : 0
                                    }
                                />
                            </b>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

ReporteNotaEnvio.propTypes = {
    despacho: PropTypes.object.isRequired,
};

export default ReporteNotaEnvio;
