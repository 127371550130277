import { connect } from 'react-redux';
import _ from "lodash";
import { actions } from '../../../redux/modules/login';
import { toggleNav } from "../../../redux/modules/dashboard/dashboard";
import { setSucursal, setRuta, closeMenu } from "../../../redux/modules/usuario/usuario";
import Navbar from './Navbar';
import { goToPerfil } from "../../../redux/modules/perfil"


const ms2p = (state) => {
    const sucursales = state.usuario.me.sucursales ? state.usuario.me.sucursales : [];
    let sucursal = state.usuario.sucursal ? parseInt(state.usuario.sucursal) : "empresa";
    const usuario = state.usuario.me;
    if (sucursal !== "empresa"){
        sucursal = parseInt(sucursal);
    }
    let empresa = state.usuario.me.empresa;
    if (!_.find(sucursales, {id: "empresa"}) && (state.usuario.me.es_global || state.usuario.me.es_propietario))
        sucursales.unshift({nombre: "Todas", id: "empresa"});
    const url = window.location.href;
    return {
        ...state.login,
        sucursales,
        sucursal,
        url,
        usuario,
        menu: state.usuario.menu,
        empresa,
    };
};

const md2p = {
    ...actions,
    toggleNav,
    setSucursal,
    setRuta,
    closeMenu,
    goToPerfil,
};

export default connect(ms2p, md2p)(Navbar);
