import { connect } from "react-redux";
import Fracciones from "./Fracciones";
import {
    selectFraccionAEditar,
    eliminarFraccion,
    agregarFaccion,
    agregarPrimeraFraccion,
} from "../../../../redux/modules/producto/producto";

const ms2p = (state) => ({
    fracciones: state.producto.fracciones,
});

const md2p = {
    selectFraccionAEditar: (nombre) => (dispatch) =>
        dispatch(selectFraccionAEditar(nombre)),
    eliminarFraccion: (nombre) => (dispatch) =>
        dispatch(eliminarFraccion(nombre)),
    agregarFaccion: (nombre, onOpenModal) => (dispatch) =>
        dispatch(agregarFaccion(nombre, onOpenModal)),
    agregarPrimeraFraccion,
};

export default connect(ms2p, md2p)(Fracciones);
