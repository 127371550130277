import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/descuento_recargo";
import ListadoDescuentoRecargo from "./ListadoDescuentoRecargo";

const ms2p = (state) => {
    return {
        ...state.reporte_descuento_recargo,
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(ListadoDescuentoRecargo);
