import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import {
    RenderDateTime,
    RenderMoneda,
    RenderNumero,
    RenderPorcentaje,
} from "Utils/renderField/renderTableField";
import {
    RenderDateTime as RDateTime,
    RenderCurrency,
} from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { Link } from "react-router-dom";

class DetalleVenta extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        detalle: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            url_back: "",
        };
    }
    componentWillMount() {
        // if (this.props.match.path === "/punto_de_venta/venta/:id") {
        //     this.setState({url_back: "/punto_de_venta"})
        // } else {
        //     this.setState({url_back: "/reporte_ventas"})
        // }
        this.props.leer(this.props.match.params.id);
    }

    render() {
        // const { url_back } = this.state;
        const {
            loader,
            detalle,
            venta_permitir_descuento,
            regresar,
        } = this.props;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        const movimiento = detalle.movimiento
            ? detalle.movimiento
            : { detalles: [], usuario: {} };
        const cliente = detalle.cliente ? detalle.cliente : {};

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="mb-1 mt-1">
                            <LoadMask loading={loader} light blur>
                                <div className="d-flex flex-column padding-15">
                                    <h3>
                                        <b>Detalle de Venta</b>
                                    </h3>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Vendedor</b>
                                            {movimiento.usuario.first_name}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Fecha / hora</b>
                                            <RDateTime
                                                value={movimiento.creado}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Comprador</b>
                                            {cliente.nombre}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>NIT</b>
                                            {cliente.nit ? cliente.nit : "C/F"}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Código</b>
                                            {movimiento ? movimiento.tipo_movimiento : ""}
                                            {" "}
                                            {movimiento ? movimiento.codigo_venta : ""}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 ml-sm-5 mr-sm-5 m-0 mt-2">
                                        </div>
                                    </div>
                                </div>
                                <BootstrapTable
                                    data={loader ? [] : movimiento.detalles}
                                    hover
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        width="0%"
                                        dataField="id"
                                        isKey
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="nombre"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <div>
                                                    {/* VALIDAR SI ES VALE EL ITEM */}
                                                    {row.es_vale ? (
                                                        <b>
                                                            Descuento (
                                                            {row.vale.codigo})
                                                        </b>
                                                    ) : (
                                                        <span>
                                                            <b>
                                                                {row.producto}
                                                            </b>
                                                            <div>
                                                                {row.fraccion
                                                                    .producto
                                                                    .a_granel
                                                                    ? row
                                                                          .unidad_de_medida
                                                                          .nombre
                                                                    : row
                                                                          .fraccion
                                                                          .nombre}
                                                            </div>
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        }}
                                    >
                                        Producto
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="precio"
                                        dataAlign="right"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda
                                                    monto={
                                                        row.es_vale
                                                            ? cell * -1
                                                            : cell
                                                    }
                                                />
                                            );
                                        }}
                                    >
                                        Precio
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cantidad"
                                        dataAlign="right"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderNumero monto={cell} />
                                            );
                                        }}
                                    >
                                        Cantidad
                                    </TableHeaderColumn>
                                    {!!venta_permitir_descuento && (
                                        <TableHeaderColumn
                                            dataField="desc_monto"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderMoneda
                                                        monto={
                                                            cell
                                                                ? cell *
                                                                  row.cantidad
                                                                : 0
                                                        }
                                                    />
                                                );
                                            }}
                                        >
                                            Monto descuento
                                        </TableHeaderColumn>
                                    )}

                                    {!!venta_permitir_descuento && (
                                        <TableHeaderColumn
                                            dataField="desc_porcentaje"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderPorcentaje
                                                        monto={cell ? cell : 0}
                                                    />
                                                );
                                            }}
                                        >
                                            Descuento
                                        </TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        dataField="sub_total"
                                        dataAlign="right"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda monto={cell} />
                                            );
                                        }}
                                    >
                                        Subtotal
                                    </TableHeaderColumn>
                                </BootstrapTable>
                                <div className="border-bottom w-100 pl-3 pr-3 mt-2 d-flex justify-content-between flex-row">
                                    <h3>
                                        <b>Total</b>
                                    </h3>
                                    <h3>
                                        <b>
                                            <RenderCurrency
                                                value={
                                                    movimiento.total_cobrar_pagar
                                                }
                                            />
                                        </b>
                                    </h3>
                                </div>
                            </LoadMask>
                            <div className="d-flex justify-content-center flex-row w-100 mt-3 mb-3">
                                <button
                                    className="btn btn-tertiary m-1"
                                    onClick={regresar}
                                >
                                    <i
                                        className="fa fa-arrow-left"
                                        aria-hidden="true"
                                    />{" "}
                                    Regresar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetalleVenta;
