import _, { forEach } from "lodash";
import {api} from "api";
import uuidv1 from "uuid/v1";
import Swal from "sweetalert2";
import moment from "moment/moment";
import {ToastStore} from "react-toasts";
import {handleActions} from "redux-actions";
import {pago_total} from "utility/variables";
import {change as changeForm} from "redux-form";
import {actions as printActions} from "../print/print";
import {actions as cajaActions} from "../caja/caja";
import {actions as usuarioActions} from "../usuario/usuario";
// ------------------------------------
// Constants
// ------------------------------------
export const SET_NOTA = "VENTA_SET_NOTA";
export const TAB = "PUNTO_VENTA_CAJA_TAB";
export const SET_CHEQUE = "VENTA_SET_CHEQUE";
export const SET_DEPOSITO = "VENTA_SET_DEPOSITO";
export const SET_PAGE = "PUNTO_VENTA_CAJA_SET_PAGE";
export const SET_VENTA = "PUNTO_VENTA_CAJA_SET_VENTA";
export const SET_LOADER = "PUNTO_VENTA_CAJA_SET_LOADER";
export const SET_SEARCH = "PUNTO_VENTA_CAJA_SET_SEARCH";
export const SET_VENTAS = "PUNTO_VENTA_CAJA_SET_VENTAS";
export const SET_NOTA_CREDITO = "VENTA_SET_NOTA_CREDITO";
export const SET_EXENCION_IVA = "VENTA_SET_EXENCION_IVA";
export const SET_TARJETA = "PUNTO_VENTA_CAJA_SET_TARJETA";
export const SET_DETALLE = "PUNTO_VENTA_CAJA_SET_DETALLE";
export const SET_RETENCION_IVA = "VENTA_SET_RETENCION_IVA";
export const SET_EFECTIVO = "PUNTO_VENTA_CAJA_SET_EFECTIVO";
export const SET_REDONDEO = "PUNTO_VENTA_CAJA_SET_REDONDEO";
export const SET_IMPRESION = "PUNTO_VENTA_CAJA_SET_IMPRESION";
export const SET_TIPO_PAGO = "PUNTO_VENTA_CAJA_SET_TIPO_PAGO";
export const SET_VER_COBRO = "PUNTO_VENTA_CAJA_SET_VER_COBRO";
export const SET_MOVIMIENTO = "PUNTO_VENTA_CAJA_SET_MOVIMIENTO";
export const SET_NIT_CLIENTE = "PUNTO_VENTA_CAJA_SET_NIT_CLIENTE";
export const SET_VER_BUSCADOR = "PUNTO_VENTA_CAJA_SET_VER_BUSCADOR";
export const SET_COTIZACION_CARGADA = "VENTA_SET_COTIZACION_CARGADA";
export const SET_NOTAS_CLIENTE = "PUNTO_VENTA_CAJA_SET_NOTAS_CLIENTE";
export const SET_CODIGO_NOTA_CREDITO = "VENTA_SET_CODIGO_NOTA_CREDITO";
export const SET_NOMBRE_CLIENTE = "PUNTO_VENTA_CAJA_SET_NOMBRE_CLIENTE";
export const SET_TIPO_DOCUMENTO = "PUNTO_VENTA_CAJA_SET_TIPO_DOCUMENTO";
export const SET_LOADER_DETALLE = "PUNTO_VENTA_CAJA_SET_LOADER_DETALLE";
export const SET_NOTA_CREDITO_CARGADA = "VENTA_SET_NOTA_CREDITO_CARGADA";
export const SET_TELEFONO_CLIENTE = "PUNTO_VENTA_CAJA_SET_TELEFONO_CLIENTE";
export const SET_DIRECCION_CLIENTE = "PUNTO_VENTA_CAJA_SET_DIRECCION_CLIENTE";
export const SET_FECHA_VENCIMIENTO_CREDITO =
    "PUNTO_VENTA_CAJA_SET_FECHA_VENCIMIENTO_CREDITO";
export const SET_ESTADO_FORM_CLIENTE =
    "PUNTO_VENTA_CAJA_SET_ESTADO_FORM_CLIENTE";
const endpoint = "cotizaciones";
const endpoint_venta = "ventas";
const endpoint_clientes = "clientes";
const endpoint_datos_impresion_venta = "historial_ventas/reimpresion_venta";

// ------------------------------------
// Actions
// ------------------------------------
export const listarVentas = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const {search} = store.punto_venta_caja;
    const {caja} = store.caja.ultimo_cierre;

    api.get(endpoint, {
        page,
        search,
        activo: true,
        estado: 10,
        enviado_caja: true,
        caja_id: caja ? caja.id : null,
    })
        .catch((err) => {
        })
        .then((resp) => {
            if (resp) {
                dispatch(setVentas(resp));
                dispatch(setPage(page));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const buscarVenta = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarVentas());
};

export const cambioBuscador = () => (dispatch, getStore) => {
    const store = getStore();
    const ver_buscador = store.punto_venta_caja.ver_buscador;
    dispatch(setVerBuscador(!ver_buscador));
};

export const cargarProductosVenta = (venta_cotizacion) => (
    dispatch,
    getStore
) => {
    dispatch(setDetalle([]));
    dispatch(setLoaderDetalle(true));
    api.get(`${endpoint}/detalles`, {cotizacion_id: venta_cotizacion.id})
        .catch((err) => {
            dispatch(setLoaderDetalle(false));
        })
        .then((resp) => {
            if (resp) {
                resp.results.forEach((detalle) => {
                    const store = getStore();
                    const detalle_venta = _.cloneDeep(
                        store.punto_venta_caja.detalle_venta
                    );
                    const items = _.filter(detalle_venta, {
                        id: detalle.fraccion.id,
                    });
                    let item = {
                        id: detalle.fraccion.id,
                        id_det_mov: detalle.id,
                        key: uuidv1(),
                        collapsed: false,
                        es_vale: false,
                        producto: detalle.fraccion.producto,
                        codigo_barras: detalle.fraccion.codigo_barras,
                        cantidad_total: 0,
                        inventario: detalle.fraccion.inventario,
                        inventario_total: 0,
                        precio: detalle.precio,
                        desc_monto: detalle.desc_monto,
                        desc_porcentaje: detalle.desc_porcentaje,
                        precio_original: detalle.precio_original,
                        cantidad: detalle.cantidad,
                        inventario_calculado:
                        detalle.fraccion.inventario_calculado,
                        nombre: detalle.fraccion.nombre,
                        codigo: null, // Para vales
                        unidad_de_medida: detalle.fraccion.unidad_de_medida,
                    };

                    if (items.length) {
                        item.inventario_total = items[0].inventario_total;
                    } else {
                        item.inventario_total = item.inventario_calculado;
                    }

                    //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
                    let cantidad_total = item.cantidad;

                    //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
                    items.forEach((itemRep) => {
                        cantidad_total += itemRep.cantidad;
                    });

                    //  SE CALCULA EL INVENTARIO FINAL
                    const inventario_final =
                        item.inventario_total - cantidad_total;

                    //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
                    items.forEach((itemRep) => {
                        const indexSeleccionado = _.indexOf(
                            detalle_venta,
                            itemRep
                        );
                        itemRep.inventario =
                            inventario_final >= 0 ? inventario_final : 0;
                        itemRep.cantidad_total = cantidad_total;
                        detalle_venta.splice(indexSeleccionado, 1, itemRep);
                    });

                    item.cantidad_total = cantidad_total;
                    item.inventario =
                        inventario_final >= 0 ? inventario_final : 0;
                    item.id_unidad_de_medida = item.producto.a_granel
                        ? item.unidad_de_medida
                        : null;
                    dispatch(setDetalle([...detalle_venta, item]));
                });
                dispatch(setVenta(venta_cotizacion));
                if (venta_cotizacion.nit != "CF") {
                    dispatch(setNitCliente(venta_cotizacion.nit));
                } else {
                    dispatch(setNitCliente("CF"));
                }
                dispatch(setNombreCliente(venta_cotizacion.nombre));
                dispatch(setTipoDocumentoCliente(venta_cotizacion.tipo_documento));
                console
                dispatch(setLoaderDetalle(false));
            }
        });
};

export const quitarDetalle = (detalle, index) => (dispatch, getStore) => {
    const store = getStore();
    const detalle_venta = _.cloneDeep(store.punto_venta_caja.detalle_venta);
    // const item = detalle.es_vale ? _.find(detalle_venta, { codigo: detalle.codigo }) : detalle_venta[index]
    const item = detalle_venta[index];
    if (!item.es_vale) {
        detalle_venta.splice(index, 1);
        //  SE FILTRAN LOS PRODUCTOS IGUALES
        const items = _.filter(detalle_venta, {id: detalle.id});

        //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
        let cantidad_total = 0;

        //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
        items.forEach((item) => {
            cantidad_total += item.cantidad;
        });
        //  SE CALCULA EL INVENTARIO FINAL
        const inventario_final = item.inventario_total - cantidad_total;

        //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
        if (items.length) {
            items.forEach((item) => {
                const indexSeleccionado = _.indexOf(detalle_venta, item);
                item.inventario = inventario_final >= 0 ? inventario_final : 0;
                item.cantidad_total = cantidad_total;
                detalle_venta.splice(indexSeleccionado, 1, item);
            });
        }

        dispatch(setDetalle(detalle_venta));
    } else {
        detalle_venta.splice(index, 1);
    }
};

export const cancelarVenta = () => (dispatch) => {
    dispatch(setVenta(null));
    dispatch(setDetalle([]));
};

const agregarVale = (codigo) => (dispatch, getStore) => {
    dispatch(setLoaderDetalle(true));
    api.get("devoluciones", {codigo})
        .then((data) => {
            if (data.results.length > 0) {
                const vale = data.results[0];
                const store = getStore();
                const detalle = _.cloneDeep(
                    store.punto_venta_caja.detalle_venta
                );
                dispatch(
                    setDetalle([
                        ...detalle,
                        {
                            ...vale,
                            es_vale: true,
                        },
                    ])
                );
            } else {
                Swal("ERROR", "El código ingresado no es válido", "warning");
            }
            dispatch(setLoaderDetalle(false));
        })
        .catch((e) => {
            dispatch(setLoaderDetalle(false));
        });
};

export const cambioCobro = (total) => (dispatch, getStore) => {
    const store = getStore();
    const {detalle_venta, ver_buscador, ver_cobro} = store.punto_venta_caja;
    let alrta_inventario = false;
    if (total <= 0) {
        ToastStore.error(
            "El monto de descuento por vale no debe sobrepasar ni igualar el total de la venta"
        );
    } else {
        if (detalle_venta.length) {
            detalle_venta.forEach((det) => {
                if (!det.es_vale) {
                    if (
                        det.producto.inventariable &&
                        det.cantidad_total > det.inventario_total
                    ) {
                        //alrta_inventario = true;
                    }
                }
            });
            //alrta_inventario = false;
            if (!alrta_inventario) {
                dispatch(setVerCobro(!ver_cobro));
                dispatch(setVerBuscador(!ver_buscador));
            } else {
                ToastStore.error(
                    "Algún producto del detalle no cuenta con inventario."
                );
            }
        } else {
            if (!ver_cobro) {
                ToastStore.error("La venta no tiene productos seleccionados.");
            } else {
                dispatch(setVerCobro(!ver_cobro));
                dispatch(setVerBuscador(!ver_buscador));
            }
        }
    }
};

export const setEfectivo = (monto) => (dispatch) => {
    if (monto < 0) {
        dispatch(_setEfectivo(0));
    } else {
        dispatch(_setEfectivo(monto));
    }
};

export const setTarjeta = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setTarjeta(0));
        } else {
            if (monto <= total) {
                dispatch(_setTarjeta(monto));
            } else {
                dispatch(_setTarjeta(total));
            }
        }
    } else {
        dispatch(_setTarjeta(""));
    }
};

export const setCheque = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setCheque(0));
        } else {
            if (monto <= total) {
                dispatch(_setCheque(monto));
            } else {
                dispatch(_setCheque(total));
            }
        }
    } else {
        dispatch(_setCheque(""));
    }
};
export const setDeposito = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setDeposito(0));
        } else {
            if (monto <= total) {
                dispatch(_setDeposito(monto));
            } else {
                dispatch(_setDeposito(total));
            }
        }
    } else {
        dispatch(_setDeposito(""));
    }
};

export const setRetencionIva = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setRetencionIVA(0));
        } else {
            if (monto <= total) {
                dispatch(_setRetencionIVA(monto));
            } else {
                dispatch(_setRetencionIVA(total));
            }
        }
    } else {
        dispatch(_setRetencionIVA(""));
    }
};

export const setExencionIva = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setExencionIVA(0));
        } else {
            if (monto <= total) {
                dispatch(_setExencionIVA(monto));
            } else {
                dispatch(_setExencionIVA(total));
            }
        }
    } else {
        dispatch(_setExencionIVA(""));
    }
};

export const setNotaCredito = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setNotaCredito(0));
        } else {
            dispatch(_setNotaCredito(monto));
        }
    } else {
        dispatch(_setNotaCredito(""));
    }
};

export const leerCliente = (nit) => (dispatch, getStore) => {
    // esta accion lee un cliente a partir de su nit
    // para cargarlo a una venta, no confundir con leer()
    dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    dispatch(changeForm("ClienteVentaForm", "direccion", ""));
    const store = getStore();
    api.get(`${endpoint_clientes}/${nit}/por_nit/`)
        .catch((err) => {
            if (err.detail) {
                if (err.detail == "El servicio de Infile no esta disponible") {
                    dispatch(setEstadoFormCliente(false));
                    ToastStore.error(err.detail);
                } else {
                    dispatch(setEstadoFormCliente(true));
                }
            } else {
                ToastStore.error(
                    "No fue posible obtener información del cliente"
                );
            }
        })
        .then((data) => {
            // cliente encontrado
            if (data) {
                dispatch(setEstadoFormCliente(true));
                dispatch(setNombreCliente(data.nombre));
                dispatch(changeForm("ClienteVentaForm", "nombre", data.nombre));
                dispatch(setTipoDocumentoCliente("NIT"));
                if (store.usuario.tipo_punto_venta === "PVMCE") {
                    dispatch(setDireccionCliente(data.direccion));
                    dispatch(setTelefonoCliente(data.telefono));
                    dispatch(setNotasCliente(data.extra));
                    dispatch(
                        changeForm(
                            "ClienteVentaForm",
                            "direccion",
                            data.direccion
                        )
                    );
                    dispatch(
                        changeForm(
                            "ClienteVentaForm",
                            "telefono",
                            data.telefono
                        )
                    );
                    dispatch(
                        changeForm("ClienteVentaForm", "extra", data.extra)
                    );
                }
            }
        });
};

export const changeDatosClientes = (campo, value) => (dispatch, getStore) => {
    if (campo === "nit") {
        dispatch(setNitCliente(value));
    } else if (campo === "nombre") {
        dispatch(setNombreCliente(value));
    } else if (campo === "telefono") {
        dispatch(setTelefonoCliente(value));
    } else if (campo === "direccion") {
        dispatch(setDireccionCliente(value));
    } else {
        dispatch(setNotasCliente(value));
    }
};

export const limpiarDatosCliente = () => (dispatch, getStore) => {
    const store = getStore();

    dispatch(setNitCliente(""));
    dispatch(setNombreCliente(""));
    dispatch(changeForm("ClienteVentaForm", "nit", ""));
    dispatch(changeForm("ClienteVentaForm", "nombre", ""));

    if (store.usuario.tipo_punto_venta === "PVMCE") {
        dispatch(setDireccionCliente(""));
        dispatch(setNotasCliente(""));
        dispatch(setTelefonoCliente(""));
        dispatch(changeForm("ClienteVentaForm", "direccion", ""));
        dispatch(changeForm("ClienteVentaForm", "telefono", ""));
        dispatch(changeForm("ClienteVentaForm", "extra", ""));
    }
};

export const finalizarPagarVenta = (total) => (dispatch, getStore) => {
    const store = getStore();
    const {
        efectivo,
        tarjeta,
        tipo_pago,
        redondeo,
        detalle_venta,
        movimiento,
        cheque,
        deposito,
        nota,
        nota_credito,
        retencion_iva,
        exencion_iva,
    } = store.punto_venta_caja;
    const {conf_venta_redondeo} = store.usuario;
    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };
    if (movimiento.sucursal !== undefined) {
        dispatch(setMovimiento({}));
        dispatch(setDetalle([]));
        dispatch(limpiarDatosCliente());
        dispatch(setVerBuscador(false));
        dispatch(setVerCobro(false));
        dispatch(_setEfectivo(""));
        dispatch(_setTarjeta(""));
        dispatch(_setCheque(""));
        dispatch(_setDeposito(""));
        dispatch(_setExencionIVA(""));
        dispatch(_setRetencionIVA(""));
        dispatch(_setNotaCredito(""));
        dispatch(setNota(""));
        dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
        dispatch(setTipoPago(pago_total));
        dispatch(setSearch(""));
        dispatch(setCodigoNotaCredito(""));
        dispatch(setNotaCreditoCargada(null));
        dispatch(listarVentas());
        dispatch(setEstadoFormCliente(true));
    } else {
        total = total ? parseFloat(total) : 0;
        //  VALIDACION DE MONTO TOTAL
        let monto_valido = false;
        const total_pago =
            pagos.tarjeta +
            pagos.efectivo +
            pagos.cheque +
            pagos.deposito +
            pagos.retencion_iva +
            pagos.exencion_iva +
            pagos.nota_credito;
        if (tipo_pago == 100) {
            if (total_pago < total) {
                Swal(
                    "ERROR",
                    "Debe cancelar el monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        } else {
            const monto_a_pagar = total_pago;

            if (monto_a_pagar >= total) {
                Swal(
                    "ERROR",
                    "El anticipo debe ser menor al monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        }
        if (monto_valido) {
            if (conf_venta_redondeo) {
                // CALCULO DE RECIDUO PARA EL REDONDEO
                let total = 0;
                detalle_venta.forEach((producto) => {
                    if (!producto.es_vale) {
                        const precio = producto.precio
                            ? parseFloat(producto.precio)
                            : 0;
                        const cantidad = producto.cantidad
                            ? parseFloat(producto.cantidad)
                            : 0;
                        total = total + precio * cantidad;
                    } else {
                        total -= producto.total_importe;
                    }
                });
                const decimal = parseFloat((total % 1).toFixed(2));
                const moneda_mas_pequeno = 0.05;
                const cantidad_monedas = Math.floor(
                    decimal / moneda_mas_pequeno
                );
                const reciduo = parseFloat(
                    (decimal - cantidad_monedas * moneda_mas_pequeno).toFixed(2)
                );
                if (reciduo > 0 && !redondeo) {
                    //  REDONDEO HACIA ARRIBA O ABAJO
                    Swal({
                        title: "¿Cómo quieres redondear el total de la venta?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Hacia arriba",
                        cancelButtonText: "Hacia abajo",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.value) {
                            dispatch(setRedondeo("arriba"));
                        } else if ((result.dismiss = "cancel")) {
                            dispatch(setRedondeo("abajo"));
                        }
                    });
                } else {
                    dispatch(validarCliente());
                }
            } else {
                dispatch(validarCliente());
            }
        }
    }
};

const validarCliente = () => (dispatch, getStore) => {
    const store = getStore();
    //  VALIDAR CLIENTE
    const data_cliente = store.form.ClienteVentaForm.values;
    const errores = store.form.ClienteVentaForm.syncErrors
        ? store.form.ClienteVentaForm.syncErrors
        : false;

    function formatError(oracion) {
        return oracion.charAt(0).toUpperCase() + oracion.slice(1);
    }
    forEach(errores, (value, key) => {
        Swal("ERROR", formatError(`${key} no cumple con los requerimientos`), "error");
    });

    if (!errores) {
        if (data_cliente.nit != "") {
            if (data_cliente.nit == "CF" || data_cliente.nit == "cf") {
                // si ingreso CF en el campo nit
                const data_cliente_cf = {
                    nit: "C.F.",
                    nombre: data_cliente.nombre == "" ? "Consumidor Final" : data_cliente.nombre, //Guardar nombre del cliente si fue escrito
                    //Guardar direccion del cliente si fue escrita
                    direccion: data_cliente.direccion && data_cliente.direccion.replace(/\s+/g, '') != '' ? data_cliente.direccion : "Ciudad",
                };
                dispatch(registrarVenta(data_cliente_cf));
            } else {
                if (data_cliente.nombre == "") {
                    // si ingreso nit pero no esta el nombre presente en el formulario
                    Swal(
                        "Nombre del cliente",
                        "Por favor de ingresar el nombre del cliente.",
                        "error"
                    );
                } else {
                    // si ingreso nit y nombre
                    dispatch(registrarVenta(data_cliente));
                }
            }
        } else {
            if (data_cliente.nombre == "" && data_cliente.nit == "") {
                // SI no ingreso NIT ni nombre mostar modal
                Swal({
                    title: "¿Registrar venta con cliente Consumidor Final?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Sí",
                    cancelButtonText: "No",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.value) {
                        const data_cliente_cf = {
                            nit: "C.F.",
                            nombre: "Consumidor Final",
                            //Guardar direccion del cliente si fue escrita
                            direccion: data_cliente.direccion && data_cliente.direccion.replace(/\s+/g, '') != '' ? data_cliente.direccion : "Ciudad",
                        };
                        dispatch(registrarVenta(data_cliente_cf));
                    }
                });
            } else {
                // Si ingreso solo el nombre y no el nit
                const data_cliente_cf = {
                    nit: "C.F.",
                    nombre: data_cliente.nombre != "" ? data_cliente.nombre : 'Consumidor Final',
                    //Guardar direccion del cliente si fue escrita
                    direccion: data_cliente.direccion && data_cliente.direccion.replace(/\s+/g, '') != '' ? data_cliente.direccion : "Ciudad",
                };
                dispatch(registrarVenta(data_cliente_cf));
            }
        }
    }
};

const registrarVenta = (data_cliente) => (dispatch, getStore) => {
    const store = getStore();
    const form_cliente = store.form.ClienteVentaForm.values;
    const {
        efectivo,
        tarjeta,
        cheque,
        deposito,
        nota_credito,
        nota,
        tipo_pago,
        fecha_vencimiento,
        redondeo,
        venta,
        telefono_cliente,
        direccion_cliente,
        notas_cliente,
        nota_credito_cargada,
        retencion_iva,
        exencion_iva,
    } = store.punto_venta_caja;
    const productos = store.punto_venta_caja.detalle_venta;

    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };

    dispatch(setLoaderDetalle(true));

    //  SE CREA O SE OBTIENE AL CLIENTE Y LUEGO SE REGISTRA LA VENTA
    api.post(endpoint_clientes, data_cliente)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error al crear el cliente, por favor vuelve a intentar.",
                "error"
            );
            return null;
        })
        .then((data) => {
            if (data) {
                //  SE REGISTRA LA VENTA CON EL CLIENTE REGISTRADO
                const cliente = data.id;
                const params = {
                    productos,
                    cliente,
                    referencia: form_cliente.referencia
                        ? form_cliente.referencia
                        : "",
                    mostrar_referencia: form_cliente.mostrar_referencia
                        ? form_cliente.mostrar_referencia
                        : false,
                    sin_factura: form_cliente.sin_factura
                    ? form_cliente.sin_factura
                    : false,
                    pagos,
                    tipo_pago,
                    fecha_vencimiento,
                    redondeo,
                    telefono_cliente,
                    direccion_cliente,
                    notas_cliente,
                    codigo_nota_credito: nota_credito_cargada
                        ? nota_credito_cargada.nota_credito_fel.uuid
                        : null,
                };
                params.cotizacion = venta.id;

                api.post(`${endpoint_venta}/cotizacion`, params)
                    .catch((err) => {
                        if (err) {
                            Swal("ERROR", err.detail, "error");
                        } else {
                            Swal(
                                "ERROR",
                                "Ha ocurrido un error, espere un momento y vuelva a intentar",
                                "error"
                            );
                        }
                        dispatch(setLoaderDetalle(false));
                    })
                    .then((data) => {
                        if (data) {
                            if (data.id_factura) {
                                Swal(
                                    "¡Factura electrónica en proceso!",
                                    "Se esta generando la factura, espere por favor",
                                    "info"
                                );
                                dispatch(consultarEstadoFEL(data.id_factura));
                            } else {
                                dispatch(impresionTicketVenta(data));
                                dispatch(setLoaderDetalle(false));
                            }
                        }
                    });
            }
        });
};

const consultarEstadoFEL = (id_factura) => (dispatch) => {
    dispatch(setLoaderDetalle(true));
    api.get("factura_electronica/verificar_fel", {id_factura})
        .catch((err) => {
            Swal("ERROR", "Error al generar factura electronica", "error");
        })
        .then((resp) => {
            status = resp.estado_factura;
            if (status === "proceso") {
                setTimeout(() => {
                    dispatch(consultarEstadoFEL(id_factura));
                }, 1000);
            } else if (status === "facturado") {
                Swal(
                    "Éxito",
                    "Factura electrónica registrada correctamente",
                    "success"
                ).then(() => {
                    setTimeout(() => {
                        dispatch(impresionTicketVenta(resp.factura));
                    }, 500);
                });
                dispatch(setLoaderDetalle(false));
            } else {
                Swal("Error en facturación electronica", "error", "error");
                // dispatch(setLoaderDetalle(false))
            }
        });
};

const impresionTicketVenta = (movimiento) => (dispatch) => {
    dispatch(setLoaderDetalle(true));
    let data_movimiento = movimiento;
    const vuelto = data_movimiento.vuelto_efectivo
        ? data_movimiento.vuelto_efectivo
        : 0;
    data_movimiento.monto_efectivo = data_movimiento.monto_efectivo + vuelto;
    dispatch(setMovimiento(data_movimiento));
    dispatch(usuarioActions.getMe());
    dispatch(cajaActions.get_ultimo_cierre());
    api.get(endpoint_datos_impresion_venta, {id: movimiento.id_venta})
        .catch((err) => {
            dispatch(setVentaImpresion(null));
        })
        .then((resp) => {
            if (resp) {
                let data_impresion = resp;
                let vuelto = 0;
                if (data_impresion.length === undefined) {
                    vuelto = data_impresion.vuelto_efectivo
                        ? data_impresion.vuelto_efectivo
                        : 0;
                    data_impresion.monto_efectivo =
                        data_impresion.monto_efectivo + vuelto;
                }
                dispatch(setVentaImpresion(data_impresion));
                if (data_movimiento.tipo_pago == pago_total) {
                    dispatch(printActions.print("reciboVenta"));
                } else dispatch(printActions.print("reciboVenta"));
            }
        })
        .finally(() => {
            dispatch(setLoaderDetalle(false));
        });
};

export const eliminarVenta = (id) => (dispatch, getStore) => {
    Swal({
        title: "¿Eliminar Venta?",
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, eliminar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoaderDetalle(true));
            api.eliminar(`${endpoint}/${id}`)
                .then(
                    (response) => {
                        ToastStore.success("Venta eliminada exitosamente.");

                        dispatch(setLoaderDetalle(false));
                        dispatch(listarVentas());
                    },
                    (error) => {
                        Swal(
                            "¡Error!",
                            error.detail ||
                            "No se ha eliminado la venta, intenta más tarde",
                            "error"
                        ).then(() => {
                        });

                        dispatch(setLoaderDetalle(false));
                        dispatch(listarVentas());
                    }
                )
                .finally(() => {
                    dispatch(setLoaderDetalle(false));
                });
        }
    });
};

export const consultarNotaCredito = (total) => (dispatch, getStore) => {
    const store = getStore();
    const {codigo_nota_credito} = store.venta;

    api.get("notas_credito_fel/consultar", {codigo_nota_credito})
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
            dispatch(setNotaCreditoCargada(null));
        })
        .then((resp) => {
            if (resp) {
                //  Validar si se agrega o no la nota de crédito por el monto total y el total de importe
                if (resp.total_importe > total) {
                    Swal(
                        "Info",
                        `No es posible agregar la nota de crédito porque el total de la venta (${total}) es menor al monto de la nota de crédito (${resp.total_importe})`,
                        "info"
                    );
                } else {
                    dispatch(setNotaCreditoCargada(resp));
                    dispatch(setNotaCredito(resp.total_importe, total));
                }
            } else {
                dispatch(setNotaCreditoCargada(null));
            }
        });
};

export const limpiarNotaCredito = () => (dispatch) => {
    dispatch(_setNotaCredito(""));
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setTab = (tab) => ({
    type: TAB,
    tab,
});
export const setLoader = (loader) => ({
    type: SET_LOADER,
    loader,
});
export const setSearch = (search) => ({
    type: SET_SEARCH,
    search,
});
export const setPage = (page) => ({
    type: SET_PAGE,
    page,
});
export const setVentas = (data) => ({
    type: SET_VENTAS,
    data,
});
export const setVerBuscador = (ver_buscador) => ({
    type: SET_VER_BUSCADOR,
    ver_buscador,
});
export const setDetalle = (detalle_venta) => ({
    type: SET_DETALLE,
    detalle_venta,
});
export const setVenta = (venta) => ({
    type: SET_VENTA,
    venta,
});
export const setLoaderDetalle = (loader_detalle) => ({
    type: SET_LOADER_DETALLE,
    loader_detalle,
});
export const setMovimiento = (movimiento) => ({
    type: SET_MOVIMIENTO,
    movimiento,
});
export const setVerCobro = (ver_cobro) => ({
    type: SET_VER_COBRO,
    ver_cobro,
});
export const setTipoPago = (tipo_pago) => ({
    type: SET_TIPO_PAGO,
    tipo_pago,
});
export const setFechaVencimientoCredito = (fecha_vencimiento) => ({
    type: SET_FECHA_VENCIMIENTO_CREDITO,
    fecha_vencimiento,
});
export const _setEfectivo = (efectivo) => ({
    type: SET_EFECTIVO,
    efectivo,
});
export const _setTarjeta = (tarjeta) => ({
    type: SET_TARJETA,
    tarjeta,
});
export const _setCheque = (cheque) => ({
    type: SET_CHEQUE,
    cheque,
});
export const _setDeposito = (deposito) => ({
    type: SET_DEPOSITO,
    deposito,
});
export const _setNotaCredito = (nota_credito) => ({
    type: SET_NOTA_CREDITO,
    nota_credito,
});
export const setNota = (nota) => ({
    type: SET_NOTA,
    nota,
});
export const setNitCliente = (nit_cliente) => ({
    type: SET_NIT_CLIENTE,
    nit_cliente,
});
export const setNombreCliente = (nombre_cliente) => ({
    type: SET_NOMBRE_CLIENTE,
    nombre_cliente,
});
export const setTipoDocumentoCliente = (tipo_documento) => ({
    type: SET_TIPO_DOCUMENTO,
    tipo_documento,
});
export const setDireccionCliente = (direccion_cliente) => ({
    type: SET_DIRECCION_CLIENTE,
    direccion_cliente,
});
export const setTelefonoCliente = (telefono_cliente) => ({
    type: SET_TELEFONO_CLIENTE,
    telefono_cliente,
});
export const setNotasCliente = (notas_cliente) => ({
    type: SET_NOTAS_CLIENTE,
    notas_cliente,
});
export const setRedondeo = (redondeo) => ({
    type: SET_REDONDEO,
    redondeo,
});
export const setVentaImpresion = (venta_impresion) => ({
    type: SET_IMPRESION,
    venta_impresion,
});
export const setCodigoNotaCredito = (codigo_nota_credito) => ({
    type: SET_CODIGO_NOTA_CREDITO,
    codigo_nota_credito,
});
export const setCotizacionCargada = (cotizacion_cargada) => ({
    type: SET_COTIZACION_CARGADA,
    cotizacion_cargada,
});
export const setNotaCreditoCargada = (nota_credito_cargada) => ({
    type: SET_NOTA_CREDITO_CARGADA,
    nota_credito_cargada,
});
export const setEstadoFormCliente = (estado_form_cliente) => ({
    type: SET_ESTADO_FORM_CLIENTE,
    estado_form_cliente,
});

export const _setRetencionIVA = (retencion_iva) => ({
    type: SET_RETENCION_IVA,
    retencion_iva,
});
export const _setExencionIVA = (exencion_iva) => ({
    type: SET_EXENCION_IVA,
    exencion_iva,
});


const getFieldForm = (nameForm, field) => (dispatch, getStore) => {
    const form = getStore().form[nameForm].values
    const fieldForm = form[field];
    return fieldForm;
}

const setDataName = (data) => (dispatch, getState) => {
    let nameNew = data.first_name + " " + data.last_name;

    if (nameNew.trim() === "") {
        dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    }
    else {
        dispatch(changeForm("ClienteVentaForm", "nombre", nameNew));
    }
};

export const changeFieldForm = (field, value) => (dispatch) => {
    dispatch(changeForm("ClienteVentaForm", field, value));
}
export const limpiarDatosCliente2 = () => (dispatch) => {
    dispatch(setNitCliente(""));
    dispatch(setNombreCliente(""));
    dispatch(changeForm("ClienteVentaForm", "nit", ""));
    dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    dispatch(changeForm("ClienteVentaForm", "direccion", ""));
    dispatch(changeForm("ClienteVentaForm", "referencia", ""));
};

export const actions = {
    setTab,
    setNota,
    setCheque,
    setTarjeta,
    setDeposito,
    buscarVenta,
    cambioCobro,
    agregarVale,
    setTipoPago,
    setEfectivo,
    leerCliente,
    setRedondeo,
    listarVentas,
    eliminarVenta,
    cancelarVenta,
    quitarDetalle,
    cambioBuscador,
    changeDatosClientes,
    finalizarPagarVenta,
    cargarProductosVenta,
    setFechaVencimientoCredito,
    setNotaCredito,
    setCodigoNotaCredito,
    consultarNotaCredito,
    limpiarNotaCredito,
    setRetencionIva,
    setExencionIva,
    setEstadoFormCliente,
    getFieldForm,
    setDataName,
    changeFieldForm,
    limpiarDatosCliente2,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [TAB]: (state, {tab}) => {
        return {
            ...state,
            tab,
        };
    },
    [SET_LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_SEARCH]: (state, {search}) => {
        return {
            ...state,
            search,
        };
    },
    [SET_PAGE]: (state, {page}) => {
        return {
            ...state,
            page,
        };
    },
    [SET_VENTAS]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [SET_VER_BUSCADOR]: (state, {ver_buscador}) => {
        return {
            ...state,
            ver_buscador,
        };
    },
    [SET_DETALLE]: (state, {detalle_venta}) => {
        return {
            ...state,
            detalle_venta,
        };
    },
    [SET_VENTA]: (state, {venta}) => {
        return {
            ...state,
            venta,
        };
    },
    [SET_LOADER_DETALLE]: (state, {loader_detalle}) => {
        return {
            ...state,
            loader_detalle,
        };
    },
    [SET_MOVIMIENTO]: (state, {movimiento}) => {
        return {
            ...state,
            movimiento,
        };
    },
    [SET_VER_COBRO]: (state, {ver_cobro}) => {
        return {
            ...state,
            ver_cobro,
        };
    },
    [SET_TIPO_PAGO]: (state, {tipo_pago}) => {
        return {
            ...state,
            tipo_pago,
        };
    },
    [SET_FECHA_VENCIMIENTO_CREDITO]: (state, {fecha_vencimiento}) => {
        return {
            ...state,
            fecha_vencimiento,
        };
    },
    [SET_EFECTIVO]: (state, {efectivo}) => {
        return {
            ...state,
            efectivo,
        };
    },
    [SET_TARJETA]: (state, {tarjeta}) => {
        return {
            ...state,
            tarjeta,
        };
    },
    [SET_CHEQUE]: (state, {cheque}) => {
        return {
            ...state,
            cheque,
        };
    },
    [SET_DEPOSITO]: (state, {deposito}) => {
        return {
            ...state,
            deposito,
        };
    },
    [SET_RETENCION_IVA]: (state, {retencion_iva}) => {
        return {
            ...state,
            retencion_iva,
        };
    },
    [SET_EXENCION_IVA]: (state, {exencion_iva}) => {
        return {
            ...state,
            exencion_iva,
        };
    },
    [SET_NOTA_CREDITO]: (state, {nota_credito}) => {
        return {
            ...state,
            nota_credito,
        };
    },
    [SET_NOTA]: (state, {nota}) => {
        return {
            ...state,
            nota,
        };
    },
    [SET_NIT_CLIENTE]: (state, {nit_cliente}) => {
        return {
            ...state,
            nit_cliente,
        };
    },
    [SET_NOMBRE_CLIENTE]: (state, {nombre_cliente}) => {
        return {
            ...state,
            nombre_cliente,
        };
    },
    [SET_TIPO_DOCUMENTO]: (state, {tipo_documento}) => {
        return {
            ...state,
            tipo_documento,
        };
    },
    [SET_TELEFONO_CLIENTE]: (state, {telefono_cliente}) => {
        return {
            ...state,
            telefono_cliente,
        };
    },
    [SET_DIRECCION_CLIENTE]: (state, {direccion_cliente}) => {
        return {
            ...state,
            direccion_cliente,
        };
    },
    [SET_NOTAS_CLIENTE]: (state, {notas_cliente}) => {
        return {
            ...state,
            notas_cliente,
        };
    },
    [SET_REDONDEO]: (state, {redondeo}) => {
        return {
            ...state,
            redondeo,
        };
    },
    [SET_IMPRESION]: (state, {venta_impresion}) => {
        return {
            ...state,
            venta_impresion,
        };
    },
    [SET_CODIGO_NOTA_CREDITO]: (state, {codigo_nota_credito}) => {
        return {
            ...state,
            codigo_nota_credito,
        };
    },
    [SET_NOTA_CREDITO_CARGADA]: (state, {nota_credito_cargada}) => {
        return {
            ...state,
            nota_credito_cargada,
        };
    },
    [SET_ESTADO_FORM_CLIENTE]: (state, {estado_form_cliente}) => {
        return {
            ...state,
            estado_form_cliente,
        };
    },
};

// ------------------------------------
// Initial state
// ------------------------------------
export const initialState = {
    tab: 0,
    data: [],
    loader: false,
    page: 1,
    search: "",
    ver_buscador: false,
    detalle_venta: [],
    venta: null,
    loader_detalle: false,
    // Cobro
    movimiento: {},
    ver_cobro: false,
    tipo_pago: pago_total,
    fecha_vencimiento: moment().format("YYYY-MM-DD"),
    efectivo: "",
    tarjeta: "",
    cheque: "",
    deposito: "",
    nota_credito: "",
    nit_cliente: "",
    nombre_cliente: "",
    tipo_documento: "",
    telefono_cliente: "",
    direccion_cliente: "",
    notas_cliente: "",
    redondeo: null,
    venta_impresion: null,
    nota: "",
    codigo_nota_credito: "",
    nota_credito_cargada: null,
    estado_form_cliente: true,
    retencion_iva: "",
    exencion_iva: "",
};

export default handleActions(reducers, initialState);
