import React from 'react'
import { renderField } from "Utils/renderField/renderField"
import { validate, validators, validatorFromFunction } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form'
import LoadMask from "Utils/LoadMask/LoadMask";
import { icons } from "icons";

const Form = (props) => {
    // state
    // bind
    const { handleSubmit, loader } = props;

    return (
        <form name="loginForm" onSubmit={handleSubmit} className="form-validate mb-lg">
            <span>Escribe tu nueva contraseña</span>
            <div className="form-group has-feedback">
                <span className="fa fa-lock form-control-feedback text-muted text-muted-login" />
                <Field name="password" component={renderField} type="password" className="form-control form-control-login" label="Nueva contraseña"/>
            </div>
            <div className="form-group has-feedback">
                <span className="fa fa-lock form-control-feedback text-muted text-muted-login" />
                <Field name="confirm_password" component={renderField} type="password" className="form-control form-control-login" label="Confirmar contraseña"/>
            </div>
            <LoadMask loading={loader} light blur>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-row">
                        <button type="submit" className="btn btn-primary m-1 align-self-center">
                            Restablecer contraseña
                        </button>
                    </div>
                </div>
            </div>
            </LoadMask>
        </form>

    );
};

let ResetearForm = reduxForm({
    form: 'ResetearForm',
    validate: (data) => {
        const pass_confirmation = validatorFromFunction(() => (data.password === data.pass_confirmation));
        return validate(data, {
            password: validators.exists()("Este campo es requerido"),
            pass_confirmation: pass_confirmation()("Las contraseñas no coinciden"),
        })
    }
})(Form);

export default ResetearForm;
