import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/despacho/recepcion_despacho";
import CrearRecepcionDespacho from './CrearRecepcionDespacho';


const ms2p = (state) => {
    return {
        ...state.recepcion_despacho,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearRecepcionDespacho);
