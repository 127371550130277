import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import _, { times } from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { icons } from "icons";
import Recibo from "Utils/Recibo";
import Factura from "Utils/Factura";
import Modal from "react-responsive-modal";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import ReciboVentaCredito from "Utils/ReciboVentaCredito";
import { PrintContainer, PrintComponent } from "Utils/Print";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import ModalCobro from "../ModalCobro";
import {
    setMovimeintoVenta,
    setVentaImpresion,
} from "../../../../redux/modules/cuenta_cobrar/cuenta_cobrar";
import ModalAyuda from "Utils/Ayuda";
import { PVN } from "utility/variables";

class ListadoCuentaCobrar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            modalAyuda: false,
            itemsCuentaCobrar: [
                // {
                //     src: icons.cuenta_cobrar_p1,
                //     alt: "Paso 1"
                // },
                {
                    src: icons.cuenta_cobrar_p2,
                    alt: "Paso 1",
                },
                {
                    src: icons.cuenta_cobrar_p3,
                    alt: "Paso 2",
                },
                {
                    src: icons.cuenta_cobrar_p4,
                    alt: "Paso 3",
                },
                {
                    src: icons.cuenta_cobrar_p5,
                    alt: "Paso 4",
                },
            ],
        };
        this.closeModal = this.closeModal.bind(this);
        this.submit = this.submit.bind(this);
    }
    closeModal() {
        this.setState({ open: false });
        this.props.limpiarFormAbono();
    }
    submit(data) {
        data.codigo_venta = this.props.codigo_venta ? this.props.codigo_venta : ""
        if (
            data.efectivo > 0 ||
            data.tarjeta > 0 ||
            data.cheque > 0 ||
            data.deposito > 0 ||
            data.retencion_iva > 0 ||
            data.exencion_iva > 0 ||
            data.nota_credito > 0
        ) {
            this.props.realizarAbono(data);
            // this.setState({open: false});
        }
    }

    componentWillMount() {
        this.props.listar();
        this.props.setMovimeintoVenta(null);
    }

    componentDidMount() {}

    closeModalAyuda = () => {
        this.setState({ modalAyuda: false });
    };
    openModalAyuda = () => {
        this.setState({ modalAyuda: true });
    };

    render() {
        // state
        const {
            data,
            loader,
            factura_personalizada,
            formato_factura,
            venta_impresion,
            movimiento_venta,
            marcar_entrega,
            tipo_punto_venta,
            print_state,
            utiliza_fel,
            //  Nota credito
            nota_credito_cargada,
            codigo_nota_credito,
        } = this.props;
        const { modalAyuda, itemsCuentaCobrar } = this.state;

        // bind
        const {
            getMovimientoVenta,
            seleccionImpresionReciboFactura,
            marcarEntrega,
            regresar,
            limpiarNotaCredito,
            consultarNotaCredito,
            setCodigoNotaCredito,
        } = this.props;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        const formatoAcciones = (cell, row) => {
            return (
                <div>
                    {print_state.flag && <PrintComponent />}
                    {row.movimiento && (
                        <div className="d-flex flex-row align-items-center">
                            <img
                                onClick={() => {
                                    getMovimientoVenta(row.movimiento.id);
                                }}
                                className="action-img"
                                title="Visualizar"
                                src={icons.ver}
                                alt="Visualizar"
                            />
                            {row.venta_data && (
                                <img
                                    onClick={() => {
                                        seleccionImpresionReciboFactura(
                                            row.venta_data.id,
                                            "reporteVentasReimpresion"
                                        );
                                    }}
                                    className="action-img"
                                    title={`Imprimir ${
                                        factura_personalizada
                                            ? "Factura"
                                            : "Recibo"
                                    }`}
                                    src={icons.imprimir}
                                    alt="Imprimir"
                                />
                            )}
                            {marcar_entrega && !row.venta_data.entregado && (
                                <img
                                    onClick={() => {
                                        marcarEntrega(row.venta_data.id);
                                    }}
                                    className="action-img"
                                    title="Marcar entrega"
                                    src={icons.dar_credito_a}
                                    alt="Marcar Entrega"
                                />
                            )}
                        </div>
                    )}
                </div>
            );
        };

        return (
            <div>
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <Link
                            className="btn btn-tertiary mt-2"
                            to={
                                tipo_punto_venta == PVN
                                    ? "/punto_de_venta"
                                    : "/punto_de_venta/caja"
                            }
                        >
                            <em className="fa fa-arrow-left"></em> Regresar
                        </Link>
                        <div className="grid-container">
                            <div className="grid-titulo">
                                <Toolbar
                                    titulo={"Listado de montos pendientes"}
                                    buscar={this.props.buscar}
                                    search={this.props.search}
                                    actionAyuda={this.openModalAyuda}
                                />
                            </div>
                            <LoadMask loading={loader} dark blur>
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                    bodyContainerClass="container-cuentas"
                                >
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="creado"
                                        dataFormat={(cell) => (
                                            <RenderDateTime fecha={cell} />
                                        )}
                                        dataSort
                                    >
                                        Fecha
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="cliente_proveedor__nombre"
                                        dataFormat={(cell, row) => (
                                            <div>
                                                {row.cliente_proveedor.nombre}
                                            </div>
                                        )}
                                        dataSort
                                    >
                                        Nombre
                                    </TableHeaderColumn>
                                    {marcar_entrega && (
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataField="entrego"
                                            dataFormat={(cell, row) => (
                                                <div
                                                    className={
                                                        row.venta_data.entregado
                                                            ? ""
                                                            : "text-danger"
                                                    }
                                                >
                                                    {row.venta_data
                                                        .entregado ? (
                                                        <div className="text-success">
                                                            {cell
                                                                ? "Entregado por"
                                                                : "Entregado"}{" "}
                                                            <span> {cell}</span>
                                                        </div>
                                                    ) : (
                                                        "Pendiente"
                                                    )}
                                                </div>
                                            )}
                                            dataSort
                                        >
                                            Pedido entregado
                                        </TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="movimiento__venta__codigo"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <div>
                                                    {row.venta_data
                                                        ? row.venta_data.codigo
                                                        : "----"}
                                                </div>
                                            );
                                        }}
                                    >
                                        Código de venta
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="total"
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Monto total
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="saldo"
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Monto pendiente
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataField='concepto'>Concepto</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey
                                        dataAlign="left"
                                        dataFormat={formatoAcciones}
                                    >
                                        Acciones
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className={`w100 container-vista-previa`}>
                            <div className="d-flex flex-row flex-xs-column justify-content-between align-items-center container-vista-previa-head">
                                <b
                                    className="negro-dark2 pl-3"
                                    style={{ fontSize: "23px" }}
                                >
                                    Vista previa
                                </b>
                                {movimiento_venta && (
                                    <div className="text-right flex1 mr-4">
                                        <button
                                            onClick={() => {
                                                this.setState({ open: true });
                                            }}
                                            className="btn btn-secondary mt-lg"
                                            type={"button"}
                                        >
                                            <img
                                                className="icon-img"
                                                src={icons.pagos_blanco}
                                                alt="pago_cuenta_cobrar"
                                            />{" "}
                                            Cobrar
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="container-vista-previa-body">
                                <LoadMask loading={loader} dark blur>
                                    <div className="d-flex flex-row justify-content-center w100">
                                        {movimiento_venta ? (
                                            <PrintContainer name="reciboVentaCredito">
                                                <ReciboVentaCredito
                                                    sucursal={
                                                        movimiento_venta.sucursal
                                                    }
                                                    movimiento={
                                                        movimiento_venta
                                                    }
                                                />
                                            </PrintContainer>
                                        ) : (
                                            <span style={{ marginTop: "50%" }}>
                                                Seleccione una cuenta para
                                                visualizarlo.
                                            </span>
                                        )}
                                    </div>
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RECIBO Y FACTURA */}
                <div className="row">
                    {!factura_personalizada ? (
                        <div className="w100 print-only">
                            <PrintContainer name="reporteVentasReimpresion">
                                {venta_impresion && (
                                    <Recibo
                                        sucursal={venta_impresion.sucursal}
                                        movimiento={venta_impresion}
                                        usuario={
                                            venta_impresion.usuario.first_name
                                                ? venta_impresion.usuario
                                                      .first_name
                                                : ""
                                        }
                                        footerText={"TOTAL"}
                                        tipo={"Venta"}
                                        utiliza_fel={utiliza_fel}
                                    />
                                )}
                            </PrintContainer>
                        </div>
                    ) : (
                        <div className="w100 print-only">
                            <PrintContainer
                                name="reporteVentasReimpresion"
                                className="impresion-venta d-table w-100"
                            >
                                {venta_impresion &&
                                    venta_impresion.map((factura, index) => {
                                        return (
                                            <Factura
                                                className={`${
                                                    index != 0
                                                        ? "salto-pagina"
                                                        : ""
                                                }`}
                                                key={index}
                                                datos_venta={factura}
                                                formato_factura={
                                                    formato_factura
                                                }
                                            />
                                        );
                                    })}
                            </PrintContainer>
                        </div>
                    )}
                </div>
                <Modal
                    open={this.state.open}
                    onClose={this.closeModal}
                    classNames={{ modal: "modal-sm" }}
                >
                    <div style={{ padding: "1.2rem", maxWidth: "450px" }}>
                        <div className="row">
                            <div className="col-12 grid-titulo">
                                Monto pendiente
                            </div>
                            <ModalCobro
                                styles={{ modal: { minWidth: "100%" } }}
                                utiliza_fel={utiliza_fel}
                                codigo_nota_credito={codigo_nota_credito}
                                nota_credito_cargada={nota_credito_cargada}
                                total=""
                                consultarNotaCredito={consultarNotaCredito}
                                setCodigoNotaCredito={setCodigoNotaCredito}
                                limpiarNotaCredito={limpiarNotaCredito}
                                onSubmit={this.submit}
                                close={this.closeModal}
                                tipo={this.state.tipo_movimiento}
                                pendiente={this.props.pendiente}
                                saldo_inicial={this.props.saldo_inicial}
                                loader={loader}
                            />
                        </div>
                    </div>
                </Modal>
                <ModalAyuda
                    open={modalAyuda}
                    closeModal={this.closeModalAyuda}
                    openModal={this.openModalAyuda}
                    items={itemsCuentaCobrar}
                />
            </div>
        );
    }
}

ListadoCuentaCobrar.propTypes = {
    data: PropTypes.object.isRequired,
    loader: PropTypes.bool.isRequired,
    buscar: PropTypes.func.isRequired,
    sortChange: PropTypes.func.isRequired,
    listar: PropTypes.func.isRequired,
};

export default ListadoCuentaCobrar;
