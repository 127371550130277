import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/reportes/mejores_clientes";
import MejoresClientes from './MejoresClientes';


const ms2p = (state) => {
    return {
        ...state.mejores_clientes,
        pais: state.usuario.me.pais
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(MejoresClientes);
