import React, {Component} from "react";
import { Async } from 'react-select';
import { api } from "api";


class SeleccionSucursal extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const getSucursales = (search) => {
            return api.get("sucursales", {search}).catch(() => {}).then((data) => {
                return {options: data.results}
            })
        };

        return (
            <div>
                <Async className="form-control"
                    placeholder="Seleccionar"
                    onChange={(e) => { this.props.setSucursal(e.id); }}
                    searchPromptText="Escriba para buscar" valueKey={"id"} labelKey={"nombre"}
                    loadOptions={getSucursales} />
            </div>
        );
    }
}

export default SeleccionSucursal;
