import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/despacho/despacho";
import DetalleDespacho from './DetalleDespacho';


const ms2p = (state) => {
    return {
        ...state.despacho,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(DetalleDespacho);
