import _ from "lodash";
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderSelectField } from "Utils/renderField/renderField";

// VALIDACIONES CUSTOMS
const validate = (values, props) => {
    const errors = {}
    if (!values.sucursal)
        errors.sucursal = "Campo requerido"
    return errors;
}
export const listaSucursales = [];
const Form = (props) => {
    const { handleSubmit, close, loader, sucursales, mostrar_info } = props;

    const getSucursales = (search) => {
        const result = _.filter(sucursales, function(i){
            return i.nombre.toLocaleLowerCase().container(search.toLocaleLowerCase()) == true
        })
        result.forEach((item) => {
            if (!_.find(listaSucursales, {id: item.id}))
                listaSucursales.push(item)
        });
        return { options: listaSucursales }
    };

    return (
        <form onSubmit={handleSubmit} className="col-sm-12 pt-2">
            <div className="form-group">
                <div className="row">
                    <div className="w-100 form-group">
                        <label htmlFor="nota">Seleccione la sucursal</label>
                        <Field
                            name="sucursal"
                            component={renderSelectField}
                            options={sucursales}
                            className="form-control"
                            item_vacio={true}
                        />
                    </div>
                    {
                        mostrar_info && (
                            <small className="text-info mb-2" style={{fontSize: "13px"}}><i className="fa fa-info-circle mr-1"></i> La orden no esta asignada a una sucursal, para darle seguimiento tiene que asignarla a una</small>
                        )
                    }
                </div>
                <div className="d-flex justify-content-center flex-row">
                    <button type="button" className="btn btn-tertiary m-1 align-self-center" onClick={(e) => {
                        e.stopPropagation();
                        close({});
                    }}>Cancelar</button>
                    <button type="submit" className="btn btn-primary m-1 align-self-center" disabled={loader}>Confirmar</button>
                </div>
            </div>
        </form>
    )
}

const AsignarSucursalForm = reduxForm({
    // a unique name for the form
    form: 'AsignarSucursalForm',
    validate
})(Form)

export default AsignarSucursalForm;