import './editar_orden_online'
import React, { Component } from 'react';
import LoadMask from "Utils/LoadMask/LoadMask";
import { ListadoCards } from "Utils/ListadoCards";
import { ListadoAccion } from "Utils/ListadoAccion";
import { SeleccionSucursal } from "Utils/SeleccionSucursal";
import { formatoAcciones, formatoPrecio, DetalleOrdenOnline } from './CamposEditarOrdenOnline'
import {
    formatoNombre,
    buscar,
    formatoAvatar,
    formatoMarca,
} from "Utils/CamposComunes";

class EditarOrdenOnline extends Component {
    constructor(props) {
        super(props)
        this.state = {
            desplegar: 'productos', // Valores productos o metodos (pago o entrega)
        }
    }

    componentWillMount() {
        this.props.setNavbarCollapse(true);
        const idOrden = this.props.match.params.id;
        this.props.cargarOrden(idOrden, true);
    }

    changeDesplegar = (desplegar) => {
        this.setState({desplegar})
    }

    isMobile(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
            return true
        return false
    }

    render() {
        //  Action
        const { sucursal, ver_buscador, loader, data_productos, search_productos, page_productos, detalle, seleccionados, metodos_pago, metodos_entrega, total, total_envio } = this.props;
        //  State
        const { desplegar } = this.state;
        //  Bind
        const { buscarProducto, listarProducto, sortChangeProducto, quitar, restar, sumar, ingresoCantidad, agregar, regresar, editarOrden, cambioBuscador } = this.props;

        const ver_cobro = false;
        /* region SELECCION DE SUCURSAL */
        if (sucursal === undefined) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo padding-5">
                                Seleccione una sucursal
                            </div>
                            <div className="padding-15">
                                <SeleccionSucursal />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        /* endregion SELECCION DE SUCURSAL */


        const llavesListado = [
            { nombre: "producto_imagen", sort: false, caption: "", format: formatoAvatar, align: "center", width:"10%" },
            { nombre: "producto__nombre", sort: true, caption: "Nombre", format: formatoNombre, align: "left", width: "45%" },
            { nombre: "producto__marca", sort: true, caption: "Marca", format: formatoMarca, align: "left", width:"15%" },
            { nombre: "precio_online", sort: false, caption: "Precio", format: formatoPrecio, align: "right", width:"15%"},
            { nombre: "acciones", sort: false, caption: "Acciones", format: formatoAcciones({agregar, cambioBuscador}), align: "center", width:"15%" },
        ];

        return (
            <div className="form-group mt d-flex">
                <div className="slide-container row w-100" style={{ margin: "0" }}>
                    {/* CARD PARA LA VISTA MOVIL */}
                    <div className={`d-block d-lg-none col-sm-12 ${ver_buscador ? "form-activo" : ""} ${!ver_cobro ? "slide": ""}`}>
                        {
                            (ver_buscador) && (<ListadoCards
                                buscar={buscar({search: search_productos, buscar: buscarProducto})}
                                loader={loader}
                                forzar_agregar={true}
                                data={data_productos}
                                listar={listarProducto}
                                sortChange={sortChangeProducto}
                                titulo={"Listado de Productos"}
                                page={page_productos}
                                agregar={agregar}
                                cambioBuscador={cambioBuscador}
                            />)
                        }
                    </div>
                    {/* FIN CARD PARA LA VISTA MOVIL*/}
                    <div className={`d-none d-lg-block col-md-12 col-lg-7  ${ver_buscador ? "form-activo" : ""} ${!ver_cobro ? "slide": ""}`}>
                        <ListadoAccion
                            buscar={buscar({search: search_productos, buscar: buscarProducto})}
                            loader={loader}
                            llaves={llavesListado}
                            data={data_productos}
                            listar={listarProducto}
                            sortChange={sortChangeProducto}
                            titulo={"Listado de Productos"}
                            page={page_productos}
                        />

                    </div>

                    <div className={`col-md-12 col-lg-5 ${!ver_buscador ? "form-activo" : ""} ${!ver_cobro ? "form-trasero":"venta-mt-4"}`}>
                        <LoadMask loading={loader} light blur radius>
                            <div className="w-100">
                                <DetalleOrdenOnline
                                    total={total}
                                    total_envio={total_envio}
                                    orden={detalle}
                                    seleccionados={seleccionados}
                                    metodos_pago={metodos_pago}
                                    metodos_entrega={metodos_entrega}
                                    desplegar={desplegar}
                                    changeDesplegar={this.changeDesplegar}
                                    quitar={quitar}
                                    restar={restar}
                                    sumar={sumar}
                                    ingresoCantidad={ingresoCantidad}
                                    isMobile={this.isMobile}
                                    regresar={regresar}
                                    editarOrden={editarOrden}
                                    cambioBuscador={cambioBuscador}/>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditarOrdenOnline;
