import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { ToastStore } from "react-toasts";

// ------------------------------------
// CONSTANTS
// ------------------------------------

const endpoint = "ventas/reporte_ganacia_bruta";
const endpointExcel = "ventas/descargar_excel_reporte_ganancia_bruta";

export const DATA = "GANANCIA_BRUTA_DATA";
export const LOADER = "GANANCIA_BRUTA_LOADER";
export const PAGE = "GANANCIA_BRUTA_PAGE";
export const FECHA_INICIAL = "GANANCIA_BRUTA_FECHA_INICIAL";
export const FECHA_FINAL = "GANANCIA_BRUTA_FECHA_FINAL";
export const SORT = "GANANCIA_BRUTA_SORT";
export const ESTADO_DESCARGA = "GANANCIA_BRUTA_ESTADO_DESCARGA";
export const BUSCADOR = "GANANCIA_BRUTA_BUSCADOR";

// ------------------------------------
// ACTIONS
// ------------------------------------

export const getListGanancia = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { fecha_inicial, fecha_final, sort, search } = store.ganancia_bruta;
    api.get(endpoint, { fecha_inicial, fecha_final, sort, page, search })
        .then((response) => {
            if (response) {
                dispatch(setData(response));
                dispatch(setPage(page));
            }
        })
        .catch((error) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const ganancia_bruta = store.ganancia_bruta;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(ganancia_bruta.fecha_final);
        if (fecha_final >= fecha_inicial) dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(ganancia_bruta.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(getListGanancia());
    // dispatch(dataGraficaProdMasVendidos());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    dispatch(getListGanancia());
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const fecha_inicial = store.ganancia_bruta.fecha_inicial;
    const fecha_final = store.ganancia_bruta.fecha_final;
    const sort = store.ganancia_bruta.sort;
    const params = {
        fecha_inicial,
        fecha_final,
        sort,
    };

    dispatch(setEstadoDescarga(true));
    api.get(endpointExcel, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Ganancia_bruta.xlsx";
                    const context = {
                        name: nombre,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(getListGanancia());
};

// ------------------------------------
// PURE ACTIONS
// ------------------------------------
export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setSort = (sort) => ({
    type: SORT,
    sort,
});
const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const actions = {
    getListGanancia,
    setFecha,
    sortChange,
    descargarListado,
    buscar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [SORT]: (state, { sort }) => {
        return {
            ...state,
            sort,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {
        results: [],
        total_invertido: 0,
    },
    loader: false,
    page: 1,
    fecha_inicial: moment().format("YYYY-M-DD"),
    fecha_final: moment().format("YYYY-M-DD"),
    sort: "fraccion__producto__nombre",
    estado_descarga: false,
    search: "",
};

export default handleActions(reducers, initialState);
