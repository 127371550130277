import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderDate,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { pago_total } from "utility/variables";
export default class Recibo extends Component {
    static propTypes = {
        sucursal: PropTypes.object.isRequired,
        movimiento: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const { sucursal, movimiento, utiliza_fel } = this.props;
        const { cuenta_cobrarpagar } = movimiento;

        const detalles = movimiento.detalles ? movimiento.detalles : [];

        const tiene_abonos =
            _.filter(cuenta_cobrarpagar.abonos, (abono) => {
                return !abono.anulado;
            }).length > 0
                ? true
                : false;

        return (
            <div className="print-shadow vista-recibo-80mm">
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-16">
                    {utiliza_fel ? (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {sucursal.datos_empresa
                                    ? sucursal.datos_empresa.nombre_comercial
                                    : ""}
                            </b>
                        </div>
                    ) : (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {sucursal.datos_empresa
                                    ? sucursal.datos_empresa.nombre
                                    : ""}
                            </b>
                        </div>
                    )}
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.nombre}</b>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.direccion}</b>
                    </div>
                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                Tel. {sucursal.telefono}
                            </b>
                        </div>
                    )}
                </div>
                <div className="mt mb d-flex flex-row justify-content-center fnt-14">
                    <span>RECIBO DE CAJA</span>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-column">
                        <b>Código de venta</b>
                        {movimiento.codigo_venta ? movimiento.codigo_venta : ""}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Fecha y hora</b>
                        <RenderDateTime value={movimiento.creado} />
                    </div>
                </div>
                <div
                    className="d-flex flex-row fnt-12 pb-05 mb-2"
                    style={{ borderBottom: "dotted 1px" }}
                >
                    {movimiento.usuario ? (
                        <div className="flex1 d-flex flex-column">
                            <b>Usuario</b>
                            {movimiento.usuario.first_name
                                ? movimiento.usuario.first_name
                                : "-----"}
                        </div>
                    ) : null}
                </div>
                <div
                    className="d-flex flex-column fnt-12 pb-05 mb-2"
                    style={{ borderBottom: "dotted 1px" }}
                >
                    <div className="flex1 d-flex flex-row">
                        <div className="flex2 d-flex flex-column">
                            <b>Nombre</b>
                            {movimiento.cliente_proveedor
                                ? movimiento.cliente_proveedor.nombre
                                    ? movimiento.cliente_proveedor.nombre
                                    : "-----"
                                : "-----"}
                        </div>
                        <div className="flex1 d-flex flex-column">
                            <b>NIT</b>
                            {movimiento.cliente_proveedor
                                ? movimiento.cliente_proveedor.nit
                                    ? movimiento.cliente_proveedor.nit
                                    : movimiento.cliente_proveedor.nombre ==
                                      "Consumidor Final"
                                    ? "CF"
                                    : "CF"
                                : "-----"}
                        </div>
                    </div>
                    <div className="flex1 d-flex flex-row">
                        <b>Fecha de vencimiento: &nbsp;</b>
                        {cuenta_cobrarpagar ? (
                            <RenderDate
                                value={cuenta_cobrarpagar.fecha_vencimiento}
                            />
                        ) : (
                            "-----"
                        )}
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between fnt-16 mb-3">
                    <b>ARTÍCULOS</b>
                    <b>SUB TOTAL</b>
                </div>

                {detalles.map((detalle) => {
                    return (
                        <div className="d-flex mb fnt-11" key={detalle.id}>
                            {!detalle.es_vale ? (
                                <div className="width100">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row">
                                            {`
                                                    ${
                                                        detalle.fraccion
                                                            .codigo_barras
                                                            ? `${detalle.fraccion.codigo_barras} / `
                                                            : ""
                                                    }
                                                    ${detalle.producto} / ${
                                                detalle.fraccion.producto
                                                    .a_granel && detalle.unidad_de_medida
                                                    ? detalle.unidad_de_medida
                                                          .nombre
                                                    : detalle.fraccion.nombre
                                            }
                                                    `}
                                        </div>
                                        <div className="d-flex flex-row">
                                            <RenderCurrency
                                                value={
                                                    detalle.precio_original &&
                                                    detalle.desc_porcentaje
                                                        ? detalle.precio_original
                                                        : detalle.precio
                                                }
                                                className={"mr-1"}
                                            />
                                            <div className="mr-1">X</div>
                                            {detalle.fraccion.producto
                                                .a_granel ? (
                                                <RenderNumber
                                                    value={detalle.cantidad}
                                                    decimalScale={3}
                                                    className={"mr-1"}
                                                />
                                            ) : (
                                                <RenderNumber
                                                    value={detalle.cantidad}
                                                    className={"mr-1"}
                                                />
                                            )}
                                            {detalle.precio_original &&
                                            detalle.desc_porcentaje ? (
                                                <RenderCurrency
                                                    value={
                                                        detalle.cantidad *
                                                        detalle.precio_original
                                                    }
                                                    className={"ml-auto"}
                                                />
                                            ) : (
                                                <RenderCurrency
                                                    value={detalle.sub_total}
                                                    className={"ml-auto"}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {/* region DESCUENTOS SOBRE CADA PRODUCTO */}
                                    {detalle.precio_original &&
                                        detalle.desc_porcentaje > 0 && (
                                            <div className="d-flex flex-column">
                                                <div className="d-flex flex-row pl-20">
                                                    Descuento (
                                                    {detalle.desc_porcentaje}%)
                                                </div>
                                                <div className="d-flex flex-row pl-20">
                                                    <span className="mr-1">
                                                        -{" "}
                                                        <RenderCurrency
                                                            value={
                                                                detalle.desc_monto
                                                            }
                                                        />
                                                    </span>
                                                    <div className="mr-1">
                                                        X
                                                    </div>
                                                    {detalle.fraccion.producto
                                                        .a_granel ? (
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                            decimalScale={3}
                                                            className={"mr-1"}
                                                        />
                                                    ) : (
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                            className={"mr-1"}
                                                        />
                                                    )}
                                                    <span className="ml-auto">
                                                        -{" "}
                                                        <RenderCurrency
                                                            value={
                                                                detalle.cantidad *
                                                                detalle.desc_monto
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    {/* endregion DESCUENTOS SOBRE CADA PRODUCTO */}
                                </div>
                            ) : (
                                <div className="width100">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row">
                                            Vale de descuento{" "}
                                            {detalle.vale.codigo}
                                        </div>
                                        <div className="d-flex flex-row">
                                            <RenderCurrency
                                                value={detalle.sub_total * -1}
                                            />{" "}
                                            X 1
                                            <RenderCurrency
                                                value={detalle.sub_total * -1}
                                                className={"ml-auto"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}

                {movimiento.reciduo_redondeo != 0 && (
                    <div className="width100 mb fnt-11">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex3 flex-row">
                                Redondeo
                            </div>
                            <div className="d-flex flex1 flex-row align-items-center justify-content-end">
                                <RenderCurrency
                                    value={movimiento.reciduo_redondeo}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className="pt-05 d-flex flex-row justify-content-between fnt-14"
                    style={{ borderTop: "dotted 1px" }}
                >
                    <b>TOTAL DE VENTA</b>
                    <b>
                        <RenderCurrency
                            value={movimiento.total_cobrar_pagar}
                            className={"ml-auto"}
                        />
                    </b>
                </div>
                <div className="d-flex flex-row justify-content-between fnt-14">
                    <span>ANTICIPO EN EFECTIVO</span>
                    <RenderCurrency
                        value={movimiento.monto_efectivo}
                        className={"ml-auto"}
                    />
                </div>
                <div className="d-flex flex-row justify-content-between fnt-14">
                    <span>ANTICIPO EN TARJETA</span>
                    <RenderCurrency
                        value={movimiento.monto_tarjeta}
                        className={"ml-auto"}
                    />
                </div>
                <div className="d-flex flex-row justify-content-between fnt-14">
                    <span>ANTICIPO EN CHEQUE</span>
                    <RenderCurrency
                        value={movimiento.monto_cheque}
                        className={"ml-auto"}
                    />
                </div>
                <div
                    className="d-flex flex-row justify-content-between fnt-14 pb-05 mb-2"
                    style={{ borderBottom: "dotted 1px" }}
                >
                    <span>ANTICIPO EN DEPÓSITO</span>
                    <RenderCurrency
                        value={movimiento.monto_deposito}
                        className={"ml-auto"}
                    />
                </div>

                <div className="pt-05 d-flex flex-row justify-content-between fnt-14">
                    <b>SALDO AL CRÉDITO</b>
                    <b>
                        <RenderCurrency
                            value={
                                cuenta_cobrarpagar
                                    ? cuenta_cobrarpagar.saldo_inicial
                                    : 0
                            }
                            className={"ml-auto"}
                        />
                    </b>
                </div>
                {tiene_abonos && (
                    <div className="pt-05 d-flex flex-row justify-content-between fnt-14">
                        <b>ABONOS:</b>
                    </div>
                )}
                {/* BONOS */}
                {cuenta_cobrarpagar.abonos.map((bono, index) => {
                    return (
                        <div className="w-100" key={index}>
                            {!bono.anulado && (
                                <div className="d-flex flex-row justify-content-between fnt-14 pb-05 mb-2">
                                    <b>
                                        <RenderDate value={bono.creado} />
                                    </b>
                                    <RenderCurrency
                                        value={bono.total}
                                        className={"ml-auto"}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
                {tiene_abonos && (
                    <div
                        className="pt-05 d-flex flex-row justify-content-between fnt-14"
                        style={{ borderTop: "dotted 1px" }}
                    >
                        <b>SALDO</b>
                        <b>
                            <RenderCurrency
                                value={cuenta_cobrarpagar.saldo}
                                className={"ml-auto"}
                            />
                        </b>
                    </div>
                )}
                {/* <div className="d-flex flex-column fnt-14 align-items-center text-center mt-5 mb-2">
                    <span>ESTE DOCUMENTO</span>
                    <span>NO ES UNA FACTURA</span>
                </div> */}
            </div>
        );
    }
}
