import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/bodega/bodega";
import { actions as reporteActions } from "../../../../../redux/modules/bodega/reporteIngreso";
import { print } from "../../../../../redux/modules/print/print";
import ReporteIngreso from "./ReporteIngreso";

const ms2p = (state) => {
    return {
        ...state.bodega,
        sucursal: state.usuario.sucursal,
        print_state: state.print,
        conf_mostrar_precio_impresion:
            state.usuario.conf_mostrar_precio_impresion,
        conf_habilitar_no_factura: state.usuario.conf_habilitar_no_factura
    };
};

const md2p = {
    ...actions,
    ...reporteActions,
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(ms2p, md2p)(ReporteIngreso);
