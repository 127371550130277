import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastStore } from "react-toasts";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from "react-accessible-accordion";
import { niveles_usuario } from "variables";
import "./accordion.css";
import "./menu.css";
import { icons } from "icons";
import Modal from "react-responsive-modal";
import Bienvenida from "Utils/Bienvenida";
import { PVN } from "utility/variables";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { ver_sucursal: false, open: false };
        this.toggleSucursal = this.toggleSucursal.bind(this);
    }
    componentWillMount() {
        this.logOut = this.logOut.bind(this);
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.url !== nextProps.url) {
            this.props.closeMenu();
        }
        if (nextProps.bienvenida_visto !== this.props.bienvenida_visto) {
            if (this.props.vista_bienvenida) {
                this.setState({ open: !nextProps.bienvenida_visto });
            }
        }
    }
    closeModal = () => {
        this.setState({ open: false });
    };
    openModal = () => {
        this.setState({ open: true });
    };
    marcarVisto = () => {
        this.closeModal();
        if (this.props.bienvenida_visto != true)
            this.props.marcarVistoBienvenida();
    };

    mostrarItem = (item) => {
        const { usuario } = this.props;
        let entra = false;
        switch (item) {
            case "dashboard":
                entra = usuario.dashboard;
                break;
            case "bodega":
                entra = usuario.bodega;
                break;
            case "punto_venta":
                entra = usuario.punto_venta;
                break;
            case "punto_venta_mostrador_caja":
                entra = usuario.punto_venta_mostrador_caja;
                break;
            case "punto_venta_mostrador":
                entra = usuario.punto_venta_mostrador;
                break;
            case "punto_venta_caja":
                entra = usuario.punto_venta_caja;
                break;
            case "producto":
                entra = usuario.producto;
                break;
            case "gasto":
                entra = usuario.gasto;
                break;
            case "reporte":
                entra = usuario.reporte;
                break;
            case "reporte_balance_general":
                entra = usuario.reporte_balance_general;
                break;
            case "reporte_apertura_cierre":
                entra = usuario.reporte_apertura_cierre;
                break;
            case "reporte_bodega":
                entra = usuario.reporte_bodega;
                break;
            case "reporte_venta":
                entra = usuario.reporte_venta;
                break;
            case "reporte_devolucion":
                entra = usuario.reporte_devolucion;
                break;
            case "reporte_cuenta_cobrar":
                entra = usuario.reporte_cuenta_cobrar;
                break;
            case "reporte_gasto":
                entra = usuario.reporte_gasto;
                break;
            case "reporte_productos_mas_vendidos":
                entra = usuario.reporte_productos_mas_vendidos;
                break;
            case "reporte_total_invertido":
                entra = usuario.reporte_total_invertido;
                break;
            case "reporte_ganancia_bruta":
                entra = usuario.reporte_ganancia_bruta;
                break;
            case "reporte_mejores_clientes":
                entra = usuario.reporte_mejores_clientes;
                break;
            case "reporte_despachos":
                entra = usuario.reporte_despachos;
                break;
            case "reporte_historial_precios":
                entra = usuario.reporte_historial_precios;
                break;
            case "reporte_movimientos":
                entra = usuario.reporte_movimientos;
                break;
            case "reporte_historial_bajas_salidas":
                entra = usuario.reporte_historial_bajas_salidas;
                break;
            case "reporte_descuentos_recargos":
                entra = usuario.reporte_descuentos_recargos;
                break;
            case "configuracion":
                entra = usuario.configuracion;
                break;
            case "conf_tienda":
                entra = usuario.conf_tienda;
                break;
            case "conf_usuario":
                entra = usuario.conf_usuario;
                break;
            case "conf_sucursal":
                entra = usuario.conf_sucursal;
                break;
            case "conf_cliente":
                entra = usuario.conf_cliente;
                break;
            case "conf_proveedor":
                entra = usuario.conf_proveedor;
                break;
            case "conf_caja":
                entra = usuario.conf_caja;
                break;
            case "conf_destino_salida":
                entra = usuario.conf_destino_salida;
                break;
            case "conf_adicionales_nota_fel":
                entra = usuario.conf_adicionales_nota_fel;
                break;
            case "bitacora":
                entra = usuario.bitacora;
                break;
            case "peril":
                entra = usuario.perfil;
                break;
            case "despacho":
                entra = usuario.despacho;
                break;
            case "despacho_despachos":
                entra = usuario.despacho_despachos;
                break;
            case "despacho_recepciones":
                entra = usuario.despacho_recepciones;
                break;
            case "despacho_recepciones_orden_compra":
                entra = usuario.despacho_recepciones_orden_compra;
                break;
            case "orden_compra":
                entra = usuario.orden_compra;
                break;
            case "orden_online":
                entra = usuario.orden_online;
                break;
        }
        if (usuario.is_superuser || usuario.es_propietario) entra = true;
        if (entra) return "";
        else return "d-none";
    };

    logOut(event) {
        this.props.logOut();
    }
    toggleSucursal() {
        this.setState({ ver_sucursal: !this.state.ver_sucursal });
    }
    render() {
        const {
            url,
            usuario,
            sucursales,
            sucursal,
            setSucursal,
            utiliza_fel,
            vista_bienvenida,
            tipo_punto_venta,
            conf_tienda_online,
        } = this.props;
        // state
        const { goToPerfil } = this.props;
        return (
            <div style={{ position: "relative" }}>
                <div
                    className={`v-menu v-menu-front ${
                        !this.state.ver_sucursal ? "" : "collapsed"
                    }`}
                >
                    <div
                        className="w-100 d-flex flex-column align-items-center justify-content-center"
                        style={{ textAlign: "center", marginBottom: "30px" }}
                    >
                        <a
                            href="#/"
                            className="imagen-sidebar d-flex align-items-center justify-content-center"
                        >
                            <img
                                className="img-responsive logo"
                                src={require("assets/img/logo_small.png")}
                                alt="Logo"
                            />
                            <img
                                className="img-responsive logo-icon"
                                src={require("assets/img/logo-icon.png")}
                                alt="Logo"
                            />
                        </a>
                        {vista_bienvenida && (
                            <strong
                                className="texto-version"
                                onClick={() => {
                                    this.openModal();
                                }}
                            >
                                Versión {vista_bienvenida.version}
                            </strong>
                        )}
                    </div>
                    <Link
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "dashboard"
                        )} ${url.includes("/dashboard") ? "activo" : ""}`}
                        to={"/dashboard"}
                    >
                        <img title="Inicio" src={icons.home} alt="" />
                        <div>Inicio</div>
                    </Link>
                    <Link
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "bodega"
                        )} ${url.includes("/bodega") ? "activo" : ""}`}
                        to={"/bodega"}
                    >
                        <img
                            className=""
                            title="Bodega"
                            src={icons.bodega}
                            alt=""
                        />
                        <div>Bodega</div>
                    </Link>
                    <Link
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "orden_compra"
                        )} ${
                            url.includes("/ordenes_de_compra") ? "activo" : ""
                        }`}
                        to={"/ordenes_de_compra"}
                    >
                        <img
                            className=""
                            title="Ordenes de compra"
                            src={icons.orden_compra}
                            alt=""
                        />
                        <div>Ordenes de compra</div>
                    </Link>
                    <Accordion
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "despacho"
                        )} ${
                            url.includes("/despacho") ||
                            url.includes("/recepciones_de_despachos")
                                ? "activo no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <img
                                        className=""
                                        title="Despachos"
                                        src={icons.despachos}
                                        alt=""
                                    />
                                    <div>Despachos</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "despacho_despachos"
                                    )} ${
                                        url.includes("/despachos")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/despachos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Despachos"
                                            src={icons.reporte_despachos}
                                            alt=""
                                        />
                                        <div>Despachar a otra sucursal</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "despacho_recepciones"
                                    )} ${
                                        url.includes(
                                            "/recepciones_de_despachos"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/recepciones_de_despachos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Recepciones"
                                            src={icons.despachos}
                                            alt=""
                                        />
                                        <div>Recibir de otra sucursal</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "despacho_recepciones_orden_compra"
                                    )} ${
                                        url.includes(
                                            "/recepciones_de_ordenes_de_compra"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/recepciones_de_ordenes_de_compra"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Recepciones"
                                            src={icons.despachos}
                                            alt=""
                                        />
                                        <div>Recibir orden de compra</div>
                                    </div>
                                </Link>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>

                    {tipo_punto_venta == PVN ? (
                        <Link
                            className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                "punto_venta"
                            )} ${
                                url.includes("/punto_de_venta") ? "activo" : ""
                            }`}
                            to={"/punto_de_venta"}
                        >
                            <img
                                className=""
                                title="Ventas"
                                src={icons.ventas}
                                alt=""
                            />
                            <div>Punto de Venta</div>
                        </Link>
                    ) : (
                        <div>
                            {usuario.punto_venta_mostrador && (
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "punto_venta_mostrador"
                                    )} ${
                                        url.includes(
                                            "/punto_de_venta/mostrador"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/punto_de_venta/mostrador"}
                                >
                                    <img
                                        className="sub-item  align-self-center"
                                        title="Mostrador"
                                        src={icons.mostrador}
                                        alt=""
                                    />
                                    <div>Mostrador</div>
                                </Link>
                            )}
                            {usuario.punto_venta_caja && (
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "punto_venta_caja"
                                    )} ${
                                        url.includes("/punto_de_venta/caja")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/punto_de_venta/caja"}
                                >
                                    <img
                                        className="sub-item  align-self-center"
                                        title="Caja"
                                        src={icons.punto_venta_caja}
                                        alt=""
                                    />
                                    <div>Caja</div>
                                </Link>
                            )}
                        </div>
                    )}
                    <Link
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "producto"
                        )} ${url.includes("/producto") ? "activo" : ""}`}
                        to={"/productos"}
                    >
                        <img
                            className=""
                            title="Productos"
                            src={icons.producto}
                            alt=""
                        />
                        <div>Productos</div>
                    </Link>
                    <Link
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "gasto"
                        )} ${url.includes("/gastos") ? "activo" : ""}`}
                        to={"/gastos"}
                    >
                        <img
                            className=""
                            title="Reportar Gastos"
                            src={icons.gastos}
                            alt=""
                        />
                        <div>Reportar Gastos</div>
                    </Link>
                    {conf_tienda_online && (
                        <Link
                            className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                "orden_online"
                            )} ${
                                url.includes("/ordenes_online") ? "activo" : ""
                            }`}
                            to={"/ordenes_online"}
                        >
                            <img
                                className=""
                                title="Ordenes Online"
                                src={icons.orden_online}
                                alt=""
                            />
                            <div>Ordenes Online</div>
                        </Link>
                    )}
                    <Accordion
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "reporte"
                        )} ${
                            url.includes("/reporte")
                                ? "activo no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <img
                                        className=""
                                        title="Reportes"
                                        src={icons.reportes}
                                        alt=""
                                    />
                                    <div>Reportes</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_balance_general"
                                    )} ${
                                        url.includes("/reporte_balance_general")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_balance_general"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Balance General"
                                            src={icons.balance_general}
                                            alt=""
                                        />
                                        <div>Balance General</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_apertura_cierre"
                                    )} ${
                                        url.includes(
                                            "/reporte_apertura_cierre_caja"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_apertura_cierre_caja"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Apertura y cierre"
                                            src={icons.apertura_cierre}
                                            alt=""
                                        />
                                        <div>Apertura y cierre</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_bodega"
                                    )} ${
                                        url.includes("/reporte_bodega")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_bodega"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Bodega"
                                            src={icons.reporte_bodega}
                                            alt=""
                                        />
                                        <div>Bodega</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_venta"
                                    )} ${
                                        url.includes("/reporte_ventas")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_ventas"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Ventas"
                                            src={icons.reporte_ventas}
                                            alt=""
                                        />
                                        <div>Ventas</div>
                                    </div>
                                </Link>

                                {usuario.subdominio=== "mercamuebles" && (
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_venta"
                                    )} ${
                                        url.includes("/reporte_compras")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_compras"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Compras"
                                            src={icons.orden_compra}
                                            alt=""
                                        />
                                        <div>Compras</div>
                                    </div>
                                </Link>
                                )}
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_devolucion"
                                    )} ${
                                        url.includes("/reporte_devoluciones")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_devoluciones"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Devoluciones"
                                            src={icons.reporte_devoluciones}
                                            alt=""
                                        />
                                        <div>Devoluciones</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_cuenta_cobrar"
                                    )} ${
                                        url.includes(
                                            "/reporte_cuenta_por_cobrar"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_cuenta_por_cobrar"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Cuentas por cobrar"
                                            src={icons.cuentas_por_cobrar}
                                            alt=""
                                        />
                                        <div>Cuentas por cobrar</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_gasto"
                                    )} ${
                                        url.includes("/reporte_gastos")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_gastos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Gastos"
                                            src={icons.gastos}
                                            alt=""
                                        />
                                        <div>Gastos</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_productos_mas_vendidos"
                                    )} ${
                                        url.includes(
                                            "/reporte_productos_mas_vendidos"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_productos_mas_vendidos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Productos más vendidos"
                                            src={icons.mas_vendido}
                                            alt=""
                                        />
                                        <div>Productos más vendidos</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_total_invertido"
                                    )} ${
                                        url.includes("/inversion_en_bodega")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/inversion_en_bodega"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Inversión en bodega"
                                            src={icons.reporte_dinero_invertido}
                                            alt=""
                                        />
                                        <div>Inversión en bodega</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_ganancia_bruta"
                                    )} ${
                                        url.includes("/reporte_ganancia_bruta")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_ganancia_bruta"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Ganancia bruta"
                                            src={icons.ganancia_bruta}
                                            alt=""
                                        />
                                        <div>Ganancia bruta</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_mejores_clientes"
                                    )} ${
                                        url.includes(
                                            "/reporte_mejores_clientes"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_mejores_clientes"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Mejores clientes"
                                            src={icons.mejores_clientes}
                                            alt=""
                                        />
                                        <div>Mejores clientes</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_despachos"
                                    )} ${
                                        url.includes("/reporte_de_despachos")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_de_despachos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Despachos"
                                            src={icons.reporte_despachos}
                                            alt=""
                                        />
                                        <div>Despachos</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_historial_precios"
                                    )} ${
                                        url.includes(
                                            "/reporte_historial_precios"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_historial_precios"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Precios"
                                            src={icons.reporte_precios}
                                            alt=""
                                        />
                                        <div>Historial de precios</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_movimientos"
                                    )} ${
                                        url.includes("/historial_de_inventario")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/historial_de_inventario"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Historial de inventario"
                                            src={icons.reporte_movimientos}
                                            alt=""
                                        />
                                        <div>Historial de inventario</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_movimientos"
                                    )} ${
                                        url.includes("/historial_general_de_inventario")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/historial_general_de_inventario"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Historial general de inventario"
                                            src={icons.reporte_movimientos_general}
                                            alt=""
                                        />
                                        <div>Historial general de inventario</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_historial_bajas_salidas"
                                    )} ${
                                        url.includes(
                                            "/bajas_y_salidas_por_productos"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/bajas_y_salidas_por_productos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Bajas y salidas por producto"
                                            src={icons.historial_baja_salida}
                                            alt=""
                                        />
                                        <div>Bajas y salidas por producto</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_descuentos_recargos"
                                    )} ${
                                        url.includes(
                                            "/reporte_descuentos_y_recargos"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_descuentos_y_recargos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Descuentos y recargos"
                                            src={
                                                icons.reporte_descuentos_recargos
                                            }
                                            alt=""
                                        />
                                        <div>Descuentos y recargos</div>
                                    </div>
                                </Link>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                    <Accordion
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "configuracion"
                        )} ${
                            url.includes("/configuracion")
                                ? "activo no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <img
                                        className=""
                                        title="Configuración"
                                        src={icons.configuracion}
                                        alt=""
                                    />
                                    <div>Configuración</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_tienda"
                                    )} ${
                                        url.includes("/general") ? "activo" : ""
                                    }`}
                                    to={"/configuracion/general"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Tienda"
                                            src={icons.tienda}
                                            alt=""
                                        />
                                        <div>Tienda</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_usuario"
                                    )} ${
                                        url.includes("/usuario") ? "activo" : ""
                                    }`}
                                    to={"/configuracion/usuarios"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Usuarios"
                                            src={icons.usuarios}
                                            alt=""
                                        />
                                        <div>Usuarios</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_sucursal"
                                    )} ${
                                        url.includes("/sucursal")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/configuracion/sucursales"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Sucursales"
                                            src={icons.sucursales}
                                            alt=""
                                        />
                                        <div>Sucursales</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_cliente"
                                    )} ${
                                        url.includes("/clientes")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/configuracion/clientes"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Clientes"
                                            src={icons.clientes}
                                            alt=""
                                        />
                                        <div>Clientes</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_proveedor"
                                    )} ${
                                        url.includes("/proveedores")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/configuracion/proveedores"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Proveedores"
                                            src={icons.proveedores}
                                            alt=""
                                        />
                                        <div>Proveedores</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_caja"
                                    )} ${
                                        url.includes("/configuracion/cajas")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/configuracion/cajas"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Cajas"
                                            src={icons.cajas}
                                            alt=""
                                        />
                                        <div>Cajas</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_destino_salida"
                                    )} ${
                                        url.includes(
                                            "/configuracion/destinos_salida_de_productos"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={
                                        "/configuracion/destinos_salida_de_productos"
                                    }
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Destinos de salida"
                                            src={icons.destino_salida}
                                            alt=""
                                        />
                                        <div>Destinos de salida</div>
                                    </div>
                                </Link>
                                {utiliza_fel && (
                                    <Link
                                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                            "conf_adicionales_nota_fel"
                                        )} ${
                                            url.includes(
                                                "/configuracion/adicionales_nota_fel"
                                            )
                                                ? "activo"
                                                : ""
                                        }`}
                                        to={
                                            "/configuracion/adicionales_nota_fel"
                                        }
                                    >
                                        <div className="d-flex flex-row align-content-start">
                                            <img
                                                className="sub-item  align-self-center"
                                                title="Destinos de salida"
                                                src={icons.adicionales_nota_fel}
                                                alt=""
                                            />
                                            <div>Adicionales nota crédito</div>
                                        </div>
                                    </Link>
                                )}
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                    <Link
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "bitacora"
                        )} ${url.includes("/bitacora") ? "activo" : ""}`}
                        to={"/bitacora"}
                    >
                        <img
                            className=""
                            title="Bitácora"
                            src={icons.bitacora}
                            alt=""
                        />
                        <div>Bitácora</div>
                    </Link>
                    <Accordion
                        className={`text-center sidebar-item menu-item d-block d-md-none ${
                            url.includes("/perfil")
                                ? "activo no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {usuario.foto_cropped ? (
                                        <img
                                            className="img-usuario"
                                            src={usuario.foto_cropped}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="img-usuario"
                                            src={icons.perfil}
                                            alt=""
                                        />
                                    )}
                                    <div>{usuario.first_name}</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "bitacora"
                                    )} ${
                                        url.includes("/perfil") ? "activo" : ""
                                    }`}
                                    to={"/perfil"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <i
                                            className="fa fa-user"
                                            style={{
                                                fontSize: "20px",
                                                paddingRight: "10px",
                                            }}
                                        />
                                        <div>Perfil</div>
                                    </div>
                                </Link>
                            </AccordionItemBody>
                            <AccordionItemBody>
                                <a
                                    onClick={this.logOut}
                                    className={`text-center sidebar-item menu-item ${
                                        url.includes("/login") ? "activo" : ""
                                    }`}
                                    to={"/login"}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <i
                                            className="fa fa-lock"
                                            style={{
                                                fontSize: "20px",
                                                paddingRight: "10px",
                                            }}
                                        />
                                        <div>Cerrrar sesión</div>
                                    </div>
                                </a>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                    {/* <a href="/#/login" onClick={this.logOut} className="text-center sidebar-item menu-item logout"><em className="fa fa-sign-out" /><div>Cerrar Sesión</div></a> */}
                </div>
                {/* MODAL */}
                <Modal
                    open={this.state.open}
                    onClose={this.closeModal}
                    showCloseIcon={false}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                    classNames={{ modal: "modal-bienvenida" }}
                >
                    <div style={{ padding: "1.2rem" }} className="w-100">
                        <div className="row">
                            {vista_bienvenida && (
                                <Bienvenida
                                    formato={vista_bienvenida.formato}
                                    className="w-100"
                                    marcarVisto={this.marcarVisto}
                                />
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Menu;
