import React, { Component } from 'react';
import {
    LiveProvider,
    LivePreview,
    LiveEditor
} from 'react-live'

import {icons} from "icons";
import { RenderDateTime, RenderCurrency, RenderNumber } from "Utils/renderField/renderReadField";


const getDateObject = (value) => {
    const fecha = new Date(value);
    return {
        day: fecha.getDate(),
        month: fecha.getMonth(),
        year: fecha.getFullYear().toString().substring(2),
        fullYear: fecha.getFullYear(),
    }
};


export default class Factura extends Component {
    render() {
        //  state
        // const {detalle} = this.state;
        const {datos_venta, formato_factura, className} = this.props;
        //  bind

        return (
            <div className={`${ className ? className : ''}`}>
                <LiveProvider className="d-table" code={formato_factura} noInline={false} scope={{...datos_venta, RenderCurrency, RenderNumber, RenderDateTime, icons, getDateObject}}>
                    <LivePreview className="d-table"/>
                </LiveProvider>
            </div>
        );
    }
}
