import React, { Component } from "react";
import PropTypes from "prop-types";
import CierreForm from "./CierreForm";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { SeleccionSucursal } from "Utils/SeleccionSucursal";
import { icons } from "icons";
import ModalAyuda from "Utils/Ayuda";
import "../../Apertura/apertura_cierre.css";

class Cierre extends Component {
    static propTypes = {
        cierre: PropTypes.func.isRequired,
        total: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            modalAyuda: false,
            itemsCierre: [
                // {
                //     src: icons.cierre_caja_p1,
                //     alt: "Paso 1"
                // },
                {
                    src: icons.cierre_caja_p2,
                    alt: "Paso 1",
                },
                {
                    src: icons.cierre_caja_p3,
                    alt: "Paso 2",
                },
                {
                    src: icons.cierre_caja_p4,
                    alt: "Paso 3",
                },
                {
                    src: icons.cierre_caja_p5,
                    alt: "Paso 4",
                },
            ],
        };
    }

    componentWillMount() {
        this.props.getCierreCaja();
    }

    closeModal = () => {
        this.setState({ modalAyuda: false });
    };
    openModal = () => {
        this.setState({ modalAyuda: true });
    };

    render() {
        // state
        const { sucursal, ultimo_cierre } = this.props;
        const { modalAyuda, itemsCierre } = this.state;

        // bind
        const { cierre, total, config, loader, regresar } = this.props;

        /* region SELECCION DE SUCURSAL */
        if (sucursal === undefined) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo padding-5">
                                Seleccione una sucursal
                            </div>
                            <div className="padding-15">
                                <SeleccionSucursal />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        /* endregion SELECCION DE SUCURSAL */

        if (!ultimo_cierre.id) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <h1 className="grid-titulo padding-15">
                                Detalle Caja
                            </h1>
                            <div className="row  padding-15">
                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                    <i className="fa fa-exclamation-circle fa-4x" />
                                    <h1>
                                        <b>No has abierto ninguna caja</b>
                                    </h1>
                                    <div>¿Deseas abrir una caja?</div>
                                    <Link
                                        className="btn btn-primary"
                                        to={"/abrir_caja"}
                                    >
                                        Abrir
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            if (ultimo_cierre.caja.sucursal != parseInt(sucursal)) {
                return (
                    <div className="row">
                        <div className="col-12">
                            <div className="grid-container">
                                <h1 className="grid-titulo padding-15">
                                    Cierre de Caja
                                </h1>
                                <div className="row  padding-15">
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <i className="fa fa-exclamation-circle fa-4x" />
                                        <h1>
                                            <b>No puedes operar</b>
                                        </h1>
                                        <div>
                                            Tienes una caja abierta en otra
                                            sucursal{" "}
                                            <b>
                                                (
                                                {
                                                    ultimo_cierre.caja
                                                        .nombre_sucursal
                                                }
                                                )
                                            </b>
                                            .
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className="row">
                <LoadMask loading={loader} light blur radius>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <CierreForm
                            {...this.props}
                            total={total}
                            onSubmit={cierre}
                            caja={ultimo_cierre}
                            config={config}
                            actionAyuda={this.openModal}
                            regresar={regresar}
                        />
                    </div>
                </LoadMask>
                <ModalAyuda
                    open={modalAyuda}
                    closeModal={this.closeModal}
                    openModal={this.openModal}
                    items={itemsCierre}
                />
            </div>
        );
    }
}

export default Cierre;
