import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import Toolbar from "Utils/Toolbar/Toolbar"
import LoadMask from "Utils/LoadMask/LoadMask"
import { tableOptions } from "Utils/tableOptions"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { default as GraficaCompras } from "../Grafica";
import { TablaOC } from "./ComponentesListadoOC";

import '../orden_compra.css'

import Select from 'react-select';

class ListadoOrdenCompraR extends Component {
    static propTypes = {
    }
    constructor(props) {
        super(props);
        this.state = {
            selectedYear: new Date().getFullYear(),
            selectedMonth: 1, // Iniciar con enero
        };
    }

    componentWillMount() {
        this.props.listarOCT(this.props.page_oc);
    }

    render() {
        // State
        const { tab, datos_listado, loader} = this.props;
       
        // Bind
        const {
            changeTab,
            listarOCT,
            sortChangeOC,
            setFechaReporte,
            estado_descarga3,
            descargarListadoOC,
        } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = this.props.page_oc;
        options.onPageChange = listarOCT;
        options.onSortChange = sortChangeOC;


        const fechaInicial =this.props.fecha_compra_inicial
        const [yearInicio, monthInicio, dayInicio] = fechaInicial.split("-");

        // Convertir los valores extraídos a números 
        const yearInicioNum = parseInt(yearInicio);
        const monthInicioNum = parseInt(monthInicio);

        // Obter el año actual
        const currentYear = new Date().getFullYear();

        // Genera un array de opciones para los años desde 2019 hasta el año actual
        const yearOptions = Array.from({ length: currentYear - 2019 }, (_, index) => {
            const year = 2020 + index;
            return { label: year, value: year };
        });

        const handleMonthChange = (selectedOption) => {
            const selectedMonth = selectedOption.value;
            const selectedYear = this.state.selectedYear || new Date().getFullYear();
            // Obtener el primer y último día del mes
            const startDate = `${selectedYear}-${selectedMonth}-01`       
            const lastDayOfMonth = new Date(selectedYear, selectedMonth, 0).getDate();
            const endDate = `${selectedYear}-${selectedMonth}-${lastDayOfMonth}`;
        
            setFechaReporte(startDate,endDate);
        
            this.setState({ selectedMonth });
        };

        const handleYearChange = (selectedOption) => {
            const selectedYear = selectedOption.value;
            const selectedMonth = this.state.selectedMonth || 1;
            // Obtener el primer y último día del mes
            const startDate = `${selectedYear}-${selectedMonth}-01`
            const lastDayOfMonth = new Date(selectedYear, selectedMonth, 0).getDate();
            const endDate = `${selectedYear}-${selectedMonth}-${lastDayOfMonth}`;

            setFechaReporte(startDate,endDate);

            this.setState({ selectedYear });
        };

        return (
            <div>
            <GraficaCompras/>
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="grid-container">
                                <div className="grid-titulo">      
                                    Compras Mensuales
                                </div>
                                <div className="row py-2 px-4 justify-content-between">
                                    <div className="row col-8 col-sm-12 col-md-10">
                                        <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                                            <label htmlFor="">
                                                Mes
                                            </label>
                                            <Select
                                               className="form-control"
                                                options={[
                                                    { label: 'Enero', value: 1 },
                                                    { label: 'Febrero', value: 2 },
                                                    { label: 'Marzo', value: 3 },
                                                    { label: 'Abril', value: 4 },
                                                    { label: 'Mayo', value: 5 },
                                                    { label: 'Junio', value: 6 },
                                                    { label: 'Julio', value: 7 },
                                                    { label: 'Agosto', value: 8 },
                                                    { label: 'Septiembre', value: 9 },
                                                    { label: 'Octubre', value: 10 },
                                                    { label: 'Noviembre', value: 11 },
                                                    { label: 'Diciembre', value: 12 },
                                                ]}
                                                onChange={handleMonthChange}
                                                value={monthInicioNum}                    
                                            />

                                        </div>
                                        <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                                            <label htmlFor="">
                                                Año
                                            </label>
                                            <Select
                                               className="form-control"
                                               options={yearOptions}
                                               onChange={handleYearChange}
                                               value={yearInicioNum} 
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga3}
                                        onClick={() => {
                                            descargarListadoOC();
                                        }}
                                    >
                                        <em className={`fa ${estado_descarga3 ? "fa-spinner fa-pulse" : "fa-download"}`}/>
                                        &nbsp;Descargar Listado
                                    </button>
                                </div>
                                <LoadMask loading={loader} dark blur>
                                    <TablaOC loader={loader} datos_listado={datos_listado} options={options} anular={this.props.anularODC}/>
                                </LoadMask>
                            </div>
                </div>
            </div>
            </div>
        )
    }
}

export default ListadoOrdenCompraR;
