import React, { Component } from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import LoadMask from "Utils/LoadMask/LoadMask";
import { DropDownMenu, DropDownDirection, ActionItem } from "react-dropdown-advanced";
import { icons } from "icons";
import {
    formatoNombre,
    formatoExistencia,
    buscar,
    formatoProducto,
    formatoAvatar,
    formatoMarca,
} from "Utils/CamposComunes";
import CardProduct from "./CardProduct"

export class ListadoCards extends  Component {
    static propTypes = {
        titulo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        buscar: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
        sortChange: PropTypes.func.isRequired,
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        // state
        const { titulo, buscar, data, loader, llaves, acciones, itemsAyuda, cambioBuscador, forzar_agregar } = this.props;
        // bind
        const { onClickOtrasAcciones, actionAyuda } = this.props

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;
        return (
            <div className="grid-container mt0 d-flex justify-content-around flex-column ">
                <div style={{width: "45%"}} className="d-lg-none d-xl-none mt0 padding-10 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row flex-start">
                    <button className="btn btn-tertiary" type="button" onClick={() => { cambioBuscador() }}>
                        <img className="icon-img" src={icons.cancelar} alt="Cancelar"/>
                        Cancelar
                    </button>
                </div>
                <div className={`grid-titulo ${acciones ? "d-flex flex-row": ""}`}>
                    <div className="col-12 mb-3 d-flex flex1 d-flex justify-content-around flex-column mt">
                        <div>
                            {buscar()}
                        </div>
                    </div>
                    {
                        (acciones) && (

                            <div style={{marginTop:"10px", marginRight: "10px"}}>
                                <button className="btn btn-tertiary-drop fnt-16 d-flex align-items-center justify-content-center" style={{position:"relative"}}>
                                {/* <img src={icons.puntos} alt="acciones" title="Otras Acciones" style={{ margin: "0", height: "1.1rem" }} /> */}
                                    <i className="fa fa-ellipsis-h fnt-18"></i>
                                    {
                                        acciones.length > 0 && (
                                            <DropDownMenu items={acciones} onClick={onClickOtrasAcciones} direction={DropDownDirection.DownLeft} className="fnt-10"/>
                                        )
                                    }
                                </button>
                            </div>
                        )
                    }
                    {
                        (itemsAyuda && actionAyuda) && (
                            <div style={{marginTop:"10px", marginRight: "10px"}}>
                                <button className="btn btn-ayuda btn-ayuda-flotante fnt-16 d-flex align-items-center justify-content-center" style={{ width: "2rem", height: "2rem" }}>
                                    <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{ margin: "0", height: "1.1rem" }} />
                                    <DropDownMenu items={itemsAyuda} onClick={actionAyuda} direction={DropDownDirection.DownLeft} className="fnt-10" />
                                </button>
                            </div>
                        )

                    }
                </div>
                <div>
                    <LoadMask loading={loader} light blur radius>
                        {
                            data.results && data.results.map((item) => {
                                return(
                                    <CardProduct {...this.props} item={item} key={item.id}/>
                                )
                            })
                        }
                    </LoadMask>
                </div>
            </div>
        );
    }
}
