import React, { Component } from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import ConfiguracionForm from "./ConfiguracionEditarForm";
import LoadMask from "Utils/LoadMask/LoadMask";
import "rc-slider/assets/index.css";
import "./editar_configuracion";

class ConfiguracionEditar extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        getMetodosPagoEntrega: PropTypes.func.isRequired,
        submitConfig: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            image: "your-image-url or as base64",
            crop: { x: 0, y: 0 },
            zoom: 1,
            perfil: "",
            imCortada: "",
            editando_logo: false,
            editando_banner: false,
        };
    }

    componentDidMount() {
        // refrescar form desde el backend
        this.props.getMetodosPagoEntrega();
    }

    componentWillReceiveProps(nexProps) {
        if (nexProps.data !== this.props.data) {
            if (nexProps.data.logo != null) {
                this.setState({ editando_logo: false });
            } else {
                this.setState({ editando_logo: true });
            }

            if (nexProps.data.banner != null) {
                this.setState({ editando_banner: false });
            } else {
                this.setState({ editando_banner: true });
            }
        }
    }

    onFileChange = (e, file, key) => {
        file = file || e.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        if (file) {
            if (!file.type.match(pattern)) {
                alert("Formato inválido");
                return;
            }
            reader.onload = (e) => {
                this.setState({ [key]: file });
                this.setState({ image: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };

    submit = () => {
        const logos = [
            {
                name: "logo",
                file: this.state.editando_logo
                    ? this.state.cortada_logo
                    : undefined,
            },
            {
                name: "banner",
                file: this.state.editando_logo
                    ? this.state.cortada_banner
                    : undefined,
            },
            { name: "logo_ticket", file: this.state.logo_ticket },
        ];
        Swal({
            title: "¿Guardar?",
            text: "¿Confirma que desea guardar la información?",
            type: "question",
            showCancelButton: true,
            confirmButtonText: "Sí, continuar!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.submitConfig(logos);
            }
        });
    };

    setCortada = (key, img) => {
        this.setState({ [`cortada_${key}`]: img });
    };

    changeEdicionImagen = (key) => {
        if (key == "banner") {
            const estado = !this.state.editando_banner;
            this.setState({ editando_banner: estado });
            if (estado == false) {
                this.setState({ [`cortada_${key}`]: undefined });
                if (
                    this.state[`${key}`] != null &&
                    this.state[`${key}`] != undefined
                ) {
                    this.setState({ image: "your-image-url or as base64" });
                }
                this.setState({ [key]: undefined });
                if (this.props.data.banner != null) {
                    this.setState({ editando_banner: estado });
                } else {
                    this.setState({ editando_banner: true });
                }
            }
        } else if (key == "logo") {
            const estado = !this.state.editando_logo;
            this.setState({ editando_logo: estado });
            if (estado == false) {
                this.setState({ [`cortada_${key}`]: undefined });
                if (
                    this.state[`${key}`] != null &&
                    this.state[`${key}`] != undefined
                ) {
                    this.setState({ image: "your-image-url or as base64" });
                }
                this.setState({ [key]: undefined });
                if (this.props.data.logo != null) {
                    this.setState({ editando_logo: estado });
                } else {
                    this.setState({ editando_logo: true });
                }
            }
        }
    };

    onLogoTicketChange = (e, file, key) => {
        debugger;
        file = file || e.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        if (file) {
            if (!file.type.match(pattern)) {
                alert("Formato inválido");
                return;
            }
            // this.setState({ loaded: false });
            reader.onload = (e) => {
                this.setState({ [key]: file });
            };
            reader.readAsDataURL(file);
        }
    };

    render() {
        // state
        const {
            loading,
            data,
            metodos_entrega,
            metodos_pago,
            conf_tienda_online,
            utiliza_fel,
        } = this.props;
        return (
            <div className="row">
                <div className="col-sm-12">
                    <LoadMask loading={loading} light blur radius>
                        <div className="form-group grid-container">
                            <div className="grid-titulo padding-15">
                                Configuración
                            </div>

                            <div className=" padding-15">
                                <ConfiguracionForm
                                    conf_tienda_online={conf_tienda_online}
                                    logo={data.logo_b64}
                                    banner={data.banner_b64}
                                    data={data}
                                    metodos_entrega={metodos_entrega}
                                    metodos_pago={metodos_pago}
                                    onSubmit={this.submit}
                                    onFileChange={this.onFileChange}
                                    initialValues={data}
                                    tienda={data.url_tienda}
                                    slug={data.slug}
                                    editando_logo={this.state.editando_logo}
                                    editando_banner={this.state.editando_banner}
                                    setCortada={this.setCortada}
                                    utiliza_fel={utiliza_fel}
                                    changeEdicionImagen={
                                        this.changeEdicionImagen
                                    }
                                    onLogoTicketChange={this.onLogoTicketChange}
                                />
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ConfiguracionEditar;
