import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SucursalForm from './SucursalDetalleForm'
import LoadMask from "Utils/LoadMask/LoadMask";

class SucursalEditar extends Component {
    static propTypes = {
        leer: PropTypes.func.isRequired,
        sucursal: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render() {
        // state
        const { sucursal, loader } = this.props;
        // bind

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={loader} dark blur>
                        <SucursalForm sucursal={sucursal} />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default SucursalEditar