import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderNumero } from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import { DESPACHO_COMPLETO } from "utility/variables";

class DetalleDespacho extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getDespacho(this.props.match.params.id);
    }

    render() {
        const { despacho, loader } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;

        if (!despacho) {
            return (
                <div className="grid-container padding-20">
                    <h2>Detalle no encontrado</h2>
                    <Link className="btn btn-secondary" to={"/despachos"}>
                        <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                        Regresar
                    </Link>
                </div>
            );
        }
        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container pb-1">
                        <LoadMask loading={loader} light blur radius>
                            <div className="">
                                <div className="grid-titulo padding-15">
                                    Encabezado
                                </div>
                                <div className="p-sm-0 pt-sm-1 pb-sm-1 mt">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Código
                                            </label>
                                            <div className="input-view">
                                                {despacho.codigo
                                                    ? despacho.codigo
                                                    : "-----"}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Enviado desde
                                            </label>
                                            <div className="input-view">
                                                {despacho.sucursal.nombre}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Enviado a
                                            </label>
                                            <div className="input-view">
                                                {
                                                    despacho.sucursal_destino
                                                        .nombre
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Enviado por
                                            </label>
                                            <div className="input-view">
                                                {despacho.usuario.first_name}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Transportado por
                                            </label>
                                            <div className="input-view">
                                                {despacho.responsable_transporte
                                                    ? despacho.responsable_transporte
                                                    : "-----"}
                                            </div>
                                        </div>
                                        {despacho.estado_despacho ==
                                            DESPACHO_COMPLETO && (
                                            <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                                <label className="label-view">
                                                    Recibido por
                                                </label>
                                                <div className="input-view">
                                                    {
                                                        despacho
                                                            .recepcionado_por
                                                            .first_name
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Fecha y hora
                                            </label>
                                            <div className="input-view">
                                                <RenderDateTime
                                                    fecha={despacho.creado}
                                                />
                                            </div>
                                        </div>
                                        {despacho.estado_despacho ==
                                            DESPACHO_COMPLETO && (
                                            <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                                <label className="label-view">
                                                    Fecha y hora de recepción
                                                </label>
                                                <div className="input-view">
                                                    <RenderDateTime
                                                        fecha={
                                                            despacho.fecha_recepcion
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="row mt">
                                        <div className="col-12 negro-dark2 font-weight-bold">
                                            <h4>Listado</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <BootstrapTable
                                data={loader ? [] : despacho.detalles}
                                hover
                                options={options}
                            >
                                <TableHeaderColumn
                                    width="0%"
                                    dataField="id"
                                    isKey
                                >
                                    ID
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div>
                                                <span>
                                                    <b>{row.producto}</b>
                                                    <div>
                                                        {row.fraccion.producto
                                                            .a_granel
                                                            ? row.fraccion
                                                                  .unidad_de_medida
                                                                  .nombre
                                                            : row.fraccion
                                                                  .nombre}
                                                    </div>
                                                </span>
                                            </div>
                                        );
                                    }}
                                >
                                    Nombre
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    width="100px"
                                    dataField="cantidad"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return <RenderNumero monto={cell} />;
                                    }}
                                >
                                    Agregados
                                </TableHeaderColumn>
                                {despacho.estado_despacho ==
                                    DESPACHO_COMPLETO && (
                                    <TableHeaderColumn
                                        dataAlign="right"
                                        width="100px"
                                        dataField="cantidad_recibido"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderNumero monto={cell} />
                                            );
                                        }}
                                    >
                                        recibidos
                                    </TableHeaderColumn>
                                )}
                                {despacho.estado_despacho ==
                                    DESPACHO_COMPLETO && (
                                    <TableHeaderColumn
                                        dataField="nota"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Notas
                                    </TableHeaderColumn>
                                )}
                            </BootstrapTable>
                            <div className="row d-flex align-items-center justify-content-center mt-1 mb-2">
                                <Link
                                    className="btn btn-tertiary"
                                    to={"/despachos"}
                                >
                                    <i
                                        className="fa fa-arrow-left"
                                        aria-hidden="true"
                                    />{" "}
                                    Regresar
                                </Link>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

DetalleDespacho.propTypes = {};

export default DetalleDespacho;
