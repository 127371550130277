import React from "react";
import _ from "lodash";
import {Field, reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import {validate, validators} from "validate-redux-form";
import {RenderCurrency} from "Utils/renderField/renderReadField";
import {
    renderTextArea,
    renderSearchSelect,
} from "Utils/renderField/renderField";
import {ListadoAccion} from "Utils/ListadoAccion";
import {DetalleAccion} from "Utils/DetalleAccion";
import {api} from "api";
import {icons} from "icons";

import {
    formatoNombre,
    formatoExistencia,
    formatoAcciones,
    buscar,
    formatoProducto,
} from "Utils/CamposComunes";

import {
    formatoIngreso,
    formatoCosto,
    formatoPrecio,
    formatoSubtotal,
    formatoUnidadesMedida,
} from "./CamposDetalle";
import {validarConfDecimales} from "../../Utils/validaConfiguracionDecimal";

export const listaProveedores = [];

const Form = (props) => {
    const {
        loader,
        data,
        listar,
        sortChange,
        seleccionados,
        total,
        page,
        ver_buscador,
        cambio_buscador,
        conf_habilitar_no_factura,
    } = props;
    const {handleSubmit, actionAyuda, quitar} = props;
    const llavesListado = [
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },
        {
            nombre: "inventario",
            sort: false,
            caption: "Existencias",
            format: formatoExistencia,
            align: "right",
            width: "100px",
        },
        {
            nombre: "acciones",
            sort: false,
            caption: "Agregar",
            format: formatoAcciones(props),
            align: "center",
            width: "90px",
        },
    ];

    const llavesDetalle = [
        {caption: "Producto", nombre: "producto", format: formatoProducto},
        {
            caption: "Cantidad que ingresa",
            nombre: "ingreso",
            width: "100px",
            format: formatoIngreso(props),
        },
        // { caption: "Medida", nombre:"unidad_medida", format: formatoUnidadesMedida(props)},
        {
            caption: "Costo (Unidad)",
            nombre: "costo",
            align: "right",
            width: "110px",
            format: formatoCosto(props),
        },
        {
            caption: "Precio",
            nombre: "precio",
            align: "right",
            width: "110px",
            format: formatoPrecio(props),
        },
        {
            caption: "Subtotal",
            nombre: "subtotal",
            format: formatoSubtotal,
            align: "right",
        },
    ];

    const getProveedores = (search) => {
        return api
            .get("proveedores", {search})
            .catch(() => {
            })
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaProveedores, {id: item.id}))
                        listaProveedores.push(item);
                });
                return {options: listaProveedores};
            });
    };

    return (
        <form onSubmit={handleSubmit} className="row form">
            <div className="col-12 mb">
                <div className="grid-container pl-5 pr-5 pt-2 pb-2 d-flex flex-column">
                    <div
                        style={{
                            position: "absolute",
                            top: "25px",
                            right: "25px",
                        }}
                    >
                        <button
                            className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center"
                            style={{width: "2rem", height: "2rem"}}
                            onClick={actionAyuda}
                        >
                            <img
                                src={icons.ayuda}
                                alt="ayuda"
                                title="Ayuda"
                                style={{margin: "0", height: "1.1rem"}}
                            />
                        </button>
                    </div>
                    <div className="d-flex flex-column bodega-info-adicional mb-1 mb-md-4">
                        <label htmlFor="proveedor">Proveedor:</label>
                        <div
                            className="d-flex flex-column flex-md-row pr-0"
                            style={{maxWidth: "650px"}}
                        >
                            <Field
                                name="proveedor"
                                component={renderSearchSelect}
                                loadOptions={getProveedores}
                                labelKey="nombre"
                                valueKey="id"
                                className="form-control"
                            />
                            <small className="d-inline-block d-md-none mt-1 mb-1">
                                No encuentras al proveedor
                            </small>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.abrirProveedor();
                                }}
                                className="btn btn-secondary ml-0 mr-0 ml-md-3 mr-md-3"
                            >
                                <i className="fa fa-plus"/> Crear Proveedor
                            </button>
                        </div>
                    </div>
                    <div className="form-group bodega-info-adicional">
                        <label htmlFor="motivo">
                            Ingrese un número de documento, anotaciones u otros
                            comentarios pertinentes al ingreso
                        </label>
                        <Field
                            name="motivo"
                            rows={2}
                            component={renderTextArea}
                            className="form-control"
                        />
                    </div>
                    {
                        conf_habilitar_no_factura && 
                        <div className="form-group bodega-no-factura">
                            <label htmlFor="no_factura">
                                No. Factura
                            </label>
                            <Field
                                name="no_factura"
                                rows={1}
                                component={renderTextArea}
                                className="form-control"
                            />
                        </div>
                    }
                </div>
            </div>
            <div className="slide-container row" style={{margin: "0"}}>
                <div
                    className={`col-lg-6 col-md-12 pr-md-0 ${
                        ver_buscador ? "form-activo" : ""
                    } slide `}
                >
                    <ListadoAccion
                        buscar={buscar(props)}
                        loader={loader}
                        llaves={llavesListado}
                        data={data}
                        listar={listar}
                        sortChange={sortChange}
                        titulo={
                            <div className="d-flex flex-column flex-lg-row m-2">
                                <span
                                    style={{fontSize: "15px"}}
                                    className="align-self-end"
                                >
                                    Listado de Productos
                                </span>
                                <Link
                                    className="btn btn-primary align-self-center ml-auto"
                                    to={{
                                        pathname: "/productos/crear",
                                        state: {deBodega: true},
                                    }}
                                >
                                    <i className="fa fa-plus"/> Agregar
                                    Producto
                                </Link>
                            </div>
                        }
                        page={page}
                    />
                    <div className="d-lg-none d-xl-none grid-container align-items-center mt0 padding-10 d-flex justify-content-around flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                        <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => {
                                cambio_buscador();
                            }}
                        >
                            <img src={icons.cancelar} alt=""/> Cancelar
                        </button>
                    </div>
                </div>
                <div
                    className={`col-lg-6 col-md-12 ${
                        !ver_buscador ? "form-activo" : ""
                    } form-trasero`}
                >
                    <DetalleAccion
                        data={seleccionados}
                        titulo={"Lista de Productos a Ingresar"}
                        llaves={llavesDetalle}
                        loader={loader}
                        agregar={cambio_buscador}
                        quitar={quitar}
                    />
                    <div
                        style={{
                            borderRadius: "0",
                            padding: "4px 2px 2px 2px",
                            borderTop: "none",
                        }}
                        className="grid-container bg-primary mt0 d-flex flex-column flex-md-row justify-content-between"
                    >
                        <div
                            className="d-flex justify-content-around align-items-end p-1 p-md-2 flex-column flex-1"
                            style={{backgroundColor: "#FFF"}}
                        >
                            {/* <button className="d-lg-none d-xl-none btn btn-outline-primary mb-1" type="button" onClick={() => { cambio_buscador() }}>Agregar Producto</button> */}
                            <div className="text-right">
                                <div>Monto total</div>
                                <h5>
                                    <b>
                                        <RenderCurrency value={total} decimalScale={validarConfDecimales(props.conf_empresa)}/>
                                    </b>
                                </h5>
                            </div>
                        </div>
                        <div
                            className="d-flex justify-content-around align-items-center p-1 p-md-2 flex-column flex-3"
                            style={{backgroundColor: "#FFF"}}
                        >
                            <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                <Link
                                    className="btn btn-tertiary mr-1"
                                    to={"/bodega"}
                                >
                                    <img src={icons.cancelar} alt=""/>
                                    Cancelar
                                </Link>
                                <button
                                    disabled={loader}
                                    className="btn btn-primary ml-1"
                                    type="submit"
                                >
                                    <i className="fa fa-check mr"/>
                                    Finalizar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const IngresoProducto = reduxForm({
    // a unique name for the form
    form: "IngresoProducto",
    validate: (data) => {
        return validate(data, {
            proveedor: validators.exists()("Campo requerido"),
            motivo: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default IngresoProducto;
