import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import LoadMask from "Utils/LoadMask/LoadMask"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { RenderDate } from "Utils/renderField/renderReadField"
import { TabProductos, TabAbonosCuentaPagar } from './ComponentesOrdenCompra'
import PagoOrdenCompraForm from './PagoOrdenCompraForm'
import { Link } from 'react-router-dom'
import { PrintContainer, PrintComponent } from "Utils/Print";
import Recibo from "Utils/Recibo";
import Nota from "Utils/Nota";




export class DetalleOrdenCompra extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        marcarEntregaOC: PropTypes.func.isRequired,
        marcarPagoFinalizadoOC: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)
                this.state = { ver_buscador: false };
        this.state = {
            tab: 0
        }
    }

    componentWillMount() {
        this.props.getOC(this.props.match.params.id);
        this.props.listarMovimientos();
    }

    cambioTab = (tabVal) => {
        this.setState({tab: tabVal})
    }

    closeModal = () => {
        this.props.setOpenModalPago(false);
        this.props.limpiarDatosPago();
        this.props.listarMovimientos();
    }
    abrirModalPago = () => {
        this.props.setOpenModalPago(true);
    }
    submit = (data) => {
        const idOC = this.props.match.params.id;
        this.props.registrarPagoAbono(idOC);
    }

    render() {
        
        // State
        const { loader, movimiento, orden_compra, page_retiros, loader_retiros, retiros, fecha_inicial, fecha_final, movimiento_caja, pago_pendiente, open_modal_pago, print_state} = this.props;
        // Bind
        const { finalizarOC, marcarEntregaOC, marcarPagoFinalizadoOC, listarMovimientos, sortChangeRetiros, changeFecha, setMovimientoCaja} = this.props;
        const data_movimiento = movimiento ? movimiento : {detalles: [], usuario: {}}
        const sucursal = orden_compra ? orden_compra.sucursal : {}


        return (
            <div className="row">
            {print_state.flag && ( <PrintComponent/> )}
                <div className="col-12">
                    <div className="grid-container mb-0">
                        <div className="px-3 pt-3 d-flex flex-column mb-1">
                            <div style={{ flex: "1"}}>
                                <h3><b>Detalle orden de compra</b></h3>
                            </div>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <div className={`col-12 d-flex flex-column align-items-center ${!orden_compra && "justify-content-center"}`} style={{ minHeight: "140px" }}>
                                {
                                    orden_compra ? (
                                        <div className="w-100">
                                            <div className="row">
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Proveedor</b>
                                                        {orden_compra.proveedor.nombre}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Fecha</b>
                                                        <RenderDate value={orden_compra.fecha} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Documento</b>
                                                        { orden_compra.num_documento ? orden_compra.num_documento : "-----" }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Nota</b>
                                                        { orden_compra.descripcion }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Estado</b>
                                                        {
                                                            orden_compra.anulado
                                                                ?<span className="text-danger font-weight-bold">ANULADO</span>
                                                                :orden_compra.estado == 10
                                                                    ? <span className="azul font-weight-bold">Activa</span>
                                                                    : <span className="text-success font-weight-bold">Fianalizada</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Estado pago</b>
                                                        { orden_compra.pago_finalizado ? (
                                                            <span className="text-success font-weight-bold">Finalizado</span>
                                                        ): (
                                                            <span className="text-danger font-weight-bold">Pendiente</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Estado ingreso</b>
                                                        { orden_compra.estado_inventario == 100 && (
                                                            <span className="naranja-oscuro font-weight-bold">Pendiente</span>
                                                        )}
                                                        { orden_compra.estado_inventario == 200 &&(
                                                            <span className="azul font-weight-bold">Parcial</span>
                                                        )}
                                                        { orden_compra.estado_inventario == 300 &&(
                                                            <span className="text-success font-weight-bold">Completada</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Sucursal de recepción</b>
                                                        { orden_compra.sucursal_destino.nombre }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Plazo de pago</b>
                                                        { orden_compra.tipo_pago == 2000 ? `${orden_compra.periodo_pago} días` : "Inmediato" }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="contenedor-campos-oc border-bottom">
                                                        <b>Fecha límite de pago</b>
                                                        {
                                                            orden_compra.tipo_pago == 2000 ? (
                                                                <RenderDate className="text-danger font-weight-bold" value={orden_compra.fecha_fin_pago} />
                                                            ): (
                                                                "-----"
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <button className="mt-3 d-lg-none d-block btn btn-secondary" disabled={orden_compra.estado == 20} onClick={() => finalizarOC(orden_compra.id)}>FINALIZAR</button> */}
                                            <div className="mt-3 row margin-x-15-neg">
                                                <Tabs className="content-tabs" selectedIndex={this.state.tab} onSelect={tabIndex => this.cambioTab(tabIndex)}>
                                                    <TabList className="tab_list d-flex flex-row align-items-center">
                                                        <Tab
                                                            className="content-tab-venta py-2 px-3 text-center"
                                                            selectedClassName="content-tab-venta-active">
                                                            PRODUCTOS</Tab>
                                                        <Tab
                                                            className="content-tab-venta py-2 px-3 text-center"
                                                            selectedClassName="content-tab-venta-active">
                                                            PAGOS</Tab>

                                                        {/* <button className="mx-3 d-none d-lg-block btn btn-secondary btn-finalizar" disabled={orden_compra.estado == 20} onClick={() => finalizarOC(orden_compra.id)}>FINALIZAR</button> */}
                                                    </TabList>
                                                    {/* TAB DE LISTADO DE PRODUCTOS */}
                                                    <TabPanel>
                                                        <TabProductos orden_compra={orden_compra} loader={loader} marcarEntregaOC={marcarEntregaOC} />
                                                    </TabPanel>

                                                    {/* TAB DE LISTADO DE ABONOS A LA CUENTA POR PAGAR */}
                                                    <TabPanel>
                                                        <TabAbonosCuentaPagar
                                                            orden_compra={orden_compra}
                                                            marcarPagoFinalizadoOC={marcarPagoFinalizadoOC}
                                                            pagar={this.abrirModalPago}
                                                            revertir={this.props.reveritirMarcarPagoFinalizadoOC}
                                                        />
                                                    </TabPanel>
                                                </Tabs>
                                            </div>
                                        </div>
                                    ) : (
                                            <div className="col-12">
                                                <h3 className="m-0 text-center">No se encontro la orden de compra</h3>
                                            </div>
                                        )
                                }
                            </div>
                        </LoadMask>

                        {/* MODAL DE ABONO A CUENTA POR PAGAR */}
                        <Modal open={open_modal_pago} onClose={this.closeModal} classNames={{modal:"modal-md"}} >
                            <div className="p-2" style={{maxWidth: '600px'}}>
                                <div className="row">
                                    <div className="col-12 mt-2 grid-titulo">Pago orden de compra</div>
                                    <PagoOrdenCompraForm
                                        saldo={orden_compra ? orden_compra.saldo : 0}
                                        pago_pendiente={pago_pendiente}
                                        movimiento_caja={movimiento_caja}
                                        data={retiros}
                                        loader={loader_retiros}
                                        fecha_inicial={fecha_inicial}
                                        fecha_final={fecha_final}
                                        page={page_retiros}
                                        changeFecha={changeFecha}
                                        sortChange={sortChangeRetiros}
                                        listarRetiros={listarMovimientos}
                                        onSubmit={this.submit}
                                        close={this.closeModal}
                                        esOrdenDeCompra={true}
                                        setMovimientoCaja={setMovimientoCaja}
                                        initialValues={{tipo_abono: "1000", forma_pago:"EFE"}}/>
                                </div>
                            </div>
                        </Modal>

                        <div className="col-md-12 mt-4">
                        <PrintContainer name="orden">
                        <Recibo
                            {...this.props}
                            movimiento={orden_compra ? orden_compra:{}}
                            sucursal={ sucursal }
                            tipo={"Ingreso"}
                            footerText={"Costo Total"}
                        />
                    </PrintContainer>
                        </div>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.print('orden');
                                //printActions.print('gastoCompraprod')
                                // window.print();
                            }}
                            className="btn btn-secondary mt-3"
                            type={'button'}
                        >
                            <em className="fa fa-print"/> Imprimir
                        </button>
                        <div className="d-flex justify-content-center flex-row w-100 mt-3 mb-3">
                            <Link className="btn btn-tertiary m-1"  to={"/ordenes_de_compra"}><i className="fa fa-arrow-left" aria-hidden="true" /> Regresar</Link>
                         </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DetalleOrdenCompra;
