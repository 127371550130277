import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/sucursal/sucursal";
import SucursalesCrear from './SucursalesCrear';


const ms2p = (state) => {
  return {
    ...state.sucursal,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(SucursalesCrear);
