import React, { Component } from "react";
import PropTypes from "prop-types";
import CrearGastoForm from "./CrearGastoForm";
import LoadMask from "Utils/LoadMask/LoadMask";
import { api } from "api";


class CrearGasto extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        crear: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
    }

    componentWillMount(){
        this.props.listarMovimientos();
    }

    render() {
        // state
        const { loader, tipo, sucursal, sucursales, retiros, fecha_inicial, fecha_final, movimiento_caja } = this.props;

        // bind
        const { crear, changeFecha, setMovimientoCaja } = this.props;

         return (
            <div className="row">
                <div className="col-sm-12">
                    <LoadMask loading={loader} light blur radius>
                        <div className="form-group grid-container">
                            <div className="grid-titulo padding-15">
                                Registro de gasto
                            </div>
                            <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                                <CrearGastoForm
                                    sucursal={sucursal}
                                    sucursales={sucursales}
                                    onSubmit={crear}
                                    tipo={tipo}
                                    data={retiros}
                                    fecha_final={fecha_final}
                                    tipoPagoValue={1000}
                                    fecha_inicial={fecha_inicial}
                                    changeFecha={changeFecha}
                                    setMovimientoCaja={setMovimientoCaja}
                                    movimiento_caja={movimiento_caja}
                                    initialValues={{tipo_abono: "0", forma_pago:"EFE"}}
                                />
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </div>
        );
    }
}
 
export default CrearGasto;
