import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from '../Crear/ProveedoresCrearForm'
import { api } from "api";
import LoadMask from "Utils/LoadMask/LoadMask";

class ProveedorEditar extends Component {
    static propTypes = {
        editar: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
    }
    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render() {
        // state

        // bind
        const { editar, loader } = this.props;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={loader} light blur radius>
                        <div className="form-group grid-container">
                            <div className="grid-titulo padding-15">
                                Edición de proveedor
                            </div>
                            <div className=" padding-15">
                                <Form onSubmit={editar} />
                            </div>
                        </div>

                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default ProveedorEditar
