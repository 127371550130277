import React, { Component } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import DatePicker from "Utils/DatePicker";
import moment from "moment";

class MejoresClientes extends Component {
    static propTypes = {
        // loader: PropTypes.bool.isRequired,
        data_grafica: PropTypes.array.isRequired,
        fecha_inicial: PropTypes.string.isRequired,
        fecha_final: PropTypes.string.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            width: "100%",
        };
    }

    componentWillMount() {
        this.props.getMejoresCompradores();
    }

    sortChange = (sortName, sortOrder) => {
        let sort = sortName
        if (sortOrder !== "asc") {
            sort = `-${sortName}`;
        }
        this.props.getMejoresCompradores(sort)
    }

    render() {
        //  state
        const {
            loader,
            data_grafica,
            fecha_inicial,
            fecha_final,
            pais,
            estado_descarga,
        } = this.props;
        //  Bind
        const { descargarListado } = this.props;
        const moneda = pais == "GT" ? "Q " : "$ ";

        const data = {
            datasets: [
                {
                    label: `Mejores Clientes`,
                    data: [],
                    backgroundColor: "rgba(255, 192, 0, 0.65)",
                    borderWidth: 1,
                },
            ],
            labels: [],
        };
        data_grafica.forEach((cliente) => {
            data.labels.push(cliente.nombre);
            data.datasets[0].data.push(cliente.comprado);
        });
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.onSortChange = this.sortChange;
        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                            <div style={{ flex: "1" }}>
                                <h3>
                                    <b>Top 10 Mejores Clientes</b>
                                </h3>
                            </div>
                            <div
                                className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                style={{ flex: "3" }}
                            >
                                <div>
                                    <label>Fecha Inicio</label>
                                    <DatePicker
                                        value={fecha_inicial}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            this.props.setFecha(
                                                "Inicial",
                                                date
                                            );
                                        }}
                                    />
                                </div>
                                &nbsp; &nbsp;
                                <div>
                                    <label>Fecha Fin</label>
                                    <DatePicker
                                        value={fecha_final}
                                        minDate={
                                            new Date(
                                                moment(
                                                    fecha_inicial,
                                                    "YYYY-MM-DD"
                                                )
                                            )
                                        }
                                        onChange={(date) => {
                                            this.props.setFecha("Final", date);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <div className="row mb-4">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <Bar
                                        height={500}
                                        data={data}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                xAxes: [
                                                    {
                                                        stacked: true,
                                                        barThickness: 20,
                                                    },
                                                ],
                                                yAxes: [
                                                    {
                                                        stacked: true,
                                                        ticks: {
                                                            beginAtZero: true,
                                                            callback: function (
                                                                value,
                                                                index,
                                                                values
                                                            ) {
                                                                value = parseFloat(
                                                                    value
                                                                );
                                                                let value_abs = Math.abs(
                                                                    value
                                                                );
                                                                if (
                                                                    value_abs >=
                                                                    1000
                                                                ) {
                                                                    return (
                                                                        moneda +
                                                                        value
                                                                            .toFixed(
                                                                                2
                                                                            )
                                                                            .replace(
                                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                                ","
                                                                            )
                                                                    );
                                                                } else {
                                                                    return (
                                                                        moneda +
                                                                        value.toFixed(
                                                                            2
                                                                        )
                                                                    );
                                                                }
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                            elements: {
                                                line: {
                                                    tension: 0.000001,
                                                },
                                            },
                                            tooltips: {
                                                callbacks: {
                                                    label: function (
                                                        tooltipItem,
                                                        data
                                                    ) {
                                                        let value =
                                                            tooltipItem.yLabel;

                                                        value = parseFloat(
                                                            value
                                                        );
                                                        let value_abs = Math.abs(
                                                            value
                                                        );
                                                        if (value_abs >= 1000) {
                                                            return (
                                                                data.datasets[
                                                                    tooltipItem
                                                                        .datasetIndex
                                                                ].label +
                                                                `: ${moneda}` +
                                                                value
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ","
                                                                    )
                                                            );
                                                        } else {
                                                            return (
                                                                data.datasets[
                                                                    tooltipItem
                                                                        .datasetIndex
                                                                ].label +
                                                                `: ${moneda}` +
                                                                value.toFixed(2)
                                                            );
                                                        }
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </LoadMask>
                        <LoadMask loading={loader} dark blur>
                            <div className="d-flex flex-row w-100 p-3">
                                <button
                                    className="btn btn-secondary mb-sm-10 align-self-center"
                                    disabled={estado_descarga}
                                    onClick={() => {
                                        descargarListado();
                                    }}
                                >
                                    <em
                                        className={`fa ${
                                            estado_descarga
                                                ? "fa-spinner fa-pulse"
                                                : "fa-download"
                                        }`}
                                    ></em>
                                    &nbsp;Descargar Listado
                                </button>
                            </div>
                            <BootstrapTable
                                data={loader ? [] : data_grafica}
                                remote
                                hover
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="nombre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataSort
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={(cell) => (cell ? cell : "C.F")}
                                    dataField="nit"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    isKey
                                    dataSort
                                >
                                    Nit
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="direccion"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell) => (cell ? cell : "---")}
                                    dataSort
                                >
                                    Direccion
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={(cell) => (cell ? cell : "---")}
                                    dataField="contacto"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataSort
                                >
                                    Contacto
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={(cell) => (cell ? cell : "---")}
                                    dataField="telefono"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataSort
                                >
                                    Teléfono
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="comprado"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="right"
                                    dataFormat={(cell) => {
                                        return <RenderMoneda monto={cell} />;
                                    }}
                                    dataSort
                                >
                                    Total Comprado
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default MejoresClientes;
