import { connect } from 'react-redux';
import { actions } from "../../../redux/modules/punto_venta/punto_venta";
import { setNavbarCollapse } from "../../../redux/modules/dashboard/dashboard";
import PuntoVenta from './PuntoVenta';


const ms2p = (state) => {
    return {
        ...state.punto_venta,
        caja: state.caja.ultimo_cierre,
        loaderUsuario: state.usuario.loader,
        sucursal: state.usuario.sucursal,
    };
};

const md2p = {
    ...actions,
    setNavbarCollapse: (estado) => dispatch => dispatch(setNavbarCollapse(estado))
};

export default connect(ms2p, md2p)(PuntoVenta);
