import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { default as GraficaVentas } from "../Grafica";
import Select, { Async } from "react-select";
import { api } from "api";
import { icons } from "icons";
import { PrintContainer, PrintComponent } from "Utils/Print";
import Recibo from "Utils/Recibo";
import ReciboVentaCredito from "Utils/ReciboVentaCredito";
import Factura from "Utils/Factura";
import DatePicker from "Utils/DatePicker";
import moment from "moment";
import {
    tipo_movimientos,
    FACTURADO,
    PROCESO,
    ANULADA,
    ERROR,
} from "variables";

class ListadoVentas extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        fecha_inicial: PropTypes.string.isRequired,
        fecha_final: PropTypes.string.isRequired,
    };
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.listar(this.props.page);
    }

    render() {
        // state
        const {
            loader,
            data,
            fecha_inicial,
            fecha_final,
            estado,
            conf_ventas_al_credito,
            factura_personalizada,
            venta_impresion,
            recibo_caja_impresion,
            formato_factura,
            datos_venta,
            utiliza_fel,
            estado_descarga,
            estado_factura,
            filtro_fecha,
            search,
        } = this.props;
        // bind
        const {
            anular,
            estadoChange,
            seleccionImpresion,
            retimbradoFEL,
            print_state,
            descargarListado,
            estadoFacturaChange,
            filtroFechaChange,
            buscar,
        } = this.props;
        const options = _.cloneDeep(tableOptions);

        const getUsuarios = (search) => {
            return api
                .get("historial_ventas/usuarios", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data };
                });
        };

        const getCliente = (search) => {
            return api
                .get("clientes", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data.results };
                });
        };

        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        const accionesReciboFactura = (id, data) => {
            const timbrado = data.datos_fel
                ? data.datos_fel.uuid ||
                  data.datos_fel.numero ||
                  data.datos_fel.serie
                    ? true
                    : false
                : false;
            const datos_anulacion = data.datos_fel
                ? data.datos_fel.datos_anulacion &&
                  data.datos_fel.respuesta_anulacion
                    ? true
                    : false
                : false;
            const impresion_recibo_caja = data.movimiento
                ? data.movimiento.tipo_pago == 200 || data.movimiento.tipo == 80
                    ? true
                    : false
                : false;
            return (
                <span>
                    <img
                        onClick={(e) => {
                            e.preventDefault();
                            seleccionImpresion(id, "reporteVentasReimpresion");
                        }}
                        className="action-img"
                        title={`Reimprimir ${
                            factura_personalizada || utiliza_fel
                                ? "factura"
                                : "recibo"
                        }`}
                        src={utiliza_fel ? icons.fel : icons.recibo}
                        alt={`Reimprimir ${
                            factura_personalizada ? "factura" : "recibo"
                        }`}
                        style={{ maxWidth: "22px" }}
                    />
                    {impresion_recibo_caja && (
                        <img
                            onClick={(e) => {
                                e.preventDefault();
                                seleccionImpresion(
                                    id,
                                    "reporteVentasReimpresionReciboCaja"
                                );
                            }}
                            className="action-img"
                            title="Reimprimir recibo de caja"
                            src={icons.recibo_caja}
                            alt="Reimprimir recibo de caja"
                            style={{ maxWidth: "22px" }}
                        />
                    )}
                    {utiliza_fel && !timbrado && !datos_anulacion && (
                        <img
                            onClick={(e) => {
                                e.preventDefault();
                                retimbradoFEL(id);
                            }}
                            className="action-img"
                            title="Retimbrar factura"
                            src={icons.timbrar_factura}
                            alt="Retimbrar factura"
                            style={{ maxWidth: "22px" }}
                        />
                    )}
                </span>
            );
        };

        const accionReciboCaja = (id) => {
            if (id) {
                return (
                    <span>
                        <img
                            onClick={(e) => {
                                e.preventDefault();
                                seleccionImpresion(
                                    id,
                                    "reporteVentasReimpresionReciboCaja"
                                );
                            }}
                            className="action-img"
                            title="Reimprimir recibo de caja"
                            src={icons.recibo}
                            alt="Reimprimir recibo de caja"
                            style={{ maxWidth: "22px" }}
                        />
                    </span>
                );
            }
            return "";
        };

        const estados_factura = [
            { value: 10, label: "En proceso" },
            { value: 20, label: "Facturada" },
            { value: 30, label: "Anulada" },
            { value: 40, label: "Error" },
            { value: 50, label: "Pendiente de facturación" },
        ];

        return (
            <div>
                {print_state.flag && <PrintComponent />}
                <GraficaVentas />
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div style={{ flex: "1" }}>
                                    <h3>
                                        <b>Historial de ventas</b>
                                    </h3>
                                </div>
                                <div
                                    className="d-flex flex-wrap flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    {utiliza_fel && (
                                        <div className="flex2">
                                            <label>Filtrar por</label>
                                            <div className="d-flex flex-column">
                                                <div className="d-flex flex-row">
                                                    <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                        <label className="negro-dark2 font-weight-normal">
                                                            <input
                                                                name="filtro_fecha"
                                                                type="radio"
                                                                value="true"
                                                                checked={
                                                                    filtro_fecha ===
                                                                    "fecha_venta"
                                                                }
                                                                onChange={() => {
                                                                    filtroFechaChange(
                                                                        "fecha_venta"
                                                                    );
                                                                }}
                                                            />
                                                            <span />
                                                            Fecha venta
                                                        </label>
                                                    </div>
                                                    <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                        <label className="negro-dark2 font-weight-normal">
                                                            <input
                                                                name="filtro_fecha"
                                                                type="radio"
                                                                value="false"
                                                                checked={
                                                                    filtro_fecha ===
                                                                    "fecha_facturacion"
                                                                }
                                                                onChange={() => {
                                                                    filtroFechaChange(
                                                                        "fecha_facturacion"
                                                                    );
                                                                }}
                                                            />
                                                            <span />
                                                            Fecha facturación
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div style={{ flex: 1 }}>
                                        <label>Fecha Inicio</label>
                                        <DatePicker
                                            value={fecha_inicial}
                                            maxDate={new Date()}
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Inicial",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: 1 }}>
                                        <label>Fecha Fin</label>
                                        <DatePicker
                                            value={fecha_final}
                                            minDate={
                                                new Date(
                                                    moment(
                                                        fecha_inicial,
                                                        "YYYY-MM-DD"
                                                    )
                                                )
                                            }
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Final",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: 1 }}>
                                        <label>Vendedor</label>
                                        <Async
                                            className="form-control"
                                            labelKey="first_name"
                                            valueKey="id"
                                            searchPromptText="Escriba para buscar"
                                            loadOptions={getUsuarios}
                                            placeholder={"Vendedor"}
                                            onChange={(e) => {
                                                this.props.setUsuario(e);
                                            }}
                                            value={this.props.usuario}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: 1 }}>
                                        <label>Cliente</label>
                                        <Async
                                            className="form-control"
                                            labelKey="nombre"
                                            valueKey="id"
                                            searchPromptText="Escriba para buscar"
                                            loadOptions={getCliente}
                                            placeholder={"Cliente"}
                                            onChange={(e) => {
                                                this.props.setCliente(e);
                                            }}
                                            value={this.props.cliente}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div className="mt-10">
                                        <label htmlFor="estado">Estado</label>
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row">
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="estado"
                                                            type="radio"
                                                            value="true"
                                                            checked={
                                                                estado === true
                                                            }
                                                            onChange={() => {
                                                                estadoChange(
                                                                    true
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        Activo
                                                    </label>
                                                </div>
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="estado"
                                                            type="radio"
                                                            value="false"
                                                            checked={
                                                                estado === false
                                                            }
                                                            onChange={() => {
                                                                estadoChange(
                                                                    false
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        Anulado
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp; &nbsp;
                                    {utiliza_fel && (
                                        <div className="mt-10 col-12 col-md-3 col-lg-2">
                                            <label>Estado Factura</label>
                                            <div>
                                                <Select
                                                    isClearable
                                                    placeholder="Seleccionar"
                                                    className="form-control"
                                                    options={estados_factura}
                                                    valueKey="value"
                                                    labelKey="label"
                                                    value={estado_factura}
                                                    onChange={(e) => {
                                                        estadoFacturaChange(
                                                            e ? e.value : ""
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    &nbsp; &nbsp;
                                    {utiliza_fel && (
                                        <div className="mt-10 col-12 col-md-4 col-lg-3">
                                            <label>Buscar Factura</label>
                                            <div
                                                className="w-100"
                                                style={{
                                                    position: "relative",
                                                }}
                                            >
                                                <input
                                                    id="buscar"
                                                    type="text"
                                                    name="buscar"
                                                    placeholder="Ingrese número o serie"
                                                    ref={(node) => {
                                                        this.buscar = node;
                                                        if (this.buscar) {
                                                            this.buscar.value = search;
                                                        }
                                                    }}
                                                    // onKeyPress={(e) => {
                                                    //     if (e.key === "Enter") {
                                                    //         e.preventDefault();
                                                    //         if (
                                                    //             this.buscar
                                                    //                 .value
                                                    //         ) {
                                                    //             buscar(
                                                    //                 this.buscar
                                                    //                     .value
                                                    //             );
                                                    //         }
                                                    //     }
                                                    // }}
                                                    onChange={() => {
                                                        if (this.buscar) {
                                                            buscar(
                                                                this.buscar
                                                                    .value
                                                            );
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <LoadMask loading={loader} light blur>
                                <div className="d-flex flex-row content-total-reports w-100 mt-10">
                                    <button
                                        className="ml-3 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em
                                            className={`fa ${
                                                estado_descarga
                                                    ? "fa-spinner fa-pulse"
                                                    : "fa-download"
                                            }`}
                                        ></em>
                                        &nbsp;Descargar Listado
                                    </button>
                                    <div className="d-flex flex-sm-row justify-content-end w-100 mt-10">
                                        <h3 className="padding-10 margin-0">
                                            <b>Total</b>
                                        </h3>
                                        <h3 className="padding-10 margin-0">
                                            <b>
                                                <RenderCurrency
                                                    value={
                                                        data.total
                                                            ? data.total
                                                            : 0
                                                    }
                                                />
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="creado"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderDateTime fecha={cell} />
                                            );
                                        }}
                                    >
                                        Fecha venta
                                    </TableHeaderColumn>
                                    {utiliza_fel && (
                                        <TableHeaderColumn
                                            dataSort
                                            dataField="fel__fechaCertificacion"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                if (!row.datos_fel) {
                                                    return "";
                                                }
                                                return (
                                                    <RenderDateTime
                                                        fecha={
                                                            row.datos_fel
                                                                .fechaCertificacion
                                                        }
                                                    />
                                                );
                                            }}
                                        >
                                            Fecha facturación
                                        </TableHeaderColumn>
                                    )}
                                    {/*----------- CONFIGURACION DE VENTAS AL CREDITO -----------*/}
                                    {conf_ventas_al_credito &&
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="cuenta_cobrar__creado"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            if (row.cuenta_cobrar) {
                                                return (
                                                    <RenderDateTime
                                                        fecha={
                                                            row.cuenta_cobrar
                                                                .creado
                                                        }
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <span>Sin Registro</span>
                                                );
                                            }
                                        }}
                                    >
                                        Fecha crédito
                                    </TableHeaderColumn>
                                    }
                                    <TableHeaderColumn
                                        dataField="mostrador"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        // dataFormat={(cell, row) => {
                                        //     return row.usuario;
                                        // }}
                                    >
                                        Vendedor
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="usuario__first_name"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                         dataFormat={(cell, row) => {
                                            return row.usuario;
                                        }}
                                    >
                                        Cajero
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="codigo"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <span>
                                                    {
                                                        row.movimiento
                                                            .tipo_movimiento
                                                    }{" "}
                                                    {row && row.codigo ?  row.codigo : ""}
                                                </span>
                                            );
                                        }}
                                    >
                                        Código
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        datasort
                                        dataField="movimiento__concepto"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return row.movimiento.concepto;
                                        }}
                                    >
                                        Nota
                                    </TableHeaderColumn>
                                    {utiliza_fel && (
                                        <TableHeaderColumn
                                            dataField="datos_fel"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                if (row.datos_fel) {
                                                    return (
                                                        <span>
                                                            {row.datos_fel
                                                                .numero
                                                                ? row.datos_fel
                                                                      .numero
                                                                : ""}
                                                        </span>
                                                    );
                                                } else {
                                                    return <span></span>;
                                                }
                                            }}
                                        >
                                            Número
                                        </TableHeaderColumn>
                                    )}
                                    {utiliza_fel && (
                                        <TableHeaderColumn
                                            dataField="datos_fel"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                if (row.datos_fel) {
                                                    return (
                                                        <span>
                                                            {row.datos_fel.serie
                                                                ? row.datos_fel
                                                                      .serie
                                                                : ""}
                                                        </span>
                                                    );
                                                } else {
                                                    return <span></span>;
                                                }
                                            }}
                                        >
                                            Serie
                                        </TableHeaderColumn>
                                    )}
                                    {utiliza_fel && (
                                        <TableHeaderColumn
                                            dataField="datos_fel"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                const datos_anulacion = row.datos_fel
                                                    ? row.datos_fel
                                                          .datos_anulacion &&
                                                      row.datos_fel
                                                          .respuesta_anulacion
                                                        ? true
                                                        : false
                                                    : false;
                                                return (
                                                    <div>
                                                        {cell ? (
                                                            <div>
                                                                {cell.estado_factura ==
                                                                    FACTURADO && (
                                                                    <span className="text-success font-weight-bold">
                                                                        Facturada
                                                                    </span>
                                                                )}
                                                                {cell.estado_factura ==
                                                                    PROCESO && (
                                                                    <span className="text-warning font-weight-bold">
                                                                        En
                                                                        proceso
                                                                    </span>
                                                                )}
                                                                {cell.estado_factura ==
                                                                    ANULADA && (
                                                                    <span className="text-danger font-weight-bold">
                                                                        Anulada
                                                                    </span>
                                                                )}
                                                                {cell.estado_factura ==
                                                                    ERROR &&
                                                                    !datos_anulacion && (
                                                                        <span className="text-danger font-weight-bold d-flex">
                                                                            Error
                                                                            <div className="compralo-img-popover">
                                                                                {/* <img
                                                                                    className="action-img"
                                                                                    title="Mensajes de error"
                                                                                    src={
                                                                                        icons.error_fel
                                                                                    }
                                                                                    alt="Mensajes de error"
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "22px",
                                                                                    }}
                                                                                /> */}
                                                                                <i className="fa fa-eye text-danger ml-2"></i>
                                                                                <span>
                                                                                    {
                                                                                        row
                                                                                            .datos_fel
                                                                                            .errores_fel
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </span>
                                                                    )}
                                                                {cell.estado_factura ==
                                                                    ERROR &&
                                                                    datos_anulacion && (
                                                                        <span className="text-danger font-weight-bold">
                                                                            Error
                                                                            en
                                                                            anulación
                                                                        </span>
                                                                    )}
                                                            </div>
                                                        ) : row.movimiento
                                                              .tipo == 30 ? (
                                                            <span className="text-warning font-weight-bold">
                                                                Pendiente de
                                                                facturación
                                                            </span>
                                                        ) : (
                                                            <span>-----</span>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        >
                                            Estado factura
                                        </TableHeaderColumn>
                                    )}
                                    {!estado && (
                                        <TableHeaderColumn
                                            datasort
                                            dataField="anulado_por"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <div>
                                                        {cell ? (
                                                            <span>{cell}</span>
                                                        ) : (
                                                            <span>-----</span>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        >
                                            Anulado por
                                        </TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="cliente__nombre"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return row.cliente;
                                        }}
                                    >
                                        Comprador
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="referencia"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return row.referencia;
                                        }}
                                    >
                                        Referencia
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn
                                        dataField="id"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <div>
                                                    {row.movimiento.monto_efectivo > 0 && (
                                                        <h6><span className="badge badge-light">Efectivo</span></h6>
                                                    )}
                                                    {row.movimiento.monto_tarjeta > 0 && (
                                                        <h6><span className="badge badge-light">Tarjeta</span></h6>
                                                    )}
                                                    {row.movimiento.monto_cheque > 0 && (
                                                        <h6><span className="badge badge-light">Cheque</span></h6>
                                                    )}
                                                    {row.movimiento.monto_deposito > 0 && (
                                                        <h6><span className="badge badge-light">Depósito</span></h6>
                                                    )}
                                                </div>
                                            )
                                        }}
                                    >
                                        Pago con
                                    </TableHeaderColumn> */}
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="movimiento__total_orginal"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda
                                                    monto={
                                                        row.movimiento
                                                            .total_cobrar_pagar
                                                    }
                                                />
                                            );
                                        }}
                                    >
                                        Pago de
                                    </TableHeaderColumn>
                                    {/*----------- CONFIGURACION DE VENTAS AL CREDITO -----------*/}
                                    {conf_ventas_al_credito &&
                                    <TableHeaderColumn
                                        datasort
                                        dataField="cuenta_cobrar"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda
                                                    monto={
                                                        row.cuenta_cobrar
                                                            ? row.cuenta_cobrar
                                                                .saldo
                                                            : 0
                                                    }
                                                />
                                            );
                                        }}
                                    >
                                        Saldo crédito
                                    </TableHeaderColumn>
                                    }
                                    {estado ? (
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            width="150px"
                                            dataAlign="center"
                                            dataFormat={(cell, row) => {
                                                if (
                                                    row.movimiento.tipo ===
                                                    tipo_movimientos.VENTA 
                                                ) {
                                                        return activeFormatter({
                                                            ver: "/reporte_ventas",
                                                            anular,
                                                            adicional: accionesReciboFactura,
                                                            datos_adicional: row,
                                                            anular_activo:
                                                                row.caja_activa,
                                                        })(cell, row);
                                                }  

                                                else if (
                                                    row.movimiento.tipo ==
                                                    tipo_movimientos.ABONO_CUENTA
                                                ) {
                                                    return activeFormatter({
                                                        anular,
                                                        adicional: accionReciboCaja,
                                                    })(cell, row);
                                                } else {
                                                    return activeFormatter({
                                                        anular,
                                                    })(cell, row);
                                                }
                                            }}
                                        >
                                            Acciones
                                        </TableHeaderColumn>
                                    ) : (
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            width="150px"
                                            dataAlign="center"
                                            dataFormat={(cell, row) => {
                                                if (
                                                    row.movimiento.tipo ===
                                                    tipo_movimientos.VENTA
                                                ) {
                                                    return activeFormatter({
                                                        ver: "/reporte_ventas",
                                                    })(cell, row);
                                                } else {
                                                    return <span></span>;
                                                }
                                            }}
                                        >
                                            Acciones
                                        </TableHeaderColumn>
                                    )}
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                    </div>
                    {
                        // RECIBO Y FACTURA
                        !factura_personalizada ? (
                            <div className="w100 print-only">
                                <PrintContainer name="reporteVentasReimpresion">
                                    {venta_impresion && (
                                        <Recibo
                                            sucursal={venta_impresion.sucursal}
                                            movimiento={venta_impresion}
                                            usuario={
                                                venta_impresion.usuario
                                                    .first_name
                                                    ? venta_impresion.usuario
                                                          .first_name
                                                    : ""
                                            }
                                            footerText={"TOTAL"}
                                            tipo={"Venta"}
                                            utiliza_fel={utiliza_fel}
                                        />
                                    )}
                                </PrintContainer>
                            </div>
                        ) : (
                            <div className="w100 print-only">
                                <PrintContainer
                                    name="reporteVentasReimpresion"
                                    className="impresion-venta d-table w-100"
                                >
                                    {venta_impresion &&
                                        venta_impresion.map(
                                            (factura, index) => {
                                                return (
                                                    <Factura
                                                        className={`${
                                                            index != 0
                                                                ? "salto-pagina"
                                                                : ""
                                                        }`}
                                                        key={index}
                                                        datos_venta={factura}
                                                        formato_factura={
                                                            formato_factura
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                </PrintContainer>
                            </div>
                        )
                    }

                    {/* RECIBO VENTA CREDITO */}
                    <div className="w100 print-only">
                        <PrintContainer
                            name="reporteVentasReimpresionReciboCaja"
                            className="impresion-venta d-table w-100"
                        >
                            {recibo_caja_impresion && (
                                <ReciboVentaCredito
                                    sucursal={recibo_caja_impresion.sucursal}
                                    movimiento={recibo_caja_impresion}
                                    utiliza_fel={utiliza_fel}
                                />
                            )}
                        </PrintContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListadoVentas;
