import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PrintContainer extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired
    }

    render() {
        const { children, name, className} = this.props
        return (
            <div id={"PRINT-" + name} className={className}>
                {children}
            </div>
        )
    }
}
