import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderPorcentaje,
} from "../../../Utils/renderField/renderField";
import { RenderCurrency as RCurrency } from "Utils/renderField/renderReadField";
import { Link } from "react-router-dom";
import { icons } from "icons";
import { preciosSucursal } from "../../camposProducto";
const prodGrande = require("../../../../../../assets/img/icons/producto-grande.png");
const prodFraccion = require("../../../../../../assets/img/icons/producto-fraccion.png");

const estructuraDatos = {
    nombre: "",
    capacidad_maxima: 1,
    opcion: "grande",
    precio: "",
    precio2: "",
    precio3: "",
    precio4: "",
    precio5: "",
    codigo_barras: "",
    minimo_existencias: "",
    vendible: false,
    alertar: false,
    precios_sucursal: [],
};

const validate = (values) => {
    const errors = {};
    if (!values.nombre) errors.nombre = "Campo requerido";

    if (!values.precio) errors.precio = "Campo requerido";

    if (!values.opcion) errors.opcion = "Campo requerido";

    // if(!values.opcion) {
    //     if(!values.precio)
    //         errors.precio = 'Campo requerido';

    //     // if(!values.codigo_barras)
    //     //     errors.codigo_barras = 'Campo requerido';
    // }

    if (values.codigo_barras) {
        if (values.codigo_barras.length > 20)
            errors.codigo_barras = "Debe tener 20 caracteres o menos.";
        if (!/^\w+$/i.test(values.codigo_barras))
            errors.codigo_barras = "Debe tener solo números y/o letras.";
    }
    if (values.descripcion_codigo_barras) {
        if (values.descripcion_codigo_barras.length > 20)
            errors.descripcion_codigo_barras =
                "Debe tener 20 caracteres o menos.";
    }
    if (values.alertar) {
        if (!values.minimo_existencias)
            errors.minimo_existencias = "Campo requerido";
    }
    if (values.precios_sucursal) {
        if (values.precios_sucursal.length) {
            const preciosSucursalArrayErrors = [];
            values.precios_sucursal.forEach((precio_sucursal, index) => {
                const precioSucursalErrors = {};
                if (!precio_sucursal["precio"])
                    precioSucursalErrors.precio = "Campo requerido";
                preciosSucursalArrayErrors[index] = precioSucursalErrors;
            });

            if (preciosSucursalArrayErrors.length)
                errors.precios_sucursal = preciosSucursalArrayErrors;
        }
    }
    return errors;
};

const DatosFraccion = (props) => {
    //  state
    const {
        alertarValue,
        editCreateFraccion,
        nombreValue,
        sucursales,
        sucursal,
        sucursal_activo,
        nombres_precios,
        conf_porcentaje_precio,
        precio2Value,
        precio3Value,
        precio4Value,
        precio5Value,
        precios_sucursalValue,
    } = props;

    //  bind
    const {
        changeSucursal,
        changeSucursalActivo,
        cambioPorcentajePrecio,
    } = props;
    return (
        <form>
            <div className="row row-fields">
                <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                    <label htmlFor="nombre">Nombre *</label>
                    <Field
                        name="nombre"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                    <label htmlFor="codigo_barras">Código de barras</label>
                    <Field
                        name="codigo_barras"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                    <label htmlFor="descripcion_codigo_barras">
                        Descripción código de barras
                    </label>
                    <Field
                        name="descripcion_codigo_barras"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-12 form-group mb-1 mt-3">
                    <label htmlFor="precio">Precios generales</label>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                    {nombres_precios ? (
                        <label htmlFor="precio">
                            {nombres_precios.nombre_precio} *
                        </label>
                    ) : (
                        <label htmlFor="precio">Precio normal *</label>
                    )}
                    <Field
                        name="precio"
                        component={renderCurrency}
                        decimalScale={2}
                        type="text"
                        className="form-control"
                        onChange={(event) => {
                            setTimeout(() => {
                                cambioPorcentajePrecio(
                                    "porcent_precio2",
                                    "FraccionCrear"
                                );
                                cambioPorcentajePrecio(
                                    "porcent_precio3",
                                    "FraccionCrear"
                                );
                                cambioPorcentajePrecio(
                                    "porcent_precio4",
                                    "FraccionCrear"
                                );
                                cambioPorcentajePrecio(
                                    "porcent_precio5",
                                    "FraccionCrear"
                                );
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                    {nombres_precios ? (
                        <label htmlFor="precio2">
                            {conf_porcentaje_precio && (
                                <span className="mr-2">%</span>
                            )}
                            {nombres_precios.nombre_precio2}
                            {conf_porcentaje_precio && (
                                <span className="ml-2 negro-dark2">
                                    (
                                    <RCurrency
                                        value={
                                            precio2Value
                                                ? parseFloat(precio2Value)
                                                : 0
                                        }
                                    />
                                    )
                                </span>
                            )}
                        </label>
                    ) : (
                        <label htmlFor="precio2">
                            {conf_porcentaje_precio && (
                                <span className="mr-2">%</span>
                            )}
                            Precio 2
                            {conf_porcentaje_precio && (
                                <span className="ml-2 negro-dark2">
                                    (
                                    <RCurrency
                                        value={
                                            precio2Value
                                                ? parseFloat(precio2Value)
                                                : 0
                                        }
                                    />
                                    )
                                </span>
                            )}
                        </label>
                    )}
                    {conf_porcentaje_precio ? (
                        <Field
                            name="porcent_precio2"
                            component={renderPorcentaje}
                            decimalScale={2}
                            type="number"
                            className="form-control"
                            onChange={(event) => {
                                setTimeout(() => {
                                    cambioPorcentajePrecio(
                                        "porcent_precio2",
                                        "FraccionCrear"
                                    );
                                });
                            }}
                        />
                    ) : (
                        <Field
                            name="precio2"
                            component={renderCurrency}
                            decimalScale={2}
                            type="number"
                            className="form-control"
                            onChange={(event) => {
                                setTimeout(() => {
                                    cambioPorcentajePrecio(
                                        "precio2",
                                        "FraccionCrear"
                                    );
                                });
                            }}
                        />
                    )}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                    {nombres_precios ? (
                        <label htmlFor="precio3">
                            {conf_porcentaje_precio && (
                                <span className="mr-2">%</span>
                            )}
                            {nombres_precios.nombre_precio3}
                            {conf_porcentaje_precio && (
                                <span className="ml-2 negro-dark2">
                                    (
                                    <RCurrency
                                        value={
                                            precio3Value
                                                ? parseFloat(precio3Value)
                                                : 0
                                        }
                                    />
                                    )
                                </span>
                            )}
                        </label>
                    ) : (
                        <label htmlFor="precio3">
                            {conf_porcentaje_precio && (
                                <span className="mr-2">%</span>
                            )}
                            Precio 3
                            {conf_porcentaje_precio && (
                                <span className="ml-2 negro-dark2">
                                    (
                                    <RCurrency
                                        value={
                                            precio3Value
                                                ? parseFloat(precio3Value)
                                                : 0
                                        }
                                    />
                                    )
                                </span>
                            )}
                        </label>
                    )}
                    {conf_porcentaje_precio ? (
                        <Field
                            name="porcent_precio3"
                            component={renderPorcentaje}
                            decimalScale={2}
                            type="number"
                            className="form-control"
                            onChange={(event) => {
                                setTimeout(() => {
                                    cambioPorcentajePrecio(
                                        "porcent_precio3",
                                        "FraccionCrear"
                                    );
                                });
                            }}
                        />
                    ) : (
                        <Field
                            name="precio3"
                            component={renderCurrency}
                            decimalScale={2}
                            type="number"
                            className="form-control"
                            onChange={(event) => {
                                setTimeout(() => {
                                    cambioPorcentajePrecio(
                                        "precio3",
                                        "FraccionCrear"
                                    );
                                });
                            }}
                        />
                    )}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                    {nombres_precios ? (
                        <label htmlFor="precio4">
                            {conf_porcentaje_precio && (
                                <span className="mr-2">%</span>
                            )}
                            {nombres_precios.nombre_precio4}
                            {conf_porcentaje_precio && (
                                <span className="ml-2 negro-dark2">
                                    (
                                    <RCurrency
                                        value={
                                            precio4Value
                                                ? parseFloat(precio4Value)
                                                : 0
                                        }
                                    />
                                    )
                                </span>
                            )}
                        </label>
                    ) : (
                        <label htmlFor="precio4">
                            {conf_porcentaje_precio && (
                                <span className="mr-2">%</span>
                            )}
                            Precio 4
                            {conf_porcentaje_precio && (
                                <span className="ml-2 negro-dark2">
                                    (
                                    <RCurrency
                                        value={
                                            precio4Value
                                                ? parseFloat(precio4Value)
                                                : 0
                                        }
                                    />
                                    )
                                </span>
                            )}
                        </label>
                    )}
                    {conf_porcentaje_precio ? (
                        <Field
                            name="porcent_precio4"
                            component={renderPorcentaje}
                            decimalScale={2}
                            type="number"
                            className="form-control"
                            onChange={(event) => {
                                setTimeout(() => {
                                    cambioPorcentajePrecio(
                                        "porcent_precio4",
                                        "FraccionCrear"
                                    );
                                });
                            }}
                        />
                    ) : (
                        <Field
                            name="precio4"
                            component={renderCurrency}
                            decimalScale={2}
                            type="number"
                            className="form-control"
                            onChange={(event) => {
                                setTimeout(() => {
                                    cambioPorcentajePrecio(
                                        "precio4",
                                        "FraccionCrear"
                                    );
                                });
                            }}
                        />
                    )}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                    {nombres_precios ? (
                        <label htmlFor="precio5">
                            {conf_porcentaje_precio && (
                                <span className="mr-2">%</span>
                            )}
                            {nombres_precios.nombre_precio5}
                            {conf_porcentaje_precio && (
                                <span className="ml-2 negro-dark2">
                                    (
                                    <RCurrency
                                        value={
                                            precio5Value
                                                ? parseFloat(precio5Value)
                                                : 0
                                        }
                                    />
                                    )
                                </span>
                            )}
                        </label>
                    ) : (
                        <label htmlFor="precio5">
                            {conf_porcentaje_precio && (
                                <span className="mr-2">%</span>
                            )}
                            Precio 5
                            {conf_porcentaje_precio && (
                                <span className="ml-2 negro-dark2">
                                    (
                                    <RCurrency
                                        value={
                                            precio5Value
                                                ? parseFloat(precio5Value)
                                                : 0
                                        }
                                    />
                                    )
                                </span>
                            )}
                        </label>
                    )}
                    {conf_porcentaje_precio ? (
                        <Field
                            name="porcent_precio5"
                            component={renderPorcentaje}
                            decimalScale={2}
                            type="number"
                            className="form-control"
                            onChange={(event) => {
                                setTimeout(() => {
                                    cambioPorcentajePrecio(
                                        "porcent_precio5",
                                        "FraccionCrear"
                                    );
                                });
                            }}
                        />
                    ) : (
                        <Field
                            name="precio5"
                            component={renderCurrency}
                            decimalScale={2}
                            type="number"
                            className="form-control"
                            onChange={(event) => {
                                setTimeout(() => {
                                    cambioPorcentajePrecio(
                                        "precio5",
                                        "FraccionCrear"
                                    );
                                });
                            }}
                        />
                    )}
                </div>
            </div>
            <div className="row row-fields">
                <div className="col-12 col-md-12 col-lg-12 form-group">
                    <label>Precios por sucursal</label>
                    <FieldArray
                        name="precios_sucursal"
                        component={preciosSucursal}
                        sucursales={sucursales}
                        sucursal={sucursal}
                        changeSucursal={changeSucursal}
                        sucursal_activo={sucursal_activo}
                        changeSucursalActivo={changeSucursalActivo}
                        nombres_precios={nombres_precios}
                        conf_porcentaje_precio={conf_porcentaje_precio}
                        cambioPorcentajePrecio={cambioPorcentajePrecio}
                        precios_sucursalValue={precios_sucursalValue}
                        nombreFormulario="FraccionCrear"
                    />
                </div>
            </div>
            <div className="row row-fields">
                {editCreateFraccion.arriba ? (
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="capacidad_maxima_arriba">
                            {editCreateFraccion.arriba.nombre} tiene
                        </label>
                        <div className="container-custom-field-span">
                            <div className="flex3">
                                <Field
                                    name="capacidad_maxima_arriba"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <strong className="span-txt-fraccion flex1">
                                {nombreValue}
                            </strong>
                        </div>
                    </div>
                ) : null}
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                    </div> */}
                {editCreateFraccion.abajo ? (
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="capacidad_maxima_abajo">
                            {nombreValue} tiene
                        </label>
                        <div className="container-custom-field-span">
                            <div className="flex3">
                                <Field
                                    name="capacidad_maxima_abajo"
                                    component={renderNumber}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <strong className="span-txt-fraccion flex1">
                                {editCreateFraccion.abajo.nombre}
                            </strong>
                        </div>
                    </div>
                ) : null}
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                    </div> */}
            </div>
            <div className="row row-fields">
                <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                    <label htmlFor="vendible">Vendible</label>
                    <br />
                    <label className="switch">
                        <Field
                            name="vendible"
                            component="input"
                            type="checkbox"
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                    <label htmlFor="alertar">Alertar</label>
                    <br />
                    <label className="switch">
                        <Field
                            name="alertar"
                            component="input"
                            type="checkbox"
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
                {Boolean(alertarValue) ? (
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="minimo_existencias">
                            Mínimo de existencia *
                        </label>
                        <Field
                            name="minimo_existencias"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                ) : null}
            </div>
        </form>
    );
};

const Form = (props) => {
    //  state
    const {
        fracciones,
        editCreateFraccion,
        nombreValue,
        opcionValue,
        alertarValue,
        sucursales,
        sucursal,
        sucursal_activo,
        nombres_precios,
        conf_porcentaje_precio,
        precio2Value,
        precio3Value,
        precio4Value,
        precio5Value,
        precios_sucursalValue,
    } = props;

    //  bind
    const {
        handleSubmit,
        agregarNuevaFraccion,
        editarFraccion,
        onCloseModal,
        changeSucursal,
        changeSucursalActivo,
        cambioPorcentajePrecio,
    } = props;
    // console.log("PROPS FROM FRACCION: ",props)
    return (
        <div className="">
            <div className="modal-container">
                <div className="grid-titulo padding-15">
                    Fracción{" "}
                    {editCreateFraccion.arriba
                        ? `menor que ${editCreateFraccion.arriba.nombre}`
                        : editCreateFraccion.abajo
                        ? `mayor que ${editCreateFraccion.abajo.nombre}`
                        : ""}
                </div>
                <div className="modal-body padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <DatosFraccion
                        alertarValue={alertarValue}
                        nombreValue={nombreValue}
                        editCreateFraccion={editCreateFraccion}
                        sucursales={sucursales}
                        sucursal={sucursal}
                        sucursal_activo={sucursal_activo}
                        changeSucursal={changeSucursal}
                        changeSucursalActivo={changeSucursalActivo}
                        nombres_precios={nombres_precios}
                        precio2Value={precio2Value}
                        precio3Value={precio3Value}
                        precio4Value={precio4Value}
                        precio5Value={precio5Value}
                        conf_porcentaje_precio={conf_porcentaje_precio}
                        cambioPorcentajePrecio={cambioPorcentajePrecio}
                        precios_sucursalValue={precios_sucursalValue}
                    />
                </div>
                {/* FOOTER */}
                <div className="d-flex justify-content-center flex-row modal-footer">
                    <button
                        className="btn btn-tertiary m-1 align-self-center"
                        type="button"
                        onClick={() => {
                            onCloseModal();
                        }}
                    >
                        <img src={icons.cancelar} alt="" />
                        Cancelar
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary m-1 align-self-center"
                        onClick={handleSubmit((valores) => {
                            let data = { ...estructuraDatos, ...valores };
                            if (editCreateFraccion.tipo === "edit") {
                                editarFraccion(data, onCloseModal);
                            } else {
                                agregarNuevaFraccion(data, onCloseModal);
                            }
                        })}
                    >
                        <img src={icons.save} alt="" /> Guardar
                    </button>
                </div>
            </div>
        </div>
    );
};

let ProdFraccionForm = reduxForm({
    // a unique name for the form
    form: "FraccionCrear",
    validate,
})(Form);

//  Decorador para leer los valores del form
const selector = formValueSelector("FraccionCrear");

ProdFraccionForm = connect((state) => {
    const nombreValue = selector(state, "nombre");
    const opcionValue = selector(state, "opcion");
    const alertarValue = selector(state, "alertar");
    //  PRECIOS
    const precio2Value = selector(state, "precio2");
    const precio3Value = selector(state, "precio3");
    const precio4Value = selector(state, "precio4");
    const precio5Value = selector(state, "precio5");
    const precios_sucursalValue = selector(state, "precios_sucursal");
    return {
        nombreValue,
        opcionValue,
        alertarValue,
        precio2Value,
        precio3Value,
        precio4Value,
        precio5Value,
        precios_sucursalValue,
        initialValues: {
            vendible: true,
            alertar: false,
            precios_sucursal: [],
        },
    };
})(ProdFraccionForm);

export default ProdFraccionForm;
