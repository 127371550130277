import React,  {Component, Fragment} from "react";
import './fileuploader.css'
import Cropper from 'react-easy-crop'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

const no_image = require('assets/img/icons/no_imagen.png');

class FileUploaderCrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: '',
            crop: { x: 0, y: 0 },
            loaded: true,
            added: false,
            zoom: 1,
            croppedAreaPixels: {width: 100, height: 100, x:0, y: 0},
            croppedImage: null,
        };
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidUpdate(nextProps) {
        if (this.state.imageSrc === '' && this.props.source !== undefined && this.props.source !== null) {
            this.setState({imageSrc: this.props.source, loaded: true})
        }
    }

    componentWillMount(){
        if (this.props.source !== null && this.props.source !== undefined){
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: this.props.source,
                loaded: true
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.source !== null && nextProps.source !== undefined) {
            const { imageSrc } = this.state;
            // Se valida si es diferente las imagenes que se esta mandando
            // se asimila que se cambio la imagen
            if ( imageSrc !== nextProps.source) {
                this.setState({
                    imageSrc: imageSrc,
                    loaded: true
                });
            }else {
                // setea la imágen si se le envia una
                this.setState({
                    imageSrc: nextProps.source,
                    loaded: true
                });
            }
        }else {
            const { imageSrc, added } = this.state;
            //  Si es undefined se asimila que se esta editando
            if (nextProps.source !== undefined) {
                // Si la imagen en el estado est asignado
                // se limpia ya que no se esta mandando un source
                if(!added) {
                    if (imageSrc !== '' && imageSrc !== null) {
                        this.setState({
                            imageSrc: '',
                            loaded: false
                        });
                    }
                }else {
                    if (nextProps.source === null && imageSrc !== "") {
                        this.setState({
                            imageSrc: imageSrc,
                            loaded: true,
                        });
                    }
                }

            }
        }

    }

    onFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);
            this.props.onFileChange(e, file, this.props.clave);
            this.setState({
                imageSrc: imageDataUrl,
                crop: { x: 0, y: 0 },
                zoom: 1
            })
        }
    };

    onCropChange = async crop => {
        this.setState({ crop });
    };

    showResult = async () => {
        try {
            this.setState({
                isCropping: true,
            });
            const croppedImage = await getCroppedImg(
                this.state.imageSrc,
                this.state.croppedAreaPixels
            );
            this.props.setCortada(this.props.clave, croppedImage);
            this.setState({
                croppedImage,
                isCropping: false,
            })
        } catch (e) {
            console.error(e);
            this.setState({
                isCropping: false,
            })
        }
    };
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({
            croppedAreaPixels,
        });
        if (this.state.imageSrc !== null && this.state.imageSrc !== undefined && this.state.imageSrc !== "")
            setTimeout(() =>{ this.showResult();}, 1000);
    };

    onZoomChange = async zoom => {
        this.setState({ zoom })
    };

    cancel = () => {
        this.setState({
            imageSrc: '',
            crop: { x: 0, y: 0 },
            loaded: true,
            added: false,
            zoom: 1,
            croppedAreaPixels: {width: 100, height: 100, x:0, y: 0},
            croppedImage: null,
        })

        this.props.changeEdicionImagen(this.props.clave)
    }

    render() {
        return (
            <div>
                {
                    (this.state.imageSrc && this.state.imageSrc !== '') && (
                        <div className="crop-cancel" title="Cancelar" onClick={() => this.cancel()}>
                            <img src={require(`../../../../../assets/img/icons/close-blanco.png`)}/>
                        </div>
                    )
                }
                <div className="crop-upload" rightImagen={(this.state.imageSrc && this.state.imageSrc !== '') ? "70px": "0px"}>
                    <label htmlFor={`input-crop-${this.props.clave}`} title="Importar imagen">
                        <img src={require(`../../../../../assets/img/icons/camara.png`)}/>
                    </label>
                    <input id={`input-crop-${this.props.clave}`} type="file" accept="image/*" onChange={this.onFileChange} ref="input"/>
                </div>
                <Fragment>
                    <div className="crop-container">
                        <Cropper
                            image={(this.state.imageSrc && this.state.imageSrc !== '') ? this.state.imageSrc : no_image}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.props.aspect ? this.props.aspect : 1}
                            cropShape={this.props.cropShape ? this.props.cropShape : 'round'}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}
                            zoomWithScroll={false}
                        />
                    </div>
                    <div className="controls">
                        <Slider
                            value={this.state.zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={this.onZoomChange}
                        />
                    </div>
                </Fragment>
            </div>
        );
    }
}
export default FileUploaderCrop

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    });

const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);
    // const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
        0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise(resolve => {
        resolve(canvas.toDataURL())
    })
};
