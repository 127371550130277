import { connect } from 'react-redux';

import GraficaHistorialPrecios from './GraficaHistorialPrecios';
import { actions } from "../../../../../redux/modules/reportes/historial_precios";

const ms2p = (state) => {
    return {
        ...state.reporte_historial_precios,
        pais: state.usuario.me.pais
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(GraficaHistorialPrecios);