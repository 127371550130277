import _ from "lodash";
import { api } from "api";
import moment from "moment";
import { icons } from "icons";
import React, { Component } from "react";
import DatePicker from "Utils/DatePicker";
import Select, { Async } from "react-select";
import "react-select/dist/react-select.css";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderNumero, RenderMoneda } from "Utils/renderField/renderTableField";
import {
    formatoNombre,
    formatoAvatar,
    formatoMarca,
} from "Utils/CamposComunes";

const tipos_movimiento = [
    { value: 50, label: "Baja" },
    { value: 130, label: "Salida de Productos" },
];

const listaSucursales = [];
const listaSalidas = [];

export default class HistorialBajasSalidas extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentWillMount() {
        this.props.listar(this.props.page);
    }

    render() {
        //  State
        const {
            fecha_inicial,
            fecha_final,
            sucursal,
            tipo_movimiento,
            loader,
            page,
            data,
            destino_salida,
            estado_descarga,
        } = this.props;
        //  Bind
        const {
            setFecha,
            setSucursal,
            tipoMovimientoChange,
            sortChange,
            listar,
            setDestinoSalida,
            descargarListado,
        } = this.props;

        const getSucursales = (search) => {
            return api
                .get("sucursales", { search })
                .catch(() => {})
                .then((data) => {
                    data.results.forEach((item) => {
                        if (!_.find(listaSucursales, { id: item.id }))
                            listaSucursales.push(item);
                    });
                    return { options: listaSucursales };
                });
        };

        const getDestinosSalida = (search) => {
            return api
                .get("destino_salida_producto", { search })
                .catch(() => {})
                .then((data) => {
                    data.results.forEach((item) => {
                        if (!_.find(listaSalidas, { id: item.id }))
                            listaSalidas.push(item);
                    });
                    return { options: listaSalidas };
                });
        };

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.onSortChange = sortChange;
        options.page = page;
        options.onPageChange = listar;

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div style={{ flex: "1" }}>
                                    <h3>
                                        <b>Bajas y salidas por producto</b>
                                    </h3>
                                </div>
                                <div
                                    className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "1" }}
                                >
                                    <div style={{ flex: "1" }}>
                                        <label>Fecha Inicio</label>
                                        <DatePicker
                                            value={fecha_inicial}
                                            maxDate={new Date()}
                                            onChange={(date) => {
                                                setFecha("Inicial", date);
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: "1" }}>
                                        <label>Fecha Fin</label>
                                        <DatePicker
                                            value={fecha_final}
                                            minDate={
                                                new Date(
                                                    moment(
                                                        fecha_inicial,
                                                        "YYYY-MM-DD"
                                                    )
                                                )
                                            }
                                            onChange={(date) => {
                                                setFecha("Final", date);
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: "1" }}>
                                        <label>Sucursal</label>
                                        <Async
                                            className="form-control"
                                            placeholder="Seleccionar sucursal"
                                            onChange={setSucursal}
                                            searchPromptText="Escriba para buscar"
                                            valueKey={"id"}
                                            labelKey={"nombre"}
                                            loadOptions={getSucursales}
                                            value={sucursal}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: "1" }}>
                                        <label>Tipo</label>
                                        <div>
                                            <Select
                                                isClearable
                                                placeholder="Seleccionar"
                                                className="form-control"
                                                options={tipos_movimiento}
                                                valueKey="value"
                                                labelKey="label"
                                                value={tipo_movimiento}
                                                onChange={(e) => {
                                                    tipoMovimientoChange(
                                                        e ? e.value : ""
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {tipo_movimiento == 130 && (
                                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start">
                                        <div className="col-12 col-md-4 col-lg-3 p-0 mt-1">
                                            <label>Destino salida</label>
                                            <Async
                                                className="form-control"
                                                placeholder="Seleccionar el destino de salida"
                                                onChange={setDestinoSalida}
                                                searchPromptText="Escriba para buscar"
                                                valueKey={"id"}
                                                labelKey={"nombre"}
                                                loadOptions={getDestinosSalida}
                                                value={destino_salida}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <LoadMask loading={loader} dark blur>
                                <div className="content-total-reports flex-column flex-md-row flex-lg-row align-items-end justify-content-start justify-content-md-between justify-content-lg-between w-100 mt-10 py-1 py-md-2 py-lg-2">
                                    <button
                                        className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em
                                            className={`fa ${
                                                estado_descarga
                                                    ? "fa-spinner fa-pulse"
                                                    : "fa-download"
                                            }`}
                                        ></em>
                                        &nbsp;Descargar Listado
                                    </button>
                                    <div className="d-flex flex-column flex-md-row flex-lg-row align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
                                        <div className="d-flex">
                                            <h3 className="py-0 px-2 margin-0">
                                                <b>Costo total</b>
                                            </h3>
                                            <h3 className="py-0 px-2 margin-0">
                                                <b>
                                                    <RenderCurrency
                                                        value={
                                                            data.total_costo_perdido
                                                                ? data.total_costo_perdido
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h3>
                                        </div>
                                        <div className="d-flex">
                                            <h3 className="py-0 px-2 margin-0">
                                                <b>Precio total</b>
                                            </h3>
                                            <h3 className="py-0 px-2 margin-0">
                                                <b>
                                                    <RenderCurrency
                                                        value={
                                                            data.total_precio_perdido
                                                                ? data.total_precio_perdido
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataSort
                                        width="0%"
                                        isKey
                                        dataField="id"
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="img_cropped"
                                        dataFormat={formatoAvatar}
                                        width="8%"
                                    ></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="producto__nombre"
                                        dataFormat={formatoNombre}
                                    >
                                        Nombre
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="producto__marca"
                                        dataFormat={formatoMarca}
                                    >
                                        Marca
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataSort dataField="lista_destinos">
                                        Destinos
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="cantidad_perdido"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderNumero monto={cell} />
                                            );
                                        }}
                                    >
                                        Cantidad
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="costo_perdido"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda monto={cell} />
                                            );
                                        }}
                                    >
                                        Costo
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="precio_perdido"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda monto={cell} />
                                            );
                                        }}
                                    >
                                        Precio
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
