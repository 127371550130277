import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Blank extends Component {
    static propTypes = {
    }

    render() {
        return (
            <div className="row">
                <div className="mt-0 col-12 grid-container" style={{height: "93vh"}}>
                    <div className="d-flex flex-column align-items-center">
                        <img
                            style={{height:"500px"}}
                            className="img-fluid"
                            src={require("../../../../assets/img/fondo-login-intermedio.png")}
                        />
                        <h1 style={{position: "absolute", top: "365px"}} className="mb-0 text-center">No tienes permisos</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default Blank;
