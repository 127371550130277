import { connect } from 'react-redux';
import { crearCliente } from "../../../../redux/modules/cliente/cliente";
import Crear from './ClientesCrear';


const ms2p = (state) => {
    return {
        ...state.usuarios,
    };
};

const md2p = { crear: crearCliente };

export default connect(ms2p, md2p)(Crear);
