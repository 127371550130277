import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductoDetalleRender from './ProductoDetalleRender'

class ProductoDetalle extends Component {
    static propTypes = {
        leer: PropTypes.func.isRequired,
        producto: PropTypes.object.isRequired,
    }
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render() {
        // state
        const { producto, loader } = this.props;
        // bind

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h1>Detalle del producto</h1>
                </div>
                {loader ? (
                    <h1>Cargando...</h1>
                ) : (
                        <ProductoDetalleRender producto={producto} />
                    )}
            </div>
        );
    }
}

export default ProductoDetalle;
