import React from "react";
import { icons } from "icons";
import { connect } from "react-redux";
import FileUploader from "Utils/FileUploader/FileUploader";
import FileUploaderCrop from "Utils/FileUploader/FileUploaderCrop";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import {
    renderNumber,
    renderSelectField,
    renderField,
    renderCurrency,
} from "Utils/renderField/renderField";
import {
    renderCheckBoxEntrega,
    renderCheckBoxPago,
    renderCheckBoxTiposFactura,
} from "./CamposConfiguracion";

// VALIDACIONES CUSTOMS
const validate = (values, state) => {
    const errors = {};
    if (!values.tipo_punto_venta) errors.tipo_punto_venta = "Campo requerido";
    if (values.conf_cotizacion) {
        if (!values.plazo_cotizacion)
            errors.plazo_cotizacion = "Campo requerido";
    }

    //  Validacion para los metodos de entrega
    if (values.metodos_entrega) {
        if (values.metodos_entrega.length > 0) {
            const MetodosEntregaArrayErrors = [];
            values.metodos_entrega.forEach((metodo_entrega, index) => {
                const metodosEntregaErrors = {};
                if (metodo_entrega[Object.keys(metodo_entrega)[0]] == true) {
                    if (
                        metodo_entrega["precio"] != "0" &&
                        !metodo_entrega["precio"]
                    ) {
                        metodosEntregaErrors.precio = "Campo requerido";
                        MetodosEntregaArrayErrors[index] = metodosEntregaErrors;
                    }
                }
            });

            if (MetodosEntregaArrayErrors.length)
                errors.metodos_entrega = MetodosEntregaArrayErrors;
        }
    } else {
        errors.metodos_entrega = "Campo requerido";
    }

    if (values.metodos_pago.length > 0) {
        const MetodosPagoArrayErrors = [];
        values.metodos_pago.forEach((metodo_pago, index) => {
            if (
                "prefijo" in metodo_pago &&
                metodo_pago.prefijo === "DEP" &&
                metodo_pago[Object.keys(metodo_pago)[0]] === true
            ) {
                const metodosErrors = {};
                if (!metodo_pago.no_cuenta)
                    metodosErrors.no_cuenta = "Campo requerido";
                MetodosPagoArrayErrors[index] = metodosErrors;
                if (!metodo_pago.banco) metodosErrors.banco = "Campo requerido";
                MetodosPagoArrayErrors[index] = metodosErrors;
                if (!metodo_pago.nombre)
                    metodosErrors.nombre = "Campo requerido";
                MetodosPagoArrayErrors[index] = metodosErrors;
            }
        });
        if (MetodosPagoArrayErrors.length)
            errors.metodos_pago = MetodosPagoArrayErrors;
    }

    return errors;
};

const Form = (props) => {
    const {
        handleSubmit,
        imCortada,
        metodos_entrega,
        metodos_pago,
        logo,
        banner,
        ver_deposito,
        tienda,
        slug,
        conf_tienda_online,
        editando_logo,
        editando_banner,
        data,
        utiliza_fel,
    } = props;
    //  Valores del formulario
    const {
        conf_cotizacionValue,
        setCortada,
        onFileChange,
        bannerCortado,
        changeEdicionImagen,
        onLogoTicketChange,
    } = props;

    //  Tipo de cotizacion
    const tipos_punto_venta = [
        {
            id: "PVN",
            value: "PVN",
            label: "Normal",
        },
        {
            id: "PVMCE",
            value: "PVMCE",
            label: "Mostrador-Caja-Entrega",
        },
    ];

    const tipos_facturas = [
        {
            id: "FACT",
            value: "FACT",
            label: "Factura normal",
        },
        {
            id: "FCAM",
            value: "FCAM",
            label: "Factura cambiaria",
        },
    ];

    return (
        <form onSubmit={handleSubmit}>
            <div>
                {conf_tienda_online && (
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Banner</h3>
                        </div>
                        <div className="col-12">
                            <div className="col-12 col-lg-6 my-4">
                                {editando_banner ? (
                                    <FileUploaderCrop
                                        name="banner"
                                        onFileChange={onFileChange}
                                        clave="banner"
                                        aspect={18 / 4}
                                        setCortada={setCortada}
                                        cropShape="rect"
                                        source={banner ? banner : null}
                                        changeEdicionImagen={
                                            changeEdicionImagen
                                        }
                                    />
                                ) : (
                                    <div className="container-img-configuracion">
                                        <img
                                            src={data.banner}
                                            alt="Banner"
                                            className="img-configuracion"
                                        />
                                        <div className="container-options">
                                            <div
                                                className="bnt-imagen-configuracion"
                                                onClick={() =>
                                                    changeEdicionImagen(
                                                        "banner"
                                                    )
                                                }
                                                title="Editar banner"
                                            >
                                                <img
                                                    src={icons.editar_blanco}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className="row">
                    {conf_tienda_online && (
                        <div className="col-12 col-lg-6 my-4">
                            <h3>Logotipo</h3>
                            <div className="col-12 ">
                                {editando_logo ? (
                                    <FileUploaderCrop
                                        name="logo"
                                        onFileChange={onFileChange}
                                        setCortada={setCortada}
                                        aspect={1}
                                        cropShape="round"
                                        clave="logo"
                                        source={logo ? logo : null}
                                        changeEdicionImagen={
                                            changeEdicionImagen
                                        }
                                    />
                                ) : (
                                    <div className="container-img-configuracion">
                                        <img
                                            src={data.logo}
                                            alt="Logo"
                                            className="img-configuracion"
                                        />
                                        <div className="container-options">
                                            <div
                                                className="bnt-imagen-configuracion"
                                                onClick={() =>
                                                    changeEdicionImagen("logo")
                                                }
                                                title="Editar logo"
                                            >
                                                <img
                                                    src={icons.editar_blanco}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-lg-6 my-4">
                        <h3>Logotipo para tickets</h3>
                        <div className="col-12 mt-3">
                            <FileUploader
                                onFileChange={onLogoTicketChange}
                                source={
                                    data.logo_ticket ? data.logo_ticket : null
                                }
                                icono={"add-img"}
                                clave={"logo_ticket"}
                                width="100%"
                                texto="Arrastra o has click en el icono para cargar el logo para los tickets."
                            />
                        </div>
                    </div>
                </div>
                {conf_tienda_online && <hr className="mb" />}

                <div className="row">
                    <div className="col-sm-12">
                        <h3>Caja</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="conf_caja_forzar_cierre">
                            Forzar siempre el cuadre de efectivo al cierre de
                            cajas
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_caja_forzar_cierre"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                </div>
                {/* <hr className="mb" /> */}

                {/* <div className="row">
                    <div className="col-sm-12">
                        <h3>Bodega</h3>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="conf_venta_imprimir_recibo">
                            Imprimir recibo al finalizar la venta
                        </label>
                        <br/>
                        <label className="switch">
                            <Field
                                name="conf_venta_imprimir_recibo"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                </div> */}
                <hr className="mb" />

                <div className="row">
                    <div className="col-sm-12">
                        <h3>Bodega</h3>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="conf_inventario_negativo">
                            Permitir inventario negativo.
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_inventario_negativo"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="conf_mostrar_precio_impresion">
                            Desplegar precios en impresion de baja y despacho
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_mostrar_precio_impresion"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="conf_costo_promedio">
                            Visualizar costo promedio
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_costo_promedio"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="conf_habilitar_no_factura">
                            Habilitar No. Factura
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_habilitar_no_factura"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                </div>
                <hr className="mb" />

                <div className="row">
                    <div className="col-sm-12">
                        <h3>Venta</h3>
                    </div>
                    {/* <div className="col-md-6">
                        <label htmlFor="conf_venta_pedir_cliente">
                            Siempre pedir información del cliente para realizar
                            la venta
                        </label>
                        <br/>
                        <label className="switch">
                            <Field
                                name="conf_venta_pedir_cliente"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div> */}
                    <div className="col-md-3 form-group">
                        <label htmlFor="tipo_punto_venta">
                            Tipo punto de venta
                        </label>
                        <Field
                            name="tipo_punto_venta"
                            component={renderSelectField}
                            options={tipos_punto_venta}
                            className="form-control"
                            item_vacio={false}
                        />
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="conf_venta_permitir_descuentos_items">
                            Descuentos de productos.
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_venta_permitir_descuentos_items"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="conf_venta_credito_marcar_entregado">
                            Marcar pedidos entregados de ventas al crédito.
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_venta_credito_marcar_entregado"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    <div className="col-md-2 form-group">
                        <label htmlFor="conf_venta_redondeo_total">
                            Redondeo del total de venta
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_venta_redondeo_total"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    <div className="col-md-2 form-group">
                        <label htmlFor="conf_venta_precio_editable">
                            Precio editable
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_venta_precio_editable"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    <div className="col-md-3 form-group">
                        <label htmlFor="conf_mostrar_referencia">
                            Mostrar opción para imprimir referencia en factura
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_mostrar_referencia"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    <div className="col-md-3 form-group">
                        <label htmlFor="conf_ventas_al_credito">
                            Venta al credito
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_ventas_al_credito"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    <div className="col-md-3 form-group">
                        <label htmlFor="conf_habilitar_campo_cliente">
                            CF / Nombre editable
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_habilitar_campo_cliente"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>

                    <div className="col-md-3 form-group">
                        <label htmlFor="buscar_nombre">
                            Buscar por cliente
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="buscar_nombre"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>

                </div>

                <hr className="mb" />

                <div className="row">
                    <div className="col-sm-12">
                        <h3>Cotización</h3>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="conf_cotizacion">
                            Habilitar cotizaciones
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_cotizacion"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                    {conf_cotizacionValue ? (
                        <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                            <label htmlFor="plazo_cotizacion">
                                Plazo cotización (días){" "}
                            </label>
                            <Field
                                name="plazo_cotizacion"
                                component={renderNumber}
                                decimalScale={0}
                                type="number"
                                className="form-control"
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                {/* ---------- GENERAL ----------*/}
                <hr className="mb" />
                 <div className="row">
                    <div className="col-sm-12">
                        <h3>General</h3>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="conf_modena_con_3_decimales">
                            Utilizar 3 decimales para precios
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_modena_con_3_decimales"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                </div>
                <hr className="mb" />

                {/* PRODUCTOS */}
                <div className="row">
                    <div className="col-12">
                        <h3>Producto</h3>
                    </div>
                    <div className="col-12">
                        <label htmlFor="">Nombre de precios</label>
                        <br />
                        <div className="row">
                            <div className="col-12 col-md-2 col-lg-2">
                                <label htmlFor="nombres_precios.nombre_precio">
                                    Precio
                                </label>
                                <br />
                                <Field
                                    name="nombres_precios.nombre_precio"
                                    component={renderField}
                                    decimalScale={2}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-2 col-lg-2">
                                <label htmlFor="nombres_precios.nombre_precio2">
                                    Precio 2
                                </label>
                                <br />
                                <Field
                                    name="nombres_precios.nombre_precio2"
                                    component={renderField}
                                    decimalScale={2}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-2 col-lg-2">
                                <label htmlFor="nombres_precios.nombre_precio3">
                                    Precio 3
                                </label>
                                <br />
                                <Field
                                    name="nombres_precios.nombre_precio3"
                                    component={renderField}
                                    decimalScale={2}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-2 col-lg-2">
                                <label htmlFor="nombres_precios.nombre_precio4">
                                    Precio 4
                                </label>
                                <br />
                                <Field
                                    name="nombres_precios.nombre_precio4"
                                    component={renderField}
                                    decimalScale={2}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-2 col-lg-2">
                                <label htmlFor="nombres_precios.nombre_precio5">
                                    Precio 5
                                </label>
                                <br />
                                <Field
                                    name="nombres_precios.nombre_precio5"
                                    component={renderField}
                                    decimalScale={2}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                        <label htmlFor="conf_precios_porcentaje">
                            Precios por porcentaje
                        </label>
                        <br />
                        <label className="switch">
                            <Field
                                name="conf_precios_porcentaje"
                                component="input"
                                type="checkbox"
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                </div>

                {/* FEL */}
                <hr className="mb" />
                {utiliza_fel && (
                    <div className="row">
                        <div className="col-12">
                            <h3>FEL</h3>
                        </div>
                        <div className="col-12 p-0">
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-6 form-group">
                                            <label>Tipos de facturas</label>
                                            <FieldArray
                                                name="tipos_facturas"
                                                component={
                                                    renderCheckBoxTiposFactura
                                                }
                                                items={tipos_facturas}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="mb" />
                    </div>
                )}

                {/* TIENDA EN LINEA */}
                {conf_tienda_online && (
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Tienda en línea</h3>
                        </div>
                        {/* <div className="col-md-2">
                                <label htmlFor="conf_venta_permitir_descuentos_items">
                                    Habilitar tienda en línea.
                                </label>
                                <br />
                                <label className="switch">
                                    <Field
                                        name="conf_tienda_online"
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="slider round" />
                                </label>
                            </div> */}

                        {/* CONTAINER DATOS TIENDA ONLINE */}
                        <div className="col-12 mt-4 p-0">
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-6 form-group">
                                            <label>
                                                Indique los métodos de entrega
                                            </label>
                                            <FieldArray
                                                name="metodos_entrega"
                                                component={
                                                    renderCheckBoxEntrega
                                                }
                                                items={metodos_entrega}
                                            />
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-6 form-group">
                                            <label>
                                                Indique los métodos de pago
                                            </label>
                                            <FieldArray
                                                name="metodos_pago"
                                                component={renderCheckBoxPago}
                                                items={metodos_pago}
                                                ver_deposito={ver_deposito}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-12 p-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <label>
                                                {" "}
                                                Indique los datos de contacto de
                                                su tienda{" "}
                                            </label>
                                        </div>
                                        <div className="pl-4 col-12 col-md-12 col-lg-6 form-group">
                                            <label>Teléfono</label>
                                            <Field
                                                name="telefono_tienda"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-6 form-group">
                                            <label>Correo</label>
                                            <Field
                                                name="correo_tienda"
                                                component={renderField}
                                                type="email"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-12 d-flex justify-content-center align-items-center">
                                    <a
                                        href={`${tienda}/tienda/${slug}`}
                                        target="_blank"
                                        className="btn btn-outline-secondary"
                                    >
                                        Ver tienda
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr className="mb w-100" />
                    </div>
                )}

                <div className="row">
                    <div className="col-sm-12 text-center">
                        <button type="submit" className="btn btn-primary">
                            <img src={icons.save} alt="guardar" />
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

let ConfiguracionForm = reduxForm({
    // a unique name for the form
    form: "configuracion",
    validate,
})(Form);

//  Decorador para leer los valores del form
const selector = formValueSelector("configuracion");

ConfiguracionForm = connect((state) => {
    //  Valores del formulario
    const form = state.form.configuracion;
    const conf_cotizacionValue = selector(state, "conf_cotizacion");

    const metodos_pago = selector(state, "metodos_pago");
    let ver_deposito = { ver: false, id: -1 };
    if (state.configuracion && metodos_pago) {
        try {
            metodos_pago.forEach((metodo, index) => {
                const partes = Object.keys(metodo)[0].split("metodo_pago-");
                const id = partes[1];
                const obj_metodo = _.find(state.configuracion.metodos_pago, {
                    id: parseInt(id),
                });
                //Mutar datos de formulario para que tengan prefijo
                form.values.metodos_pago[index]["prefijo"] = obj_metodo.prefijo;
                if (obj_metodo && obj_metodo.prefijo == "DEP") {
                    ver_deposito = {
                        ver: metodo[Object.keys(metodo)[0]],
                        id: id,
                    };
                }
            });
        } catch (e) {}
    }

    return {
        conf_cotizacionValue,
        ver_deposito,
        initialValues: {
            metodos_entrega: [],
            metodos_pago: [],
            tipos_facturas: [],
        },
    };
})(ConfiguracionForm);

export default ConfiguracionForm;
