import React from "react";
import { renderField, renderTextArea } from "Utils/renderField/renderField";
import { Field, reduxForm } from "redux-form";
import { icons } from "icons";
import { Link } from 'react-router-dom';
import { validate, validators } from "validate-redux-form";
import { email, NIT } from "../../../../utility/validation";



const FormRender = props => {
    const { handleSubmit, cancelar } = props;
    return (
        <form onSubmit={handleSubmit} className="row mt">
            <div className="col-sm-12">
                <div className="form-group">
                    <div className="padding-15 p-sm-0 pt-sm-1">
                        <div className="row">
                            {!!cancelar ?
                                (
                                    <div className="col-sm-12 m-3" style={{borderBottom: "1px solid #cacaca"}}>
                                        <h3>Registro de nuevo proveedor</h3>
                                    </div>
                                ) : (
                                    <div className="col-sm-12">
                                        <h3>Datos del Proveedor</h3>
                                    </div>
                                )
                            }
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="nombre">Nombre Completo*</label>
                                <Field name="nombre" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="nit">Nit*</label>
                                <Field name="nit" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="direccion">Dirección</label>
                                <Field name="direccion" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="contacto">Contacto</label>
                                <Field name="contacto" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="correo">Correo</label>
                                <Field name="correo" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="telefono">Teléfono</label>
                                <Field name="telefono" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="Extra">Extra</label>
                                <Field name="extra" component={renderTextArea} className="form-control" />
                            </div>
                        </div>

                        <br />
                        <div className="d-flex justify-content-center flex-row">
                            {!!cancelar ? (
                                <button className="btn btn-tertiary m-1 align-self-center" onClick={(e) => {
                                    e.preventDefault();
                                    cancelar();
                                }}>Cancelar</button>
                            ) :
                                (<Link className="btn btn-tertiary m-1 align-self-center" to="/configuracion/proveedores"><img src={icons.cancelar} alt="" />Cancelar </Link>)}
                            <button type="submit" className="btn btn-primary m-1 align-self-center"><img src={icons.save} alt="" />Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const Form = reduxForm({
    // a unique name for the form
    form: "ProveedorCrear",
    validate: data => {
        const nit = !!data.nit ? NIT : validators.exists()('Campo requerido');
        return validate(data, {
            nombre: validators.exists()('Campo requerido'),
            correo: email,
            nit
        })
    }
})(FormRender);

export default Form;
