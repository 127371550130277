import React, { Component } from "react";
import PropTypes from "prop-types";
import "rc-tooltip/assets/bootstrap.css";
import ItemDetalleVenta from "Utils/Venta/ItemDetalleVenta";
import ItemEditableDetalleVenta from "Utils/Venta/ItemEditableDetalleVenta";

export class DetalleVenta extends Component {
    static propTypes = {
        seleccionados: PropTypes.array.isRequired,
        restar: PropTypes.func.isRequired,
        sumar: PropTypes.func.isRequired,
        quitar: PropTypes.func.isRequired,
        ingresoCantidad: PropTypes.func.isRequired,
        desplegarItem: PropTypes.func.isRequired,
        venta_permitir_descuento: PropTypes.bool.isRequired,
        cambiarDescuento: PropTypes.func.isRequired,
        cambiarPrecio: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    isMobile() {
        return (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/iPad/i)
        );
    }

    render() {
        // State
        const {
            seleccionados,
            venta_permitir_descuento,
            pais,
            mostrar_alerta_inventario,
            texto_sin_detalles,
            editable,
            conf_venta_precio_editable,
            conf_porcentaje_precio,
        } = this.props;
        // State Cotizacion
        const { cotizacion_cargada } = this.props;
        // Bind
        const {
            quitar,
            ingresoCantidad,
            desplegarItem,
            restar,
            sumar,
            cambiarPrecio,
            cambiarDescuento,
            cambiarPrecioFinal,
            cambiarPrecioEditable,
        } = this.props;

        return (
            <div
                className="grid-container mt0 border-radius-buttom-squa"
                style={{ backgroundColor: "#F5F5F5" }}
            >
                <br></br>
                <div className="r-encajar">
                    {seleccionados.length === 0 ? (
                        texto_sin_detalles ? (
                            <h4 className="negro-dark2 mr-2 ml-2">
                                {texto_sin_detalles}
                            </h4>
                        ) : (
                            <h4 className="negro-dark2 mr-2 ml-2">
                                Agregue un producto a la venta
                            </h4>
                        )
                    ) : (
                        <div className="w-100 mt-2">
                            {seleccionados.map((item, index) => {
                                if (editable) {
                                    return (
                                        <ItemEditableDetalleVenta
                                            key={index}
                                            index={index}
                                            item={item}
                                            mostrar_alerta_inventario={
                                                mostrar_alerta_inventario
                                            }
                                            venta_permitir_descuento={
                                                venta_permitir_descuento
                                            }
                                            pais={pais}
                                            conf_venta_precio_editable={
                                                conf_venta_precio_editable
                                            }
                                            sumar={sumar}
                                            restar={restar}
                                            quitar={quitar}
                                            desplegarItem={desplegarItem}
                                            ingresoCantidad={ingresoCantidad}
                                            cambiarDescuento={cambiarDescuento}
                                            cambiarPrecio={cambiarPrecio}
                                            cambiarPrecioFinal={
                                                cambiarPrecioFinal
                                            }
                                            cambiarPrecioEditable={
                                                cambiarPrecioEditable
                                            }
                                            conf_porcentaje_precio={
                                                conf_porcentaje_precio
                                            }
                                        />
                                    );
                                } else {
                                    return (
                                        <ItemDetalleVenta
                                            key={index}
                                            index={index}
                                            item={item}
                                            mostrar_alerta_inventario={
                                                mostrar_alerta_inventario
                                            }
                                            quitar={quitar}
                                        />
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
