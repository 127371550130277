import React from "react";
import { Field, reduxForm } from "redux-form";
import { validate, validatorFromFunction } from "validate-redux-form";
import { renderTextArea, renderCurrency } from "Utils/renderField/renderField";
import { icons } from "icons";
import {
    RenderDate,
    RenderCurrency,
    RenderDateTime,
} from "../../Utils/renderField/renderReadField";

const Form = (props) => {
    //  State
    const {
        close,
        pendiente,
        saldo_inicial,
        utiliza_fel,
        nota_credito_cargada,
        codigo_nota_credito,
        total,
        loader,
    } = props;
    //  Bind
    const {
        handleSubmit,
        consultarNotaCredito,
        setCodigoNotaCredito,
        limpiarNotaCredito,
    } = props;

    let ref_codigo_nota_credito = "";
    return (
        <form onSubmit={handleSubmit} className="col-sm-12 p-0">
            <div className="form-group">
                <div className="padding-15 p-sm-0 pt-sm-1">
                    <div className="d-flex justify-content-around flex-column">
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1">Monto total</label>
                            <label className="flex-1">
                                <RenderCurrency
                                    value={saldo_inicial}
                                    className={"font-weight-bold"}
                                />
                            </label>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1">Fecha de pago</label>
                            <label className="flex-1">
                                <RenderDateTime
                                    value={new Date()}
                                    className={"font-weight-bold"}
                                />
                            </label>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1" htmlFor="efectivo">
                                Pago en efectivo
                            </label>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="efectivo"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1" htmlFor="tarjeta">
                                Pago en tarjeta
                            </label>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="tarjeta"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1" htmlFor="tarjeta">
                                Pago en cheque
                            </label>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="cheque"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1" htmlFor="tarjeta">
                                Pago en depósito
                            </label>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="deposito"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1" htmlFor="retencion_iva">
                                Pago en Retención de IVA
                            </label>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="retencion_iva"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1" htmlFor="exencion_iva">
                                Pago en Exención de IVA
                            </label>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="exencion_iva"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {/* NOTA DE CRÉDITO */}

                        {utiliza_fel && (
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between padding-5 mb-2 mt-2 px-0"
                            >
                                <div
                                    className="d-flex flex-column"
                                    style={{ flex: "1.3" }}
                                >
                                    <label
                                        className="flex-1"
                                        htmlFor="nota_credito"
                                    >
                                        Pago en nota de crédito
                                    </label>
                                    <Field
                                        name="nota_credito"
                                        component={renderCurrency}
                                        type="number"
                                        className="form-control d-none"
                                    />
                                    {/* INFO NOTA CREDITO */}
                                    {nota_credito_cargada && (
                                        <div className="w-100 py-2 px-1 d-flex flex-column">
                                            {/* FECHA */}
                                            <div className="d-flex mb">
                                                <b className="mr-1">Fecha</b>{" "}
                                                <RenderDate
                                                    value={
                                                        nota_credito_cargada
                                                            .nota_credito_fel
                                                            .fecha_emision
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex mb">
                                                <b className="mr-1">Código</b>{" "}
                                                {nota_credito_cargada.codigo}
                                            </div>
                                            <div className="d-flex mb">
                                                <b className="mr-1">
                                                    Número de autorización
                                                </b>{" "}
                                                {
                                                    nota_credito_cargada
                                                        .nota_credito_fel.uuid
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <div className="d-flex justify-content-end">
                                        {nota_credito_cargada ? (
                                            <button
                                                className="btn btn-danger"
                                                onClick={limpiarNotaCredito}
                                            >
                                                Eliminar
                                            </button>
                                        ) : (
                                            <input
                                                id="nota_credito"
                                                name="cod_nota_credito"
                                                type="text"
                                                placeholder="Código o número de autorización"
                                                className="form-control"
                                                ref={(node) => {
                                                    ref_codigo_nota_credito = node;
                                                    if (
                                                        ref_codigo_nota_credito
                                                    ) {
                                                        ref_codigo_nota_credito.value = codigo_nota_credito;
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        e.preventDefault();
                                                        if (
                                                            ref_codigo_nota_credito.value
                                                        ) {
                                                            consultarNotaCredito(
                                                                total
                                                            );
                                                        }
                                                    }
                                                }}
                                                onChange={() => {
                                                    setCodigoNotaCredito(
                                                        ref_codigo_nota_credito.value
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                    {nota_credito_cargada && (
                                        <div className="w-100 text-right my-3 fnt-18">
                                            <b>
                                                <RenderCurrency
                                                    value={
                                                        nota_credito_cargada.total_importe
                                                    }
                                                />
                                            </b>
                                        </div>
                                    )}
                                </div>
                                {!nota_credito_cargada && (
                                    <div
                                        style={{ flex: "0.2", height: "30px" }}
                                        className="d-flex justify-content-center"
                                    >
                                        <button
                                            className="btn btn-secondary ml-1"
                                            onClick={() =>
                                                consultarNotaCredito(total)
                                            }
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1" htmlFor="tarjeta">
                                Nota
                            </label>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="nota"
                                    rows={2}
                                    component={renderTextArea}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between">
                            <label className="flex-1">
                                Monto pendiente de pago
                            </label>
                            <label className="flex-1">
                                <RenderCurrency
                                    value={pendiente}
                                    className={"font-weight-bold"}
                                />
                            </label>
                        </div>
                    </div>
                    <br />
                    <div className="d-flex justify-content-center flex-row">
                        <button
                            type="button"
                            className="btn btn-tertiary m-1 align-self-center"
                            onClick={close}
                        >
                            <img src={icons.cancelar} alt="" />
                            Cancelar
                        </button>
                        {
                        loader==false ? 
                        <button
                            type="submit"
                            className="btn btn-primary m-1 align-self-center"
                        >
                            <img src={icons.save} alt="" />
                            Registrar
                        </button>
                        :""
                        }
                    </div>
                </div>
            </div>
        </form>
    );
};

const CobroCreditoForm = reduxForm({
    // a unique name for the form
    form: "CobroCreditoForm",
    validate: (data, props) => {
        const efectivo = validatorFromFunction(() => !(props.pendiente < 0));
        const tarjeta = validatorFromFunction(() => !(props.pendiente < 0));
        const cheque = validatorFromFunction(() => !(props.pendiente < 0));
        const deposito = validatorFromFunction(() => !(props.pendiente < 0));
        const retencion_iva = validatorFromFunction(
            () => !(props.pendiente < 0)
        );
        const exencion_iva = validatorFromFunction(
            () => !(props.pendiente < 0)
        );
        const nota_credito = validatorFromFunction(
            () => !(props.pendiente < 0)
        );
        // if (props.pendiente < 0) {
        //     efectivo = "La recepción no puede superar el monto total pendiente";
        //     tarjeta = "La recepción no puede superar el monto total pendiente";
        // }
        return validate(data, {
            efectivo: efectivo()(
                "La recepción no puede superar el monto total pendiente"
            ),
            tarjeta: tarjeta()(
                "La recepción no puede superar el monto total pendiente"
            ),
            cheque: cheque()(
                "La recepción no puede superar el monto total pendiente"
            ),
            deposito: deposito()(
                "La recepción no puede superar el monto total pendiente"
            ),
            retencion_iva: retencion_iva()(
                "La recepción no puede superar el monto total pendiente"
            ),
            exencion_iva: exencion_iva()(
                "La recepción no puede superar el monto total pendiente"
            ),
            nota_credito: nota_credito()(
                "La recepción no puede superar el monto total pendiente"
            ),
        });
    },
})(Form);

export default CobroCreditoForm;
