import React, { Fragment, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { renderField, renderSelectField, renderFieldDPI} from "Utils/renderField/renderField";
import { Async } from 'react-select';
import { api } from 'api';
import classNames from "classnames";

const cuiIsValid = (cui) => {
    if (!cui) {
      console.log("CUI vacío");
      return true;
    }

    var cuiRegExp = /^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/;

    if (!cuiRegExp.test(cui)) {
      console.log("CUI con formato inválido");
      return false;
    }

    // Se eliminan los espacios en blanco.
    cui = cui.replace(/\s/, "");
    cui = cui.replace(" ", "");
    cui = cui.trim();

    var depto = parseInt(cui.substring(9, 11), 10);
    var muni = parseInt(cui.substring(11, 13));
    var numero = cui.substring(0, 8);
    var verificador = parseInt(cui.substring(8, 9));

    // Este listado contiene la cantidad de municipios
    // existentes en cada departamento para poder
    // determinar el código máximo aceptado por cada
    // uno de los departamentos.
    var munisPorDepto = [
      /* 01 - Guatemala tiene:      */ 17 /* municipios. */,
      /* 02 - El Progreso tiene:    */ 8 /* municipios. */,
      /* 03 - Sacatepéquez tiene:   */ 16 /* municipios. */,
      /* 04 - Chimaltenango tiene:  */ 16 /* municipios. */,
      /* 05 - Escuintla tiene:      */ 13 /* municipios. */,
      /* 06 - Santa Rosa tiene:     */ 14 /* municipios. */,
      /* 07 - Sololá tiene:         */ 19 /* municipios. */,
      /* 08 - Totonicapán tiene:    */ 8 /* municipios. */,
      /* 09 - Quetzaltenango tiene: */ 24 /* municipios. */,
      /* 10 - Suchitepéquez tiene:  */ 21 /* municipios. */,
      /* 11 - Retalhuleu tiene:     */ 9 /* municipios. */,
      /* 12 - San Marcos tiene:     */ 30 /* municipios. */,
      /* 13 - Huehuetenango tiene:  */ 32 /* municipios. */,
      /* 14 - Quiché tiene:         */ 21 /* municipios. */,
      /* 15 - Baja Verapaz tiene:   */ 8 /* municipios. */,
      /* 16 - Alta Verapaz tiene:   */ 17 /* municipios. */,
      /* 17 - Petén tiene:          */ 14 /* municipios. */,
      /* 18 - Izabal tiene:         */ 5 /* municipios. */,
      /* 19 - Zacapa tiene:         */ 11 /* municipios. */,
      /* 20 - Chiquimula tiene:     */ 11 /* municipios. */,
      /* 21 - Jalapa tiene:         */ 7 /* municipios. */,
      /* 22 - Jutiapa tiene:        */ 17 /* municipios. */,
    ];

    if (depto === 0 || muni === 0) {
      console.log("CUI con código de municipio o departamento inválido.");
      return false;
    }

    if (depto > munisPorDepto.length) {
      console.log("CUI con código de departamento inválido.");
      return false;
    }

    if (muni > munisPorDepto[depto - 1]) {
      console.log("CUI con código de municipio inválido.");
      return false;
    }

    // Se verifica el correlativo con base
    // en el algoritmo del complemento 11.
    var total = 0;

    for (var i = 0; i < numero.length; i++) {
      total += numero[i] * (i + 2);
    }

    var modulo = total % 11;

    // console.log("CUI con módulo: " + modulo);
    // console.log(modulo === verificador);
    return modulo === verificador;
  };

const validate = (values) => {
    const errors = {};
    if (values.tipo_documento === "NIT") {
        if (values.nit) {
            let nit = values.nit;
            if (!nit) {
                return true;
            }

            var nitRegExp = new RegExp("^[0-9]+(-?[0-9kK])?$");

            if (!nitRegExp.test(nit)) {
                errors.nit = "Nit invalido";
            }

            nit = nit.replace(/-/, "");
            var lastChar = nit.length - 1;
            var number = nit.substring(0, lastChar);
            var expectedCheker = nit
                .substring(lastChar, lastChar + 1)
                .toLowerCase();

            var factor = number.length + 1;
            var total = 0;

            for (var i = 0; i < number.length; i++) {
                var character = number.substring(i, i + 1);
                var digit = parseInt(character, 10);

                total += digit * factor;
                factor = factor - 1;
            }

            var modulus = (11 - (total % 11)) % 11;
            var computedChecker = modulus == 10 ? "k" : modulus.toString();
            if (expectedCheker !== computedChecker) {
                errors.nit = "Nit invalido";
            }
        }
    }
    else if (values.tipo_documento === "CUI") {
        if (values.nit) {
            let cui = values.nit;
            if (!cuiIsValid(cui)) {
                errors.nit = "CUI invalido";
            }
        }
    }
    return errors;
};
const required = (value) => (value ? undefined : "Campo requerido.");
const validaNoEspacios = (value) => value && value.indexOf(" ") >= 0 ? "No se permiten espacios" : undefined;
const validaNoSOLOEspacios = (value) => value && value.trim().length === 0 ? "No se permiten espacios" : undefined;

const Form = (props) => {
    const {
        leerCliente,
        leerClienteNombre,
        changeDatosClientes,
        disabled,
        estado_movimiento,
        conf_mostrar_referencia,
        dataValidacionesCobro,
        limpiarDatosCliente,
        changeFieldForm,
        utiliza_fel,
        conf_empresa,
        setDataName,
    } = props;

    const sucursales = conf_empresa && conf_empresa.me && conf_empresa.me.sucursales && conf_empresa.me.sucursales[0] && conf_empresa.me.sucursales ? conf_empresa.me.sucursales : null;

    let id_empresa;
    if (sucursales) {
        sucursales.map((sucursal) => {
            if (sucursal.empresa) {
                id_empresa = sucursal.empresa;
            }
        });
    }

    const {monto_pendiente} = dataValidacionesCobro;

    const getUsuarios = (search) => {
        return api.get("clientes_list", {search}).catch(() => {}).then((data) => {
            return {options: data.results}
        })
    };

    const change = (e) => {
        const value = e.target.value;

        // buscar el nit solo cuando tiene al menos 5 letras
        if (value.length > 5) {
            leerCliente(value);
        }
        if(props.utiliza_fel && props.conf_habilitar_campo_cliente){
            if(value === "CF" | value === "cf"){
                props.setEstadoFormCliente(false)
            }
            else{
                props.setEstadoFormCliente(true)
            }
        }
        changeDatosClientes("nit", value);
    };
    let optionsDocumentos;
    //si no utiliza fell
    if (!utiliza_fel) {
        optionsDocumentos = [
            {value: "NIT", label: "NIT", id: "NIT"},
            {value: "CUI", label: "CUI", id: "CUI"},
            {value: "CF", label: "CF", id: "CF"}
        ]
    }
    else{
        optionsDocumentos = [
            {value: "NIT", label: "NIT", id: "NIT"},
            {value: "CUI", label: "CUI", id: "CUI"},
            {value: "EXT", label: "EXT", id: "EXT"},
        ];

        if (monto_pendiente < 2500) {
            optionsDocumentos = [
                ...optionsDocumentos,
                {value: "CF", label: "CF", id: "CF"},
            ];
        }
    }

    // guardamos el estado del tipo de documento
    const [docuemntoOption, setDocumentoOption] = React.useState(props.initialValues.tipo_documento);


    // Campo del id del receptor que cambiara de acuerdo al tipo de documento
    let fieldDocumento;
    console.log(docuemntoOption)

    //si el documento es de tipo NIT conserva la busqueda por cliente
    if (docuemntoOption === "NIT") {
        fieldDocumento = (
        <Fragment>
            <label htmlFor="nit">Nit</label>
            <Field
                name="nit"
                label="Ingrese un nit válido"
                component={renderField}
                validate={[required, validaNoEspacios]}
                type="text"
                className="form-control"
                onChange={change}
                disabled={estado_movimiento}
            />
        </Fragment>
        );
    //  si es cui o ext no se busca por cliente
    } else if (docuemntoOption === "CUI") {
        fieldDocumento = (
        <Fragment>
            <label htmlFor="nit">CUI</label>
            <Field
                name="nit"
                label="Ingrese un cui válido"
                component={renderFieldDPI}
                validate={[required, validaNoEspacios]}
                type="text"
                className="form-control"
                disabled={estado_movimiento}
                onKeyDownEnter={setDataName}
                utiliza_fel={utiliza_fel}
                id_empresa={id_empresa}
                onBlurs={true}
            />
        </Fragment>
        );
    } else if (docuemntoOption === "EXT") {
        fieldDocumento = (
        <Fragment>
            <label htmlFor="nit">EXT</label>
            <Field
                name="nit"
                label="Ingrese un ext válido"
                component={renderField}
                validate={[required, validaNoEspacios]}
                type="text"
                className="form-control"
                disabled={estado_movimiento}
            />
        </Fragment>
        );
    // si es CF y el monto es menor a 2500 se muestra el campo CF y se deshabilita
    } else if (docuemntoOption === "CF") {
        fieldDocumento = (
        <Fragment>
            <label htmlFor="nit"> &nbsp; &nbsp; &nbsp;</label>
            <Field
                name="nit"
                label="CF"
                component={renderField}
                type="text"
                value={"CF"}
                className="form-control"
                disabled={true}
            />
        </Fragment>
    );
    }

    useEffect(() => {
        if (monto_pendiente >= 2500 && utiliza_fel){
            if (docuemntoOption === "CF"){
                setDocumentoOption("NIT");
            }
        }
        if (docuemntoOption !== "CF"){
            limpiarDatosCliente();
        }
        else{
            changeFieldForm("nit", "CF");
            changeFieldForm("nombre", "CONSUMIDOR FINAL");
            changeFieldForm("direccion", "CIUDAD");
            changeFieldForm("telefono", "");
        }
    }, [monto_pendiente, docuemntoOption])


    const renderSearchSelectI = ({
        input,
        disabled,
        loadOptions,
        valueKey,
        labelKey,
        meta: { touched, error },
    }) => {
        const invalid = touched && error;
        return (
            <React.Fragment>
                <Async
                    disabled={disabled}
                    value={input.value}
                    className={classNames("form-control", {
                        "is-invalid": invalid,
                    })}
                    onChange={(e) => {
                        leerClienteNombre(e.nombre, e.nit);
                        input.onChange(!!e ? e[valueKey] : null);
                    }}
                    placeholder="Seleccionar"
                    searchPromptText="Escriba para buscar"
                    valueKey={valueKey}
                    labelKey={labelKey}
                    loadOptions={loadOptions}
                />
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                        {input.value}
                    </div>
                )}
            </React.Fragment>
        );
    };

    return (
        <form className="mt1">
            <div className="form-group ">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12 form-group">
                        <label htmlFor="tipo_documento">Tipo de documento</label>
                        <Field
                            name="tipo_documento"
                            label="Tipo de documento"
                            component={renderSelectField}
                            options={optionsDocumentos}
                            validate={[required, validaNoEspacios]}
                            className="form-control"
                            onChange={(e) => {
                                setDocumentoOption(e.target.value);
                            }}
                        />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12 form-group">
                        {fieldDocumento}
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <label htmlFor="nombre">Nombre</label>
                        <Field
                            name="nombre"
                            label="Nombre del cliente"
                            component={renderField}
                            type="text"
                            validate={[required, validaNoSOLOEspacios]}
                            className="form-control"
                            disabled={utiliza_fel ? disabled && !["EXT", "CF"].includes(docuemntoOption) || ["NIT", "CUI"].includes(docuemntoOption) : false}
                            onChange={(e) => {
                                const value = e.target.value;
                                changeDatosClientes("nombre", value);
                            }}

                        />
                    </div>
                    {(props.buscar_nombre && !estado_movimiento && docuemntoOption == "NIT") &&
                    //IF
                    (
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <label htmlFor="nombre">Buscar cliente</label>
                        <Field
                            name="buscar_nombre"
                            component={renderSearchSelectI}
                            loadOptions={getUsuarios}
                            labelKey="nombre"
                            valueKey="id_nombre"
                            className="form-control"
                        />
                    </div>
                    )
                    }
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <label htmlFor="direccion">Dirección*</label>
                        <Field
                            name="direccion"
                            component={renderField}
                            type="text"
                            validate={[required, validaNoSOLOEspacios]}
                            className="form-control"
                            disabled={estado_movimiento}
                        />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <label htmlFor="direccion">Referencia</label>
                        <Field
                            name="referencia"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={estado_movimiento}
                        />
                    </div>
                    {conf_mostrar_referencia==true ?
                                 <div className="col-md-4 form-group">
                                 <label htmlFor="mostrar_referencia">
                                     Imprimir referencia
                                 </label>
                                 <br />
                                 <label className="switch">
                                     <Field
                                         name="mostrar_referencia"
                                         component="input"
                                         type="checkbox"
                                     />
                                     <span className="slider round" />
                                 </label>
                             </div>
                    :""
                        }

                    {id_empresa == 69?
                        <div className="col-md-4 form-group">
                                 <label htmlFor="sin_factura">
                                     Pagar Sin Factura
                                 </label>
                                 <br />
                                 <label className="switch">
                                     <Field
                                         name="sin_factura"
                                         component="input"
                                         type="checkbox"
                                     />
                                     <span className="slider round" />
                                 </label>
                             </div>
                    :""
                        }
                </div>
            </div>
        </form>
    );
};

const ClienteVentaForm = reduxForm({
    // a unique name for the form
    form: "ClienteVentaForm",
    validate,
})(Form);

export default ClienteVentaForm;
