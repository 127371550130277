import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";
import CodigoFacturaForm from "./CodigoFacturaForm";
import { Link } from 'react-router-dom';
import { icons } from "icons";
import ModalAyuda from "Utils/Ayuda";
import { PVN } from "utility/variables";


class CodigoFactura extends Component {
    static propTypes = {
        buscarFactura: PropTypes.func.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            modalAyuda: false,
            itemsDevolucion: [
                {
                    src: icons.devolucion_p2,
                    alt: "Paso 1"
                },
            ]
        }
    }

    componentWillMount() {
    }
    closeModalAyuda = () => {
        this.setState({modalAyuda: false});
    }
    openModalAyuda = () => {
        this.setState({modalAyuda: true});
    }

    render() {
        const { modalAyuda, itemsDevolucion, tipo_punto_venta } = this.state;
        const { regresar } = this.props;
        return (
            <React.Fragment>
                <div className="col-12">
                    <button className="btn btn-tertiary m-1" onClick={regresar}><i className="fa fa-arrow-left" aria-hidden="true" /> Regresar</button>
                    <div className="grid-container p-1 p-sm-3 p-md-5 d-flex flex-column justify-content-center align-items-center">
                        <div style={{position: "absolute", top: "70px", right: "30px"}}>
                            <button className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center" style={{ width: "2rem", height: "2rem" }} onClick={this.openModalAyuda}>
                                <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{ margin: "0", height: "1.1rem" }} />
                            </button>
                        </div>
                        <h4 className="font-weight-bold" style={{marginRight: "35px"}}>Ingresa el código de la factura</h4>
                        <LoadMask loading={this.props.loader} blur light>
                            <CodigoFacturaForm onSubmit={this.props.buscarFactura} />
                        </LoadMask>
                    </div>
                    <ModalAyuda open={modalAyuda} closeModal={this.closeModalAyuda} openModal={this.openModalAyuda} items={itemsDevolucion}/>
                </div>
            </React.Fragment>
        )
    }
}

export default CodigoFactura;
