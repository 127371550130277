import { handleActions } from "redux-actions";
import {ToastStore} from 'react-toasts';

import Swal from "sweetalert2";
import { api } from "api";
import _ from "lodash";
import uuidv1  from "uuid/v1";
import { push } from "react-router-redux";

const endpoint = "despachos";
const endpoint_prods_ventas ="productos_venta";

// ------------------------------------
// Constants
// ------------------------------------
export const DATA = "DESPACHOS_DATA";
export const SELECCIONADOS = "DESPACHOS_SELECCIONADOS";
export const ITEM = "DESPACHOS_ITEM";
export const SORT = "DESPACHOS_SORT";
export const PAGE = "DESPACHOS_PAGE";
export const BUSCADOR = "DESPACHOS_BUSCADOR";
export const LOADER = "DESPACHOS_LOADER";
export const TAB = "DESPACHOS_TAB";
export const SUCURSAL_ORIGEN = "DESPACHOS_SUCURSAL_ORIGEN";
export const SET_DESPACHO = "DESPACHOS_SET_DESPACHO";
export const PRODUCTOS = "DESPACHOS_PRODUCTOS";
export const SORT_PRODUCTOS = "DESPACHOS_SORT_PRODUCTOS";
export const PAGE_PRODUCTOS = "DESPACHOS_PAGE_PRODUCTOS";
export const LOADER_PRODUCTOS = "DESPACHOS_LOADER_PRODUCTOS";
export const UUID_REQ_PRODUCTOS = "DESPACHOS_UUID_REQ_PRODUCTOS";
export const BUSCADOR_PRODUCTOS = "DESPACHOS_BUSCADOR_PRODUCTOS";

// ------------------------------------
// PureActions
// ------------------------------------

export const seData = data => ({
    type: DATA,
    data,
});

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setBuscador = search => ({
    type: BUSCADOR,
    search,
});

export const setSort = ordering => ({
    type: SORT,
    ordering,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setTab = tab => ({
    type: TAB,
    tab,
});
export const setSeleccionados = seleccionados => ({
    type: SELECCIONADOS,
    seleccionados,
});

export const setSucursalOrigen = sucursal_origen => ({
    type: SUCURSAL_ORIGEN,
    sucursal_origen,
});

export const setProductos = data_productos => ({
    type: PRODUCTOS,
    data_productos,
});

export const setLoaderProductos = loader_productos => ({
    type: LOADER_PRODUCTOS,
    loader_productos,
});
export const setPageProductos = page_productos => ({
    type: PAGE_PRODUCTOS,
    page_productos,
});
export const setSortProductos = ordering_productos => ({
    type: SORT_PRODUCTOS,
    ordering_productos,
});

export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos
})

export const setBuscadorProductos = search_productos => ({
    type: BUSCADOR_PRODUCTOS,
    search_productos,
});

export const setDespacho = despacho => ({
    type: SET_DESPACHO,
    despacho,
});

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const {ordering, search, tab} = store.despacho;
    const params = { ordering, search, estado:tab, page };
    api.get(endpoint, params).catch((err) => { }).then((data) => {
        if (data) {
            dispatch(seData(data));
        }
        dispatch(setPage(page));
        dispatch(setLoader(false));
    });

    dispatch(getSucursalOrigen());
};

export const getSucursalOrigen =() => (dispatch, getStore) => {
    const store = getStore();
    const {sucursal} = store.usuario;
    if (sucursal) {
        api.get(`sucursales/${sucursal}`).then(resp => {
            if (resp) {
                dispatch(setSucursalOrigen(resp))
            }
        })
    }
}

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === 'asc') {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const changeTab = (tab) => (dispatch) => {
    dispatch(setTab(tab))
    dispatch(listar())
}

export const agregar = (producto) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.despacho.seleccionados;
    if (!_.find(seleccionados, {id: producto.id})) {
        let producto_data = _.cloneDeep(producto);
        producto_data.cantidad_despachada = 1;
        dispatch(setSeleccionados([...seleccionados, producto_data]));
    }
};

export const quitar = (producto) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.despacho.seleccionados);
    const item = _.find(seleccionados, {id: producto.id})
    if (item) {
        const index = seleccionados.indexOf(item);
        seleccionados.splice(index, 1);
        dispatch(setSeleccionados(seleccionados))
    }
}

export const asignarCantidadDespachada = (producto, cantidad_despachada) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.despacho.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (cantidad_despachada < 0) {
        item.cantidad_despachada = 0;
    } else {
        item.cantidad_despachada = cantidad_despachada;
    }
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const crear = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const seleccionados = store.despacho.seleccionados;
    seleccionados.forEach(item => {
        item.cantidad_despachada = (item.cantidad_despachada != "" && item.cantidad_despachada != null) ? item.cantidad_despachada : 0;
    })
    const data = store.form.CrearDespacho.values;
    api.post(endpoint, {productos: seleccionados, sucursal_destino: data.sucursal_destino, responsable_transporte: data.responsable_transporte }).then(resp => {
        if (resp){
            dispatch(setSeleccionados([]));
            ToastStore.success("Datos almacenados correctamente");
            dispatch(setDespacho(resp))
            dispatch(push(`/despachos/nota_de_envio/${resp.id}`));
        }
    }).catch(err => {
        if (err) {
            if (err.detail) {
                Swal(
                    "ERROR",
                    err.detail,
                    "error"
                );
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                    "error"
                );
            }
        } else {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const getDespacho = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).catch((err) => {
    }).then((data) => {
        if (data) {
            dispatch(setDespacho(data));
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const anularDespacho = (id, motivo) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`, {motivo}).catch((err) => {
        dispatch(push('/despachos'));
        if (err) {
            Swal("ERROR", err.detail, "error");
        }else {
            Swal("ERROR","No se ha podido anular el despacho, intente más tarde.", "error")
        }
    }).then((data) => {
        if (data) {
            ToastStore.success("Despacho anulado correctamente.");
            dispatch(listar())
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

// -----------------------------
// PRODUCTOS
// -----------------------------
export const listarProductos = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoaderProductos(true));
    const store = getStore();
    const producto = store.despacho;
    const { ordering_productos, search_productos } = producto;
    //  GENERAR EL UUID
    const uuid = uuidv1();
    dispatch(setUuidReqProductos(uuid));
    api.get(endpoint_prods_ventas, { page, ordering:ordering_productos, search:search_productos, activo:true, inventariable:true }).catch((err) => { }).then((data) => {
        if (data) {
            const otroUuid = getStore().despacho.uuid_req_productos;
            if (otroUuid === uuid) {
                dispatch(setProductos(data));
                dispatch(setPageProductos(page));
                dispatch(setLoaderProductos(false));
            }
        }
    });
};

export const buscarProductos = (search) => (dispatch) => {
    dispatch(setBuscadorProductos(search));
    dispatch(listarProductos());
};

export const sortChangeProductos = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSortProductos(sortName));
    } else {
        dispatch(setSortProductos(`-${sortName}`));
    }
    const store = getStore();
    const page_productos = store.despacho.page_productos;
    dispatch(listarProductos(page_productos));
};

export const agregarPrimer = (search) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const producto = store.venta;
    const { ordering } = producto;
    api.get(endpoint_prods_ventas, {ordering, search}).catch((err) => {}).then((data) => {
        if (data) {
            if (data.count > 0){
                const seleccionados = store.despacho.seleccionados;
                const producto = data.results[0];
                if (!_.find(seleccionados, {id: producto.id})) {
                    // TODO configuracion de ver si agrega o solo filtra
                    dispatch(setSeleccionados([...seleccionados, data.results[0]]));
                }
            }
        }
    }).finally(() => {
        dispatch(buscar(""));
        dispatch(setLoader(false));
    });
};

export const actions = {
    sortChange,
    changeTab,
    buscar,
    listar,
    agregar,
    getSucursalOrigen,
    agregarPrimer,
    listarProductos,
    buscarProductos,
    sortChangeProductos,
    asignarCantidadDespachada,
    quitar,
    setSeleccionados,
    crear,
    getDespacho,
    anularDespacho
    // leer,
    // eliminar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [SUCURSAL_ORIGEN]: (state, { sucursal_origen }) => {
        return {
            ...state,
            sucursal_origen,
        };
    },
    [SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    // PRODUCTOS
    [PRODUCTOS]: (state, { data_productos }) => {
        return {
            ...state,
            data_productos,
        };
    },
    [LOADER_PRODUCTOS]: (state, { loader_productos }) => {
        return {
            ...state,
            loader_productos,
        };
    },
    [PAGE_PRODUCTOS]: (state, { page_productos }) => {
        return {
            ...state,
            page_productos,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [SORT_PRODUCTOS]: (state, { ordering_productos }) => {
        return {
            ...state,
            ordering_productos,
        };
    },
    [BUSCADOR_PRODUCTOS]: (state, { search_productos }) => {
        return {
            ...state,
            search_productos,
        };
    },
    [SET_DESPACHO]: (state, { despacho }) => {
        return {
            ...state,
            despacho,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    item: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "-creado",
    tab: 10,
    sucursal_origen: null,
    seleccionados: [],
    // productos
    data_productos: {},
    loader_productos: false,
    page_productos: 1,
    uuid_req_productos: "",
    ordering_productos: "",
    search_productos:"",
    despacho: null
};

export default handleActions(reducers, initialState);
