import { connect } from 'react-redux';
import ListadoOrdenCompra from "./ListadoOrdenCompra";
import { actions } from "../../../../redux/modules/orden_compra/orden_compra"

const ms2p = (state) => {
    return {
        ...state.ordenes_compra
    }
}

const md2p = {...actions};

export default connect(ms2p, md2p)(ListadoOrdenCompra);
