import React, { Component } from 'react';
import LoadMask from "Utils/LoadMask/LoadMask";;
import { Line } from 'react-chartjs-2';

class GraficaHistorialPrecios extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: '100%'
        }
    }

    render() {
        //  state
        const { data_grafica, loader_grafica, loader, pais, producto } = this.props;

        const moneda = pais=="GT" ? "Q ":"$ ";

        const translate_month = (month) => {

            var result = month;
        
            switch(month) {
                case 'Jan':
                    result = 'Ene' ;
                    break;
                case 'Apr':
                    result = 'Abr' ;
                    break;
                case 'Aug':
                    result = 'Ago' ;
                    break;
                case 'Dec':
                    result = 'Dic' ;
                    break;
            }  
            return result;
        };
        
        
        const translate_this_label = (label) => {
            const month = label.match(/Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec/g);
        
            if ( ! month ) 
                return label;
        
            const translation = translate_month( month[0] );
            return label.replace( month, translation, 'g' );
        };

        const data = {
            labels: [],
            datasets: [
                {
                    label: `Venta del producto`,
                    data: [],
                    type: 'line',
                    borderColor: 'rgba(247, 149, 42, 0.65)',
                    pointBackgroundColor: 'rgba(247, 149, 42, 0.65)',
                    backgroundColor: 'rgba(255, 192, 0, 0.10)',
                    borderWidth: 2
                },
                {
                    label: `Compra del producto`,
                    data: [],
                    type: 'line',
                    borderColor: 'rgb(60, 179, 113, 0.65)',
                    pointBackgroundColor: 'rgb(60, 179, 113, 0.65)',
                    backgroundColor: 'rgb(60, 179, 113, 0.10)',
                    borderWidth: 2
                },
            ],
        };

        const lineOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    type: 'time',
                    tooltipFormat:'MM/DD/YYYY',
                    distribution: 'linear',
                    time: {
                        displayFormats: {
                            'hour': 'MMM DD',
                            'day': 'YYYY MMM DD',
                            'week': 'YYYY MMM DD',
                            'month': 'YYYY MMM',
                            'quarter': 'YYYY MMM',
                            'year': 'YYYY MMM',
                        }
                    },
                    ticks : {

                        // Here's where the magic happens:
                        callback: function( label, index, labels ) {
    
                            return translate_this_label(label);
                        }
                    },        

                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: false,
                        callback: function (value, index, values) {
                            value = parseFloat(value);
                            let value_abs = Math.abs(value);
                            if (value_abs >= 1000) {
                                return moneda + value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return moneda + value.toFixed(2);
                            }
                        }
                    },
                }],
            },
            legend: {
              display: true,
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let value = tooltipItem.yLabel;
                        let index = tooltipItem.datasetIndex;

                        value = parseFloat(value);
                        let value_abs = Math.abs(value);    
                        if (value_abs >= 1000) {
                            return `${index == 0 ? "Precio:" : "Costo:"} ${moneda} ` + value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return `${index == 0 ? "Precio:" : "Costo:"} ${moneda} ` + value.toFixed(2);
                        }
                    },
                    beforeLabel: function(tooltipItem, data) {
                        let value = tooltipItem.xLabel;
                        value = new Date(value)

                        let day = value.getDate()
                        let month = value.getMonth() + 1
                        let year = value.getFullYear()
                        let hour = value.getHours()
                        let min = value.getMinutes()

                        if (min<10){
                            min=`0${min}`
                        }
                        
                        if(month < 10){
                            return(`${day}-0${month}-${year} ${hour}:${min}`)
                        }else{
                            return(`${day}-${month}-${year}`)
                        }
                    },
                    title: function(tooltipItem, data) {
                        return null
                    }
                }
            }, 
            elements: {
                line: {
                    tension: 0.000001
                }
            }
          };

        if (data_grafica){
            data_grafica.ventas.forEach(historial => {
                const item = { x: historial.creado, y: historial.precio }
                data.datasets[0].data.push(item);
            });
            data_grafica.compras.forEach(historial => {
                const item = { x: historial.creado, y: historial.costo }
                data.datasets[1].data.push(item);
            });
        }

        
 
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <LoadMask loading={loader_grafica} light blur>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <Line
                                    height={500}
                                    data={data}
                                    options={lineOptions}
                                />
                            </div>
                        </div>
                    </LoadMask>                    
                </div>
            </div>
        );
    }
}

export default GraficaHistorialPrecios;