import React, { Component } from 'react';
import "./ayuda.css";
import { icons } from "icons";
import Modal from 'react-responsive-modal';

class ModalAyuda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posicion: 0,
        }

    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.items !== this.props.items) {
            this.setState({posicion: 0})
        }
    }

    siguienteItem = () => {
        const {posicion} = this.state;
        const {items} = this.props;
        if (items) {
            if (posicion < items.length -1) {
                this.setState({posicion: posicion + 1})
            }else {
                this.setState({posicion: 0});
                this.props.closeModal()
            }
        }
    };
    regresarItem = () => {
        const {posicion} = this.state;
        if (posicion > 0) {
            this.setState({posicion: posicion - 1})
        } else {
            this.props.closeModal()
        }
    };

    setItem = (idx) => {
        this.setState({posicion: idx})
    };

    render() {
        // State
        const { open, items } = this.props;
        const { posicion } = this.state;

        // Bind
        const { closeModal } = this.props;

        return (
            <div className="w-100">
                <Modal open={open} onClose={closeModal} showCloseIcon={false} classNames={{modal:"modal-ayuda"}} styles={{padding: "10px"}}>
                    {
                        (items.length > 0) ? (
                            <img src={items[posicion].src} alt={items[posicion].alt} title={items[posicion].alt} style={{width: "100%"}}/>
                        ): (
                            <div className="w-100 text-center mt">
                                <h1 className="text-warning">No hay imagenes para mostrar.</h1>
                                <em className="fa fa-frown-o text-warning" style={{fontSize: "200px"}}/>
                            </div>
                        )
                    }
                    {
                        (items.length > 0) && (
                            <div className="row">
                                <div className="w-100">
                                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mt-2">
                                        <div className="d-flex flex-row flex1">
                                            {
                                                items.map((item, index) => {
                                                    return(
                                                        <div key={index} className={`punto ${index <= posicion && "punto punto-activo"}`} title={item.alt} onClick={() => {
                                                            this.setItem(index);
                                                        }}/>
                                                    )
                                                })
                                            }
                                            {/* <div className="punto"/>
                                            <div className="punto punto-activo"/>
                                            <div className="punto"/> */}
                                        </div>
                                        <div className="mt-sm-10">
                                            <button className="btn btn-tertiary fnt-14 mr-2" onClick={this.regresarItem}>
                                                Regresar
                                            </button>
                                            <button className="btn btn-primary fnt-14 " onClick={this.siguienteItem}>
                                                {
                                                    posicion < items.length - 1 ? "Siguiente" : "Finalizar"
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Modal>
            </div>
        );
    }
}

ModalAyuda.propTypes = {

};

export default ModalAyuda;
