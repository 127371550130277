import { api } from 'api';
import Swal from 'sweetalert2';
import { handleActions } from 'redux-actions';

export const DATA = "DASHBOARD_DATA";
export const LOADER = "DASHBOARD_LOADER";
export const NAVBAR_COLLAPSE = "NAVBAR_COLLAPSE";
export const MOSTRAR_DASHBOARD = "MOSTRAR_DASHBOARD";

const endpoint = "dashboard";


export const setDataDashboard = () => (dispatch, getStore) => {
    dispatch(setLoader(true));

    api
        .get(endpoint, {})
        .then(response => {
            if (response)
                dispatch(setData(response))
        })
        .catch(error => {
            // COMENTADO PORQUE NO ES UTIL AL USUARIO
            // Swal(
            //     'ERROR',
            //     'Ha ocurrido un error. Por favor intenta más tarde.',
            //     'error'
            // );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

export const toggleNav = () => (dispatch, getStore) => {
    const navbarCollapse = getStore().dashboard.navbarCollapse;
    dispatch(setNavbarCollapse(!navbarCollapse));
};

const setDataActivarDashboard = (mostrar_dashboard) => (dispatch, getStore) => {
   
    dispatch({type: MOSTRAR_DASHBOARD, mostrar_dashboard});
   
    if(mostrar_dashboard){
      dispatch(setDataDashboard())
    }

  };


export const setNavbarCollapse = navbarCollapse => ({
    type: NAVBAR_COLLAPSE,
    navbarCollapse,
});

export const setData = data => ({
    type: DATA,
    data,
});
export const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const actions = {
   setDataDashboard,
   setDataActivarDashboard
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [NAVBAR_COLLAPSE]: (state, { navbarCollapse }) => {
        return {
            ...state,
            navbarCollapse,
        };
    },
    [MOSTRAR_DASHBOARD]: (state, { mostrar_dashboard }) => {
        return {
            ...state,
            mostrar_dashboard,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {
        ventas: {
            hoy: 0,
            mes: 0,
            semana: 0,
        },
        compras: {
            hoy: 0,
            mes: 0,
            semana: 0,
        }
    },
    navbarCollapse: false,
    loader: false,
    mostrar_dashboard:false
};

export default handleActions(reducers, initialState);
