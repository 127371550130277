import _ from "lodash";
import { api } from 'api';
import uuidv1 from "uuid/v1";
import Swal from 'sweetalert2';
import {ToastStore} from 'react-toasts';
import { goBack } from "react-router-redux";
import { handleActions } from 'redux-actions';
import { actions as ventaActions, setBuscador, setCotizacionCargada, setNitCliente, setNombreCliente } from "../venta/venta";
// ------------------------------------
// Constants
// ------------------------------------
export const DATA = "COTIZACIONES_DATA";
export const SORT = "COTIZACIONES_SORT";
export const PAGE = "COTIZACIONES_PAGE";
export const LOADER = "COTIZACIONES_LOADER";
export const BUSCADOR = "COTIZACIONES_BUSCADOR";
export const UUID_REQ_COTIZACIONES = "COTIZACIONES_UUID_REQ_COTIZACIONES";

const endpoint = "cotizaciones";

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { ordering, search } = store.cotizaciones;
    const params = { ordering, search, page, estado: 10, enviado_caja: false };
    //  GENERAR EL UUID
    const uuid = uuidv1();
    dispatch(setUuidReqCotizacion(uuid));
    api.get(`${endpoint}`, params).catch((err) => { }).then((data) => {
        if (data) {
            const otroUuid = getStore().cotizaciones.uuid_req;
            if (otroUuid === uuid) {
                dispatch(seData(data));
                dispatch(setPage(page));
            }
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === 'asc') {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

/**
 * Action para cargar el detalle de la cotizacion
 * @param {*} cotizacion 
 */
export const cargarCotizacionPuntoVenta = (cotizacion) => (dispatch, getStore) => {
    dispatch(ventaActions.setSeleccionados([]));
    dispatch(setBuscador(""));
    dispatch(setLoader(true));
    api.get(`${endpoint}/detalles`, { cotizacion_id: cotizacion.id }).catch((err) => {
        dispatch(setLoaderDetalle(false));
    }).then((resp) => {
        if (resp) {
            resp.results.forEach((detalle) => {
                const store = getStore();
                const detalle_cotizacion = _.cloneDeep(store.venta.seleccionados);
                const items = _.filter(detalle_cotizacion, { id: detalle.fraccion.id });
                let item = {
                    detalle_id: detalle.id,
                    id: detalle.fraccion.id,
                    id_det_mov: detalle.id,
                    key: uuidv1(),
                    collapsed: false,
                    es_vale: false,
                    producto: detalle.fraccion.producto,
                    codigo_barras: detalle.fraccion.codigo_barras,
                    cantidad_total: 0,
                    inventario: detalle.fraccion.inventario,
                    inventario_total: 0,
                    precio: detalle.precio,
                    desc_monto: detalle.desc_monto,
                    desc_porcentaje: detalle.desc_porcentaje,
                    precio_original: detalle.precio_original,
                    cantidad: detalle.cantidad,
                    inventario_calculado: detalle.fraccion.inventario_calculado,
                    nombre: detalle.fraccion.nombre,
                    codigo: null,// Para vales
                    unidad_de_medida: detalle.fraccion.unidad_de_medida

                };
                if (items.length) {
                    item.inventario_total = items[0].inventario_total;
                } else {
                    item.inventario_total = item.inventario_calculado;
                }

                //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
                let cantidad_total = item.cantidad;

                //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
                items.forEach(itemRep => {
                    cantidad_total += itemRep.cantidad;
                })

                //  SE CALCULA EL INVENTARIO FINAL
                const inventario_final = item.inventario_total - cantidad_total;

                //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
                items.forEach(itemRep => {
                    const indexSeleccionado = _.indexOf(detalle_cotizacion, itemRep);
                    itemRep.inventario = inventario_final >= 0 ? inventario_final : 0;
                    itemRep.cantidad_total = cantidad_total;
                    detalle_cotizacion.splice(indexSeleccionado, 1, itemRep);
                })

                item.cantidad_total = cantidad_total;
                item.inventario = inventario_final >= 0 ? inventario_final : 0;
                item.id_unidad_de_medida = item.producto.a_granel ? item.unidad_de_medida : null;
                dispatch(ventaActions.setSeleccionados([...detalle_cotizacion, item]));
            })
            dispatch(setCotizacionCargada(cotizacion))
            if (cotizacion.nit != "CF") {
                dispatch(setNitCliente(cotizacion.nit))
            }
            dispatch(setNombreCliente(cotizacion.nombre))
            dispatch(setLoader(false));

            // VALIDACION DE REDIRECCIONAMIENTO
            dispatch(goBack());
            // dispatch(push("/punto_de_venta"));
        }
    })
}

export const eliminarCotizacion = (id) => (dispatch, getStore) => {
    Swal({
        title: '¿Eliminar cotización?',
        text: '¡No podrá revertir esta acción!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, eliminar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true))
            api
                .eliminar(`${endpoint}/${id}`)
                .then(response => {
                    ToastStore.success("Cotizacion eliminada.");
        
                    dispatch(setLoader(false));
                    dispatch(listar());
        
                }, error => {
                    Swal(
                        '¡Error!',
                        "No se ha eliminado la otización, intenta más tarde",
                        'error'
                    ).then(() => { });
        
                    dispatch(setLoader(false))
                    dispatch(listar());
                });
        }
      });
}

export const escanearCodigoBarras = (search) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { ordering } = store.cotizaciones;
    const params = { ordering, search, estado: 10, enviado_caja: false };
    api.get(`${endpoint}`, params).catch((err) => { }).then((data) => {
        if (data) {
            if (data.count === 1) {
                dispatch(cargarCotizacionPuntoVenta(data.results[0]));
            }
        }
    }).finally(() => {
        dispatch(setSearch(""));
        dispatch(listar());
        dispatch(setLoader(false));
    });
}

// ------------------------------------
// PureActions
// ------------------------------------
export const seData = data => ({
    type: DATA,
    data,
});

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setSearch = search => ({
    type: BUSCADOR,
    search,
});

export const setSort = ordering => ({
    type: SORT,
    ordering,
});

export const setPage = page => ({
    type: PAGE,
    page,
});
export const setUuidReqCotizacion = (uuid_req) => ({
    type: UUID_REQ_COTIZACIONES,
    uuid_req
})

export const actions = {
    listar,
    buscar,
    sortChange,
    eliminarCotizacion,
    escanearCodigoBarras,
    cargarCotizacionPuntoVenta,
}

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [UUID_REQ_COTIZACIONES]: (state, { uuid_req }) => {
        return {
            ...state,
            uuid_req,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "-creado",
    uuid_req: "",
}

export default handleActions(reducers, initialState);