import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/print/print'

class PrintComponent extends Component {
    constructor(props){
      super (props);
    }
    
    componentDidMount(){
      this.printRef.handlePrint();
      this.props.banderaDown();
      
    }

    render() {

      const el = document.getElementById("PRINT-" + this.props.item);
      return (
          <ReactToPrint
            trigger={() => <button>IMPRIMIR</button>}
            content={() => el}
            ref={el => (this.printRef = el)}
          />
      );
    }
  }


  const ms2p = (state) => {
    return {
      ...state.print,
    };
  };

const md2p = { ...actions, };

export default connect(ms2p,md2p)(PrintComponent)