import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/reportes/inversion";
import Inversion from './Inversion';


const ms2p = (state) => {
    return {
        ...state.inversion,
        pais: state.usuario.me.pais,
        sucursal: state.usuario.sucursal
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Inversion);
