import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/caja/caja";
import CajasListado from './CajasListado';


const ms2p = (state) => {
    const usuario = state.usuario.me;
    return {
        ...state.caja,
        usuario,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CajasListado);
