import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { ToastStore } from "react-toasts";

const endpoint = "historial_inventario";
const endpoint_grafica = "historial_inventario/grafica";

//REP_MOV : REPORTE MOVIMIENTOS

export const DATA = "REP_MOV_DATA";
export const DATA_GENERAL = "REP_MOV_DATA_GENERAL";
export const DATA_GRAFICA = "REP_MOV_DATA_GRAFICA";
export const LOADER = "REP_MOV_LOADER";
export const LOADER_GRAFICA = "REP_MOV_LOADER_GRAFICA";
export const PAGE = "REP_MOV_PAGE";
export const PAGE_GENERAL = "REP_MOV_PAGE_GENERAL";
export const SUCURSAL = "REP_MOV_SUCURSAL";
export const SUCURSAL_GENERAL = "REP_MOV_SUCURSAL_GENERAL";
export const PRODUCTO = "REP_MOV_PRODUCTO";
export const FRACCION = "REP_MOV_FRACCION";
export const SORT = "REP_MOV_SORT";
export const SORT_GENERAL = "REP_MOV_SORT_GENERAL";
export const FECHA_INICIAL = "REP_MOV_FECHA_INICIAL";
export const FECHA_INICIAL_GENERAL = "REP_MOV_FECHA_INICIAL_GENERAL1";
export const FECHA_FINAL = "REP_MOV_FECHA_FINAL";
export const FECHA_FINAL_GENERAL = "REP_MOV_FECHA_FINAL_GENERAL1";
export const ESTADO_DESCARGA = "REP_MOV_ESTADO_DESCARGA";

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();

    const fecha_inicial = store.reporte_movimientos.fecha_inicial;
    const fecha_final = store.reporte_movimientos.fecha_final;
    const params = { page, fecha_inicial, fecha_final };
    const sucursal = store.reporte_movimientos.sucursal;
    const fraccion = store.reporte_movimientos.fraccion;
    const producto = store.reporte_movimientos.producto;

    params.ordering = store.reporte_movimientos.ordering;

    if (fraccion) params.fraccion = fraccion.id;
    if (producto) params.producto = producto.id;
    if (sucursal) params.sucursal_rep = sucursal.id;
    if (sucursal && producto && fraccion) {
        api.get(`${endpoint}`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                    dispatch(dataGraficaMovimientos());
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    } else {
        const data = {};
        dispatch(setData(data));
        dispatch(setLoader(false));
    }
};

export const listarGeneral = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();

    const fecha_inicial = store.reporte_movimientos.fecha_inicial_general;
    const fecha_final = store.reporte_movimientos.fecha_final_general;
    const params = { page, fecha_inicial, fecha_final };
    const sucursal = store.reporte_movimientos.sucursal_general;

    params.ordering = store.reporte_movimientos.ordering_general;

    if (sucursal) params.sucursal_rep = sucursal.id;
    if (sucursal) {
        api.get(`${endpoint}/general`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setDataGeneral(resp));
                    dispatch(setPageGeneral(page));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    } else {
        const data = {};
        dispatch(setDataGeneral(data));
        dispatch(setLoader(false));
    }
};

export const dataGraficaMovimientos = () => (dispatch, getStore) => {
    dispatch(setLoaderGrafica(true));
    const store = getStore();

    const fecha_inicial = store.reporte_movimientos.fecha_inicial;
    const fecha_final = store.reporte_movimientos.fecha_final;
    const params = { fecha_inicial, fecha_final };
    const sucursal = store.reporte_movimientos.sucursal;
    const fraccion = store.reporte_movimientos.fraccion;
    const producto = store.reporte_movimientos.producto;

    if (fraccion) params.fraccion = fraccion.id;
    if (producto) params.producto = producto.id;
    if (sucursal) params.sucursal_rep = sucursal.id;
    if (sucursal && producto && fraccion) {
        api.get(`${endpoint_grafica}`, params)
            .then((response) => {
                if (response) dispatch(setDataGrafica(response));
            })
            .catch((error) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .finally(() => {
                dispatch(setLoaderGrafica(false));
            });
    } else {
        dispatch(setDataGrafica(null));
        dispatch(setLoaderGrafica(false));
    }
};

export const setSucursal = (value) => (dispatch, getStore) => {
    dispatch(_setSucursal(value));

    const store = getStore();
    const producto = store.reporte_movimientos.producto;
    const sucursal = store.reporte_movimientos.sucursal;
    const fraccion = store.reporte_movimientos.fraccion;

    if (producto && sucursal && fraccion) {
        dispatch(listar());
    } else {
        dispatch(setDataGrafica(null));
    }
};

export const setSucursalGeneral = (value) => (dispatch, getStore) => {
    dispatch(_setSucursalGeneral(value));
    const store = getStore();
    const sucursal = store.reporte_movimientos.sucursal_general;
    if (sucursal) {
        dispatch(listarGeneral());
    }
};

export const setProducto = (value) => (dispatch, getStore) => {
    dispatch(_setProducto(value));

    const store = getStore();
    const producto = store.reporte_movimientos.producto;
    const sucursal = store.reporte_movimientos.sucursal;
    const fraccion = store.reporte_movimientos.fraccion;

    if (producto && sucursal && fraccion) {
        dispatch(listar());
    } else {
        dispatch(setDataGrafica(null));
    }
};
export const setFraccion = (value) => (dispatch, getStore) => {
    dispatch(_setFraccion(value));

    const store = getStore();
    const sucursal = store.reporte_movimientos.sucursal;
    const producto = store.reporte_movimientos.producto;
    const fraccion = store.reporte_movimientos.fraccion;

    if (producto && sucursal && fraccion) {
        dispatch(listar());
    } else {
        dispatch(setDataGrafica(null));
    }
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_movimientos.page;
    dispatch(listar(page));
};

export const sortChangeGeneral = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSortGeneral(sortName));
    } else {
        dispatch(setSortGeneral(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_movimientos.page;
    dispatch(listarGeneral(page));
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_movimientos = store.reporte_movimientos;
    const sucursal = store.reporte_movimientos.sucursal;
    const producto = store.reporte_movimientos.producto;
    const fraccion = store.reporte_movimientos.fraccion;

    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(reporte_movimientos.fecha_final);
        if (fecha_final >= fecha_inicial) dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(reporte_movimientos.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    if (producto && sucursal && fraccion) {
        dispatch(listar());
    } else {
        dispatch(setDataGrafica(null));
    }
};

export const setFechaGeneral = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_movimientos = store.reporte_movimientos;
    const sucursal = store.reporte_movimientos.sucursal_general;

    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(reporte_movimientos.fecha_final_general);
        if (fecha_final >= fecha_inicial) {
            dispatch(setFechaInicialGeneral(value));
        }
    } else {
        const fecha_inicial = new Date(reporte_movimientos.fecha_inicial_general);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) {
            dispatch(setFechaFinalGeneral(value));
        }
    }
    if (sucursal) {
        dispatch(listarGeneral());
    }
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const fecha_inicial = store.reporte_movimientos.fecha_inicial;
    const fecha_final = store.reporte_movimientos.fecha_final;
    const params = { fecha_inicial, fecha_final };
    const sucursal = store.reporte_movimientos.sucursal;
    const fraccion = store.reporte_movimientos.fraccion;
    const producto = store.reporte_movimientos.producto;

    params.ordering = store.reporte_movimientos.ordering;

    if (fraccion) params.fraccion = fraccion.id;
    if (producto) params.producto = producto.id;
    if (sucursal) params.sucursal_rep = sucursal.id;

    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel_reporte`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

export const descargarListadoGeneral = () => (dispatch, getStore) => {
    const store = getStore();
    const fecha_inicial = store.reporte_movimientos.fecha_inicial_general;
    const fecha_final = store.reporte_movimientos.fecha_final_general;
    const params = { fecha_inicial, fecha_final };
    const sucursal = store.reporte_movimientos.sucursal_general;

    params.ordering = store.reporte_movimientos.ordering;

    if (sucursal) params.sucursal_rep = sucursal.id;

    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel_reporte_general`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setData = (data) => ({
    type: DATA,
    data,
});
export const setDataGeneral = (data_general) => ({
    type: DATA_GENERAL,
    data_general,
});
export const setDataGrafica = (data_grafica) => ({
    type: DATA_GRAFICA,
    data_grafica,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setLoaderGrafica = (loader_grafica) => ({
    type: LOADER_GRAFICA,
    loader_grafica,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setPageGeneral = (page_general) => ({
    type: PAGE_GENERAL,
    page_general,
});
export const _setSucursal = (sucursal) => ({
    type: SUCURSAL,
    sucursal,
});
export const _setSucursalGeneral = (sucursal_general) => ({
    type: SUCURSAL_GENERAL,
    sucursal_general,
});
export const _setProducto = (producto) => ({
    type: PRODUCTO,
    producto,
});
export const _setFraccion = (fraccion) => ({
    type: FRACCION,
    fraccion,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setSortGeneral = (ordering_general) => ({
    type: SORT_GENERAL,
    ordering_general,
});
export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});
export const setFechaInicialGeneral = (fecha_inicial_general) => ({
    type: FECHA_INICIAL_GENERAL,
    fecha_inicial_general,
});
export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});
export const setFechaFinalGeneral = (fecha_final_general) => ({
    type: FECHA_FINAL_GENERAL,
    fecha_final_general,
});
const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const actions = {
    setSucursal,
    setSucursalGeneral,
    setProducto,
    setFraccion,
    listar,
    listarGeneral,
    sortChange,
    sortChangeGeneral,
    dataGraficaMovimientos,
    setFecha,
    setFechaGeneral,
    descargarListado,
    descargarListadoGeneral,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_GENERAL]: (state, { data_general }) => {
        return {
            ...state,
            data_general,
        };
    },
    [DATA_GRAFICA]: (state, { data_grafica }) => {
        return {
            ...state,
            data_grafica,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LOADER_GRAFICA]: (state, { loader_grafica }) => {
        return {
            ...state,
            loader_grafica,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE_GENERAL]: (state, { page_general }) => {
        return {
            ...state,
            page_general,
        };
    },
    [SUCURSAL]: (state, { sucursal }) => {
        return {
            ...state,
            sucursal,
        };
    },
    [SUCURSAL_GENERAL]: (state, { sucursal_general }) => {
        return {
            ...state,
            sucursal_general,
        };
    },
    [PRODUCTO]: (state, { producto }) => {
        return {
            ...state,
            producto,
        };
    },
    [FRACCION]: (state, { fraccion }) => {
        return {
            ...state,
            fraccion,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SORT_GENERAL]: (state, { ordering_general }) => {
        return {
            ...state,
            ordering_general,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_INICIAL_GENERAL]: (state, { fecha_inicial_general }) => {
        return {
            ...state,
            fecha_inicial_general,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [FECHA_FINAL_GENERAL]: (state, { fecha_final_general }) => {
        return {
            ...state,
            fecha_final_general,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    data_general: {},
    data_grafica: [],
    ordering: "",
    ordering_general: "",
    loader: false,
    loader_grafica: false,
    page: 1,
    page_general: 1,
    sucursal: 0,
    fraccion: 0,
    producto: null,
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    fecha_inicial_general: moment().format("YYYY-MM-DD"),
    fecha_final_general: moment().format("YYYY-MM-DD"),
    estado_descarga: false,
};

export default handleActions(reducers, initialState);
