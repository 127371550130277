import React from 'react'
import { icons } from "icons"
import { activeFormatter } from "Utils/Acciones/Acciones"
import { RenderDateTime } from "Utils/renderField/renderTableField"
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField"
import moment from "moment";

function rowClassNameFormat(row, rowIdx){
    return !!(row.anulado)? 'underline-row' : '';
}
export const TablaOC = (props) => {
    const anular = props.anular
    return (
        <BootstrapTable
            data={props.loader ? [] : props.datos_listado.results}
            remote pagination hover
            fetchInfo={{ dataTotalSize: props.datos_listado.count }}
            trClassName={rowClassNameFormat}
            options={props.options}
        >
            {/* <TableHeaderColumn
                dataField='creado'
                dataFormat={(cell) => <RenderDateTime fecha={cell} />}
                dataSort >Fecha
            </TableHeaderColumn> */}
            <TableHeaderColumn dataField='sucursal_destino__nombre'
                dataFormat={(cell, row) => <div>{row.sucursal_destino.nombre}</div>}
                dataSort >Sucursal destino
            </TableHeaderColumn>
            <TableHeaderColumn dataField='proveedor__nombre'
                dataFormat={(cell, row) => <div>{row.proveedor.nombre}</div>}
            >Proveedor
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='fecha'
                dataFormat={(cell) => <RenderDate value={cell} />}
                dataSort >Fecha
            </TableHeaderColumn>
            <TableHeaderColumn dataField='no_orden'
            >Código
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='fecha_fin_pago'
                dataFormat={(cell, row) => {
                    return (
                        <div>
                            {row.tipo_pago ? (
                                <RenderDate value={cell} />
                            ) : (
                                    <span>-----</span>
                                )}
                        </div>
                    )
                }}
            >Fecha fin pago
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='estado_inventario'
                dataFormat={(cell, row) => {
                    return (
                        !!(row.anulado===true)?
                        <div>
                            <span className="font-weight-bold text-danger">ANULADO</span>
                        </div>:
                        <div>
                            {cell==100 && (<span className="font-weight-bold text-danger">PENDIENTE</span>)}
                            {cell==200 && (<span className="font-weight-bold text-warning">PARCIAL</span>)}
                            {cell==300 && (<span className="font-weight-bold text-success">COMPLETADA</span>)}

                        </div>
                    )
                }}
            >Entrega
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='pago_finalizado'
                dataFormat={(cell) => {
                    return (
                        <div>
                            {cell ? (
                                <img style={{ margin: "0", height: "1.5rem" }} src={icons.check} alt="Finalizado"/>
                            ) : (
                                <img style={{ margin: "0", height: "1.5rem" }} src={icons.reloj} alt="Pendiente"/>
                            )}
                        </div>
                    )
                }}
            >Pago
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField='tipo_pago'
                dataFormat={(cell) => {
                    return (
                        <div>
                            {cell == 1000 ? (
                                <span className="font-weight-bold text-success">INMEDIATO</span>
                            ) : (
                                    <span className="font-weight-bold text-danger">CRÉDITO</span>
                                )}
                        </div>
                    )
                }}
            >Tipo de pago
            </TableHeaderColumn>
            <TableHeaderColumn
                dataAlign='right'
                dataField='total'
                dataFormat={(cell) => <RenderCurrency value={cell} />}
            >Total
            </TableHeaderColumn>
            <TableHeaderColumn
                dataAlign='right'
                dataField='saldo'
                dataFormat={(cell) => <RenderCurrency value={cell}/>}
            >Saldo
            </TableHeaderColumn>
            <TableHeaderColumn
                isKey
                dataField='id'
                dataAlign="center"
                dataFormat={(cell, row) => {
                    const created = moment(row.creado).add(24, "hours")
                    const now = moment()
                    console.log('creado ', created)
                    console.log('ahora', now)
                    console.log('row',  row)
                    let anular_activo = true
                    if(created <= now){
                        anular_activo = false
                    }
                    if(row.anular_deshabilitado === true){
                        anular_activo = false
                    }
                    return activeFormatter({ver: '/ordenes_de_compra', anular, anular_activo: anular_activo})(cell, row)
                }}>
                Acciones
            </TableHeaderColumn>
        </BootstrapTable>
    )
}
