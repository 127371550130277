import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SeleccionSucursal } from "Utils/SeleccionSucursal";
import MiCaja from "../../components/Cajas/Detalle/DetalleCajaContainer";
import Venta from "../../components/Ventas/Venta"
import LoadMask from "Utils/LoadMask/LoadMask";

class PuntoVenta extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {
        this.props.setNavbarCollapse(true);
    }

    render() {
        // state
        const { sucursal, caja, tab, loaderUsuario } = this.props;
        // bind
        const { setTab } = this.props;

        /* region SELECCION DE SUCURSAL */
        if (sucursal === undefined) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo padding-5">
                                Seleccione una sucursal
                            </div>
                            <div className="padding-15">
                                <SeleccionSucursal />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        /* endregion SELECCION DE SUCURSAL */

        if (!caja.id){
            return (
                <LoadMask loading={loaderUsuario} light blur radius>
                    <div className="row">
                        <div className="col-12">
                            <div className="grid-container">
                                <h1 className="grid-titulo padding-15">Detalle Caja</h1>
                                <div className="row  padding-15">
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <i className="fa fa-exclamation-circle fa-4x" />
                                        <h1><b>No has abierto ninguna caja</b></h1>
                                        <div>¿Deseas abrir una caja?</div>
                                        <Link className="btn btn-primary" to={"/abrir_caja"}>Abrir</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadMask>
            );
        }else {
            if (caja.caja.sucursal != parseInt(sucursal)) {
                return (
                    <div className="row">
                        <div className="col-12">
                            <div className="grid-container">
                                <h1 className="grid-titulo padding-15">Punto de venta</h1>
                                <div className="row  padding-15">
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <i className="fa fa-exclamation-circle fa-4x" />
                                        <h1><b>No puedes operar</b></h1>
                                        <div>Tienes una caja abierta en otra sucursal <b>({caja.caja.nombre_sucursal})</b>.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }


        return (
            <div className="w-100">
                <Tabs className="content-tabs" selectedIndex={tab} onSelect={tabIndex => setTab(tabIndex)}>
                    <TabList className="tab_list d-flex flex-row justify-content-between">
                        <Tab className="content-tab-venta flex1 text-center" selectedClassName="content-tab-venta-active">VENTAS</Tab>
                        <Tab className="content-tab-venta flex1 text-center" selectedClassName="content-tab-venta-active">MI CAJA</Tab>
                    </TabList>
                    {/* PANEL VENTA */}
                    <TabPanel>
                        <Venta/>
                    </TabPanel>
                    {/* PANEL CAJA */}
                    <TabPanel>
                        <MiCaja/>
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

PuntoVenta.propTypes = {

};

export default PuntoVenta;
