import { connect } from 'react-redux';

import GraficaGastos from './GraficaGastos';
import { actions } from "../../../../../redux/modules/reportes/gastos";

const ms2p = (state) => ({
    ...state.reporte_gastos,
    pais: state.usuario.me.pais
})

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(GraficaGastos);
