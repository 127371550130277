import { icons } from "icons";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { RenderDate } from 'Utils/renderField/renderReadField'
import { RenderNumero } from "Utils/renderField/renderTableField"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

export const TablaRecepcionOrdenCompra = (props) => {
    // state
    const { data, loader, search, page, tab } = props;
    // bind
    const { buscar, listar, sortChange } = props;

    function rowClassNameFormat(row, rowIdx) {
        return !!(row.anulado) ? 'underline-row' : '';
    }

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? 'Cargando...' : options.noDataText;
    options.page = page;
    options.onPageChange = listar;
    options.onSortChange = sortChange;
    return (
        <div className="w-100">
            <div className="grid-container">
                <div className="grid-titulo">
                    <Toolbar titulo={"Listado de recepciones"} buscar={buscar} search={search} />
                </div>
                <LoadMask loading={loader} dark blur>
                    <BootstrapTable
                        data={loader ? [] : data.results}
                        remote pagination hover
                        trClassName={rowClassNameFormat}
                        fetchInfo={{ dataTotalSize: data.count }}
                        options={options}
                    >
                        <TableHeaderColumn dataField='no_orden' isKey>No. Orden</TableHeaderColumn>
                        <TableHeaderColumn dataField='proveedor__nombre' dataSort dataFormat={(cell, row) => {
                            return <span>{ row.proveedor.nombre }</span>
                        }}>Proveedor</TableHeaderColumn>
                        <TableHeaderColumn dataField='fecha' dataFormat={(cell) => <RenderDate value={cell} />} dataSort >Fecha</TableHeaderColumn>
                        <TableHeaderColumn dataField='sucursal__nombre' dataFormat={(cell, row) => <div>{row.sucursal.nombre}</div>} dataSort >Enviado desde</TableHeaderColumn>
                        <TableHeaderColumn dataField='descripcion'>Nota</TableHeaderColumn>
                        { !tab ? (
                            <TableHeaderColumn
                                dataField='id'
                                dataAlign="center"
                                dataFormat={(cell, row) => {
                                    return (
                                        <Link to={`/recepciones_de_ordenes_de_compra/recibir/${cell}`}><img className="action-img" src={icons.accion_recepcion_oc} alt="recibir orden de compra" title="Recibir orden de compra"/></Link>
                                    )
                                }}>
                                Acciones
                            </TableHeaderColumn>

                        ): (
                            <TableHeaderColumn
                                dataField='id'
                                dataAlign="center"
                                dataFormat={(cell, row) => {
                                    if (row.anulado){
                                        return null
                                    }else {
                                    return (
                                        <Link to={`/recepciones_de_ordenes_de_compra/visualizacion/${cell}`}><img className="action-img" src={icons.ver} alt="detalle orden compra" title="Detalle orden de compra"/></Link>
                                    )}
                                }}>
                                Acciones
                            </TableHeaderColumn>
                        )}
                    </BootstrapTable>
                </LoadMask>
            </div>
        </div>
    )
}

export function accionProductosRecepcionOC(props) {
    return (cell, row) => {
        return (
            <div className="w-100 d-flex align-items-center justify-content-end">
                <img
                    className="icon-img"
                    style={{ marginRight: "10px" }}
                    onClick={e => {
                        e.persist();
                        props.agregar(row);
                    }}
                    src={icons.agregar}
                    alt="Agregar"
                />
            </div>
        );
    }
}

export const TablaProductosDetalleOC = (props) => {
    // State
    const { orden_compra, loader } = props;
    // Bind
    // const { marcarEntregaOC } = props;

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    return (
        <div className="w-100 col-12 pt-3 pb-2">
            {/* <button className="mb-4 btn btn-secondary" disabled={orden_compra.estado_inventario == 300 || orden_compra.estado == 20} onClick={() => marcarEntregaOC(orden_compra.id)}>Finalizar Entrega</button> */}
            <BootstrapTable
                data={loader ? [] : orden_compra.detalles}
                hover
                options={options}
            >
                <TableHeaderColumn
                    width="0%"
                    dataField="id"
                    isKey
                >
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nombre"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (
                            <span>
                                <b>{row.producto }</b>
                                <div>{row.fraccion.producto.a_granel ? row.unidad_de_medida.nombre: row.fraccion.nombre}</div>
                            </span>
                        )
                    }}
                >
                    Producto
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                    dataField="precio"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderMoneda monto={row.es_vale ? (cell * -1) : cell} />)
                    }}
                >
                    Precio
                </TableHeaderColumn> */}
                <TableHeaderColumn
                    dataField="cantidad"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderNumero monto={cell} />)
                    }}
                >
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad_recibido"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderNumero monto={cell ? cell : 0} />)
                    }}
                >
                    Cantidad recibido
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                    dataField="sub_total"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderMoneda monto={cell} />)
                    }}
                >
                    Subtotal
                </TableHeaderColumn> */}
            </BootstrapTable>
            {/* <div className="border-bottom w-100 pl-3 pr-3 mt-2 d-flex justify-content-between flex-row">
                <h5><b>Total</b></h5>
                <h5><b><RenderCurrency value={orden_compra.total} /></b></h5>
            </div> */}
        </div>
    )
}
