import React from "react";
import { renderField } from "Utils/renderField/renderField";
import { Field, reduxForm } from "redux-form";
import { icons } from "icons";
import { Link } from "react-router-dom";
import {} from "validate-redux-form";
import { email, nitSinCF } from "../../../../utility/validation";

const validate = (values) => {
    const errors = {};
    if (values.nit) {
        const resultado = nitSinCF(values.nit);
        if (resultado) errors.nit = resultado;
    } else {
        errors.nit = "Campo requerido";
    }
    if (!values.nombre) errors.nombre = "Campo requerido";
    if (values.correo) {
        const res = email(values.correo);
        if (res) {
            errors.correo = res;
        }
    }

    return errors;
};

const FormRender = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit} className="row mt">
            <div className="col-sm-12">
                <div className="form-group">
                    <div className="padding-15 p-sm-0 pt-sm-1">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Datos del Cliente</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="nombre">Nombre Completo*</label>
                                <Field
                                    name="nombre"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="nit">Nit*</label>
                                <Field
                                    name="nit"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="direccion">Dirección</label>
                                <Field
                                    name="direccion"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="contacto">Contacto</label>
                                <Field
                                    name="contacto"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="correo">Correo</label>
                                <Field
                                    name="correo"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="telefono">Teléfono</label>
                                <Field
                                    name="telefono"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <br />
                        <div className="d-flex justify-content-center flex-row">
                            <Link
                                className="btn btn-tertiary m-1 align-self-center"
                                to="/configuracion/clientes"
                            >
                                <img src={icons.cancelar} alt="" />
                                Cancelar{" "}
                            </Link>
                            <button
                                type="submit"
                                className="btn btn-primary m-1 align-self-center"
                            >
                                <img src={icons.save} alt="" />
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const Form = reduxForm({
    // a unique name for the form
    form: "ClienteCrear",
    validate,
})(FormRender);

export default Form;
