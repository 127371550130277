import { connect } from 'react-redux';

import ResumenGasto from './ResumenGasto';
import { actions } from "../../../../../redux/modules/reportes/gastos";
import { print } from '../../../../../redux/modules/print/print'

const ms2p = (state) => ({
    ...state.reporte_gastos,
    usuarios_sucursal: state.usuario.usuarios_sucursal,
    print_state: state.print,
})

const md2p = {
    ...actions,
    print: (name) => dispatch => dispatch(print(name)),
};

export default connect(ms2p, md2p)(ResumenGasto);
