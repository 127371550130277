import React from 'react';
import { icons } from "icons";
import { RenderCurrency, RenderNumber } from "Utils/renderField/renderReadField";
import NumberFormat from 'react-number-format';

export function formatoAcciones(props) {
    return (cell, row) => {
        if (row.existe_descontable || props.conf_cotizacion)
            return (
                <div className="w-100 d-flex align-items-center justify-content-center">
                    <button className="btn btn-agregar-venta" onClick={() => {
                        props.agregar(row);
                        props.cambioBuscador();
                    }} >
                        <img className="icon-img m-0"src={icons.carreta} alt="Agregar"/>
                    </button>
                </div>
            );
        return (<div className="rojo">Existencias agotadas</div>);
    }
}

export function formatoPrecio(cell, row) {
    return <RenderCurrency value={row.precio} />
}

export function formatoExistencias(cell, row) {
    return <RenderNumber value={row.inventario_calculado} decimalScale={row.producto.a_granel ? 3: 2} />
}