import React from 'react'
import moment from 'moment'
import { icons } from "icons"
import DatePicker from "Utils/DatePicker"
import {BreakLine, tableOptions} from "Utils/tableOptions"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import { RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField"
import { RenderCurrency } from "Utils/renderField/renderReadField"
import { RenderDateTime } from "Utils/renderField/renderTableField"

export const TabProductos = (props) => {
    // State
    const { orden_compra, loader } = props;
    // Bind
    const { marcarEntregaOC } = props;

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    return (
        <div className="w-100 col-12 pt-3 pb-2">
            <button className="mb-4 btn btn-secondary" disabled={orden_compra.estado_inventario == 300 || orden_compra.estado == 20} onClick={() => marcarEntregaOC(orden_compra.id, orden_compra)}>Finalizar Entrega</button>
            <BootstrapTable
                data={loader ? [] : orden_compra.detalles}
                hover
                options={options}
            >
                <TableHeaderColumn
                    width="0%"
                    dataField="id"
                    isKey
                >
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nombre"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (
                            <span>
                                <b>{row.producto }</b>
                                <div>{row.fraccion.producto.a_granel ? row.unidad_de_medida.nombre: row.fraccion.nombre}</div>
                            </span>
                        )
                    }}
                >
                    Producto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="precio"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderMoneda monto={row.es_vale ? (cell * -1) : cell} />)
                    }}
                >
                    Precio
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderNumero monto={cell} />)
                    }}
                >
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad_recibido"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderNumero monto={cell ? cell : 0} />)
                    }}
                >
                    Cantidad recibido
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="sub_total"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderMoneda monto={cell} />)
                    }}
                >
                    Subtotal
                </TableHeaderColumn>
            </BootstrapTable>
            <div className="border-bottom w-100 pl-3 pr-3 mt-2 d-flex justify-content-between flex-row">
                <h5><b>Total</b></h5>
                <h5><b><RenderCurrency value={orden_compra.total} /></b></h5>
            </div>
        </div>
    )
}

export const TabAbonosCuentaPagar = (props) => {
    // State
    const { orden_compra, pagar, loader, revertir } = props;
    const cuenta_por_pagar = orden_compra.cuenta_por_pagar
    const abonos = cuenta_por_pagar ? cuenta_por_pagar.abonos : [];
    // Bind
    const { marcarPagoFinalizadoOC } = props;
    let total = 0;
    abonos.forEach(abono => {
        total += abono.total
    });
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    return (
        <div className="w-100 col-12 pt-3 pb-2">
            <div className="d-flex flex-column flex-lg-row align-items-center justify-justify-content-between mb-2 mb-lg-3">
                <div className="flex-1 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <button className="mb-3 mr-2 btn btn-primary" disabled={orden_compra.pago_finalizado || orden_compra.estado == 20} onClick={pagar}> Registrar pago </button>
                    <button className="mb-3 mr-2 btn btn-secondary" disabled={orden_compra.pago_finalizado || orden_compra.estado == 20} onClick={() => marcarPagoFinalizadoOC(orden_compra.id)}>Finalizar Pago</button>
                    {/*<button className="mb-3 btn btn-tertiary" disabled={((orden_compra.pago_finalizado == false) || orden_compra.saldo == 0)} onClick={() => revertir(orden_compra.id)}>Revertir (Finalizar Pago)</button>*/}
                </div>
                <div className="flex-1 d-flex align-items-center justify-content-end">
                    <h3 className="m-0 mr-2 mr-lg-4 font-weight-bold">Saldo</h3>
                    <h3 className="m-0 font-weight-bold text-danger"><RenderCurrency value={orden_compra.saldo}/></h3>
                </div>
            </div>
            <BootstrapTable
                data={loader ? [] : abonos}
                hover
                options={options}
            >
                <TableHeaderColumn
                    width="0%"
                    dataField="id"
                    isKey
                >
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="usuario__nombre"
                    dataAlign="center"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<span>{ row.usuario.first_name }</span>)
                    }}
                >
                    Usuario
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tipo_abono"
                    dataAlign="center"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell) => {
                        return (<div>
                            { cell == 1000 ? (
                                <span>CAJA</span>
                            ): (
                                <span>EXTERNO</span>
                            )}
                        </div>)
                    }}
                >
                    Origen de pago
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="forma_pago"
                    dataAlign="center"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell) => {
                        return (<div>
                            { cell == "EFE" && <span className="text-success">EFECTIVO</span>}
                            { cell == "TAR" && <span className="text-success">TARJETA</span>}
                            { cell == "CHE" && <span className="text-success">CHEQUE</span>}
                            { cell == "DEP" && <span className="text-success">DEPÓSITO</span>}
                            { cell == "TRA" && <span className="text-success">TRANSFERENCIA</span>}
                            { cell == "TRE" && <span className="text-success">TRANSFERENCIA ELECTRÓNICA</span>}
                        </div>)
                    }}
                >
                    Forma de pago
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="movimiento_caja_abono"
                    dataAlign="center"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (
                            <div>
                                {row.tipo_abono == 1000 ? (
                                    <div>
                                        {row.movimiento_caja_abono.concepto}{" "}
                                        {row.movimiento_caja_abono
                                            .num_documento && (
                                            <span>
                                                , No. Documento:
                                                {
                                                    row.movimiento_caja_abono
                                                        .num_documento
                                                }
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <span>-----</span>
                                )}
                            </div>
                        );
                    }}
                >
                    Concepto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="num_documento"
                    dataAlign="center"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell) => {
                        return (<div>
                            { cell ? (
                                <span>{ cell }</span>
                            ): (
                                <span>-----</span>
                            )}
                        </div>)
                    }}
                >
                    No. Documento
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nota_de_descuento"
                    dataAlign="center"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell) => {
                        return (<div>
                            { cell ? (
                                <span>{ cell }</span>
                            ): (
                                <span>-----</span>
                            )}
                        </div>)
                    }}
                >
                    Nota de crédito
                </TableHeaderColumn>
                 <TableHeaderColumn
                    dataField="descuento"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderMoneda monto={cell} />)
                    }}
                >
                    Monto de descuento
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderMoneda monto={cell} />)
                    }}
                >
                    Monto
                </TableHeaderColumn>
            </BootstrapTable>
            <div className="border-bottom w-100 pl-3 pr-3 mt-2 d-flex justify-content-between flex-row">
                <h5><b>Total</b></h5>
                <h5><b><RenderCurrency value={total} /></b></h5>
            </div>
        </div>
    )
}

export const ListadoRetiros = (props) => {
    // State
    const { loader, data, page, fecha_inicial, fecha_final } = props;
    // Bind
    const { listar, sortChange, changeFecha, setMovimientoCaja } = props;

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = page;
    options.sizePerPage = 5;
    options.onPageChange = listar;
    options.onSortChange = sortChange;
    return(
        <div className="w-100 border-radius-1 pt-3 pb-2 tabla-w-auto">
            <div className="row">
                <div className="col-12 col-lg-6 form-group">
                    <label htmlFor="fecha_inicial">Desde</label>
                    <DatePicker
                        value={fecha_inicial}
                        maxDate={new Date()}
                        onChange={(date) => {
                            changeFecha('Inicial', date);
                        }}
                    />
                </div>
                <div className="col-12 col-lg-6 form-group">
                    <label htmlFor="fecha_final">Hasta</label>
                    <DatePicker
                        value={fecha_final}
                        minDate={new Date(moment(fecha_inicial, "YYYY-MM-DD"))}
                        onChange={(date) => {
                            changeFecha('Final', date);
                        }}
                    />
                </div>
            </div>
            <BootstrapTable
                data={loader ? [] : data.results}
                remote pagination hover
                fetchInfo={{ dataTotalSize: data.count }}
                options={options}
            >
                <TableHeaderColumn
                    width="0%"
                    dataField="id"
                    isKey
                >
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="apertura_cierre_caja__caja_nombre"
                    dataAlign="center"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<span>{ row.apertura_cierre_caja.caja.nombre }</span>)
                    }}
                >
                    Caja
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="concepto"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<div>{cell}{row.num_documento && (<span>, No. Documento {row.num_documento}</span>)}</div>)
                    }}
                >
                    Concepto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderMoneda monto={cell} />)
                    }}
                >
                    Monto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="creado"
                    dataAlign="center"
                    dataSort
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell) => {
                        return (<RenderDateTime fecha={cell}/>)
                    }}
                >
                    Fecha
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<img
                            onClick={(e) => {
                                e.preventDefault();
                                setMovimientoCaja(row);
                            }}
                            className="action-img"
                            title="Seleccionar"
                            src={icons.check}></img>)
                    }}
                >
                    Acción
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    )
}


export const MovimientoCaja = (props) => {
    const { movimiento_caja } = props;
    const { setMovimientoCaja } = props;
    return(
        <div className="w-100 border-radius-1 pt-2 pb-2 tabla-w-auto">
            <div className="col-12 d-flex align-items-center justify-content-around">
                <div style={{width: "103px"}} className="mx-2 font-weight-bold">Caja</div>
                <div className="flex-1 mx-2 font-weight-bold">Concepto</div>
                <div style={{width: "90px"}} className="mx-2 font-weight-bold text-right">Monto</div>
                <div style={{width: "85px"}} className="mx-2 font-weight-bold">Fecha</div>
                <div style={{width: "60px"}} className="mx-2 font-weight-bold">Acción</div>
            </div>
            <div style={{backgroundColor: "#ECECEC", fontSize: "12px"}} className="col-12 d-flex align-items-center justify-content-around">
                <div style={{width: "103px"}} className="mx-2">{movimiento_caja.apertura_cierre_caja.caja.nombre}</div>
                <div className="flex-1 mx-2">{movimiento_caja.concepto} {movimiento_caja.num_documento && (<span>, No. Documento {movimiento_caja.num_documento}</span>)}</div>
                <div style={{width: "90px"}} className="mx-2 text-right"><RenderMoneda monto={movimiento_caja.total} /></div>
                <div style={{width: "85px"}} className="mx-2"><RenderDateTime fecha={movimiento_caja.creado}/></div>
                <div style={{width: "60px"}} className="mx-2 d-flex align-items-center justify-content-center">
                    <img
                        style={{maxWidth: "15px"}}
                        onClick={(e) => {
                            e.preventDefault();
                            setMovimientoCaja(null);
                        }}
                        className="action-img"
                        title="Quitar"
                        src={icons.anular_cotizacion}></img>
                </div>
            </div>
        </div>
    )
}
