import React, { Component } from "react";
import { Link } from "react-router-dom";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderPorcentaje,
    RenderMoneda,
} from "Utils/renderField/renderTableField";

export default class ListadoAdicionales extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.listar(this.props.page);
    }

    render() {
        //// state
        const { data, loader, page, search } = this.props;
        // bind
        const { eliminar, listar, sortChange, buscar } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        options.onSortChange = sortChange;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt">
                        <Link
                            className="btn btn-primary"
                            to="/configuracion/adicionales_nota_fel/crear"
                        >
                            <em className="fa fa-plus"></em> Agregar Adicional
                        </Link>
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Adicionales nota crédito FEL"}
                                buscar={buscar}
                                search={search}
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn dataField="nombre" dataSort>
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="tipo"
                                    dataFormat={(cell, row) => (
                                        <div>
                                            {cell == 10 ? (
                                                <span>Monto fijo</span>
                                            ) : (
                                                <span>
                                                    Porcentaje sobre el total de
                                                    la factura
                                                </span>
                                            )}
                                        </div>
                                    )}
                                >
                                    Tipo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="valor"
                                    dataAlign="right"
                                    dataFormat={(cell, row) => (
                                        <div>
                                            {row.tipo == 10 ? (
                                                <RenderMoneda monto={cell} />
                                            ) : (
                                                <span>
                                                    <RenderPorcentaje
                                                        monto={cell}
                                                        decimalScale={2}
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    )}
                                >
                                    Valor
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn dataField='fecha' dataSort dataFormat={(cell, row) => <span>{row.sucursal.nombre}</span>}>Sucursal</TableHeaderColumn> */}
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    dataAlign="center"
                                    width="20%"
                                    dataFormat={(cell, row) => {
                                        if (row.sucursal === null) return "";
                                        return activeFormatter({
                                            editar:
                                                "/configuracion/adicionales_nota_fel/editar",
                                            eliminar,
                                        })(cell, row);
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}
