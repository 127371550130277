import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Recibo from "Utils/ReciboVentaCredito";
import LoadMask from "Utils/LoadMask/LoadMask";
import PrintContainer from "Utils/Print/PrintContainer";
import { PrintComponent} from 'Utils/Print';


class ReciboCuentaCobrar extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getMovimientoVenta(this.props.match.params.id);
    }

    render() {
        const { movimiento_venta, loader, print_state } = this.props;

        return (
            <div className="grid-container">
                {print_state.flag && ( <PrintComponent/> )}
                <div className="p-2 d-flex flex-column align-items-center">

                    <h2 className="m-2"><strong>¿Deseas imprimir la nota?</strong></h2>
                    <div className="d-flex flex-column flex-sm-row m-2">
                        <Link className="btn btn-secondary m-2" to="/cuentas_por_cobrar/"><i className="fa fa-arrow-left" aria-hidden="true" > </i> Regresar</Link>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.print('reciboCuentaCobrar');
                                //printActions.print('reciboCuentaCobrar')
                            }}
                            className="btn btn-primary m-2"
                            type={'button'}
                        >
                            <em className="fa fa-print" /> Imprimir
                        </button>
                    </div>
                    <LoadMask loading={loader} blur light >
                        <div className="d-flex flex-row justify-content-center w100">

                            <PrintContainer name="reciboCuentaCobrar">
                                <Recibo
                                    sucursal={movimiento_venta ? movimiento_venta.sucursal : {}}
                                    movimiento={movimiento_venta ? movimiento_venta : {detalles: [], cuenta_cobrarpagar: {abonos:[]}}}
                                />
                            </PrintContainer>
                        </div>
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default ReciboCuentaCobrar;
