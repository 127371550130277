import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes/historial_precios";
import HistorialPrecios from './HistorialPrecios';

const ms2p = (state) => {
    return {
        ...state.reporte_historial_precios,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(HistorialPrecios);
