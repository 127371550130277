import React, { Component } from 'react';
import LoadMask from "Utils/LoadMask/LoadMask";;
import { Bar } from 'react-chartjs-2';

class GraficaVentas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: '100%'
        }
    }

    componentWillMount() {
        this.props.dataGraficaVentas()
    }

    render() {
        //  state
        const { data_grafica, loaderGrafica, loader, pais } = this.props;
        let now = new Date();
        let year = now.getFullYear();
        const moneda = pais=="GT" ? "Q ":"$ ";

        const data = {
            datasets: [
                {
                    label: `Año actual ${year}`,
                    data: data_grafica.ventas_actual ?  data_grafica.ventas_actual: [],
                    backgroundColor: 'rgba(255, 192, 0, 0.65)',
                    borderWidth: 1
                },
                {
                    label: `Año pasado ${year-1}`,
                    data: data_grafica.ventas_pasado ? data_grafica.ventas_pasado: [],
                    type: 'line',
                    borderColor: "#dd8589",
                    borderWidth: 3,
                    backgroundColor: "rgba(33, 33, 33, 0.075)"
                }
            ],
            labels: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre"
            ]
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                            <div style={{flex: "1"}}>
                                <h3><b>Ventas</b></h3>
                            </div>
                            <LoadMask loading={loaderGrafica} light blur>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <Bar
                                            height={500}
                                            data={data}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                scales: {
                                                    xAxes: [{ stacked: true, barThickness: 20 }],
                                                    yAxes: [
                                                        {
                                                            stacked: true,
                                                            ticks: {
                                                                beginAtZero: true,
                                                                callback: function (value, index, values) {
                                                                    value = parseFloat(value);
                                                                    let value_abs = Math.abs(value);
                                                                    if (value_abs >= 1000) {
                                                                        return moneda + value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                                    } else {
                                                                        return moneda + value.toFixed(2);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                elements: {
                                                    line: {
                                                        tension: 0.000001
                                                    }
                                                },
                                                tooltips: {
                                                    callbacks: {
                                                        label: function(tooltipItem, data) {
                                                            let value = tooltipItem.yLabel;

                                                            value = parseFloat(value);
                                                            let value_abs = Math.abs(value);
                                                            if (value_abs >= 1000) {
                                                                return data.datasets[tooltipItem.datasetIndex].label + `: ${moneda}` + value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                            } else {
                                                                return data.datasets[tooltipItem.datasetIndex].label + `: ${moneda}` + value.toFixed(2);
                                                            }
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GraficaVentas;
