import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import moment from "moment";
import DatePicker from "Utils/DatePicker";


class Resumen extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        fecha_inicial: PropTypes.string.isRequired,
        fecha_final: PropTypes.string.isRequired,
    };
    constructor(props){
        super (props);
    }
    componentWillMount() {
        this.props.leer();
    }

    render() {
        const { loader, data, fecha_inicial, fecha_final } = this.props;
        const ventas = data.ventas ? data.ventas : {};
        const ventas_online = data.ventas_online ? data.ventas_online : {};
        const total_ventas = ventas.total ? ventas.total : 0 + ventas_online.total ? ventas_online.total : 0;
        const gastos = data.compras ? data.compras : {};
        const utilidad = data.utilidad ? data.utilidad : 0;

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                            <div style={{flex: "1"}}>
                                <h3><b>Resumen</b></h3>
                            </div>
                            <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start" style={{flex: "3"}}>
                                <div>
                                    <label>Fecha Inicio</label>
                                    <DatePicker
                                        value={fecha_inicial}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            this.props.setFecha('Inicial', date);
                                        }}
                                    />
                                </div>
                                &nbsp;
                                &nbsp;
                                <div>
                                    <label>Fecha Fin</label>
                                    <DatePicker
                                        value={fecha_final}
                                        minDate={new Date(moment(fecha_inicial, "YYYY-MM-DD"))}
                                        onChange={(date) => {
                                            this.props.setFecha('Final', date);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <div className="d-flex justify-content-between flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                                <div style={{flex: 1}}>
                                    <div className="negro-dark2">
                                        <h3 className="balance-seccion m-0">Ventas</h3>
                                        <div className="balance-seccion d-flex justify-content-between">
                                            <div>Subtotal ventas en efectivo:</div>
                                            <RenderMoneda className="mr-2" monto={ventas.efectivo} />
                                        </div>
                                        <div className="balance-seccion d-flex justify-content-between">
                                            <div>Subtotal ventas con tarjeta:</div>
                                            <RenderMoneda className="mr-2" monto={ventas.tarjeta} />
                                        </div>
                                        <div className="balance-seccion d-flex justify-content-between">
                                            <div>Subtotal ventas con cheque:</div>
                                            <RenderMoneda className="mr-2" monto={ventas.cheque} />
                                        </div>
                                        <div className="balance-seccion d-flex justify-content-between">
                                            <div>Subtotal ventas con depósito:</div>
                                            <RenderMoneda className="mr-2" monto={ventas.deposito} />
                                        </div>
                                        <div className="balance-seccion d-flex justify-content-between">
                                            <div>Subtotal ventas online:</div>
                                            <RenderMoneda className="mr-2" monto={ventas_online.total} />
                                        </div>
                                        <div className="balance-seccion d-flex justify-content-between font-weight-bold">
                                            <div>Total de ventas:</div>
                                            <RenderMoneda className="mr-2" monto={total_ventas} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{flex: 1}}>
                                    <div className="negro-dark2">
                                        <h3 className="balance-seccion m-0">Gastos</h3>
                                        <div className="balance-seccion d-flex justify-content-between">
                                            <div>Gastos fijos</div>
                                            <RenderMoneda className="mr-2" monto={gastos.fijas} />
                                        </div>
                                        <div className="balance-seccion d-flex justify-content-between">
                                            <div>Gastos variables</div>
                                            <RenderMoneda className="mr-2" monto={gastos.variables} />
                                        </div>
                                        <div className="balance-seccion d-flex justify-content-between">
                                            <div>Compras</div>
                                            <RenderMoneda className="mr-2" monto={gastos.generales} />
                                        </div>
                                        <div className="balance-seccion d-flex justify-content-between"><span style={{color: "transparent"}}>-</span></div>
                                        <div className="balance-seccion d-flex justify-content-between"><span style={{color: "transparent"}}>-</span></div>
                                        <div className="balance-seccion d-flex justify-content-between font-weight-bold">
                                            <div>Total de gastos:</div>
                                            <RenderMoneda className="mr-2" monto={gastos.total} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="padding-15 text-right mr-4">
                                <h3>
                                    <b>
                                        BALANCE <RenderMoneda className={utilidad > 0 ? "verde" : "rojo"} monto={utilidad}/>
                                    </b>
                                </h3>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }

}

export default Resumen;
