import React from 'react'
import { icons } from "icons";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { renderField, renderCurrency } from "Utils/renderField/renderField";

const Form = props => {
    const { handleSubmit, close, tipo, tipoRetiroValue } = props;
    return (
        <form onSubmit={handleSubmit} className="col-sm-12 mt">
            <div >
                <div className="form-group">
                    <div className="padding-15 p-sm-0 pt-sm-1">
                        <div className="d-flex justify-content-around flex-column">
                            <div className="d-flex flex-column form-group">
                                <label htmlFor="total">Monto</label>
                                <Field name="total" component={renderCurrency} type="number" className="form-control"/>
                            </div>
                            <div className="d-flex flex-column form-group">
                                <label htmlFor="concepto">Concepto</label>
                                <Field name="concepto" component={renderField} type="text" className="form-control"/>
                            </div>
                            {
                                tipo === "egreso" && (
                                    <div className="d-flex flex-column form-group">
                                        <label htmlFor="tipo_retiro">Tipo</label>
                                        {/* <Field name="tipo" component={renderField} type="text" className="form-control"/> */}
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row">
                                                <div className="radio c-radio c-radio-nofont d-flex">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <Field name="tipo_retiro" component="input" type="radio" value="100" />
                                                        <span />
                                                        Para depósito
                                                    </label>
                                                </div>
                                                <div className="radio c-radio c-radio-nofont d-flex">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <Field name="tipo_retiro" component="input" type="radio" value="200" />
                                                        <span />
                                                        Gasto
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                (tipoRetiroValue == 100 && tipo === "egreso") && (
                                    <div className="d-flex flex-column form-group">
                                        <label htmlFor="num_documento">No. Boleta</label>
                                        <Field name="num_documento" component={renderField} type="text" className="form-control"/>
                                    </div>
                                )
                            }
                        </div>
                        <br/>
                        <div className="d-flex justify-content-center flex-row">
                            <button type="button" className="btn btn-tertiary m-1 align-self-center" onClick={close}><img src={icons.cancelar} alt=""/>Cancelar</button>
                            <button type="submit" className="btn btn-primary m-1 align-self-center"><img src={icons.save} alt=""/>Registrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

let MovimientoForm = reduxForm({
    // a unique name for the form
    form: 'MovimientoForm',
    initialValues: { tipo_retiro: "100" },
    validate: data => {
        return validate(data, {
            total: validators.exists()("Campo requerido"),
            concepto: validators.exists()("Campo requerido")
        })
    }
})(Form);

const selector = formValueSelector('MovimientoForm')
MovimientoForm = connect(
    state => {
        const tipoRetiroValue = selector(state, 'tipo_retiro');
        return {
            tipoRetiroValue
        }
    }
)(MovimientoForm)


export default MovimientoForm
