import { icons } from "icons";
import Recibo from "Utils/Recibo";
import Factura from "Utils/Factura";
import React, { Component } from "react";
import Modal from "react-responsive-modal";
import LoadMask from "Utils/LoadMask/LoadMask";
import TicketEntrega from "Utils/TicketEntrega";
import { ListadoVentas } from "./ListadoVentas";
import ValeForm from "../../../Ventas/Venta/Vale";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Cobro from "../../../Ventas/Venta/Cobro";
import { DetalleVenta } from "Utils/Venta/DetalleVenta";
import ReciboVentaCredito from "Utils/ReciboVentaCredito";
import { pago_total, pago_credito } from "utility/variables";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { PrintContainer, actions as printActions } from "Utils/Print";
import {
    DropDownMenu,
    DropDownDirection,
    ActionItem,
} from "react-dropdown-advanced";
import { PrintComponent } from "Utils/Print";
import { buscar } from "Utils/CamposComunes";

export default class CajaVenta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_vale: false,
            dropdownItems: [
                new ActionItem("cobrar_credito", "Entrega productos al crédito / realizar cobros al crédito"),
                new ActionItem("recibir_devolucion", "Recibir devolución"),
            ],
        };

        this.closeModal = this.closeModal.bind(this);
    }

    componentWillMount() {
        this.props.listarVentas(this.props.page);
    }

    ingresarValeDescuento = () => {
        this.setState({ modal_vale: true });
    };

    closeModal(data) {
        if (data.codigo) {
            this.props.agregarVale(data.codigo);
        }
        this.setState({ modal_vale: false });
    }

    onClickOtrasAcciones = (item) => {
        // bind
        const { pushRouter, utiliza_fel } = this.props;

        if (item.key === "cobrar_credito") {
            pushRouter("/cuentas_por_cobrar");
        } else if (item.key === "recibir_devolucion") {
            pushRouter("/devolucion");
        }
    };

    render() {
        // State
        const {
            ver_buscador,
            ver_cobro,
            page,
            loader,
            search,
            data,
            pais,
            movimiento,
            tipo_punto_venta,
            print_state,
            estado_form_cliente,
            retencion_iva,
            exencion_iva,
        } = this.props;
        // State ticket
        const {
            notas_cliente,
            telefono_cliente,
            direccion_cliente,
        } = this.props;
        const { dropdownItems } = this.state;
        // State venta
        const {
            loader_detalle,
            detalle_venta,
            total,
            factura_personalizada,
            formato_factura,
            utiliza_fel,
            venta_impresion,
        } = this.props;
        // State cobro
        const {
            tipo_pago,
            fecha_vencimiento,
            efectivo,
            tarjeta,
            nit_cliente,
            nombre_cliente,
            tipo_documento,
            redondeo,
            conf_venta_redondeo,
            conf_mostrar_referencia,
            conf_habilitar_campo_cliente,
            setEstadoFormCliente,
            cheque,
            deposito,
            nota,
            nota_credito,
            codigo_nota_credito,
            nota_credito_cargada,
            buscar_nombre,
        } = this.props;
        // Bind
        const {
            listarVentas,
            buscarVenta,
            cambioBuscador,
            cargarProductosVenta,
            quitarDetalle,
            cancelarVenta,
            pushRouter,
            eliminarVenta,
        } = this.props;
        // Bind cobro
        const {
            cambioCobro,
            setTipoPago,
            setFechaVencimientoCredito,
            setEfectivo,
            setTarjeta,
            leerCliente,
            changeDatosClientes,
            setRedondeo,
            finalizarPagarVenta,
            setCheque,
            setDeposito,
            setRetencionIva,
            setExencionIva,
            setNota,
            setCodigoNotaCredito,
            consultarNotaCredito,
            limpiarNotaCredito,
        } = this.props;

        console.log("CajaVenta", this.props);
        return (
            <div className="form-group mt">
                {/* SECCION DEL FLUJO DE VENTA */}
                {movimiento.sucursal == undefined && (
                    <div className="slide-container row w-100">
                        <div
                            className={`col-md-12 col-lg-7 ${
                                ver_buscador ? "form-activo" : ""
                            } ${!ver_cobro ? "slide" : ""}`}
                        >
                            {!ver_cobro && (
                                <ListadoVentas
                                    titulo={"Listado de clientes"}
                                    buscar={buscar({
                                        search: search,
                                        buscar: buscarVenta,
                                        agregarPrimer: () => {},
                                    })}
                                    data={data}
                                    loader={loader}
                                    page={page}
                                    listar={listarVentas}
                                    cambioBuscador={cambioBuscador}
                                    agregar={cargarProductosVenta}
                                    pushRouter={pushRouter}
                                    eliminarVenta={eliminarVenta}
                                />
                            )}

                            {/* CAMBIO MOVIL DE VISTAS */}
                            {!ver_cobro && (
                                <div className="d-lg-none d-xl-none grid-container align-items-center mt0 padding-10 d-flex justify-content-around flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                    <button
                                        className="btn btn-tertiary"
                                        type="button"
                                        onClick={() => {
                                            cambioBuscador();
                                        }}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            )}
                            {/* FIN CAMBIO MOVIL DE VISTAS*/}

                            {/* COBRO */}
                            {ver_cobro && (
                                <Cobro
                                    // state
                                    {...this.props}
                                    utiliza_fel={utiliza_fel}
                                    tipo_punto_venta={tipo_punto_venta}
                                    total={total}
                                    movimiento={movimiento}
                                    factura_personalizada={
                                        factura_personalizada
                                    }
                                    conf_ventas_al_credito={this.props.conf_ventas_al_credito}
                                    tipo_pago={tipo_pago}
                                    fecha_vencimiento={fecha_vencimiento}
                                    efectivo={efectivo}
                                    tarjeta={tarjeta}
                                    cheque={cheque}
                                    deposito={deposito}
                                    retencion_iva={retencion_iva}
                                    exencion_iva={exencion_iva}
                                    nota={nota}
                                    nit_cliente={nit_cliente}
                                    nombre_cliente={nombre_cliente}
                                    tipo_documento={tipo_documento}
                                    loader={loader_detalle}
                                    pais={pais}
                                    redondeo={redondeo}
                                    conf_venta_redondeo={conf_venta_redondeo}
                                    conf_mostrar_referencia={conf_mostrar_referencia}
                                    conf_habilitar_campo_cliente={conf_habilitar_campo_cliente}
                                    buscar_nombre={buscar_nombre}
                                    setEstadoFormCliente={setEstadoFormCliente}
                                    nota_credito={nota_credito}
                                    codigo_nota_credito={codigo_nota_credito}
                                    nota_credito_cargada={nota_credito_cargada}
                                    estado_form_cliente={estado_form_cliente}
                                    // bind
                                    setTipoPago={setTipoPago}
                                    setEfectivo={setEfectivo}
                                    setTarjeta={setTarjeta}
                                    setCheque={setCheque}
                                    setDeposito={setDeposito}
                                    setRetencionIva={setRetencionIva}
                                    setExencionIva={setExencionIva}
                                    setNota={setNota}
                                    leerCliente={leerCliente}
                                    limpiarDatosCliente={() => {}}
                                    changeDatosClientes={changeDatosClientes}
                                    setFechaVencimientoCredito={
                                        setFechaVencimientoCredito
                                    }
                                    setRedondeo={setRedondeo}
                                    cambioCobro={cambioCobro}
                                    ingresarValeDescuento={
                                        this.ingresarValeDescuento
                                    }
                                    pagar={finalizarPagarVenta}
                                    print_state={print_state}
                                    print={print}
                                    setCodigoNotaCredito={setCodigoNotaCredito}
                                    consultarNotaCredito={consultarNotaCredito}
                                    limpiarNotaCredito={limpiarNotaCredito}
                                />
                            )}
                            {/* FIN COBRO */}
                        </div>

                        <div
                            className={`col-md-12 col-lg-5 ${
                                !ver_buscador ? "form-activo" : ""
                            } ${!ver_cobro ? "form-trasero" : "venta-mt-4"}`}
                        >
                            <div className="w-100">
                                <LoadMask
                                    loading={loader_detalle}
                                    light
                                    blur
                                    radius
                                >
                                    <DetalleVenta
                                        texto_sin_detalles={
                                            "Seleccione una venta para agregar sus detalles"
                                        }
                                        mostrar_alerta_inventario={true}
                                        seleccionados={detalle_venta}
                                        desplegarItem={() => {}}
                                        quitar={quitarDetalle}
                                        sumar={() => {}}
                                        restar={() => {}}
                                        ingresoCantidad={() => {}}
                                        venta_permitir_descuento={false}
                                        pais={pais}
                                        cambiarDescuento={() => {}}
                                        cambiarPrecio={() => {}}
                                    />

                                    {/* SECCION TOTAL Y ACCIONES */}
                                    <div className="grid-container align-items-center mt0 padding-5 d-flex justify-content-around border-radius-top-squa border-radius-buttom-squa bg-blanco flex-column">
                                        {!ver_cobro && (
                                            <div className="padding-10 p-lg-0 d-flex flex-column flex-sm-row display-flex-width-100 justify-content-between d-lg-none d-xl-none">
                                                <div className="text-center flex1 mb-2">
                                                    <button
                                                        className="btn btn-secondary mb-1 fnt-sm-10"
                                                        type="button"
                                                        onClick={() => {
                                                            cambioBuscador();
                                                        }}
                                                    >
                                                        <em className="fa fa-plus fa-lg mr"></em>
                                                        Seleccionar Venta
                                                    </button>
                                                </div>
                                                <div className="text-center flex1 mb-2 d-flex align-items-center justify-content-center">
                                                    <button
                                                        className="btn btn-outline-tertiary fnt-sm-10 d-flex align-items-center justify-content-center mb-3"
                                                        style={{
                                                            position:
                                                                "relative",
                                                        }}
                                                    >
                                                        <span className="mr">
                                                            Otras acciones
                                                        </span>
                                                        <em className="ml-2 fa fa-caret-down"></em>
                                                        <DropDownMenu
                                                            items={
                                                                dropdownItems
                                                            }
                                                            onClick={
                                                                this
                                                                    .onClickOtrasAcciones
                                                            }
                                                            direction={
                                                                DropDownDirection.UpLeft
                                                            }
                                                            className="fnt-10"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        <div className="flex-row display-flex-width-100 padding-bottom-10 mt-2">
                                            <h3 className="flex1 ml">
                                                <b>Total</b>
                                            </h3>
                                            <h4 className="flex1 content-total mr-2 text-right">
                                                <b>
                                                    <RenderCurrency
                                                        value={total}
                                                    />
                                                </b>
                                            </h4>
                                        </div>

                                        <div className="w-100 d-flex flex-row">
                                            {!ver_cobro && (
                                                <div className="pl-0 pr-0 venta-btns mt0 container-botones-venta d-flex align-items-center justify-content-center">
                                                    <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                        <button
                                                            style={{
                                                                minHeight:
                                                                    "67px",
                                                                width: "72px",
                                                            }}
                                                            className="rounded white-space-normal btn btn-primary d-flex flex-column align-items-center justify-content-center"
                                                            type="submit"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                cambioCobro();
                                                                if (
                                                                    nit_cliente
                                                                ) {
                                                                    leerCliente(
                                                                        nit_cliente
                                                                    );
                                                                }
                                                                // this.openModalCliente();
                                                            }}
                                                        >
                                                            <img
                                                                className="m-0 mb-1"
                                                                src={
                                                                    icons.pagar
                                                                }
                                                            />
                                                            Pagar
                                                        </button>
                                                    </div>

                                                    {/* ######################################################## */}
                                                    {/* TODO: EN ANALISIS PARA APLICAR VALES DE DESCUENTOS EN FEL */}
                                                    {!utiliza_fel && (
                                                        <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                            <button
                                                                style={{
                                                                    minHeight:
                                                                        "67px",
                                                                    width:
                                                                        "72px",
                                                                }}
                                                                className="rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                                type="submit"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    this.ingresarValeDescuento();
                                                                }}
                                                            >
                                                                <img
                                                                    className="m-0 mb-1"
                                                                    src={
                                                                        icons.canje_devolucion_azul
                                                                    }
                                                                />
                                                                Canjear
                                                                devolución
                                                            </button>
                                                        </div>
                                                    )}
                                                    {/* ######################################################## */}
                                                    <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                        <button
                                                            style={{
                                                                minHeight:
                                                                    "67px",
                                                                width: "72px",
                                                            }}
                                                            className="rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                            type="submit"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                cancelarVenta();
                                                            }}
                                                        >
                                                            <img
                                                                className="m-0 mb-1"
                                                                src={
                                                                    icons.anular_cotizacion
                                                                }
                                                            />
                                                            Cancelar
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                            {/* region AGREGAR VALE */}
                                            <Modal
                                                center
                                                showCloseIcon={false}
                                                open={this.state.modal_vale}
                                                onClose={this.closeModal}
                                                classNames={{
                                                    modal: "modal-sm",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        padding: "1.2rem",
                                                        maxWidth: "400px",
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="d-none d-lg-block col-12 grid-titulo">
                                                            Ingrese el código
                                                            del vale
                                                        </div>
                                                        <div className="d-lg-none col-12 grid-titulo">
                                                            Vale de descuento
                                                        </div>
                                                        <ValeForm
                                                            styles={{
                                                                modal: {
                                                                    minWidth:
                                                                        "100%",
                                                                },
                                                            }}
                                                            onSubmit={
                                                                this.closeModal
                                                            }
                                                            close={
                                                                this.closeModal
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Modal>
                                            {/* endregion AGREGAR VALE */}
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                )}
                {/* FIN SECCION DEL FLUJO DE VENTA */}

                {/* SECCION DE IMPRESION */}
                {print_state.flag && <PrintComponent />}
                {movimiento.sucursal !== undefined && (
                    <div
                        className={`slide-container row w-100 d-flex flex-column flex-lg-row justify-content-center`}
                    >
                        <div className="d-lg-none">
                            <Tabs className="content-tabs">
                                <TabList className="tab_list d-flex flex-row justify-content-between">
                                    <Tab
                                        className="content-tab-naranja flex1 text-center"
                                        selectedClassName="content-tab-naranja-active"
                                    >
                                        <h5>
                                            {factura_personalizada ||
                                            utiliza_fel
                                                ? "Factura"
                                                : "Recibo"}
                                        </h5>
                                    </Tab>
                                    <Tab
                                        className="content-tab-naranja flex1 text-center"
                                        selectedClassName="content-tab-naranja-active"
                                    >
                                        <h5>Despacho</h5>
                                    </Tab>
                                </TabList>

                                <TabPanel className="pt-1 pb-1">
                                    <div
                                        className={`col-12 col-lg-4 container-vista-previa ml-0 ml-l-2 mt-lg-0`}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "transparent",
                                            }}
                                            className="w-100 p-2 container-vista-previa-head flex-column d-flex"
                                        >
                                            <div className="w-100 text-center">
                                                <div className="w-100 pl-0 pr-0 pl-md-4 pr-md-4 pl-lg-4 pr-lg-4 d-flex align-items-center justify-content-center">
                                                    {tipo_pago ==
                                                        pago_credito && (
                                                        <button
                                                            className="btn mr-2"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.print(
                                                                    "reciboVenta"
                                                                );
                                                            }}
                                                        >
                                                            <em className="fa fa-print" />{" "}
                                                            {factura_personalizada ||
                                                            utiliza_fel ? (
                                                                <span>
                                                                    Factura
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    Recibo
                                                                </span>
                                                            )}
                                                        </button>
                                                    )}
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (
                                                                tipo_pago ==
                                                                pago_total
                                                            )
                                                                this.props.print(
                                                                    "reciboVenta"
                                                                );
                                                            else
                                                                this.props.print(
                                                                    "reciboVentaCredito"
                                                                );
                                                        }}
                                                        style={{
                                                            width: "135px",
                                                        }}
                                                        className="btn mr-1 btn-secondary mt-lg"
                                                        type={"button"}
                                                    >
                                                        <em className="fa fa-print" />{" "}
                                                        Imprimir
                                                    </button>
                                                    <button
                                                        style={{
                                                            width: "135px",
                                                        }}
                                                        className="btn ml-1 btn-outline-secondary mt-lg"
                                                        type="button"
                                                        onClick={() => {
                                                            finalizarPagarVenta();
                                                        }}
                                                        disabled={loader}
                                                    >
                                                        <em className="fa fa-check" />{" "}
                                                        Finalizar venta
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-vista-previa-body">
                                            {!factura_personalizada ? (
                                                <div
                                                    className={`d-flex flex-row justify-content-center w100 ${
                                                        tipo_pago ==
                                                        pago_credito
                                                            ? "print-only"
                                                            : ""
                                                    }`}
                                                >
                                                    <PrintContainer name="reciboVenta">
                                                        {venta_impresion && (
                                                            <Recibo
                                                                sucursal={
                                                                    movimiento.sucursal
                                                                }
                                                                movimiento={
                                                                    movimiento
                                                                }
                                                                usuario={
                                                                    movimiento
                                                                        .usuario
                                                                        .first_name
                                                                        ? movimiento
                                                                              .usuario
                                                                              .first_name
                                                                        : ""
                                                                }
                                                                footerText={
                                                                    "TOTAL"
                                                                }
                                                                tipo={"Venta"}
                                                                utiliza_fel={
                                                                    utiliza_fel
                                                                }
                                                            />
                                                        )}
                                                    </PrintContainer>
                                                </div>
                                            ) : (
                                                <div
                                                    className={`d-flex flex-row justify-content-center w100 ${
                                                        tipo_pago ==
                                                        pago_credito
                                                            ? "print-only"
                                                            : ""
                                                    }`}
                                                >
                                                    <PrintContainer
                                                        name="reciboVenta"
                                                        className="impresion-venta"
                                                    >
                                                        {venta_impresion &&
                                                            venta_impresion.map(
                                                                (
                                                                    factura,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Factura
                                                                            className={`${
                                                                                index !=
                                                                                0
                                                                                    ? "salto-pagina"
                                                                                    : ""
                                                                            }`}
                                                                            key={
                                                                                index
                                                                            }
                                                                            datos_venta={
                                                                                factura
                                                                            }
                                                                            formato_factura={
                                                                                formato_factura
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                    </PrintContainer>
                                                </div>
                                            )}
                                            {tipo_pago == pago_credito && (
                                                <div className="d-flex flex-row justify-content-center w100">
                                                    <PrintContainer name="reciboVentaCredito">
                                                        {venta_impresion && (
                                                            <ReciboVentaCredito
                                                                sucursal={
                                                                    movimiento.sucursal
                                                                }
                                                                movimiento={
                                                                    movimiento
                                                                }
                                                                utiliza_fel={
                                                                    utiliza_fel
                                                                }
                                                            />
                                                        )}
                                                    </PrintContainer>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* {
                                            factura_personalizada && (
                                                <div className={`col-12 col-lg-7 container-vista-previa mr-0 mr-lg-2 ${tipo_pago == pago_credito ? "print-only": ""}`}>
                                                    <div style={{ backgroundColor: "transparent" }} className="w-100 p-2 container-vista-previa-head d-flex flex-column">
                                                        <div className="w-100 text-center">
                                                            <div className="w-100 pl-4 pr-4 d-flex align-items-center justify-content-center">
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.props.print('reciboVenta');
                                                                    }}
                                                                    style={{width: "135px"}}
                                                                    className="btn mr-1 btn-primary mt-lg"
                                                                    type={'button'}
                                                                >
                                                                    <em className="fa fa-print" /> Imprimir
                                                                </button>
                                                                <button style={{width: "135px"}} className="btn ml-1 btn-outline-primary mt-lg" type="button"
                                                                    onClick={() => { finalizarPagarVenta() }} disabled={loader}>
                                                                    <em className="fa fa-check" /> Finalizar venta
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        } */}
                                </TabPanel>
                                <TabPanel className="pt-1 pb-1">
                                    <div
                                        className={`col-12 col-lg-4 container-vista-previa ml-0 ml-l-2 mt-lg-0`}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "transparent",
                                            }}
                                            className="w-100 p-2 container-vista-previa-head d-flex flex-column"
                                        >
                                            <div className="w-100 text-center">
                                                <div className="w-100 pl-4 pr-4 d-flex align-items-center justify-content-center">
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.print(
                                                                "ticketEntrega"
                                                            );
                                                        }}
                                                        style={{
                                                            width: "135px",
                                                        }}
                                                        className="btn mr-1 btn-secondary mt-lg"
                                                        type={"button"}
                                                    >
                                                        <em className="fa fa-print" />{" "}
                                                        Imprimir
                                                    </button>
                                                    <button
                                                        style={{
                                                            width: "135px",
                                                        }}
                                                        className="btn ml-1 btn-outline-secondary mt-lg"
                                                        type="button"
                                                        onClick={() => {
                                                            finalizarPagarVenta();
                                                        }}
                                                        disabled={loader}
                                                    >
                                                        <em className="fa fa-check" />{" "}
                                                        Finalizar venta
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-vista-previa-body">
                                            <div className="d-flex flex-row justify-content-center w100">
                                                <PrintContainer name="ticketEntrega">
                                                    {venta_impresion && (
                                                        <TicketEntrega
                                                            sucursal={
                                                                movimiento.sucursal
                                                            }
                                                            movimiento={
                                                                movimiento
                                                            }
                                                            usuario={
                                                                movimiento
                                                                    .usuario
                                                                    .first_name
                                                                    ? movimiento
                                                                          .usuario
                                                                          .first_name
                                                                    : ""
                                                            }
                                                            utiliza_fel={
                                                                utiliza_fel
                                                            }
                                                            telefono_cliente={
                                                                telefono_cliente
                                                            }
                                                            direccion_cliente={
                                                                direccion_cliente
                                                            }
                                                            notas_cliente={
                                                                notas_cliente
                                                            }
                                                        />
                                                    )}
                                                </PrintContainer>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>

                        <div
                            className={`col-12 col-lg-4 container-vista-previa ${
                                !factura_personalizada
                                    ? "mr-0 mr-lg-2 d-lg-block"
                                    : tipo_pago == pago_credito
                                    ? "d-mr-0 mr-lg-2 d-lg-block"
                                    : "d-none"
                            }`}
                        >
                            <div
                                style={{ backgroundColor: "transparent" }}
                                className="w-100 p-2 container-vista-previa-head d-flex flex-column"
                            >
                                <div className="w-100 text-center">
                                    <h5>
                                        Vista previa{" "}
                                        {factura_personalizada || utiliza_fel
                                            ? "Factura"
                                            : "Recibo"}
                                    </h5>
                                    <div className="w-100 pl-4 pr-4 d-flex align-items-center justify-content-center">
                                        {tipo_pago == pago_credito && (
                                            <button
                                                className="btn mr-2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.print(
                                                        "reciboVenta"
                                                    );
                                                }}
                                            >
                                                <em className="fa fa-print" />{" "}
                                                {factura_personalizada ||
                                                utiliza_fel ? (
                                                    <span>Factura</span>
                                                ) : (
                                                    <span>Recibo</span>
                                                )}
                                            </button>
                                        )}
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (tipo_pago == pago_total)
                                                    this.props.print(
                                                        "reciboVenta"
                                                    );
                                                else
                                                    this.props.print(
                                                        "reciboVentaCredito"
                                                    );
                                            }}
                                            style={{ width: "135px" }}
                                            className="btn mr-1 btn-secondary mt-lg"
                                            type={"button"}
                                        >
                                            <em className="fa fa-print" />{" "}
                                            Imprimir
                                        </button>
                                        <button
                                            style={{ width: "135px" }}
                                            className="btn ml-1 btn-outline-secondary mt-lg"
                                            type="button"
                                            onClick={() => {
                                                finalizarPagarVenta();
                                            }}
                                            disabled={loader}
                                        >
                                            <em className="fa fa-check" />{" "}
                                            Finalizar venta
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="container-vista-previa-body">
                                {!factura_personalizada && (
                                    <div
                                        className={`d-flex flex-row justify-content-center w100 ${
                                            tipo_pago == pago_credito
                                                ? "print-only"
                                                : ""
                                        }`}
                                    >
                                        <PrintContainer name="reciboVenta">
                                            {venta_impresion && (
                                                <Recibo
                                                    sucursal={
                                                        movimiento.sucursal
                                                    }
                                                    movimiento={movimiento}
                                                    usuario={
                                                        movimiento.usuario
                                                            .first_name
                                                            ? movimiento.usuario
                                                                  .first_name
                                                            : ""
                                                    }
                                                    footerText={"TOTAL"}
                                                    tipo={"Venta"}
                                                    utiliza_fel={utiliza_fel}
                                                />
                                            )}{" "}
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        "transparent",
                                                }}
                                                className="w-100 p-2 container-vista-previa-head d-flex flex-column"
                                            ></div>
                                        </PrintContainer>
                                    </div>
                                )}
                                {tipo_pago == pago_credito && (
                                    <div className="d-flex flex-row justify-content-center w100">
                                        <PrintContainer name="reciboVentaCredito">
                                            {venta_impresion && (
                                                <ReciboVentaCredito
                                                    sucursal={
                                                        movimiento.sucursal
                                                    }
                                                    movimiento={movimiento}
                                                    utiliza_fel={utiliza_fel}
                                                />
                                            )}
                                        </PrintContainer>
                                    </div>
                                )}
                            </div>
                        </div>
                        {factura_personalizada && (
                            <div
                                className={`d-none d-lg-block col-12 col-lg-8 container-vista-previa mr-0  ${
                                    tipo_pago == pago_credito
                                        ? "print-only"
                                        : ""
                                }`}
                            >
                                <div
                                    style={{ backgroundColor: "transparent" }}
                                    className="w-100 p-2 container-vista-previa-head d-flex flex-column"
                                >
                                    <div className="w-100 text-center">
                                        <h5>Vista previa Factura</h5>
                                        <div className="w-100 pl-4 pr-4 d-flex align-items-center justify-content-center">
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.print(
                                                        "reciboVenta"
                                                    );
                                                }}
                                                style={{ width: "135px" }}
                                                className="btn mr-1 btn-secondary mt-lg"
                                                type={"button"}
                                            >
                                                <em className="fa fa-print" />{" "}
                                                Imprimir
                                            </button>
                                            <button
                                                style={{ width: "135px" }}
                                                className="btn ml-1 btn-outline-secondary mt-lg"
                                                type="button"
                                                onClick={() => {
                                                    finalizarPagarVenta();
                                                }}
                                                disabled={loader}
                                            >
                                                <em className="fa fa-check" />{" "}
                                                Finalizar venta
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 mt-10 container-vista-previa-body justify-content-center"
                                    style={{ zIndex: "0" }}
                                >
                                    <PrintContainer
                                        name="reciboVenta"
                                        className="impresion-venta"
                                    >
                                        {venta_impresion &&
                                            venta_impresion.map(
                                                (factura, index) => {
                                                    return (
                                                        <Factura
                                                            className={`${
                                                                index != 0
                                                                    ? "salto-pagina"
                                                                    : ""
                                                            }`}
                                                            key={index}
                                                            datos_venta={
                                                                factura
                                                            }
                                                            formato_factura={
                                                                formato_factura
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                    </PrintContainer>
                                </div>
                            </div>
                        )}
                        <div
                            className={`d-none d-lg-block col-12 col-lg-4 container-vista-previa ml-0 ml-l-2 mt-4 mt-lg-0`}
                        >
                            <div
                                style={{ backgroundColor: "transparent" }}
                                className="w-100 p-2 container-vista-previa-head d-flex flex-column"
                            >
                                <div className="w-100 text-center">
                                    <h5>Vista previa despacho</h5>
                                    <div className="w-100 pl-4 pr-4 d-flex align-items-center justify-content-center">
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.print(
                                                    "ticketEntrega"
                                                );
                                            }}
                                            style={{ width: "135px" }}
                                            className="btn mr-1 btn-secondary mt-lg"
                                            type={"button"}
                                        >
                                            <em className="fa fa-print" />{" "}
                                            Imprimir
                                        </button>
                                        <button
                                            style={{ width: "135px" }}
                                            className="btn ml-1 btn-outline-secondary mt-lg"
                                            type="button"
                                            onClick={() => {
                                                finalizarPagarVenta();
                                            }}
                                            disabled={loader}
                                        >
                                            <em className="fa fa-check" />{" "}
                                            Finalizar venta
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="container-vista-previa-body">
                                <div className="d-flex flex-row justify-content-center w100">
                                    <PrintContainer name="ticketEntrega">
                                        {venta_impresion && (
                                            <TicketEntrega
                                                sucursal={movimiento.sucursal}
                                                movimiento={movimiento}
                                                usuario={
                                                    movimiento.usuario
                                                        .first_name
                                                        ? movimiento.usuario
                                                              .first_name
                                                        : ""
                                                }
                                                utiliza_fel={utiliza_fel}
                                                telefono_cliente={
                                                    telefono_cliente
                                                }
                                                direccion_cliente={
                                                    direccion_cliente
                                                }
                                                notas_cliente={notas_cliente}
                                            />
                                        )}
                                    </PrintContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* FIN SECCION DE IMPRESION */}
            </div>
        );
    }
}
