import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { ToastStore } from "react-toasts";

const endpoint = "gastos/reporte_gastos";
const endpoint_grafica = "gastos/grafica_reporte_gastos";
const endpoint_listado_impresion = "gastos/listado_impresion";

export const DATA = "REPORTE_GASTOS_DATA";
export const DATA_GRAFICA = "DATA_GRAFICA";
export const FECHA_INICIAL = "REPORTE_GASTOS_FECHA_INICIAL";
export const FECHA_FINAL = "REPORTE_GASTOS_FECHA_FINAL";
export const LOADER = "REPORTE_GASTOS_LOADER";
export const SORT = "REPORTE_GASTOS_SORT";
export const ID_USUARIO = "REPORTE_GASTOS_ID_USUARIO";
export const TIPO_GASTO = "REPORTE_GASTOS_TIPO_GASTO";
export const SET_GASTO = "REPORTE_GASTOS_SET_GASTO";
export const PAGINAS_IMPRESION = "REPORTE_GASTOS_PAGINAS_IMPRESION";
export const PAGE = "REPORTE_GASTOS_PAGE";
export const ESTADO_DESCARGA = "REPORTE_GASTOS_ESTADO_DESCARGA";

export const dataGraficaGastos = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let now = new Date();
    let year = now.getFullYear();

    api.get(endpoint_grafica, { anio_actual: year, anio_pasado: year - 1 })
        .then((response) => {
            if (response) dispatch(setDataGrafica(response));
        })
        .catch((error) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getReporteGastos = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const fecha_inicial = store.reporte_gastos.fecha_inicial;
    const fecha_final = store.reporte_gastos.fecha_final;
    const sort = store.reporte_gastos.sort;
    const id_usuario = store.reporte_gastos.id_usuario;
    const tipo = store.reporte_gastos.tipo_gasto;

    api.get(`${endpoint}`, {
        fecha_inicial,
        fecha_final,
        sort,
        id_usuario,
        tipo,
        page,
    })
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setData(resp));
                dispatch(getListaImpresion());
                dispatch(setPage(page));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const getListaImpresion = () => (dispatch, getStore) => {
    const store = getStore();
    const fecha_inicial = store.reporte_gastos.fecha_inicial;
    const fecha_final = store.reporte_gastos.fecha_final;
    const sort = store.reporte_gastos.sort;
    const id_usuario = store.reporte_gastos.id_usuario;
    const tipo = store.reporte_gastos.tipo_gasto;
    api.get(`${endpoint_listado_impresion}`, {
        fecha_inicial,
        fecha_final,
        sort,
        id_usuario,
        tipo,
    })
        .catch((err) => {
            console.log("ERROR LISTADO IMPRESION GASTOS: ", err);
        })
        .then((resp) => {
            if (resp) {
                dispatch(listaGastosImpresion(resp));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getGasto = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("gastos/resumen", { id: id })
        .catch((err) => {})
        .then((response) => {
            if (response) {
                dispatch(setGasto(response));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    dispatch(getReporteGastos());
};

export const idUsuarioChange = (id_usuario) => (dispatch) => {
    dispatch(setIdUsuario(id_usuario));
    dispatch(getReporteGastos());
};

export const tipoGastoChange = (tipo) => (dispatch) => {
    dispatch(setTipoGasto(tipo));
    dispatch(getReporteGastos());
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_gastos = store.reporte_gastos;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(reporte_gastos.fecha_final);
        if (fecha_final >= fecha_inicial) dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(reporte_gastos.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(getReporteGastos());
};

export const listaGastosImpresion = (data) => (dispatch) => {
    dispatch(setLoader(true));

    //  Variables para las paginas
    const cant_por_pagina = 15;
    let paginas = 0;
    let listado_pagina = [];
    const resultado = data.results;
    const total_datos = resultado.length;
    paginas = Math.ceil(total_datos / cant_por_pagina);

    //  Construccion por pagina
    for (let i = 0; i < paginas; i++) {
        const pagina_impresion = {
            no_pagina: i + 1,
            gastos: [],
        };
        listado_pagina.push(pagina_impresion);
    }

    //  Asignacion de gastos para cada pagina
    resultado.forEach((gasto, index) => {
        const no_pagina = Math.ceil((index + 1) / cant_por_pagina);
        listado_pagina[no_pagina - 1].gastos.push(gasto);
    });

    //  Se agrega en redux el listado
    dispatch(
        setPaginasImpresion({
            paginas,
            listado_pagina,
        })
    );
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        fecha_inicial,
        fecha_final,
        sort,
        id_usuario,
        tipo_gasto,
    } = store.reporte_gastos;

    let params = {
        fecha_inicial,
        fecha_final,
        sort,
        tipo: tipo_gasto,
    };
    if (id_usuario) {
        params.id_usuario = id_usuario;
    }

    dispatch(setEstadoDescarga(true));
    api.get(`gastos/descargar_excel_reporte`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const setDataGrafica = (data_grafica) => ({
    type: DATA_GRAFICA,
    data_grafica,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setSort = (sort) => ({
    type: SORT,
    sort,
});

export const setIdUsuario = (id_usuario) => ({
    type: ID_USUARIO,
    id_usuario,
});

export const setTipoGasto = (tipo_gasto) => ({
    type: TIPO_GASTO,
    tipo_gasto,
});

export const setGasto = (gasto) => ({
    type: SET_GASTO,
    gasto,
});

export const setPaginasImpresion = (paginas_impresion) => ({
    type: PAGINAS_IMPRESION,
    paginas_impresion,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const actions = {
    getReporteGastos,
    setFecha,
    sortChange,
    dataGraficaGastos,
    idUsuarioChange,
    tipoGastoChange,
    getGasto,
    listaGastosImpresion,
    descargarListado,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_GRAFICA]: (state, { data_grafica }) => {
        return {
            ...state,
            data_grafica,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SORT]: (state, { sort }) => {
        return {
            ...state,
            sort,
        };
    },
    [ID_USUARIO]: (state, { id_usuario }) => {
        return {
            ...state,
            id_usuario,
        };
    },
    [TIPO_GASTO]: (state, { tipo_gasto }) => {
        return {
            ...state,
            tipo_gasto,
        };
    },
    [SET_GASTO]: (state, { gasto }) => {
        return {
            ...state,
            gasto,
        };
    },
    [PAGINAS_IMPRESION]: (state, { paginas_impresion }) => {
        return {
            ...state,
            paginas_impresion,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    data_grafica: {},
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    loader: false,
    sort: "-creado",
    id_usuario: "",
    tipo_gasto: "",
    gasto: {},
    paginas_impresion: {
        paginas: 0,
        listado_pagina: [],
    },
    page: 1,
    estado_descarga: false,
};

export default handleActions(reducers, initialState);
