import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderCurrency } from "Utils/renderField/renderField";
import { icons } from "icons";

const Form = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex">
            <div className="form-group">
                <div className="padding-15 p-sm-0 pt-sm-1">
                    <div className="d-flex justify-content-around flex-column">
                        <div className="d-flex flex-column form-group">
                            <Field name="codigo" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    <br/>
                    <div className="d-flex justify-content-center flex-row">
                        <button type="submit" className="btn btn-primary m-1 align-self-center"><i className="fa fa-check" />&nbsp;Siguiente</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

const MovimientoForm = reduxForm({
    // a unique name for the form
    form: 'CodigoFacturaForm',
    validate: data => {
        return validate(data, {
            codigo: validators.exists()("Campo requerido"),
        })
    }
})(Form);

export default MovimientoForm
