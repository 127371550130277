import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/devolucion/devolucion";
import VistaPrevia from "./VistaPrevia";


const ms2p = (state) => {
    let empresa = "";
    try {
        empresa = state.usuario.me.empresa ? state.usuario.me.empresa : "";
    } catch(e) { }
    return {
        ...state.devolucion,
        empresa,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VistaPrevia);
