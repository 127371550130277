import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/bodega/bodega";
import ListadoProductos from "./ListadoProductos";

const ms2p = (state) => {
    return {
        ...state.bodega,
        usuario: state.usuario.me,
        config: state.configuracion.config,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoProductos);
