// MENU

const apertura_cierre = require("../../assets/img/menu/apertura_cierre.png");
const balance_general = require("../../assets/img/menu/balance.png");
const bitacora = require("../../assets/img/menu/bitacora.png");
const bodega = require("../../assets/img/menu/bodega.png");
const caja_activa = require("../../assets/img/menu/caja_activa.png");
const cajas = require("../../assets/img/menu/cajas.png");
const clientes = require("../../assets/img/menu/clientes.png");
const configuracion = require("../../assets/img/menu/configuracion.png");
const cuentas_por_cobrar = require("../../assets/img/menu/cuentas_por_cobrar.png");
const devoluciones = require("../../assets/img/menu/devoluciones.png");
const gastos = require("../../assets/img/menu/gastos.png");
const home = require("../../assets/img/menu/inicio.png");
const mas_vendido = require("../../assets/img/menu/mas_vendido.png");
const mejores_clientes = require("../../assets/img/menu/mejores_clientes.png");
const producto = require("../../assets/img/menu/productos.png");
const proveedores = require("../../assets/img/menu/proveedores.png");
const reportes = require("../../assets/img/menu/reportes.png");
const reporte_ventas = require("../../assets/img/menu/reporte_ventas.png");
const reporte_devoluciones = require("../../assets/img/menu/reporte_devoluciones.png");
const reporte_bodega = require("../../assets/img/menu/reporte_bodega.png");
const reporte_dinero_invertido = require("../../assets/img/menu/reporte_dinero_invertido.png");
const ganancia_bruta = require("../../assets/img/menu/ganancia_bruta.png");
const reporte_despachos = require("../../assets/img/menu/reporte_despachos.png");
const reporte_movimientos = require("../../assets/img/menu/reporte_movimientos.png");
const reporte_movimientos_general = require("../../assets/img/menu/reporte_movimientos_general.png");
const reporte_precios = require("../../assets/img/menu/reporte_precios.png");
const sucursales = require("../../assets/img/menu/sucursales.png");
const tienda = require("../../assets/img/menu/tienda.png");
const usuarios = require("../../assets/img/menu/usuarios.png");
const ventas = require("../../assets/img/menu/ventas.png");
const despachos = require("../../assets/img/menu/despachos.png");
const mostrador = require("../../assets/img/menu/mostrador.png");
const punto_venta_caja = require("../../assets/img/menu/punto_venta_caja.png");
const orden_compra = require("../../assets/img/menu/orden_compra.png");
const orden_online = require("../../assets/img/menu/orden-online.png");
const destino_salida = require("../../assets/img/menu/destino_salida.png");
const historial_baja_salida = require("../../assets/img/menu/historial_baja_salida.png");
const adicionales_nota_fel = require("../../assets/img/menu/adicionales_nota_fel.png");
const reporte_descuentos_recargos = require("../../assets/img/menu/reporte_descuentos_recargos.png");

const lista_cajas = require("../../assets/img/icons/lista_cajas.png");
const mreportes = require("../../assets/img/icons/mreportes.png");
const img_default = require("../../assets/img/icons/upload-foto.png");

// OTROS
const cancelar = require("../../assets/img/icons/x.png");
const save = require("../../assets/img/icons/save.png");
const moneda_1 = require("../../assets/img/icons/moneda-1.png");
const moneda_5 = require("../../assets/img/icons/moneda-5.png");
const moneda_10 = require("../../assets/img/icons/moneda-10.png");
const moneda_25 = require("../../assets/img/icons/moneda-25.png");
const moneda_50 = require("../../assets/img/icons/moneda-50.png");
const moneda_100 = require("../../assets/img/icons/moneda-100.png");
const billete_1 = require("../../assets/img/icons/billete-1.png");
const billete_5 = require("../../assets/img/icons/billete-5.png");
const billete_10 = require("../../assets/img/icons/billete-10.png");
const billete_20 = require("../../assets/img/icons/billete-20.png");
const billete_50 = require("../../assets/img/icons/billete-50.png");
const billete_100 = require("../../assets/img/icons/billete-100.png");
const billete_200 = require("../../assets/img/icons/billete-200.png");
const caja_nueva = require("../../assets/img/icons/caja-nueva.png");
const caja = require("../../assets/img/icons/caja.png");
const caja_inactivo = require("../../assets/img/icons/caja-inactivo.png");
const candado_a = require("../../assets/img/icons/candado-a.png");
const candado_c = require("../../assets/img/icons/candado-c.png");
const editar = require("../../assets/img/icons/editar.png");
const editar_blanco = require("../../assets/img/icons/editar-blanco.png");
const eliminar = require("../../assets/img/icons/eliminar.png");
const carreta = require("../../assets/img/icons/carreta.png");
const agregar = require("../../assets/img/icons/agregar.png");
const reajuste_bodega = require("../../assets/img/icons/reajuste_bodega.png");
const baja_bodega = require("../../assets/img/icons/baja_bodega.png");
const ingreso_bodega = require("../../assets/img/icons/ingreso_bodega.png");
const salida_producto_bodega = require("../../assets/img/icons/salida_producto_bodega.png");
const img_placeholder = require("../../assets/img/icons/img_placeholder.png");
const mas = require("../../assets/img/icons/mas.png");
const menos = require("../../assets/img/icons/menos.png");
const efectivo = require("../../assets/img/icons/efectivo.png");
const pagar = require("../../assets/img/icons/pagar.png");
const tarjeta = require("../../assets/img/icons/tarjeta.png");
const total_monto = require("../../assets/img/icons/total_monto.png");
const cambio = require("../../assets/img/icons/cambio.png");
const gastos_fijos = require("../../assets/img/icons/costos_fijos.png");
const gastos_variables = require("../../assets/img/icons/costos_variables.png");
const usuario = require("../../assets/img/icons/clientes.png");
const perfil = require("../../assets/img/icons/perfil.png");
const logout = require("../../assets/img/icons/salir.png");
const imprimir = require("../../assets/img/icons/imprimir.png");
const recibo = require("../../assets/img/icons/recibo.png");
const recibo_caja = require("../../assets/img/icons/recibo_caja.png");
const fel = require("../../assets/img/icons/fel.png");
const anular = require("../../assets/img/icons/anular.png");
const anular_color = require("../../assets/img/icons/anular_color.png");
const efectivo_tarjeta = require("../../assets/img/icons/efectivo_tarjeta.png");
const efectivo_tarjeta_a = require("../../assets/img/icons/efectivo_tarjeta_a.png");
const dar_credito = require("../../assets/img/icons/dar_credito.png");
const dar_credito_a = require("../../assets/img/icons/dar_credito_a.png");
const fecha_vencimiento = require("../../assets/img/icons/fecha_vencimiento.png");
const fecha_vencimiento_a = require("../../assets/img/icons/fecha_vencimiento_a.png");
const fecha_vencimiento_gris = require("../../assets/img/icons/fecha_vencimiento_gris.png");
const pagos = require("../../assets/img/icons/pagos.png");
const pagos_blanco = require("../../assets/img/icons/pagos_blanco.png");
const pagos_naranja = require("../../assets/img/icons/pagos_naranja.png");
const ver = require("../../assets/img/icons/ver.png");
const producto_simple = require("../../assets/img/icons/producto_simple.png");
const fraccion_fardo = require("../../assets/img/icons/fraccion_fardo.png");
const fraccion_caja = require("../../assets/img/icons/fraccion_caja.png");
const fraccion_blister = require("../../assets/img/icons/fraccion_blister.png");
const fraccion_pastilla = require("../../assets/img/icons/fraccion_pastilla.png");
const producto_peso_saco = require("../../assets/img/icons/producto_peso_saco.png");
const producto_peso_libra = require("../../assets/img/icons/producto_peso_libra.png");
const producto_capacidad_barril = require("../../assets/img/icons/producto_capacidad_barril.png");
const producto_capacidad_galon = require("../../assets/img/icons/producto_capacidad_galon.png");
const producto_capacidad_litro = require("../../assets/img/icons/producto_capacidad_litro.png");
const producto_capacidad_mililitro = require("../../assets/img/icons/producto_capacidad_mililitro.png");
const flecha_derecha_naranja = require("../../assets/img/icons/flecha_derecha_naranja.png");
const flecha_derecha = require("../../assets/img/icons/flecha_derecha.png");
const fraccion_mayor = require("../../assets/img/icons/fraccion_mayor.png");
const fraccion_menor = require("../../assets/img/icons/fraccion_menor.png");
const devolucion = require("../../assets/img/icons/devolucion.png");
const timbrar_factura = require("../../assets/img/icons/timbrar_factura.png");
const icono_bienvenida = require("../../assets/img/Bienvenida.png");
const ayuda = require("../../assets/img/icons/ayuda.png");
const eliminar_datos = require("../../assets/img/icons/eliminar_datos.png");
const canje_devolucion = require("../../assets/img/icons/canje-devolucion.png");
const guardar_enviar_cotizacion = require("../../assets/img/icons/guardar_enviar_cotizacion.png");
const anular_cotizacion = require("../../assets/img/icons/anular_cotizacion.png");
const buscar = require("../../assets/img/icons/buscar.png");
const cargar = require("../../assets/img/icons/7-cargar.png");
const canje_devolucion_azul = require("../../assets/img/icons/Canje_devolucion_azul.png");
const codigo_barras = require("../../assets/img/icons/codigo_barras.png");
const abrir = require("../../assets/img/icons/abrir.png");
const guardar = require("../../assets/img/icons/guardar.png");
const check = require("../../assets/img/icons/check.png");
const reloj = require("../../assets/img/icons/reloj.png");
const accion_recepcion_oc = require("../../assets/img/icons/accion_recepcion.png");
const puntos = require("../../assets/img/icons/puntos.png");
const telefono = require("../../assets/img/icons/telefono.png");
const correo = require("../../assets/img/icons/correo.png");
const cheque = require("../../assets/img/icons/cheque.png");
const deposito = require("../../assets/img/icons/deposito.png");
const notas = require("../../assets/img/icons/notas.png");
const nota_credito = require("../../assets/img/icons/nota_credito.png");
const retencion_exencion_iva = require("../../assets/img/icons/retencion_exencion_iva.png");

//MONEDAS Y BILLETES MEXICO
const moneda_5_mx = require("../../assets/img/icons/moneda_5_mx.png");
const moneda_10_mx = require("../../assets/img/icons/moneda_10_mx.png");
const moneda_20_mx = require("../../assets/img/icons/moneda_20_mx.png");
const moneda_50_mx = require("../../assets/img/icons/moneda_50_mx.png");
const moneda_100_mx = require("../../assets/img/icons/moneda_100_mx.png");
const moneda_200_mx = require("../../assets/img/icons/moneda_200_mx.png");
const moneda_500_mx = require("../../assets/img/icons/moneda_500_mx.png");
const moneda_1000_mx = require("../../assets/img/icons/moneda_1000_mx.png");
const moneda_2000_mx = require("../../assets/img/icons/moneda_2000_mx.png");
const billete_20_mx = require("../../assets/img/icons/billete_20_mx.png");
const billete_50_mx = require("../../assets/img/icons/billete_50_mx.png");
const billete_100_mx = require("../../assets/img/icons/billete_100_mx.png");
const billete_200_mx = require("../../assets/img/icons/billete_200_mx.png");
const billete_500_mx = require("../../assets/img/icons/billete_500_mx.png");
const billete_1000_mx = require("../../assets/img/icons/billete_1000_mx.png");

////////////////////////////
// DASHBOARD
const dia = require("../../assets/img/icons/dia.png");
const mes = require("../../assets/img/icons/mes.png");
const semana = require("../../assets/img/icons/semana.png");
const dia_blanco = require("../../assets/img/icons/dia_blanco.png");
const mes_blanco = require("../../assets/img/icons/mes_blanco.png");
const semana_blanco = require("../../assets/img/icons/semana_blanco.png");
////////////////////////////

////////////////////////////
// LOGOS RECIBOS
const logo_lucerna = require("../../assets/img/logo-recibos/logo_lucerna.jpg");
////////////////////////////

////////////////////////////
// AYUDAS
////////////////////////////
// USUARIO
const usuario_crear_editar_p1 = require("../../assets/img/ayuda/usuario/crear_editar/paso1.jpg");
const usuario_crear_editar_p2 = require("../../assets/img/ayuda/usuario/crear_editar/paso2.jpg");
const usuario_crear_editar_p3 = require("../../assets/img/ayuda/usuario/crear_editar/paso3.jpg");
const usuario_crear_editar_p4 = require("../../assets/img/ayuda/usuario/crear_editar/paso4.jpg");
const usuario_crear_editar_p5 = require("../../assets/img/ayuda/usuario/crear_editar/paso5.jpg");
const usuario_accion_editar = require("../../assets/img/ayuda/usuario/acciones/editar.jpg");
const usuario_accion_eliminar = require("../../assets/img/ayuda/usuario/acciones/eliminar.jpg");
// BODEGA => INGRESO
const bodega_ingreso_p1 = require("../../assets/img/ayuda/bodega/ingreso/paso1.jpg");
const bodega_ingreso_p2 = require("../../assets/img/ayuda/bodega/ingreso/paso2.jpg");
const bodega_ingreso_p3 = require("../../assets/img/ayuda/bodega/ingreso/paso3.jpg");
const bodega_ingreso_p4 = require("../../assets/img/ayuda/bodega/ingreso/paso4.jpg");
const bodega_ingreso_p5 = require("../../assets/img/ayuda/bodega/ingreso/paso5.jpg");
const bodega_ingreso_p6 = require("../../assets/img/ayuda/bodega/ingreso/paso6.jpg");
const bodega_ingreso_p7 = require("../../assets/img/ayuda/bodega/ingreso/paso7.jpg");
const bodega_ingreso_p8 = require("../../assets/img/ayuda/bodega/ingreso/paso8.jpg");
const bodega_ingreso_p9 = require("../../assets/img/ayuda/bodega/ingreso/paso9.jpg");
const bodega_ingreso_p10 = require("../../assets/img/ayuda/bodega/ingreso/paso10.jpg");
const bodega_ingreso_p11 = require("../../assets/img/ayuda/bodega/ingreso/paso11.jpg");
const bodega_ingreso_p12 = require("../../assets/img/ayuda/bodega/ingreso/paso12.jpg");
// BODEGA => BAJA
const bodega_baja_p1 = require("../../assets/img/ayuda/bodega/baja/paso1.jpg");
const bodega_baja_p2 = require("../../assets/img/ayuda/bodega/baja/paso2.jpg");
const bodega_baja_p3 = require("../../assets/img/ayuda/bodega/baja/paso3.jpg");
const bodega_baja_p4 = require("../../assets/img/ayuda/bodega/baja/paso4.jpg");
const bodega_baja_p5 = require("../../assets/img/ayuda/bodega/baja/paso5.jpg");
const bodega_baja_p6 = require("../../assets/img/ayuda/bodega/baja/paso6.jpg");
const bodega_baja_p7 = require("../../assets/img/ayuda/bodega/baja/paso7.jpg");
const bodega_baja_p8 = require("../../assets/img/ayuda/bodega/baja/paso8.jpg");
// BODEGA => REAJUSTE
const bodega_reajuste_p1 = require("../../assets/img/ayuda/bodega/reajuste/paso1.jpg");
const bodega_reajuste_p2 = require("../../assets/img/ayuda/bodega/reajuste/paso2.jpg");
const bodega_reajuste_p3 = require("../../assets/img/ayuda/bodega/reajuste/paso3.jpg");
const bodega_reajuste_p4 = require("../../assets/img/ayuda/bodega/reajuste/paso4.jpg");
const bodega_reajuste_p5 = require("../../assets/img/ayuda/bodega/reajuste/paso5.jpg");
const bodega_reajuste_p6 = require("../../assets/img/ayuda/bodega/reajuste/paso6.jpg");
const bodega_reajuste_p7 = require("../../assets/img/ayuda/bodega/reajuste/paso7.jpg");
const bodega_reajuste_p8 = require("../../assets/img/ayuda/bodega/reajuste/paso8.jpg");

//  PUNTO DE VENTA => APERTURA CAJA
const apertura_caja_p1 = require("../../assets/img/ayuda/punto_venta/caja/apertura/paso1.jpg");
const apertura_caja_p2 = require("../../assets/img/ayuda/punto_venta/caja/apertura/paso2.jpg");
const apertura_caja_p3 = require("../../assets/img/ayuda/punto_venta/caja/apertura/paso3.jpg");
const apertura_caja_p4 = require("../../assets/img/ayuda/punto_venta/caja/apertura/paso4.jpg");
const apertura_caja_p5 = require("../../assets/img/ayuda/punto_venta/caja/apertura/paso5.jpg");
const apertura_caja_p6 = require("../../assets/img/ayuda/punto_venta/caja/apertura/paso6.jpg");
//  PUNTO DE VENTA => Cierre CAJA
const cierre_caja_p1 = require("../../assets/img/ayuda/punto_venta/caja/cierre/paso1.jpg");
const cierre_caja_p2 = require("../../assets/img/ayuda/punto_venta/caja/cierre/paso2.jpg");
const cierre_caja_p3 = require("../../assets/img/ayuda/punto_venta/caja/cierre/paso3.jpg");
const cierre_caja_p4 = require("../../assets/img/ayuda/punto_venta/caja/cierre/paso4.jpg");
const cierre_caja_p5 = require("../../assets/img/ayuda/punto_venta/caja/cierre/paso5.jpg");
//  PUNTO DE VENTA => AGREGAR EFECTIVO CAJA
const agregar_efectivo_p1 = require("../../assets/img/ayuda/punto_venta/caja/agregar_efectivo/paso1.jpg");
const agregar_efectivo_p2 = require("../../assets/img/ayuda/punto_venta/caja/agregar_efectivo/paso2.jpg");
const agregar_efectivo_p3 = require("../../assets/img/ayuda/punto_venta/caja/agregar_efectivo/paso3.jpg");
const agregar_efectivo_p4 = require("../../assets/img/ayuda/punto_venta/caja/agregar_efectivo/paso4.jpg");

//  PUNTO DE VENTA => REMOVER EFECTIVO CAJA
const remover_efectivo_p1 = require("../../assets/img/ayuda/punto_venta/caja/remover_efectivo/paso1.jpg");
const remover_efectivo_p2 = require("../../assets/img/ayuda/punto_venta/caja/remover_efectivo/paso2.jpg");
const remover_efectivo_p3 = require("../../assets/img/ayuda/punto_venta/caja/remover_efectivo/paso3.jpg");
const remover_efectivo_p4 = require("../../assets/img/ayuda/punto_venta/caja/remover_efectivo/paso4.jpg");
const remover_efectivo_p5 = require("../../assets/img/ayuda/punto_venta/caja/remover_efectivo/paso5.jpg");

//  PUNTO DE VENTA => ANULAR MOVIMIENTO DE CAJA
const anular_movimiento_p1 = require("../../assets/img/ayuda/punto_venta/caja/anular_movimiento/paso1.jpg");
const anular_movimiento_p2 = require("../../assets/img/ayuda/punto_venta/caja/anular_movimiento/paso2.jpg");
const anular_movimiento_p3 = require("../../assets/img/ayuda/punto_venta/caja/anular_movimiento/paso3.jpg");

//  PUNTO DE VENTA => CUENTA POR COBRAR
const cuenta_cobrar_p1 = require("../../assets/img/ayuda/punto_venta/cuenta_cobrar/paso1.jpg");
const cuenta_cobrar_p2 = require("../../assets/img/ayuda/punto_venta/cuenta_cobrar/paso2.jpg");
const cuenta_cobrar_p3 = require("../../assets/img/ayuda/punto_venta/cuenta_cobrar/paso3.jpg");
const cuenta_cobrar_p4 = require("../../assets/img/ayuda/punto_venta/cuenta_cobrar/paso4.jpg");
const cuenta_cobrar_p5 = require("../../assets/img/ayuda/punto_venta/cuenta_cobrar/paso5.jpg");
const cuenta_cobrar_p6 = require("../../assets/img/ayuda/punto_venta/cuenta_cobrar/paso6.jpg");
const cuenta_cobrar_p7 = require("../../assets/img/ayuda/punto_venta/cuenta_cobrar/paso7.jpg");
const cuenta_cobrar_p8 = require("../../assets/img/ayuda/punto_venta/cuenta_cobrar/paso8.jpg");
//  PUNTO DE VENTA => DEVOLUCION
const devolucion_p1 = require("../../assets/img/ayuda/punto_venta/devolucion/paso1.jpg");
const devolucion_p2 = require("../../assets/img/ayuda/punto_venta/devolucion/paso2.jpg");
const devolucion_p3 = require("../../assets/img/ayuda/punto_venta/devolucion/paso3.jpg");
const devolucion_p4 = require("../../assets/img/ayuda/punto_venta/devolucion/paso4.jpg");
const devolucion_p5 = require("../../assets/img/ayuda/punto_venta/devolucion/paso5.jpg");
const devolucion_p6 = require("../../assets/img/ayuda/punto_venta/devolucion/paso6.jpg");
const devolucion_p7 = require("../../assets/img/ayuda/punto_venta/devolucion/paso7.jpg");
const devolucion_p8 = require("../../assets/img/ayuda/punto_venta/devolucion/paso8.jpg");
const devolucion_p9 = require("../../assets/img/ayuda/punto_venta/devolucion/paso9.jpg");
const devolucion_p10 = require("../../assets/img/ayuda/punto_venta/devolucion/paso10.jpg");
const devolucion_p11 = require("../../assets/img/ayuda/punto_venta/devolucion/paso11.jpg");
//  PUNTO DE VENTA => ANULAR VENTA
const anular_venta_p1 = require("../../assets/img/ayuda/punto_venta/anular_venta/paso1.jpg");
const anular_venta_p2 = require("../../assets/img/ayuda/punto_venta/anular_venta/paso2.jpg");
const anular_venta_p3 = require("../../assets/img/ayuda/punto_venta/anular_venta/paso3.jpg");
const anular_venta_p4 = require("../../assets/img/ayuda/punto_venta/anular_venta/paso4.jpg");
const anular_venta_p5 = require("../../assets/img/ayuda/punto_venta/anular_venta/paso5.jpg");
//  PUNTO DE VENTA => VENTA
const venta_p1 = require("../../assets/img/ayuda/punto_venta/venta/paso1.jpg");
const venta_p2 = require("../../assets/img/ayuda/punto_venta/venta/paso2.jpg");
const venta_p3 = require("../../assets/img/ayuda/punto_venta/venta/paso3.jpg");
const venta_p4 = require("../../assets/img/ayuda/punto_venta/venta/paso4.jpg");
const venta_p5 = require("../../assets/img/ayuda/punto_venta/venta/paso5.jpg");
const venta_p6 = require("../../assets/img/ayuda/punto_venta/venta/paso6.jpg");
const venta_p7 = require("../../assets/img/ayuda/punto_venta/venta/paso7.jpg");
const venta_p8 = require("../../assets/img/ayuda/punto_venta/venta/paso8.jpg");
const venta_p9 = require("../../assets/img/ayuda/punto_venta/venta/paso9.jpg");
const venta_p10 = require("../../assets/img/ayuda/punto_venta/venta/paso10.jpg");
const venta_p11 = require("../../assets/img/ayuda/punto_venta/venta/paso11.jpg");
const venta_p12 = require("../../assets/img/ayuda/punto_venta/venta/paso12.jpg");
const venta_p13 = require("../../assets/img/ayuda/punto_venta/venta/paso13.jpg");
const venta_p14 = require("../../assets/img/ayuda/punto_venta/venta/paso14.jpg");
const venta_p15 = require("../../assets/img/ayuda/punto_venta/venta/paso15.jpg");
const venta_p16 = require("../../assets/img/ayuda/punto_venta/venta/paso16.jpg");
const venta_p17 = require("../../assets/img/ayuda/punto_venta/venta/paso17.jpg");
const venta_p18 = require("../../assets/img/ayuda/punto_venta/venta/paso18.jpg");
//  PUNTO DE VENTA => VENTA AL CREDITO
const venta_credito_p1 = require("../../assets/img/ayuda/punto_venta/venta_credito/paso1.jpg");
const venta_credito_p2 = require("../../assets/img/ayuda/punto_venta/venta_credito/paso2.jpg");
const venta_credito_p3 = require("../../assets/img/ayuda/punto_venta/venta_credito/paso3.jpg");
const venta_credito_p4 = require("../../assets/img/ayuda/punto_venta/venta_credito/paso4.jpg");
const venta_credito_p5 = require("../../assets/img/ayuda/punto_venta/venta_credito/paso5.jpg");
//  PUNTO DE VENTA => VALE DESCUENTO
const canje_vale_devolucion_p1 = require("../../assets/img/ayuda/punto_venta/canje_vale_devolucion/paso1.jpg");
const canje_vale_devolucion_p2 = require("../../assets/img/ayuda/punto_venta/canje_vale_devolucion/paso2.jpg");
const canje_vale_devolucion_p3 = require("../../assets/img/ayuda/punto_venta/canje_vale_devolucion/paso3.jpg");
const canje_vale_devolucion_p4 = require("../../assets/img/ayuda/punto_venta/canje_vale_devolucion/paso4.jpg");

export const icons = {
    cancelar,
    cerrar: cancelar,
    save,
    moneda_1,
    moneda_5,
    moneda_10,
    moneda_25,
    moneda_50,
    moneda_100,
    billete_1,
    billete_5,
    billete_10,
    billete_20,
    billete_50,
    billete_100,
    billete_200,
    caja_nueva,
    caja,
    lista_cajas,
    caja_activa,
    bitacora,
    caja_inactivo,
    candado_a,
    candado_c,
    editar,
    eliminar,
    carreta,
    agregar,
    reajuste_bodega,
    baja_bodega,
    ingreso_bodega,
    salida_producto_bodega,
    img_placeholder,
    mas,
    menos,
    efectivo,
    pagar,
    tarjeta,
    total_monto,
    cambio,
    gastos_fijos,
    gastos_variables,
    usuario,
    perfil,
    logout,
    dia,
    mes,
    semana,
    dia_blanco,
    mes_blanco,
    semana_blanco,
    home,
    sucursales,
    cajas,
    clientes,
    producto,
    ventas,
    gastos,
    mreportes,
    balance_general,
    configuracion,
    apertura_cierre,
    reporte_ventas,
    reporte_devoluciones,
    reporte_dinero_invertido,
    usuarios,
    img_default,
    proveedores,
    imprimir,
    bodega,
    anular,
    anular_color,
    devoluciones,
    efectivo_tarjeta,
    efectivo_tarjeta_a,
    dar_credito,
    dar_credito_a,
    fecha_vencimiento,
    fecha_vencimiento_a,
    fecha_vencimiento_gris,
    pagos,
    pagos_blanco,
    pagos_naranja,
    ver,
    reportes,
    cuentas_por_cobrar,
    mejores_clientes,
    mas_vendido,
    reporte_bodega,
    tienda,
    producto_simple,
    fraccion_fardo,
    fraccion_caja,
    fraccion_blister,
    fraccion_pastilla,
    producto_peso_saco,
    producto_peso_libra,
    producto_capacidad_barril,
    producto_capacidad_galon,
    producto_capacidad_litro,
    producto_capacidad_mililitro,
    flecha_derecha_naranja,
    flecha_derecha,
    fraccion_mayor,
    fraccion_menor,
    moneda_5_mx,
    moneda_10_mx,
    moneda_20_mx,
    moneda_50_mx,
    moneda_100_mx,
    moneda_200_mx,
    moneda_500_mx,
    moneda_1000_mx,
    moneda_2000_mx,
    billete_20_mx,
    billete_50_mx,
    billete_100_mx,
    billete_200_mx,
    billete_500_mx,
    billete_1000_mx,
    devolucion,
    timbrar_factura,
    logo_lucerna,
    icono_bienvenida,
    ayuda,
    usuario_crear_editar_p1,
    usuario_crear_editar_p2,
    usuario_crear_editar_p3,
    usuario_crear_editar_p4,
    usuario_crear_editar_p5,
    usuario_accion_editar,
    usuario_accion_eliminar,
    eliminar_datos,
    apertura_caja_p1,
    apertura_caja_p2,
    apertura_caja_p3,
    apertura_caja_p4,
    apertura_caja_p5,
    apertura_caja_p6,
    cierre_caja_p1,
    cierre_caja_p2,
    cierre_caja_p3,
    cierre_caja_p4,
    cierre_caja_p5,
    agregar_efectivo_p1,
    agregar_efectivo_p2,
    agregar_efectivo_p3,
    agregar_efectivo_p4,
    remover_efectivo_p1,
    remover_efectivo_p2,
    remover_efectivo_p3,
    remover_efectivo_p4,
    remover_efectivo_p5,
    anular_movimiento_p1,
    anular_movimiento_p2,
    anular_movimiento_p3,
    canje_devolucion,
    bodega_ingreso_p1,
    bodega_ingreso_p2,
    bodega_ingreso_p3,
    bodega_ingreso_p4,
    bodega_ingreso_p5,
    bodega_ingreso_p6,
    bodega_ingreso_p7,
    bodega_ingreso_p8,
    bodega_ingreso_p9,
    bodega_ingreso_p10,
    bodega_ingreso_p11,
    bodega_ingreso_p12,
    bodega_baja_p1,
    bodega_baja_p2,
    bodega_baja_p3,
    bodega_baja_p4,
    bodega_baja_p5,
    bodega_baja_p6,
    bodega_baja_p7,
    bodega_baja_p8,
    bodega_reajuste_p1,
    bodega_reajuste_p2,
    bodega_reajuste_p3,
    bodega_reajuste_p4,
    bodega_reajuste_p5,
    bodega_reajuste_p6,
    bodega_reajuste_p7,
    bodega_reajuste_p8,
    cuenta_cobrar_p1,
    cuenta_cobrar_p2,
    cuenta_cobrar_p3,
    cuenta_cobrar_p4,
    cuenta_cobrar_p5,
    cuenta_cobrar_p6,
    cuenta_cobrar_p7,
    cuenta_cobrar_p8,
    devolucion_p1,
    devolucion_p2,
    devolucion_p3,
    devolucion_p4,
    devolucion_p5,
    devolucion_p6,
    devolucion_p7,
    devolucion_p8,
    devolucion_p9,
    devolucion_p10,
    devolucion_p11,
    anular_venta_p1,
    anular_venta_p2,
    anular_venta_p3,
    anular_venta_p4,
    anular_venta_p5,
    venta_p1,
    venta_p2,
    venta_p3,
    venta_p4,
    venta_p5,
    venta_p6,
    venta_p7,
    venta_p8,
    venta_p9,
    venta_p10,
    venta_p11,
    venta_p12,
    venta_p13,
    venta_p14,
    venta_p15,
    venta_p16,
    venta_p17,
    venta_p18,
    venta_credito_p1,
    venta_credito_p2,
    venta_credito_p3,
    venta_credito_p4,
    venta_credito_p5,
    ganancia_bruta,
    despachos,
    canje_vale_devolucion_p1,
    canje_vale_devolucion_p2,
    canje_vale_devolucion_p3,
    canje_vale_devolucion_p4,
    reporte_despachos,
    reporte_movimientos,
    reporte_movimientos_general,
    reporte_precios,
    mostrador,
    punto_venta_caja,
    guardar_enviar_cotizacion,
    anular_cotizacion,
    buscar,
    cargar,
    canje_devolucion_azul,
    codigo_barras,
    abrir,
    guardar,
    check,
    reloj,
    orden_compra,
    accion_recepcion_oc,
    puntos,
    telefono,
    correo,
    orden_online,
    cheque,
    deposito,
    notas,
    editar_blanco,
    destino_salida,
    historial_baja_salida,
    nota_credito,
    recibo,
    recibo_caja,
    fel,
    adicionales_nota_fel,
    reporte_descuentos_recargos,
    retencion_exencion_iva,
};
