import React, { Component } from 'react';
import './tree_categoria.css'
import CheckboxTree from 'react-checkbox-tree';

class TreeCategoria extends Component {
    render() {
        //  State
        const { categorias, categorias_seleccionados, categorias_expanded } = this.props;
        // Bind
        const { asignarCategoria, setCategoriasExpanded } = this.props;
        return (
            <CheckboxTree
                id="TREE-CATEGORIA"
                nodes={categorias}
                checked={categorias_seleccionados}
                expanded={categorias_expanded}
                onCheck={checked => asignarCategoria(checked)}
                onExpand={expanded => setCategoriasExpanded(expanded)}
                onlyLeafCheckboxes="true"
                showExpandAll="true"
                icons={{
                    expandAll: <em className="fa fa-plus-square-o"/>,
                    collapseAll: <em className="fa fa-minus-square-o"/>,
                    expandClose: <em className="fa fa-plus-square-o"/>,
                    expandOpen: <em className="fa fa-minus-square-o"/>,
                    parentClose: <em className="fa fa-folder"/>,
                    parentOpen: <em className="fa fa-folder-open"/>,
                    leaf: <em className="fa fa-leaf"/>
                }}
            />
        );
    }
}

export default TreeCategoria;