import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/gastos/gastos";
import CrearGasto from './CrearGasto';
import _ from "lodash";


const ms2p = (state) => {
    const formulario = state.form.GastoCrear ? state.form.GastoCrear : {};
    const valores = formulario.values ? formulario.values : {};
    const tipo = valores.gasto_fijo;

    const sucursales = state.usuario.me.sucursales ? state.usuario.me.sucursales : [];
    let sucursal = state.usuario.sucursal ? parseInt(state.usuario.sucursal) : "empresa";
    if (sucursal !== "empresa"){
        sucursal = parseInt(sucursal);
    }
    if (!_.find(sucursales, {id: "empresa"}) && (state.usuario.me.es_global || state.usuario.me.es_propietario))
        sucursales.unshift({nombre: "Todas", id: "empresa"});

    return {
        ...state.gastos,
        tipo,
        sucursal,
        sucursales,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearGasto);
