import React from 'react';
import { api } from "api";
import { icons } from "icons";
import { Field, reduxForm } from 'redux-form';
import { renderField, renderSearchSelect, renderTextArea } from "Utils/renderField/renderField";

// VALIDACIONES CUSTOMS
const validate = (values, props) => {
    const errors = {}
    if (!values.info)
        errors.info = "Campo requerido"
    return errors;
}

const Form = (props) => {
    const { handleSubmit, close, loader } = props;

    return (
        <form onSubmit={handleSubmit} className="col-sm-12 pt-2">
            <div className="form-group">
                <div className="row">
                    <div className="col-12 form-group">
                        <label htmlFor="text">Escribe la información del envío</label>
                        <Field name="info" label="Escribe aquí" component={renderTextArea} type="text" className="form-control" autoFocus={true}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center flex-row">
                    <button type="button" className="btn btn-tertiary m-1 align-self-center" onClick={(e) => {
                        e.stopPropagation();
                        close({});
                    }}>Cancelar</button>
                    <button type="submit" className="btn btn-primary m-1 align-self-center" disabled={loader}>Confirmar</button>
                </div>
            </div>
        </form>
    )
}

const OrdenOnlineEntregaForm = reduxForm({
    // a unique name for the form
    form: 'OrdenOnlineEntregaForm',
    validate
})(Form)

export default OrdenOnlineEntregaForm;