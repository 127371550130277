import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { PrintContainer, PrintComponent } from "Utils/Print";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

class ImpresionProducto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoy: Date(),
        };
    }

    accionImprimir = () => {
        this.props.print("bodegaListadoProductos");
    };

    componentWillMount() {}

    componentDidMount() {
        this.props.listaProductosImpresion();
    }

    render() {
        //  state
        const {
            loader,
            listado_pagina,
            paginas,
            usuario,
            id_sucursal,
            sucursales,
            print_state,
        } = this.props;

        let oficina = _.find(sucursales, { id: parseInt(id_sucursal) });
        if (!oficina) {
            oficina = {};
        }

        return (
            <div className="row">
                {print_state.flag && <PrintComponent />}
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt1 mb1 text-center">
                        <Link
                            className="btn btn-secondary align-self-center m-1"
                            to="/bodega"
                        >
                            <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                            />{" "}
                            Regresar
                        </Link>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.accionImprimir(e);
                            }}
                            className="btn btn-primary mt-lg"
                            type={"button"}
                        >
                            <em className="fa fa-print" /> Imprimir
                        </button>
                    </div>
                    <LoadMask loading={loader} light blur radius>
                        <div className="w100">
                            <PrintContainer
                                name="bodegaListadoProductos"
                                className="print-space"
                            >
                                {listado_pagina.map((pagina, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="print-shadow hoja-carta"
                                            style={{ fontSize: "11px" }}
                                        >
                                            <div className="d-flex flex-row justify-content-between imp-pt-2 imp-pl-20 imp-pr-20">
                                                <div className="flex1 d-flex flex-column border-b-3">
                                                    <div>
                                                        <b>
                                                            Usuario:&nbsp;&nbsp;
                                                        </b>
                                                        <span>{usuario}</span>
                                                    </div>
                                                    <div>
                                                        <b>
                                                            Sucursal:&nbsp;&nbsp;
                                                        </b>
                                                        <span>
                                                            {oficina.nombre}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <b>
                                                            Fecha y hora:&nbsp;
                                                        </b>
                                                        <RenderDateTime
                                                            className="text-right"
                                                            value={
                                                                this.state.hoy
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {pagina.no_pagina === 1 && (
                                                    <div className="flex1 d-flex flex-column align-items-center justify-content-center border-b-3">
                                                        <h5 className="padding-10 margin-0 text-right w-100">
                                                            <b>
                                                                Reporte
                                                                inventario de
                                                                productos
                                                            </b>
                                                        </h5>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="d-flex flex-row justify-content-between imp-pt-2">
                                                <BootstrapTable
                                                    className="impresion-tabla"
                                                    data={
                                                        loader
                                                            ? []
                                                            : pagina.productos
                                                    }
                                                    remote
                                                    tableContainerClass="tabla-auto"
                                                    headerContainerClass="border-b-3"
                                                    bodyContainerClass="border-b-3"
                                                    bodyStyle={{
                                                        height: "auto",
                                                    }}
                                                >
                                                    <TableHeaderColumn
                                                        tdStyle={{
                                                            padding: "0.25rem",
                                                        }}
                                                        width="13%"
                                                        dataField="codigo_barras"
                                                        dataFormat={(
                                                            cell,
                                                            row
                                                        ) => {
                                                            return (
                                                                <div>
                                                                    {cell}
                                                                </div>
                                                            );
                                                        }}
                                                    >
                                                        Código
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        tdStyle={{
                                                            padding: "0.25rem",
                                                        }}
                                                        dataField="id"
                                                        isKey
                                                        dataFormat={(
                                                            cell,
                                                            row
                                                        ) => {
                                                            return (
                                                                <div>
                                                                    <b
                                                                        style={{
                                                                            whiteSpace:
                                                                                "normal",
                                                                        }}
                                                                    >
                                                                        {
                                                                            row
                                                                                .producto
                                                                                .nombre
                                                                        }
                                                                    </b>
                                                                    <div>
                                                                        {
                                                                            row.nombre
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    >
                                                        Producto
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        tdStyle={{
                                                            padding: "0.25rem",
                                                        }}
                                                        width="15%"
                                                        dataField="id"
                                                        dataFormat={(
                                                            cell,
                                                            row
                                                        ) => {
                                                            return (
                                                                <div>
                                                                    {row
                                                                        .producto
                                                                        .marca ? (
                                                                        <span>
                                                                            {
                                                                                row
                                                                                    .producto
                                                                                    .marca
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            -----
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            );
                                                        }}
                                                    >
                                                        Marca
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        tdStyle={{
                                                            padding: "0.25rem",
                                                        }}
                                                        width="12%"
                                                        dataField="inventario"
                                                        dataAlign="right"
                                                        dataFormat={(
                                                            cell,
                                                            row
                                                        ) => {
                                                            return (
                                                                <RenderNumber
                                                                    decimalScale={
                                                                        row
                                                                            .producto
                                                                            .a_granel
                                                                            ? 3
                                                                            : 2
                                                                    }
                                                                    value={cell}
                                                                />
                                                            );
                                                        }}
                                                    >
                                                        Inventario
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        tdStyle={{
                                                            padding: "0.25rem",
                                                        }}
                                                        width="12%"
                                                        dataField="precio"
                                                        dataAlign="right"
                                                        dataFormat={(
                                                            cell,
                                                            row
                                                        ) => {
                                                            return (
                                                                <RenderCurrency
                                                                    value={cell}
                                                                />
                                                            );
                                                        }}
                                                    >
                                                        Precio
                                                    </TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>

                                            <div className="no_pagina d-flex flex-row justify-content-center imp-pt-2">
                                                <span className="text-center">
                                                    Pag. {pagina.no_pagina} de{" "}
                                                    {paginas}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </PrintContainer>
                        </div>
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ImpresionProducto;
