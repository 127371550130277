import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from 'react-router-dom';
import { tableOptions } from "Utils/tableOptions";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";

class SucursalesListado extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        eliminar: PropTypes.func.isRequired,
        buscar: PropTypes.func.isRequired,
        sortChange: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
    }
    componentWillMount() {
        this.props.listar(this.props.page);
    }

    render() {
        // state
        const { data, loader } = this.props;

        // bind
        const { eliminar } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {/* <Link className="btn btn-primary" to="/sucursales/crear/">+ Agregar Sucursal</Link> */}
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar titulo={"Sucursales"} buscar={this.props.buscar} search={this.props.search} />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote pagination hover
                                fetchInfo={{ dataTotalSize: data.count }} options={options}>
                                <TableHeaderColumn dataField='nombre' dataSort >Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataField='direccion' dataSort >Dirección</TableHeaderColumn>
                                <TableHeaderColumn dataField='telefono' >Teléfono</TableHeaderColumn>
                                <TableHeaderColumn dataField='id' isKey dataAlign="center" width="20%"
                                    dataFormat={activeFormatter({ ver: '/sucursales/detalle', editar: '/sucursales/editar' })}>Acciones</TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}

export default SucursalesListado
