import React from "react";
import { api } from "api";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { renderSearchSelect, renderField } from "Utils/renderField/renderField";
import { ListadoAccion } from "Utils/ListadoAccion";
import { DetalleAccion } from "Utils/DetalleAccion";
import { icons } from "icons";
import LoadMask from "Utils/LoadMask/LoadMask";

import {
    formatoNombre,
    formatoExistencia,
    formatoAcciones,
    buscar,
    formatoProducto,
} from "Utils/CamposComunes";

import {
    formatoCantidadDespachada,
    formatoAccionDetalle,
} from "./camposDespacho";

const listaSucursales = [];

const Form = (props) => {
    const {
        loader_productos,
        data_productos,
        seleccionados,
        page_productos,
        ver_buscador,
        sucursal_origen,
        search_productos,
        loader,
    } = props;

    // bind
    const {
        handleSubmit,
        cambioBuscador,
        listarProductos,
        sortChangeProductos,
        agregar,
        buscarProductos,
        agregarPrimer,
        asignarCantidadDespachada,
        quitar,
    } = props;

    const llavesListado = [
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },
        {
            nombre: "inventario",
            sort: false,
            caption: "Existencia",
            format: formatoExistencia,
            align: "right",
            width: "100px",
        },
        {
            nombre: "acciones",
            sort: false,
            caption: "Agregar",
            format: formatoAcciones({
                agregar,
                cambio_buscador: cambioBuscador,
            }),
            align: "center",
            width: "90px",
        },
    ];
    const llavesDetalle = [
        { caption: "Producto", nombre: "producto", format: formatoProducto },
        {
            width: "150px",
            caption: "Cantidad",
            nombre: "cantidad_despachada",
            format: formatoCantidadDespachada({ asignarCantidadDespachada }),
        },
        // { caption: "Acciones", nombre: "quitar", format: formatoAccionDetalle(props), align: "center", width: "90px" },
    ];
    const getSucursales = (search) => {
        return api
            .get("sucursales", { search, exluir_esta_sucursal: true })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaSucursales, { id: item.id }))
                        listaSucursales.push(item);
                });
                return { options: listaSucursales };
            });
    };

    return (
        <form onSubmit={handleSubmit} className="row form">
            <LoadMask loading={loader} light blur radius>
                <div className="col-12 mb">
                    <div className="grid-container pt-2 pb-2 row">
                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                            <label>Sucursal origen</label>
                            {sucursal_origen && (
                                <input
                                    className={"form-control"}
                                    type="text"
                                    value={
                                        sucursal_origen.nombre
                                            ? sucursal_origen.nombre
                                            : ""
                                    }
                                    disabled
                                />
                            )}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-1 form-group">
                            <label htmlFor="sucursal_destino">
                                Sucursal destino:
                            </label>
                            <div
                                className="d-flex flex-column pr-0"
                                style={{ maxWidth: "650px" }}
                            >
                                <Field
                                    name="sucursal_destino"
                                    component={renderSearchSelect}
                                    loadOptions={getSucursales}
                                    options={listaSucursales}
                                    labelKey="nombre"
                                    valueKey="id"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-1 form-group">
                            <label htmlFor="responsable_transporte">
                                Responsable de transporte:
                            </label>
                            <div
                                className="d-flex flex-column pr-0"
                                style={{ maxWidth: "650px" }}
                            >
                                <Field
                                    name="responsable_transporte"
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide-container row" style={{ margin: "0" }}>
                    <div
                        className={`col-lg-6 col-md-12 pr-md-0 ${
                            ver_buscador ? "form-activo" : ""
                        } slide `}
                    >
                        <ListadoAccion
                            buscar={buscar({
                                buscar: buscarProductos,
                                search: search_productos,
                                agregarPrimer: agregarPrimer,
                            })}
                            loader={loader_productos}
                            llaves={llavesListado}
                            data={data_productos}
                            listar={listarProductos}
                            sortChange={sortChangeProductos}
                            titulo={
                                <div className="d-flex flex-column flex-lg-row">
                                    <span
                                        style={{
                                            fontSize: "15px",
                                            marginBottom: "0.5rem",
                                        }}
                                        className="align-self-end"
                                    >
                                        Listado de Productos
                                    </span>
                                </div>
                            }
                            page={page_productos}
                        />
                        <div className="d-lg-none d-xl-none grid-container align-items-center mt0 padding-10 d-flex justify-content-around flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                            <button
                                className="btn btn-tertiary"
                                type="button"
                                onClick={() => {
                                    cambioBuscador();
                                }}
                            >
                                <img src={icons.cancelar} alt="" /> Cancelar
                            </button>
                        </div>
                    </div>
                    <div
                        className={`col-lg-6 col-md-12 ${
                            !ver_buscador ? "form-activo" : ""
                        } form-trasero`}
                    >
                        <DetalleAccion
                            data={seleccionados}
                            titulo={"Lista de Productos a despachar"}
                            llaves={llavesDetalle}
                            loader={loader_productos}
                            agregar={cambioBuscador}
                            quitar={quitar}
                        />
                        <div
                            className="backgr-naranja-degradado"
                            style={{
                                padding: "2px",
                                borderLeft: "2px solid #FFFF",
                                borderRight: "2px solid #FFFF",
                            }}
                        >
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{
                                    backgroundColor: "#FFF",
                                    marginTop: "15px",
                                    padding: "15px",
                                }}
                            >
                                <Link
                                    className="btn btn-tertiary mr-1"
                                    to={"/despachos"}
                                >
                                    <img src={icons.cancelar} alt="" />
                                    Cancelar
                                </Link>

                                <button
                                    className="btn btn-primary ml-1"
                                    type="submit"
                                    disabled={seleccionados.length <= 0}
                                >
                                    <i className="fa fa-check mr" />
                                    Finalizar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadMask>
        </form>
    );
};

const CrearDespacho = reduxForm({
    // a unique name for the form
    form: "CrearDespacho",
    validate: (data) => {
        return validate(data, {
            sucursal_destino: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default CrearDespacho;
