import _ from "lodash";
import {api} from "api";
import uuidv1 from "uuid/v1";
import Swal from "sweetalert2";
import moment from "moment/moment";
import {ToastStore} from "react-toasts";
import {push} from "react-router-redux";
import {handleActions} from "redux-actions";
import {PVN, PVMCE} from "utility/variables";
import {actions as printActions} from "../print/print";
import {actions as cajaActions} from "../caja/caja";
import {actions as usuarioActions} from "../usuario/usuario";
import {pago_total} from "utility/variables";
import {initialize as initializeForm, change as changeForm} from "redux-form";

// ------------------------------------
// Constants
// ------------------------------------

export const PAGE = "VENTA_PAGE";
export const SORT = "VENTA_SORT";
export const BUSCADOR = "VENTA_BUSCADOR";
export const PRODUCTOS = "VENTA_PRODUCTOS";
export const SELECCIONADOS = "VENTA_SELECCIONADOS";
export const LOADER = "VENTA_LOADER";
export const MOVIMIENTO = "VENTA_MOVIMIENTO";
export const SET_IMPRESION = "VENTA_SET_IMPRESION";
export const SET_TIPO_PAGO = "VENTA_SET_TIPO_PAGO";
export const SET_FECHA_VENCIMIENTO_CREDITO =
    "VENTA_SET_FECHA_VENCIMIENTO_CREDITO";
export const SET_EFECTIVO = "VENTA_SET_EFECTIVO";
export const SET_TARJETA = "VENTA_SET_TARJETA";
export const SET_CHEQUE = "VENTA_SET_CHEQUE";
export const SET_DEPOSITO = "VENTA_SET_DEPOSITO";
export const SET_RETENCION_IVA = "VENTA_SET_RETENCION_IVA";
export const SET_EXENCION_IVA = "VENTA_SET_EXENCION_IVA";
export const SET_NOTA = "VENTA_SET_NOTA";
export const SET_NOTA_CREDITO = "VENTA_SET_NOTA_CREDITO";
export const SET_CODIGO_NOTA_CREDITO = "VENTA_SET_CODIGO_NOTA_CREDITO";
export const UUID_REQ_PRODUCTOS = "VENTA_UUID_REQ_PRODUCTOS";
export const SET_NIT_CLIENTE = "VENTA_SET_NIT_CLIENTE";
export const SET_NOMBRE_CLIENTE = "VENTA_SET_NOMBRE_CLIENTE";
export const SET_VER_BUSCADOR = "VENTA_SET_VER_BUSCADOR";
export const SET_VER_COBRO = "VENTA_SET_VER_COBRO";
export const SET_REDONDEO = "VENTA_SET_REDONDEO";
export const SET_VENTA_COTIZACION = "VENTA_SET_VENTA_COTIZACION";
export const SET_VER_IMPRESION = "VENTA_SET_VER_IMPRESION";
export const SET_COTIZACION_CARGADA = "VENTA_SET_COTIZACION_CARGADA";
export const SET_NOTA_CREDITO_CARGADA = "VENTA_SET_NOTA_CREDITO_CARGADA";
export const SET_ESTADO_FORM_CLIENTE = "VENTA_SET_ESTADO_FORM_CLIENTE";

const endpoint = "ventas";
const endpoint_prods_ventas = "productos_venta";
const endpoint_datos_impresion_venta = "historial_ventas/reimpresion_venta";
const endpoint_clientes = "clientes";
const endpoint_cotizacion = "cotizaciones";

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const producto = store.venta;
    const {ordering, search} = producto;
    //  GENERAR EL UUID
    const uuid = uuidv1();
    dispatch(setUuidReqProductos(uuid));
    api.get(endpoint_prods_ventas, {page, ordering, search, activo: true})
        .catch((err) => {
        })
        .then((data) => {
            if (data) {
                const otroUuid = getStore().venta.uuid_req_productos;
                if (otroUuid === uuid) {
                    dispatch(setProductos(data));
                    dispatch(setPage(page));
                    dispatch(setLoader(false));
                }
            }
        });
};

export const cambiarPrecio = (index, precio) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        item.precio_original = precio;
        if (item.desc_porcentaje) {
            item.desc_monto = parseFloat(
                (item.precio_original * (item.desc_porcentaje / 100)).toFixed(2)
            );
            item.precio = item.precio_original - item.desc_monto;
        } else {
            item.desc_monto = 0;
            item.precio_final = "";
            item.precio = precio;
        }
        item.modelo_precio = precio;
        seleccionados.splice(index, 1, item);
        dispatch(setSeleccionados(seleccionados));
    } else {
        Swal(
            "Cambio de precio",
            "No fue posible cambiar el precio del producto seleccionado.",
            "warning"
        );
    }
};

export const cambiarPrecioEditable = (index, precio_producto) => (
    dispatch,
    getStore
) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        if (precio_producto != null && precio_producto != "") {
            let precio = precio_producto >= 0 ? precio_producto : 0;
            // item.precio_original = item.precio;
            if (item.desc_porcentaje) {
                item.desc_porcentaje = 0;
                item.precio_final = "";
                item.precio = precio;
            } else {
                item.desc_monto = 0;
                item.precio_final = "";
                item.precio = precio;
            }
            item.modelo_precio = precio;
            seleccionados.splice(index, 1, item);
            dispatch(setSeleccionados(seleccionados));
        }
    } else {
        Swal(
            "Cambio de precio",
            "No fue posible cambiar el precio del producto seleccionado.",
            "warning"
        );
    }
};

export const cambiarDescuento = (producto, index, descuento) => (
    dispatch,
    getStore
) => {
    if (descuento > 100) {
        producto.desc_porcentaje = 100;
    } else if (descuento < 0) {
        producto.desc_porcentaje = 0;
    } else {
        producto.desc_porcentaje = descuento;
    }
    //  Se calcula el precio del producto con el descuento
    if (producto.desc_porcentaje) {
        producto.desc_monto = parseFloat(
            Number(
                Number(
                    (producto.precio_original *
                    (producto.desc_porcentaje / 100)).toFixed(4)
                )
            ).toFixed(2)
        );
        producto.precio = producto.precio_original - producto.desc_monto;
    } else {
        producto.desc_monto = 0;
        producto.precio = producto.precio_original;
    }

    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        seleccionados.splice(index, 1, producto);
        dispatch(setSeleccionados(seleccionados));
    } else {
        Swal(
            "Cambio de descuento",
            "No fue posible cambiar el descuento del producto seleccionado.",
            "warning"
        );
    }
};

export const cambiarPrecioFinal = (producto, index, precio_final) => (
    dispatch,
    getStore
) => {
    if (precio_final > producto.precio_original) {
        producto.precio_final = producto.precio_original;
    } else if (precio_final < 0) {
        producto.precio_final = 0;
    } else {
        producto.precio_final = precio_final;
    }

    //  Se calcula el porcentaje de descuento con el precio final
    if (producto.precio_final) {
        producto.desc_monto = parseFloat(
            (producto.precio_original - producto.precio_final).toFixed(2)
        );
        producto.desc_porcentaje = parseFloat(
            ((producto.desc_monto / producto.precio_original) * 100)
        );
        producto.precio = producto.precio_final;
    } else {
        producto.desc_monto = 0;
        producto.precio = producto.precio_original;
    }

    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    const item = seleccionados[index];
    if (item) {
        seleccionados.splice(index, 1, producto);
        dispatch(setSeleccionados(seleccionados));
    } else {
        Swal(
            "Cambio de precio final",
            "No fue posible cambiar el precio final del producto seleccionado.",
            "warning"
        );
    }
};

export const desplegarItem = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        producto.collapsed = !item.collapsed;
        seleccionados.splice(index, 1, producto);

        //  SE CIERRAN LOS PRODUCTOS ANTERIORES
        seleccionados.forEach((prod, idx) => {
            if (index !== idx) {
                prod.collapsed = false;
            }
        });
        dispatch(setSeleccionados(seleccionados));
    } else {
        Swal(
            "Desplegar producto",
            "No fue posible desplegar mas opciones del producto seleccionado.",
            "warning"
        );
    }
};

export const agregar = (productoSeleccionado, tipoVenta) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    let producto = _.cloneDeep(productoSeleccionado);
    const {conf_cotizacion} = store.usuario;
    if (producto.existe_descontable || conf_cotizacion) {
        const items = _.filter(seleccionados, {id: producto.id});

        // if (item) {
        //     const index = seleccionados.indexOf(item);
        //     item.cantidad += 1;
        //     //  Se calcula el precio del producto con el descuento
        //     if (item.desc_porcentaje) {
        //         item.desc_monto = parseFloat((item.precio_original * (item.desc_porcentaje/100)).toFixed(2));
        //     }else {
        //         item.desc_monto = 0;
        //     }

        //     seleccionados.splice(index, 1, item);
        //     dispatch(setSeleccionados(seleccionados));
        // } else {

        producto.key = uuidv1();
        producto.cantidad = 1;

        if (tipoVenta === 'ONLINE'){
            producto.cantidad   = productoSeleccionado.cantidad;
        }

        //  SE SETEA EL INVENTARIO TOTAL COMO REFERENCIA
        if (items.length) {
            producto.inventario_total = items[0].inventario_total;
        } else {
            producto.inventario_total = producto.inventario_calculado;
        }
        //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
        let cantidad_total = producto.cantidad;

        //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
        items.forEach((item) => {
            cantidad_total += item.cantidad;
        });
        //  SE CALCULA EL INVENTARIO FINAL
        const inventario_final = producto.inventario_total - cantidad_total;

        //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
        if (items.length) {
            items.forEach((item) => {
                const indexSeleccionado = _.indexOf(seleccionados, item);
                item.inventario = inventario_final >= 0 ? inventario_final : 0;
                item.cantidad_total = cantidad_total;
                seleccionados.splice(indexSeleccionado, 1, item);
            });
        }
        producto.cantidad_total = cantidad_total;
        producto.inventario = inventario_final >= 0 ? inventario_final : 0;
        producto.collapsed = false;
        producto.es_vale = false;
        // Se conserva el precio principal
        producto.precio_original = producto.precio;
        producto.precio1 = producto.precio;
        producto.num_precios = 0;
        producto.listado_precios = [];
        producto.id_unidad_de_medida = producto.producto.a_granel
            ? producto.unidad_de_medida
            : null;

        const {nombres_precios} = store.usuario;

        //  Se valida que si tiene varios precios para dar la opcion de seleccionar
        if (producto.precio) {
            producto.num_precios += 1;
            producto.modelo_precio = {id: 1, value: producto.precio};
            producto.listado_precios.push({
                id: 1,
                value: producto.precio.toFixed(2),
                nombre: nombres_precios
                    ? nombres_precios.nombre_precio
                    : "Precio",
                porcentaje: "100.00",
            });
        }
        if (producto.precio2) {
            producto.num_precios += 1;
            producto.listado_precios.push({
                id: 2,
                value: producto.precio2.toFixed(2),
                nombre: nombres_precios
                    ? nombres_precios.nombre_precio2
                    : "Precio 2",
                porcentaje: producto.porcent_precio2
                    ? producto.porcent_precio2.toFixed(2)
                    : 0,
            });
        }
        if (producto.precio3) {
            producto.num_precios += 1;
            producto.listado_precios.push({
                id: 3,
                value: producto.precio3.toFixed(2),
                nombre: nombres_precios
                    ? nombres_precios.nombre_precio3
                    : "Precio 3",
                porcentaje: producto.porcent_precio3
                    ? producto.porcent_precio3.toFixed(2)
                    : 0,
            });
        }
        if (producto.precio4) {
            producto.num_precios += 1;
            producto.listado_precios.push({
                id: 4,
                value: producto.precio4.toFixed(2),
                nombre: nombres_precios
                    ? nombres_precios.nombre_precio4
                    : "Precio 4",
                porcentaje: producto.porcent_precio4
                    ? producto.porcent_precio4.toFixed(2)
                    : 0,
            });
        }
        if (producto.precio5) {
            producto.num_precios += 1;
            producto.listado_precios.push({
                id: 5,
                value: producto.precio5.toFixed(2),
                nombre: nombres_precios
                    ? nombres_precios.nombre_precio5
                    : "Precio 5",
                porcentaje: producto.porcent_precio5
                    ? producto.porcent_precio5.toFixed(2)
                    : 0,
            });
        }

        dispatch(setSeleccionados([...seleccionados, producto]));
        // }
    } else {
        Swal("ERROR", "No cuenta con inventario para este producto", "error");
    }
};

const agregarVale = (codigo) => (dispatch, getStore) => {
    api.get("devoluciones", {codigo})
        .then((data) => {
            if (data.results.length > 0) {
                const vale = data.results[0];
                const store = getStore();
                const seleccionados = _.cloneDeep(store.venta.seleccionados);
                dispatch(
                    setSeleccionados([
                        ...seleccionados,
                        {
                            ...vale,
                            es_vale: true,
                        },
                    ])
                );
            } else {
                Swal("ERROR", "El código ingresado no es válido", "warning");
            }
        })
        .catch((e) => {
        })
        .finally(() => {
        });
};

export const sumar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        item.cantidad += 1;
        if (item.cantidad > 0) {
            //  Se calcula el precio del producto con el descuento
            if (item.desc_porcentaje) {
                item.desc_monto = parseFloat(
                    (
                        item.precio_original *
                        (item.desc_porcentaje / 100)
                    ).toFixed(2)
                );
            } else {
                item.desc_monto = 0;
            }
            //  SE FILTRAN LOS PRODUCTOS IGUALES
            const items = _.filter(seleccionados, {id: producto.id});

            //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
            let cantidad_total = 0;

            //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
            items.forEach((item) => {
                cantidad_total += item.cantidad;
            });
            //  SE CALCULA EL INVENTARIO FINAL
            const inventario_final = item.inventario_total - cantidad_total;

            //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
            if (items.length) {
                items.forEach((item) => {
                    const indexSeleccionado = _.indexOf(seleccionados, item);
                    item.inventario =
                        inventario_final >= 0 ? inventario_final : 0;
                    item.cantidad_total = cantidad_total;
                    seleccionados.splice(indexSeleccionado, 1, item);
                });
            }
            item.cantidad_total = cantidad_total;
            item.inventario = inventario_final >= 0 ? inventario_final : 0;
            seleccionados.splice(index, 1, item);
        } else {
            seleccionados.splice(index, 1);
        }
        dispatch(setSeleccionados(seleccionados));
    }
};

export const restar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        item.cantidad -= 1;
        if (item.cantidad > 0) {
            //  Se calcula el precio del producto con el descuento
            if (item.desc_porcentaje) {
                item.desc_monto = parseFloat(
                    (
                        item.precio_original *
                        (item.desc_porcentaje / 100)
                    ).toFixed(2)
                );
            } else {
                item.desc_monto = 0;
            }

            //  SE FILTRAN LOS PRODUCTOS IGUALES
            const items = _.filter(seleccionados, {id: producto.id});

            //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
            let cantidad_total = 0;

            //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
            items.forEach((item) => {
                cantidad_total += item.cantidad;
            });
            //  SE CALCULA EL INVENTARIO FINAL
            const inventario_final = item.inventario_total - cantidad_total;

            //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
            if (items.length) {
                items.forEach((item) => {
                    const indexSeleccionado = _.indexOf(seleccionados, item);
                    item.inventario =
                        inventario_final >= 0 ? inventario_final : 0;
                    item.cantidad_total = cantidad_total;
                    seleccionados.splice(indexSeleccionado, 1, item);
                });
            }
            item.cantidad_total = cantidad_total;
            item.inventario = inventario_final >= 0 ? inventario_final : 0;

            seleccionados.splice(index, 1, item);
        } else {
            seleccionados.splice(index, 1);
        }
        dispatch(setSeleccionados(seleccionados));
        // dispatch(buscar(""));
        // dispatch(listar());
    }
};
export const quitar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = producto.es_vale ? _.find(seleccionados, { codigo: producto.codigo }) : _.find(seleccionados, { id: producto.id });
    const item = producto.es_vale
        ? _.find(seleccionados, {codigo: producto.codigo})
        : seleccionados[index];
    if (item) {
        // const index = seleccionados.indexOf(item);
        seleccionados.splice(index, 1);
        //  SE FILTRAN LOS PRODUCTOS IGUALES
        const items = _.filter(seleccionados, {id: producto.id});

        //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
        let cantidad_total = 0;

        //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
        items.forEach((item) => {
            cantidad_total += item.cantidad;
        });
        //  SE CALCULA EL INVENTARIO FINAL
        const inventario_final = item.inventario_total - cantidad_total;

        //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
        if (items.length) {
            items.forEach((item) => {
                const indexSeleccionado = _.indexOf(seleccionados, item);
                item.inventario = inventario_final >= 0 ? inventario_final : 0;
                item.cantidad_total = cantidad_total;
                seleccionados.splice(indexSeleccionado, 1, item);
            });
        }

        dispatch(setSeleccionados(seleccionados));
    }
};

export const ingresoCantidad = (cantidad, producto, index) => (
    dispatch,
    getStore
) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    // const item = _.find(seleccionados, { id: producto.id });
    const item = seleccionados[index];
    // debugger;
    if (item) {
        const index = seleccionados.indexOf(item);
        item.cantidad = cantidad;
        if (item.cantidad > 0) {
            //  Se calcula el precio del producto con el descuento
            if (item.desc_porcentaje) {
                item.desc_monto = parseFloat(
                    (
                        item.precio_original *
                        (item.desc_porcentaje / 100)
                    ).toFixed(2)
                );
            } else {
                item.desc_monto = 0;
            }
            seleccionados.splice(index, 1, item);
        } else {
            item.desc_monto = 0;
        }

        //  SE FILTRAN LOS PRODUCTOS IGUALES
        const items = _.filter(seleccionados, {id: producto.id});

        //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
        let cantidad_total = 0;

        //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
        items.forEach((item) => {
            cantidad_total += item.cantidad;
        });
        //  SE CALCULA EL INVENTARIO FINAL
        const inventario_final = item.inventario_total - cantidad_total;

        //  SI SE ENCUENTRAN PRODUCTOS SE ACTUALIZAN SUS INVENTARIO
        if (items.length) {
            items.forEach((item) => {
                const indexSeleccionado = _.indexOf(seleccionados, item);
                item.inventario = inventario_final >= 0 ? inventario_final : 0;
                item.cantidad_total = cantidad_total;
                seleccionados.splice(indexSeleccionado, 1, item);
            });
        }
        item.cantidad_total = cantidad_total;
        item.inventario = inventario_final >= 0 ? inventario_final : 0;

        dispatch(setSeleccionados(seleccionados));
    }
};

export const asignarUnidadDeMedida = (producto, id_unidad_medida) => (
    dispatch,
    getStore
) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.venta.seleccionados);
    let item = _.find(seleccionados, {id: producto.id});
    const index = seleccionados.indexOf(item);
    item.id_unidad_de_medida = parseInt(id_unidad_medida);
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const agregarPrimer = (search) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const producto = store.venta;
    const {ordering} = producto;
    api.get(endpoint, {ordering, search})
        .catch((err) => {
        })
        .then((data) => {
            if (data) {
                if (data.count === 1) {
                    dispatch(agregar(data.results[0]));
                    // dispatch(seleccionarProducto(data.results[0]))
                }
            }
        })
        .finally(() => {
            dispatch(buscar(""));
            dispatch(listar());
            dispatch(setLoader(false));
        });
};

export const pagar = (total) => (dispatch, getStore) => {


    console.log("PAGAR");
    const store = getStore();
    const {
        efectivo,
        tarjeta,
        tipo_pago,
        redondeo,
        seleccionados,
        cheque,
        deposito,
        retencion_iva,
        exencion_iva,
        nota_credito,
        nota,
    } = store.venta;
    const movimiento = store.venta.movimiento;
    const {conf_venta_redondeo} = store.usuario;
    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };
    if (movimiento.sucursal !== undefined) {
        // dispatch(push('/ventas'));
        dispatch(setMovimiento({}));
        dispatch(setSeleccionados([]));
        dispatch(limpiarDatosCliente());
        dispatch(setVerBuscador(false));
        dispatch(setVerCobro(false));
        dispatch(_setEfectivo(""));
        dispatch(_setTarjeta(""));
        dispatch(_setCheque(""));
        dispatch(_setDeposito(""));
        dispatch(_setRetencionIVA(""));
        dispatch(_setExencionIVA(""));
        dispatch(_setNotaCredito(""));
        dispatch(setNota(""));
        dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
        dispatch(setTipoPago(pago_total));
        dispatch(setBuscador(""));
        dispatch(setCodigoNotaCredito(""));
        dispatch(setNotaCreditoCargada(null));
        dispatch(listar());
    } else {
        total = total ? parseFloat(total) : 0;
        //  VALIDACION DE MONTO TOTAL
        let monto_valido = false;
        const total_pago =
            pagos.tarjeta +
            pagos.efectivo +
            pagos.cheque +
            pagos.deposito +
            pagos.retencion_iva +
            pagos.exencion_iva +
            pagos.nota_credito;
        if (tipo_pago == 100) {
            if (total_pago < total) {
                Swal(
                    "ERROR",
                    "Debe cancelar el monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        } else {
            const monto_a_pagar = total_pago;

            if (monto_a_pagar >= total) {
                Swal(
                    "ERROR",
                    "El anticipo debe ser menor al monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        }

        if (monto_valido) {
            if (conf_venta_redondeo) {
                // CALCULO DE RECIDUO PARA EL REDONDEO
                let total = 0;
                seleccionados.forEach((producto) => {
                    if (!producto.es_vale) {
                        const precio = producto.precio
                            ? parseFloat(producto.precio)
                            : 0;
                        const cantidad = producto.cantidad
                            ? parseFloat(producto.cantidad)
                            : 0;
                        total = total + precio * cantidad;
                    } else {
                        total -= producto.total_importe;
                    }
                });

                const decimal = parseFloat((total % 1).toFixed(2));
                const moneda_mas_pequeno = 0.05;
                const cantidad_monedas = Math.floor(
                    decimal / moneda_mas_pequeno
                );
                const reciduo = parseFloat(
                    (decimal - cantidad_monedas * moneda_mas_pequeno).toFixed(2)
                );
                if (reciduo > 0 && !redondeo) {
                    //  REDONDEO HACIA ARRIBA O ABAJO
                    Swal({
                        title: "¿Cómo quieres redondear el total de la venta?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Hacia arriba",
                        cancelButtonText: "Hacia abajo",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.value) {
                            dispatch(setRedondeo("arriba"));
                        } else if ((result.dismiss = "cancel")) {
                            dispatch(setRedondeo("abajo"));
                        }
                    });
                } else {
                    dispatch(validarCliente());
                }
            } else {
                dispatch(validarCliente());
            }
        }
    }
};

const forzarFinalizarVenta = () => (dispatch) => {
    dispatch(setMovimiento({}));
    dispatch(setSeleccionados([]));
    dispatch(limpiarDatosCliente());
    dispatch(setVerBuscador(false));
    dispatch(setVerCobro(false));
    dispatch(_setEfectivo(""));
    dispatch(_setTarjeta(""));
    dispatch(_setCheque(""));
    dispatch(_setDeposito(""));
    dispatch(_setRetencionIVA(""));
    dispatch(_setExencionIVA(""));
    dispatch(_setNotaCredito(""));
    dispatch(setNota(""));
    dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
    dispatch(setTipoPago(pago_total));
    dispatch(setBuscador(""));
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
    dispatch(listar());
};

const validarCliente = () => (dispatch, getStore) => {
    //  VALIDAR CLIENTE
    const store = getStore();
    const data_cliente = store.form.ClienteVentaForm.values;
    const errores = store.form.ClienteVentaForm.syncErrors
        ? store.form.ClienteVentaForm.syncErrors
        : false;
    if (!errores) {
        if (data_cliente.nit != "") {
            if (data_cliente.nit == "CF" || data_cliente.nit == "cf") {
                // si ingreso CF en el campo nit
                const data_cliente_cf = {
                    nit: "C.F.",
                    nombre: data_cliente.nombre == "" ? "Consumidor Final" : data_cliente.nombre, //Guardar nombre del cliente si fue escrito
                    //Guardar direccion del cliente si fue escrita
                    direccion: data_cliente.direccion && data_cliente.direccion.replace(/\s+/g, '') != '' ? data_cliente.direccion : "Ciudad",
                };
                dispatch(registrarVenta(data_cliente_cf));
            } else {
                if (data_cliente.nombre == "") {
                    // si ingreso nit pero no esta el nombre presente en el formulario
                    Swal(
                        "Nombre del cliente",
                        "Por favor de ingresar el nombre del cliente.",
                        "error"
                    );
                } else {
                    // si ingreso nit y nombre
                    dispatch(registrarVenta(data_cliente));
                }
            }
        } else {
            if (data_cliente.nombre == "" && data_cliente.nit == "") {
                // SI no ingreso NIT ni nombre mostar modal
                Swal({
                    title: "¿Registrar venta con cliente Consumidor Final?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Sí",
                    cancelButtonText: "No",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.value) {
                        const data_cliente_cf = {
                            nit: "C.F.",
                            nombre: "Consumidor Final",
                            //Guardar direccion del cliente si fue escrita
                        direccion: data_cliente.direccion && data_cliente.direccion.replace(/\s+/g, '') != '' ? data_cliente.direccion : "Ciudad",
                        };
                        dispatch(registrarVenta(data_cliente_cf));
                    }
                });
            } else {
                // Si ingreso solo el nombre y no el nit
                const data_cliente_cf = {
                    nit: "C.F.",
                    nombre: data_cliente.nombre != "" ? data_cliente.nombre : 'Consumidor Final',
                    //Guardar direccion del cliente si fue escrita
                    direccion: data_cliente.direccion && data_cliente.direccion.replace(/\s+/g, '') != '' ? data_cliente.direccion : "Ciudad",
                };
                dispatch(registrarVenta(data_cliente_cf));
            }
        }
    }
};

const registrarVenta = (data_cliente) => (dispatch, getStore) => {
    const store = getStore();
    const form_cliente = store.form.ClienteVentaForm.values;
    const {
        efectivo,
        tarjeta,
        cheque,
        deposito,
        nota_credito,
        tipo_pago,
        fecha_vencimiento,
        redondeo,
        nota,
        nota_credito_cargada,
        retencion_iva,
        exencion_iva,
    } = store.venta;
    const productos = store.venta.seleccionados;

    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };

    dispatch(setLoader(true));

    //  SE CREA O SE OBTIENE AL CLIENTE Y LUEGO SE REGISTRA LA VENTA
    api.post(endpoint_clientes, data_cliente)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error al crear el cliente, por favor vuelve a intentar.",
                "error"
            );
            return null;
        })
        .then((data) => {
            if (data) {
                //  SE REGISTRA LA VENTA CON EL CLIENTE REGISTRADO
                const cliente = data.id;
                api.post(endpoint, {
                    productos,
                    cliente,
                    referencia: form_cliente.referencia
                        ? form_cliente.referencia
                        : "",
                    mostrar_referencia: form_cliente.mostrar_referencia
                        ? form_cliente.mostrar_referencia
                        : false,
                    sin_factura: form_cliente.sin_factura
                        ? form_cliente.sin_factura
                        : false,
                    pagos,
                    tipo_pago,
                    fecha_vencimiento,
                    redondeo,
                    codigo_nota_credito: nota_credito_cargada
                        ? nota_credito_cargada.nota_credito_fel.uuid
                        : null,
                    tipo_documento: form_cliente.tipo_documento,
                })
                    .catch((err) => {
                        if (err) {
                            Swal("ERROR", err.detail, "error");
                        } else {
                            Swal(
                                "ERROR",
                                "Ha ocurrido un error, espere un momento y vuelva a intentar",
                                "error"
                            );
                        }
                        dispatch(setLoader(false));
                    })
                    .then((data) => {
                        if (data) {
                            if (data.id_factura) {
                                Swal(
                                    "¡Factura electrónica en proceso!",
                                    "Se esta generando la factura, espere por favor",
                                    "info"
                                );
                                dispatch(consultarEstadoFEL(data.id_factura));
                            } else {
                                dispatch(impresionTicketVenta(data));
                                dispatch(setLoader(false));
                            }
                        }
                    });
            }
        });
};

const consultarEstadoFEL = (id_factura) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("factura_electronica/verificar_fel", {id_factura})
        .catch((err) => {
            Swal("ERROR", "Error al generar factura electrónica", "error");
        })
        .then((resp) => {
            status = resp.estado_factura;
            if (status === "proceso") {
                setTimeout(() => {
                    dispatch(consultarEstadoFEL(id_factura));
                }, 1000);
            } else if (status === "facturado") {
                Swal(
                    "Éxito",
                    "Factura electrónica registrada correctamente",
                    "success"
                ).then(() => {
                    setTimeout(() => {
                        dispatch(impresionTicketVenta(resp.factura));
                    }, 500);
                });
                dispatch(setLoader(false));
            } else {
                const msj = resp.detail
                    ? `Error en facturación electrónica, ${resp.detail}, la venta se ha registrado y puedes rintentarlo en el detalle de caja o en el reporte de ventas`
                    : "Error en facturación electrónica, la venta se ha registrado y puedes rintentarlo en el detalle de caja o en el reporte de ventas";
                Swal("ERROR", msj, "error").then((val) => {
                    dispatch(setLoader(false));
                    dispatch(forzarFinalizarVenta());
                });
            }
        });
};

const impresionTicketVenta = (movimiento) => (dispatch) => {
    dispatch(setLoader(true));
    let data_movimiento = movimiento;
    const vuelto = data_movimiento.vuelto_efectivo
        ? data_movimiento.vuelto_efectivo
        : 0;
    data_movimiento.monto_efectivo = data_movimiento.monto_efectivo + vuelto;
    dispatch(setMovimiento(data_movimiento));
    dispatch(usuarioActions.getMe());
    dispatch(cajaActions.get_ultimo_cierre());
    api.get(endpoint_datos_impresion_venta, {id: movimiento.id_venta})
        .catch((err) => {
            dispatch(setVentaImpresion(null));
        })
        .then((resp) => {
            if (resp) {
                let data_impresion = resp;
                let vuelto = 0;
                if (data_impresion.length === undefined) {
                    vuelto = data_impresion.vuelto_efectivo
                        ? data_impresion.vuelto_efectivo
                        : 0;
                    data_impresion.monto_efectivo =
                        data_impresion.monto_efectivo + vuelto;
                }
                dispatch(setVentaImpresion(data_impresion));
                if (data_movimiento.tipo_pago == pago_total)
                    dispatch(printActions.print("reciboVenta"));
                else {
                    dispatch(printActions.print("reciboVenta"));
                    // dispatch(printActions.print("reciboVentaCredito"));
                }
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const resetVenta = () => (dispatch) => {
    dispatch(setSeleccionados([]));
    dispatch(setMovimiento({}));
    dispatch(setCotizacionCargada(null));
};

export const setEfectivo = (monto) => (dispatch) => {
    if (monto < 0) {
        dispatch(_setEfectivo(0));
    } else {
        dispatch(_setEfectivo(monto));
    }
};
export const setTarjeta = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setTarjeta(0));
        } else {
            if (monto <= total) {
                dispatch(_setTarjeta(monto));
            } else {
                dispatch(_setTarjeta(total));
            }
        }
    } else {
        dispatch(_setTarjeta(""));
    }
};
export const setCheque = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setCheque(0));
        } else {
            if (monto <= total) {
                dispatch(_setCheque(monto));
            } else {
                dispatch(_setCheque(total));
            }
        }
    } else {
        dispatch(_setCheque(""));
    }
};
export const setDeposito = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setDeposito(0));
        } else {
            if (monto <= total) {
                dispatch(_setDeposito(monto));
            } else {
                dispatch(_setDeposito(total));
            }
        }
    } else {
        dispatch(_setDeposito(""));
    }
};

export const setNotaCredito = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setNotaCredito(0));
        } else {
            dispatch(_setNotaCredito(monto));
        }
    } else {
        dispatch(_setNotaCredito(""));
    }
};

export const setRetencionIva = (monto, total) => (dispatch) => {
    console.log("AAAAA");
    if (monto) {
        if (monto < 0) {
            dispatch(_setRetencionIVA(0));
        } else {
            if (monto <= total) {
                dispatch(_setRetencionIVA(monto));
            } else {
                dispatch(_setRetencionIVA(total));
            }
        }
    } else {
        dispatch(_setRetencionIVA(""));
    }
};

export const setExencionIva = (monto, total) => (dispatch) => {
    if (monto) {
        if (monto < 0) {
            dispatch(_setExencionIVA(0));
        } else {
            if (monto <= total) {
                dispatch(_setExencionIVA(monto));
            } else {
                dispatch(_setExencionIVA(total));
            }
        }
    } else {
        dispatch(_setExencionIVA(""));
    }
};

export const leerCliente = (nit) => (dispatch) => {
    // esta accion lee un cliente a partir de su nit
    // para cargarlo a una venta, no confundir con leer()
    dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    dispatch(changeForm("ClienteVentaForm", "direccion", ""));
    dispatch(changeForm("ClienteVentaForm", "buscar_nombre", ""));
    api.get(`${endpoint_clientes}/${nit}/por_nit/`)
        .catch((err) => {
            // si no existe retorna un 404,
            // no hacer nada
            if (err.detail) {
                if (err.detail == "El servicio de Infile no esta disponible") {
                    dispatch(setEstadoFormCliente(false));
                    ToastStore.error(err.detail);
                } else {
                    dispatch(setEstadoFormCliente(true));
                }
            } else {
                ToastStore.error(
                    "No fue posible obtener información del cliente"
                );
            }
        })
        .then((data) => {
            // cliente encontrado
            if (data) {
                dispatch(setEstadoFormCliente(true));
                dispatch(setNombreCliente(data.nombre));
                dispatch(changeForm("ClienteVentaForm", "nombre", data.nombre));
                dispatch(changeForm("ClienteVentaForm", "buscar_nombre", ""));
                dispatch(
                    changeForm(
                        "ClienteVentaForm",
                        "direccion",
                        data.direccion ? data.direccion : ""
                    )
                );
            }
        });
};

export const leerClienteNombre = (nombre, nit) => (dispatch) => {
    // esta accion lee un cliente a partir de su nombre
    dispatch(changeForm("ClienteVentaForm", "nit", ""));
    dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    dispatch(changeForm("ClienteVentaForm", "direccion", ""));
    api.get(`${endpoint_clientes}/${nit}/por_nombre/`,{ search: nombre})
        .catch((err) => {
            // si no existe retorna un 404,
            // no hacer nada
            if (err.detail) {
                if (err.detail == "El servicio de Infile no esta disponible") {
                    dispatch(setEstadoFormCliente(false));
                    ToastStore.error(err.detail);
                } else {
                    dispatch(setEstadoFormCliente(true));
                }
            } else {
                ToastStore.error(
                    "No fue posible obtener información del cliente"
                );
            }
        })
        .then((data) => {
            // cliente encontrado
            if (data) {
                dispatch(setEstadoFormCliente(true));
                dispatch(setNitCliente(data.nit));
                dispatch(changeForm("ClienteVentaForm", "nit", data.nit));
                dispatch(setNombreCliente(data.nombre));
                dispatch(changeForm("ClienteVentaForm", "nombre", data.nombre));
                dispatch(
                    changeForm(
                        "ClienteVentaForm",
                        "direccion",
                        data.direccion ? data.direccion : ""
                    )
                );
            }
        });
};

export const limpiarDatosCliente = () => (dispatch) => {
    dispatch(setNitCliente(""));
    dispatch(setNombreCliente(""));
    dispatch(changeForm("ClienteVentaForm", "nit", ""));
    dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    dispatch(changeForm("ClienteVentaForm", "direccion", ""));
    dispatch(changeForm("ClienteVentaForm", "referencia", ""));
};

export const changeFieldForm = (field, value) => (dispatch) => {
    dispatch(changeForm("ClienteVentaForm", field, value));
}

export const changeDatosClientes = (campo, value) => (dispatch, getStore) => {
    const nit = getStore().venta.nit_cliente
    if (campo === "nit") {
        dispatch(setNitCliente(value));
    } else {
        dispatch(setNombreCliente(value));
    }
};

export const changeDisabled = (value) => (dispatch, getStore) => {
    dispatch(setEstadoFormCliente(value));

};

export const cambioBuscador = () => (dispatch, getStore) => {
    const store = getStore();
    const ver_buscador = store.venta.ver_buscador;
    dispatch(setVerBuscador(!ver_buscador));
};
export const cambioCobro = (total) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.venta.seleccionados;
    const {ver_buscador, ver_cobro} = store.venta;
    if (total <= 0) {
        ToastStore.error(
            "El monto de descuento por vale no debe sobrepasar ni igualar el total de la venta"
        );
    } else {
        if (seleccionados.length) {
            dispatch(setVerCobro(!ver_cobro));
            dispatch(setVerBuscador(!ver_buscador));
        } else {
            if (!ver_cobro) {
                ToastStore.error("La venta no tiene productos seleccionados.");
            } else {
                dispatch(setVerCobro(!ver_cobro));
                dispatch(setVerBuscador(!ver_buscador));
            }
        }
    }
};

export const leerClienteCotizacion = (nit) => (dispatch) => {
    // esta accion lee un cliente a partir de su nit
    // para cargarlo a una venta, no confundir con leer()
    api.get(`${endpoint_clientes}/${nit}/por_nit/`)
        .catch((err) => {
            // si no existe retorna un 404,
            // no hacer nada
        })
        .then((data) => {
            // cliente encontrado
            if (data) {
                dispatch(
                    changeForm("ClienteCotizacionForm", "nombre", data.nombre)
                );
                dispatch(
                    changeForm("ClienteCotizacionForm", "correo", data.correo)
                );
            }
        });
};

export const enviarCotizacion = (datos) => (dispatch, getStore) => {
    const store = getStore();
    const productos = store.venta.seleccionados;
    dispatch(setLoader(true));
    api.post(endpoint_cotizacion, {productos, enviado_caja: false, ...datos})
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                let data_venta_cotizacion = data;
                dispatch(setVentaCotizacion(data_venta_cotizacion));
                dispatch(setVerImpresion(true));
                // ToastStore.success("Cotizacion generada exitosamente");
                dispatch(printActions.print("ticketCotizacion"));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const actualizarCotizacion = (datos) => (dispatch, getStore) => {
    const store = getStore();
    const {seleccionados, cotizacion_cargada} = store.venta;
    dispatch(setLoader(true));
    api.put(`${endpoint_cotizacion}/${cotizacion_cargada.id}`, {
        productos: seleccionados,
        enviado_caja: false,
        ...datos,
    })
        .catch((err) => {
            if (err) {
                Swal(
                    "ERROR",
                    err.detail ||
                    "Error al actualizar la cotización, intenta más tarde",
                    "error"
                );
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                let data_venta_cotizacion = data;
                dispatch(setVentaCotizacion(data_venta_cotizacion));
                dispatch(setVerImpresion(true));
                dispatch(printActions.print("ticketCotizacion"));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const finalizarCotizacion = () => (dispatch) => {
    dispatch(usuarioActions.getMe());
    dispatch(setMovimiento({}));
    dispatch(setSeleccionados([]));
    dispatch(limpiarDatosCliente());
    dispatch(setVerBuscador(false));
    dispatch(setVerCobro(false));
    dispatch(_setEfectivo(""));
    dispatch(_setTarjeta(""));
    dispatch(_setCheque(""));
    dispatch(_setDeposito(""));
    dispatch(_setRetencionIVA(""));
    dispatch(_setExencionIVA(""));
    dispatch(_setNotaCredito(""));
    dispatch(setNota(""));
    dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
    dispatch(setTipoPago(pago_total));
    dispatch(setBuscador(""));
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
    dispatch(listar());
    dispatch(setVentaCotizacion(null));
    dispatch(setVerImpresion(false));
    dispatch(setCotizacionCargada(null));
};

export const pagarVentaCotizacon = (total) => (dispatch, getStore) => {
    console.log("pagarVentaCotizacon");
    const store = getStore();
    const {
        efectivo,
        tarjeta,
        tipo_pago,
        redondeo,
        seleccionados,
        movimiento,
        cheque,
        deposito,
        nota_credito,
        nota,
        retencion_iva,
        exencion_iva,
    } = store.venta;
    const {conf_venta_redondeo} = store.usuario;
    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };

    if (movimiento.sucursal !== undefined) {
        dispatch(setMovimiento({}));
        dispatch(setSeleccionados([]));
        dispatch(limpiarDatosCliente());
        dispatch(setVerBuscador(false));
        dispatch(setVerCobro(false));
        dispatch(_setEfectivo(""));
        dispatch(_setTarjeta(""));
        dispatch(_setCheque(""));
        dispatch(_setDeposito(""));
        dispatch(_setRetencionIVA(""));
        dispatch(_setExencionIVA(""));
        dispatch(_setNotaCredito(""));
        dispatch(setNota(""));
        dispatch(setFechaVencimientoCredito(moment().format("YYYY-MM-DD")));
        dispatch(setTipoPago(pago_total));
        dispatch(setBuscador(""));
        dispatch(setCodigoNotaCredito(""));
        dispatch(setNotaCreditoCargada(null));
        dispatch(listar());
        dispatch(setCotizacionCargada(null));
    } else {
        total = total ? parseFloat(total) : 0;
        //  VALIDACION DE MONTO TOTAL
        let monto_valido = false;
        const total_pago =
            pagos.tarjeta +
            pagos.efectivo +
            pagos.cheque +
            pagos.deposito +
            pagos.retencion_iva +
            pagos.exencion_iva +
            pagos.nota_credito;
        if (tipo_pago == 100) {
            if (total_pago < total) {
                Swal(
                    "ERROR",
                    "Debe cancelar el monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        } else {
            const monto_a_pagar = total_pago;

            if (monto_a_pagar >= total) {
                Swal(
                    "ERROR",
                    "El anticipo debe ser menor al monto total de la venta.",
                    "error"
                );
                monto_valido = false;
            } else {
                monto_valido = true;
            }
        }

        if (monto_valido) {
            if (conf_venta_redondeo) {
                // CALCULO DE RECIDUO PARA EL REDONDEO
                let total = 0;
                seleccionados.forEach((producto) => {
                    if (!producto.es_vale) {
                        const precio = producto.precio
                            ? parseFloat(producto.precio)
                            : 0;
                        const cantidad = producto.cantidad
                            ? parseFloat(producto.cantidad)
                            : 0;
                        total = total + precio * cantidad;
                    } else {
                        total -= producto.total_importe;
                    }
                });

                const decimal = parseFloat((total % 1).toFixed(2));
                const moneda_mas_pequeno = 0.05;
                const cantidad_monedas = Math.floor(
                    decimal / moneda_mas_pequeno
                );
                const reciduo = parseFloat(
                    (decimal - cantidad_monedas * moneda_mas_pequeno).toFixed(2)
                );
                if (reciduo > 0 && !redondeo) {
                    //  REDONDEO HACIA ARRIBA O ABAJO
                    Swal({
                        title: "¿Cómo quieres redondear el total de la venta?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Hacia arriba",
                        cancelButtonText: "Hacia abajo",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.value) {
                            dispatch(setRedondeo("arriba"));
                        } else if ((result.dismiss = "cancel")) {
                            dispatch(setRedondeo("abajo"));
                        }
                    });
                } else {
                    dispatch(validarClienteCotizacion());
                }
            } else {
                dispatch(validarClienteCotizacion());
            }
        }
    }
};

const validarClienteCotizacion = () => (dispatch, getStore) => {
    //  VALIDAR CLIENTE
    const store = getStore();
    const data_cliente = store.form.ClienteVentaForm.values;
    if (data_cliente.nit != "") {
        if (data_cliente.nombre == "") {
            Swal(
                "Nombre del cliente",
                "Por favor de ingresar el nombre del cliente.",
                "error"
            );
        } else {
            dispatch(registrarVentaCotizacion(data_cliente));
        }
    } else {
        Swal({
            title: "¿Registrar venta con cliente Consumidor Final?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                const data_cliente_cf = {
                    nit: "C.F.",
                    nombre: "Consumidor Final",
                };
                dispatch(registrarVentaCotizacion(data_cliente_cf));
            }
        });
    }
};

const registrarVentaCotizacion = (data_cliente) => (dispatch, getStore) => {
    const store = getStore();
    const form_cliente = store.form.ClienteVentaForm.values;
    const {
        efectivo,
        tarjeta,
        tipo_pago,
        fecha_vencimiento,
        redondeo,
        cotizacion_cargada,
        cheque,
        deposito,
        nota_credito,
        nota,
        nota_credito_cargada,
        retencion_iva,
        exencion_iva,
    } = store.venta;
    const productos = store.venta.seleccionados;

    let pagos = {
        efectivo: efectivo ? parseFloat(efectivo) : 0,
        tarjeta: tarjeta ? parseFloat(tarjeta) : 0,
        cheque: cheque ? parseFloat(cheque) : 0,
        deposito: deposito ? parseFloat(deposito) : 0,
        retencion_iva: retencion_iva ? parseFloat(retencion_iva) : 0,
        exencion_iva: exencion_iva ? parseFloat(exencion_iva) : 0,
        nota_credito: nota_credito ? parseFloat(nota_credito) : 0,
        nota: nota ? nota : "",
    };

    dispatch(setLoader(true));

    //  SE CREA O SE OBTIENE AL CLIENTE Y LUEGO SE REGISTRA LA VENTA
    api.post(endpoint_clientes, data_cliente)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error al crear el cliente, por favor vuelve a intentar.",
                "error"
            );
            return null;
        })
        .then((data) => {
            if (data) {
                //  SE REGISTRA LA VENTA CON EL CLIENTE REGISTRADO
                const cliente = data.id;
                api.post(`${endpoint}/cotizacion`, {
                    cotizacion: cotizacion_cargada.id,
                    productos,
                    cliente,
                    referencia: form_cliente.referencia
                        ? form_cliente.referencia
                        : "",
                    mostrar_referencia: form_cliente.mostrar_referencia
                        ? form_cliente.mostrar_referencia
                        : false,
                    pagos,
                    tipo_pago,
                    fecha_vencimiento,
                    redondeo,
                    codigo_nota_credito: nota_credito_cargada
                        ? nota_credito_cargada.nota_credito_fel.uuid
                        : null,
                })
                    .catch((err) => {
                        if (err) {
                            Swal("ERROR", err.detail, "error");
                        } else {
                            Swal(
                                "ERROR",
                                "Ha ocurrido un error, espere un momento y vuelva a intentar",
                                "error"
                            );
                        }
                    })
                    .then((data) => {
                        if (data) {
                            let data_movimiento = data;
                            const vuelto = data_movimiento.vuelto_efectivo
                                ? data_movimiento.vuelto_efectivo
                                : 0;
                            data_movimiento.monto_efectivo =
                                data_movimiento.monto_efectivo + vuelto;
                            dispatch(setMovimiento(data_movimiento));
                            dispatch(usuarioActions.getMe());
                            dispatch(cajaActions.get_ultimo_cierre());
                            api.get(endpoint_datos_impresion_venta, {
                                id: data.id_venta,
                            })
                                .catch((err) => {
                                    dispatch(setVentaImpresion(null));
                                })
                                .then((resp) => {
                                    if (resp) {
                                        let data_impresion = resp;
                                        let vuelto = 0;
                                        if (
                                            data_impresion.length === undefined
                                        ) {
                                            vuelto = data_impresion.vuelto_efectivo
                                                ? data_impresion.vuelto_efectivo
                                                : 0;
                                            data_impresion.monto_efectivo =
                                                data_impresion.monto_efectivo +
                                                vuelto;
                                        }
                                        dispatch(
                                            setVentaImpresion(data_impresion)
                                        );
                                        if (
                                            data_movimiento.tipo_pago ==
                                            pago_total
                                        ) {
                                            console.log("reciboVenta");
                                            dispatch(
                                                printActions.print(
                                                    "reciboVenta"
                                                )
                                            );
                                        } else
                                            dispatch(
                                                printActions.print(
                                                    "reciboVentaCredito"
                                                )
                                            );
                                    }
                                });
                        }
                    })
                    .finally(() => {
                        dispatch(setLoader(false));
                    });
            }
        });
};

export const consultarNotaCredito = (total) => (dispatch, getStore) => {
    const store = getStore();
    const {codigo_nota_credito} = store.venta;

    api.get("notas_credito_fel/consultar", {codigo_nota_credito})
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
            dispatch(setNotaCreditoCargada(null));
        })
        .then((resp) => {
            if (resp) {
                //  Validar si se agrega o no la nota de crédito por el monto total y el total de importe
                if (resp.total_importe > total) {
                    Swal(
                        "Info",
                        `No es posible agregar la nota de crédito porque el total de la venta (${total}) es menor al monto de la nota de crédito (${resp.total_importe})`,
                        "info"
                    );
                } else {
                    dispatch(setNotaCreditoCargada(resp));
                    dispatch(setNotaCredito(resp.total_importe, total));
                }
            } else {
                dispatch(setNotaCreditoCargada(null));
            }
        });
};

export const limpiarNotaCredito = () => (dispatch) => {
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
};

// ------------------------------------
// PureActions
// ------------------------------------

export const setMovimiento = (movimiento) => ({
    type: MOVIMIENTO,
    movimiento,
});

export const setProductos = (data) => ({
    type: PRODUCTOS,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setNitCliente = (nit_cliente) => ({
    type: SET_NIT_CLIENTE,
    nit_cliente,
});

export const setNombreCliente = (nombre_cliente) => ({
    type: SET_NOMBRE_CLIENTE,
    nombre_cliente,
});

export const setSeleccionados = (seleccionados) => ({
    type: SELECCIONADOS,
    seleccionados,
});

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.venta.page;
    dispatch(listar(page));
};

export const setVentaImpresion = (venta_impresion) => ({
    type: SET_IMPRESION,
    venta_impresion,
});
export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos,
});

export const setTipoPago = (tipo_pago) => ({
    type: SET_TIPO_PAGO,
    tipo_pago,
});

export const setFechaVencimientoCredito = (fecha_vencimiento) => ({
    type: SET_FECHA_VENCIMIENTO_CREDITO,
    fecha_vencimiento,
});
export const _setEfectivo = (efectivo) => ({
    type: SET_EFECTIVO,
    efectivo,
});
export const _setTarjeta = (tarjeta) => ({
    type: SET_TARJETA,
    tarjeta,
});
export const _setCheque = (cheque) => ({
    type: SET_CHEQUE,
    cheque,
});
export const _setDeposito = (deposito) => ({
    type: SET_DEPOSITO,
    deposito,
});
export const _setNotaCredito = (nota_credito) => ({
    type: SET_NOTA_CREDITO,
    nota_credito,
});
export const _setRetencionIVA = (retencion_iva) => ({
    type: SET_RETENCION_IVA,
    retencion_iva,
});
export const _setExencionIVA = (exencion_iva) => ({
    type: SET_EXENCION_IVA,
    exencion_iva,
});
export const setNota = (nota) => ({
    type: SET_NOTA,
    nota,
});
export const setCodigoNotaCredito = (codigo_nota_credito) => ({
    type: SET_CODIGO_NOTA_CREDITO,
    codigo_nota_credito,
});

export const setVerBuscador = (ver_buscador) => ({
    type: SET_VER_BUSCADOR,
    ver_buscador,
});
export const setVerCobro = (ver_cobro) => ({
    type: SET_VER_COBRO,
    ver_cobro,
});
export const setRedondeo = (redondeo) => ({
    type: SET_REDONDEO,
    redondeo,
});

export const setVentaCotizacion = (venta_cotizacion) => ({
    type: SET_VENTA_COTIZACION,
    venta_cotizacion,
});
export const setVerImpresion = (ver_impresion) => ({
    type: SET_VER_IMPRESION,
    ver_impresion,
});

export const setCotizacionCargada = (cotizacion_cargada) => ({
    type: SET_COTIZACION_CARGADA,
    cotizacion_cargada,
});
export const setNotaCreditoCargada = (nota_credito_cargada) => ({
    type: SET_NOTA_CREDITO_CARGADA,
    nota_credito_cargada,
});
export const setEstadoFormCliente = (estado_form_cliente) => ({
    type: SET_ESTADO_FORM_CLIENTE,
    estado_form_cliente,
});


const getFieldForm = (nameForm, field) => (dispatch, getStore) => {
    const form = getStore().form[nameForm].values
    const fieldForm = form[field];
    return fieldForm;
}

const setDataName = (data) => (dispatch, getState) => {
    let nameNew = data.first_name + " " + data.last_name;

    console.log("nameNew", nameNew);
    if (nameNew.trim() === "") {
        dispatch(changeForm("ClienteVentaForm", "nombre", ""));
    }
    else {
        dispatch(changeForm("ClienteVentaForm", "nombre", nameNew));
    }
};


export const actions = {
    listar,
    buscar,
    sortChange,
    setMovimiento,
    cambiarPrecio,
    cambiarPrecioEditable,
    agregarVale,
    agregar,
    quitar,
    agregarPrimer,
    setSeleccionados,
    pagar,
    restar,
    sumar,
    ingresoCantidad,
    cambiarDescuento,
    cambiarPrecioFinal,
    desplegarItem,
    resetVenta,
    setTipoPago,
    setFechaVencimientoCredito,
    setEfectivo,
    setTarjeta,
    setCheque,
    setDeposito,
    leerCliente,
    limpiarDatosCliente,
    changeFieldForm,
    changeDatosClientes,
    cambioBuscador,
    cambioCobro,
    asignarUnidadDeMedida,
    setRedondeo,
    leerClienteCotizacion,
    enviarCotizacion,
    finalizarCotizacion,
    pagarVentaCotizacon,
    actualizarCotizacion,
    setNota,
    setNotaCredito,
    setCodigoNotaCredito,
    consultarNotaCredito,
    limpiarNotaCredito,
    setRetencionIva,
    setExencionIva,
    setEstadoFormCliente,
    leerClienteNombre,
    getFieldForm,
    setDataName,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [MOVIMIENTO]: (state, {movimiento}) => {
        return {
            ...state,
            movimiento,
        };
    },
    [SELECCIONADOS]: (state, {seleccionados}) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [PRODUCTOS]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [BUSCADOR]: (state, {search}) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, {page}) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, {ordering}) => {
        return {
            ...state,
            ordering,
        };
    },
    [SET_NIT_CLIENTE]: (state, {nit_cliente}) => {
        return {
            ...state,
            nit_cliente,
        };
    },
    [SET_NOMBRE_CLIENTE]: (state, {nombre_cliente}) => {
        return {
            ...state,
            nombre_cliente,
        };
    },
    [SET_IMPRESION]: (state, {venta_impresion}) => {
        return {
            ...state,
            venta_impresion,
        };
    },
    [SET_TIPO_PAGO]: (state, {tipo_pago}) => {
        return {
            ...state,
            tipo_pago,
        };
    },
    [SET_FECHA_VENCIMIENTO_CREDITO]: (state, {fecha_vencimiento}) => {
        return {
            ...state,
            fecha_vencimiento,
        };
    },
    [SET_EFECTIVO]: (state, {efectivo}) => {
        return {
            ...state,
            efectivo,
        };
    },
    [SET_TARJETA]: (state, {tarjeta}) => {
        return {
            ...state,
            tarjeta,
        };
    },
    [SET_CHEQUE]: (state, {cheque}) => {
        return {
            ...state,
            cheque,
        };
    },
    [SET_DEPOSITO]: (state, {deposito}) => {
        return {
            ...state,
            deposito,
        };
    },
    [SET_RETENCION_IVA]: (state, {retencion_iva}) => {
        return {
            ...state,
            retencion_iva,
        };
    },
    [SET_EXENCION_IVA]: (state, {exencion_iva}) => {
        return {
            ...state,
            exencion_iva,
        };
    },
    [SET_NOTA_CREDITO]: (state, {nota_credito}) => {
        return {
            ...state,
            nota_credito,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, {uuid_req_productos}) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [SET_VER_BUSCADOR]: (state, {ver_buscador}) => {
        return {
            ...state,
            ver_buscador,
        };
    },
    [SET_VER_COBRO]: (state, {ver_cobro}) => {
        return {
            ...state,
            ver_cobro,
        };
    },
    [SET_REDONDEO]: (state, {redondeo}) => {
        return {
            ...state,
            redondeo,
        };
    },
    [SET_VENTA_COTIZACION]: (state, {venta_cotizacion}) => {
        return {
            ...state,
            venta_cotizacion,
        };
    },
    [SET_VER_IMPRESION]: (state, {ver_impresion}) => {
        return {
            ...state,
            ver_impresion,
        };
    },
    [SET_COTIZACION_CARGADA]: (state, {cotizacion_cargada}) => {
        return {
            ...state,
            cotizacion_cargada,
        };
    },
    [SET_NOTA]: (state, {nota}) => {
        return {
            ...state,
            nota,
        };
    },
    [SET_CODIGO_NOTA_CREDITO]: (state, {codigo_nota_credito}) => {
        return {
            ...state,
            codigo_nota_credito,
        };
    },
    [SET_NOTA_CREDITO_CARGADA]: (state, {nota_credito_cargada}) => {
        return {
            ...state,
            nota_credito_cargada,
        };
    },
    [SET_ESTADO_FORM_CLIENTE]: (state, {estado_form_cliente}) => {
        return {
            ...state,
            estado_form_cliente,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "",
    movimiento: {},
    seleccionados: [],
    nit_cliente: "",
    nombre_cliente: "",
    venta_impresion: null,
    tipo_pago: pago_total,
    fecha_vencimiento: moment().format("YYYY-MM-DD"),
    efectivo: "",
    tarjeta: "",
    cheque: "",
    deposito: "",
    nota_credito: "",
    uuid_req_productos: "",
    ver_buscador: false,
    ver_cobro: false,
    redondeo: null,
    venta_cotizacion: null,
    ver_impresion: false,
    cotizacion_cargada: null,
    nota: "",
    codigo_nota_credito: "",
    nota_credito_cargada: null,
    estado_form_cliente: true,
    retencion_iva: "",
    exencion_iva: "",
};

export default handleActions(reducers, initialState);
