import { connect } from 'react-redux';
import CrearOrdenCompra from "./CrearOrdenCompra";
import { actions, setVerBuscador } from "../../../../redux/modules/orden_compra/orden_compra"

const ms2p = (state) => {
    let total = 0;
    state.ordenes_compra.seleccionados.forEach((producto) => {
        const costo = producto.costo ? parseFloat(producto.costo) : 0;
        const cantidad = producto.cantidad ? parseFloat(producto.cantidad) : 0;
        total = total + (costo * cantidad);
    });
    return {
        sucursal: state.usuario.sucursal,
        ...state.ordenes_compra,
        pais: state.usuario.me.pais,
        conf_empresa: state.usuario,
        total,
    }
}

const md2p = {
    ...actions,
    setVerBuscador: (estado) => dispatch => dispatch(setVerBuscador(estado)),
};

export default connect(ms2p, md2p)(CrearOrdenCompra);
