import React from "react";
import {
    renderField,
    renderTextArea,
    renderSearchSelect,
    renderSelectField,
    renderCurrency,
} from "Utils/renderField/renderField";
import { Field, reduxForm } from "redux-form";
import { icons } from "icons";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import _ from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import {
    ListadoRetiros,
    MovimientoCaja,
} from "../../OrdenCompra/Detalle/ComponentesOrdenCompra";
import { api } from "api";
import {
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";

const listaSucursales = [];

const Form = (props) => {
    //STATE
    const {
        handleSubmit,
        tipo,
        sucursal,
        sucursales,
        tipoPagoValue,
        movimiento_caja,
        fecha_inicial,
        fecha_final,
        loader_retiros,
        page_retiros,
        data,
    } = props;

    //BINDS
    const {
        listarMovimientos,
        changeFecha,
        sortChange,
        setMovimientoCaja,
    } = props;

    const getSucursales = (search) => {
        return api
            .get("sucursales", { search })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaSucursales, { id: item.id }))
                        listaSucursales.push(item);
                });
                return { options: listaSucursales };
            });
    };

    let oficina = _.find(sucursales, { id: sucursal });
    if (!oficina) {
        oficina = {};
    }

    const formas_pago = [
        {
            id: 1,
            value: "EFE",
            label: "Efectivo",
        },
        {
            id: 2,
            value: "TAR",
            label: "Tarjeta",
        },
        {
            id: 3,
            value: "CHE",
            label: "Cheque",
        },
        {
            id: 4,
            value: "DEP",
            label: "Depósito",
        },
        {
            id: 5,
            value: "TRA",
            label: "Transferencia",
        },
        {
            id: 6,
            value: "TRE",
            label: "Transferencia electrónica",
        },
    ];

    const tipos_pago = [
        {
            id: 1,
            value: 5,
            label: "Seleccione forma de pago...",
        },
        {
            id: 2,
            value: 1000,
            label: "Caja",
        },
        {
            id: 3,
            value: 2000,
            label: "Externo",
        },
    ];

    return (
        <form onSubmit={handleSubmit}>
            <div className="formCrearGasto form-group">
                <div className="">
                    <div className="mt">
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 form-group">
                            <label htmlFor="gasto_fijo">Tipo de gasto</label>
                            <br />
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-row">
                                    <div className="radio c-radio c-radio-nofont d-flex">
                                        <label className="negro-dark2 font-weight-normal">
                                            <Field
                                                name="gasto_fijo"
                                                component="input"
                                                type="radio"
                                                value="true"
                                            />
                                            <span />
                                            Fijo
                                        </label>
                                    </div>
                                    <div className="radio c-radio c-radio-nofont d-flex">
                                        <label className="negro-dark2 font-weight-normal">
                                            <Field
                                                name="gasto_fijo"
                                                component="input"
                                                type="radio"
                                                value="false"
                                            />
                                            <span />
                                            Variable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="col-xl-11 col-lg-11 col-md-6 col-sm-12 negro-dark2">
                            <div className="d-flex flex-row">
                                <div className=" col-lg-3 col-md-3 col-sm-12 form-group">
                                    <img
                                        className="align-self-center mw-100"
                                        style={
                                            tipo === "false"
                                                ? { display: "inline-block" }
                                                : { display: "none" }
                                        }
                                        src={icons.gastos_variables}
                                        alt=""
                                    />
                                    <img
                                        className="align-self-center mw-100"
                                        style={
                                            tipo === "true"
                                                ? { display: "inline-block" }
                                                : { display: "none" }
                                        }
                                        src={icons.gastos_fijos}
                                        alt=""
                                    />
                                </div>
                                <div className=" col-lg-8 col-md-8 col-sm-12 form-group">
                                    <div
                                        style={
                                            tipo === undefined
                                                ? { display: "none" }
                                                : {}
                                        }
                                        className={`gasto_fijo ${
                                            tipo === "false" ? "collapsed" : ""
                                        }`}
                                    >
                                        <h4>Gastos fijos</h4>
                                        <p className="text-justify">
                                            Los gastos fijos están asociados con
                                            lo que Ud, tiene que pagar,
                                            independientemente del volumen que
                                            vende. <br />
                                            Los gastos fijos incluyen el
                                            alquiler, las nóminas, los seguros,
                                            los suministros (agua, luz,
                                            telefonía…) etc.
                                        </p>
                                    </div>
                                    <div
                                        style={
                                            tipo === undefined
                                                ? { display: "none" }
                                                : {}
                                        }
                                        className={`gasto_variable ${
                                            tipo === "true" ? "collapsed" : ""
                                        }`}
                                    >
                                        <h4>Gastos variables</h4>
                                        <p className="text-justify">
                                            Los gastos variables son los costos
                                            de mano de obra o el coste de los
                                            materiales, que cambian con las
                                            ventas. <br />
                                            Entre los gastos variables pueden
                                            darse: compra de insumos,
                                            imprevistos, viáticos, etc.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 form-group">
                            {/* INGORMACIÓN DE PAGO*/}
                            <label htmlFor="tipo_abono">Ejecutado en</label>
                            <div className="d-flex flex-row mb-2">
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <div
                                        className="d-flex flex-column pr-0"
                                        style={{ maxWidth: "650px" }}
                                    >
                                        <Field
                                            name="tipo_pago_gasto"
                                            component={renderSelectField}
                                            options={tipos_pago}
                                            className="form-control"
                                            item_vacio={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    {tipoPagoValue == 1000 && (
                                        <span className="ml-2 negro-dark2">
                                            Son todos los movimientos que se ha
                                            realizado en caja.
                                        </span>
                                    )}
                                    {tipoPagoValue == 2000 && (
                                        <span className="ml-2 negro-dark2">
                                            Son todos los movimientos que se ha
                                            realizado fuera de caja.
                                        </span>
                                    )}
                                </div>
                            </div>

                            {tipoPagoValue == 1000 &&
                                (movimiento_caja ? (
                                    <MovimientoCaja
                                        movimiento_caja={movimiento_caja}
                                        setMovimientoCaja={setMovimientoCaja}
                                    />
                                ) : (
                                    <ListadoRetiros
                                        fecha_inicial={fecha_inicial}
                                        fecha_final={fecha_final}
                                        loader={loader_retiros}
                                        data={data}
                                        page={page_retiros}
                                        changeFecha={changeFecha}
                                        listar={listarMovimientos}
                                        sortChange={sortChange}
                                        setMovimientoCaja={setMovimientoCaja}
                                    />
                                ))}
                        </div>
                        {/* FIN INGORMACIÓN DE PAGO*/}
                        {tipoPagoValue == 1000 && movimiento_caja && (
                            <div className="col-lg-11 row">
                                <div className="col-lg-5 col-md-5 col-sm-12 mb-4">
                                    <label className="label-view">
                                        Sucursal
                                    </label>
                                    <div className="input-view">
                                        {movimiento_caja.sucursal.nombre}
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-5 col-sm-12 mb-4">
                                    <label className="label-view">
                                        Nombre de la caja
                                    </label>
                                    <div className="input-view">
                                        {
                                            movimiento_caja.apertura_cierre_caja
                                                .caja.nombre
                                        }
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-5 col-sm-12 mb-4">
                                    <label className="label-view">Monto</label>
                                    <div className="input-view">
                                        <RenderCurrency
                                            value={movimiento_caja.total}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-5 col-sm-12 mb-4">
                                    <label className="label-view">Fecha</label>
                                    <div className="input-view">
                                        {movimiento_caja.creado}
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-5 col-sm-12 mb-4">
                                    <label className="label-view">
                                        No. documento
                                    </label>
                                    <div className="input-view">
                                        {movimiento_caja.num_documento ? (
                                            movimiento_caja.num_documento
                                        ) : (
                                            <b>----</b>
                                        )}
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-5 col-sm-12 mb-4">
                                    <label className="label-view">
                                        Concepto o descripción del gasto
                                    </label>
                                    <div className="input-view">
                                        {movimiento_caja.concepto}
                                    </div>
                                </div>
                            </div>
                        )}
                        {tipoPagoValue == 2000 && (
                            <div className="col-lg-11 row">
                                <div className="col-lg-5 col-md-5 col-sm-12 form-group mb-4">
                                    <label htmlFor="sucursal">Sucursal</label>
                                    <div
                                        className="d-flex flex-column pr-0"
                                        style={{ maxWidth: "650px" }}
                                    >
                                        <Field
                                            name="sucursal"
                                            component={renderSearchSelect}
                                            loadOptions={getSucursales}
                                            labelKey="nombre"
                                            valueKey="id"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-5 col-sm-12 form-group mb-4">
                                    <label htmlFor="forma_pago">
                                        Tipo de pago
                                    </label>
                                    <div
                                        className="d-flex flex-column pr-0"
                                        style={{ maxWidth: "650px" }}
                                    >
                                        <Field
                                            name="forma_pago"
                                            component={renderSelectField}
                                            options={formas_pago}
                                            className="form-control"
                                            item_vacio={false}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-5 col-sm-12 form-group mb-4">
                                    <label htmlFor="total">Monto*</label>
                                    <Field
                                        name="total"
                                        component={renderCurrency}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-lg-5 col-md-5 col-sm-12 form-group mb-4">
                                    <label htmlFor="num_documento">
                                        Número de documento relacionado al gasto
                                    </label>
                                    <Field
                                        name="num_documento"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-lg-8 col-md-8 col-sm-12 form-group mb-4">
                                    <label htmlFor="concepto">
                                        Concepto o descripción del gasto*
                                    </label>
                                    <Field
                                        name="concepto"
                                        component={renderTextArea}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <br />
                    <div className="d-flex justify-content-center flex-row">
                        <Link
                            className="btn btn-tertiary m-1 align-self-center"
                            to="/gastos"
                        >
                            <img src={icons.cancelar} alt="" />
                            Cancelar
                        </Link>
                        <button
                            type="submit"
                            disabled={
                                (tipoPagoValue == 1000 ||
                                    tipoPagoValue != 2000) &&
                                movimiento_caja == null
                            }
                            className="btn btn-primary m-1 align-self-center"
                        >
                            <img src={icons.save} alt="" />
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

let CrearGastoForm = reduxForm({
    // a unique name for the form
    form: "GastoCrear",
    validate: (data) => {
        return validate(data, {
            concepto: validators.exists()("Campo requerido"),
            total: validators.exists()("Campo requerido"),
            gasto_fijo: validators.exists()("Campo requerido"),
            sucursal: validators.exists()("Campo requerido"),
        });
    },
})(Form);

const selector = formValueSelector("GastoCrear");
CrearGastoForm = connect((state) => {
    const tipoPagoValue = selector(state, "tipo_pago_gasto");
    return {
        tipoPagoValue,
    };
})(CrearGastoForm);

export default CrearGastoForm;
