import React from "react";
import { icons } from "icons";
import { connect } from "react-redux";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { ListadoRetiros, MovimientoCaja } from "./ComponentesOrdenCompra";
import {
    renderField,
    renderCurrency,
    renderSelectField,
    renderSearchSelect,
} from "Utils/renderField/renderField";
import { api } from "api";

// VALIDACIONES CUSTOMS
const validate = (values, props) => {
    let errors = {};
    if (!values.tipo_abono) errors.tipo_abono = "Campo requerido";
    else {
        if (parseInt(values.tipo_abono) == 2000) {
            if (!values.forma_pago) errors.forma_pago = "Campo requerido";
            if (!values.monto) errors.monto = "Campo requerido";
        }
    }

    return errors;
};

const listaSucursales = [];

const Form = (props) => {
    // State
    const {
        pago_pendiente,
        saldo,
        data,
        tipoAbonoValue,
        loader,
        fecha_inicial,
        fecha_final,
        page,
        movimiento_caja,
    } = props;
    // Bind
    const {
        handleSubmit,
        close,
        sortChange,
        listarRetiros,
        changeFecha,
        setMovimientoCaja,
    } = props;

    const formas_pago = [
        {
            id: 1,
            value: "EFE",
            label: "Efectivo",
        },
        {
            id: 2,
            value: "TAR",
            label: "Tarjeta",
        },
        {
            id: 3,
            value: "CHE",
            label: "Cheque",
        },
        {
            id: 4,
            value: "DEP",
            label: "Depósito",
        },
        {
            id: 5,
            value: "TRA",
            label: "Transferencia",
        },
        {
            id: 6,
            value: "TRE",
            label: "Transferencia electrónica",
        },
    ];

    const getSucursales = (search) => {
        return api
            .get("sucursales", { search })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaSucursales, { id: item.id }))
                        listaSucursales.push(item);
                });
                return { options: listaSucursales };
            });
    };

    return (
        <form onSubmit={handleSubmit} className="w-100">
            <LoadMask loading={loader} light blur>
                <div className="row">
                    <div className="col-12 col-lg-8 form-group">
                        <label htmlFor="tipo_abono"></label>
                        <div className="d-flex flex-row mb-2">
                            <div className="mr-2 radio c-radio c-radio-nofont d-flex">
                                <label className="negro-dark2 font-weight-normal">
                                    <Field
                                        name="tipo_abono"
                                        component="input"
                                        type="radio"
                                        value="1000"
                                    />
                                    <span />
                                    Caja
                                </label>
                            </div>
                            <div className="ml-2 radio c-radio c-radio-nofont d-flex">
                                <label className="negro-dark2 font-weight-normal">
                                    <Field
                                        name="tipo_abono"
                                        component="input"
                                        type="radio"
                                        value="2000"
                                    />
                                    <span />
                                    Externo
                                </label>
                            </div>
                        </div>
                        {tipoAbonoValue == 1000 ? (
                            <span className="ml-2 negro-dark2">
                                Son todos los movimientos que se ha realizado en
                                caja
                            </span>
                        ) : (
                            <span className="ml-2 negro-dark2">
                                Son todos los movimientos que se ha realizado
                                fuera de caja
                            </span>
                        )}
                    </div>
                    <div className="col-12 pl-lg-0 col-lg-4">
                        <div className="form-group mb-0 mt-3">
                            <label htmlFor="">Monto total: </label>
                            <span className="ml-2">
                                <RenderCurrency value={saldo} />
                            </span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Pago pendiente:</label>
                            <span className="ml-2">
                                <RenderCurrency value={pago_pendiente} />
                            </span>
                        </div>
                    </div>
                    {tipoAbonoValue == 1000 ? (
                        movimiento_caja ? (
                            <MovimientoCaja
                                movimiento_caja={movimiento_caja}
                                setMovimientoCaja={setMovimientoCaja}
                            />
                        ) : (
                            <ListadoRetiros
                                fecha_inicial={fecha_inicial}
                                fecha_final={fecha_final}
                                loader={loader}
                                data={data}
                                page={page}
                                changeFecha={changeFecha}
                                listar={listarRetiros}
                                sortChange={sortChange}
                                setMovimientoCaja={setMovimientoCaja}
                            />
                        )
                    ) : (
                        <div className="w-100 row mx-lg-5 mt-4">
                            <div className="col-12 form-group">
                                <label htmlFor="sucursal">Sucursal</label>
                                <div
                                    className="d-flex flex-column pr-0"
                                    style={{ maxWidth: "650px" }}
                                >
                                    <Field
                                        name="sucursal"
                                        component={renderSearchSelect}
                                        loadOptions={getSucursales}
                                        labelKey="nombre"
                                        valueKey="id"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-12 form-group">
                                <label htmlFor="forma_pago">Tipo de pago</label>
                                <div
                                    className="d-flex flex-column pr-0"
                                    style={{ maxWidth: "650px" }}
                                >
                                    <Field
                                        name="forma_pago"
                                        component={renderSelectField}
                                        options={formas_pago}
                                        className="form-control"
                                        item_vacio={false}
                                    />
                                </div>
                            </div>
                            <div className="col-12 form-group">
                                <label htmlFor="monto">Monto</label>
                                <div
                                    className="d-flex flex-column pr-0"
                                    style={{ maxWidth: "650px" }}
                                >
                                    <Field
                                        name="monto"
                                        component={renderCurrency}
                                        decimalScale={2}
                                        type="number"
                                        className="form-control text-right"
                                    />
                                </div>
                            </div>
                            <div className="col-12 form-group">
                                <label htmlFor="num_documento">
                                    No. Documento
                                </label>
                                <div
                                    className="d-flex flex-column pr-0"
                                    style={{ maxWidth: "650px" }}
                                >
                                    <Field
                                        name="num_documento"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            {props.esOrdenDeCompra &&
                                <div className="flex-column-reverse col-12 form-group text-right">
                                    <label htmlFor="aplicar_descuento">
                                       Aplicar descuento
                                    </label>
                                    <br/>
                                    <label className="switch">
                                        <Field
                                            name="aplicar_descuento"
                                            component="input"
                                            type="checkbox"
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            }
                            {props.aplicarDescuento &&
                            <div className="col-12 form-group">
                                <label htmlFor="descuento">Monto de Descuento</label>
                                <div
                                    className="d-flex flex-column pr-0"
                                    style={{maxWidth: "650px"}}
                                >
                                    <Field
                                        name="descuento"
                                        component={renderCurrency}
                                        decimalScale={2}
                                        type="number"
                                        className="form-control text-right"
                                    />
                                </div>
                            </div>
                            }
                            {props.aplicarDescuento &&
                            <div className="col-12 form-group">
                                <label htmlFor="nota_de_descuento">Nota de credito</label>
                                <div
                                    className="d-flex flex-column pr-0"
                                    style={{maxWidth: "650px"}}
                                >
                                    <Field
                                        name="nota_de_descuento"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            }
                        </div>
                    )}

                    <div
                        className="d-flex justify-content-around align-items-center p-1 p-md-2 flex-column flex-3"
                        style={{ backgroundColor: "#FFF" }}
                    >
                        <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                            <button
                                type="button"
                                className="btn btn-tertiary mr-1"
                                onClick={close}
                            >
                                <img src={icons.cancelar} alt="" /> Cancelar
                            </button>
                            <button
                                disabled={
                                    tipoAbonoValue == 1000 &&
                                    movimiento_caja == null
                                }
                                className="btn btn-primary ml-1"
                                type="submit"
                            >
                                <img src={icons.save} alt="" />
                                Registrar
                            </button>
                        </div>
                    </div>
                </div>
            </LoadMask>
        </form>
    );
};

let PagoOrdenCompraForm = reduxForm({
    form: "PagoOrdenCompra",
    validate,
})(Form);

const selector = formValueSelector("PagoOrdenCompra");
PagoOrdenCompraForm = connect((state) => {
    const tipoAbonoValue = selector(state, "tipo_abono");
    const aplicarDescuento = selector(state, "aplicar_descuento");
    return {
        tipoAbonoValue,
        aplicarDescuento,
    };
})(PagoOrdenCompraForm);

export default PagoOrdenCompraForm;
