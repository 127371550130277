import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import {tableOptions} from "Utils/tableOptions";
import { actions } from "../../../../../redux/modules/venta/existencias_sucursal";
import {
    formatoProducto,
    formatoAvatar,
} from "Utils/CamposComunes";
import {
    RenderNumber
} from "Utils/renderField/renderReadField"

const mapStateToProps = (state)=> {
    return {
        ...state.existencias_sucursal
    };
}

const mapDispatchToProps = {...actions};

class ExistenciaSucursal extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        buscar: PropTypes.func.isRequired,
        // sortChange: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props)
    }
    componentWillMount() {
        // this.props.listar(this.props.page);
        this.props.buscar(this.props.search_venta);
    }
    render() {
        // state
        const { data, loader, search } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        // options.onSortChange = this.props.sortChange;

        return (
            <div className="w-100">
                {/* <Link className="btn btn-primary" to="/gastos/registrar/"><em className="fa fa-plus"></em> Agregar un Gasto</Link> */}
                <div className="grid-container" style={{borderRadius: "0", border: "0"}}>
                    <div className="grid-titulo">
                        <Toolbar titulo={"Búsqueda de producto"} buscar={this.props.buscar} search={search} />
                    </div>
                    <LoadMask loading={loader} dark blur>
                        <BootstrapTable
                            data={loader ? [] : data.results}
                            remote pagination hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            bodyContainerClass='r-encajar-listado-productos'
                        >
                            <TableHeaderColumn dataField='producto__img_cropped' width="80px" dataAlign="center" dataFormat={formatoAvatar} ></TableHeaderColumn>
                            <TableHeaderColumn dataField='producto__nombre' dataFormat={formatoProducto} >Nombre</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' dataAlign="right"
                                isKey
                                dataFormat={(cell, row) => <div>
                                    {row.producto.a_granel ? <RenderNumber value={row.existencias} decimalScale={3} />
                                        : <RenderNumber value={row.existencias}/>}
                                    </div>}>
                                Cantidad en existencias
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='sucursal__nombre' dataFormat={(cell, row) => <div>{row.sucursal.nombre}</div>} >Sucursal</TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default connect( mapStateToProps,mapDispatchToProps)(ExistenciaSucursal);
