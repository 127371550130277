import React from "react";
import { Field, reduxForm } from "redux-form";
import NumberFormat from 'react-number-format';

const Form = (props) => {
    const { sucursales, metodos_entrega, metodos_pago } = props;
    return (
        <form>
            <div className="container-metodo">
                <label htmlFor="metodo_pago">Método de pago</label>
                <div className="d-flex flex-wrap flex-lg-row justify-content-start">
                    {
                        metodos_pago.map((item) => {
                            return(
                                <div key={item.id} className="radio c-radio c-radio-nofont">
                                    <label className="d-flex negro-dark2 font-weight-normal ml-3 mr-4">
                                        <Field
                                            name="metodo_pago"
                                            component="input"
                                            type="radio"
                                            value={item.prefijo}
                                        />
                                        <span/>
                                        <div className="d-flex flex-column justify-content-start ">
                                            <div><b>{item.nombre}</b></div>
                                            <div>{item.descripcion}</div>
                                        </div>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="container-metodo">
                <label htmlFor="metodo_entrega">Método de entrega</label>
                <div className="d-flex flex-wrap flex-lg-row justify-content-start">
                    {
                        metodos_entrega.map((item) => {
                            return(
                                <div key={item.id} className="radio c-radio c-radio-nofont">
                                    <label className="d-flex negro-dark2 font-weight-normal ml-3 mr-4">
                                        <Field
                                            name="metodo_entrega"
                                            component="input"
                                            type="radio"
                                            value={item.nombre}
                                        />
                                        <span />
                                        <div className="d-flex flex-column justify-content-start ">
                                        <div>&nbsp;{item.nombre}</div>
                                            <div style={{ color: "blue" }}>
                                                &nbsp;
                                                <NumberFormat style={{width: "auto", height: "auto", border: "0", borderRadius: "0", margin: "0"}}
                                                    decimalScale={2} fixedDecimalScale={true}
                                                    value={item.precio_empresa} thousandSeparator={true} prefix={"Q "} displayType={"text"}
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </form>
    )
}

const SeleccionMetodosForm = reduxForm({
    form: "SeleccionMetodosForm"
})(Form);

export default SeleccionMetodosForm;
