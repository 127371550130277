import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import {initialize as initializeForm} from 'redux-form'
import {ToastStore} from 'react-toasts';
import Swal from 'sweetalert2';
import { getMe } from "../usuario/usuario";
import { api } from 'api';

// ------------------------------------
// Constants
// ------------------------------------
export const SUCURSALES = "SUCURSAL_SUCURSALES";
export const SUCURSAL = "SUCURSAL_SUCURSAL";
export const PAGE = "SUCURSAL_PAGE";
export const BUSCADOR = "SUCURSAL_BUSCADOR";
export const SORT = "SUCURSAL_SORT";
export const LOADER = "SUCURSAL_LOADER";

export const constants = {
};

const endpoint = "sucursales";
// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const sucursal = store.sucursal;
    const {ordering, search} = sucursal;
    api.get(endpoint, {page, ordering, search}).catch((err) => {}).then((data) => {
        if (data) {
            dispatch(setSucursales(data));
        }
        dispatch(setPage(page));
        dispatch(setLoader(false));
    });
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === 'asc') {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const crear = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const data = store.form.SucursalCrear.values;
    api.post(endpoint, data).catch((err) => {
        Swal(
            'ERROR',
            'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
            'error'
        );
    }).then((resp) => {
        if (resp){
            ToastStore.success("Datos almacenados correctamente");
            dispatch(getMe());
            dispatch(push('/configuracion/sucursales'));
       }
       dispatch(setLoader(false));
    });
};

export const leer = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).catch((err) => {
        Swal(
            'ERROR',
            'Ha ocurrido un error.',
            'error'
        );
    }).then((resp) => {
        if (resp){
            dispatch(setSucursal(resp));
            dispatch(initializeForm('SucursalEditar', resp));
       }
       dispatch(setLoader(false));
    });
};

export const editar = () => (dispatch, getStore) => {
    const store = getStore();
    const data = store.form.SucursalEditar.values;
    dispatch(setLoader(true));
    api.put(`${endpoint}/${data.id}`, data).catch((err) => {
        Swal(
            'ERROR',
            'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
            'error'
        );
    }).then((resp) => {
        if (resp){
            ToastStore.success("Datos almacenados correctamente");
            dispatch(getMe());
            dispatch(push('/configuracion/sucursales'));
       }
       dispatch(setLoader(false));
    });
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).catch((error) =>{
        dispatch(setLoader(false));
        Swal(
            'ERROR',
            'Ha ocurrido un error, verifique que no hay usuarios asignados a la sucursal y vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        dispatch(setLoader(false));
        if (data){
            ToastStore.success("Registro eliminado.");
            dispatch(listar());
        }
    });
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setSucursales = data => ({
    type: SUCURSALES,
    data,
});
export const setPage = page => ({
    type: PAGE,
    page,
});
export const setBuscador = search => ({
    type: BUSCADOR,
    search,
});
export const setSort = ordering => ({
    type: SORT,
    ordering,
});
export const setLoader = loader => ({
    type: LOADER,
    loader,
});
export const setSucursal = sucursal => ({
    type: SUCURSAL,
    sucursal,
});


export const actions = {
    listar,
    buscar,
    sortChange,
    eliminar,
    crear,
    editar,
    leer,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [SUCURSALES]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SUCURSAL]: (state, { sucursal }) => {
        return {
            ...state,
            sucursal,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    sucursal: {},
    page: 1,
    search: '',
    ordering: '',
    loader: false,
};

export default handleActions(reducers, initialState);
