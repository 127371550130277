import _ from 'lodash';
import { icons } from "icons";
import React, { Component, Fragment } from 'react';
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { RenderDate } from "Utils/renderField/renderReadField"
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class ListadoOrdenOnline extends Component {

    constructor(props) {
        super(props)
        this.state = {
            intervalListar: null
        }
    }

    componentDidMount() {
        this.props.listar(this.props.page);
        //  Interval para actualizar el listado de ordenes a cada 30 segundos
        let intervalListar = setInterval(this.listarOrdenes.bind(this), 30000)
        this.setState({intervalListar})
    }
    componentWillUnmount() {
        //  Se limpia el interval para que ya no siga actualizando
        clearInterval(this.state.intervalListar);
    }

    listarOrdenes () {
        this.props.listar(this.props.page);
    }

    imageFormatter(cell, row){
        if (cell===true)
            return (<img src={icons.check}  style={{width:"20px"}} />);
        else (cell===false)
            return (<img src={icons.reloj}  style={{width:"20px"}} />);
    }

    formaPago(cell, row){

        let forma_pago = row.forma_pago

        if (forma_pago==="EFE"){
            return (<div>Efectivo</div>);
        }else if (forma_pago==="TAR"){
            return (<div>Tarjeta</div>);
        }else if (forma_pago==="CHE"){
            return (<div>Cheque</div>);
        }else if (forma_pago==="DEP"){
            return (<div>Deposito</div>);
        }else if (forma_pago==="TRA"){
            return (<div>Transferencia</div>);
        }else if (forma_pago==="TRE"){
            return (<div>Transferencia electronica</div>);
        }else if (forma_pago==="PCE"){
            return (<div>Pago contra entrega</div>);
        }else if (forma_pago==="PT"){
            return (<div>Pago en tienda</div>);
        }else {
        return (<div>{forma_pago}</div>)
        }
    }

    render() {

        // state
        const { data, loader, search, buscar, sucursal, estado, config } = this.props;
        // Bind
        const { anular, estadoChange } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar titulo={"Ordenes online"} buscar={buscar}  search={search}/>
                            <div className="padding-10 row">
                                <div className="col-12 fnt-16 text-left d-flex justify-content-between text-left">
                                    <div>
                                        <label className="font-weight-normal" htmlFor="estado">Estado</label>
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row">
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input name="estado" type="radio" value="true" checked={estado === true} onChange={()=>{
                                                            estadoChange(true)
                                                        }}/>
                                                        <span />
                                                        Activo
                                                    </label>
                                                </div>
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input name="estado"  type="radio" value="false" checked={estado === false} onChange={()=>{
                                                            estadoChange(false)
                                                        }}/>
                                                        <span />
                                                        Anulado
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <a href={`${config.url_tienda}/tienda/${config.slug}`} target="_blank" className="btn btn-outline-secondary">Ver tienda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote pagination hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField='creado'
                                    dataFormat={(cell) => <RenderDate value={cell}/>}
                                    dataSort
                                    >Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='sucursal__nombre'
                                    dataFormat={(cell, row) => <div>{row.sucursal ? <span>{row.sucursal.nombre}</span>:<span className="naranja font-weight-bold">Sin asignar</span>}</div>}
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    >Surcursal destino
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='codigo'
                                    >Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='nombre'
                                    >Cliente
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='forma_pago'
                                    dataFormat={this.formaPago}
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    >Metodo de pago
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='metodo_envio'
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    >Metodo de envio
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='enviada'
                                    dataFormat={this.imageFormatter}
                                    dataAlign="center"
                                    >Entregado
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='pagada'
                                    dataFormat={this.imageFormatter}
                                    dataAlign="center"
                                    >Pagado
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='id'
                                    isKey
                                    dataAlign="center"
                                    width={"120px"}
                                    dataFormat={
                                        (cell, row) => {
                                            if (estado) {
                                                return activeFormatter({
                                                    ver: "/ordenes_online/detalle",
                                                    editar: '/ordenes_online/editar',
                                                    editar_activo: (row.enviada == true || row.pagada == true || row.anulado == true || row.sucursal == null) ? false: true,
                                                    anular,
                                                    anular_activo: (row.enviada == true || row.pagada == true || row.anulado == true || row.sucursal == null) ? false: true
                                                })(cell, row)
                                            }else {
                                                return activeFormatter({
                                                    ver: "/ordenes_online/detalle"
                                                })(cell, row)
                                            }
                                        }
                                    }
                                    >Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>

                </div>
            </div>
        )
    }
}

export default ListadoOrdenOnline
