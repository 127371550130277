import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes/balance_general";
import Resumen from './Resumen';


const ms2p = (state) => {
    return {
        ...state.balance_general,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Resumen);
