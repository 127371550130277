import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import Toolbar from "Utils/Toolbar/Toolbar"
import LoadMask from "Utils/LoadMask/LoadMask"
import { tableOptions } from "Utils/tableOptions"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { TablaOC } from "./ComponentesListadoOC";
import moment from "moment";
import DatePicker from "Utils/DatePicker";
import {anularODC} from "../../../../redux/modules/orden_compra/orden_compra";

class ListadoOrdenCompra extends Component {
    static propTypes = {
    }
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.props.listarOC(this.props.page_oc);
    }

    render() {
        // State
        const { tab, datos_listado, loader, search_oc } = this.props;
        // Bind
        const {
            changeTab,
            listarOC,
            sortChangeOC,
            buscarOC,
            setFecha,
            setFechaOCF,
            estado_descarga,
            estado_descarga2,
            descargarListadoOCA,
            descargarListadoOCF,
            fecha_compra_final,
            fecha_compra_inicial,
            fecha_final_compra_finalizada,
            fecha_inicial_compra_finalizada,
        } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = this.props.page_oc;
        options.onPageChange = listarOC;
        options.onSortChange = sortChangeOC;


        return (
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Link className="btn btn-primary" to="/ordenes_de_compra/nueva/"><em className="fa fa-plus"></em> Agregar Orden Compra</Link>
                    <Tabs className="mt-2 content-tabs" selectedIndex={tab == 10 ? 0 : 1} onSelect={tabIndex => changeTab(tabIndex == 0 ? 10 : 20)}>
                        <TabList className="tab_list d-flex flex-row justify-content-between">
                            <Tab className="content-tab-venta flex1 text-center fnt-14" style={{ marginBottom: "0px" }} selectedClassName="content-tab-venta-active">ORDENES DE COMPRA ACTIVAS</Tab>
                            <Tab className="content-tab-venta flex1 text-center fnt-14" style={{ marginBottom: "0px" }} selectedClassName="content-tab-venta-active">ORDENES DE COMPRA FINALIZADAS</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="grid-container">
                                <div className="grid-titulo">
                                    <Toolbar titulo={"Listado"} buscar={buscarOC} search={search_oc} />
                                </div>
                                <div className="row py-2 px-4 justify-content-between">
                                    <div className="row col-8 col-sm-12 col-md-10">
                                        <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                                            <label htmlFor="">
                                                Fecha inicial
                                            </label>
                                            <DatePicker
                                                value={
                                                    fecha_compra_inicial
                                                }
                                                maxDate={
                                                    new Date(moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD"))
                                                }
                                                onChange={(date) => {
                                                    setFecha("Inicial", date);
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                                            <label htmlFor="">
                                                Fecha final
                                            </label>
                                            <DatePicker
                                                value={fecha_compra_final}
                                                minDate={
                                                    new Date(moment(fecha_compra_inicial, "YYYY-MM-DD"))
                                                }
                                                onChange={(date) => {
                                                    setFecha("Final", date);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListadoOCA();
                                        }}
                                    >
                                        <em className={`fa ${estado_descarga ? "fa-spinner fa-pulse" : "fa-download"}`}/>
                                        &nbsp;Descargar Listado
                                    </button>
                                </div>
                                <LoadMask loading={loader} dark blur>
                                    <TablaOC loader={loader} datos_listado={datos_listado} options={options} anular={this.props.anularODC}/>
                                </LoadMask>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="grid-container">
                                <div className="grid-titulo">
                                    <Toolbar titulo={"Listado"} buscar={buscarOC} search={search_oc}/>
                                </div>
                                <div className="row py-2 px-4 justify-content-between">
                                    <div className="row col-8 col-sm-12 col-md-10">
                                        <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                                            <label htmlFor="">
                                                Fecha inicial
                                            </label>
                                            <DatePicker
                                                value={
                                                    fecha_inicial_compra_finalizada
                                                }
                                                maxDate={
                                                    new Date(moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD"))
                                                }
                                                onChange={(date) => {
                                                    setFechaOCF("Inicial", date);
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                                            <label htmlFor="">
                                                Fecha final
                                            </label>
                                            <DatePicker
                                                value={fecha_final_compra_finalizada}
                                                minDate={
                                                    new Date(moment(fecha_inicial_compra_finalizada, "YYYY-MM-DD"))
                                                }
                                                onChange={(date) => {
                                                    setFechaOCF("Final", date);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga2}
                                        onClick={() => {
                                            descargarListadoOCF();
                                        }}
                                    >
                                        <em className={`fa ${estado_descarga2 ? "fa-spinner fa-pulse" : "fa-download"}`}/>
                                        &nbsp;Descargar Listado
                                    </button>
                                </div>
                                <LoadMask loading={loader} dark blur>
                                    <TablaOC loader={loader} datos_listado={datos_listado} options={options} anular={this.props.anularODC}/>
                                </LoadMask>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default ListadoOrdenCompra;
