import { handleActions } from 'redux-actions';
import { api } from 'api';

// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

// ------------------------------------
// PureActions
// ------------------------------------

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {};

export default handleActions(reducers, initialState);
