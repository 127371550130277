import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/despacho/recepcion_despacho";
import { print } from '../../../../../redux/modules/print/print'
import NotaRecepcionDespacho from './NotaRecepcionDespacho';


const ms2p = (state) => {
    return {
        ...state.recepcion_despacho,
        print_state: state.print,
    };
};

const md2p = {
    ...actions,
    print: (name) => dispatch => dispatch(print(name)),
};

export default connect(ms2p, md2p)(NotaRecepcionDespacho);
