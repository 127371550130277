import Caja from "./Caja";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { setNavbarCollapse } from "../../../../redux/modules/dashboard/dashboard";
import { actions } from "../../../../redux/modules/punto_venta/caja";
import { print } from "../../../../redux/modules/print/print";

const ms2p = (state) => {
    return {
        ...state.punto_venta_caja,
        caja: state.caja.ultimo_cierre,
        sucursal: state.usuario.sucursal,
        loader_usuario: state.usuario.loader,
        print_state: state.print,
    };
};

const md2p = {
    ...actions,
    setNavbarCollapse: (estado) => (dispatch) =>
        dispatch(setNavbarCollapse(estado)),
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(ms2p, md2p)(Caja);
