import { api } from "api";
import moment from "moment";
import PropTypes from "prop-types";
import { Async } from "react-select";
import React, { Component } from "react";
import DatePicker from "Utils/DatePicker";
import LoadMask from "Utils/LoadMask/LoadMask";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import ResumenCuentaCobrar from "../Resumen";

class ListadoCuentaCobrar extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.listar(this.props.page);
    }

    componentDidMount() {}

    render() {
        // state
        const {
            loader,
            data,
            marcar_entrega,
            filtro,
            total,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,
            estado_descarga,
        } = this.props;
        // bind
        const { filtroChange, setFecha, descargarListado } = this.props;

        const getCliente = (search) => {
            return api
                .get("clientes", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data.results };
                });
        };

        //  OPCIONES
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        return (
            <div>
                <div className="row">
                    {/* RESUMEN */}
                    <div className="col-12">
                        <ResumenCuentaCobrar />
                    </div>
                    {/* RESUMEN FIN */}
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div style={{ flex: "1" }}>
                                    <h3 className="negro-dark2">
                                        <b>Listado de créditos</b>
                                    </h3>
                                </div>
                                <div
                                    className="d-flex flex-wrap flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                                        <label>Cliente</label>
                                        <Async
                                            className="form-control"
                                            labelKey="nombre"
                                            valueKey="id"
                                            searchPromptText="Escriba para buscar"
                                            loadOptions={getCliente}
                                            placeholder={"Cliente"}
                                            onChange={(e) => {
                                                this.props.setCliente(e);
                                            }}
                                            value={this.props.cliente}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-5 col-md-5 form-group pl-0">
                                        <label htmlFor="filtro">
                                            Filtrar por
                                        </label>
                                        <div
                                            className="d-flex flex-column flex-sm-row flex-lg-row"
                                            style={{ marginTop: "7px" }}
                                        >
                                            <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <input
                                                        name="filtro"
                                                        type="radio"
                                                        value=""
                                                        checked={filtro === ""}
                                                        onChange={() => {
                                                            filtroChange("");
                                                        }}
                                                    />
                                                    <span />
                                                    Todos
                                                </label>
                                            </div>
                                            <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <input
                                                        name="filtro"
                                                        type="radio"
                                                        value=""
                                                        checked={
                                                            filtro ===
                                                            "vencidos"
                                                        }
                                                        onChange={() => {
                                                            filtroChange(
                                                                "vencidos"
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Créditos vencidos
                                                </label>
                                            </div>
                                            <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <input
                                                        name="filtro"
                                                        type="radio"
                                                        value=""
                                                        checked={
                                                            filtro ===
                                                            "por_vencer"
                                                        }
                                                        onChange={() => {
                                                            filtroChange(
                                                                "por_vencer"
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Créditos por vencer
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {filtro === "" && (
                                        <div className="col-12 col-lg-2 col-md-2 form-group pl-0">
                                            <label htmlFor="">
                                                Fecha inicial
                                            </label>
                                            <DatePicker
                                                value={
                                                    fecha_vencimiento_inicial
                                                }
                                                maxDate={
                                                    new Date(
                                                        moment(
                                                            moment().format(
                                                                "YYYY-MM-DD"
                                                            ),
                                                            "YYYY-MM-DD"
                                                        )
                                                    )
                                                }
                                                onChange={(date) => {
                                                    setFecha("Inicial", date);
                                                }}
                                            />
                                        </div>
                                    )}
                                    {filtro === "" && (
                                        <div className="col-12 col-lg-2 col-md-2 form-group pl-0">
                                            <label htmlFor="">
                                                Fecha final
                                            </label>
                                            <DatePicker
                                                value={fecha_vencimiento_final}
                                                minDate={
                                                    new Date(
                                                        moment(
                                                            fecha_vencimiento_inicial,
                                                            "YYYY-MM-DD"
                                                        )
                                                    )
                                                }
                                                onChange={(date) => {
                                                    setFecha("Final", date);
                                                }}
                                            />
                                        </div>
                                    )}
                                    {filtro !== "" && (
                                        <div className="col-12 col-lg-2 col-md-2 form-group pl-0">
                                            <label htmlFor="">Fecha</label>
                                            <DatePicker
                                                disabled={true}
                                                value={moment().format(
                                                    "YYYY-MM-DD"
                                                )}
                                                onChange={(date) => {}}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="content-total-reports flex-column flex-md-row flex-lg-row align-items-end justify-content-start justify-content-md-between justify-content-lg-between w-100 mt-10 py-1 py-md-2 py-lg-2">
                                    <button
                                        className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em
                                            className={`fa ${
                                                estado_descarga
                                                    ? "fa-spinner fa-pulse"
                                                    : "fa-download"
                                            }`}
                                        ></em>
                                        &nbsp;Descargar Listado
                                    </button>
                                    <div className="d-flex flex-column flex-md-row flex-lg-row align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
                                        <h3 className="padding-10 margin-0 negro-dark2">
                                            <b>Monto total</b>
                                        </h3>
                                        <h3 className="padding-10 margin-0 negro-dark2">
                                            <b>
                                                <RenderMoneda monto={total} />
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <LoadMask loading={loader} light blur>
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="fecha_vencimiento"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderDateTime fecha={cell} />
                                            );
                                        }}
                                    >
                                        Fecha vencimiento
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cliente_proveedor__nombre"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => (
                                            <div>
                                                {row.cliente_proveedor.nombre}
                                            </div>
                                        )}
                                        dataSort
                                    >
                                        Nombre
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="movimiento__venta__codigo"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <div>
                                                    {row.venta_data
                                                        ? row.venta_data.codigo
                                                        : "----"}
                                                </div>
                                            );
                                        }}
                                    >
                                        Código de venta
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        width="8%"
                                        dataField="movimiento__anulado"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <div>
                                                    {row.movimiento.anulado ? (
                                                        <b className="text-danger">
                                                            Anulado
                                                        </b>
                                                    ) : (
                                                        <b className="text-success">
                                                            Activo
                                                        </b>
                                                    )}
                                                </div>
                                            );
                                        }}
                                    >
                                        Estado
                                    </TableHeaderColumn>
                                    {marcar_entrega && (
                                        <TableHeaderColumn
                                            dataField="entrego"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => (
                                                <div
                                                    className={
                                                        row.venta_data.entregado
                                                            ? "text-success"
                                                            : "text-danger"
                                                    }
                                                >
                                                    {row.venta_data.entregado
                                                        ? "Entregado"
                                                        : "Pendiente"}
                                                </div>
                                            )}
                                        >
                                            Pedido entregado
                                        </TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        dataField="saldo"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Monto pendiente
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="total"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Monto total
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        dataField="id"
                                        width="10%"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        isKey
                                        dataAlign="center"
                                        dataFormat={(cell, row) => {
                                            return activeFormatter({
                                                ver:
                                                    "/reporte_cuenta_por_cobrar",
                                            })(cell, row);
                                        }}
                                    >
                                        Acciones
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ListadoCuentaCobrar.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoCuentaCobrar;
