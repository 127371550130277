import React from "react";
import { icons } from "icons";
import { Link } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { connect } from "react-redux";
import {
    renderField,
    renderCurrency,
    renderPorcentaje,
} from "Utils/renderField/renderField";

let Form = (props) => {
    const { handleSubmit, tipoValue } = props;
    return (
        <form onSubmit={handleSubmit} className="row mt">
            <div className="col-sm-12">
                <div className="form-group">
                    <div className="padding-15 p-sm-0 pt-sm-1">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-12 form-group">
                                <label htmlFor="nombre">Nombre *</label>
                                <Field
                                    name="nombre"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 form-group">
                                <label htmlFor="nombre">Tipo *</label>
                                <br />
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-row">
                                        <div className="radio c-radio c-radio-nofont d-flex">
                                            <label className="negro-dark2 font-weight-normal">
                                                <Field
                                                    name="tipo"
                                                    component="input"
                                                    type="radio"
                                                    value="10"
                                                />
                                                <span />
                                                Monto fijo
                                            </label>
                                        </div>
                                        <div className="radio c-radio c-radio-nofont d-flex">
                                            <label className="negro-dark2 font-weight-normal">
                                                <Field
                                                    name="tipo"
                                                    component="input"
                                                    type="radio"
                                                    value="20"
                                                />
                                                <span />
                                                Porcentaje sobre el total de la
                                                factura
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-12 form-group">
                                <label htmlFor="nombre">Valor *</label>
                                <Field
                                    name="valor"
                                    component={
                                        tipoValue == "20"
                                            ? renderPorcentaje
                                            : renderCurrency
                                    }
                                    type="number"
                                    className="form-control text-right"
                                />
                            </div>
                        </div>
                        <br />
                        <div className="d-flex justify-content-center flex-row">
                            <Link
                                className="btn btn-tertiary m-1 align-self-center"
                                to="/configuracion/adicionales_nota_fel"
                            >
                                <img src={icons.cancelar} alt="" />
                                Cancelar
                            </Link>
                            <button
                                type="submit"
                                className="btn btn-primary m-1 align-self-center"
                            >
                                <img src={icons.save} alt="" />
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

let AdicionalNotaFelCreateForm = reduxForm({
    form: "AdicionalNotaFelCreateForm",
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Campo requerido"),
            tipo: validators.exists()("Campo requerido"),
            valor: validators.exists()("Campo requerido"),
        });
    },
})(Form);
const selector = formValueSelector("AdicionalNotaFelCreateForm");
AdicionalNotaFelCreateForm = connect((state) => {
    const tipoValue = selector(state, "tipo");
    return {
        tipoValue,
    };
})(AdicionalNotaFelCreateForm);

export default AdicionalNotaFelCreateForm;
