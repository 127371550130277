import { connect } from 'react-redux';

import Perfil from './Perfil';
import {editarPerfil, guardar, cambiarContrasena} from "../../../redux/modules/perfil"
// import usuario from '../../../redux/modules/usuario/usuario';

const ms2p = (state) => ({
    usuario: state.usuario.me
})

const md2p = {
    editarPerfil: () => dispatch => dispatch(editarPerfil()),
    guardar: (fotos, cambioEdicion) => dispatch => dispatch(guardar(fotos, cambioEdicion)),
    actualizarContrasena: (cambioEdicionContra) => dispatch => dispatch(cambiarContrasena(cambioEdicionContra))
};

export default connect(ms2p, md2p)(Perfil);
