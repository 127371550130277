import { connect } from "react-redux";
import DestinoCrear from "./DestinoCrear";
import { crear } from "../../../../../redux/modules/destino_salida_producto/destino_salida_producto";

const mapStateToProps = (state) => ({
    loader: state.destino_salida_producto.loader,
});

const mapDispatchToProps = {
    crear,
};

export default connect(mapStateToProps, mapDispatchToProps)(DestinoCrear);
