import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import Toolbar from "Utils/Toolbar/Toolbar";
import { icons } from "icons";
import {
    RenderImage,
    RenderDateTime,
    RenderMoneda,
    RenderNumber,
} from "Utils/renderField/renderTableField";
import {
    DropDownMenu,
    DropDownDirection,
    ActionItem,
    HeaderItem,
} from "react-dropdown-advanced";
import ModalAyuda from "Utils/Ayuda";


class Apertura extends Component {
    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = {
            dropdownItems: [
                new HeaderItem("¿Con qué necesitas ayuda?"),
                new ActionItem(
                    "ingreso",
                    "¿Cómo realizar un ingreso de mercadería?"
                ),
                new ActionItem("baja", "¿Cómo realizar un reporte de baja?"),
                new ActionItem("reajuste", "¿Cómo realizar un reajuste?"),
            ],
            modalAyuda: false,
            itemsImagenes: [],
            itemsBodegaIngreso: [
                {
                    src: icons.bodega_ingreso_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.bodega_ingreso_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.bodega_ingreso_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.bodega_ingreso_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.bodega_ingreso_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.bodega_ingreso_p6,
                    alt: "Paso 6",
                },
                {
                    src: icons.bodega_ingreso_p7,
                    alt: "Paso 7",
                },
                {
                    src: icons.bodega_ingreso_p8,
                    alt: "Paso 8",
                },
                {
                    src: icons.bodega_ingreso_p9,
                    alt: "Paso 9",
                },
                {
                    src: icons.bodega_ingreso_p10,
                    alt: "Paso 10",
                },
                {
                    src: icons.bodega_ingreso_p11,
                    alt: "Paso 11",
                },
                {
                    src: icons.bodega_ingreso_p12,
                    alt: "Paso 12",
                },
            ],
            itemsBodegaBaja: [
                {
                    src: icons.bodega_baja_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.bodega_baja_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.bodega_baja_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.bodega_baja_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.bodega_baja_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.bodega_baja_p6,
                    alt: "Paso 6",
                },
                {
                    src: icons.bodega_baja_p7,
                    alt: "Paso 7",
                },
                {
                    src: icons.bodega_baja_p8,
                    alt: "Paso 8",
                },
            ],
            itemsBodegaReajuste: [
                {
                    src: icons.bodega_reajuste_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.bodega_reajuste_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.bodega_reajuste_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.bodega_reajuste_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.bodega_reajuste_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.bodega_reajuste_p6,
                    alt: "Paso 6",
                },
                {
                    src: icons.bodega_reajuste_p7,
                    alt: "Paso 7",
                },
                {
                    src: icons.bodega_reajuste_p8,
                    alt: "Paso 8",
                },
            ],
        };
    }
    componentWillMount() {
        this.props.setSeleccionados([]);
        this.props.listar(this.props.page);
    }

    onClickAyuda = (item) => {
        if (item.key === "ingreso") {
            this.setState({
                itemsImagenes: this.state.itemsBodegaIngreso,
            });
            this.openModal();
        } else if (item.key === "baja") {
            this.setState({
                itemsImagenes: this.state.itemsBodegaBaja,
            });
            this.openModal();
        } else if (item.key === "reajuste") {
            this.setState({
                itemsImagenes: this.state.itemsBodegaReajuste,
            });
            this.openModal();
        }
    };

    closeModal = () => {
        this.setState({ modalAyuda: false });
    };
    openModal = () => {
        this.setState({ modalAyuda: true });
    };

    render() {
        const { data, loader, estado_descarga, usuario, filtro, config } = this.props;
        const { modalAyuda, itemsImagenes, dropdownItems } = this.state;
        // bind
        const { descargarListado, filtroChange } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {/* <div style={{position:"absolute", right: "15px", top: "-15px"}}>
                        <button className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center" style={{width: "2rem", height: "2rem"}}>
                            <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{margin: "0", height:"1.1rem"}}/>
                            <DropDownMenu items={this.state.dropdownItems} onClick={this.onClickAyuda} direction={DropDownDirection.DownLeft} className="fnt-10"/>
                        </button>
                    </div> */}
                    <div className="d-flex justify-content-between flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                        <div className="text-center flex1">
                            {usuario.igreso_mercaderia ? (
                                <Link
                                    className="a-remove-fromat"
                                    to="/bodega/ingreso_de_mercaderia/"
                                >
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.ingreso_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button className="btn btn-secondary btn-bodega">
                                        Ingreso de Mercadería
                                    </button>
                                </Link>
                            ) : (
                                <div>
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.ingreso_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button
                                        disabled
                                        className="btn btn-secondary btn-bodega"
                                    >
                                        Ingreso de Mercadería
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="text-center flex1 border-left-bodega">
                            {usuario.baja_mercaderia ? (
                                <Link
                                    className="a-remove-fromat"
                                    to="/bodega/reportar_baja/"
                                >
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.baja_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button className="btn btn-danger btn-bodega">
                                        Reportar Baja
                                    </button>
                                </Link>
                            ) : (
                                <div>
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.baja_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button
                                        disabled
                                        className="btn btn-danger btn-bodega"
                                    >
                                        Reportar Baja
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="text-center flex1 border-left-bodega">
                            {usuario.salida_mercaderia ? (
                                <Link
                                    className="a-remove-fromat"
                                    to="/bodega/salida_de_producto/"
                                >
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.salida_producto_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button className="btn btn-tertiary btn-bodega">
                                        Salida de Producto
                                    </button>
                                </Link>
                            ) : (
                                <div>
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.salida_producto_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button
                                        disabled
                                        className="btn btn-tertiary btn-bodega"
                                    >
                                        Salida de Producto
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="text-center flex1 border-left-bodega">
                            {usuario.reajuste_mercaderia ? (
                                <Link
                                    className="mb1 a-remove-fromat"
                                    to="/bodega/reajuste/"
                                >
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.reajuste_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button className="btn btn-primary btn-bodega">
                                        Hacer Reajuste
                                    </button>
                                </Link>
                            ) : (
                                <div>
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.reajuste_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button
                                        disabled
                                        className="btn btn-primary btn-bodega"
                                    >
                                        Hacer Reajuste
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="grid-container">
                        <div
                            className="px-3 pt-3 d-flex flex-wrap flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                            style={{ flex: "3" }}
                        >
                            <div className="flex2">
                                <label>Filtrar por</label>
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-row">
                                        <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                            <label className="negro-dark2 font-weight-normal">
                                                <input
                                                    name="todos"
                                                    type="radio"
                                                    value="true"
                                                    checked={filtro === "todos"}
                                                    onChange={() => {
                                                        filtroChange("todos");
                                                    }}
                                                />
                                                <span />
                                                Todos
                                            </label>
                                        </div>
                                        <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                            <label className="negro-dark2 font-weight-normal">
                                                <input
                                                    name="con_inventario"
                                                    type="radio"
                                                    value="false"
                                                    checked={
                                                        filtro ===
                                                        "con_inventario"
                                                    }
                                                    onChange={() => {
                                                        filtroChange(
                                                            "con_inventario"
                                                        );
                                                    }}
                                                />
                                                <span />
                                                Con inventario
                                            </label>
                                        </div>
                                        <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                            <label className="negro-dark2 font-weight-normal">
                                                <input
                                                    name="sin_inventario"
                                                    type="radio"
                                                    value="false"
                                                    checked={
                                                        filtro ===
                                                        "sin_inventario"
                                                    }
                                                    onChange={() => {
                                                        filtroChange(
                                                            "sin_inventario"
                                                        );
                                                    }}
                                                />
                                                <span />
                                                Sin inventario
                                            </label>
                                        </div>
                                        <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                            <label className="negro-dark2 font-weight-normal">
                                                <input
                                                    name="inventario_negativo"
                                                    type="radio"
                                                    value="false"
                                                    checked={
                                                        filtro ===
                                                        "inventario_negativo"
                                                    }
                                                    onChange={() => {
                                                        filtroChange(
                                                            "inventario_negativo"
                                                        );
                                                    }}
                                                />
                                                <span />
                                                Con inventario negativo
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Bodega"}
                                buscar={this.props.buscar}
                                search={this.props.search}
                                estado_descarga={estado_descarga}
                                url_impresion={"/bodega/impresion_productos"}
                                itemsAyuda={dropdownItems}
                                actionAyuda={this.onClickAyuda}
                                descarga_excel={descargarListado}
                            />
                        </div>
                        <LoadMask loading={loader} light blur radius>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    width="0%"
                                    isKey
                                    dataField="id"
                                >
                                    ID
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="8%"
                                    dataField="nombre"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <RenderImage
                                                src={row.producto.img_cropped}
                                                alt={cell}
                                            />
                                        );
                                    }}
                                    dataAlign="center"
                                >
                                    Img
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="codigo_barras"
                                    dataFormat={(cell, row) => {
                                        return `${cell ? cell : "---"}`;
                                    }}
                                    dataSort
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="25%"
                                    dataField="producto__nombre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div>
                                                <b>{row.producto.nombre}</b>
                                                <div>{row.nombre}</div>
                                            </div>
                                        );
                                    }}
                                    dataSort
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="8%"
                                    dataField="producto__marca"
                                    dataFormat={(cell, row) => {
                                        return `${
                                            row.producto.marca
                                                ? row.producto.marca
                                                : "---"
                                        }`;
                                    }}
                                    dataSort
                                >
                                    Marca
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="9%"
                                    dataField="costo"
                                    dataSort
                                    dataFormat={(cell, row) => {
                                        let costo = config.conf_costo_promedio ? row.costo_promedio_ponderado : cell
                                        return <RenderMoneda monto={costo}/>;
                                    }}
                                    dataAlign="right"
                                >
                                    Costo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha_ultima_compra"
                                    dataSort
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="center"
                                    dataFormat={(cell, row) => {
                                        return <RenderDateTime fecha={cell} />;
                                    }}
                                >
                                    Última compra
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="9%"
                                    dataField="inventario_transito"
                                    dataFormat={(cell, row) => {
                                        return <div>{cell}</div>;
                                    }}
                                    dataAlign="right"
                                >
                                    Producto en tránsito
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="9%"
                                    dataField="inventario_a_recibir"
                                    dataFormat={(cell, row) => {
                                        return <div>{cell}</div>;
                                    }}
                                    dataAlign="right"
                                >
                                    Producto por recibir
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="9%"
                                    dataField="inventario"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div
                                                className={
                                                    row.minimo_existencias !==
                                                        null &&
                                                    row.minimo_existencias >=
                                                        cell
                                                        ? "rojo"
                                                        : ""
                                                }
                                            >
                                                {row.minimo_existencias !==
                                                    null &&
                                                    row.minimo_existencias >=
                                                        cell && (
                                                        <i className="fa fa-exclamation-circle" />
                                                    )}
                                                &nbsp;
                                                {row.producto.a_granel
                                                    ? cell.toFixed(3)
                                                    : cell.toFixed(2)}
                                            </div>
                                        );
                                    }}
                                    dataAlign="right"
                                >
                                    Inventario
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
                <ModalAyuda
                    open={modalAyuda}
                    closeModal={this.closeModal}
                    openModal={this.openModal}
                    items={itemsImagenes}
                />
            </div>
        );
    }
}

export default Apertura;
