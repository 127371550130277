import React from "react";
import { icons } from "icons";
import Select from "react-select";
import { Field } from "redux-form";
import Collapse from "@kunukn/react-collapse";
import {
    renderCurrency,
    renderPorcentaje,
} from "Utils/renderField/renderField";
import { RenderCurrency as RCurrency } from "Utils/renderField/renderReadField";

export const preciosSucursal = ({
    fields,
    sucursales,
    sucursal,
    changeSucursal,
    sucursal_activo,
    nombres_precios,
    conf_porcentaje_precio,
    precios_sucursalValue,
    changeSucursalActivo,
    cambioPorcentajePrecio,
    nombreFormulario,
    meta,
}) => {
    return (
        <div className="w-100">
            <div
                className="w-100 row mt-2 mb-3"
                style={{ marginLeft: "-15px", marginRight: "-15px" }}
            >
                <div className="col-12 col-md-6 col-lg-3">
                    <Select
                        className="form-control"
                        placeholder="Seleccione la sucursal"
                        disabled={!sucursales.length}
                        options={sucursales}
                        valueKey={"id"}
                        labelKey={"nombre"}
                        value={sucursal}
                        onChange={(value) => {
                            changeSucursal(value);
                        }}
                    />
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            fields.push({
                                active: true,
                                datos_sucursal: sucursal,
                                sucursal: sucursal.id,
                            });
                            changeSucursal(null);
                            changeSucursalActivo(sucursal.id);
                        }}
                        disabled={!sucursal}
                    >
                        Agregar
                    </button>
                </div>
            </div>
            {fields.map((sucursal_item, index) => {
                const item = fields.get(index);
                return (
                    <div className="w-100" key={index}>
                        <div
                            className="header-collapse w-100"
                            onClick={() => {
                                changeSucursalActivo(item.sucursal);
                            }}
                        >
                            {item.datos_sucursal.nombre}
                            <div>
                                <i
                                    className={`fa  ${
                                        sucursal_activo == item.sucursal
                                            ? "fa-chevron-up"
                                            : "fa-chevron-down"
                                    }`}
                                ></i>
                                <img
                                    onClick={() => {
                                        fields.remove(index);
                                    }}
                                    className="ml-2 action-img"
                                    title="Eliminar"
                                    src={icons.eliminar}
                                    alt="Eliminar"
                                />
                            </div>
                        </div>
                        <Collapse
                            isOpen={sucursal_activo == item.sucursal}
                            className="body-collapse"
                        >
                            <div className="row">
                                <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                    {nombres_precios ? (
                                        <label
                                            htmlFor={`${sucursal_item}.precio`}
                                        >
                                            {nombres_precios.nombre_precio} *
                                        </label>
                                    ) : (
                                        <label
                                            htmlFor={`${sucursal_item}.precio`}
                                        >
                                            Precio *
                                        </label>
                                    )}
                                    <Field
                                        name={`${sucursal_item}.precio`}
                                        component={renderCurrency}
                                        decimalScale={2}
                                        type="number"
                                        className="form-control"
                                        onChange={(event) => {
                                            setTimeout(() => {
                                                cambioPorcentajePrecio(
                                                    "porcent_precio2",
                                                    nombreFormulario,
                                                    true,
                                                    index
                                                );
                                                cambioPorcentajePrecio(
                                                    "porcent_precio3",
                                                    nombreFormulario,
                                                    true,
                                                    index
                                                );
                                                cambioPorcentajePrecio(
                                                    "porcent_precio4",
                                                    nombreFormulario,
                                                    true,
                                                    index
                                                );
                                                cambioPorcentajePrecio(
                                                    "porcent_precio5",
                                                    nombreFormulario,
                                                    true,
                                                    index
                                                );
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                    {nombres_precios ? (
                                        <label
                                            htmlFor={`${sucursal_item}.precio2`}
                                        >
                                            {conf_porcentaje_precio && (
                                                <span className="mr-2">%</span>
                                            )}
                                            {nombres_precios.nombre_precio2}
                                            {conf_porcentaje_precio && (
                                                <span className="ml-2 negro-dark2">
                                                    (
                                                    <RCurrency
                                                        value={
                                                            precios_sucursalValue[
                                                                index
                                                            ].precio2
                                                                ? parseFloat(
                                                                      precios_sucursalValue[
                                                                          index
                                                                      ].precio2
                                                                  )
                                                                : 0
                                                        }
                                                    />
                                                    )
                                                </span>
                                            )}
                                        </label>
                                    ) : (
                                        <label
                                            htmlFor={`${sucursal_item}.precio2`}
                                        >
                                            {conf_porcentaje_precio && (
                                                <span className="mr-2">%</span>
                                            )}
                                            Precio 2
                                            {conf_porcentaje_precio && (
                                                <span className="ml-2 negro-dark2">
                                                    (
                                                    <RCurrency
                                                        value={
                                                            precios_sucursalValue[
                                                                index
                                                            ].precio2
                                                                ? parseFloat(
                                                                      precios_sucursalValue[
                                                                          index
                                                                      ].precio2
                                                                  )
                                                                : 0
                                                        }
                                                    />
                                                    )
                                                </span>
                                            )}
                                        </label>
                                    )}
                                    {conf_porcentaje_precio ? (
                                        <Field
                                            name={`${sucursal_item}.porcent_precio2`}
                                            component={renderPorcentaje}
                                            decimalScale={2}
                                            type="number"
                                            className="form-control"
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    cambioPorcentajePrecio(
                                                        "porcent_precio2",
                                                        nombreFormulario,
                                                        true,
                                                        index
                                                    );
                                                });
                                            }}
                                        />
                                    ) : (
                                        <Field
                                            name={`${sucursal_item}.precio2`}
                                            component={renderCurrency}
                                            decimalScale={2}
                                            type="number"
                                            className="form-control"
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    cambioPorcentajePrecio(
                                                        "precio2",
                                                        nombreFormulario,
                                                        true,
                                                        index
                                                    );
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                    {nombres_precios ? (
                                        <label
                                            htmlFor={`${sucursal_item}.precio3`}
                                        >
                                            {conf_porcentaje_precio && (
                                                <span className="mr-2">%</span>
                                            )}
                                            {nombres_precios.nombre_precio3}
                                            {conf_porcentaje_precio && (
                                                <span className="ml-2 negro-dark2">
                                                    (
                                                    <RCurrency
                                                        value={
                                                            precios_sucursalValue[
                                                                index
                                                            ].precio3
                                                                ? parseFloat(
                                                                      precios_sucursalValue[
                                                                          index
                                                                      ].precio3
                                                                  )
                                                                : 0
                                                        }
                                                    />
                                                    )
                                                </span>
                                            )}
                                        </label>
                                    ) : (
                                        <label
                                            htmlFor={`${sucursal_item}.precio3`}
                                        >
                                            {conf_porcentaje_precio && (
                                                <span className="mr-2">%</span>
                                            )}
                                            Precio 3
                                            {conf_porcentaje_precio && (
                                                <span className="ml-2 negro-dark2">
                                                    (
                                                    <RCurrency
                                                        value={
                                                            precios_sucursalValue[
                                                                index
                                                            ].precio3
                                                                ? parseFloat(
                                                                      precios_sucursalValue[
                                                                          index
                                                                      ].precio3
                                                                  )
                                                                : 0
                                                        }
                                                    />
                                                    )
                                                </span>
                                            )}
                                        </label>
                                    )}
                                    {conf_porcentaje_precio ? (
                                        <Field
                                            name={`${sucursal_item}.porcent_precio3`}
                                            component={renderPorcentaje}
                                            decimalScale={2}
                                            type="number"
                                            className="form-control"
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    cambioPorcentajePrecio(
                                                        "porcent_precio3",
                                                        nombreFormulario,
                                                        true,
                                                        index
                                                    );
                                                });
                                            }}
                                        />
                                    ) : (
                                        <Field
                                            name={`${sucursal_item}.precio3`}
                                            component={renderCurrency}
                                            decimalScale={2}
                                            type="number"
                                            className="form-control"
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    cambioPorcentajePrecio(
                                                        "precio3",
                                                        nombreFormulario,
                                                        true,
                                                        index
                                                    );
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                    {nombres_precios ? (
                                        <label
                                            htmlFor={`${sucursal_item}.precio4`}
                                        >
                                            {conf_porcentaje_precio && (
                                                <span className="mr-2">%</span>
                                            )}
                                            {nombres_precios.nombre_precio4}
                                            {conf_porcentaje_precio && (
                                                <span className="ml-2 negro-dark2">
                                                    (
                                                    <RCurrency
                                                        value={
                                                            precios_sucursalValue[
                                                                index
                                                            ].precio4
                                                                ? parseFloat(
                                                                      precios_sucursalValue[
                                                                          index
                                                                      ].precio4
                                                                  )
                                                                : 0
                                                        }
                                                    />
                                                    )
                                                </span>
                                            )}
                                        </label>
                                    ) : (
                                        <label
                                            htmlFor={`${sucursal_item}.precio4`}
                                        >
                                            {conf_porcentaje_precio && (
                                                <span className="mr-2">%</span>
                                            )}
                                            Precio 4
                                            {conf_porcentaje_precio && (
                                                <span className="ml-2 negro-dark2">
                                                    (
                                                    <RCurrency
                                                        value={
                                                            precios_sucursalValue[
                                                                index
                                                            ].precio4
                                                                ? parseFloat(
                                                                      precios_sucursalValue[
                                                                          index
                                                                      ].precio4
                                                                  )
                                                                : 0
                                                        }
                                                    />
                                                    )
                                                </span>
                                            )}
                                        </label>
                                    )}
                                    {conf_porcentaje_precio ? (
                                        <Field
                                            name={`${sucursal_item}.porcent_precio4`}
                                            component={renderPorcentaje}
                                            decimalScale={2}
                                            type="number"
                                            className="form-control"
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    cambioPorcentajePrecio(
                                                        "porcent_precio4",
                                                        nombreFormulario,
                                                        true,
                                                        index
                                                    );
                                                });
                                            }}
                                        />
                                    ) : (
                                        <Field
                                            name={`${sucursal_item}.precio4`}
                                            component={renderCurrency}
                                            decimalScale={2}
                                            type="number"
                                            className="form-control"
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    cambioPorcentajePrecio(
                                                        "precio4",
                                                        nombreFormulario,
                                                        true,
                                                        index
                                                    );
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="col-sm-12 col-md-2 col-lg-2 form-group">
                                    {nombres_precios ? (
                                        <label
                                            htmlFor={`${sucursal_item}.precio5`}
                                        >
                                            {conf_porcentaje_precio && (
                                                <span className="mr-2">%</span>
                                            )}
                                            {nombres_precios.nombre_precio5}
                                            {conf_porcentaje_precio && (
                                                <span className="ml-2 negro-dark2">
                                                    (
                                                    <RCurrency
                                                        value={
                                                            precios_sucursalValue[
                                                                index
                                                            ].precio5
                                                                ? parseFloat(
                                                                      precios_sucursalValue[
                                                                          index
                                                                      ].precio5
                                                                  )
                                                                : 0
                                                        }
                                                    />
                                                    )
                                                </span>
                                            )}
                                        </label>
                                    ) : (
                                        <label
                                            htmlFor={`${sucursal_item}.precio5`}
                                        >
                                            {conf_porcentaje_precio && (
                                                <span className="mr-2">%</span>
                                            )}
                                            Precio 5
                                            {conf_porcentaje_precio && (
                                                <span className="ml-2 negro-dark2">
                                                    (
                                                    <RCurrency
                                                        value={
                                                            precios_sucursalValue[
                                                                index
                                                            ].precio5
                                                                ? parseFloat(
                                                                      precios_sucursalValue[
                                                                          index
                                                                      ].precio5
                                                                  )
                                                                : 0
                                                        }
                                                    />
                                                    )
                                                </span>
                                            )}
                                        </label>
                                    )}
                                    {conf_porcentaje_precio ? (
                                        <Field
                                            name={`${sucursal_item}.porcent_precio5`}
                                            component={renderPorcentaje}
                                            decimalScale={2}
                                            type="number"
                                            className="form-control"
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    cambioPorcentajePrecio(
                                                        "porcent_precio5",
                                                        nombreFormulario,
                                                        true,
                                                        index
                                                    );
                                                });
                                            }}
                                        />
                                    ) : (
                                        <Field
                                            name={`${sucursal_item}.precio5`}
                                            component={renderCurrency}
                                            decimalScale={2}
                                            type="number"
                                            className="form-control"
                                            onChange={(event) => {
                                                setTimeout(() => {
                                                    cambioPorcentajePrecio(
                                                        "precio5",
                                                        nombreFormulario,
                                                        true,
                                                        index
                                                    );
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                );
            })}
            {!fields.length && (
                <div className="w-100">Precios por sucursal no asignados.</div>
            )}
        </div>
    );
};
