import { handleActions } from 'redux-actions';
import { api } from 'api';
import Swal from 'sweetalert2';
import moment from "moment/moment";

const endpoint = "movimientos/balance_general";
const endpoint_grafica = "movimientos/grafica_balance_general";

export const DATA = "BALANCE_DATA";
export const DATA_GRAFICA = "DATA_GRAFICA";
export const FECHA_INICIAL = "BALANCE_FECHA_INICIAL";
export const FECHA_FINAL = "BALANCE_FECHA_FINAL";
export const LOADER = "BALANCE_LOADER";

export const dataGraficaBalanceGeneral = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let now = new Date();
    let year = now.getFullYear();

    api
        .get(endpoint_grafica, { anio_actual: year, anio_pasado: year - 1 })
        .then(response => {
            if (response)
                dispatch(setDataGrafica(response))
        })
        .catch(error => {
            Swal(
                'ERROR',
                'Ha ocurrido un error.',
                'error'
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}


export const leer = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const fecha_inicial = store.balance_general.fecha_inicial;
    const fecha_final = store.balance_general.fecha_final;
    api.get(`${endpoint}`, {fecha_inicial, fecha_final}).catch((err) => {
        Swal(
            'ERROR',
            'Ha ocurrido un error.',
            'error'
        );
    }).then((resp) => {
        if (resp){
            dispatch(setData(resp));
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const balance_general = store.balance_general;
    if (key === "Inicial"){
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(balance_general.fecha_final);
        if (fecha_final >= fecha_inicial)
            dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(balance_general.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial)
        dispatch(setFechaFinal(value));
    }
    dispatch(leer());
};


export const setFechaInicial = fecha_inicial => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = fecha_final => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const setData = data => ({
    type: DATA,
    data,
});
export const setDataGrafica = data_grafica => ({
    type: DATA_GRAFICA,
    data_grafica,
});
export const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const actions = {
    leer,
    dataGraficaBalanceGeneral,
    setFecha,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_GRAFICA]: (state, { data_grafica }) => {
        return {
            ...state,
            data_grafica,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    data_grafica: {},
    fecha_inicial: moment().format('YYYY-MM-DD'),
    fecha_final: moment().format('YYYY-MM-DD'),
    loader: false,
};

export default handleActions(reducers, initialState);
