import { icons } from "icons";
import Tooltip from "rc-tooltip";
import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import EdicionDetalleVenta from "Utils/Venta/EdicionDetalleVenta";

const EncabezadoItem = (props) => {
    return (
        <div className="w-100 d-flex flex-row">
            {/* FLECHAS PARA DESPLEGAR */}
            {!props.item.es_vale && (
                <div style={{ width: "20px" }}>
                    {props.item.collapsed ? (
                        <em
                            style={{ flex: "0.4" }}
                            className="fa fa-angle-down icono-flechas"
                        />
                    ) : (
                        <em
                            style={{ flex: "0.4" }}
                            className="fa fa-angle-right icono-flechas"
                        />
                    )}
                </div>
            )}
            {/* FIN FLECHAS PARA DESPLEGAR */}

            {/* NOMBRE Y CODIGO DE PRODUCTO O VALE */}
            <div className="w-100">
                <b>{props.item.es_vale ? "" : props.item.producto.nombre}</b>{" "}
                {props.item.codigo_barras ? props.item.codigo_barras : ""}
            </div>
            {/* FIN NOMBRE Y CODIGO DE PRODUCTO O VALE */}

            {/* BOTON ELIMINAR */}
            <div
                className="align-self-start text-right"
                style={{ width: "20px" }}
            >
                <button
                    className="btn-delete"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.quitar(props.item, props.index);
                    }}
                >
                    <em className="fa fa-times" />
                </button>
            </div>
            {/* FIN BOTON ELIMINAR */}
        </div>
    );
};

const ProductoItem = (props) => {
    return (
        <div className="d-flex flex1 flex-column justify-content-between">
            <div
                className="d-flex justify-content-between align-items-center mt-10"
                style={{ fontSize: "13px" }}
            >
                {props.item.producto.inventariable &&
                props.item.cantidad_total > props.item.inventario_total &&
                props.mostrar_alerta_inventario ? (
                    <div
                        className="d-flex flex-row align-items-center justify-content-center"
                        style={{ flex: "0.3" }}
                    >
                        <Tooltip
                            placement="top"
                            overlay={
                                <div>
                                    <strong>
                                        Se ha excedido el inventario
                                    </strong>
                                    <br />
                                    <strong>
                                        Disponible:{" "}
                                        {props.item.inventario_total}
                                    </strong>
                                </div>
                            }
                        >
                            <em className="text-danger fa fa-exclamation-triangle opacityPulse-css" />
                        </Tooltip>
                    </div>
                ) : (
                    <div style={{ flex: "0.3" }}></div>
                )}
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ flex: "0.6" }}
                >
                    {props.conf_venta_precio_editable ? (
                        <NumberFormat
                            style={{
                                height: "35px",
                                padding: "6px 4px",
                            }}
                            className={`form-control text-right ${
                                props.item.desc_porcentaje ? "mt-3" : ""
                            }`}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={props.item.precio ? props.item.precio : 0}
                            prefix={props.pais == "GT" ? "Q " : "$ "}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onValueChange={(values) => {
                                props.cambiarPrecioEditable(
                                    props.index,
                                    values.floatValue
                                );
                            }}
                            onFocus={(e) => {
                                e.stopPropagation();
                                e.target.select();
                            }}
                        />
                    ) : (
                        <div className="width100 text-right font-weight-bold">
                            <RenderCurrency
                                value={
                                    props.item.precio ? props.item.precio : 0
                                }
                            />
                        </div>
                    )}
                    {props.item.desc_porcentaje > 0 && (
                        <Tooltip className="width100 text-right precio-anterior">
                            <RenderCurrency
                                value={
                                    props.item.precio_original
                                        ? props.item.precio_original
                                        : 0
                                }
                            />
                        </Tooltip>
                    )}
                </div>
                &nbsp;&nbsp;&nbsp;
                <div
                    className="text-center mr-1 flex-row-center"
                    style={{ flex: "1.8" }}
                >
                    x
                    <div
                        className="d-flex flex-row ml-10 flex1"
                        style={{ height: "30px" }}
                    >
                        <button
                            className="btn btn-item-venta btn-menos d-flex align-items-center justify-content-center"
                            onClick={(e) => {
                                e.stopPropagation();
                                props.restar(props.item, props.index);
                            }}
                            type="button"
                        >
                            {/* <img className="img-icono-cantidades" src={icons.menos} alt="-" /> */}
                            <i className="fa fa-minus"></i>
                        </button>
                        <NumberFormat
                            className="form-control venta-cantidad text-right"
                            decimalScale={props.item.producto.a_granel ? 3 : 0}
                            fixedDecimalScale={
                                props.item.producto.a_granel ? true : false
                            }
                            value={props.item.cantidad}
                            style={{ minWidth: "55px", padding: "6px 2px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onValueChange={(values) => {
                                props.ingresoCantidad(
                                    values.floatValue,
                                    props.item,
                                    props.index
                                );
                            }}
                        />
                        <button
                            className="btn btn-item-venta btn-mas d-flex align-items-center justify-content-center"
                            onClick={(e) => {
                                e.stopPropagation();
                                props.sumar(props.item, props.index);
                            }}
                            type="button"
                        >
                            {/* <img className="img-icono-cantidades" src={icons.mas} alt="+" /> */}
                            <i className="fa fa-plus"></i>
                        </button>
                    </div>
                    {/* UNIDAD DE MEDIDA */}
                    <div
                        className="d-flex flex1 align-items-center justify-content-center"
                        style={{ height: "35px" }}
                    >
                        &nbsp;&nbsp;
                        <span
                            className="flex1 text-center"
                            style={{ paddingLeft: "3px" }}
                        >
                            {props.item.nombre}
                        </span>
                    </div>
                </div>
                <b className="text-right" style={{ flex: "0.6" }}>
                    <RenderCurrency
                        value={props.item.precio * props.item.cantidad}
                    />
                </b>
            </div>
        </div>
    );
};

export const ValeItem = (props) => {
    return (
        <div className="d-flex flex-column justify-content-between w-100">
            <b className="text-danger">Vale de descuento</b>
            <div className="d-flex flex-row">
                <div className="flex-1">{props.item.codigo}</div>
                <div className="flex-1 text-right">
                    <RenderCurrency
                        className={"font-weight-bold"}
                        value={props.item.total_importe * -1}
                    />
                </div>
            </div>
        </div>
    );
};

export default class ItemEditableDetalleVenta extends Component {
    render() {
        // State
        const {
            index,
            item,
            mostrar_alerta_inventario,
            venta_permitir_descuento,
            pais,
            conf_venta_precio_editable,
            conf_porcentaje_precio,
        } = this.props;
        // Bind
        const {
            quitar,
            desplegarItem,
            sumar,
            restar,
            ingresoCantidad,
            cambiarDescuento,
            cambiarPrecio,
            cambiarPrecioFinal,
            cambiarPrecioEditable,
        } = this.props;
        return (
            <div
                onClick={() => {
                    desplegarItem(item, index);
                }}
                className={` ${
                    item.collapsed ? "container-item-collapsed" : ""
                } d-flex flex-column justify-content-between container-item`}
            >
                <EncabezadoItem item={item} index={index} quitar={quitar} />
                {item.es_vale ? (
                    <ValeItem item={item} />
                ) : (
                    <ProductoItem
                        item={item}
                        index={index}
                        mostrar_alerta_inventario={mostrar_alerta_inventario}
                        pais={pais}
                        conf_venta_precio_editable={conf_venta_precio_editable}
                        sumar={sumar}
                        restar={restar}
                        ingresoCantidad={ingresoCantidad}
                        cambiarPrecioEditable={cambiarPrecioEditable}
                    />
                )}
                <EdicionDetalleVenta
                    item={item}
                    index={index}
                    venta_permitir_descuento={venta_permitir_descuento}
                    pais={pais}
                    cambiarDescuento={cambiarDescuento}
                    cambiarPrecio={cambiarPrecio}
                    cambiarPrecioFinal={cambiarPrecioFinal}
                    conf_porcentaje_precio={conf_porcentaje_precio}
                />
            </div>
        );
    }
}
