import { icons } from "icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField";
import { RenderReadCurrency } from "../../Utils/renderField/renderFieldsCurrency";

function formatoAvatar(cell, row) {
    return (
        <div>
            {row.fraccion.producto.imagen ? (
                <img
                    className="imagen-sm-50"
                    src={row.fraccion.producto.imagen}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                />
            ) : (
                <img
                    className="imagen-sm-50"
                    src={icons.img_default}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                />
            )}
        </div>
    );
}

class Inversion extends Component {
    static propTypes = {
        // loader: PropTypes.bool.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getInversion(this.props.page);
    }

    render() {
        //  state
        const { loader, data, sucursal, estado_descarga } = this.props;

        //  Bind
        const { descargarListado } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.onSortChange = this.props.sortChange;
        options.page = this.props.page;
        options.onPageChange = this.props.getInversion;

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container mb-3">
                        <div className="pt-15 pl-15 pr-15 d-flex flex-column flex-sm-column mb-1">
                            <div style={{ flex: "1" }}>
                                <h3>
                                    <b>Inversión en bodega</b>
                                </h3>
                            </div>
                            <div className="negro-dark2">
                                * Monto aproximado basado en el último precio de
                                compra
                            </div>
                        </div>
                        <div className="flex-column flex-md-row flex-lg-row align-items-end justify-content-start justify-content-md-between justify-content-lg-between w-100">
                            <h4 className="d-flex flex-column flex-sm-row justify-content-end pr-15 align-items-end mb-3">
                                <div>Monto total</div>
                                <div className="ml-3">
                                    <b>
                                        <RenderReadCurrency
                                            value={
                                                data.total_invertido
                                                    ? data.total_invertido
                                                    : 0
                                            }
                                        />
                                    </b>
                                </div>
                            </h4>
                        </div>
                        <div className="grid-titulo">
                            <Toolbar
                                titulo=""
                                buscar={this.props.buscar}
                                search={this.props.search}
                                estado_descarga={estado_descarga}
                                descarga_excel={descargarListado}
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataFormat={formatoAvatar}
                                    width="80px"
                                    dataField="creado"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    isKey
                                >
                                    Img
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={(cell) =>
                                        cell.codigo_barras
                                            ? cell.codigo_barras
                                            : ""
                                    }
                                    dataField="fraccion"
                                    dataSort={true}
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    width="100px"
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div>
                                                <b>{row.fraccion.producto.nombre}</b>
                                                <div>{row.fraccion.nombre}</div>
                                            </div>
                                        );
                                    }}
                                    dataField="fraccion__producto__nombre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataSort
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={(cell, row) =>
                                        row.fraccion.producto.marca
                                            ? row.fraccion.producto.marca
                                            : ""
                                    }
                                    dataField="fraccion__producto__marca"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataSort
                                >
                                    Marca
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataFormat={(cell) => (
                                        <RenderNumero monto={cell} />
                                    )}
                                    dataField="existencias"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataSort
                                >
                                    Cantidad en existencia
                                </TableHeaderColumn>
                                {!sucursal && (
                                    <TableHeaderColumn
                                        dataAlign="right"
                                        dataFormat={(cell) => (
                                            <span>{cell.nombre}</span>
                                        )}
                                        dataField="sucursal"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                    >
                                        Sucursal
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataFormat={(cell, row) => (
                                        <RenderMoneda
                                            monto={
                                                row.fraccion
                                                    .costo_promedio_ponderado
                                            }
                                        />
                                    )}
                                    dataField="fraccion__costo_promedio_ponderado"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataSort
                                >
                                    Costo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataFormat={(cell) => (
                                        <RenderMoneda monto={cell} />
                                    )}
                                    dataField="total_invertido"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataSort
                                >
                                    Total en dinero
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}

export default Inversion;
