import { connect } from 'react-redux'
import ProductoDetalle from './ProductoDetalle'

import {leer} from '../../../../redux/modules/producto/producto'

const ms2p = (state) => ({
    ...state.producto,
})

const md2p = {
    leer: id => dispatch => dispatch(leer(id))
}

export default connect(ms2p, md2p)(ProductoDetalle)
