import { connect } from 'react-redux';

import ReporteBodega from './Bodega';
import { actions } from "../../../../redux/modules/reportes/bodega";

const ms2p = (state) => ({
    ...state.reporte_bodega,
    conf_habilitar_no_factura: state.usuario.conf_habilitar_no_factura
})

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(ReporteBodega);
