import _ from "lodash";
import { ToastStore } from "react-toasts";
import { setLoader, setSeleccionados } from "./bodega";
import { push } from "react-router-redux";
import { api } from "api";
import Swal from "sweetalert2";

const endpoint = "bodegas";

export const asignarBaja = (producto, baja) => (dispatch, getStore) => {
    const store = getStore();
    const { conf_inventario_negativo } = store.usuario;
    const seleccionados = _.cloneDeep(store.bodega.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (item.inventario < parseInt(baja)) {
        if (conf_inventario_negativo) {
            item.baja = baja;
        } else {
            Swal(
                "ERROR",
                "No puede registrar una baja mayor al número de existencias actual de un producto",
                "error"
            );
            item.baja = item.inventario;
        }
    } else if (baja < 0) {
        item.baja = 0;
    } else {
        item.baja = baja;
    }
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const efectuarBaja = () => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.bodega.seleccionados;
    const data = store.form.BajasProducto.values;
    dispatch(setLoader(true));
    api.post(`${endpoint}/baja`, {
        productos: seleccionados,
        motivo: data.motivo,
    })
        .catch((err) => {
            if (err) {
                if (err.detail) {
                    Swal("ERROR", err.detail, "error");
                } else {
                    Swal(
                        "ERROR",
                        "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                        "error"
                    );
                }
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                    "error"
                );
            }
        })
        .then((resp) => {
            if (resp) {
                dispatch(setSeleccionados([]));
                ToastStore.success("Datos almacenados correctamente");
                dispatch(push(`/bodega/resumen_de_ingreso/${resp.id}`));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const actions = {
    asignarBaja,
    efectuarBaja,
};
