import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from 'sweetalert2'
import {ToastStore} from 'react-toasts';
import {getMe} from "../usuario/usuario"
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form'

// ------------------------------------
// Constants
// ------------------------------------
const EDITAR_PERFIL = "EDITAR_PERFIL";
// -------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
export const goToPerfil = () => (dispatch) => {
    dispatch(push("/perfil"))
}

export const editarPerfil = () => (dispatch, getStore) => {
    const store = getStore();
    const me = store.usuario.me;
    const usuario = {
        first_name: me.first_name,
        email: me.email,
        telefono: me.telefono ? me.telefono : null 
    }
    dispatch(initializeForm("PerfilForm", usuario))
}

export const guardar= (fotos, cambioEdicion) => (dispatch, getStore) => {
    const store = getStore();
    const me = store.usuario.me;
    const usuario = store.form.PerfilForm.values;
    fotos.forEach(function(foto) {
        // debugger;
        if (foto.file == undefined)
            foto.file = me.foto
    })
    console.log("DATA: ", usuario);
    console.log("FOTOS: ", fotos);

    api.putAttachments("usuarios/actualizar_perfil", usuario, fotos).catch((err) => {
        if (err) {
            Swal("ERROR", err.detail, "error");
        }else {
            Swal("ERROR","No se ha podido almacenar los datos, intente más tarde.", "error")
        }
    }).then((resp) => {
        if (resp) {
            dispatch(getMe())
            ToastStore.success("Datos almacenados correctamente");
            cambioEdicion()
        }
        // dispatch(isLoading(false));
    })
}

export const cambiarContrasena = (cambioEdicionContra) => (dispatch, getStore) => {
    const store = getStore();
    const id = store.usuario.me.id;
    let contrsena = store.form.CambioContraForm.values;
    contrsena.id = id;
    api.post("usuarios/change_password", contrsena).catch((err) => {
        if (err) {
            Swal("ERROR", err.detail, "error");
        }else {
            Swal("ERROR","No se ha podido almacenar los datos, intente más tarde.", "error")
        }
    }).then((resp) => {
        if (resp) {
            ToastStore.success("Contraseña actualizado correctamente");
            cambioEdicionContra();
        }
    })
}
// ------------------------------------


export const actions = {
    editarPerfil,
    guardar,
    goToPerfil,
    cambiarContrasena
};
