import { connect } from "react-redux";
import AdicionalCreate from "./AdicionalCreate";
import {
    crear,
    leer,
    editar,
} from "../../../../../redux/modules/adicionales_nota_fel/adicionales_nota_fel";

const mapStateToProps = (state) => ({
    loader: state.adicionales_nota_fel.loader,
});

const mapDispatchToProps = {
    crear,
    leer,
    editar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdicionalCreate);
