import React from "react";
import {
    RenderNumber,
    RenderCurrency,
} from "Utils/renderField/renderReadField";
import NumberFormat from "react-number-format";
import { icons } from "icons";
import Select from "react-select";
import {validarConfDecimales} from "../../Utils/validaConfiguracionDecimal";

export function formatoIngreso(props) {
    return (cell, producto) => {
        return (
            <div className="d-flex flex-row">
                <button
                    className="btn btn-cantidad-detalle btn-menos"
                    onClick={() => {
                        const ingreso = producto.ingreso ? producto.ingreso : 0;
                        props.asignarIngreso(producto, ingreso - 1);
                    }}
                    type="button"
                >
                    <img src={icons.menos} alt="-" />
                </button>
                <NumberFormat
                    className="form-control bodega-ingreso text-right"
                    placeholder="0"
                    decimalScale={producto.producto.a_granel ? 3 : 0}
                    fixedDecimalScale={
                        producto.producto.a_granel ? true : false
                    }
                    value={producto.ingreso}
                    thousandSeparator={true}
                    prefix={""}
                    style={{ padding: "2px" }}
                    onValueChange={(values) => {
                        let val = "";
                        if (
                            values.value != "" &&
                            values.value != undefined &&
                            values.value != null
                        ) {
                            val = producto.producto.a_granel
                                ? parseFloat(values.value)
                                : parseInt(values.value);
                        }
                        props.asignarIngreso(producto, val);
                    }}
                />
                <button
                    className="btn btn-cantidad-detalle btn-mas"
                    onClick={() => {
                        const ingreso = producto.ingreso ? producto.ingreso : 0;
                        props.asignarIngreso(producto, ingreso + 1);
                    }}
                    type="button"
                >
                    <img src={icons.mas} alt="+" />
                </button>
            </div>
        );
    };
}

export function formatoCosto(props) {
    return (cell, producto) => {
        return (
            <div>
                <NumberFormat
                    className="form-control text-right"
                    placeholder="0"
                    decimalScale={props.conf_empresa? validarConfDecimales(props.conf_empresa): 2}
                    fixedDecimalScale={true}
                    value={producto.costo}
                    thousandSeparator={true}
                    prefix={props.pais == "GT" ? "Q " : "$ "}
                    onValueChange={(values) => {
                        props.asignarCosto(producto, values.value);
                    }}
                />
            </div>
        );
    };
}

export function formatoPrecio(props) {
    return (cell, producto) => {
        return (
            <div>
                <NumberFormat
                    className="form-control text-right"
                    placeholder="0"
                    decimalScale={props.conf_empresa? validarConfDecimales(props.conf_empresa): 2}
                    // decimalScale={2}
                    fixedDecimalScale={true}
                    value={producto.precio}
                    thousandSeparator={true}
                    prefix={props.pais == "GT" ? "Q " : "$ "}
                    onValueChange={(values) => {
                        props.asignarPrecio(producto, values.value);
                    }}
                />
            </div>
        );
    };
}

export function formatoSubtotal(cell, producto) {
    let subtotal = 0;
    if (producto.ingreso && producto.costo) {
        subtotal = parseFloat(producto.ingreso) * parseFloat(producto.costo);
    }
    return (
        <div>
            <div>
                <RenderCurrency value={subtotal} />
            </div>
        </div>
    );
}

export function formatoUnidadesMedida(props) {
    return (cell, producto) => {
        return (
            <div>
                {producto.producto.a_granel && (
                    <select
                        className="form-control"
                        value={producto.id_unidad_de_medida}
                        onChange={(event) => {
                            const value = event.target.value;
                            props.asignarUnidadDeMedida(producto, value);
                        }}
                    >
                        {producto.unidades_de_medida.map((unidad) => {
                            return (
                                <option key={unidad.id} value={unidad.id}>
                                    {unidad.nombre}
                                </option>
                            );
                        })}
                    </select>
                    // <Select
                    //     className="form-control"
                    //     options={producto.unidades_de_medida}
                    //     valueKey="id"
                    //     labelKey="nombre"
                    //     value={producto.id_unidad_medida}
                    //     onChange={(e) => { console.log("SELECCION: ", e);
                    //      }}
                    // />
                )}
            </div>
        );
    };
}
