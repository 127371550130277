import { setMovimiento, setLoader } from "./bodega";
import {api} from "api";

const getMovimiento = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`movimientos/${id}`).catch((err) => {
    }).then((data) => {
        if (data) {
            dispatch(setMovimiento(data));
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    getMovimiento,
};
