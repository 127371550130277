import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";

// ------------------------------------
// CONSTANTS
// ------------------------------------

const endpoint = "bodegas/reporte_total_invertido";

export const DATA = "INVERSION_DATA";
export const LOADER = "INVERSION_LOADER";
export const PAGE = "INVERSION_PAGE";
export const ESTADO_DESCARGA = "INVERSION_ESTADO_DESCARGA";
export const BUSCADOR = "INVERSION_BUSCADOR";
export const SORT = "REPORTE_INVERSION_DE_BODEGA_SORT";
// ------------------------------------
// PureActions
// ------------------------------------
export const setSort = (sort) => ({
    type: SORT,
    sort,
});
// ------------------------------------
// ACTIONS
// ------------------------------------

export const getInversion = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { search, sort } = store.inversion;

    api.get(endpoint, { page, search, sort })
        .then((response) => {
            if (response) {
                dispatch(setData(response));
                dispatch(setPage(page));
            }
        })
        .catch((error) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const descargarListado = () => (dispatch, getStore) => {
    dispatch(setEstadoDescarga(true));
    const { sort } = getStore().inversion;

    api.get(`bodegas/descargar_excel_inversion_bodega`, {sort})
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(getInversion());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    dispatch(getInversion());
};


// ------------------------------------
// PURE ACTIONS
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const actions = {
    getInversion,
    descargarListado,
    buscar,
    sortChange,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { sort }) => {
        return {
            ...state,
            sort,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {
        results: [],
        total_invertido: 0,
    },
    sort: "-total_invertido",
    loader: false,
    page: 1,
    estado_descarga: false,
    search: "",
};

export default handleActions(reducers, initialState);
