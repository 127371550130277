import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/usuario/usuario";
import SeleccionSucursal from './SeleccionSucursal';


const ms2p = (state) => {

    return {
        ...state.usuario,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(SeleccionSucursal);
