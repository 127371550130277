import { connect } from "react-redux";
import { actions } from "../../../redux/modules/login";
import { setSucursal, closeMenu } from "../../../redux/modules/usuario/usuario";
import _ from "lodash";
import Menu from "./Menu";
import { logOut } from "../../../redux/modules/login";
import { marcarVistoBienvenida } from "../../../redux/modules/usuario/usuario";

const ms2p = (state) => {
    const url = window.location.href;
    const usuario = state.usuario.me;

    const sucursales = state.usuario.me.sucursales
        ? state.usuario.me.sucursales
        : [];
    const sucursal = state.usuario.sucursal
        ? state.usuario.sucursal
        : "empresa";
    if (
        !_.find(sucursales, { id: "empresa" }) &&
        (state.usuario.me.es_global || state.usuario.me.es_propietario)
    )
        sucursales.unshift({ nombre: "Todas", id: "empresa" });
    const empresa = _.find(sucursales, { id: "empresa" });
    try {
        if (typeof empresa.nombre === "object" && empresa.nombre !== null) {
            empresa.nombre = empresa.nombre.nombre;
        }
    } catch (e) {}

    return {
        url,
        caja: state.usuario.me.caja,
        usuario,
        sucursales,
        sucursal,
        utiliza_fel: state.usuario.utiliza_fel,
        vista_bienvenida: state.usuario.vista_bienvenida,
        bienvenida_visto: state.usuario.bienvenida_visto,
        tipo_punto_venta: state.usuario.tipo_punto_venta,
        conf_tienda_online: state.usuario.conf_tienda_online,
    };
};

const md2p = {
    ...actions,
    setSucursal,
    closeMenu,
    logOut,
    marcarVistoBienvenida,
};

export default connect(ms2p, md2p)(Menu);
