import React, { Component } from "react";
import PropTypes from "prop-types";
import { api } from "api";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import {
    RenderDateTime as RDT,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import "react-select/dist/react-select.css";
import { Async } from "react-select";
import _ from "lodash";
import { PrintContainer, PrintComponent } from "Utils/Print";

import GraficaGastos from "./Grafica/";
import moment from "moment";
import DatePicker from "Utils/DatePicker";

function tipoFormatter(cell, row) {
    return (
        <div>
            {cell == true ? <span>Fijo</span> : null}
            {cell == false ? <span>Variable</span> : null}
            {cell == null ? <span>Compra de producto</span> : null}
        </div>
    );
}
function usuarioFormatter(cell, row) {
    return (
        <div>
            <span>{cell.first_name}</span>
        </div>
    );
}
class Gastos extends Component {
    static propTypes = {
        // loader: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        fecha_inicial: PropTypes.string.isRequired,
        fecha_final: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            hoy: Date(),
        };
    }

    componentWillMount() {
        this.props.getReporteGastos(this.props.page);
    }

    render() {
        //  state
        const {
            loader,
            data,
            fecha_inicial,
            fecha_final,
            usuarios_sucursal,
            id_usuario,
            tipo_gasto,
            listado_pagina,
            paginas,
            usuario,
            id_sucursal,
            sucursales,
            print_state,
            estado_descarga,
        } = this.props;

        //  bind
        const {
            idUsuarioChange,
            tipoGastoChange,
            descargarListado,
        } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.onSortChange = this.props.sortChange;
        options.page = this.props.page;
        options.onPageChange = this.props.getReporteGastos;

        let oficina = _.find(sucursales, { id: parseInt(id_sucursal) });
        if (!oficina) {
            oficina = {};
        }

        const getCliente = (search) => {
            return api
                .get("usuarios", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data.results };
                });
        };

        return (
            <div>
                {print_state.flag && <PrintComponent />}
                <GraficaGastos />
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div style={{ flex: "1" }}>
                                    <h3>
                                        <b>Historial de gastos</b>
                                    </h3>
                                </div>
                                <div
                                    className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div>
                                        <label>Fecha Inicio</label>
                                        <DatePicker
                                            value={fecha_inicial}
                                            maxDate={new Date()}
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Inicial",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div>
                                        <label>Fecha Fin</label>
                                        <DatePicker
                                            value={fecha_final}
                                            minDate={
                                                new Date(
                                                    moment(
                                                        fecha_inicial,
                                                        "YYYY-MM-DD"
                                                    )
                                                )
                                            }
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Final",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: "0.5" }}>
                                        <label>Usuario</label>
                                        <div>
                                            <Async
                                                className="form-control"
                                                labelKey="first_name"
                                                valueKey="id"
                                                searchPromptText="Escriba para buscar"
                                                loadOptions={getCliente}
                                                placeholder={"Usuario"}
                                                onChange={(e) => {
                                                    idUsuarioChange(
                                                        e ? e.id : ""
                                                    );
                                                }}
                                                value={id_usuario}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div className="mt-10">
                                        <label htmlFor="gasto_fijo">Tipo</label>
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row">
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="gasto_fijo"
                                                            type="radio"
                                                            value=""
                                                            checked={
                                                                tipo_gasto ===
                                                                ""
                                                            }
                                                            onChange={() => {
                                                                tipoGastoChange(
                                                                    ""
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        Todos
                                                    </label>
                                                </div>
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="gasto_fijo"
                                                            type="radio"
                                                            value="1000"
                                                            checked={
                                                                tipo_gasto ===
                                                                1000
                                                            }
                                                            onChange={() => {
                                                                tipoGastoChange(
                                                                    1000
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        Gastos fijos
                                                    </label>
                                                </div>
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="gasto_fijo"
                                                            type="radio"
                                                            value="2000"
                                                            checked={
                                                                tipo_gasto ===
                                                                2000
                                                            }
                                                            onChange={() => {
                                                                tipoGastoChange(
                                                                    2000
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        Gastos variables
                                                    </label>
                                                </div>
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="gasto_fijo"
                                                            type="radio"
                                                            value="3000"
                                                            checked={
                                                                tipo_gasto ===
                                                                3000
                                                            }
                                                            onChange={() => {
                                                                tipoGastoChange(
                                                                    3000
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        Compra de productos
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoadMask loading={loader} dark blur>
                                <div className="d-flex flex-row content-total-reports w-100 mt-10">
                                    <button
                                        className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em
                                            className={`fa ${
                                                estado_descarga
                                                    ? "fa-spinner fa-pulse"
                                                    : "fa-download"
                                            }`}
                                        ></em>
                                        &nbsp;Descargar Listado
                                    </button>
                                    <button
                                        disabled={!listado_pagina.length}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.print(
                                                "reporteListadoGastos"
                                            );
                                            //printActions.print('reporteListadoGastos')
                                        }}
                                        className="btn btn-secondary mt-3 mt-md-0 mt-lg-0  mh-10"
                                        type={"button"}
                                    >
                                        <em className="fa fa-print" /> Imprimir
                                    </button>
                                    <div className="d-flex flex-sm-row justify-content-end w-100 mt-10">
                                        <h3 className="padding-10 margin-0">
                                            <b>Total</b>
                                        </h3>
                                        <h3 className="padding-10 margin-0">
                                            <b>
                                                <RenderMoneda
                                                    monto={data.total}
                                                />
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <strong>Total: </strong>
                                    <RenderMoneda monto={data.total}/>
                                </div> */}
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataField="creado"
                                        dataFormat={(cell) => (
                                            <RenderDateTime fecha={cell} />
                                        )}
                                        dataSort
                                    >
                                        Fecha
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="usuario"
                                        dataFormat={usuarioFormatter}
                                    >
                                        Usuario
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="gasto_fijo"
                                        dataFormat={tipoFormatter}
                                    >
                                        Tipo
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataSort dataField="concepto">
                                        Concepto
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="total"
                                        dataAlign="right"
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataSort
                                    >
                                        Pago de
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey
                                        dataAlign="center"
                                        dataFormat={(cell, row) => {
                                            if (row.gasto_fijo === null)
                                                return activeFormatter({
                                                    ver: "/reporte_gastos",
                                                })(cell, row);
                                        }}
                                    >
                                        Acciones
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>

                        {/* PAGINAS A IMPRIMIR */}
                        <div className="w100">
                            <PrintContainer
                                name="reporteListadoGastos"
                                className="print-space print-only"
                            >
                                {listado_pagina.map((pagina, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="print-shadow hoja-carta"
                                        >
                                            <div className="d-flex flex-row justify-content-between imp-pt-2 imp-pl-20 imp-pr-20">
                                                <div className="flex1 d-flex flex-column border-b-3">
                                                    <div>
                                                        <b>
                                                            Usuario:&nbsp;&nbsp;
                                                        </b>
                                                        <span>{usuario}</span>
                                                    </div>
                                                    <div>
                                                        <b>
                                                            Sucursal:&nbsp;&nbsp;
                                                        </b>
                                                        <span>
                                                            {oficina.nombre}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <b>
                                                            Fecha y hora:&nbsp;
                                                        </b>
                                                        <RDT
                                                            className="text-right"
                                                            value={
                                                                this.state.hoy
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {pagina.no_pagina === 1 && (
                                                    <div className="flex1 d-flex flex-column align-items-center justify-content-center border-b-3">
                                                        <h5 className="padding-10 margin-0 text-right w-100">
                                                            <b>
                                                                Reporte gastos
                                                            </b>
                                                        </h5>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="d-flex flex-row justify-content-between imp-pt-2">
                                                <BootstrapTable
                                                    className="impresion-tabla"
                                                    data={
                                                        loader
                                                            ? []
                                                            : pagina.gastos
                                                    }
                                                    remote
                                                    headerContainerClass="border-b-3"
                                                    bodyContainerClass="border-b-3"
                                                    bodyStyle={{
                                                        height: "auto",
                                                    }}
                                                >
                                                    <TableHeaderColumn
                                                        dataField="creado"
                                                        dataFormat={(cell) => (
                                                            <RenderDateTime
                                                                fecha={cell}
                                                            />
                                                        )}
                                                        width="25%"
                                                    >
                                                        Fecha
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="usuario"
                                                        dataFormat={
                                                            usuarioFormatter
                                                        }
                                                        width="15%"
                                                    >
                                                        Usuario
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="gasto_fijo"
                                                        dataFormat={
                                                            tipoFormatter
                                                        }
                                                    >
                                                        Tipo
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="concepto"
                                                        dataFormat={(cell) => (
                                                            <div
                                                                style={{
                                                                    whiteSpace:
                                                                        "normal",
                                                                }}
                                                            >
                                                                {cell}
                                                            </div>
                                                        )}
                                                    >
                                                        Concepto
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        isKey
                                                        dataField="id"
                                                        dataAlign="right"
                                                        width="15%"
                                                        dataFormat={(
                                                            cell,
                                                            row
                                                        ) => (
                                                            <RenderMoneda
                                                                monto={
                                                                    row.total
                                                                }
                                                            />
                                                        )}
                                                    >
                                                        Pago de
                                                    </TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                            {pagina.no_pagina === paginas && (
                                                <div className="d-flex flex-row w-100 mt-10">
                                                    <div className="d-flex flex-sm-row justify-content-end w-100 mt-10">
                                                        <h5 className="padding-10 margin-0">
                                                            <b>Total</b>
                                                        </h5>
                                                        <h5 className="padding-10 margin-0">
                                                            <b>
                                                                <RenderMoneda
                                                                    monto={
                                                                        data.total
                                                                    }
                                                                />
                                                            </b>
                                                        </h5>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="no_pagina d-flex flex-row justify-content-center imp-pt-2">
                                                <span className="text-center">
                                                    Pag. {pagina.no_pagina} de{" "}
                                                    {paginas}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </PrintContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gastos;
