import React from "react";
import { connect } from "react-redux";
import ListadoAdicionales from "./ListadoAdicionales";
import { actions } from "../../../../../redux/modules/adicionales_nota_fel/adicionales_nota_fel";

const mapStateToProps = (state) => ({
    ...state.adicionales_nota_fel,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListadoAdicionales);
