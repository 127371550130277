import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/despacho/despacho";
import { print } from "../../../../redux/modules/print/print";
import NotaEnvio from "./NotaEnvio";

const ms2p = (state) => {
    return {
        ...state.despacho,
        print_state: state.print,
        conf_mostrar_precio_impresion:
            state.usuario.conf_mostrar_precio_impresion,
    };
};

const md2p = {
    ...actions,
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(ms2p, md2p)(NotaEnvio);
