import React from "react";
import { icons } from "icons";
import NumberFormat from "react-number-format";

export function formatoCantidad(props) {
    return (cell, producto) => {
        return (
            <div className="d-flex flex-row">
                <button
                    className="btn btn-cantidad-detalle btn-menos"
                    onClick={() => {
                        const cantidad = props.getCantidad(producto.key);

                        if (!props.elegido(producto.key)) {
                            let item_producto = producto;
                            item_producto.cantidad = cantidad - 1;
                            props.seleccionar(item_producto);
                        } else {
                            props.asignaCantiad(producto, cantidad - 1);
                        }
                    }}
                    type="button"
                >
                    <img src={icons.menos} alt="-" />
                </button>
                <NumberFormat
                    className="form-control bodega-ingreso text-right"
                    placeholder="0"
                    decimalScale={producto.producto.a_granel ? 3 : 0}
                    fixedDecimalScale={
                        producto.producto.a_granel ? true : false
                    }
                    value={props.getCantidad(producto.key)}
                    thousandSeparator={true}
                    prefix={""}
                    style={{ padding: "2px" }}
                    onValueChange={(values) => {
                        let val = "";
                        if (
                            values.value != "" &&
                            values.value != undefined &&
                            values.value != null
                        ) {
                            val = producto.producto.a_granel
                                ? parseFloat(values.value)
                                : parseInt(values.value);
                        } else {
                            val = 0;
                        }
                        if (!props.elegido(producto.key)) {
                            let item_producto = producto;
                            item_producto.cantidad = val;
                            props.seleccionar(item_producto);
                        } else {
                            props.asignaCantiad(producto, val);
                        }
                    }}
                />
                <button
                    className="btn btn-cantidad-detalle btn-mas"
                    onClick={() => {
                        const cantidad = props.getCantidad(producto.key);

                        if (!props.elegido(producto.key)) {
                            let item_producto = producto;
                            item_producto.cantidad = cantidad + 1;
                            props.seleccionar(item_producto);
                        } else {
                            props.asignaCantiad(producto, cantidad + 1);
                        }
                    }}
                    type="button"
                >
                    <img src={icons.mas} alt="+" />
                </button>
            </div>
        );
    };
}
