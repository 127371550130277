import { icons } from "icons";
import moment from "moment/moment";
import PropTypes from "prop-types";
import Tooltip from 'rc-tooltip';
import React, { Component } from "react";
import ClienteVentaForm from "./ClienteForm";
import ClienteMCEVentaForm from "./ClienteMCEForm";
import NumberFormat from "react-number-format";
import ReactToPrint from "react-to-print";
import { actions as printActions } from "Utils/Print";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { pago_total, pago_credito } from "utility/variables";
import {
    RenderCurrency,
    RenderNumber,
    RenderDate,
} from "Utils/renderField/renderReadField";
import DatePicker from "Utils/DatePicker";
import { validarConfDecimales } from "../../Utils/validaConfiguracionDecimal";
import { setEstadoFormCliente } from "../../../../redux/modules/venta/venta";
import Swal from "sweetalert2";

class Cobro extends Component {
    static propTypes = {
        pagar: PropTypes.func.isRequired,
        total: PropTypes.number.isRequired,
    };
    constructor(props) {
        super(props);

        this.setClienteSearch = this.setClienteSearch.bind(this);
        this.state = {
            fecha_vencimiento: moment().format("YYYY-MM-DD"),
            cliente: ''
        };
    }

    setClienteSearch(e){
        this.setState({ cliente: e})
    }
    render() {
        //  state
        const {
            total,
            movimiento,
            factura_personalizada,
            tipo_pago,
            fecha_vencimiento,
            efectivo,
            tarjeta,
            nit_cliente,
            nombre_cliente,
            tipo_documento,
            loader,
            pais,
            redondeo,
            conf_venta_redondeo,
            conf_mostrar_referencia,
            tipo_punto_venta,
            print_state,
            utiliza_fel,
            cheque,
            deposito,
            nota,
            nota_credito,
            codigo_nota_credito,
            nota_credito_cargada,
            estado_form_cliente,
            retencion_iva,
            exencion_iva,
            conf_ventas_al_credito,
            conf_habilitar_campo_cliente,
            buscar_nombre
        } = this.props;
        //  bind
        const {
            setTipoPago,
            setEfectivo,
            setTarjeta,
            setCheque,
            setDeposito,
            leerCliente,
            limpiarDatosCliente,
            limpiarDatosCliente2,
            changeDatosClientes,
            setFechaVencimientoCredito,
            ingresarValeDescuento,
            setRedondeo,
            pagarVentaCotizacon,
            changeFieldForm,
            setNota,
            setCodigoNotaCredito,
            consultarNotaCredito,
            limpiarNotaCredito,
            setRetencionIva,
            setExencionIva,
            leerClienteNombre,
            getFieldForm,
            setDataName,
        } = this.props;
        //  CALCULO DEL VUELTO
        let vuelto;
        let monto_pagado = 0;
        let clienteVentaForm;
        if (tarjeta) monto_pagado += tarjeta;
        if (efectivo) monto_pagado += efectivo;
        if (cheque) monto_pagado += cheque;
        if (deposito) monto_pagado += deposito;
        if (retencion_iva) monto_pagado += retencion_iva;
        if (exencion_iva) monto_pagado += exencion_iva;
        if (nota_credito) monto_pagado += nota_credito;

        vuelto = monto_pagado - total;
        if (vuelto < 0) vuelto = 0;
        //  CALCULO DEL MONTO PENDIENTE DE PAGO
        let monto_pendiente = total - monto_pagado;

        let dataValidacionesCobro = {monto_pendiente:total};

        if (tipo_punto_venta === "PVMCE") {
            clienteVentaForm = (
                <ClienteMCEVentaForm
                    changeFieldForm={changeFieldForm}
                    //setState = {this.setState}
                    //cliente = {this.state.cliente}
                    leerClienteNombre={leerClienteNombre}
                    total={total}
                    leerCliente={leerCliente}
                    limpiarDatosCliente={limpiarDatosCliente2}
                    changeDatosClientes={changeDatosClientes}
                    initialValues={{ nit: nit_cliente, nombre: nombre_cliente, tipo_documento: tipo_documento || "NIT"}}
                    estado_movimiento={movimiento.sucursal !== undefined}
                    disabled={
                        utiliza_fel
                            ? estado_form_cliente
                            : movimiento.sucursal !== undefined
                    }
                    conf_mostrar_referencia={conf_mostrar_referencia}
                    setEstadoFormCliente={this.props.setEstadoFormCliente}
                    utiliza_fel={utiliza_fel}
                    conf_habilitar_campo_cliente={conf_habilitar_campo_cliente}
                    buscar_nombre={buscar_nombre}
                    conf_empresa={this.props.conf_empresa}
                    setDataName={setDataName}
                    dataValidacionesCobro={dataValidacionesCobro}
                />
            );
        } else {
            clienteVentaForm = (
                <ClienteVentaForm
                    setDataName={setDataName}
                    setState = {this.setClienteSearch}
                    cliente = { {cliente: this.state.cliente} }
                    leerClienteNombre={leerClienteNombre}
                    leerCliente={leerCliente}
                    limpiarDatosCliente={limpiarDatosCliente}
                    changeFieldForm={changeFieldForm}
                    changeDatosClientes={changeDatosClientes}
                    initialValues={{ nit: nit_cliente, nombre: nombre_cliente, tipo_documento: "NIT"}}
                    estado_movimiento={movimiento.sucursal !== undefined}
                    disabled={
                        utiliza_fel
                        ? estado_form_cliente
                        : movimiento.sucursal !== undefined
                    }
                    conf_empresa={this.props.conf_empresa}
                    conf_mostrar_referencia={conf_mostrar_referencia}
                    setEstadoFormCliente={this.props.setEstadoFormCliente}
                    utiliza_fel={utiliza_fel}
                    conf_habilitar_campo_cliente={conf_habilitar_campo_cliente}
                    buscar_nombre={buscar_nombre}
                    dataValidacionesCobro={dataValidacionesCobro}
                />
            );
        }

        let ref_codigo_nota_credito = "";
        let numeroDecimales = validarConfDecimales(this.props.conf_empresa);

        return (
            <div className="">
                <div className="cobro-container grid-container mt0">
                    <div className="d-flex flex-row flex-xs-column justify-content-between align-items-center">
                        <div
                            className="flex3 p15"
                            style={{ backgroundColor: "white" }}
                        >
                            <h3 className="negro-dark2">
                                <b>
                                    {tipo_pago == pago_total ? (
                                        factura_personalizada ? (
                                            <span>Factura</span>
                                        ) : (
                                            <span>Recibo</span>
                                        )
                                    ) : (
                                        <span>Crédito</span>
                                    )}{" "}
                                    a nombre de
                                </b>
                            </h3>
                            {clienteVentaForm}
                        </div>
                        <div
                            className="flex1 p15"
                            style={{ backgroundColor: "white" }}
                        >
                            <div className="d-none d-lg-block">
                                <div
                                    className="align-self-center negro-dark2 fnt-lg-24 fnt-sm-20"
                                    style={{ flex: "1" }}
                                >
                                    Monto total
                                </div>
                                <div
                                    className="negro-dark2 fnt-lg-22 fnt-sm-18"
                                    style={{ flex: "1"}}
                                >
                                    {total >= 2500 && utiliza_fel && (
                                        <React.Fragment>
                                            <Tooltip placement="bottom" overlay={
                                                <div>
                                                    <strong>En ventas mayores a <RenderCurrency value={2500} /><br/>no se pueden facturar con CF</strong>
                                                </div>}>
                                                <em className="text-danger fa fa-exclamation-triangle opacityPulse-css" />
                                            </Tooltip>
                                            &nbsp; &nbsp;
                                        </React.Fragment>
                                    )}
                                    <b>
                                        <RenderCurrency value={total} />
                                    </b>

                                </div>
                            </div>

                            <div className="monto-total container-bckg-orange d-lg-none">
                                <div
                                    className="align-self-center negro-dark2 fnt-lg-24 fnt-sm-20"
                                    style={{ flex: "1" }}
                                >
                                    <b>Monto total</b>
                                </div>
                                <div
                                    className="text-right negro-dark2 fnt-lg-22 fnt-sm-18"
                                    style={{ flex: "1" }}
                                >
                                    <b>
                                        <RenderCurrency value={total} />
                                    </b>
                                </div>
                            </div>

                            {conf_venta_redondeo && (
                                <div className="flex1 mt-3">
                                    <h4 className="negro fnt-lg-20 fnt-sm-14">
                                        Redondear
                                    </h4>
                                    <div className="d-flex align-items-center">
                                        <button
                                            disabled={
                                                movimiento.sucursal !==
                                                undefined
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRedondeo("abajo");
                                            }}
                                            className={`btn-icon btn-icon-primary ${
                                                redondeo == "abajo" &&
                                                "btn-icon-activo"
                                            } mr1 d-flex align-items-center justify-content-center`}
                                            title="Hacia abajo"
                                        >
                                            <em className="fa fa-arrow-down fnt-18" />
                                        </button>
                                        <button
                                            disabled={
                                                movimiento.sucursal !==
                                                undefined
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRedondeo("arriba");
                                            }}
                                            className={`btn-icon btn-icon-primary ${
                                                redondeo == "arriba" &&
                                                "btn-icon-activo"
                                            } mr1 d-flex align-items-center justify-content-center`}
                                            title="Hacia arriba"
                                        >
                                            <em className="fa fa-arrow-up fnt-18" />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Tabs
                        className="content-tabs"
                        selectedIndex={tipo_pago == pago_total ? 0 : 1}
                        onSelect={(tabIndex) =>
                            setTipoPago(tabIndex == 0 ? pago_total : 200)
                        }
                    >
                        <TabList className="tab_list d-flex flex-row justify-content-between">
                            <Tab
                                disabled={
                                    tipo_pago == pago_credito &&
                                    movimiento.sucursal !== undefined
                                }
                                className="content-tab-naranja flex1 text-center"
                                selectedClassName="content-tab-naranja-active"
                            >
                                {tipo_pago == pago_total ? (
                                    <img
                                        style={{
                                            maxWidth: "30px",
                                            marginRight: "10px",
                                        }}
                                        src={icons.efectivo_tarjeta_a}
                                    />
                                ) : (
                                    <img
                                        style={{
                                            maxWidth: "30px",
                                            marginRight: "10px",
                                        }}
                                        src={icons.efectivo_tarjeta}
                                    />
                                )}
                                Efectivo/Tarjeta
                            </Tab>
                            {conf_ventas_al_credito === true && <Tab
                                disabled={
                                    tipo_pago == pago_total &&
                                    movimiento.sucursal !== undefined
                                }
                                className="content-tab-naranja flex1 text-center"
                                selectedClassName="content-tab-naranja-active"
                            >
                                {tipo_pago == pago_credito ? (
                                    <img
                                        style={{
                                            maxWidth: "30px",
                                            marginRight: "10px",
                                        }}
                                        src={icons.dar_credito_a}
                                    />
                                ) : (
                                    <img
                                        style={{
                                            maxWidth: "30px",
                                            marginRight: "10px",
                                        }}
                                        src={icons.dar_credito}
                                    />
                                )}
                                Dar crédito
                            </Tab>}
                        </TabList>

                        {/* PANEL EFECTIVO/TARJETA */}
                        <TabPanel className="pt-1 pb-1">
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.efectivo}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Monto recibido en Efectivo
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="cobro en efectivo"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={efectivo}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setEfectivo(
                                                parseFloat(values.value)
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.tarjeta}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Monto Recibido en Tarjeta
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="cobro en tarjeta"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={tarjeta}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setTarjeta(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>

                            {/* CHEQUE */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.cheque}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Monto Recibido en Cheque
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="cobro en cheque"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={cheque}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setCheque(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            {/* DEPOSITO */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.deposito}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Monto Recibido en Depósito
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="cobro en depósito"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={deposito}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setDeposito(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>

                            {/* RETENCION IVA */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.retencion_exencion_iva}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Monto Recibido por Retención de IVA
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="cobro en retención de IVA"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={retencion_iva}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setRetencionIva(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>

                            {/* EXENCION IVA */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.retencion_exencion_iva}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Monto Recibido por Exención de IVA
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="cobro en retención de IVA"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={exencion_iva}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setExencionIva(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>

                            {/* NOTA DE CRÉDITO */}

                            {utiliza_fel && (
                                <div
                                    style={{ backgroundColor: "white" }}
                                    className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                >
                                    <div
                                        style={{ flex: "0.5" }}
                                        className="align-self-center text-center d-xs-none"
                                    >
                                        <img
                                            style={{ maxWidth: "35px" }}
                                            src={icons.nota_credito}
                                        />
                                    </div>
                                    <div
                                        className="d-flex flex-column"
                                        style={{ flex: "1.3" }}
                                    >
                                        <h4 className="negro-dark2 fnt-lg-18 fnt-sm-16">
                                            Monto Recibido en Nota de Crédito
                                        </h4>

                                        {/* INFO NOTA CREDITO */}
                                        {nota_credito_cargada && (
                                            <div className="w-100 py-2 px-1 d-flex flex-column">
                                                {/* FECHA */}
                                                <div className="d-flex mb">
                                                    <b className="mr-1">
                                                        Fecha
                                                    </b>{" "}
                                                    <RenderDate
                                                        value={
                                                            nota_credito_cargada
                                                                .nota_credito_fel
                                                                .fecha_emision
                                                        }
                                                    />
                                                </div>
                                                <div className="d-flex mb">
                                                    <b className="mr-1">
                                                        Código
                                                    </b>{" "}
                                                    {
                                                        nota_credito_cargada.codigo
                                                    }
                                                </div>
                                                <div className="d-flex mb">
                                                    <b className="mr-1">
                                                        Número de autorización
                                                    </b>{" "}
                                                    {
                                                        nota_credito_cargada
                                                            .nota_credito_fel
                                                            .uuid
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="negro-dark2"
                                        style={{ flex: "1" }}
                                    >
                                        <div className="d-flex justify-content-end">
                                            {nota_credito_cargada ? (
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={limpiarNotaCredito}
                                                    disabled={
                                                        movimiento.sucursal !==
                                                        undefined
                                                    }
                                                >
                                                    Eliminar
                                                </button>
                                            ) : (
                                                <input
                                                    id="nota_credito"
                                                    name="nota_credito"
                                                    type="text"
                                                    placeholder="Código o número de autorización"
                                                    className="form-control"
                                                    disabled={
                                                        movimiento.sucursal !==
                                                        undefined
                                                    }
                                                    ref={(node) => {
                                                        ref_codigo_nota_credito = node;
                                                        if (
                                                            ref_codigo_nota_credito
                                                        ) {
                                                            ref_codigo_nota_credito.value = codigo_nota_credito;
                                                        }
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                            if (
                                                                ref_codigo_nota_credito.value
                                                            ) {
                                                                consultarNotaCredito(
                                                                    total
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    onChange={() => {
                                                        setCodigoNotaCredito(
                                                            ref_codigo_nota_credito.value
                                                        );
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {nota_credito_cargada && (
                                            <div className="w-100 text-right my-3 fnt-18">
                                                <b>
                                                    <RenderCurrency
                                                        value={
                                                            nota_credito_cargada.total_importe
                                                        }
                                                    />
                                                </b>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        style={{ flex: "0.2", height: "30px" }}
                                        className="d-flex justify-content-center"
                                    >
                                        {!nota_credito_cargada && (
                                            <button
                                                className="btn btn-secondary"
                                                onClick={() =>
                                                    consultarNotaCredito(total)
                                                }
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                            >
                                                <i className="fa fa-search"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                            {/* NOTA */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.notas}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Nota
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <textarea
                                        id="notas"
                                        name="textarea"
                                        className="form-control"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        value={nota}
                                        placeholder="Ingrese la nota"
                                        onChange={(event) => {
                                            setNota(event.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            <div
                                style={{ backgroundColor: "white",  fontSize: "18px"}}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.cambio}
                                    />
                                </div>
                                <div
                                    className="d-none d-lg-block align-self-center"
                                    style={{ flex: "1.3" }}
                                >
                                    <b>Vuelto en efectivo</b>
                                </div>
                                <div
                                    className="d-lg-none align-self-center"
                                    style={{
                                        flex: "1.3",
                                        backgroundColor: "#E2E2E2",
                                    }}
                                >
                                    <b>Vuelto en efectivo</b>
                                </div>
                                <div
                                    className="d-none d-lg-block align-self-center text-right"
                                    style={{ flex: "1", marginRight: "15px" }}
                                >
                                    <b>
                                        <RenderCurrency value={vuelto} />
                                    </b>
                                </div>
                                <div
                                    className="d-lg-none align-self-center text-right"
                                    style={{
                                        flex: "1",
                                        marginRight: "15px",
                                        backgroundColor: "#E2E2E2",
                                    }}
                                >
                                    <b>
                                        <RenderCurrency value={vuelto} />
                                    </b>
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                        </TabPanel>
                        {/* PANEL CREDITO */}
                        {conf_ventas_al_credito === true && <TabPanel className="pt-1 pb-1">
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.fecha_vencimiento_gris}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Fecha de vencimiento
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <DatePicker
                                        value={fecha_vencimiento}
                                        minDate={new Date()}
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        onChange={(date) => {
                                            setFechaVencimientoCredito(date);
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        className=""
                                        style={{ maxWidth: "35px" }}
                                        src={icons.efectivo}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Anticipo en Efectivo
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="Anticipo en efectivo"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={efectivo}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setEfectivo(
                                                parseFloat(values.value)
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        className=""
                                        style={{ maxWidth: "35px" }}
                                        src={icons.tarjeta}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Anticipo en Tarjeta
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="Anticipo en tarjeta"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={tarjeta}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setTarjeta(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            {/* CHEQUE */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.cheque}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Anticipo en Cheque
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="Anticipo en cheque"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={cheque}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setCheque(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            {/* DEPOSITO */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.deposito}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Anticipo en Depósito
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="Anticipo en depósito"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={deposito}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setDeposito(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            {/* RETENCION IVA */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.retencion_exencion_iva}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Monto Recibido por Retención de IVA
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="cobro en retención de IVA"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={retencion_iva}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setRetencionIva(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>

                            {/* EXENCION IVA */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.retencion_exencion_iva}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Monto Recibido por Exención de IVA
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <NumberFormat
                                        placeholder="cobro en retención de IVA"
                                        className="form-control text-right"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        decimalScale={numeroDecimales}
                                        fixedDecimalScale={true}
                                        value={exencion_iva}
                                        thousandSeparator={true}
                                        prefix={pais == "GT" ? "Q " : "$ "}
                                        onValueChange={(values) => {
                                            setExencionIva(
                                                parseFloat(values.value),
                                                total
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            {/* NOTA DE CRÉDITO */}
                            {utiliza_fel && (
                                <div
                                    style={{ backgroundColor: "white" }}
                                    className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                >
                                    <div
                                        style={{ flex: "0.5" }}
                                        className="align-self-center text-center d-xs-none"
                                    >
                                        <img
                                            style={{ maxWidth: "35px" }}
                                            src={icons.nota_credito}
                                        />
                                    </div>
                                    <div
                                        className="d-flex flex-column"
                                        style={{ flex: "1.3" }}
                                    >
                                        <h4 className="negro-dark2 fnt-lg-18 fnt-sm-16">
                                            Monto Recibido en Nota de Crédito
                                        </h4>

                                        {/* INFO NOTA CREDITO */}
                                        {nota_credito_cargada && (
                                            <div className="w-100 py-2 px-1 d-flex flex-column">
                                                {/* FECHA */}
                                                <div className="d-flex mb">
                                                    <b className="mr-1">
                                                        Fecha
                                                    </b>{" "}
                                                    <RenderDate
                                                        value={
                                                            nota_credito_cargada
                                                                .nota_credito_fel
                                                                .fecha_emision
                                                        }
                                                    />
                                                </div>
                                                <div className="d-flex mb">
                                                    <b className="mr-1">
                                                        Código
                                                    </b>{" "}
                                                    {
                                                        nota_credito_cargada.codigo
                                                    }
                                                </div>
                                                <div className="d-flex mb">
                                                    <b className="mr-1">
                                                        Número de autorización
                                                    </b>{" "}
                                                    {
                                                        nota_credito_cargada
                                                            .nota_credito_fel
                                                            .uuid
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="negro-dark2"
                                        style={{ flex: "1" }}
                                    >
                                        <div className="d-flex justify-content-end">
                                            {nota_credito_cargada ? (
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={limpiarNotaCredito}
                                                    disabled={
                                                        movimiento.sucursal !==
                                                        undefined
                                                    }
                                                >
                                                    Eliminar
                                                </button>
                                            ) : (
                                                <input
                                                    id="nota_credito"
                                                    name="nota_credito"
                                                    type="text"
                                                    placeholder="Código o número de autorización"
                                                    className="form-control"
                                                    disabled={
                                                        movimiento.sucursal !==
                                                        undefined
                                                    }
                                                    ref={(node) => {
                                                        ref_codigo_nota_credito = node;
                                                        if (
                                                            ref_codigo_nota_credito
                                                        ) {
                                                            ref_codigo_nota_credito.value = codigo_nota_credito;
                                                        }
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                            if (
                                                                ref_codigo_nota_credito.value
                                                            ) {
                                                                consultarNotaCredito(
                                                                    total
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    onChange={() => {
                                                        setCodigoNotaCredito(
                                                            ref_codigo_nota_credito.value
                                                        );
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {nota_credito_cargada && (
                                            <div className="w-100 text-right my-3 fnt-18">
                                                <b>
                                                    <RenderCurrency
                                                        value={
                                                            nota_credito_cargada.total_importe
                                                        }
                                                    />
                                                </b>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        style={{ flex: "0.2", height: "30px" }}
                                        className="d-flex justify-content-center"
                                    >
                                        {!nota_credito_cargada && (
                                            <button
                                                className="btn btn-secondary"
                                                onClick={() =>
                                                    consultarNotaCredito(total)
                                                }
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                            >
                                                <i className="fa fa-search"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                            {/* NOTA */}
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "35px" }}
                                        src={icons.notas}
                                    />
                                </div>
                                <h4
                                    className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                    style={{ flex: "1.3" }}
                                >
                                    Nota
                                </h4>
                                <div
                                    className="align-self-center negro-dark2"
                                    style={{ flex: "1" }}
                                >
                                    <textarea
                                        id="notas"
                                        name="textarea"
                                        disabled={
                                            movimiento.sucursal !== undefined
                                        }
                                        className="form-control"
                                        value={nota}
                                        placeholder="Ingrese la nota"
                                        onChange={(event) => {
                                            setNota(event.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                            <div
                                className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                style={{ fontSize: "18px" }}
                            >
                                <div
                                    style={{ flex: "0.5" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        className=""
                                        style={{ maxWidth: "35px" }}
                                        src={icons.cambio}
                                    />
                                </div>
                                <div
                                    className="d-none d-lg-block align-self-center"
                                    style={{ flex: "1.3" }}
                                >
                                    <b>Monto pendiente de pago</b>
                                </div>
                                <div
                                    className="d-lg-none align-self-center"
                                    style={{
                                        flex: "1.3",
                                        backgroundColor: "#E2E2E2",
                                    }}
                                >
                                    <b>Monto pendiente de pago</b>
                                </div>
                                <div
                                    className="d-none d-lg-block align-self-center text-right"
                                    style={{ flex: "1", marginRight: "15px" }}
                                >
                                    <b>
                                        <RenderCurrency
                                            value={monto_pendiente}
                                        />
                                    </b>
                                </div>
                                <div
                                    className="d-lg-none align-self-center text-right"
                                    style={{
                                        flex: "1",
                                        marginRight: "15px",
                                        backgroundColor: "#E2E2E2",
                                    }}
                                >
                                    <b>
                                        <RenderCurrency
                                            value={monto_pendiente}
                                        />
                                    </b>
                                </div>
                                <div style={{ flex: "0.2" }} />
                            </div>
                        </TabPanel>}
                    </Tabs>
                </div>
                <div
                    className="cobro-footer d-flex flex-column grid-container mt0 padding-10 justify-content-around"
                    style={{ borderTop: "0", backgroundColor: "white" }}
                >
                    <div className="row d-flex justify-content-center">
                        {movimiento.sucursal === undefined && (
                            <button
                                className="btn btn-tertiary m-1"
                                type="button"
                                onClick={() => {
                                    this.props.cambioCobro();
                                }}
                            >
                                <img src={icons.cancelar} alt="" />
                                Cancelar
                            </button>
                        )}
                        <button
                            className="btn btn-primary m-1"
                            type="button"
                            onClick={() => {
                                let tipo_documento = getFieldForm("ClienteVentaForm", "tipo_documento");
                                let isValidFactura = tipo_documento && tipo_documento.toUpperCase() === "CF"
                                if (total >= 2500 && isValidFactura && utiliza_fel) {
                                    //Alerta no se puede facturar si el total es mayor a 2500 y el tipo de documento es "CF"
                                    Swal(
                                        "ERROR",
                                        "No se puede facturar si el total es mayor a 2500 y el tipo de documento es CF.",
                                        "error"
                                    );
                                }
                                else{
                                    if (
                                        this.props.conf_cotizacion &&
                                        this.props.cotizacion_cargada
                                    ) {
                                        pagarVentaCotizacon(total);
                                    } else {
                                        this.props.pagar(total);
                                    }
                                }
                            }}
                            disabled={loader}
                        >
                            <img src={icons.save} alt="" />
                            Finalizar venta
                        </button>
                    </div>
                    {movimiento.sucursal === undefined && !utiliza_fel && (
                        <div className="d-flex flex-column flex-sm-row justify-content-center mt">
                            <button
                                className="btn btn-outline-secondary m-2 sm-col-12"
                                onClick={() => {
                                    ingresarValeDescuento();
                                }}
                            >
                                <em className="fa fa-minus fa-lg mr"></em>
                                Vale de descuento
                            </button>
                        </div>
                    )}

                    {/* <button className="btn btn-outline-primary ml-1 mt" onClick={() => {
                        ingresarValeDescuento();
                    }}>
                        <em className="fa fa-minus fa-lg mr"></em>
                        Vale de descuento
                    </button> */}

                    {tipo_pago == pago_credito &&
                        movimiento.sucursal != undefined && (
                            <div className="d-flex flex-row align-items-center justify-content-center">
                                {/*<ReactToPrint
                                    trigger={() => {
                                            <a className="text-primary venta_vale">
                                                <b>IMPRIMIR {factura_personalizada ? (<span>FACTURA</span>) : (<span>RECIBO</span>)}</b>
                                            </a>
                                        }
                                    }
                                    content={() => 'reciboVenta' }
                                />*/}
                                {/* <a  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.print('reciboVenta');
                                }}>
                                    <b>IMPRIMIR {factura_personalizada ? (<span>FACTURA</span>) : (<span>RECIBO</span>)}</b>
                                </a> */}
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

export default Cobro;
