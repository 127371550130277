import { icons } from "icons";
import moment from "moment/moment";
import { PVN, PVMCE } from "utility/variables";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Field } from "redux-form";

class Dashboard extends Component {

    componentDidMount() {
        if (localStorage.getItem("sucursal")) {
            this.props.setDataActivarDashboard()
            //this.props.setDataDashboard();
        }
    }

    render() {
        const { data, loader, usuario, tipo_punto_venta , mostrar_dashboard} = this.props;
        
        const month = moment().toDate().getMonth();

        const meses = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ];
        if (
            !usuario.dashboard &&
            !usuario.es_propietario &&
            !usuario.is_superuser &&
            tipo_punto_venta != ""
        ) {
            let sin_permisos = true;
            if (localStorage.getItem("sucursal")) {
                if (usuario.bodega) {
                    sin_permisos = false;
                    return <Redirect to="/bodega" />;
                } else if (usuario.bodega == undefined) {
                    sin_permisos = false;
                }

                if (usuario.despacho_despachos) {
                    sin_permisos = false;
                    return <Redirect to="/despachos" />;
                } else if (usuario.despacho_despachos == undefined) {
                    sin_permisos = false;
                }

                if (usuario.despacho_recepciones) {
                    sin_permisos = false;
                    return <Redirect to="/recepciones_de_despachos" />;
                } else if (usuario.despacho_recepciones == undefined) {
                    sin_permisos = false;
                }
                if (usuario.despacho_recepciones_orden_compra) {
                    sin_permisos = false;
                    return <Redirect to="/recepciones_de_ordenes_de_compra" />;
                } else if (
                    usuario.despacho_recepciones_orden_compra == undefined
                ) {
                    sin_permisos = false;
                }
                if (usuario.orden_compra) {
                    sin_permisos = false;
                    return <Redirect to="/ordenes_de_compra" />;
                } else if (usuario.orden_compra == undefined) {
                    sin_permisos = false;
                }
                if (usuario.orden_online) {
                    sin_permisos = false;
                    return <Redirect to="/ordenes_online" />;
                } else if (usuario.orden_online == undefined) {
                    sin_permisos = false;
                }

                if (tipo_punto_venta == PVN) {
                    if (usuario.punto_venta) {
                        sin_permisos = false;
                        return <Redirect to="/punto_de_venta" />;
                    } else if (usuario.punto_venta == undefined) {
                        sin_permisos = false;
                    }
                }
                if (tipo_punto_venta == PVMCE) {
                    if (usuario.punto_venta_mostrador_caja) {
                        if (usuario.punto_venta_mostrador) {
                            sin_permisos = false;
                            return <Redirect to="/punto_de_venta/mostrador" />;
                        } else if (usuario.punto_venta_mostrador == undefined) {
                            sin_permisos = false;
                        }
                        if (usuario.punto_venta_caja) {
                            sin_permisos = false;
                            return <Redirect to="/punto_de_venta/caja" />;
                        } else if (usuario.punto_venta_caja == undefined) {
                            sin_permisos = false;
                        }
                    }
                }
                if (usuario.producto) {
                    sin_permisos = false;
                    return <Redirect to="/productos" />;
                } else if (usuario.producto == undefined) {
                    sin_permisos = false;
                }
                if (usuario.gasto) {
                    sin_permisos = false;
                    return <Redirect to="/gastos" />;
                } else if (usuario.gasto == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_balance_general) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_balance_general" />;
                } else if (usuario.reporte_balance_general == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_apertura_cierre) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_apertura_cierre_caja" />;
                } else if (usuario.reporte_apertura_cierre == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_bodega) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_bodega" />;
                } else if (usuario.reporte_bodega == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_venta) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_ventas" />;
                } else if (usuario.reporte_venta == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_devolucion) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_devoluciones" />;
                } else if (usuario.reporte_devolucion == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_cuenta_cobrar) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_cuenta_por_cobrar" />;
                } else if (usuario.reporte_cuenta_cobrar == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_gasto) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_gastos" />;
                } else if (usuario.reporte_gasto == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_productos_mas_vendidos) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_productos_mas_vendidos" />;
                } else if (
                    usuario.reporte_productos_mas_vendidos == undefined
                ) {
                    sin_permisos = false;
                }
                if (usuario.reporte_mejores_clientes) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_mejores_clientes" />;
                } else if (usuario.reporte_mejores_clientes == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_descuentos_recargos) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_descuentos_y_recargos" />;
                } else if (usuario.reporte_descuentos_recargos == undefined) {
                    sin_permisos = false;
                }
                if (usuario.reporte_ganancia_bruta) {
                    sin_permisos = false;
                    return <Redirect to="/reporte_ganancia_bruta" />;
                } else if (usuario.reporte_ganancia_bruta == undefined) {
                    sin_permisos = false;
                }
                if (usuario.conf_tienda) {
                    sin_permisos = false;
                    return <Redirect to="/configuracion/general" />;
                } else if (usuario.conf_tienda == undefined) {
                    sin_permisos = false;
                }
                if (usuario.conf_usuario) {
                    sin_permisos = false;
                    return <Redirect to="/configuracion/usuarios" />;
                } else if (usuario.conf_usuario == undefined) {
                    sin_permisos = false;
                }
                if (usuario.conf_sucursal) {
                    sin_permisos = false;
                    return <Redirect to="/configuracion/sucursales" />;
                } else if (usuario.conf_sucursal == undefined) {
                    sin_permisos = false;
                }
                if (usuario.conf_cliente) {
                    sin_permisos = false;
                    return <Redirect to="/configuracion/clientes" />;
                } else if (usuario.conf_cliente == undefined) {
                    sin_permisos = false;
                }
                if (usuario.conf_proveedor) {
                    sin_permisos = false;
                    return <Redirect to="/configuracion/proveedores" />;
                } else if (usuario.conf_proveedor == undefined) {
                    sin_permisos = false;
                }
                if (usuario.conf_caja) {
                    sin_permisos = false;
                    return <Redirect to="/configuracion/cajas" />;
                } else if (usuario.conf_caja == undefined) {
                    sin_permisos = false;
                }
                if (usuario.conf_destino_salida) {
                    sin_permisos = false;
                    return (
                        <Redirect to="/configuracion/destinos_salida_de_productos" />
                    );
                } else if (usuario.conf_destino_salida == undefined) {
                    sin_permisos = false;
                }
                if (usuario.conf_adicionales_nota_fel) {
                    sin_permisos = false;
                    return (
                        <Redirect to="/configuracion/adicionales_nota_fel" />
                    );
                } else if (usuario.conf_adicionales_nota_fel == undefined) {
                    sin_permisos = false;
                }
                if (usuario.bitacora) {
                    sin_permisos = false;
                    return <Redirect to="/bitacora" />;
                } else if (usuario.bitacora == undefined) {
                    sin_permisos = false;
                }

                if (sin_permisos) {
                    return <Redirect to="/sin_permisos" />;
                }
            }
        }

        return (
            <div>
            <div className="col-lg-12 col-md-12 col-sm-12 m-4 text-center">
                    Activar o desactivar Dashboard
                    <div className="">
                    <label className="switch">
                        <input
                        name="mostrar_dashboard"
                        component="input"
                        type="checkbox"
                        
                        onChange={()=> {this.props.setDataActivarDashboard(!mostrar_dashboard) }}
                        checked={mostrar_dashboard}
                        />
                        <span className="slider round"/>
                    </label>
                    </div>
            </div>


            { mostrar_dashboard &&
            <LoadMask loading={loader} light blur radius>
                <div className="row mt">
                    {/* Cards de compras */}
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <br />
                        <br />
                        <span className="fnt-1-5-rem font-weight-bold">
                            &nbsp;&nbsp;Gastos
                        </span>

                        <div className="hr-negro-dark2"></div>

                        <div className="col-12 card-list">
                            {/* Hoy */}
                            <div className="card-list-item">
                                <div className="row card-gastos">
                                    <div className="col-1 item-card">
                                        <img
                                            src={icons.dia}
                                            alt="Hoy"
                                            className="w100"
                                        />
                                    </div>
                                    <div className="col-11 item-card">
                                        <br />
                                        <h4 className="bold">
                                            <RenderCurrency
                                                value={+data.compras.hoy}
                                            />
                                        </h4>
                                        <br />
                                        <h6>Gastos de hoy</h6>
                                    </div>
                                </div>
                            </div>

                            {/* Semana */}
                            <div className="card-list-item">
                                <div className="row card-gastos">
                                    <div className="col-1 item-card">
                                        <img
                                            src={icons.semana}
                                            alt="Hoy"
                                            className="w100"
                                        />
                                    </div>
                                    <div className="col-11 item-card">
                                        <br />
                                        <h4 className="bold">
                                            <RenderCurrency
                                                value={+data.compras.semana}
                                            />
                                        </h4>
                                        <br />
                                        <h6>Esta semana</h6>
                                    </div>
                                </div>
                            </div>

                            {/* Mes */}
                            <div className="card-list-item">
                                <div className="row card-gastos">
                                    <div className="col-1 item-card">
                                        <img
                                            src={icons.mes}
                                            alt="Hoy"
                                            className="w100"
                                        />
                                    </div>
                                    <div className="col-11 item-card">
                                        <br />
                                        <h4 className="bold">
                                            <RenderCurrency
                                                value={+data.compras.mes}
                                            />
                                        </h4>
                                        <br />
                                        <h6>{meses[month]}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Cards de ventas */}
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <br />
                        <br />
                        <span className="fnt-1-5-rem font-weight-bold">
                            &nbsp;&nbsp;Ventas
                        </span>

                        <div className="hr-negro-dark2"></div>

                        {/* Cards de ventas */}
                        <div className="col-12 card-list">
                            {/* Hoy */}
                            <div className="card-list-item">
                                <div className="row card-ventas">
                                    <div className="col-1 item-card">
                                        <img
                                            src={icons.dia_blanco}
                                            alt="Hoy"
                                            className="w100"
                                        />
                                    </div>
                                    <div className="col-11 item-card">
                                        <br />
                                        <h4 className="bold">
                                            <RenderCurrency
                                                value={+data.ventas.hoy}
                                            />
                                        </h4>
                                        <br />
                                        <h6>Ventas de hoy</h6>
                                    </div>
                                </div>
                            </div>

                            {/* Semana */}
                            <div className="card-list-item">
                                <div className="row card-ventas">
                                    <div className="col-1 item-card">
                                        <img
                                            src={icons.semana_blanco}
                                            alt="Hoy"
                                            className="w100"
                                        />
                                    </div>
                                    <div className="col-11 item-card">
                                        <br />
                                        <h4 className="bold">
                                            <RenderCurrency
                                                value={+data.ventas.semana}
                                            />
                                        </h4>
                                        <br />
                                        <h6>Esta semana</h6>
                                    </div>
                                </div>
                            </div>

                            {/* Mes */}
                            <div className="card-list-item">
                                <div className="row card-ventas">
                                    <div className="col-1 item-card">
                                        <img
                                            src={icons.mes_blanco}
                                            alt="Hoy"
                                            className="w100"
                                        />
                                    </div>
                                    <div className="col-11 item-card">
                                        <br />
                                        <h4 className="bold">
                                            <RenderCurrency
                                                value={+data.ventas.mes}
                                            />
                                        </h4>
                                        <br />
                                        <h6>{meses[month]}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Cards de productos más vendidos */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <span className="fnt-1-5-rem font-weight-bold">
                            &nbsp;&nbsp;Top 5
                        </span>
                        <div className="hr-negro-dark2"></div>

                        <div className="col-12">
                            {/* Productos más vendidos */}
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="green-container">
                                    <h5>
                                        <span className="blanco fnt-1-6-rem font-weight-bold mb05">
                                            Productos más vendidos
                                        </span>
                                    </h5>

                                    <div className="container1">
                                        <div className="container2">
                                            <BootstrapTable
                                                data={
                                                    loader
                                                        ? []
                                                        : data.productos_mas_vendidos
                                                }
                                                remote
                                                hover
                                                tableContainerClass="tabla-auto"
                                                options={tableOptions}
                                            >
                                                <TableHeaderColumn
                                                    width="0%"
                                                    isKey
                                                    dataField="fraccion"
                                                >
                                                    Nombre
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataAlign="center"
                                                    dataField="cantidad"
                                                >
                                                    Cantidad
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="producto__nombre"
                                                    dataFormat={(cell, row) => {
                                                        return (
                                                            <div>
                                                                <b>
                                                                    {
                                                                        row
                                                                            .fraccion_data
                                                                            .producto
                                                                            .nombre
                                                                    }
                                                                </b>
                                                                <div>
                                                                    {
                                                                        row
                                                                            .fraccion_data
                                                                            .nombre
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                >
                                                    Nombre
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataAlign="center"
                                                    dataField="total_ventas"
                                                    dataFormat={(cell) => (
                                                        <RenderMoneda
                                                            monto={cell}
                                                        />
                                                    )}
                                                >
                                                    Total recaudado
                                                </TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Productos con alerta */}
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="red-container">
                                    <h5>
                                        <span className="blanco fnt-1-6-rem font-weight-bold mb05">
                                            Productos con alerta
                                        </span>
                                    </h5>

                                    <div className="container1">
                                        <div className="container2">
                                            <BootstrapTable
                                                hover
                                                remote
                                                tableContainerClass="tabla-auto"
                                                options={tableOptions}
                                                data={
                                                    loader
                                                        ? []
                                                        : data.productos_con_alerta
                                                }
                                            >
                                                <TableHeaderColumn
                                                    isKey
                                                    width="0%"
                                                    dataField="fraccionid"
                                                >
                                                    ID
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="producto__nombre"
                                                    dataFormat={(cell, row) => {
                                                        return (
                                                            <div>
                                                                <b>
                                                                    {
                                                                        row
                                                                            .fraccion_data
                                                                            .producto
                                                                            .nombre
                                                                    }
                                                                </b>
                                                                <div>
                                                                    {
                                                                        row
                                                                            .fraccion_data
                                                                            .nombre
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                >
                                                    Nombre
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="fraccion"
                                                    dataFormat={(cell, row) => {
                                                        return (
                                                            <div>
                                                                {
                                                                    row
                                                                        .sucursal_data
                                                                        .nombre
                                                                }
                                                            </div>
                                                        );
                                                    }}
                                                >
                                                    Sucursal
                                                </TableHeaderColumn>
                                                {/* <TableHeaderColumn
                                                    dataAlign="center"
                                                    dataField="minimo_existencias"
                                                    dataFormat={(cell, row) => {
                                                        return (<div>{row.fraccion_data.minimo_existencias}</div>)
                                                    }}
                                                >
                                                    Mínimo de existencias
                                                </TableHeaderColumn> */}
                                                <TableHeaderColumn
                                                    dataAlign="center"
                                                    dataField="existencia"
                                                >
                                                    Existencia
                                                </TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadMask>
             }
            </div>
        );
    }
}

export default Dashboard;
