import React from "react";
import { icons } from "icons";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField } from "Utils/renderField/renderField";

const FormRender = (props) => {
    const { handleSubmit, cancelar } = props;
    return (
        <form onSubmit={handleSubmit} className="row mt">
            <div className="col-sm-12">
                <div className="form-group">
                    <div className="padding-15 p-sm-0 pt-sm-1">
                        <div className="row">
                            {!!cancelar && (
                                <div
                                    className="col-sm-12 m-3"
                                    style={{
                                        borderBottom: "1px solid #cacaca",
                                    }}
                                >
                                    <h3>Registro de nuevo destino</h3>
                                </div>
                            )}

                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="nombre">Nombre*</label>
                                <Field
                                    name="nombre"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <br />
                        <div className="d-flex justify-content-center flex-row">
                            {!!cancelar ? (
                                <button
                                    className="btn btn-tertiary m-1 align-self-center"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        cancelar();
                                    }}
                                >
                                    Cancelar
                                </button>
                            ) : (
                                <Link
                                    className="btn btn-tertiary m-1 align-self-center"
                                    to="/configuracion/destinos_salida_de_productos"
                                >
                                    <img src={icons.cancelar} alt="" />
                                    Cancelar
                                </Link>
                            )}
                            <button
                                type="submit"
                                className="btn btn-primary m-1 align-self-center"
                            >
                                <img src={icons.save} alt="" />
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const Form = reduxForm({
    form: "DestinoSalidaProductoCrear",
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Campo requerido"),
        });
    },
})(FormRender);

export default Form;
