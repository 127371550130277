import { connect } from 'react-redux'
import ImprimirCodigoProducto from './ImprimirCodigo'
import {leer} from '../../../../redux/modules/producto/producto'
import { print } from '../../../../redux/modules/print/print'

const ms2p = (state) => ({
    ...state.producto,
    print_state: state.print,
})

const md2p = {
    leer: id => dispatch => dispatch(leer(id)),
    print: (name) => dispatch => dispatch(print(name)),
}

export default connect(ms2p, md2p)(ImprimirCodigoProducto)
