import React from 'react'
import { Link } from 'react-router-dom';
import { validate, validators, optional } from 'validate-redux-form';
import { renderDisabledField } from "Utils/renderField/renderField";
import { Field, reduxForm } from 'redux-form'

const Form = props => {
    const { sucursal } = props;
    return (
        <form onSubmit={() => {}} className="row">
            <div className="col-sm-12">
                <div className="form-group grid-container">
                    <div className="grid-titulo padding-15">Detalle</div>
                    <div className=" padding-15">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label htmlFor="nombre">Nombre*</label>
                                <input className="form-control" disabled type="text" value={sucursal.nombre}/>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-6 text-right">
                                <Link className="btn btn-tertiary" to="/configuracion/sucursales/">Regresar</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

const SucursalForm = reduxForm({
    // a unique name for the form
    form: 'SucursalEditar',
    validate: data => {
        return validate(data, {
            nombre: validators.exists()('Campo requerido'),
        })
    }
})(Form);

export default SucursalForm
