import React, { Component } from 'react';
import Menu from '../Menu/MenuContainer';
import "./sidebar.css";

class Sidebar extends Component {

    render(){
        return (
            <div className={`sidebar ${ this.props.navbarCollapse ? 'sidebar-reduced' : ''}`}>
                <Menu />
            </div>
        );
    }
}

export default Sidebar;
