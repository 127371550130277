import { api } from "api";
import Swal from "sweetalert2";
import { push, goBack } from "react-router-redux";
import { ToastStore } from "react-toasts";
import { handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form";

// ------------------------------------
// Constants
// ------------------------------------
export const DATA = "ADICIONALES_NOTA_FEL_DATA";
export const PAGE = "ADICIONALES_NOTA_FEL_PAGE";
export const BUSCADOR = "ADICIONALES_NOTA_FEL_BUSCADOR";
export const SORT = "ADICIONALES_NOTA_FEL_SORT";
export const LOADER = "ADICIONALES_NOTA_FEL_LOADER";
export const ADICIONAL = "ADICIONALES_NOTA_FEL_ADICIONAL";

const endpoint = "adiciones_nota_fel";

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { ordering, search } = store.adicionales_nota_fel;

    api.get(endpoint, { page, ordering, search })
        .catch((err) => {})
        .then((data) => {
            if (data) {
                dispatch(setData(data));
            }
            dispatch(setPage(page));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.adicionales_nota_fel.page;
    dispatch(listar(page));
};

export const crear = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const data = store.form.AdicionalNotaFelCreateForm.values;
    api.post(endpoint, data)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .then((resp) => {
            if (resp) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(goBack());
            }
            dispatch(setLoader(false));
        });
};

export const leer = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setAdicional(resp));
                let data = resp;
                data.tipo = data.tipo.toString();
                dispatch(initializeForm("AdicionalNotaFelCreateForm", resp));
            }
            dispatch(setLoader(false));
        });
};

export const editar = () => (dispatch, getStore) => {
    const store = getStore();
    const data = store.form.AdicionalNotaFelCreateForm.values;
    dispatch(setLoader(true));
    api.put(`${endpoint}/${data.id}`, data)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .then((resp) => {
            if (resp) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(push("/configuracion/adicionales_nota_fel"));
            }
            dispatch(setLoader(false));
        });
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`)
        .catch((error) => {
            Swal("ERROR", "Ha ocurrido un error, intente más tarde.", "error");
        })
        .then((data) => {
            if (data) {
                ToastStore.success("Registro eliminado.");
                dispatch(listar());
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

// ------------------------------------
// PureActions
// ------------------------------------

export const setData = (data) => ({
    type: DATA,
    data,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setAdicional = (adicional) => ({
    type: ADICIONAL,
    adicional,
});

export const actions = {
    crear,
    listar,
    buscar,
    sortChange,
    leer,
    editar,
    eliminar,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ADICIONAL]: (state, { adicional }) => {
        return {
            ...state,
            adicional,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------
export const initialState = {
    data: {},
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    adicional: {},
};

export default handleActions(reducers, initialState);
