import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/usuarios/usuarios";
import Editar from "./UsuariosEditar";

const ms2p = (state) => {
    let reportes = false,
        configuracion = false,
        despacho = false,
        bodega = false,
        punto_venta_mostrador_caja = false;
    try {
        const data = state.form.UsuarioEditar.values;
        if (
            data.reporte_balance_general ||
            data.reporte_apertura_cierre ||
            data.reporte_bodega ||
            data.reporte_venta ||
            data.reporte_devolucion ||
            data.reporte_cuenta_cobrar ||
            data.reporte_gasto ||
            data.reporte_productos_mas_vendidos ||
            data.reporte_mejores_clientes ||
            data.reporte_total_invertido ||
            data.reporte_ganancia_bruta ||
            data.reporte_movimientos ||
            data.reporte_historial_precios ||
            data.reporte_despachos ||
            data.reporte_historial_bajas_salidas ||
            data.reporte_descuentos_recargos
        )
            reportes = true;
        if (
            data.conf_tienda ||
            data.conf_usuario ||
            data.conf_sucursal ||
            data.conf_cliente ||
            data.conf_proveedor ||
            data.conf_caja ||
            data.conf_destino_salida ||
            data.conf_adicionales_nota_fel
        )
            configuracion = true;
        if (
            data.despacho_despachos ||
            data.despacho_recepciones ||
            data.despacho_recepciones_orden_compra
        )
            despacho = true;
        if (data.punto_venta_caja || data.punto_venta_mostrador)
            punto_venta_mostrador_caja = true;

        if (
            data.igreso_mercaderia ||
            data.baja_mercaderia ||
            data.salida_mercaderia ||
            data.reajuste_mercaderia
        )
            bodega = true;
    } catch (e) {
        // Entra aqui cuando no existen valores el form todavia
    }

    //  Se obtienen las sucursales del usuario, para crear usuarios para solo esas sucursales
    const usuario_sucursales = state.usuario.me.sucursales
        ? state.usuario.me.sucursales
        : [];
    let sucursales = [];
    usuario_sucursales.forEach((sucursal) => {
        if (sucursal.id !== "empresa") {
            sucursales.push(sucursal);
        }
    });

    return {
        ...state.usuarios,
        reportes,
        configuracion,
        despacho,
        bodega,
        punto_venta_mostrador_caja,
        sucursales,
        utiliza_fel: state.usuario.utiliza_fel,
        tipo_punto_venta: state.usuario.tipo_punto_venta,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Editar);
