import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/devolucion/devolucion";
import Eleccion from "./Eleccion";

const ms2p = (state) => {
    let total = 0;
    const { elegidos, adicionales_seleccionados } = state.devolucion;
    elegidos.forEach((elegido) => {
        const cantidad = elegido.cantidad
            ? elegido.producto.a_granel
                ? parseFloat(elegido.cantidad)
                : parseInt(elegido.cantidad)
            : 0;
        if (elegido.precio_calculado) {
            total += elegido.precio_calculado * cantidad;
        } else {
            total += elegido.precio * cantidad;
        }
    });
    adicionales_seleccionados.forEach((adicional) => {
        total += adicional.precio;
    });
    return {
        ...state.devolucion,
        utiliza_fel: state.usuario.utiliza_fel,
        total: parseFloat(total.toFixed(2)),
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Eleccion);
