import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes/cuenta_cobrar";
import DetalleCuentaCobrar from "./DetalleCuentaCobrar";


const ms2p = (state) => {
    return {
        ...state.reporte_cuenta_cobrar,
        venta_permitir_descuento: state.usuario.venta_permitir_descuento,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DetalleCuentaCobrar);
