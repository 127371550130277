import { connect } from "react-redux";
import {
    getMetodosPagoEntrega,
    submitConfig,
} from "../../../../redux/modules/configuracion/configuracion";
import ConfiguracionEditar from "./ConfiguracionEditar";

const mstp = (state) => {
    const { configuracion } = state;
    return {
        data: configuracion.config,
        loading: configuracion.loading,
        metodos_entrega: configuracion.metodos_entrega,
        metodos_pago: configuracion.metodos_pago,
        conf_tienda_online: state.usuario.conf_tienda_online,
        utiliza_fel: state.usuario.utiliza_fel,
    };
};

const mdtp = {
    getMetodosPagoEntrega,
    submitConfig,
};

export default connect(mstp, mdtp)(ConfiguracionEditar);
