import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./toolbar.css";
import { PrintContainer, actions as printActions } from "Utils/Print";
import { icons } from "icons";
import { DropDownMenu, DropDownDirection } from "react-dropdown-advanced";

class ToolBar extends Component {
    render() {
        //  state
        const { itemsAyuda, estado_descarga } = this.props;
        //  bind
        const { actionAyuda, descarga_excel } = this.props;
        return (
            <div>
                {this.props.buscar !== undefined && (
                    <div className="react-bs-table-tool-bar">
                        <div className="row">
                            <div
                                className={`col-12 ${
                                    itemsAyuda && actionAyuda
                                        ? "pr-0"
                                        : actionAyuda
                                        ? "pr-0"
                                        : ""
                                }`}
                            >
                                <div className="d-flex flex-column flex-sm-row justify-content-between">
                                    <div>{this.props.titulo}</div>
                                    <div className="d-flex flex-column flex-sm-row">
                                        {this.props.url_impresion !==
                                        undefined ? (
                                            <Link to={this.props.url_impresion}>
                                                <button className="btn btn-primary mt-lg">
                                                    <em className="fa fa-print" />{" "}
                                                    Imprimir
                                                </button>
                                            </Link>
                                        ) : null}
                                        {descarga_excel && (
                                            <button
                                                style={{
                                                    marginTop: "2px",
                                                    height: "30px",
                                                }}
                                                disabled={estado_descarga}
                                                className="btn btn-secondary mt-lg ml-3"
                                                onClick={descarga_excel}
                                            >
                                                <em
                                                    className={`fa ${
                                                        estado_descarga
                                                            ? "fa-spinner fa-pulse"
                                                            : "fa-download"
                                                    }`}
                                                ></em>
                                                &nbsp;Descargar Listado
                                            </button>
                                        )}

                                        <div className="toolbar-search">
                                            {this.props.buscar !==
                                                undefined && (
                                                <div
                                                    className="w-100"
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <input
                                                        id="buscar"
                                                        type="text"
                                                        name="buscar"
                                                        placeholder="Buscar"
                                                        autoFocus={
                                                            this.props.autoFocus
                                                                ? this.props
                                                                      .autoFocus
                                                                : false
                                                        }
                                                        ref={(node) => {
                                                            this.buscar = node;
                                                            if (this.buscar) {
                                                                this.buscar.value = this.props.search;
                                                            }
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                e.preventDefault();
                                                                if (
                                                                    this.props
                                                                        .agregarPrimer !==
                                                                    undefined
                                                                ) {
                                                                    if (
                                                                        this
                                                                            .buscar
                                                                            .value
                                                                    ) {
                                                                        this.props.agregarPrimer(
                                                                            this
                                                                                .buscar
                                                                                .value
                                                                        );
                                                                        this.buscar.value =
                                                                            "";
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        onChange={() => {
                                                            if (this.buscar) {
                                                                this.props.buscar(
                                                                    this.buscar
                                                                        .value
                                                                );
                                                            }
                                                        }}
                                                        autoComplete="off"
                                                        className="form-control"
                                                    />
                                                    <span
                                                        className={`fa fa-search icon-search ${
                                                            itemsAyuda &&
                                                            actionAyuda
                                                                ? "icon-search-ayuda"
                                                                : actionAyuda
                                                                ? "icon-search-ayuda"
                                                                : ""
                                                        }`}
                                                    />
                                                </div>
                                            )}
                                            {itemsAyuda && actionAyuda && (
                                                <button
                                                    className="btn btn-ayuda btn-ayuda-flotante fnt-16 d-flex align-items-center justify-content-center"
                                                    style={{
                                                        width: "2rem",
                                                        height: "2rem",
                                                    }}
                                                >
                                                    <img
                                                        src={icons.ayuda}
                                                        alt="ayuda"
                                                        title="Ayuda"
                                                        style={{
                                                            margin: "0",
                                                            height: "1.1rem",
                                                        }}
                                                    />
                                                    <DropDownMenu
                                                        items={itemsAyuda}
                                                        onClick={actionAyuda}
                                                        direction={
                                                            DropDownDirection.DownLeft
                                                        }
                                                        className="fnt-10"
                                                    />
                                                </button>
                                            )}
                                            {actionAyuda && !itemsAyuda && (
                                                <button
                                                    className="btn btn-ayuda btn-ayuda-flotante fnt-16 d-flex align-items-center justify-content-center"
                                                    style={{
                                                        width: "2rem",
                                                        height: "2rem",
                                                    }}
                                                    onClick={actionAyuda}
                                                >
                                                    <img
                                                        src={icons.ayuda}
                                                        alt="ayuda"
                                                        title="Ayuda"
                                                        style={{
                                                            margin: "0",
                                                            height: "1.1rem",
                                                        }}
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.props.buscar_tienda !== undefined && (
                    <div className="row px-0 mx-0">
                        <div className="col-12 px-0 mx-0">
                            <div className="toolbar-search">
                                <div
                                    className="w-100"
                                    style={{ position: "relative" }}
                                >
                                    <input
                                        id="buscar"
                                        type="text"
                                        name="buscar"
                                        placeholder="Buscar"
                                        autoFocus={
                                            this.props.autoFocus
                                                ? this.props.autoFocus
                                                : false
                                        }
                                        ref={(node) => {
                                            this.buscar_tienda = node;
                                            if (this.buscar_tienda) {
                                                this.buscar_tienda.value = this.props.search_tienda;
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                if (this.buscar_tienda) {
                                                    this.props.buscar_tienda(
                                                        this.buscar_tienda.value
                                                    );
                                                }
                                            }
                                        }}
                                        // onChange={() => {
                                        //     if (this.buscar_tienda) {
                                        //         this.props.buscar_tienda(this.buscar_tienda.value);
                                        //     }
                                        // }}
                                        autoComplete="off"
                                        className="form-control"
                                    />
                                    <span
                                        className={`fa fa-search icon-search ${
                                            itemsAyuda && actionAyuda
                                                ? "icon-search-ayuda"
                                                : actionAyuda
                                                ? "icon-search-ayuda"
                                                : ""
                                        }`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

ToolBar.propTypes = {
    filtar: PropTypes.any,
    titulo: PropTypes.any,
    subtitulo: PropTypes.any,
    editar: PropTypes.any,
    buscar: PropTypes.any,
    regresar: PropTypes.any,
    url_impresion: PropTypes.any,
};

export default ToolBar;
