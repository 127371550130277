import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/bodega/bodega";
import { actions as ingresoActions } from "../../../../redux/modules/bodega/ingreso";
import Ingreso from './Ingreso';


const ms2p = (state) => {
    let total = 0;
    state.bodega.seleccionados.forEach((producto) => {
        const costo = producto.costo ? parseFloat(producto.costo) : 0;
        const cantidad = producto.ingreso ? parseFloat(producto.ingreso) : 0;
        total = total + (costo * cantidad);
    });
    return {
        ...state.bodega,
        // conf_empresa: state.configuracion.config,
        sucursal: state.usuario.sucursal,
        pais: state.usuario.me.pais,
        conf_empresa: state.usuario,
        total,
        conf_habilitar_no_factura: state.usuario.me.conf_habilitar_no_factura
    };
};

const md2p = { ...actions, ...ingresoActions };

export default connect(ms2p, md2p)(Ingreso);
