import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import Recibo from "Utils/Recibo";
import Nota from "Utils/Nota";
import { PrintContainer, PrintComponent } from "Utils/Print";

class ReajusteProducto extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = { ver_buscador: false };
    }
    componentWillMount() {
        if (this.props.getMovimiento !== undefined) {
            this.props.getMovimiento(this.props.match.params.id);
        }
    }
    render() {
        const {
            movimiento,
            regresar,
            print_state,
            conf_mostrar_precio_impresion,
        } = this.props;
        const sucursal = movimiento.sucursal ? movimiento.sucursal : {};

        if (movimiento.tipo_movimiento === "Baja") {
            return (
                <div className="col-md-12 mt-4">
                    {print_state.flag && <PrintComponent />}
                    <PrintContainer name="reciboIngreso">
                        <Nota
                            {...this.props}
                            sucursal={sucursal}
                            tipo={"Baja"}
                        />
                    </PrintContainer>
                    <div className="mt1 text-center">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.print("reciboIngreso");
                                //printActions.print('reciboIngreso')
                                // window.print();
                            }}
                            className="btn btn-primary mt-lg"
                            type={"button"}
                        >
                            <em className="fa fa-print" /> Imprimir
                        </button>
                    </div>
                    <Link
                        className="btn btn-tertiary align-self-center m-1"
                        to={regresar ? regresar : "/bodega"}
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                        {!regresar ? "Regresar a Bodega" : "Regresar"}
                    </Link>
                </div>
            );
        }

        if (movimiento.tipo_movimiento === "Salida De Producto") {
            return (
                <div className="col-md-12 mt-4">
                    {print_state.flag && <PrintComponent />}
                    <PrintContainer name="reciboIngreso">
                        <Nota
                            {...this.props}
                            sucursal={sucursal}
                            tipo={"Salida De Producto"}
                        />
                    </PrintContainer>
                    <div className="mt1 text-center">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.print("reciboIngreso");
                                //printActions.print('reciboIngreso')
                                // window.print();
                            }}
                            className="btn btn-primary mt-lg"
                            type={"button"}
                        >
                            <em className="fa fa-print" /> Imprimir
                        </button>
                    </div>
                    <Link
                        className="btn btn-tertiary align-self-center m-1"
                        to={regresar ? regresar : "/bodega"}
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                        {!regresar ? "Regresar a Bodega" : "Regresar"}
                    </Link>
                </div>
            );
        }

        if (movimiento.tipo_movimiento === "Reajuste") {
            return (
                <div className="col-md-12 mt-4">
                    {print_state.flag && <PrintComponent />}
                    <PrintContainer name="reciboIngreso">
                        <Nota
                            {...this.props}
                            sucursal={sucursal}
                            tipo={"Reajuste"}
                        />
                    </PrintContainer>
                    <div className="mt1 text-center">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.print("reciboIngreso");
                                //printActions.print('reciboIngreso')
                                // window.print();
                            }}
                            className="btn btn-secondary mt-lg"
                            type={"button"}
                        >
                            <em className="fa fa-print" /> Imprimir
                        </button>
                    </div>
                    <Link
                        className="btn btn-tertiary align-self-center m-1"
                        to={regresar ? regresar : "/bodega"}
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                        {!regresar ? "Regresar a Bodega" : "Regresar"}
                    </Link>
                </div>
            );
        }

        if (movimiento.tipo_movimiento === "RECEPCION_ORDEN_COMPRA") {
            return (
                <div className="col-md-12 mt-4">
                    {print_state.flag && <PrintComponent />}
                    <PrintContainer name="reciboIngreso">
                        <Nota
                            {...this.props}
                            sucursal={sucursal}
                            tipo={"Orden de Compra"}
                        />
                    </PrintContainer>
                    <div className="mt1 text-center">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.print("reciboIngreso");
                                //printActions.print('reciboIngreso')
                                // window.print();
                            }}
                            className="btn btn-secondary mt-lg"
                            type={"button"}
                        >
                            <em className="fa fa-print" /> Imprimir
                        </button>
                    </div>
                    <Link
                        className="btn btn-tertiary align-self-center m-1"
                        to={regresar ? regresar : "/bodega"}
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                        {!regresar ? "Regresar a Bodega" : "Regresar"}
                    </Link>
                </div>
            );
        }

        return (
            <div className="col-md-12 mt-4">
                {print_state.flag && <PrintComponent />}
                <PrintContainer name="reciboIngreso">
                    <Recibo
                        {...this.props}
                        sucursal={sucursal}
                        tipo={"Ingreso"}
                        footerText={"Costo Total del Ingreso"}
                    />
                </PrintContainer>
                <div className="mt1 text-center">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.print("reciboIngreso");
                            //printActions.print('reciboIngreso')
                            // window.print();
                        }}
                        className="btn btn-secondary mt-lg"
                        type={"button"}
                    >
                        <em className="fa fa-print" /> Imprimir
                    </button>
                </div>
                <Link
                    className="btn btn-tertiary align-self-center m-1"
                    to={regresar ? regresar : "/bodega"}
                >
                    <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                    {!regresar ? "Regresar a Bodega" : "Regresar"}
                </Link>
            </div>
        );
    }
}

export default ReajusteProducto;
