import React from 'react'
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form'
import { validate, validators, optional } from 'validate-redux-form';
import { renderField, renderSelectField, renderSearchSelect } from "Utils/renderField/renderField";
import { icons } from "icons";

const Form = props => {
    const { handleSubmit, getSucursales } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">Edición</div>
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1 mt">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="nombre">Nombre de la caja*</label>
                            <Field name="nombre" component={renderField} type="text" className="form-control"/>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="sucursal">Sucursal</label>
                            <Field name="sucursal" component={renderSearchSelect} disabled={true} loadOptions={getSucursales}
                                   labelKey="nombre" valueKey="id" className="form-control"/>
                        </div>
                    </div>
                    <br/>
                    <div className="d-flex justify-content-center flex-row">
                        <Link className="btn btn-tertiary m-1 align-self-center"  to="/configuracion/cajas"><img src={icons.cancelar} alt=""/>Cancelar</Link>
                        <button type="submit" className="btn btn-primary m-1 align-self-center"><img src={icons.save} alt=""/>Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

const CajaForm = reduxForm({
    // a unique name for the form
    form: 'CajaEditar',
    validate: data => {
        return validate(data, {
            nombre: validators.exists()('Campo requerido'),
        })
    }
})(Form);

export default CajaForm
