import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators, optional } from "validate-redux-form";
import { renderNumber, renderTextArea } from "Utils/renderField/renderField";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { icons } from "icons";

const Form = (props) => {
    const {
        handleSubmit,
        total,
        caja,
        config,
        pais,
        actionAyuda,
        regresar,
    } = props;
    return (
        <form onSubmit={handleSubmit} className="">
            <div className="apertura-cierre">
                <div className="form-group grid-container">
                    <div
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "25px",
                        }}
                    >
                        <button
                            type="button"
                            className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center"
                            style={{ width: "2rem", height: "2rem" }}
                            onClick={actionAyuda}
                        >
                            <img
                                src={icons.ayuda}
                                alt="ayuda"
                                title="Ayuda"
                                style={{ margin: "0", height: "1.1rem" }}
                            />
                        </button>
                    </div>
                    <div className="grid-titulo padding-15">Cierre de Caja</div>
                    <div className=" padding-15">
                        <div className="grid-end-seccion em-1-1">
                            <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row padding-15 verde">
                                <div
                                    className="d-flex flex-column grid-border-right pr-lg-2 pr-md-2 mb-2"
                                    style={{ flex: 1 }}
                                >
                                    <b className="em-1-1">Resumen Caja</b>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Apertura:</b>{" "}
                                            <RenderCurrency
                                                value={caja.total_apertura}
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Ingresos en Efectivo:</b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.total_ingreso_efectivo
                                                        ? caja.total_ingreso_efectivo
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Egresos en Efectivo:</b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.total_egreso_efectivo
                                                        ? caja.total_egreso_efectivo
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Ventas Efectivo:</b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.total_venta_efectivo
                                                        ? caja.total_venta_efectivo
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Ventas Tarjeta de Crédito:</b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.total_tarjeta_cierre
                                                        ? caja.total_tarjeta_cierre
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Ventas Cheque:</b>{" "}
                                            <RenderCurrency
                                                value={caja.total_cheque_cierre}
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Ventas Depósito:</b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.total_deposito_cierre
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Ventas Nota de Crédito:</b>{" "}
                                            <RenderCurrency
                                                value={caja.total_nota_credito}
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>
                                                Ventas Anuladas (
                                                {caja.total_ventas_anuladas}):
                                            </b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.monto_total_ventas_anuladas
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Total de Abonos:</b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.abonos.total
                                                        ? caja.abonos.total
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Total Ventas al Crédito:</b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.total_venta_credito
                                                        ? caja.total_venta_credito
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Total Retención de IVA:</b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.total_retencion_iva
                                                        ? caja.total_retencion_iva
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <b>Total Exención de IVA:</b>{" "}
                                            <RenderCurrency
                                                value={
                                                    caja.total_exencion_iva
                                                        ? caja.total_exencion_iva
                                                        : 0
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="d-flex flex-column pl-lg-2 pl-md-2"
                                    style={{ flex: 1 }}
                                >
                                    <b className="em-1-1">Cuadre de efectivo</b>
                                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row justify-content-between">
                                        <div className="d-flex flex-column">
                                            Registrado en caja{" "}
                                            <div>
                                                <RenderCurrency
                                                    value={caja.total_actual}
                                                    className={"em-1-3"}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column">
                                            Total en efectivo{" "}
                                            <div>
                                                <RenderCurrency
                                                    value={total}
                                                    className={"em-1-3"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {pais == "MX" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.billete_1000_mx}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="b1000">
                                        Total de billetes
                                    </label>
                                    <Field
                                        name="b1000"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            {pais == "MX" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.billete_500_mx}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="b500">
                                        Total de billetes
                                    </label>
                                    <Field
                                        name="b500"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    {pais == "GT" ? (
                                        <img
                                            className="dinero"
                                            src={icons.billete_200}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="dinero"
                                            src={icons.billete_200_mx}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <label htmlFor="b200">Total de billetes</label>
                                <Field
                                    name="b200"
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    {pais == "GT" ? (
                                        <img
                                            className="dinero"
                                            src={icons.billete_100}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="dinero"
                                            src={icons.billete_100_mx}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <label htmlFor="b100">Total de billetes</label>
                                <Field
                                    name="b100"
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    {pais == "GT" ? (
                                        <img
                                            className="dinero"
                                            src={icons.billete_50}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="dinero"
                                            src={icons.billete_50_mx}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <label htmlFor="b50">Total de billetes</label>
                                <Field
                                    name="b50"
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    {pais == "GT" ? (
                                        <img
                                            className="dinero"
                                            src={icons.billete_20}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="dinero"
                                            src={icons.billete_20_mx}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <label htmlFor="b20">Total de billetes</label>
                                <Field
                                    name="b20"
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                            {pais == "GT" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.billete_10}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="b10">
                                        Total de billetes
                                    </label>
                                    <Field
                                        name="b10"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            {pais == "GT" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.billete_5}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="b5">
                                        Total de billetes
                                    </label>
                                    <Field
                                        name="b5"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            {pais == "GT" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.billete_1}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="b1">
                                        Total de billetes
                                    </label>
                                    <Field
                                        name="b1"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            {pais == "MX" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.moneda_2000_mx}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="m2000">
                                        Total de Monedas
                                    </label>
                                    <Field
                                        name="m2000"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            {pais == "MX" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.moneda_1000_mx}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="m1000">
                                        Total de Monedas
                                    </label>
                                    <Field
                                        name="m1000"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            {pais == "MX" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.moneda_500_mx}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="m500">
                                        Total de Monedas
                                    </label>
                                    <Field
                                        name="m500"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            {pais == "MX" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.moneda_200_mx}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="m200">
                                        Total de Monedas
                                    </label>
                                    <Field
                                        name="m200"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    {pais == "GT" ? (
                                        <img
                                            className="dinero"
                                            src={icons.moneda_100}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="dinero"
                                            src={icons.moneda_100_mx}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <label htmlFor="m100">Total de Monedas</label>
                                <Field
                                    name="m100"
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    {pais == "GT" ? (
                                        <img
                                            className="dinero"
                                            src={icons.moneda_50}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="dinero"
                                            src={icons.moneda_50_mx}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <label htmlFor="m50">Total de Monedas</label>
                                <Field
                                    name="m50"
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                            {pais == "GT" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.moneda_25}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="m25">
                                        Total de Monedas
                                    </label>
                                    <Field
                                        name="m25"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            {pais == "MX" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.moneda_20_mx}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="m20">
                                        Total de Monedas
                                    </label>
                                    <Field
                                        name="m20"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    {pais == "GT" ? (
                                        <img
                                            className="dinero"
                                            src={icons.moneda_10}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="dinero"
                                            src={icons.moneda_10_mx}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <label htmlFor="m10">Total de Monedas</label>
                                <Field
                                    name="m10"
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                <div className="text-center">
                                    {pais == "GT" ? (
                                        <img
                                            className="dinero"
                                            src={icons.moneda_5}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="dinero"
                                            src={icons.moneda_5_mx}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <label htmlFor="m5">Total de Monedas</label>
                                <Field
                                    name="m5"
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                            {pais == "GT" && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                    <div className="text-center">
                                        <img
                                            className="dinero"
                                            src={icons.moneda_1}
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="m1">Total de Monedas</label>
                                    <Field
                                        name="m1"
                                        component={renderNumber}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            )}
                        </div>
                        {!config.conf_caja_forzar_cierre && (
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="concepto_cierre">
                                        Tu arqueo no cuadra, indica el motivo:
                                    </label>
                                    <Field
                                        name="concepto_cierre"
                                        component={renderTextArea}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}
                        <br />
                        <div className="d-flex justify-content-center flex-column flex-sm-row">
                            <button
                                type="button"
                                className="btn btn-tertiary m-1 align-self-center"
                                onClick={regresar}
                            >
                                <img src={icons.cancelar} alt="" />
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary m-1 align-self-center"
                            >
                                <img src={icons.save} alt="" />
                                Realizar Cierre
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const CierreForm = reduxForm({
    // a unique name for the form
    form: "CajaCierre",
    validate: (data, props) => {
        const { caja, total, config } = props;
        const total_actual = parseFloat(
            parseFloat(caja.total_actual).toFixed(2)
        );
        if (!config.conf_caja_forzar_cierre) {
            return validate(data, {
                concepto_cierre:
                    total_actual !== parseFloat(parseFloat(total).toFixed(2))
                        ? validators.exists()("Campo requerido")
                        : null,
            });
        }
        return validate(data, {});
    },
})(Form);

export default CierreForm;
