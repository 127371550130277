import React from "react";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import { tipo_movimientos } from "utility/variables";

export const TablaHstorialPrecios = (props) => {
    //  State
    const { data, loader, tipo, page, estado_descarga } = props;
    //  Bind
    const { listar, sortChange, descargarListado } = props;
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = page;
    options.onPageChange = listar;
    options.onSortChange = sortChange;

    return (
        <div className="w-100">
            <div className="d-flex flex-row pt-1 pb-3 w-100 mt-10">
                <button
                    className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                    disabled={estado_descarga}
                    onClick={() => {
                        descargarListado();
                    }}
                >
                    <em
                        className={`fa ${
                            estado_descarga
                                ? "fa-spinner fa-pulse"
                                : "fa-download"
                        }`}
                    ></em>
                    &nbsp;Descargar Listado
                </button>
            </div>
            <BootstrapTable
                data={loader ? [] : data.results}
                remote
                pagination
                hover
                fetchInfo={{ dataTotalSize: data.count }}
                options={options}
            >
                <TableHeaderColumn
                    dataSort
                    isKey
                    dataField="creado"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataAlign="center"
                    dataFormat={(cell, row) => {
                        return <RenderDateTime fecha={cell} />;
                    }}
                >
                    Fecha
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cliente_proveedor"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataAlign="center"
                    dataSort
                >
                    {tipo == tipo_movimientos.VENTA ? "Cliente" : "Proveedor"}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort
                    dataField="costo"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataAlign="right"
                    dataFormat={(cell, row) => {
                        return <RenderMoneda monto={cell} />;
                    }}
                >
                    Costo
                </TableHeaderColumn>
                {tipo == tipo_movimientos.VENTA && (
                    <TableHeaderColumn
                        dataSort
                        dataField={"precio"}
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataAlign="right"
                        dataFormat={(cell, row) => {
                            return <RenderMoneda monto={cell} />;
                        }}
                    >
                        Precio
                    </TableHeaderColumn>
                )}
                <TableHeaderColumn
                    dataSort
                    dataField="cantidad"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataAlign="center"
                >
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort
                    dataField="sub_total"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataAlign="right"
                    dataFormat={(cell, row) => {
                        return <RenderMoneda monto={cell} />;
                    }}
                >
                    Subtotal
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};
