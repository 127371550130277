import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/bodega/bodega";
import { actions as reajusteActions } from "../../../../redux/modules/bodega/reajuste";
import ReajusteProductos from './ReajusteProductos';


const ms2p = (state) => {
    return {
        ...state.bodega,
        sucursal: state.usuario.sucursal,
    };
};

const md2p = { ...actions, ...reajusteActions };

export default connect(ms2p, md2p)(ReajusteProductos);
