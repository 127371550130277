import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { validations } from "validations";
import '../login.css';

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            campoUsuarioTouched: false,
            campoPasswordTouched: false
        };
    }

    campoUsuarioMarkAsTouched = () => {
        this.setState({ campoUsuarioTouched: true });
    }

    campoPasswordMarkAsTouched = () => {

    }

    render() {
        const { passError, nameError, submitError, usuario } = this.props;
        // bind
        const { getMe } = this.props;

        if (localStorage.getItem('token')) {
            return (<Redirect to="/dashboard" />);
        }
        return (
            <div className="fondo-login">
                <div className="login-wrapper">
                    <div className="d-none d-md-block d-lg-none container-img-fondo">
                        <img className="img-login" src={require('../../../../../assets/img/fondo-login-tablet.png')} />
                    </div>
                    <div className="contenedor-form-login">
                        <div>
                            <img className="img-login" src={require('../../../../../assets/img/logo_small.png')} />
                        </div>
                        <div className="container-controls">
                            <div id="login" className="panel-body">
                                {/* <p className="text-center pv">INGRESE SU USUARIO Y CONTRASEÑA.</p> */}
                                <form name="loginForm" className="form-validate mb-lg" onSubmit={(e) => {
                                    e.preventDefault();
                                    this.props.onSubmit(this.state);
                                }}
                                >
                                    <div className="form-group has-feedback">
                                        <span className="fa fa-user form-control-feedback text-muted text-muted-login" />
                                        <input id="inputUsername" type="text" name="account_username" placeholder="Usuario"
                                            ref={node => {
                                                this.userName = node;
                                            }}
                                            onChange={() => {
                                                this.setState({ campoUsuarioTouched: true });
                                                if (this.userName) {
                                                    this.setState({ username: this.userName.value });
                                                }
                                            }}
                                            autoComplete="off" required="" className="form-control form-control-login" autoFocus="true"/>
                                        {
                                            this.state.campoUsuarioTouched
                                                ? <p className="form-notice">{validations.required(this.state.username)}</p>
                                                : null
                                        }
                                    </div>
                                    <div className="form-group has-feedback">
                                        <span className="fa fa-lock form-control-feedback text-muted text-muted-login" />
                                        <input id="inputPassword" type="password" name="account_password" placeholder="Contraseña"
                                            ref={node => {
                                                this.userPassword = node;
                                            }}
                                            onChange={() => {
                                                this.setState({ campoPasswordTouched: true });
                                                if (this.userPassword) {
                                                    this.setState({ password: this.userPassword.value });
                                                }
                                            }}
                                            required="" className="form-control form-control-login" />
                                        {
                                            this.state.campoPasswordTouched
                                                ? <p className="form-notice">{validations.required(this.state.password)}</p>
                                                : null
                                        }
                                    </div>
                                    <div className="clearfix" />
                                    {(this.props.loader) && (<div className="loader-container"><div className="loader" /></div>)}
                                    {(!this.props.loader) && (
                                        <div className="footer-login">
                                            <button
                                                onClick={(e) => {
                                                    this.setState({ campoUsuarioTouched: true });
                                                    this.setState({ campoPasswordTouched: true });
                                                    e.preventDefault();
                                                    this.props.onSubmit(this.state);
                                                }}
                                                className="w-100 btn btn-block btn-primary mt-lg btn-login"
                                                type={'submit'}
                                            >
                                                Inciar sesión
                                            </button>
                                            <br/>
                                            <small className="font-weight-bold">¿Olvidaste tu contraseña? <Link to="/recuperar_contraseña">Recuperar</Link></small>
                                            <br/>
                                            <span className="txt-copy">
                                                &copy; 2020 <a href="https://ciancoders.com" target="_blank">Ciancoders</a> - Compralo ®
                                            </span>
                                        </div>
                                    )}
                                </form>
                                <br />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
