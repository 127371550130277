import React from 'react';
import { Field } from "redux-form";


export const renderCheckBox = ({fields ,items, meta}) => {
    let invalid = true;
    fields.getAll().forEach((item) => {
        if (item[Object.keys(item)[0]])
            invalid = false;
    });

    return (
        <div className="w-100">
            {
                items.map((sucursal, index) => {
                    return(
                        <div className="checkbox c-checkbox ml-3" key={sucursal.id}>
                            <label className="needsclick">
                                <Field name={`sucursales[${index}].sucursal-${sucursal.id}`} component="input" type="checkbox"/>
                                <span className="fa fa-check" />
                                {sucursal.nombre}
                            </label>
                        </div>
                    )
                })
            }
            {(invalid && meta.submitFailed) && <div className="invalid-feedback d-block">
                Seleccione al menos una sucursal
            </div>}
        </div>
    )
};
