import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoadMask from "Utils/LoadMask/LoadMask";
import { RenderDate } from "Utils/renderField/renderReadField"
import { TablaProductosDetalleOC } from "../ComponentesRecepcionOC"

class DetalleRecepcionOrdenCompra extends Component {
    static propTypes = {
    }
    componentWillMount() {
        this.props.getOC(this.props.match.params.id, false)
    }
    render() {
        // State
        const { orden_compra, detalles, loader } = this.props
        

        const goBack = () => {
            this.props.history.goBack();
        }
        if (!orden_compra){
            return (
                <div className="grid-container padding-20 text-center">
                    <LoadMask loading={loader} light blur radius>
                        <h2>Orden de compra no encontrada</h2>
                        <button className="btn btn-tertiary" onClick={goBack}> <i className="fa fa-arrow-left" aria-hidden="true" /> Regresar</button>
                    </LoadMask>
                </div>
            );
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container pb-0">
                        <LoadMask loading={loader} light blur radius>
                            <div className="w-100">
                                <div className="px-3 pt-3 d-flex flex-column mb-1">
                                    <div style={{ flex: "1" }}>
                                        <h3>
                                            <b>
                                                Orden de compra No.{" "}
                                                {orden_compra.no_orden}
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 d-flex flex-column align-items-center">
                                    <div className="w-100 row">
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Proveedor</b>
                                                {orden_compra.proveedor.nombre}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Fecha</b>
                                                <RenderDate
                                                    value={orden_compra.fecha}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Sucursal destino</b>
                                                {
                                                    orden_compra
                                                        .sucursal_destino.nombre
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Documento</b>
                                                {orden_compra.num_documento
                                                    ? orden_compra.num_documento
                                                    : "-----"}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Nota</b>
                                                {orden_compra.descripcion}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Estado ingreso</b>
                                                { orden_compra.estado_inventario == 100 && (
                                                    <span className="naranja-oscuro font-weight-bold">Pendiente</span>
                                                )}
                                                { orden_compra.estado_inventario == 200 &&(
                                                    <span className="azul font-weight-bold">Parcial</span>
                                                )}
                                                { orden_compra.estado_inventario == 300 &&(
                                                    <span className="text-success font-weight-bold">Completada</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TablaProductosDetalleOC orden_compra={orden_compra} loader={loader} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}


export default DetalleRecepcionOrdenCompra;