import _ from "lodash";
import moment from "moment";
import { api } from "api";
import React from "react";
import { icons } from "icons";
import { connect } from "react-redux";
import DatePicker from "Utils/DatePicker";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderSearchSelect,
    renderField,
    renderSelectField,
    renderDatePicker,
} from "Utils/renderField/renderField";
import { formatoNombre } from "Utils/CamposComunes";
// VALIDACIONES CUSTOMS
const validate = (values) => {
    const errors = {};
    if (!values.sucursal_destino) errors.sucursal_destino = "Campo requerido";
    if (!values.plazo_pago) errors.plazo_pago = "Campo requerido";
    if (!values.fecha_limite) errors.fecha_limite = "Campo requerido";
    return errors;
};

export function formatoCosto(cell, producto) {
    return (
        <div>
            <div>
                <RenderCurrency value={cell} />
            </div>
        </div>
    );
}

export function formatoSubtotal(cell, producto) {
    let subtotal = 0;
    if (producto.cantidad && producto.costo) {
        subtotal = parseFloat(producto.cantidad) * parseFloat(producto.costo);
    }
    return (
        <div>
            <div>
                <RenderCurrency value={subtotal} />
            </div>
        </div>
    );
}
const listaSucursales = [];
const Form = (props) => {
    // State
    const {
        seleccionados,
        plazoPagoValue,
        fechaLimiteValue,
        loader,
        total,
    } = props;
    // Bind
    const {
        handleSubmit,
        cambioPlazo,
        seleccionFechaLimite,
        asignarDatosTipoPago,
    } = props;

    const getSucursales = (search) => {
        return api
            .get("sucursales", { search })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaSucursales, { id: item.id }))
                        listaSucursales.push(item);
                });
                return { options: listaSucursales };
            });
    };
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader
        ? "Cargando..."
        : "Seleccione un producto para agregar al listado";

    const goBack = () => {
        props.history.goBack();
    };

    let plazos = [
        {
            id: 1,
            value: "inmediato",
            label: "Inmediato",
        },
        {
            id: 2,
            value: 30,
            label: "30 días",
        },
        {
            id: 3,
            value: 60,
            label: "60 días",
        },
        {
            id: 4,
            value: 90,
            label: "90 días",
        },
        {
            id: 5,
            value: 120,
            label: "120 días",
        },
        {
            id: 6,
            value: "personalizado",
            label: "Personalizado",
        },
    ];

    return (
        <form onSubmit={handleSubmit}>
            <div className="slide-container row" style={{ margin: "0" }}>
                <div className="col-12 col-lg-6">
                    <LoadMask loading={loader} light blur radius>
                        <div className="grid-container">
                            <div className="grid-titulo padding-10">
                                Tipo de pago
                            </div>
                            <div className="pl-lg-5 pr-lg-5 pt-lg-2 pb-lg-2 p-0 pt-1 pb-1">
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <label htmlFor="sucursal_destino">
                                            Lugar de recepción
                                        </label>
                                        <div
                                            className="d-flex flex-column pr-0"
                                            style={{ maxWidth: "650px" }}
                                        >
                                            <Field
                                                name="sucursal_destino"
                                                component={renderSearchSelect}
                                                loadOptions={getSucursales}
                                                labelKey="nombre"
                                                valueKey="id"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 form-group">
                                        <label htmlFor="plazo_pago">
                                            Plazo para pagar
                                        </label>
                                        <div
                                            className="d-flex flex-column pr-0"
                                            style={{ maxWidth: "650px" }}
                                        >
                                            <Field
                                                name="plazo_pago"
                                                component={renderSelectField}
                                                options={plazos}
                                                className="form-control"
                                                item_vacio={false}
                                                onChange={(e) => {
                                                    cambioPlazo(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {plazoPagoValue == "personalizado" ? (
                                        <div className="col-12 col-md-6 col-lg-6 form-group">
                                            <label htmlFor="fecha_limite">
                                                Fecha límite
                                            </label>
                                            <div className="d-flex flex-column pr-0">
                                                <DatePicker
                                                    value={fechaLimiteValue}
                                                    minDate={new Date()}
                                                    onChange={(date) => {
                                                        seleccionFechaLimite(
                                                            date
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-12 col-md-6 col-lg-6 form-group">
                                            <label htmlFor="fecha_limite">
                                                Fecha límite
                                            </label>
                                            <div
                                                className="p-1"
                                                style={{
                                                    borderBottom:
                                                        "1px solid  #878787",
                                                }}
                                            >
                                                <RenderDate
                                                    value={fechaLimiteValue}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row d-flex align-items-center justify-content-center">
                                    <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <button
                                            type="button"
                                            className="btn btn-tertiary mr-1"
                                            onClick={() => {
                                                asignarDatosTipoPago();
                                                goBack();
                                            }}
                                        >
                                            {" "}
                                            <img
                                                src={icons.cancelar}
                                                alt=""
                                            />{" "}
                                            Cancelar
                                        </button>
                                        <button
                                            className="btn btn-primary ml-1"
                                            type="submit"
                                        >
                                            <i className="fa fa-check mr" />
                                            Finalizar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="grid-container contenedor-lectura-productos-oc">
                        <div className="encabezado-lista-productos-oc">
                            <h5 className="mb-0 font-weight-bold">
                                Productos de la Orden de compra
                            </h5>
                        </div>
                        <div
                            className="r-encajar backgr-naranja-degradado"
                            style={{ padding: "0 2px" }}
                        >
                            <LoadMask loading={loader} light blur radius>
                                <BootstrapTable
                                    data={loader ? [] : seleccionados}
                                    hover
                                    options={options}
                                    containerStyle={{ backgroundColor: "#FFF" }}
                                    bodyStyle={{ minHeight: "200px" }}
                                >
                                    <TableHeaderColumn
                                        width="0%"
                                        isKey
                                        dataField="id"
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="producto"
                                        dataFormat={formatoNombre}
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Producto
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cantidad"
                                        dataAlign="right"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Cantidad
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="costo"
                                        dataAlign="right"
                                        dataFormat={formatoCosto}
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Costo
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="right"
                                        dataFormat={formatoSubtotal}
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Subtotal
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                        <div className="footer-lista-productos-oc">
                            <h4 className="mb-0">Total</h4>
                            <h4 className="mb-0">
                                <RenderCurrency value={total} />
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

let OCTipoPago = reduxForm({
    form: "OCTipoPago",
    validate,
})(Form);

const selector = formValueSelector("OCTipoPago");
OCTipoPago = connect((state) => {
    const plazoPagoValue = selector(state, "plazo_pago");
    const fechaLimiteValue = selector(state, "fecha_limite");
    return {
        plazoPagoValue,
        fechaLimiteValue,
    };
})(OCTipoPago);

export default OCTipoPago;
