import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { renderTextArea } from "../../Utils/renderField/renderField";
import { ListadoAccion } from "../../Utils/ListadoAccion";
import { DetalleAccion } from "../../Utils/DetalleAccion";
import { icons } from "icons";
import {
    buscar,
    formatoAcciones,
    formatoExistencia,
    formatoInventario,
    formatoNombre,
    formatoProducto,
} from "Utils/CamposComunes";

import { formatoTotalBaja } from "./CamposDetalle";

const Form = (props) => {
    const {
        loader,
        data,
        listar,
        sortChange,
        seleccionados,
        page,
        ver_buscador,
        cambio_buscador,
    } = props;
    const { handleSubmit, actionAyuda, quitar } = props;
    const llavesListado = [
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },
        {
            nombre: "inventario",
            sort: false,
            caption: "Existencias",
            format: formatoExistencia,
            align: "right",
        },
        {
            nombre: "acciones",
            sort: false,
            caption: "Acciones",
            format: formatoAcciones(props),
            align: "center",
            width: "90px",
        },
    ];

    const llavesDetalle = [
        { caption: "Producto", nombre: "producto", format: formatoProducto },
        {
            caption: "Existencias",
            nombre: "existencias",
            format: formatoInventario,
            width: "120px",
            align: "right",
        },
        {
            caption: "Cantidad que da de baja",
            nombre: "baja",
            format: formatoTotalBaja(props),
            width: "180px",
        },
    ];
    return (
        <form onSubmit={handleSubmit} className="row form-group">
            <div className="col-12 mb">
                <div className="grid-container pl-5 pr-5 pt-2 pb-2 d-flex flex-column">
                    <div
                        style={{
                            position: "absolute",
                            top: "25px",
                            right: "25px",
                        }}
                    >
                        <button
                            className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center"
                            style={{ width: "2rem", height: "2rem" }}
                            onClick={actionAyuda}
                        >
                            <img
                                src={icons.ayuda}
                                alt="ayuda"
                                title="Ayuda"
                                style={{ margin: "0", height: "1.1rem" }}
                            />
                        </button>
                    </div>
                    <div className="form-group bodega-info-adicional">
                        <label htmlFor="motivo">
                            Ingrese el motivo de la baja de productos
                        </label>
                        <Field
                            name="motivo"
                            rows={2}
                            component={renderTextArea}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="slide-container row" style={{ margin: "0" }}>
                <div
                    className={`col-lg-6 col-md-12 pr-md-0 ${
                        ver_buscador ? "form-activo" : ""
                    } slide `}
                >
                    <ListadoAccion
                        buscar={buscar(props)}
                        loader={loader}
                        llaves={llavesListado}
                        data={data}
                        listar={listar}
                        sortChange={sortChange}
                        titulo={"Listado de Productos"}
                        page={page}
                    />
                    <div className="d-lg-none d-xl-none grid-container align-items-center mt0 padding-10 d-flex justify-content-around flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                        <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => {
                                cambio_buscador();
                            }}
                        >
                            <img src={icons.cancelar} alt="" /> Cancelar
                        </button>
                    </div>
                </div>
                <div
                    className={`col-lg-6 col-md-12 ${
                        !ver_buscador ? "form-activo" : ""
                    } form-trasero`}
                >
                    <DetalleAccion
                        data={seleccionados}
                        titulo={"Lista de Productos a dar de Baja"}
                        llaves={llavesDetalle}
                        loader={loader}
                        agregar={cambio_buscador}
                        quitar={quitar}
                    />
                    <div
                        style={{
                            borderRadius: "0",
                            padding: "4px 2px 2px 2px",
                            borderTop: "none",
                        }}
                        className="grid-container bg-primary mt0"
                    >
                        <div
                            className="d-flex justify-content-around align-items-center padding-10 flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row"
                            style={{ backgroundColor: "#FFF" }}
                        >
                            {/* <button className="d-lg-none d-xl-none btn btn-outline-primary" type="button" onClick={() => {cambio_buscador()}}>Agregar Producto</button> */}
                            <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                <Link
                                    className="btn btn-tertiary mr-1"
                                    to={"/bodega"}
                                >
                                    <img src={icons.cancelar} alt="" />
                                    Cancelar
                                </Link>
                                <button
                                    disabled={loader}
                                    className="btn btn-primary pull-right ml-1"
                                    type="submit"
                                >
                                    <i className="fa fa-check mr"></i>
                                    Finalizar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const BajasProducto = reduxForm({
    // a unique name for the form
    form: "BajasProducto",
    validate: (data) => {
        return validate(data, {
            motivo: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default BajasProducto;
