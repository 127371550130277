import _ from "lodash";
import { api } from "api";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { push } from "react-router-redux";
import { setLoader, setSeleccionados } from "./bodega";
import { initialize as initializeForm } from "redux-form";
import { listaDestinoSalida } from "../../../common/components/Bodega/SalidaProductos/SalidaProductosForm";

const endpoint = "bodegas";

export const asignarCantidad = (producto, cantidad) => (dispatch, getStore) => {
    const store = getStore();
    const { conf_inventario_negativo } = store.usuario;
    const seleccionados = _.cloneDeep(store.bodega.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (item.inventario < parseInt(cantidad)) {
        if (conf_inventario_negativo) {
            item.cantidad = cantidad;
        } else {
            Swal(
                "ERROR",
                "La cantidad es mayor a la existencia actual del producto",
                "error"
            );
            item.cantidad = item.inventario;
        }
    } else if (cantidad < 0) {
        item.cantidad = 0;
    } else {
        item.cantidad = cantidad;
    }
    seleccionados.splice(index, 1, item);
    dispatch(setSeleccionados(seleccionados));
};

export const efectuarSalidaProducto = () => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.bodega.seleccionados;
    const data = store.form.SalidaProductoForm.values;
    dispatch(setLoader(true));
    const items_cero = _.filter(seleccionados, function (item) {
        if (item.cantidad == undefined || item.cantidad == null) return true;
        else if (parseFloat(item.cantidad) <= 0) return true;
    });

    if (items_cero.length <= 0) {
        api.post(`${endpoint}/salida_producto`, {
            productos: seleccionados,
            destino_salida: data.destino,
            concepto: data.concepto,
        })
            .catch((err) => {
                if (err) {
                    if (err.detail) {
                        Swal("ERROR", err.detail, "error");
                    } else {
                        Swal(
                            "ERROR",
                            "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                            "error"
                        );
                    }
                } else {
                    Swal(
                        "ERROR",
                        "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                        "error"
                    );
                }
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setSeleccionados([]));
                    ToastStore.success("Datos almacenados correctamente");
                    dispatch(push(`/bodega/resumen_de_ingreso/${resp.id}`));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    } else {
        Swal(
            "ERROR",
            "Verifique que las cantidades no sean cero y vuelva a intentar.",
            "error"
        );
        dispatch(setLoader(false));
    }
};

export const crearDestinoSalida = (data) => (dispatch, getStore) => {
    api.post("destino_salida_producto", data)
        .then((data) => {
            ToastStore.success("Nuevo destino almacenado");
            const form = getStore().form.SalidaProductoForm.values;
            listaDestinoSalida.push({ ...data });
            dispatch(
                initializeForm("SalidaProductoForm", {
                    ...form,
                    destino: data.id,
                })
            );
        })
        .catch((err) => {
            console.log(err);
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            // dispatch(setLoader(false));
        });
};

export const actions = {
    asignarCantidad,
    efectuarSalidaProducto,
    crearDestinoSalida,
};
