import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/cuenta_cobrar/cuenta_cobrar";
import { print } from '../../../../redux/modules/print/print'
import Recibo from './Recibo';


const ms2p = (state) => {

    return {
        ...state.cuenta_cobrar,
        sucursal: state.usuario.sucursal,
        factura_personalizada: state.usuario.factura_personalizada,
        formato_factura: state.usuario.formato_factura,
        marcar_entrega: state.usuario.me ? !!state.usuario.me.marcar_entrega : false,
        print_state: state.print,
    };
};

const md2p = { 
    ...actions,
    print: (name) => dispatch => dispatch(print(name)),
};

export default connect(ms2p, md2p)(Recibo);
