import { handleActions } from "redux-actions";
import {ToastStore} from 'react-toasts';

import Swal from "sweetalert2";
import { api } from "api";
import _ from "lodash";
import uuidv1  from "uuid/v1";
import { push } from "react-router-redux";

const endpoint = "recepcion_despachos";

// ------------------------------------
// Constants
// ------------------------------------
export const DATA = "RECEPCION_DESPACHOS_DATA";
export const SELECCIONADOS = "RECEPCION_DESPACHOS_SELECCIONADOS";
export const SET_DESPACHO = "RECEPCION_DESPACHOS_SET_DESPACHO";
export const SORT = "RECEPCION_DESPACHOS_SORT";
export const PAGE = "RECEPCION_DESPACHOS_PAGE";
export const BUSCADOR = "RECEPCION_DESPACHOS_BUSCADOR";
export const LOADER = "RECEPCION_DESPACHOS_LOADER";
export const TAB = "RECEPCION_DESPACHOS_TAB";
export const TIENE_ALERTAS = "RECEPCION_DESPACHOS_TIENE_ALERTAS";

// ------------------------------------
// PureActions
// ------------------------------------

export const seData = data => ({
    type: DATA,
    data,
});

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setBuscador = search => ({
    type: BUSCADOR,
    search,
});

export const setSort = ordering => ({
    type: SORT,
    ordering,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setDespacho = despacho => ({
    type: SET_DESPACHO,
    despacho,
});

export const setTab = tab => ({
    type: TAB,
    tab,
});
export const setTieneAlertas = tiene_alertas => ({
    type: TIENE_ALERTAS,
    tiene_alertas,
});

export const setSeleccionados = seleccionados => ({
    type: SELECCIONADOS,
    seleccionados,
});

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const {ordering, search, tab} = store.recepcion_despacho;
    const params = { ordering, search, estado:tab, page };
    api.get(endpoint, params).catch((err) => { }).then((data) => {
        if (data) {
            dispatch(seData(data));
        }
        dispatch(setPage(page));
        dispatch(setLoader(false));
    });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === 'asc') {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const changeTab = (tab) => (dispatch) => {
    dispatch(setTab(tab))
    dispatch(listar())
}

const getDespacho = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).catch((err) => {
        dispatch(setDespacho(null));
    }).then((data) => {
        if (data) {
            let detalles = _.cloneDeep(data.detalles);
            detalles.forEach(item => {
                item.cantidad_recibido = item.cantidad;
                item.tiene_alerta = false;
            })
            dispatch(setTieneAlertas(false));
            dispatch(setSeleccionados(detalles));
            dispatch(setDespacho(data));
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const asignarCantidadRecibido = (producto, cantidad_recibido) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.recepcion_despacho.seleccionados)
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (cantidad_recibido < 0) {
        item.cantidad_recibido = 0;
    } else {
        item.cantidad_recibido = cantidad_recibido > producto.cantidad ? producto.cantidad : cantidad_recibido;
    }
    seleccionados.splice(index, 1, item);

    let tiene_alertas = false;
    seleccionados.forEach(item => {
        if (item.cantidad_recibido < item.cantidad) {
            if (item.nota == undefined || item.nota == null || item.nota == "") {
                item.tiene_alerta = true;
                tiene_alertas=true;
            }else {
                item.tiene_alerta = false;
            }
        }else {
            item.tiene_alerta = false;
        }
    })
    dispatch(setTieneAlertas(tiene_alertas));
    dispatch(setSeleccionados(seleccionados));
}

const setNotaProducto = (nota, producto) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.recepcion_despacho.seleccionados)
    const item = _.find(seleccionados, { id: producto.id });
    const index = seleccionados.indexOf(item);
    if (item) {
        item.nota = nota;
        seleccionados.splice(index, 1, item);
        let tiene_alertas = false;
        seleccionados.forEach(item => {
            if (item.cantidad_recibido < item.cantidad) {
                if (item.nota == undefined || item.nota == null || item.nota == "") {
                    item.tiene_alerta = true;
                    tiene_alertas = true;
                } else {
                    item.tiene_alerta = false;
                }
            } else {
                item.tiene_alerta = false;
            }
        })
        dispatch(setTieneAlertas(tiene_alertas));
        dispatch(setSeleccionados(seleccionados));
    }
}

const registrarRecepcion = () => (dispatch, getStore) => {
    const store = getStore();
    const { despacho } = store.recepcion_despacho
    let { seleccionados } = store.recepcion_despacho;
    let tiene_alertas = false;
    dispatch(setLoader(true));
    seleccionados.forEach(item => {
        if (!item.cantidad_recibido)
            item.cantidad_recibido = 0;

        if (item.cantidad_recibido < item.cantidad) {
            if (item.nota == undefined || item.nota == null || item.nota == "") {
                tiene_alertas = true;
            }
        }
    })

    if (!tiene_alertas) {
        api.put(`${endpoint}/${despacho.id}`, {detalles: seleccionados}).catch(err => {
            if (err) {
                if (err.detail) {
                    Swal(
                        "ERROR",
                        err.detail,
                        "error"
                    );
                } else {
                    Swal(
                        "ERROR",
                        "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                        "error"
                    );
                }
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                    "error"
                );
            }
        }).then(resp => {
            if (resp) {
                dispatch(setSeleccionados([]));
                ToastStore.success("Datos almacenados correctamente");
                dispatch(setDespacho(resp))
                dispatch(push(`/recepciones_de_despachos/nota_de_recepcion/${resp.id}`));
            }
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const actions = {
    listar,
    sortChange,
    buscar,
    changeTab,
    getDespacho,
    asignarCantidadRecibido,
    setNotaProducto,
    registrarRecepcion
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_DESPACHO]: (state, { despacho }) => {
        return {
            ...state,
            despacho,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [TIENE_ALERTAS]: (state, { tiene_alertas }) => {
        return {
            ...state,
            tiene_alertas,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    despacho: null,
    loader: false,
    page: 1,
    search: "",
    ordering: "-creado",
    tab: 10,
    seleccionados: [],
    tiene_alertas:false,
}

export default handleActions(reducers, initialState);
