import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/despachos";
import ListadoDespachos from "./ListadoDespachos";

const ms2p = (state) => {
    return {
        ...state.reporte_despachos,
        print_state: state.print,
        conf_mostrar_precio_impresion:
            state.usuario.conf_mostrar_precio_impresion,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoDespachos);
