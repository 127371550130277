export { BalanceGeneral } from "./BalanceGeneral";
export { ListadoAperturaCierre } from "./AperturaCierre";
export { ListadoVentas, DetalleVenta } from "./Ventas";
export { ListadoDevoluciones, DetalleDevolucion } from "./Devoluciones";
export { default as MejoresClientes } from "./MejoresClientes";
export { default as ReporteBodega } from "./Bodega";

export { default as ResumenReporteBodega } from "./Bodega/Resumen";

export { default as Inversion } from "./Inversion";

export { default as GananciaBruta } from "./GananciaBruta";

export { ListadoReporteDespachos, DetalleReporteDespacho } from "./Despachos";

export { HistorialPrecios } from "./HistorialPrecios";

export { DetalleMovimientos } from "./Movimientos";

export { HistorialBajasSalidas } from "./HistorialBajasSalidas";

export { DetalleMovimientosGeneral } from "./MovimientosGeneral";

export { ListadoCompras,DetalleOrdenCompraR} from "./OrdenCompra"