import React, { Component } from 'react';
import FileUploader from "../../Utils/FileUploader/FileUploader";
import "./catalogo.css"


export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogo: []
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.catalogo.length === 0 && nextProps.catalogo) {
            const catalogo = [];
            // Hasta 4 porque por ahora son solo 4
            for (let i = 1; i <= 4; i++) {
                if (nextProps.catalogo[`imagen_${i}`]) {
                    catalogo.push({imagen: nextProps.catalogo[`imagen_${i}`]})
                }
            }
            this.setState({ catalogo })
        }
    }

    onFileChange = (e, file, key) => {
        this.props.onFileChange(e, file, "catalogo_"+key);
        file = file || e.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        if (file) {
            if (!file.type.match(pattern)) {
                alert('Formato inválido');
                return;
            }
            reader.onload = (e) => {
                const { catalogo } = this.state;
                catalogo[key] = {imagen: e.target.result};
                this.setState({ catalogo });
            };
            reader.readAsDataURL(file);
        }
    }
    render() {
        const { onFileChange, disabled } = this.props;
        const { catalogo } = this.state;
        return (
            <div className="catalogo-producto row">
                {catalogo.map((item, index) => {
                    return (
                        <div className="col-lg-3 col-md-6" key={index}>
                            <FileUploader
                                onFileChange={onFileChange}
                                icono={'add-img'}
                                clave={`catalogo_${index}`}
                                width="100%"
                                source={item.imagen}
                                height={"70px"}
                            />
                        </div>
                    )
                })}
                {catalogo.length < 4 && (
                    <div className="col-lg-3 col-md-6">
                        <FileUploader
                            disabled={disabled}
                            keepSource
                            source={require("assets/img/icons/upload-add-img.png")}
                            height={"70px"}
                            onFileChange={this.onFileChange}
                            icono={'add-img'}
                            clave={catalogo.length}
                            width="100%"
                        />
                    </div>
                )}
            </div>
        );
    }
}
