import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { icons } from "icons";
import LoadMask from "Utils/LoadMask/LoadMask";
import Toolbar from "Utils/Toolbar/Toolbar";
import ModalAyuda from "Utils/Ayuda"
import "../caja.css";

class CajaListado extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        loader: PropTypes.bool.isRequired,
        eliminar: PropTypes.func.isRequired,
        buscar: PropTypes.func.isRequired,
        sortChange: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
    }
    componentWillMount() {
        this.props.listar();
        this.state = {
            modalAyuda: false,
            itemsApertura: [
                {
                    src: icons.apertura_caja_p3,
                    alt: "Paso 1"
                }
            ]
        }
    }
    closeModal = () => {
        this.setState({modalAyuda: false});
    }
    openModal = () => {
        this.setState({modalAyuda: true});
    }

    render() {
        // state
        const { data, loader, usuario } = this.props;
        const { modalAyuda, itemsApertura } = this.state;

        // bind
        const { eliminar } = this.props;

        return (
            <div id="cajas-listado" className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={loader} light blur radius>
                        <div className="grid-container">
                            <div style={{ position: "absolute", top: "10px", right: "10px", zIndex:"1" }}>
                                <button type="button" className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center" style={{ width: "2rem", height: "2rem" }} onClick={this.openModal}>
                                    <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{ margin: "0", height: "1.1rem" }} />
                                </button>
                            </div>
                            <div className="grid-titulo">
                                <Toolbar titulo={"Cajas"} />
                            </div>
                            <br />
                            <div className="d-flex flex-row flex-wrap justify-content-around justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xl-start padding-15">
                                {data.map((caja) => {
                                    return (
                                        <div key={caja.nombre} className={` text-center m-2 ${caja.aperturado_por ? '' : 'activo'}`}>
                                            <div className="caja-titulo"><b>{caja.nombre}</b></div>
                                            {caja.aperturado_por ? (
                                                <div>
                                                    <Link className="btn btn-secondary disabled mb-1" to={`/cajas/apertura/${caja.id}`}>
                                                        <div className="d-flex flex-column align-items-center">
                                                            <img className="caja-img mb-2" style={{maxWidth: "60px", margin: "0"}} src={icons.caja_inactivo} alt="" />
                                                            En uso por {caja.aperturado_por.first_name}
                                                        </div>
                                                    </Link>
                                                    {/* {(usuario.es_admin) && (<div style={{ padding: "5px" }}>
                                                        <a className="disabled">
                                                            <img className="action-img" src={icons.editar} alt="editar" />
                                                        </a>
                                                        <a className="disabled">
                                                            <img className="action-img" src={icons.eliminar} alt="eliminar" />
                                                        </a>
                                                    </div>)} */}
                                                </div>
                                            ) : (
                                                <div>
                                                    <Link className="btn btn-secondary mb-1" to={`/cajas/apertura/${caja.id}`}>
                                                        <div className="d-flex flex-column align-items-center">
                                                            <img className="caja-img mb-2" style={{maxWidth: "60px", margin: "0"}} src={icons.caja} alt="" />
                                                            <div>
                                                                <img style={{margin: "2px"}} src={icons.candado_a} alt="" />Aperturar Caja
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    {/* {(usuario.es_admin) && (<div style={{ padding: "5px" }}>
                                                        <Link to={`/cajas/editar/${caja.id}`}>
                                                            <img className="action-img" src={icons.editar} alt="editar" />
                                                        </Link>
                                                        <a onClick={() => {
                                                            this.props.eliminar(caja.id)
                                                        }}>
                                                            <img className="action-img" src={icons.eliminar} alt="eliminar" />
                                                        </a>
                                                    </div>)} */}
                                                </div>
                                            )}

                                        </div>
                                    );
                                })}
                                {/* {(usuario.es_admin) && (<div style={{ marginTop: "44px" }} className="ml-2 mr-2 text-center">
                                    <Link to={"/cajas/crear"}>
                                        <button className="btn btn-caja">
                                            <img src={icons.caja_nueva} alt="" />
                                            <div>
                                                Agregar Caja
                                            </div>
                                        </button>
                                    </Link>
                                </div>)} */}
                            </div>
                            <br />
                            {
                               usuario.conf_caja && (
                                    <div className="mb w-100 text-center">
                                        <Link className="btn btn-primary" to="/configuracion/cajas/crear"><em className="fa fa-plus"></em> Agregar Caja</Link>
                                    </div>
                                )
                            }
                        </div>
                    </LoadMask>
                </div>
                <ModalAyuda open={modalAyuda} closeModal={this.closeModal} openModal={this.openModal} items={itemsApertura}/>
            </div>
        )
    }
}

export default CajaListado
