import React, { Component } from "react";
import { icons } from "icons";
import Recibo from "Utils/Recibo";
import PropTypes from "prop-types";
import Factura from "Utils/Factura";
import ModalAyuda from "Utils/Ayuda";
import { Link } from "react-router-dom";
import MovimientoForm from "./Movimiento";
import Modal from "react-responsive-modal";
import Toolbar from "Utils/Toolbar/Toolbar";
import { FACTURADO, tipo_movimientos } from "utility/variables";
import LoadMask from "Utils/LoadMask/LoadMask";
import ReciboVentaCredito from "Utils/ReciboVentaCredito";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BreakLine, tableOptions } from "Utils/tableOptions";
// import { ReadFields } from "Utils/renderField/renderReadField";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { PrintContainer, PrintComponent } from "Utils/Print";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import {
    DropDownMenu,
    DropDownDirection,
    ActionItem,
    HeaderItem,
} from "react-dropdown-advanced";

class DetalleCaja extends Component {
    static propTypes = {
        caja: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            tipo_movimiento: "",
            dropdownItems: [
                new HeaderItem("¿Con qué necesitas ayuda?"),
                new ActionItem(
                    "aperturar_caja",
                    "¿Cómo realizar una apertura de caja?"
                ),
                new ActionItem(
                    "cierre_caja",
                    "¿Cómo realizar el cierre de caja?"
                ),
                new ActionItem("agregar_efectivo", "¿Cómo agregar efectivo?"),
                new ActionItem("remover_efectivo", "¿Cómo remover efectivo?"),
                new ActionItem(
                    "anular_movimiento",
                    "¿Cómo anular un movimiento?"
                ),
            ],
            itemsAyuda: [
                // {key:"aperturar_caja", texto: "¿Cómo realizar una apertura de caja?"},
                {
                    key: "cierre_caja",
                    texto: "¿Cómo realizar el cierre de caja?",
                },
                { key: "agregar_efectivo", texto: "¿Cómo agregar efectivo?" },
                { key: "remover_efectivo", texto: "¿Cómo remover efectivo?" },
                {
                    key: "anular_movimiento",
                    texto: "¿Cómo anular un movimiento?",
                },
            ],
            modalAyuda: false,
            itemsImagenes: [],
            itemsApertura: [
                {
                    src: icons.apertura_caja_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.apertura_caja_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.apertura_caja_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.apertura_caja_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.apertura_caja_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.apertura_caja_p6,
                    alt: "Paso 6",
                },
            ],
            itemsCierre: [
                {
                    src: icons.cierre_caja_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.cierre_caja_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.cierre_caja_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.cierre_caja_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.cierre_caja_p5,
                    alt: "Paso 5",
                },
            ],
            itemsAgregarEfectivo: [
                {
                    src: icons.agregar_efectivo_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.agregar_efectivo_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.agregar_efectivo_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.agregar_efectivo_p4,
                    alt: "Paso 4",
                },
            ],
            itemsRemoverEfectivo: [
                {
                    src: icons.remover_efectivo_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.remover_efectivo_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.remover_efectivo_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.remover_efectivo_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.remover_efectivo_p5,
                    alt: "Paso 5",
                },
            ],
            itemsAnularMovimiento: [
                {
                    src: icons.anular_movimiento_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.anular_movimiento_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.anular_movimiento_p3,
                    alt: "Paso 3",
                },
            ],
        };
        this.closeModal = this.closeModal.bind(this);
        this.nuevoEgreso = this.nuevoEgreso.bind(this);
        this.nuevoIngreso = this.nuevoIngreso.bind(this);
    }
    nuevoIngreso() {
        this.setState({ open: true, tipo_movimiento: "ingreso" });
    }
    nuevoEgreso() {
        this.setState({ open: true, tipo_movimiento: "egreso" });
    }
    closeModal(data) {
        if (data.total) {
            if (this.state.tipo_movimiento === "ingreso") {
                this.props.ingresa();
            } else {
                this.props.egresa();
            }
        }
        this.setState({ open: false });
    }

    componentWillMount() {
        this.props.getCierreCaja();
    }

    onClickAyuda = (item) => {
        if (item.key === "aperturar_caja") {
            this.setState({
                itemsImagenes: this.state.itemsApertura,
            });
            this.openModalAyuda();
        } else if (item.key === "cierre_caja") {
            this.setState({
                itemsImagenes: this.state.itemsCierre,
            });
            this.openModalAyuda();
        } else if (item.key === "agregar_efectivo") {
            this.setState({
                itemsImagenes: this.state.itemsAgregarEfectivo,
            });
            this.openModalAyuda();
        } else if (item.key === "remover_efectivo") {
            this.setState({
                itemsImagenes: this.state.itemsRemoverEfectivo,
            });
            this.openModalAyuda();
        } else if (item.key === "anular_movimiento") {
            this.setState({
                itemsImagenes: this.state.itemsAnularMovimiento,
            });
            this.openModalAyuda();
        }
    };

    closeModalAyuda = () => {
        this.setState({ modalAyuda: false });
    };
    openModalAyuda = () => {
        this.setState({ modalAyuda: true });
    };

    render() {
        // state
        const {
            ultimo_cierre: caja,
            loader,
            data,
            page,
            data_movimientos_caja,
            loader_movimiento_caja,
            factura_personalizada,
            formato_factura,
            venta_impresion,
            utiliza_fel,
            print_state,
            recibo_caja_impresion,
        } = this.props;
        const {
            modalAyuda,
            itemsImagenes,
            itemsAyuda,
            dropdownItems,
        } = this.state;
        // bind
        const {
            anularVenta,
            buscarMovimientoCaja,
            getListaMovimientos,
            seleccionImpresion,
            retimbradoFEL,
        } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader_movimiento_caja
            ? "Cargando..."
            : options.noDataText;
        options.page = data_movimientos_caja.page;
        options.onPageChange = getListaMovimientos;
        options.onSortChange = this.props.sortChangeMovimientoCaja;

        //  TOTALES PARA EL RESUMEN DE CAJA
        let total_ventas_brutas = 0;
        let total_facturacion_venta = 0;
        //  DETALLE CAJA
        let total_tarjeta = 0;
        let total_efectivo = 0;
        let total_cheque = 0;
        let total_deposito = 0;
        let total_nota_credito = 0;
        let total_retencion_iva = 0;
        let total_exencion_iva = 0;
        let total_general_detalle_caja = 0;
        //  RESULTADO
        let total_resultado = 0;
        if (caja) {
            const total_ventas = caja.total_ventas ? caja.total_ventas : 0;
            const monto_total_ventas_anuladas = caja.monto_total_ventas_anuladas
                ? caja.monto_total_ventas_anuladas
                : 0;
            const total_venta_credito = caja.total_venta_credito
                ? caja.total_venta_credito
                : 0;
            const total_egreso_efectivo = caja.total_egreso_efectivo
                ? caja.total_egreso_efectivo
                : 0;

            //  DETALLE CAJA
            const detalle_total_ventas = caja.detalle_total_ventas
                ? caja.detalle_total_ventas
                : {};
            const total_apertura = caja.total_apertura
                ? caja.total_apertura
                : 0;
            const total_ventas_tarjeta = detalle_total_ventas.total_tarjeta
                ? detalle_total_ventas.total_tarjeta
                : 0;
            const total_ventas_efectivo = detalle_total_ventas.total_efectivo
                ? detalle_total_ventas.total_efectivo
                : 0;
            const total_ventas_cheque = detalle_total_ventas.total_cheque
                ? detalle_total_ventas.total_cheque
                : 0;
            const total_ventas_deposito = detalle_total_ventas.total_deposito
                ? detalle_total_ventas.total_deposito
                : 0;
            const total_ventas_nota_credito = detalle_total_ventas.total_nota_credito
                ? detalle_total_ventas.total_nota_credito
                : 0;
            const total_ventas_retencion_iva = detalle_total_ventas.total_retencion_iva
                ? detalle_total_ventas.total_retencion_iva
                : 0;
            const total_ventas_exencion_iva = detalle_total_ventas.total_exencion_iva
                ? detalle_total_ventas.total_exencion_iva
                : 0;

            //  ABONOS
            const abonos = caja.abonos ? caja.abonos : {};
            const total_abonos = abonos.total ? abonos.total : 0;
            const total_abono_tarjeta = abonos.total_tarjeta
                ? abonos.total_tarjeta
                : 0;
            const total_abono_efectivo = abonos.total_efectivo
                ? abonos.total_efectivo
                : 0;
            const total_abono_cheque = abonos.total_cheque
                ? abonos.total_cheque
                : 0;
            const total_abono_deposito = abonos.total_deposito
                ? abonos.total_deposito
                : 0;
            const total_abono_nota_credito = abonos.total_nota_credito
                ? abonos.total_nota_credito
                : 0;
            const total_abono_retencion_iva = abonos.total_retencion_iva
                ? abonos.total_retencion_iva
                : 0;
            const total_abono_exencion_iva = abonos.total_exencion_iva
                ? abonos.total_exencion_iva
                : 0;

            total_ventas_brutas =
                total_ventas +
                monto_total_ventas_anuladas +
                total_venta_credito;
            total_facturacion_venta =
                total_ventas_brutas -
                monto_total_ventas_anuladas -
                total_venta_credito -
                total_egreso_efectivo;
            total_tarjeta = total_ventas_tarjeta + total_abono_tarjeta;
            total_efectivo =
                total_apertura + total_ventas_efectivo + total_abono_efectivo;
            total_cheque = total_ventas_cheque + total_abono_cheque;
            total_deposito = total_ventas_deposito + total_abono_deposito;
            total_nota_credito =
                total_ventas_nota_credito + total_abono_nota_credito;
            total_retencion_iva =
                total_ventas_retencion_iva + total_abono_retencion_iva;
            total_exencion_iva =
                total_ventas_exencion_iva + total_abono_exencion_iva;

            total_general_detalle_caja =
                total_efectivo +
                total_tarjeta +
                total_cheque +
                total_deposito +
                total_retencion_iva +
                total_exencion_iva;
            if (utiliza_fel) {
                total_general_detalle_caja += total_nota_credito;
            }

            //  RESULTADO
            total_resultado =
                total_general_detalle_caja -
                total_facturacion_venta -
                total_abonos -
                total_apertura;
        }

        const accionesReciboFactura = (id, data) => {
            const timbrado = data.datos_fel
                ? data.datos_fel.uuid ||
                  data.datos_fel.numero ||
                  data.datos_fel.serie
                    ? true
                    : false
                : false;
            const datos_anulacion = data.datos_fel
                ? data.datos_fel.datos_anulacion &&
                  data.datos_fel.respuesta_anulacion
                    ? true
                    : false
                : false;

            return (
                <span>
                    <img
                        onClick={(e) => {
                            e.preventDefault();
                            seleccionImpresion(
                                id,
                                "puntoVentasReimpresionVenta"
                            );
                        }}
                        className="action-img"
                        title={`Reimprimir ${
                            factura_personalizada || utiliza_fel
                                ? "factura"
                                : "recibo"
                        }`}
                        src={utiliza_fel ? icons.fel : icons.recibo}
                        alt={`Reimprimir ${
                            factura_personalizada ? "factura" : "recibo"
                        }`}
                        style={{ maxWidth: "22px" }}
                    />
                    {utiliza_fel &&
                        !timbrado &&
                        data.tipo_movimiento == "Venta" &&
                        !datos_anulacion && (
                            <img
                                onClick={(e) => {
                                    e.preventDefault();
                                    retimbradoFEL(data.id_venta);
                                }}
                                className="action-img"
                                title="Retimbrar factura"
                                src={icons.timbrar_factura}
                                alt="Retimbrar factura"
                                style={{ maxWidth: "22px" }}
                            />
                        )}
                </span>
            );
        };

        const accionReciboCaja = (id, data) => {
            if (data.id_venta) {
                return (
                    <span>
                        <img
                            onClick={(e) => {
                                e.preventDefault();
                                seleccionImpresion(
                                    data.id_venta,
                                    "detalleCajaReimpresionReciboCaja"
                                );
                            }}
                            className="action-img"
                            title="Reimprimir recibo de caja"
                            src={icons.recibo}
                            alt="Reimprimir recibo de caja"
                            style={{ maxWidth: "22px" }}
                        />
                    </span>
                );
            }
            return "";
        };

        return (
            <div className="row mt">
                {print_state.flag && <PrintComponent />}
                <div className="col-12">
                    <LoadMask loading={loader} light blur radius>
                        <div
                            className="grid-container"
                            style={{ marginTop: "0" }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: "15px",
                                    right: "15px",
                                }}
                            >
                                <button
                                    className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center"
                                    style={{ width: "2rem", height: "2rem" }}
                                >
                                    <img
                                        src={icons.ayuda}
                                        alt="ayuda"
                                        title="Ayuda"
                                        style={{
                                            margin: "0",
                                            height: "1.1rem",
                                        }}
                                    />
                                    <DropDownMenu
                                        items={dropdownItems}
                                        onClick={this.onClickAyuda}
                                        direction={DropDownDirection.DownLeft}
                                        className="fnt-10"
                                    />
                                </button>
                            </div>
                            <div className="grid-titulo">
                                <div className="px-3 pt-3 pb-0">
                                    Detalle Caja
                                </div>
                                <div className="row pb-1 pb-sm-1">
                                    <Modal
                                        open={this.state.open}
                                        onClose={this.closeModal}
                                        classNames={{ modal: "modal-sm" }}
                                    >
                                        <div
                                            style={{
                                                padding: "1.2rem",
                                                maxWidth: "400px",
                                            }}
                                        >
                                            <div className="row">
                                                <div className="col-12 grid-titulo">
                                                    {this.state
                                                        .tipo_movimiento ===
                                                    "ingreso"
                                                        ? "Ingreso"
                                                        : "Retiro"}{" "}
                                                    de Efectivo
                                                </div>
                                                <MovimientoForm
                                                    styles={{
                                                        modal: {
                                                            minWidth: "100%",
                                                        },
                                                    }}
                                                    onSubmit={(data) => {
                                                        if (!loader) {
                                                            this.closeModal(
                                                                data
                                                            );
                                                        }
                                                    }}
                                                    close={this.closeModal}
                                                    tipo={
                                                        this.state
                                                            .tipo_movimiento
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Modal>
                                    <div className="col-lg-6 col-md-10 col-sm-12">
                                        <div className="d-flex justify-content-around flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                            <button
                                                className="btn btn-outline-secondary m-1 align-self-center fnt-sm-10"
                                                onClick={this.nuevoEgreso}
                                            >
                                                <em className="fa fa-minus"></em>{" "}
                                                Remover Efectivo
                                            </button>
                                            <button
                                                className="btn btn-outline-secondary m-1 align-self-center fnt-sm-10"
                                                onClick={this.nuevoIngreso}
                                            >
                                                <em className="fa fa-plus"></em>{" "}
                                                Agregar Efectivo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex justify-content-around flex-column">
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row mb-2">
                                        <b className="flex1">Usuario:</b>
                                        <span className="flex1 pl-2">
                                            {caja
                                                ? caja.usuario.first_name
                                                : ""}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row mb-2">
                                        <b className="flex1">Sucursal:</b>
                                        <span className="flex1 pl-2">
                                            {caja
                                                ? caja.caja.nombre_sucursal
                                                : ""}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row mb-2">
                                        <b className="flex1">Caja:</b>
                                        <span className="flex1 pl-2">
                                            {caja ? caja.caja.nombre : ""}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row mb-2">
                                        <b className="flex1">
                                            Fecha de apertura:
                                        </b>
                                        <RenderDateTime
                                            className="flex1 pl-2"
                                            fecha={caja ? caja.creado : null}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row mb-2">
                                        <b className="flex1">Apertura:</b>
                                        <RenderCurrency
                                            value={
                                                caja ? caja.total_apertura : 0
                                            }
                                            className="flex1 pl-2"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row p-3 grid-end-seccion">
                                <div className="col-lg-5 col-md-6 col-12 d-flex flex-column">
                                    <b style={{ marginLeft: "-15px" }}>
                                        Detalle de{" "}
                                        {utiliza_fel ? "facturación" : "venta"}
                                    </b>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>Ventas brutas:</b>
                                        <b>
                                            <RenderCurrency
                                                value={total_ventas_brutas}
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>
                                            (-) Ventas anuladas (
                                            {caja.total_ventas_anuladas
                                                ? caja.total_ventas_anuladas
                                                : 0}
                                            ):
                                        </b>
                                        <b>
                                            <RenderCurrency
                                                value={
                                                    caja.monto_total_ventas_anuladas
                                                        ? caja.monto_total_ventas_anuladas
                                                        : 0
                                                }
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>(-) Créditos:</b>
                                        <b>
                                            <RenderCurrency
                                                value={
                                                    caja.total_venta_credito
                                                        ? caja.total_venta_credito
                                                        : 0
                                                }
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>(-) Egreso en efectivo:</b>
                                        <b>
                                            <RenderCurrency
                                                value={
                                                    caja.total_egreso_efectivo
                                                        ? caja.total_egreso_efectivo
                                                        : 0
                                                }
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>
                                            Total de{" "}
                                            {utiliza_fel
                                                ? "facturación"
                                                : "venta"}
                                            :
                                        </b>
                                        <b>
                                            <RenderCurrency
                                                value={total_facturacion_venta}
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>Total de abonos:</b>
                                        <b>
                                            <RenderCurrency
                                                value={
                                                    caja.abonos &&
                                                    caja.abonos.total
                                                        ? caja.abonos.total
                                                        : 0
                                                }
                                            />
                                        </b>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6 col-12 d-flex flex-column">
                                    <b style={{ marginLeft: "-15px" }}>
                                        Detalle de Caja
                                    </b>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>(+) Efectivo:</b>
                                        <b>
                                            <RenderCurrency
                                                value={total_efectivo}
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>(+) Tarjeta de crédito:</b>
                                        <b>
                                            <RenderCurrency
                                                value={total_tarjeta}
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>(+) Cheque:</b>
                                        <b>
                                            <RenderCurrency
                                                value={total_cheque}
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>(+) Depósito:</b>
                                        <b>
                                            <RenderCurrency
                                                value={total_deposito}
                                            />
                                        </b>
                                    </div>
                                    {utiliza_fel && (
                                        <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                            <b>(+) Nota de crédito:</b>
                                            <b>
                                                <RenderCurrency
                                                    value={total_nota_credito}
                                                />
                                            </b>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>(+) Retención de IVA:</b>
                                        <b>
                                            <RenderCurrency
                                                value={total_retencion_iva}
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>(+) Exención de IVA:</b>
                                        <b>
                                            <RenderCurrency
                                                value={total_exencion_iva}
                                            />
                                        </b>
                                    </div>
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>Total general:</b>
                                        <b>
                                            <RenderCurrency
                                                value={
                                                    total_general_detalle_caja
                                                }
                                            />
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-3">
                                <div className="col-lg-5 col-md-6 col-12 d-flex flex-column">
                                    <div className="d-flex justify-content-between flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <b>Resultado (+)(-):</b>
                                        <b>
                                            <RenderCurrency
                                                value={total_resultado}
                                            />
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div className="row  padding-15 text-center">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <Link
                                        className="btn btn-primary"
                                        to={"/caja_activa/cierre"}
                                    >
                                        <img src={icons.candado_c} alt="" />
                                        Cerrar Caja
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Listado de movimientos"}
                                buscar={buscarMovimientoCaja}
                                search={data_movimientos_caja.search}
                            />
                            {/* <div className="padding-15">Ventas activas</div> */}
                        </div>
                        <LoadMask
                            loading={loader_movimiento_caja}
                            light
                            blur
                            radius
                        >
                            <BootstrapTable
                                data={
                                    loader_movimiento_caja
                                        ? []
                                        : data_movimientos_caja.data.results
                                }
                                remote
                                pagination
                                hover
                                fetchInfo={{
                                    dataTotalSize:
                                        data_movimientos_caja.data.count,
                                }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    width="0%"
                                    isKey
                                    dataField="id"
                                >
                                    ID
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataSort
                                    dataField="creado"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <RenderDateTime
                                                fecha={cell}
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            />
                                        );
                                    }}
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="tipo_movimiento"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <span
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            >
                                                {cell}
                                            </span>
                                        );
                                    }}
                                >
                                    Tipo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="concepto"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        const timbrado = row.datos_fel
                                            ? row.datos_fel.estado_factura ==
                                              FACTURADO
                                                ? true
                                                : false
                                            : false;
                                        const datos_anulacion = row.datos_fel
                                            ? row.datos_fel.datos_anulacion &&
                                              row.datos_fel.respuesta_anulacion
                                                ? true
                                                : false
                                            : false;
                                        const tiene_fel = row.datos_fel;
                                        return (
                                            <div
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            >
                                                {row.tipo_movimiento ==
                                                    "Venta" && (
                                                    <span>
                                                        Venta {row.codigo_venta}{" "}
                                                        {utiliza_fel ? (
                                                            timbrado ? (
                                                                <span className="text-success font-weight-bold">
                                                                    Facturada
                                                                </span>
                                                            ) : datos_anulacion ? (
                                                                <span className="text-danger font-weight-bold">
                                                                    Error en
                                                                    anulación
                                                                </span>
                                                            ) : !tiene_fel ? (
                                                                <span className="text-warning font-weight-bold">
                                                                    Pendiente de
                                                                    facturación
                                                                </span>
                                                            ) : (
                                                                <span className="text-danger font-weight-bold">
                                                                    Error
                                                                    <i
                                                                        className="fa fa-eye text-danger ml-2"
                                                                        title={
                                                                            row
                                                                                .datos_fel
                                                                                .errores_fel
                                                                        }
                                                                    ></i>
                                                                </span>
                                                            )
                                                        ) : (
                                                            ""
                                                        )}
                                                    </span>
                                                )}
                                                {row.tipo_movimiento ==
                                                    "Retiro" && (
                                                    <span>
                                                        {cell}{" "}
                                                        {row.num_documento &&
                                                            ", No. Boleta: " +
                                                                row.num_documento}
                                                    </span>
                                                )}
                                                {row.tipo_movimiento !=
                                                    "Retiro" &&
                                                    row.tipo_movimiento !=
                                                        "Venta" && (
                                                        <span>
                                                            Venta{" "}
                                                            {row.codigo_venta}{" "}
                                                            {utiliza_fel ? (
                                                                timbrado ? (
                                                                    <span className="text-success font-weight-bold">
                                                                        Facturada
                                                                    </span>
                                                                ) : datos_anulacion ? (
                                                                    <span className="text-danger font-weight-bold">
                                                                        Error en
                                                                        anulación
                                                                    </span>
                                                                ) : !tiene_fel ? (
                                                                    <span className="text-warning font-weight-bold">
                                                                        Pendiente
                                                                        de
                                                                        facturación
                                                                    </span>
                                                                ) : (
                                                                    <span className="text-danger font-weight-bold">
                                                                        Error
                                                                        <i
                                                                            className="fa fa-eye text-danger ml-2"
                                                                            title={
                                                                                row
                                                                                    .datos_fel
                                                                                    .errores_fel
                                                                            }
                                                                        ></i>
                                                                    </span>
                                                                )
                                                            ) : (
                                                                ""
                                                            )}
                                                        </span>
                                                    )}
                                            </div>
                                        );
                                    }}
                                >
                                    Concepto
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="concepto"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <span
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            >
                                                {cell}
                                            </span>
                                        );
                                    }}
                                >
                                    Nota
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataSort
                                    dataField="cliente_proveedor__nombre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <span
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            >
                                                {row.cliente_proveedor
                                                    ? row.cliente_proveedor
                                                          .nombre
                                                    : ""}
                                            </span>
                                        );
                                    }}
                                >
                                    Comprador
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn
                                    dataSort
                                    dataField="usuario__first_name"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return row.usuario.first_name;
                                    }}
                                >
                                    Operador
                                </TableHeaderColumn> */}
                                <TableHeaderColumn
                                    dataSort
                                    dataField="monto_efectivo"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="right"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <RenderMoneda
                                                monto={cell}
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            />
                                        );
                                    }}
                                >
                                    Efectivo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataSort
                                    dataField="monto_tarjeta"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="right"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <RenderMoneda
                                                monto={cell}
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            />
                                        );
                                    }}
                                >
                                    Tarjeta
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataSort
                                    dataField="monto_cheque"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="right"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <RenderMoneda
                                                monto={cell}
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            />
                                        );
                                    }}
                                >
                                    Cheque
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataSort
                                    dataField="monto_deposito"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="right"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <RenderMoneda
                                                monto={cell}
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            />
                                        );
                                    }}
                                >
                                    Depósito
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total_cobrar_pagar"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="right"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <RenderMoneda
                                                monto={row.total_cobrar_pagar}
                                                className={`${
                                                    row.anulado
                                                        ? "text-underline"
                                                        : ""
                                                }`}
                                            />
                                        );
                                    }}
                                >
                                    Pago de
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    width="170px"
                                    dataFormat={(cell, row) => {
                                        if (row.anulado) {
                                            return (
                                                <span className="text-danger font-weight-bold">
                                                    Anulado
                                                </span>
                                            );
                                        } else {
                                            if (row.id_venta) {
                                                if (
                                                    row.tipo ===
                                                    tipo_movimientos.VENTA
                                                ) {
                                                    return activeFormatter({
                                                        anular: anularVenta,
                                                        anular_activo:
                                                            row.caja_activa,
                                                        ver:
                                                            "/punto_de_venta/venta",
                                                        id_ver: row.id_venta,
                                                        adicional: accionesReciboFactura,
                                                        id_adicional:
                                                            row.id_venta,
                                                        datos_adicional: row,
                                                    })(cell, row);
                                                } else if (
                                                    row.tipo ==
                                                    tipo_movimientos.ABONO_CUENTA
                                                ) {
                                                    return activeFormatter({
                                                        anular: anularVenta,
                                                        adicional: accionReciboCaja,
                                                        datos_adicional: row,
                                                    })(cell, row);
                                                } else {
                                                    return activeFormatter({
                                                        anular: anularVenta,
                                                    })(cell, row);
                                                }
                                            } else {
                                                return activeFormatter({
                                                    anular: anularVenta,
                                                    anular_activo:
                                                        row.caja_activa,
                                                })(cell, row);
                                            }
                                        }
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                    <ModalAyuda
                        open={modalAyuda}
                        closeModal={this.closeModalAyuda}
                        openModal={this.openModalAyuda}
                        items={itemsImagenes}
                    />
                </div>
                {
                    // RECIBO Y FACTURA
                    !factura_personalizada ? (
                        <div className="w100 print-only">
                            <PrintContainer name="puntoVentasReimpresionVenta">
                                {venta_impresion && (
                                    <Recibo
                                        sucursal={venta_impresion.sucursal}
                                        movimiento={venta_impresion}
                                        usuario={
                                            venta_impresion.usuario.first_name
                                                ? venta_impresion.usuario
                                                      .first_name
                                                : ""
                                        }
                                        footerText={"TOTAL"}
                                        tipo={"Venta"}
                                        utiliza_fel={utiliza_fel}
                                    />
                                )}
                            </PrintContainer>
                        </div>
                    ) : (
                        <div className="w100 print-only">
                            <PrintContainer
                                name="puntoVentasReimpresionVenta"
                                className="impresion-venta d-table w-100"
                            >
                                {venta_impresion &&
                                    venta_impresion.map((factura, index) => {
                                        return (
                                            <Factura
                                                className={`${
                                                    index != 0
                                                        ? "salto-pagina"
                                                        : ""
                                                }`}
                                                key={index}
                                                datos_venta={factura}
                                                formato_factura={
                                                    formato_factura
                                                }
                                            />
                                        );
                                    })}
                            </PrintContainer>
                        </div>
                    )
                }
                {/* RECIBO VENTA CREDITO */}
                <div className="w100 print-only">
                    <PrintContainer
                        name="detalleCajaReimpresionReciboCaja"
                        className="impresion-venta d-table w-100"
                    >
                        {recibo_caja_impresion && (
                            <ReciboVentaCredito
                                sucursal={recibo_caja_impresion.sucursal}
                                movimiento={recibo_caja_impresion}
                                utiliza_fel={utiliza_fel}
                            />
                        )}
                    </PrintContainer>
                </div>
            </div>
        );
    }
}

export default DetalleCaja;
