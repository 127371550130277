import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/proveedor/proveedor";
import Listado from './ListadoProveedores';


const ms2p = (state) => {
    return {
        ...state.proveedor,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Listado);
