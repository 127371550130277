import React from 'react'
import { icons } from "icons"
import { Field, reduxForm } from "redux-form"
import NumberFormat from 'react-number-format';
import {BreakLine, tableOptions} from "Utils/tableOptions";
import { RenderNumero } from "Utils/renderField/renderTableField";
import { renderField, renderTextArea} from "Utils/renderField/renderField"

export function formatoCantidadRecibido(props) {
    return (cell, detalle) => {
        return (<div className="d-flex flex-row">
            <button className="btn btn-cantidad-detalle btn-menos" onClick={() => {
                const cantidad_recibido = detalle.a_recibir ? detalle.a_recibir : 0;
                props.asignarCantidadRecibido(detalle, cantidad_recibido - 1)
            }} type="button">
                <img src={icons.menos} alt="-"/>
            </button>
            <NumberFormat className="form-control bodega-ingreso text-right"
                placeholder="0"
                decimalScale={detalle.fraccion.producto.a_granel ? 3 : 0} fixedDecimalScale={detalle.fraccion.producto.a_granel ? true : false}
                value={detalle.a_recibir} thousandSeparator={true} prefix={''}
                style={{ padding: "2px" }}
                onValueChange={(values) => {
                    let val = "";
                    if (values.value != "" && values.value != undefined && values.value != null )
                        val = parseInt(values.value)
                    props.asignarCantidadRecibido(detalle, val)
                }}
            />
            <button className="btn btn-cantidad-detalle btn-mas" onClick={() => {
                const cantidad_recibido = detalle.a_recibir ? detalle.a_recibir : 0;
                props.asignarCantidadRecibido(detalle, cantidad_recibido + 1)
            }} type="button">
                <img src={icons.mas} alt="+"/>
            </button>
        </div>)
    }
}

const Form = (props) => {
    // State
    const { detalles, loader } = props;
    // Bind
    const { handleSubmit, limpiarDatosOC, goBack, setNotaProducto, agregarMasProductos } = props

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    return (
        <form onSubmit={handleSubmit} className="w-100">
            <div className="row">
                <h5 className="mt-2 negro-dark2 font-weight-bold">Datos recepción</h5>
            </div>
            <div className="row mb-3">
                <div className="col-12 col-md-6 col-lg-6 form-group">
                    <label htmlFor="num_documento">Documento</label>
                    <div className="d-flex flex-column pr-0">
                        <Field name="num_documento" component={renderField} className="form-control" autoFocus={true}/>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 form-group">
                    <label htmlFor="nota">Nota</label>
                    <div className="d-flex flex-column pr-0">
                        <Field name="nota" rows={2} component={renderTextArea} className="form-control"/>
                    </div>
                </div>
            </div>
            {/* DETALLE OC */}
            <BootstrapTable
                data={loader ? [] : detalles}
                remote
                hover
                options={options}
            >
                <TableHeaderColumn
                    width="0%"
                    dataField="id"
                    isKey
                >
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nombre"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (
                            <div>
                                <span>
                                    <b>{row.producto}</b>
                                    <div>{row.fraccion.producto.a_granel ? row.fraccion.unidad_de_medida.nombre : row.fraccion.nombre}</div>
                                </span>
                            </div>
                        )
                    }}
                >
                    Nombre
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataAlign="right"
                    width="100px"
                    dataField="cantidad"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (<RenderNumero monto={cell} />)
                    }}
                >
                    Solicitados
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataAlign="right"
                    width="150px"
                    dataField="cantidad_recibido"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                dataFormat={(cell, row) => <span>{cell ? (<RenderNumero monto={cell} />): (<RenderNumero monto={0} />)}</span>}
                >
                    Recibidos
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataAlign="right"
                    width="150px"
                    dataField="a_recibir"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatoCantidadRecibido({...props})}
                >
                    A recibir
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nota"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, producto) => {
                        return (
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Escribe la nota"
                                    ref={node => {
                                        props.this.nota = node;
                                        if (props.this.nota && producto.nota !== undefined) {
                                            props.this.nota.value = producto.nota;
                                        }
                                    }}
                                    onChange={e => {
                                        if (props.this.nota) {
                                            setNotaProducto(e.target.value, producto);
                                        }
                                    }}
                                />
                                &nbsp;&nbsp;
                                {
                                    (producto.tiene_alerta && (producto.nota == undefined || producto.nota == null || producto.nota == "")) && (
                                        <em className="fa fa-exclamation-triangle" style={{color: "#EC2C30"}}></em>
                                    )
                                }
                            </div>
                        )
                    }}
                >
                    Notas
                </TableHeaderColumn>
            </BootstrapTable>
            <div className="col-12 container-btn-agregar-productos" onClick={agregarMasProductos}>
                <em className="fa fa-plus"></em> Agregar producto
            </div>
            <div className="d-flex justify-content-around align-items-center p-1 p-md-2 flex-column flex-3" style={{backgroundColor:"#FFF"}}>
                <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                    <button type="button" className="btn btn-tertiary mr-1" onClick={() => {limpiarDatosOC();goBack();}}> <img src={icons.cancelar} alt=""/> Cancelar</button>
                    <button className="btn btn-primary ml-1" type="submit" >
                    <img src={icons.save} alt=""/>
                        Guardar
                    </button>
                </div>
            </div>
        </form>
    )
}

const FormRecepcionOC = reduxForm({
    form: 'RecepcionOrdenCompra'
})(Form)

export default FormRecepcionOC