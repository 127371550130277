import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./botonAyuda.css";
import { icons } from "icons";

class FloatingMenu extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        //  state
        const { items } = this.props;
        //  bind
        const { action } = this.props;
        return (
            <div className={`${ items ? "contenedor": "contenedor-no-desplegable"}`}>
                {
                    items && (
                        items.map((item, index) => {
                            return(
                                <span key={index} className="ayuda" onClick={(e) => {
                                    e.preventDefault();
                                    action(item);
                                }}>{item.texto}</span>
                            )
                        })
                    )
                }
                <div className={` ${items ? "floating-button": ""} d-flex align-items-center`} onClick={(e) =>{
                    e.preventDefault();
                    if (!items) {
                        action();
                    }
                }}>
                    <div className="icono-ayuda d-flex align-items-center">
                        <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{ margin: "0 0 0 14px", height: "1.1rem" }} />
                    </div>
                    {
                        items && (
                            <span className="etiqueta">¿Con qué necesitas ayuda?</span>
                        )
                    }
                </div>
            </div>
        );
    }
}

FloatingMenu.propTypes = {
    action: PropTypes.func.isRequired
};

export default FloatingMenu;
