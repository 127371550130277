import { connect } from 'react-redux';

import ResumenBodega from './ResumenBodega';
import { actions } from "../../../../../redux/modules/reportes/bodega";

const ms2p = (state) => ({
    ...state.reporte_bodega,
})

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(ResumenBodega);
