import React, { Component } from "react";
import { slide as Slide } from "react-burger-menu";
import _ from "lodash";
import PropTypes from "prop-types";
import Select from "react-select";
import { icons } from "icons";
import Menu from "../Menu/MenuContainer";
import "./navbar.css";
import "./burger-sidebar.css";
import "./dd-menu.css";
import { DropDownMenu, DropDownDirection, ActionItem, SeperatorItem } from "react-dropdown-advanced";
import {SucursalOption, SucursalValue} from "./SucursalOption";
import Swal from "sweetalert2";


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { verMenu: false, dropdownItems:[]};
    }
    componentWillMount() {
        this.toggleMenu = this.toggleMenu.bind(this);
        this.logOut = this.logOut.bind(this);
        // this.setState({dropdownItems: items})
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.url !== this.props.url) {
            this.props.setRuta(window.location.href);
        }
        let perfil = false;
        if (nextProps.usuario.perfil || nextProps.usuario.es_propietario)
            perfil = true;
        const items = this.getDynamicItems(perfil);
        this.setState({dropdownItems: items})
    }

    toggleMenu() {
        return (e) => {
            e.preventDefault();
            this.setState({ verMenu: !this.state.verMenu });
        };
    }
    logOut() {
        this.props.logOut();
    }
    getDynamicItems = (perfil) => {
        let arr = [];
        let item = new ActionItem("perfil", "Perfil", "fa-user");
        const { usuario } = this.props;
        if (perfil)
            arr.push(item);
        arr.push(new SeperatorItem());
        item = new ActionItem("logout", "Cerrar sesión ", "fa-lock");
        arr.push(item);
        return arr;
    };

    onClick = (item) => {
        if (item.key === "logout") {
            this.props.logOut()
        }
        else {
            this.props.goToPerfil();
        }
    };
    setSucursal = (id) => {
        Swal({
            title: '¿Estás seguro que quieres cambiar de sucursal?',
            text: 'Perderás los datos no almacenados o transacciones no finalizadas',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, Cambiar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.setSucursal(id)
            }
        });
    };
    render() {

        const { sucursales, sucursal, usuario, empresa, navbarCollapse, menu } = this.props;
        let oficina = _.find(sucursales, {id: sucursal});
        if (!oficina){
            oficina = {};
        }
        sucursales.forEach((item, index) => {
            item.index = index;
            item.sucursal_activa = oficina.id;
        });


        return (
            <div>
                <header className={`topnavbar-wrapper ${navbarCollapse ? "topnavbar-wrapper-reduced" : "topnavbar-wrapper-expanded"}`}>
                    <nav className="navbar topnavbar">
                        <div className="nav-wrapper">
                            <div className="container-logo-sucursal">
                                <ul className="nav navbar-right pr-2 align-self-center">
                                    <a style={{cursor: "pointer"}} onClick={() => this.props.toggleNav()}>
                                        <i className="fa fa-bars" style={{fontSize:"17px"}}/>
                                    </a>
                                </ul>
                                <ul className="nav navbar-right pl-2 pr-2 d-flex flex-column flex-md-row align-self-center justify-content-center container-empres-sucursal">
                                    <div className="d-flex align-items-center nombre-empresa">
                                        <b style={{textTransform: "uppercase", fontSize: "15px"}}>{empresa}</b>
                                    </div>
                                    {(typeof oficina.nombre === "string") &&
                                    (<div className="nav navbar-right sucursal-nav-select">
                                        <Select
                                            optionComponent={SucursalOption}
                                            valueComponent={SucursalValue}
                                            className={"w-100 b-transparent"}
                                            onChange={(value) => {
                                                this.setSucursal(value.id);
                                            }}
                                            searchable={false}
                                            clearable={false}
                                            value={oficina}
                                            options={sucursales}
                                            valueKey={"id"}
                                            labelKey={"nombre"}
                                        />
                                    </div>)}
                                </ul>
                            </div>
                            <div className="nav navbar-nav container-drop-usuario d-none d-md-flex flex-md-row align-items-md-center">
                                <div id="test-menu-lt" className="dopdown-usuario">
                                    <div className="flex-row-center">
                                        <div className="imagen-sm d-none d-md-flex container-no-foto" style={{marginRight:"7px"}}>
                                            {
                                                usuario.foto_cropped ? (<img className="imagen-sm" width="25" src={usuario.foto_cropped} alt=""/>)
                                                : <img width="25" src={icons.perfil} alt=""/>
                                            }
                                        </div>
                                        <span className="d-flex">{usuario.first_name}&nbsp;&nbsp;</span>
                                        <i className="fa fa-caret-down" />
                                    </div>
                                    <DropDownMenu items={this.state.dropdownItems} onClick={this.onClick} direction={DropDownDirection.DownLeft} />
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                <Slide onStateChange={(state) => this.props.closeMenu(state.isOpen)} isOpen={menu} id="bubble" pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
                    <Menu />
                </Slide>
            </div>
        );
    }
}
Navbar.propTypes = {
};

export default Navbar;
