import _ from "lodash";
import { api } from "api";
import uuidv1 from 'uuid/v1'
import Swal from "sweetalert2";
import {ToastStore} from 'react-toasts';
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
export const TAB = "RECEPCION_OC_TAB";
export const DATA = "RECEPCION_OC_DATA";
export const SORT = "RECEPCION_OC_SORT";
export const PAGE = "RECEPCION_OC_PAGE";
export const LOADER = "RECEPCION_OC_LOADER";
export const DETALLES = "RECEPCION_OC_DETALLES";
export const BUSCADOR = "RECEPCION_OC_BUSCADOR";
export const ORDEN_COMPRA = "RECEPCION_OC_ORDEN_COMPRA";
// PRODUCTOS
export const SET_PRODUCTOS = "RECEPCION_OC_SET_PRODUCTOS";
export const SORT_PRODUCTOS = "RECEPCION_OC_SORT_PRODUCTOS";
export const PAGE_PRODUCTOS = "RECEPCION_OC_PAGE_PRODUCTOS";
export const LOADER_PRODUCTOS = "RECEPCION_OC_LOADER_PRODUCTOS";
export const BUSCADOR_PRODUCTOS = "RECEPCION_OC_BUSCADOR_PRODUCTOS";
export const UUID_REQ_PRODUCTOS = "RECEPCION_OC_UUID_REQ_PRODUCTOS";

const bodegaEndpoint = "bodegas"
const endpoint = "recepcion_ordenes_compra";

// ------------------------------------
// PureActions
// ------------------------------------

export const seData = data => ({
    type: DATA,
    data,
});

export const setLoader = loader => ({
    type: LOADER,
    loader,
});
export const setBuscador = search => ({
    type: BUSCADOR,
    search,
});
export const setSort = ordering => ({
    type: SORT,
    ordering,
});
export const setPage = page => ({
    type: PAGE,
    page,
});
export const setTab = tab => ({
    type: TAB,
    tab,
});

export const setOC = orden_compra => ({
    type: ORDEN_COMPRA,
    orden_compra,
});

// export const setTieneAlertas = tiene_alertas => ({
//     type: TIENE_ALERTAS,
//     tiene_alertas,
// });

export const setDetalleOC = detalles => ({
    type: DETALLES,
    detalles,
});
// PRODUCTOS
export const setLoaderProductos = loader_productos => ({
    type: LOADER_PRODUCTOS,
    loader_productos,
});
export const setBuscadorProductos = search_productos => ({
    type: BUSCADOR_PRODUCTOS,
    search_productos,
});
export const setSortProductos = ordering_productos => ({
    type: SORT_PRODUCTOS,
    ordering_productos,
});
export const setPageProductos = page_productos => ({
    type: PAGE_PRODUCTOS,
    page_productos,
});
export const setProductos = productos => ({
    type: SET_PRODUCTOS,
    productos,
});
export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos
})

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const {ordering, search, tab} = store.recepcion_orden_compra;
    const params = { ordering, search, estado_despacho:tab, page };
    api.get(endpoint, params).catch((err) => { }).then((data) => {
        if (data) {
            dispatch(seData(data));
        }
        dispatch(setPage(page));
        dispatch(setLoader(false));
    });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === 'asc') {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const changeTab = (tab) => (dispatch) => {
    dispatch(setTab(tab))
    dispatch(listar())
}

const getOC = (id, recepcion=true) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`, {recepcion}).catch((err) => {
        dispatch(setOC(null));
    }).then((data) => {
        if (data) {
            let detalles = _.cloneDeep(data.detalles);
            detalles.forEach(item => {
                const recibido = item.cantidad_recibido ? item.cantidad_recibido : 0
                const calculo_a_recibir = item.cantidad - recibido;
                item.a_recibir = calculo_a_recibir > 0 ? calculo_a_recibir : 0;
                item.tiene_alerta = false;
            })
            dispatch(setDetalleOC(detalles));
            dispatch(setOC(data));
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const asignarCantidadRecibido = (detalle, cantidad_recibido) => (dispatch, getStore) => {
    const store = getStore();
    let seleccionados = _.cloneDeep(store.recepcion_orden_compra.detalles)
    let item = null;
    if (detalle.id)
        item = _.find(seleccionados, { id: detalle.id });
    else 
        item = _.find(seleccionados, function(det) {return det.fraccion.id == detalle.fraccion.id});
    
    const index = seleccionados.indexOf(item);
    if (cantidad_recibido < 0) {
        item.a_recibir = 0;
    } else {
        item.a_recibir = cantidad_recibido;
    }
    seleccionados.splice(index, 1, item);
    seleccionados.forEach(item => {
        if(item.id) {
            if (item.a_recibir < item.cantidad_faltante) {
                if (item.nota == undefined || item.nota == null || item.nota == "") {
                    item.tiene_alerta = true;
                }else {
                    item.tiene_alerta = false;
                }
            }else {
                item.tiene_alerta = false;
            }
        }
    })
    dispatch(setDetalleOC(seleccionados));
}

const setNotaProducto = (nota, detalle) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.recepcion_orden_compra.detalles)
    let item = null;
    if (detalle.id)
        item = _.find(seleccionados, { id: detalle.id });
    else 
        item = _.find(seleccionados, function(det) {return det.fraccion.id == detalle.fraccion.id});
        
    const index = seleccionados.indexOf(item);
    if (item) {
        item.nota = nota;
        seleccionados.splice(index, 1, item);
        seleccionados.forEach(item => {
            if(item.id) {
                if (item.a_recibir < item.cantidad_faltante) {
                    if (item.nota == undefined || item.nota == null || item.nota == "") {
                        item.tiene_alerta = true;
                    } else {
                        item.tiene_alerta = false;
                    }
                } else {
                    item.tiene_alerta = false;
                }
            }
        })
        dispatch(setDetalleOC(seleccionados));
    }
}

const registrarRecepcion = () => (dispatch, getStore) => {
    const store = getStore();
    const { orden_compra } = store.recepcion_orden_compra
    const { values } = store.form.RecepcionOrdenCompra;
    let { detalles } = store.recepcion_orden_compra;
    let tiene_alertas = false;
    detalles.forEach(item => {
        if (!item.a_recibir)
            item.a_recibir = 0;
        if(item.id) {
            if (item.a_recibir < item.cantidad_faltante) {
                if (item.nota == undefined || item.nota == null || item.nota == "") {
                    tiene_alertas = true;
                }
            }
        }
    })
    let datos = {
        num_documento: "",
        nota: ""
    }
    if(values) {
        datos.num_documento = values.num_documento ? values.num_documento : "";
        datos.nota = values.nota ? values.nota : "";
    }

    if (!tiene_alertas) {
        dispatch(setLoader(true));
        api.put(`${endpoint}/${orden_compra.id}`, {...datos, detalles}).catch(err => {
            if (err) {
                if (err.detail) {
                    Swal(
                        "ERROR",
                        err.detail,
                        "error"
                    );
                } else {
                    Swal(
                        "ERROR",
                        "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                        "error"
                    );
                }
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                    "error"
                );
            }
        }).then(resp => {
            if (resp) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(limpiarDatosOC())
                // dispatch(setDespacho(resp))
                dispatch(push(`/recepciones_de_ordenes_de_compra`));
            }
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

const limpiarDatosOC = () => (dispatch) => {
    dispatch(setOC(null));
    dispatch(setDetalleOC([]))
}

// PRODUCTOS
export const listarProductos = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoaderProductos(true));
    const store = getStore();
    const { search_productos } = store.recepcion_orden_compra;
    let { ordering_productos } = store.recepcion_orden_compra;
    //  GENERAR EL UUID
    const uuid = uuidv1();
    dispatch(setUuidReqProductos(uuid));
    if (!ordering_productos) {
        ordering_productos = "-creado";
    }
    api.get(bodegaEndpoint, { page, ordering: ordering_productos, search: search_productos }).catch((err) => { }).then((data) => {
        if (data) {
            const otroUuid = getStore().recepcion_orden_compra.uuid_req_productos;
            if (otroUuid === uuid)
                dispatch(setProductos(data));
        }
        dispatch(setPageProductos(page));
    }).finally(() => {
        dispatch(setLoaderProductos(false));
    });
};
export const buscarProductos = (search) => (dispatch) => {
    dispatch(setBuscadorProductos(search));
    dispatch(listarProductos());
};

export const sortChangeProductos = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === 'asc') {
        dispatch(setSortProductos(sortName));
    } else {
        dispatch(setSortProductos(`-${sortName}`));
    }
    const store = getStore();
    const page = store.ordenes_compra.page_productos;
    dispatch(listarProductos(page));
};

export const agregarProducto = (producto) => (dispatch, getStore) => {
    const store = getStore();
    const { detalles } = store.recepcion_orden_compra;
    if (!_.find(detalles, function(det) { return det.fraccion.id == producto.id})) {
        let detalle = {
            id: null,
            cantidad: 0,
            recibido: 0,
            a_recibir: 1,
            producto: producto.producto.nombre,
            fraccion: producto,
            nota: ""
        }

        dispatch(setDetalleOC([...detalles, detalle]))
    }
}

export const actions = {
    getOC,
    listar,
    buscar,
    changeTab,
    sortChange,
    limpiarDatosOC,
    buscarProductos,
    setNotaProducto,
    listarProductos,
    agregarProducto,
    registrarRecepcion,
    sortChangeProductos,
    asignarCantidadRecibido,
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ORDEN_COMPRA]: (state, { orden_compra }) => {
        return {
            ...state,
            orden_compra,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [DETALLES]: (state, { detalles }) => {
        return {
            ...state,
            detalles,
        };
    },
    [LOADER_PRODUCTOS]: (state, { loader_productos }) => {
        return {
            ...state,
            loader_productos,
        };
    },
    [SORT_PRODUCTOS]: (state, { ordering_productos }) => {
        return {
            ...state,
            ordering_productos,
        };
    },
    [PAGE_PRODUCTOS]: (state, { page_productos }) => {
        return {
            ...state,
            page_productos,
        };
    },
    [BUSCADOR_PRODUCTOS]: (state, { search_productos }) => {
        return {
            ...state,
            search_productos,
        };
    },
    [SET_PRODUCTOS]: (state, { productos }) => {
        return {
            ...state,
            productos,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    orden_compra: null,
    loader: false,
    page: 1,
    search: "",
    ordering: "-creado",
    tab: false,
    detalles: [],
    tiene_alertas:false,
    productos:{},
    loader_productos:false,
    ordering_productos:"",
    page_productos:1,
    search_productos: "",
    uuid_req_productos: "",
}

export default handleActions(reducers, initialState);
