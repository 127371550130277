import { connect } from 'react-redux';

import ProductosMasVendidos from './ProductosMasVendidos';
import { actions } from "../../../../redux/modules/reportes/productos_mas_vendidos";

const ms2p = (state) => ({
    ...state.reporte_productos_mas_vendidos,
    usuarios_sucursal: state.usuario.usuarios_sucursal
})

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(ProductosMasVendidos);
