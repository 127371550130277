import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/reportes/ganancia_bruta";
import GananciaBruta from './GananciaBruta';


const ms2p = (state) => {
    return {
        ...state.ganancia_bruta,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(GananciaBruta);
