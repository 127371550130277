import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes/apertura_cierre";
import ListadoAperturaCierre from "./ListadoAperturaCierre";


const ms2p = (state) => {
    return {
        ...state.apertura_cierre,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoAperturaCierre);
