import { createAction, handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { api } from "api";
import {ToastStore} from 'react-toasts';
import Swal from 'sweetalert2';
import { getMe } from "./usuario/usuario";

// ------------------------------------
// Constantes
// ------------------------------------

const SUBMIT = "SUBMIT";
const NAME_ERROR = "NAME_ERROR";
const PASS_ERROR = "PASS_ERROR";
const LOGIN_LOADER = "LOGIN_LOADER";
const SUBMIT_ERROR = "SUBMIT_ERROR";

export const constants = {
    SUBMIT
};

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    let canLog = true;
    dispatch({ type: LOGIN_LOADER, loader: true });
    dispatch({ type: SUBMIT_ERROR, submitError: false });
    if (data.username === "" || data.username === undefined) {
        canLog = false;
        dispatch({ type: LOGIN_LOADER, loader: false });
    }
    if (data.password === "" || data.password === undefined) {
        canLog = false;
        dispatch({ type: LOGIN_LOADER, loader: false });
    }
    if (canLog) {
        // Esto debiera hacer una peticion a un API
        api.post("usuarios/token", data)
            .catch(() => {
                dispatch({ type: LOGIN_LOADER, loader: false });
                dispatch({ type: SUBMIT_ERROR, submitError: true });
                ToastStore.error('Verifique que ha ingresado bien su nombre de usuario y contraseña.');
            })
            .then(resp => {
                if (resp) {
                    localStorage.setItem("token", resp.token);
                    dispatch(getMe())
                    dispatch({ type: SUBMIT, autenticado: true });
                    dispatch({ type: LOGIN_LOADER, loader: false });
                }
            });
    } else {
        dispatch({ type: LOGIN_LOADER, loader: false });
        dispatch({ type: SUBMIT, autenticado: false });
    }
};

export const solicitarPass = (data) => (dispatch) => {
    dispatch({ type: LOGIN_LOADER, loader: true });
    api.post("usuarios/pass_reset_mail", data).then(() => {
        Swal(
            "Éxito",
            "Te hemos enviado un correo con los pasos a seguir para restablecer tu contraseña, puede tardar unos minutos para que lo recibas",
            "info"
        );
        dispatch(push('/'));
    }).catch(() => {
        Swal(
            "ERROR",
            'Ha ocurrido un error, verifica que la dirección de correo que has escrito es correcta y sea la asociada a tu cuenta de Vende Más.',
            'error'
        );
    }).finally(() => {
        dispatch({ type: LOGIN_LOADER, loader: false });
    })
};


export const restablecerPass = (data) => (dispatch) => {
    dispatch({ type: LOGIN_LOADER, loader: true });
    api.post('usuarios/pass_reset', data).then(() => {
        Swal(
            "Éxito",
            'Tu contraseña a sido restablecida con éxito.',
            'success'
        );
        dispatch(push('/'));
    }).catch(() => {
        Swal(
            "ERROR",
            'El enlace no es válido o ha expirado.',
            'error'
        );
    }).finally(() => {
        dispatch({ type: LOGIN_LOADER, loader: false });
    })
};

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('sucursal');
    dispatch({ type: SUBMIT, autenticado: false });
    window.location.assign("/");
};

export const actions = {
    onSubmit,
    solicitarPass,
    restablecerPass,
    logOut
};

export const reducers = {
    [SUBMIT]: (state, { autenticado }) => {
        return {
            ...state,
            autenticado
        };
    },
    [LOGIN_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader
        };
    },
    [SUBMIT_ERROR]: (state, { submitError }) => {
        return {
            ...state,
            submitError,
        };
    }
};

export const initialState = {
    submitError: false,
    autenticado: false,
    loader: false,
    me: {}
};

export default handleActions(reducers, initialState);
