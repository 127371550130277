import React, { Component } from "react";
import { Link } from "react-router-dom";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

export default class ListadoDestinos extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.listar(this.props.page);
    }

    render() {
        //// state
        const { data, loader, page, search } = this.props;
        // bind
        const { eliminar, listar, sortChange, buscar } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        options.onSortChange = sortChange;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt">
                        <Link
                            className="btn btn-primary"
                            to="/configuracion/destinos_salida_de_productos/crear"
                        >
                            <em className="fa fa-plus"></em> Agregar Destino
                        </Link>
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Destinos de salida de productos"}
                                buscar={buscar}
                                search={search}
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn dataField="nombre" dataSort>
                                    Nombre
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn dataField='fecha' dataSort dataFormat={(cell, row) => <span>{row.sucursal.nombre}</span>}>Sucursal</TableHeaderColumn> */}
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    dataAlign="center"
                                    width="20%"
                                    dataFormat={(cell, row) => {
                                        if (row.sucursal === null) return "";
                                        return activeFormatter({
                                            editar:
                                                "/configuracion/destinos_salida_de_productos/editar",
                                            eliminar,
                                        })(cell, row);
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}
