import { connect } from "react-redux";
import classNames from "classnames";
import React, { Component } from "react";
import NumberFormat from "react-number-format";
import {validarConfDecimales} from "../validaConfiguracionDecimal";

class RenderCurrencyComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { className, input, invalid, error, pais, disabled, conf_empresa } = this.props;
        return (
            <React.Fragment>
                <NumberFormat
                    className={classNames(
                        className ? className : "form-control text-right",
                        { "is-invalid": invalid }
                    )}
                    decimalScale={validarConfDecimales(conf_empresa)}
                    fixedDecimalScale={true}
                    value={input.value}
                    thousandSeparator={true}
                    prefix={pais == "GT" ? "Q " : "$ "}
                    disabled={disabled}
                    onValueChange={(values) => {
                        input.onChange(values.value);
                    }}
                />
                {invalid && <div className="invalid-feedback">{error}</div>}
            </React.Fragment>
        );
    }
}

class RenderCurrencyReadComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { value, className, pais, disabled, conf_empresa } = this.props;
        return (
            <NumberFormat
                className={className}
                decimalScale={validarConfDecimales(conf_empresa)}
                fixedDecimalScale={true}
                value={value}
                thousandSeparator={true}
                prefix={pais == "GT" ? "Q " : "$ "}
                displayType={"text"}
                disabled={disabled}
            />
        );
    }
}

function ms2p(state) {
    return {
        pais: state.usuario.me.pais,
        conf_empresa: state.usuario,
    };
}

const md2p = {};

const RenderReadCurrency = connect(ms2p, md2p)(RenderCurrencyReadComponent);
const RenderCurrency = connect(ms2p, md2p)(RenderCurrencyComponent);

export { RenderReadCurrency, RenderCurrency };
