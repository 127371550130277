import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { icons } from "icons";
import _ from "lodash";
import LoadMask from "Utils/LoadMask/LoadMask";
import {
    RenderCurrency,
    RenderDateTime,
} from "Utils/renderField/renderReadField";
import { FACTURADO } from "utility/variables";
import { PrintContainer, PrintComponent } from "Utils/Print";
import { opcionesDevolucion, opcionesReingreso } from "utility/variables";
import moment from "moment"

class Vale extends Component {
    static propTypes = {
        elegidos: PropTypes.array.isRequired,
        empresa: PropTypes.string.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getDevolucion(
            this.props.match.params.id_devolucion,
            this.props.match.params.id
        );
        this.props.getFactura(this.props.match.params.id);
    }

    getMotivo(motivo) {
        return _.find(opcionesDevolucion, { value: parseFloat(motivo) }).label;
    }

    render() {
        const { devolucion, empresa, print_state, utiliza_fel, factura } = this.props;
        const { movimiento } = devolucion;
        const { detalles } = movimiento;
        const sucursal = movimiento.sucursal ? movimiento.sucursal : {};
        const datos_empresa =
            sucursal && sucursal.datos_empresa ? sucursal.datos_empresa : {};

        const nota_credito_fel = devolucion.nota_credito_fel
            ? devolucion.nota_credito_fel
            : null;

        return (
            <div className="col-12">
                {print_state.flag && <PrintComponent />}
                <div className="grid-container p-1 p-sm-3 p-md-5 d-flex flex-column text-center">
                    <h3 className="mb-3">
                        <strong>
                            ¿Deseas finalizar el proceso y generar el voucher?
                        </strong>
                    </h3>
                    <div
                        className="col-lg-12 col-md-12 col-sm-12"
                        style={{ zIndex: "1" }}
                    >
                        <div className="mt1 text-right">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.print("valeDevolucion");
                                    //printActions.print('valeDevolucion')
                                }}
                                className="btn btn-secondary mt-lg"
                                type={"button"}
                            >
                                <em className="fa fa-print" /> Imprimir
                            </button>
                        </div>
                    </div>
                    <div
                        className="col-lg-12 col-md-12 col-sm-12 mt-10"
                        style={{ zIndex: "0" }}
                    >
                        <PrintContainer
                            name="valeDevolucion"
                            className="impresion-venta"
                        >
                            <div
                                className="print-shadow mr-auto ml-auto text-center"
                                style={{ fontSize: "80%" }}
                            >
                                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                                    {utiliza_fel ? (
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                {sucursal.nombre_comercial
                                                    ? sucursal.nombre_comercial
                                                    : datos_empresa.nombre_comercial}
                                            </b>
                                        </div>
                                    ) : (
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                {datos_empresa.nombre}
                                            </b>
                                        </div>
                                    )}
                                    {utiliza_fel ? (
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                {datos_empresa.nombre_emisor}
                                            </b>
                                        </div>
                                    ) : (
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                {sucursal.nombre}
                                            </b>
                                        </div>
                                    )}

                                    <div className="w-100 text-center text-uppercase">
                                        {sucursal.direccion}
                                    </div>

                                    {(sucursal.telefono ||
                                        sucursal.telefono != "") && (
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                Tel. {sucursal.telefono}
                                            </b>
                                        </div>
                                    )}
                                    {utiliza_fel && (
                                        <div className="w-100 text-center">
                                            <div className="w-100 text-center">
                                                <b className="text-uppercase">
                                                    Nit. {datos_empresa.nit}
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                    {utiliza_fel && nota_credito_fel && (
                                        <div className="w-100 text-center mt-3">
                                            <div className="w-100 text-center">
                                                <b className="text-uppercase">
                                                    NOTA DE CRÉDITO
                                                </b>
                                            </div>
                                            <div className="w-100 text-center">
                                                <b className="text-uppercase">
                                                    FEL - DOCUMENTO TRIBUTARIO
                                                    ELECTRÓNICO
                                                </b>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {utiliza_fel && nota_credito_fel && (
                                    <div className="w-100 text-left">
                                        {nota_credito_fel.estado_factura ==
                                            FACTURADO && (
                                            <div className="w-100">
                                                <div className="d-flex flex-row fnt-12 mb05">
                                                    <div className="flex1 d-flex flex-column">
                                                        <b>Serie</b>
                                                        {nota_credito_fel.serie
                                                            ? nota_credito_fel.serie
                                                            : ""}
                                                    </div>
                                                    <div className="flex1 d-flex flex-column">
                                                        <b>Número</b>
                                                        {nota_credito_fel.numero
                                                            ? nota_credito_fel.numero
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="d-flex flex-column">
                                    <div className="border-dotted ml-5 mr-5 mt-2 text-center">
                                        <h2 className="m-2">
                                            <strong>{devolucion.codigo}</strong>
                                        </h2>
                                    </div>
                                    {utiliza_fel && nota_credito_fel ? (
                                        <small className="mb-5 mt-1">
                                            Ingrese este código para utilizar la
                                            nota de crédito como método de pago
                                        </small>
                                    ) : (
                                        <small className="mb-5 mt-1">
                                            Ingrese este código para realizar el
                                            descuento en la venta
                                        </small>
                                    )}

                                    <div className="d-flex flex-row justify-content-between">
                                        {utiliza_fel && nota_credito_fel ? (
                                            <strong>NOTA DE CRÉDITO POR</strong>
                                        ) : (
                                            <strong>
                                                VALE DE DEVOLUCIÓN POR
                                            </strong>
                                        )}

                                        <RenderCurrency
                                            value={movimiento.total}
                                            className={"font-weight-bold"}
                                        />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mt-2 mb-2">
                                        <div className="font-weight-bold flex-3 text-left">
                                            PRODUCTO
                                        </div>
                                        <div className="font-weight-bold flex-1 text-right">
                                            TOTAL
                                        </div>
                                    </div>
                                    <div>
                                        {detalles.map((elegido) => {
                                            if (elegido.fraccion != null) {
                                                return (
                                                    <div
                                                        className="d-flex flex-row justify-content-between mt-2 mb-2"
                                                        key={elegido.id}
                                                    >
                                                        <div className="d-flex flex-3 flex-column align-items-start">
                                                            <div className="text-left">
                                                                {elegido
                                                                    .fraccion
                                                                    .codigo_barras
                                                                    ? `${elegido.fraccion.codigo_barras} / `
                                                                    : ""}
                                                                {
                                                                    elegido.producto
                                                                }{" "}
                                                                /{" "}
                                                                {elegido
                                                                    .fraccion
                                                                    .producto
                                                                    .a_granel
                                                                    ? elegido
                                                                          .fraccion
                                                                          .unidad_de_medida
                                                                          .nombre
                                                                    : elegido
                                                                          .fraccion
                                                                          .nombre}
                                                            </div>
                                                            <small>
                                                                {this.getMotivo(
                                                                    elegido.motivo_devolucion
                                                                )}
                                                            </small>
                                                            {elegido.desc_porcentaje <
                                                            100 ? (
                                                                <small className="text-left">
                                                                    Precio (
                                                                    {
                                                                        elegido.desc_porcentaje
                                                                    }
                                                                    {"% de "}
                                                                    <RenderCurrency
                                                                        value={
                                                                            elegido.precio_original
                                                                        }
                                                                    />
                                                                    )
                                                                    <br />
                                                                    <RenderCurrency
                                                                        value={
                                                                            elegido.precio
                                                                        }
                                                                    />{" "}
                                                                    X{" "}
                                                                    {
                                                                        elegido.cantidad
                                                                    }
                                                                </small>
                                                            ) : (
                                                                <small className="text-left">
                                                                    <RenderCurrency
                                                                        value={
                                                                            elegido.precio_original
                                                                        }
                                                                    />{" "}
                                                                    X{" "}
                                                                    {
                                                                        elegido.cantidad
                                                                    }
                                                                </small>
                                                            )}
                                                        </div>
                                                        <RenderCurrency
                                                            className={
                                                                "ml-3 flex-1 text-right"
                                                            }
                                                            value={
                                                                elegido.sub_total
                                                            }
                                                        />
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        className="d-flex flex-row justify-content-between mt-2 mb-2"
                                                        key={elegido.id}
                                                    >
                                                        <div className="d-flex flex-column align-items-start">
                                                            <div className="text-left">
                                                                {elegido.nota}
                                                            </div>
                                                            {elegido.concepto !=
                                                                "Monto fijo" && (
                                                                <small>
                                                                    {
                                                                        elegido.concepto
                                                                    }
                                                                </small>
                                                            )}
                                                        </div>
                                                        <RenderCurrency
                                                            className={"ml-3"}
                                                            value={
                                                                elegido.precio
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                                <div
                                    className="w-100 mb-2"
                                    style={{ borderBottom: "dotted 1px" }}
                                />
                                <div className="d-flex flex-row justify-content-between">
                                    {utiliza_fel && nota_credito_fel ? (
                                        <div>Total</div>
                                    ) : (
                                        <div>Vale por devolución</div>
                                    )}

                                    <RenderCurrency value={movimiento.total} />
                                </div>
                                {utiliza_fel && (
                                    <div className="w-100 mt-3">
                                        {/* DENOMINACION */}
                                        {nota_credito_fel && (
                                            <div className="d-flex flex-row justify-content-center fnt-14 mb-3 text-center font-weight-bold text-uppercase">
                                                {nota_credito_fel.frase_fel}
                                            </div>
                                        )}
                                        {nota_credito_fel && (
                                            <div className="d-flex flex-row fnt-12 mb05">
                                                <div className="flex1 d-flex flex-column align-items-center">
                                                    <b>COMPLEMENTOS</b>
                                                    {/* 
                                                    <p className="text-uppercase m-0 p-0">
                                                        Referencias de Nota de
                                                        Crédito y Débito
                                                    </p>
                                                    */}
                                                    <p className="text-uppercase m-0 p-0">
                                                        Fecha emisión doc origen:&nbsp;
                                                        {factura && factura.movimiento && factura.movimiento.datos_fel ? 
                                                        moment(factura.movimiento.datos_fel.creado).format("DD/MM/YYYY") : 
                                                        ""}
                                                    </p>
                                                    <p className="text-uppercase m-0 p-0">
                                                        Número autorización doc origen:&nbsp;
                                                        {factura && factura.movimiento && factura.movimiento.datos_fel ?
                                                        factura.movimiento.datos_fel.uuid : 
                                                        ""}
                                                    </p>
                                                    <p className="text-uppercase m-0 p-0">
                                                        Motivo de ajuste: Devolucion de productos
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {nota_credito_fel && (
                                            <div className="w-100">
                                                <div className="d-flex flex-row fnt-12 mb05">
                                                    <div className="flex1 d-flex flex-column align-items-center">
                                                        <b>AUTORIZACIÓN FEL</b>
                                                        {nota_credito_fel.uuid
                                                            ? nota_credito_fel.uuid
                                                            : ""}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row fnt-12">
                                                    <div className="flex1 d-flex flex-column align-items-center">
                                                        <b>
                                                            FECHA CERTIFICACIÓN
                                                        </b>
                                                        <RenderDateTime
                                                            value={
                                                                nota_credito_fel.fechaCertificacion
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* DATOS CERTIFICADOR */}
                                        <div className="d-flex flex-row fnt-12 mt-3">
                                            <div className="flex1 d-flex flex-column align-items-center">
                                                <b>CERTIFICADOR</b>
                                                INFILE, S.A. -- NIT: 12521337
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </PrintContainer>
                    </div>
                    <div className="d-flex justify-content-center flex-row mt-3">
                        <Link
                            to={"/devolucion"}
                            className="btn btn-primary m-1 align-self-center"
                        >
                            <i className="fa fa-check" />
                            &nbsp;Finalizar
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Vale;
