import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes/ventas";
import { print } from '../../../../../redux/modules/print/print'
import ListadoVentas from "./ListadoVentas";


const ms2p = (state) => {
    // const venta_impresion_data = state.reporte_ventas.venta_impresion;
    // //  Datos para la factura perosonalizada
    // let datos_venta= {}

    return {
        ...state.reporte_ventas,
        factura_personalizada: state.usuario.factura_personalizada,
        formato_factura: state.usuario.formato_factura,
        utiliza_fel: state.usuario.utiliza_fel,
        conf_ventas_al_credito: state.usuario.conf_ventas_al_credito,
        print_state: state.print,
        // datos_venta
    };
};

const md2p = {
    ...actions ,
    print: (name) => dispatch => dispatch(print(name)),
};

export default connect(ms2p, md2p)(ListadoVentas);
