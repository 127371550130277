import React, { Component } from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import LoadMask from "Utils/LoadMask/LoadMask";
import { DropDownMenu, DropDownDirection, ActionItem } from "react-dropdown-advanced";
import { icons } from "icons";
import {
    formatoNombre,
    formatoExistencia,
    buscar,
    formatoProducto,
    formatoAvatar,
    formatoMarca,
} from "Utils/CamposComunes";

export class ListadoAccion extends  Component {
    static propTypes = {
        titulo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        buscar: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
        sortChange: PropTypes.func.isRequired,
        data: PropTypes.object.isRequired,
        llaves: PropTypes.array.isRequired,
        loader: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        // state
        const { titulo, buscar, data, loader, llaves, acciones, itemsAyuda } = this.props;
        // bind
        const { onClickOtrasAcciones, actionAyuda } = this.props

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;
        return (
            <div className="grid-container mt0 d-flex justify-content-around flex-column ">
                <div className={`grid-titulo ${acciones ? "d-flex flex-row": ""}`}>
                    <div className="col-12 mb-3 d-flex flex1 d-flex justify-content-around flex-column mt">
                        <div>
                            {buscar()}
                        </div>
                    </div>
                    {
                        (acciones) && (
                            <div className="flex07 d-lg-flex d-none align-items-end justify-content-center">
                                <button className="btn btn-tertiary-drop fnt-14 d-flex align-items-center justify-content-center mb-3" style={{position:"relative"}}>
                                    <span className="mr">Otras acciones</span>
                                    <em className="ml-2 fa fa-caret-down"></em>
                                    {
                                        acciones.length > 0 && (
                                            <DropDownMenu items={acciones} onClick={onClickOtrasAcciones} direction={DropDownDirection.DownLeft} className="fnt-10"/>
                                        )
                                    }
                                </button>
                            </div>
                        )
                    }
                    {
                        (itemsAyuda && actionAyuda) && (
                            <div style={{marginTop:"10px", marginRight: "10px"}}>
                                <button className="btn btn-ayuda btn-ayuda-flotante fnt-16 d-flex align-items-center justify-content-center" style={{ width: "2rem", height: "2rem" }}>
                                    <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{ margin: "0", height: "1.1rem" }} />
                                    <DropDownMenu items={itemsAyuda} onClick={actionAyuda} direction={DropDownDirection.DownLeft} className="fnt-10" />
                                </button>
                            </div>
                        )
                       
                    }
                </div>
                <div>
                    <LoadMask loading={loader} light blur radius>
                        <BootstrapTable
                            data={loader ? [] : data.results}
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            bodyContainerClass='r-encajar-listado-productos'
                        >
                            <TableHeaderColumn
                                width="2px"
                                isKey
                                dataField="id"
                                dataFormat={(cell) => <span></span>}
                            >

                            </TableHeaderColumn>

                            {llaves.map((llave) => {
                                return (
                                    <TableHeaderColumn key={llave.nombre}
                                                       width={llave.width}
                                                       dataField={llave.nombre}
                                                       dataFormat={llave.format}
                                                       dataSort={llave.sort}
                                                       dataAlign={llave.align}
                                                       tdStyle={BreakLine}
                                                       thStyle={BreakLine}
                                    >
                                        {llave.caption}
                                    </TableHeaderColumn>
                                );
                            })}

                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        );
    }
}
