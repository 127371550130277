import React from "react";
import { Field } from "redux-form";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import {
    renderNumber,
    renderCurrency,
    renderField,
} from "Utils/renderField/renderField";

export const renderCheckBoxEntrega = ({ fields, items, meta }) => {
    let invalid = true;
    fields.getAll().forEach((item) => {
        if (item[Object.keys(item)[0]]) invalid = false;
    });
    if (items) {
        return (
            <div className="w-100">
                {items.map((metodo_entrega, index) => {
                    const item = fields.get(index);
                    const nombre = `metodo_entrega-${metodo_entrega.id}`;
                    return (
                        <div
                            className="align-items-center d-flex py-2"
                            key={metodo_entrega.id}
                        >
                            <div className="checkbox c-checkbox ml-3">
                                <label className="needsclick d-flex align-items-center">
                                    <Field
                                        name={`metodos_entrega[${index}].metodo_entrega-${metodo_entrega.id}`}
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="fa fa-check" />
                                    {metodo_entrega.nombre}
                                </label>
                            </div>
                            <div className="">
                                <Field
                                    name={`metodos_entrega[${index}].precio`}
                                    component={renderCurrency}
                                    decimalScale={2}
                                    type="number"
                                    className="form-control"
                                    disabled={item ? !item[nombre] : false}
                                />
                            </div>
                        </div>
                    );
                })}
                {invalid && meta.submitFailed && (
                    <div className="invalid-feedback d-block">
                        Seleccione al menos un método de entrega
                    </div>
                )}
            </div>
        );
    }

    return <div className="w-100">No se ha encontrado coincidencias.</div>;
};

export const renderCheckBoxPago = ({ fields, items, meta, ver_deposito }) => {
    let invalid = true;
    fields.getAll().forEach((item) => {
        if (item[Object.keys(item)[0]]) invalid = false;
    });
    if (items) {
        return (
            <div className="w-100">
                {items.map((metodo_pago, index) => {
                    return (
                        <div key={metodo_pago.id}>
                            <div className="checkbox c-checkbox ml-3">
                                <label className="needsclick d-flex align-items-center">
                                    <Field
                                        name={`metodos_pago[${index}].metodo_pago-${metodo_pago.id}`}
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="fa fa-check" />
                                    {metodo_pago.nombre}{" "}
                                    <small className="span-checkbox-exclude ml-1">
                                        ({metodo_pago.descripcion})
                                    </small>
                                </label>
                            </div>
                            {ver_deposito.ver === true &&
                                ver_deposito.id == metodo_pago.id && (
                                    <div className="ml-4 py-2 row">
                                        <div className="col-12 col-md-4">
                                            <label>Banco</label>
                                            <Field
                                                name={`metodos_pago[${index}].banco`}
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label>No. cuenta</label>
                                            <Field
                                                name={`metodos_pago[${index}].no_cuenta`}
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label>A nombre de</label>
                                            <Field
                                                name={`metodos_pago[${index}].nombre`}
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                )}
                        </div>
                    );
                })}
                {invalid && meta.submitFailed && (
                    <div className="invalid-feedback d-block">
                        Seleccione al menos un método de pago
                    </div>
                )}
            </div>
        );
    }
    return <div className="w-100">No se ha encontrado coincidencias.</div>;
};

export const renderCheckBoxTiposFactura = ({ fields, items, meta }) => {
    let invalid = true;
    fields.getAll().forEach((item) => {
        if (item[Object.keys(item)[0]]) invalid = false;
    });

    if (items) {
        return (
            <div className="w-100">
                {items.map((tipo_factura, index) => {
                    return (
                        <div
                            className="align-items-center d-flex py-2"
                            key={tipo_factura.id}
                        >
                            <div className="checkbox c-checkbox ml-3">
                                <label className="needsclick d-flex align-items-center">
                                    <Field
                                        name={`tipos_facturas[${index}].tipo_factura-${tipo_factura.id}`}
                                        component="input"
                                        type="checkbox"
                                        disabled="true"
                                    />
                                    <span className="fa fa-check" />
                                    {tipo_factura.label}
                                </label>
                            </div>
                        </div>
                    );
                })}
                {invalid && meta.submitFailed && (
                    <div className="invalid-feedback d-block">
                        Seleccione al menos un método de entrega
                    </div>
                )}
            </div>
        );
    }

    return <div className="w-100">No se ha encontrado coincidencias.</div>;
};
