import Resumen from "./Resumen";
import React, { Component } from 'react'
import GraficaBalanceGeneral from "./Grafica";

export default class BalanceGeneral extends Component {
    render() {
        return (
            <div>
                <GraficaBalanceGeneral />
                <Resumen />
            </div>
        )
    }
}
