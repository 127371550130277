import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";

class ListadoDespacho extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.setSeleccionados([]);
        this.props.listar(this.props.page);
    }

    render() {
        // state
        const { data, loader, search, tab } = this.props;

        // bind
        const { anularDespacho, buscar, changeTab } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        return (
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Link className="btn btn-primary" to="/despachos/crear/">
                        <em className="fa fa-plus"></em> Nuevo despacho
                    </Link>
                    <Tabs
                        className="content-tabs"
                        selectedIndex={tab == 10 ? 0 : 1}
                        onSelect={(tabIndex) =>
                            changeTab(tabIndex == 0 ? 10 : 20)
                        }
                    >
                        <TabList className="tab_list d-flex flex-row justify-content-between">
                            <Tab
                                className="content-tab-venta flex1 text-center fnt-14"
                                style={{ marginBottom: "0px" }}
                                selectedClassName="content-tab-venta-active"
                            >
                                DESPACHOS PENDIENTES
                            </Tab>
                            <Tab
                                className="content-tab-venta flex1 text-center fnt-14"
                                style={{ marginBottom: "0px" }}
                                selectedClassName="content-tab-venta-active"
                            >
                                DESPACHOS COMPLETADOS
                            </Tab>
                        </TabList>
                        {/* PANEL PENDIENTES */}
                        <TabPanel>
                            <div className="grid-container">
                                <div className="grid-titulo">
                                    <Toolbar
                                        titulo={"Listado"}
                                        buscar={buscar}
                                        search={search}
                                    />
                                </div>
                                <LoadMask loading={loader} dark blur>
                                    <BootstrapTable
                                        data={loader ? [] : data.results}
                                        remote
                                        pagination
                                        hover
                                        fetchInfo={{
                                            dataTotalSize: data.count,
                                        }}
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            dataField="codigo"
                                            width="120px"
                                            dataFormat={(cell, row) => (
                                                <div>{cell ? cell : ""}</div>
                                            )}
                                        >
                                            Código
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="sucursal_destino__nombre"
                                            dataFormat={(cell, row) => (
                                                <div>
                                                    {
                                                        row.sucursal_destino
                                                            .nombre
                                                    }
                                                </div>
                                            )}
                                            dataSort
                                        >
                                            Enviado a
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="creado"
                                            dataFormat={(cell) => (
                                                <RenderDateTime fecha={cell} />
                                            )}
                                            dataSort
                                        >
                                            Fecha
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            isKey
                                            dataField="id"
                                            dataAlign="center"
                                            dataFormat={activeFormatter({
                                                anular: anularDespacho,
                                                ver: "/despachos/visualizacion",
                                            })}
                                        >
                                            Acciones
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </LoadMask>
                            </div>
                        </TabPanel>
                        {/* PANEL ENTREGADOS */}
                        <TabPanel>
                            <div className="grid-container">
                                <div className="grid-titulo">
                                    <Toolbar
                                        titulo={"Listado"}
                                        buscar={buscar}
                                        search={search}
                                    />
                                </div>
                                <LoadMask loading={loader} dark blur>
                                    <BootstrapTable
                                        data={loader ? [] : data.results}
                                        remote
                                        pagination
                                        hover
                                        fetchInfo={{
                                            dataTotalSize: data.count,
                                        }}
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            dataField="codigo"
                                            width="120px"
                                            dataFormat={(cell, row) => (
                                                <div>{cell ? cell : ""}</div>
                                            )}
                                        >
                                            Código
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="sucursal_destino__nombre"
                                            dataFormat={(cell, row) => (
                                                <div>
                                                    {
                                                        row.sucursal_destino
                                                            .nombre
                                                    }
                                                </div>
                                            )}
                                            dataSort
                                        >
                                            Enviado a
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="fecha_recepcion"
                                            dataFormat={(cell) => (
                                                <RenderDateTime fecha={cell} />
                                            )}
                                            dataSort
                                        >
                                            Fecha
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            isKey
                                            dataField="id"
                                            dataAlign="center"
                                            dataFormat={activeFormatter({
                                                ver: "/despachos/visualizacion",
                                            })}
                                        >
                                            Acciones
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </LoadMask>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        );
    }
}

ListadoDespacho.propTypes = {
    data: PropTypes.object.isRequired,
    loader: PropTypes.bool.isRequired,
    buscar: PropTypes.func.isRequired,
    sortChange: PropTypes.func.isRequired,
    listar: PropTypes.func.isRequired,
};

export default ListadoDespacho;
