import React, { Component } from "react";
import PropTypes from "prop-types";
import FraccionForm from "./FraccionesCrearForm";

class FraccionCrear extends Component {
    static propTypes = {
        // submit: PropTypes.func.isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            sucursal: null,
            sucursal_activo: null,
        };
    }

    changeSucursal = (value) => {
        this.setState({ sucursal: value });
    };

    changeSucursalActivo = (id) => {
        this.setState({
            sucursal_activo: this.state.sucursal_activo === id ? null : id,
        });
    };
    render() {
        //  state
        const {
            fracciones,
            editCreateFraccion,
            lista_sucursales,
            nombres_precios,
            conf_porcentaje_precio,
        } = this.props;
        //  bind
        const {
            onCloseModal,
            agregarNuevaFraccion,
            editarFraccion,
            cambioPorcentajePrecio,
        } = this.props;
        // console.log("PROPS FRACCION: ", this.props)
        return (
            <div className="">
                <FraccionForm
                    onCloseModal={onCloseModal}
                    agregarNuevaFraccion={agregarNuevaFraccion}
                    editarFraccion={editarFraccion}
                    fracciones={fracciones}
                    editCreateFraccion={editCreateFraccion}
                    sucursales={lista_sucursales}
                    sucursal={this.state.sucursal}
                    sucursal_activo={this.state.sucursal_activo}
                    nombres_precios={nombres_precios}
                    conf_porcentaje_precio={conf_porcentaje_precio}
                    changeSucursal={this.changeSucursal}
                    changeSucursalActivo={this.changeSucursalActivo}
                    cambioPorcentajePrecio={cambioPorcentajePrecio}
                />
            </div>
        );
    }
}

export default FraccionCrear;
