import _ from "lodash";
import { connect } from "react-redux";
import FraccionesCrear from "./FraccionCrear";
import {
    agregarNuevaFraccion,
    editarFraccion,
    cambioPorcentajePrecio,
} from "../../../../../redux/modules/producto/producto";

const ms2p = (state) => {
    const { sucursales } = state.usuario.me;
    const values = state.form.FraccionCrear
        ? state.form.FraccionCrear.values
        : {};
    let lista_sucursales = [];
    if (sucursales) {
        sucursales.forEach((sucursal) => {
            if (sucursal.id != "empresa") {
                const precios_sucursal = values.precios_sucursal
                    ? values.precios_sucursal
                    : [];
                const encontrado = _.find(precios_sucursal, function (o) {
                    return o.sucursal == sucursal.id;
                });
                if (!encontrado) {
                    lista_sucursales.push({
                        id: sucursal.id,
                        nombre: sucursal.nombre,
                    });
                }
            }
        });
    }
    return {
        fracciones: state.producto.fracciones,
        editCreateFraccion: state.producto.editCreateFraccion,
        lista_sucursales,
        nombres_precios: state.usuario.nombres_precios,
        conf_porcentaje_precio: state.usuario.conf_porcentaje_precio,
    };
};

const md2p = {
    agregarNuevaFraccion: (fraccion, onCloseModal) => (dispatch) =>
        dispatch(agregarNuevaFraccion(fraccion, onCloseModal)),
    editarFraccion: (fraccion, onCloseModal) => (dispatch) =>
        dispatch(editarFraccion(fraccion, onCloseModal)),
    cambioPorcentajePrecio,
};

export default connect(ms2p, md2p)(FraccionesCrear);
