import React, { Component } from 'react';
import _ from 'lodash';
import { icons } from "icons";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { DropDownMenu, DropDownDirection, ActionItem, HeaderItem } from "react-dropdown-advanced";
import ModalAyuda from "Utils/Ayuda"

function formatoNivel(cell, row) {
    return (<div>
        {(!row.es_admin && !row.es_vendedor_mostrador && !row.es_bodeguero && !row.es_cajero) && (<div>Personalizado</div>)}
        {(row.es_admin) && (<div>Administrador</div>)}
        {(row.es_bodeguero) && (<div>Bodeguero</div>)}
        {(row.es_cajero) && (<div>Cajero</div>)}
        {(row.es_vendedor_mostrador) && (<div>Vendedor Mostrador</div>)}
    </div>);
}

function formatoSucursales(cell, row) {
    return (
        <div>
            {
                cell.map((item, index) => {
                    return <span key={index}>{index != 0 ? ", ": ""}{item.nombre}</span>
                })
            }
        </div>
    )
}
class ListadoUsuarios extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        eliminar: PropTypes.func.isRequired,
        buscar: PropTypes.func.isRequired,
        sortChange: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props)
        this.state = {
            dropdownItems:[
                new HeaderItem("¿Con qué necesitas ayuda?"),
                new ActionItem("agregar_usuario", "¿Como agrego un usuario?"),
                new ActionItem("editar", "¿Cómo editar un usuario?"),
                new ActionItem("eliminar", "¿Cómo eliminar un usuario?"),
            ],
            modalAyuda: false,
            itemsImagenes: [],
            itemsCrearEditar: [
                {
                    src: icons.usuario_crear_editar_p1,
                    alt: "Paso 1"
                },
                {
                    src: icons.usuario_crear_editar_p2,
                    alt: "Paso 2"
                },
                {
                    src: icons.usuario_crear_editar_p3,
                    alt: "Paso 3"
                },
                {
                    src: icons.usuario_crear_editar_p4,
                    alt: "Paso 4"
                },
                {
                    src: icons.usuario_crear_editar_p5,
                    alt: "Paso 5"
                }
            ],
            itemsEditar: [
                {
                    src: icons.usuario_accion_editar,
                    alt: "Editar"
                },
            ],
            itemsEliminar: [
                {
                    src: icons.usuario_accion_eliminar,
                    alt: "Eliminar"
                }
            ]
        }
    }
    componentWillMount() {
        this.props.listar(this.props.page);
    }

    onClickAyuda = (item) => {
        if (item.key === "agregar_usuario") {
            this.setState({
                itemsImagenes: this.state.itemsCrearEditar
            })
            this.openModal()
        }else if (item.key === "eliminar") {
            this.setState({
                itemsImagenes: this.state.itemsEliminar
            })
            this.openModal()
        }
        else if (item.key === "editar") {
            this.setState({
                itemsImagenes: this.state.itemsEditar
            })
            this.openModal()
        }
    };

    closeModal = () => {
        this.setState({modalAyuda: false});
    }
    openModal = () => {
        this.setState({modalAyuda: true});
    }

    render() {
        // state
        const { data, loader } = this.props;
        const { modalAyuda, itemsImagenes, dropdownItems } = this.state;

        // bind
        const { eliminar } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;
        return (
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <Link className="btn btn-primary" to="/configuracion/usuarios/crear/"><em className="fa fa-plus"></em> Agregar Usuario</Link>
                        {/* <button className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center" style={{width: "2rem", height: "2rem"}}>
                            <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{margin: "0", height:"1.1rem"}}/>
                            <DropDownMenu items={this.state.dropdownItems} onClick={this.onClickAyuda} direction={DropDownDirection.DownLeft} className="fnt-10"/>
                        </button> */}
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar titulo={"Usuarios"} buscar={this.props.buscar} search={this.props.search} itemsAyuda={dropdownItems} actionAyuda={this.onClickAyuda} />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote pagination hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn dataField='first_name' dataSort >Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataField='id' dataFormat={formatoNivel} >Permisos</TableHeaderColumn>
                                <TableHeaderColumn dataField='sucursales' dataFormat={formatoSucursales}>Sucursales</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='id' isKey dataAlign="center" width="20%"
                                    dataFormat={
                                        (cell, row) => {
                                            if (row.sucursal === null)
                                                return "";
                                            return activeFormatter({editar: '/configuracion/usuarios/editar', eliminar })(cell, row)
                                        }
                                    }
                                >Acciones</TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                        <ModalAyuda open={modalAyuda} closeModal={this.closeModal} openModal={this.openModal} items={itemsImagenes}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ListadoUsuarios
