import { handleActions } from "redux-actions";
import { api } from "api";
import uuidv1  from "uuid/v1";

const endpoint = "productos_venta/existencias_otras_sucursales";

// ------------------------------------
// Constants
// ------------------------------------

export const DATA = "GASTOS_DATA";
export const SORT = "GASTOS_SORT";
export const PAGE = "GASTOS_PAGE";
export const BUSCADOR = "GASTOS_BUSCADOR";
export const LOADER = "GASTOS_LOADER";
export const UUID_REQ_PRODUCTOS_SUCURSAL = "VENTA_UUID_REQ_PRODUCTOS_SUCURSAL";

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const params = store.existencias_sucursal;
    const { ordering, search } = params;
    //  GENERAR EL UUID
    const uuid = uuidv1();
    dispatch(setUuidReqProductos(uuid));
    api.get(endpoint, { page, ordering, search }).catch((err) => { }).then((data) => {
        if (data) {
            const otroUuid = getStore().existencias_sucursal.uuid_req_productos;
            if (otroUuid === uuid) {
                dispatch(seData(data));
                dispatch(setPage(page));
                dispatch(setLoader(false));
            }
        }
    });
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

// ------------------------------------
// PureActions
// ------------------------------------

export const seData = data => ({
    type: DATA,
    data,
});

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setBuscador = search => ({
    type: BUSCADOR,
    search,
});

// export const setSort = ordering => ({
//     type: SORT,
//     ordering,
// });

export const setPage = page => ({
    type: PAGE,
    page,
});

export const actions = {
    buscar,
    listar
}
export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS_SUCURSAL,
    uuid_req_productos
})

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [UUID_REQ_PRODUCTOS_SUCURSAL]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    loader: false,
    page: 1,
    search: "",
    uuid_req_productos: "",
};

export default handleActions(reducers, initialState);
