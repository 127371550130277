import _ from 'lodash';
import { icons } from "icons";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { DropDownMenu, DropDownDirection, ActionItem } from "react-dropdown-advanced";

export class ListadoVentas extends Component {
    static propTypes = {

    }

    constructor(props) {
        super(props);
        this.state = {
            dropdownItems:[
                new ActionItem("cobrar_credito", "Entrega productos al crédito / realizar cobros al crédito"),
            ],
        }
    }

    onClickOtrasAcciones = (item) => {
        // bind
        const {pushRouter} = this.props;

        if (item.key === "cobrar_credito") {
            pushRouter("/cuentas_por_cobrar");
        }else if (item.key === "recibir_devolucion") {
            pushRouter("/devolucion");
        }
    };

    render() {
        // state
        const { titulo, buscar, data, loader, page } = this.props;
        const { dropdownItems } = this.state;
        // bind
        const { listar, cambioBuscador, agregar, eliminarVenta} = this.props

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        // options.onSortChange = sortChange;
        return (
            <div className="grid-container mt0 d-flex justify-content-around flex-column ">
                <div className="grid-titulo d-flex flex-row">
                    <div className="col-12 mb-3 d-flex flex1 d-flex justify-content-around flex-column mt">
                        {titulo}
                        <div>
                            {buscar()}
                        </div>
                    </div>
                    <div className="flex07 d-lg-flex d-none align-items-end justify-content-center">
                        <button className="btn btn-tertiary-drop fnt-14 d-flex align-items-center justify-content-center mb-3" style={{position:"relative"}}>
                            <span className="mr">Otras acciones</span>
                            <em className="ml-2 fa fa-caret-down"></em>
                            <DropDownMenu items={dropdownItems} onClick={this.onClickOtrasAcciones} direction={DropDownDirection.DownLeft} className="fnt-10"/>
                        </button>
                    </div>
                </div>
                <div>
                    <LoadMask loading={loader} light blur radius>
                        <BootstrapTable
                            data={loader ? [] : data.results}
                            remote
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            bodyContainerClass='r-encajar-listado-productos'
                        >
                            <TableHeaderColumn
                                width="2px"
                                isKey
                                dataField="id"
                                dataFormat={(cell) => <span></span>}
                            ></TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombre"
                                dataFormat={(cell, row) => <span>{cell}</span>}
                            >Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="codigo"
                                dataFormat={(cell) => <span>{cell}</span>}
                            >Código</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="movimiento__total"
                                dataAlign={"right"}
                                width={"20%"}
                                dataFormat={(cell, row) => <span>{<RenderCurrency value={row.movimiento.total} />}</span>}
                            >Monto</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="acciones"
                                dataAlign={"center"}
                                width={"15%"}
                                dataFormat={(cell, row) => {
                                    return(
                                        <div className="d-flex flex-row align-items-center justify-content-center">
                                            <img style={{maxWidth: "20px"}} className="action-img" onClick={() => {
                                                // props.agregar(row);
                                                agregar(row)
                                                cambioBuscador();
                                            }} src={icons.cargar} alt="Cargar venta" title="Cargar venta"/>
                                            <img onClick={() => {
                                                eliminarVenta(row.id)
                                            }} style={{maxWidth: "20px"}} className="action-img" title="Eliminar venta" src={icons.eliminar} alt="eliminar"/>
                                        </div>
                                    )
                                }}
                            >Acciones</TableHeaderColumn>

                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        )
    }
}
