import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AperturaForm from './AperturaForm'
import LoadMask from "Utils/LoadMask/LoadMask";
import { icons } from "icons";
import ModalAyuda from "Utils/Ayuda"
import "./apertura_cierre.css";

class Apertura extends Component {
    static propTypes = {
        aperturar: PropTypes.func.isRequired,
        total: PropTypes.number.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            modalAyuda:false,
            itemsApertura: [
                {
                    src: icons.apertura_caja_p4,
                    alt: "Paso 1"
                },
                {
                    src: icons.apertura_caja_p5,
                    alt: "Paso 2"
                },
                {
                    src: icons.apertura_caja_p6,
                    alt: "Paso 3"
                }
            ]
        }
    }
    componentWillMount(){
        this.props.setId(this.props.match.params.id);
    }
    closeModal = () => {
        this.setState({modalAyuda: false});
    }
    openModal = () => {
        this.setState({modalAyuda: true});
    }

    render(){
        // state
        const { modalAyuda, itemsApertura } = this.state;
        // bind
        const {aperturar, total, loader} = this.props;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={loader} light blur radius>
                        <AperturaForm {...this.props} total={total} onSubmit={aperturar} actionAyuda={this.openModal}/>
                    </LoadMask>
                </div>
                <ModalAyuda open={modalAyuda} closeModal={this.closeModal} openModal={this.openModal} items={itemsApertura}/>
            </div>
        )
    }
}

export default Apertura
