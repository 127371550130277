import { handleActions } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------

export const TAB = "PUNTO_VENTA_TAB";

// ------------------------------------
// Actions
// ------------------------------------

// ------------------------------------
// PureActions
// ------------------------------------
export const setTab = tab => ({
    type: TAB,
    tab
})

export const actions = {
    setTab
}


// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [TAB]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
}

export const initialState = {
    tab: 0
}
export default handleActions(reducers, initialState);
