import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TablaRecepcionOrdenCompra } from '../ComponentesRecepcionOC'

class ListadoRecepcionOrdenCompra extends Component {
    constructor(props) {
        super(props);

    }
    
    static propTypes = {
        
    };

    componentWillMount() {
        this.props.listar();
    }

    render() {
        // state
        const { data, loader, search, page, tab } = this.props;
        // bind
        const { buscar, changeTab, listar, sortChange } = this.props;
        return (
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Tabs className="content-tabs" selectedIndex={tab==false ? 0:1} onSelect={tabIndex => changeTab(tabIndex == 0 ? false : true )}>
                        <TabList className="tab_list d-flex flex-row justify-content-between">
                            <Tab className="content-tab-venta flex1 text-center fnt-14" style={{marginBottom: "0px"}} selectedClassName="content-tab-venta-active">PENDIENTES</Tab>
                            <Tab className="content-tab-venta flex1 text-center fnt-14" style={{marginBottom: "0px"}} selectedClassName="content-tab-venta-active">RECIBIDAS</Tab>
                        </TabList>
                        {/* PANEL PENDIENTES */}
                        <TabPanel>
                            <TablaRecepcionOrdenCompra
                                data={data}
                                loader={loader}
                                search={search}
                                page={page}
                                tab={tab}
                                buscar={buscar}
                                listar={listar}
                                sortChange={sortChange}
                            />
                        </TabPanel>
                        
                        {/* PANEL RECIBIDOS */}
                        <TabPanel>
                            <TablaRecepcionOrdenCompra
                                data={data}
                                loader={loader}
                                search={search}
                                page={page}
                                tab={tab}
                                buscar={buscar}
                                listar={listar}
                                sortChange={sortChange}
                            />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default ListadoRecepcionOrdenCompra;
