import React from "react";
import { icons } from "icons";
import NumberFormat from "react-number-format";

export function formatoCantidadDespachada(props) {
    return (cell, producto) => {
        return (
            <div className="d-flex flex-row">
                <button
                    className="btn btn-cantidad-detalle btn-menos"
                    onClick={() => {
                        const cantidad_despachada = producto.cantidad_despachada
                            ? producto.cantidad_despachada
                            : 0;
                        props.asignarCantidadDespachada(
                            producto,
                            cantidad_despachada - 1
                        );
                    }}
                    type="button"
                >
                    <img src={icons.menos} alt="-" />
                </button>
                <NumberFormat
                    className="form-control bodega-ingreso text-right"
                    placeholder="0"
                    decimalScale={producto.producto.a_granel ? 3 : 0}
                    fixedDecimalScale={
                        producto.producto.a_granel ? true : false
                    }
                    value={producto.cantidad_despachada}
                    thousandSeparator={true}
                    prefix={""}
                    style={{ padding: "2px" }}
                    onValueChange={(values) => {
                        let val = "";
                        if (
                            values.value != "" &&
                            values.value != undefined &&
                            values.value != null
                        ) {
                            val = producto.producto.a_granel
                                ? parseFloat(values.value)
                                : parseInt(values.value);
                        }
                        props.asignarCantidadDespachada(producto, val);
                    }}
                />
                <button
                    className="btn btn-cantidad-detalle btn-mas"
                    onClick={() => {
                        const cantidad_despachada = producto.cantidad_despachada
                            ? producto.cantidad_despachada
                            : 0;
                        props.asignarCantidadDespachada(
                            producto,
                            cantidad_despachada + 1
                        );
                    }}
                    type="button"
                >
                    <img src={icons.mas} alt="+" />
                </button>
            </div>
        );
    };
}

export function formatoAccionDetalle(props) {
    return (cell, producto) => {
        return (
            <span
                className="text-danger"
                style={{ fontSize: "25px", cursor: "pointer" }}
                title="Quitar producto"
            >
                <em
                    className="fa fa-times fa-x2"
                    onClick={(e) => {
                        e.preventDefault();
                        props.quitar(producto);
                    }}
                ></em>
            </span>
        );
    };
}
