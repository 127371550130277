import React, { Component } from "react";
import Modal from "react-responsive-modal";
import SalidaProductoForm from "./SalidaProductosForm";
import { SeleccionSucursal } from "Utils/SeleccionSucursal";
import DestinoForm from "../../Configuracion/DestinosSalidaProducto/Crear/DestinoCrearForm";

export default class SalidaProductos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ver_buscador: false,
            open: false,
        };
    }

    componentWillMount() {
        this.props.listar();
    }

    cambioBuscador() {
        this.setState({ ver_buscador: !this.state.ver_buscador });
    }

    cierre = () => {
        this.setState({ open: false });
    };
    abrir = () => {
        this.setState({ open: true });
    };

    render() {
        //  State
        const {
            sucursal,
            loader,
            data,
            seleccionados,
            page,
            search,
        } = this.props;

        //  Bind
        const {
            listar,
            buscar,
            quitar,
            agregar,
            sortChange,
            agregarPrimer,
            asignarCantidad,
            crearDestinoSalida,
            efectuarSalidaProducto,
        } = this.props;

        if (sucursal === undefined) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo padding-5">
                                Para registrar una baja elija una sucursal
                            </div>
                            <div className="padding-15">
                                <SeleccionSucursal />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <SalidaProductoForm
                    loader={loader}
                    data={data}
                    page={page}
                    seleccionados={seleccionados}
                    ver_buscador={this.state.ver_buscador}
                    cambio_buscador={this.cambioBuscador.bind(this)}
                    search={search}
                    listar={listar}
                    buscar={buscar}
                    quitar={quitar}
                    agregar={agregar}
                    sortChange={sortChange}
                    agregarPrimer={agregarPrimer}
                    asignarCantidad={asignarCantidad}
                    onSubmit={efectuarSalidaProducto}
                    abrirDestino={this.abrir}
                />
                <Modal
                    classNames={{ modal: "modal-sm" }}
                    onClose={this.cierre}
                    showCloseIcon={false}
                    open={this.state.open}
                >
                    <DestinoForm
                        cancelar={this.cierre}
                        onSubmit={(data) => {
                            this.cierre();
                            crearDestinoSalida(data);
                        }}
                    />
                </Modal>
            </div>
        );
    }
}
