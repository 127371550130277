import React, { Component } from "react";
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import FraccionCrear from "../Fraccion/Crear/FraccionCrearContainer";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField";

const iconEditar = require("../../../../../assets/img/icons/editar.png");
const iconEliminar = require("../../../../../assets/img/icons/eliminar.png");
const iconAgregar = require("../../../../../assets/img/icons/upload-add-img.png");

function actionsFormat(params) {
    const { props, onOpenModal, onCloseModal, esEliminable } = params;
    const { selectFraccionAEditar, agregarFaccion } = props;
    return (cell, row, enumObject, index) => {
        return (
            <div>
                <img
                    onClick={() => {
                        agregarFaccion(row.nombre, onOpenModal);
                    }}
                    className="action-img"
                    title="Agregar fracción"
                    src={iconAgregar}
                />
                <img
                    onClick={() => {
                        selectFraccionAEditar(row.nombre);
                        onOpenModal();
                    }}
                    className="action-img"
                    title="Editar"
                    src={iconEditar}
                />
                <img
                    onClick={() => {
                        esEliminable(row);
                    }}
                    className="action-img"
                    title="Eliminar"
                    src={iconEliminar}
                />
            </div>
        );
    };
}

function nombreFormat(cell, row, enumObject, index) {
    return (
        <div className="container-nombre-fraccion">
            <span
                className="nombre-fraccion"
                style={{ marginLeft: index * 15 }}
            >
                {cell}
            </span>
            {/* <div className="flex3"></div> */}
        </div>
    );
}

class FraccionesListado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    esEliminable = (fraccion) => {
        if (fraccion.eliminable == true || fraccion.eliminable == undefined) {
            this.props.eliminarFraccion(fraccion.nombre);
        } else {
            Swal(
                "ERROR!",
                "No es posible eliminar este producto porque ya tiene ventas y/o inventario registrado.",
                "error"
            );
        }
    };

    render() {
        //  state
        const { open } = this.state;
        const { fracciones } = this.props;
        let infoPreciosSucursal = false;

        fracciones.forEach((fraccion) => {
            if (fraccion.precios_sucursal) {
                if (fraccion.precios_sucursal.length)
                    infoPreciosSucursal = true;
            }
        });
        //  bind
        return (
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <div className="row row-fields">
                    {fracciones.length == 0 ? (
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                            <button
                                type="button"
                                className="btn btn-primary-outline"
                                onClick={() => {
                                    this.props.agregarPrimeraFraccion();
                                    this.onOpenModal();
                                }}
                            >
                                <em className="fa fa-plus"></em>
                                <span> Agregar fracción </span>
                            </button>
                        </div>
                    ) : null}
                    <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                        <Modal
                            open={open}
                            onClose={this.onCloseModal}
                            center
                            showCloseIcon={false}
                            classNames={{ modal: "custom-modal modal-sm" }}
                        >
                            <FraccionCrear onCloseModal={this.onCloseModal} />
                        </Modal>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <BootstrapTable
                            data={fracciones}
                            options={{ noDataText: "No hay registros" }}
                        >
                            <TableHeaderColumn isKey dataField="codigo_barras">
                                Código de barras
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombre"
                                dataFormat={nombreFormat}
                                tdStyle={{ paddingTop: 0, paddingBottom: 0 }}
                            >
                                Presentación
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="precio"
                                dataAlign="right"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div
                                            className="w-100 d-flex align-items-center justify-content-end"
                                            style={{ minHeight: "50px" }}
                                        >
                                            {row.precios_sucursal &&
                                            row.precios_sucursal.length ? (
                                                <i className="fa fa-info-circle text-info mr-2"></i>
                                            ) : (
                                                <span />
                                            )}
                                            <RenderMoneda monto={cell} />
                                        </div>
                                    );
                                }}
                            >
                                Precio
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="capacidad_maxima"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div
                                            className="w-100 d-flex align-items-center"
                                            style={{ minHeight: "50px" }}
                                        >
                                            <RenderNumero monto={cell} />
                                            {row.contenido ? (
                                                <span className="ml-2">
                                                    {row.contenido}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    );
                                }}
                            >
                                Tiene
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                width="20%"
                                dataFormat={actionsFormat({
                                    props: this.props,
                                    onOpenModal: this.onOpenModal,
                                    onCloseModal: this.onCloseModal,
                                    esEliminable: this.esEliminable,
                                })}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </BootstrapTable>

                        {infoPreciosSucursal && (
                            <div className="w-100 my-2">
                                <div className="text-center text-info fnt-sm-10 font-weight-bold">
                                    <em className="fa fa-info-circle"></em>
                                    &nbsp;ESTA FRACCIÓN TIENE DEFINIDO PRECIOS
                                    DIFERENTES PARA OTRAS SUCURSALES.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default FraccionesListado;
