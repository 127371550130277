import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/devolucion/devolucion";
import CodigoFactura from "./CodigoFactura";
import { goBack } from "react-router-redux";


const ms2p = (state) => {
    return {
        ...state.devolucion,
        tipo_punto_venta: state.usuario.tipo_punto_venta,
    };
};

const md2p = { ...actions, regresar: () => (dispatch) => {dispatch(goBack())} };

export default connect(ms2p, md2p)(CodigoFactura);
