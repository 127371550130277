import { connect } from 'react-redux';

import GraficaCompras from './GraficaCompras'
import { actions } from "../../../../../redux/modules/orden_compra/orden_compra"

const ms2p = (state) => {
    return {
        ...state.ordenes_compra,
        pais: state.usuario.me.pais
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(GraficaCompras);
