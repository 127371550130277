import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import NumberFormat from "react-number-format";
import { icons } from "icons";
import {validarConfDecimales} from "../../../Utils/validaConfiguracionDecimal";

class EdicionDetalle extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        cambiarDescuento: PropTypes.func.isRequired,
        cambiarPrecio: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {
            item,
            index,
            venta_permitir_descuento,
            pais,
            conf_porcentaje_precio,
        } = this.props;
        const {
            cambiarPrecio,
            cambiarDescuento,
            ingresoCantidad,
            cambiarPrecioFinal,
        } = this.props;

        const numeroDeDecimal = validarConfDecimales(this.props.conf_empresa)

        return (
            <div
                style={{ cursor: "auto", fontSize: "13px" }}
                className={`${
                    !item.collapsed ? "collapsed" : ""
                } row detalle-item p-2`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="d-flex flex-column col-6">
                    <div className="font-weight-bold">Precio</div>
                    <select
                        className="form-control"
                        value={item.modelo_precio}
                        onChange={(event) => {
                            const value = event.target.value;
                            cambiarPrecio(index, value);
                        }}
                        style={{ padding: "6px 2px", height: "35px" }}
                    >
                        {item.listado_precios.map((precio) => {
                            return (
                                <option key={precio.id} value={precio.value}>
                                    {pais == "GT" ? "Q " : "$ "}
                                    {parseFloat(precio.value).toFixed(numeroDeDecimal)} - {precio.nombre}{" "}
                                    {conf_porcentaje_precio &&
                                        `(
                                    ${precio.porcentaje}%)`}
                                </option>
                            );
                        })}
                    </select>
                </div>
                {venta_permitir_descuento && (
                    <div className="d-flex flex-column col-6 p-0">
                        <div className="d-flex flex-row col-12 p-0">
                            <div className="d-flex flex-column col-6">
                                <div className="font-weight-bold ml-10">
                                    Descuento
                                </div>
                                <NumberFormat
                                    style={{
                                        height: "35px",
                                        padding: "6px 4px",
                                    }}
                                    decimalScale={4}
                                    className="form-control text-right ml-10"
                                    value={item.desc_porcentaje}
                                    suffix=" %"
                                    onValueChange={(values) => {
                                        cambiarDescuento(
                                            item,
                                            index,
                                            values.floatValue
                                        );
                                    }}
                                />
                            </div>
                            <div className="d-flex flex-column col-6">
                                <div className="font-weight-bold ml-10">
                                    Precio final
                                </div>
                                <NumberFormat
                                    style={{
                                        height: "35px",
                                        padding: "6px 4px",
                                    }}
                                    className="form-control text-right ml-10"
                                    decimalScale={numeroDeDecimal}
                                    fixedDecimalScale={true}
                                    value={item.precio_final}
                                    prefix={pais == "GT" ? "Q " : "$ "}
                                    onValueChange={(values) => {
                                        cambiarPrecioFinal(
                                            item,
                                            index,
                                            values.floatValue
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        {item.precio < item.costo && (
                            <div
                                className="col-12"
                                style={{ color: "#ac1900 !important" }}
                            >
                                <small className="ml-10 font-weight-bold ml-10 opacityPulse-css">
                                    El precio es menor al costo
                                </small>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default EdicionDetalle;
