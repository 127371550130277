import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/caja/caja";
import { print } from "../../../../../redux/modules/print/print";
import Impresion from "./Impresion";
import { tipo_movimientos } from "variables";

const ms2p = (state) => {
    const caja = state.caja.apertura;
    const usuario = state.usuario.me;

    return {
        ...state.caja,
        caja: caja ? caja : {},
        usuario,
        ruta: state.usuario.ruta,
        tipo_punto_venta: state.usuario.tipo_punto_venta,
        print_state: state.print,
        utiliza_fel: state.usuario.utiliza_fel,
    };
};

const md2p = {
    ...actions,
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(ms2p, md2p)(Impresion);
