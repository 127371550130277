export const tableOptions = {
    sizePerPage: 10,
    hideSizePerPage: true,
    alwaysShowAllBtns: true,
    noDataText: 'No hay registros',
};

export const BreakLine = {
    whiteSpace:'normal',
};
