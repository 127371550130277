import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import _ from "lodash";
import { ToastStore } from "react-toasts";
import Swal from "sweetalert2";
import uuidv1 from "uuid/v1";
import moment from "moment";
import { api } from "api";

// ------------------------------------
// Constants
// ------------------------------------

export const LOADER = "DEVOLUCION_LOADER";
export const DEVOLUCIONES = "DEVOLUCION_DEVOLUCIONES";
export const FACTURA = "DEVOLUCION_FACTURA";
export const ELEGIDOS = "DEVOLUCION_ELEGIDOS";
export const SELECCION_TODOS = "DEVOLUCION_SELECCION_TODOS";
export const DATA_ADICIONALES = "DEVOLUCION_DATA_ADICIONALES";
export const ADICIONALES_SELECCIONADOS = "DEVOLUCION_ADICIONALES_SELECCIONADOS";
export const LOADER_ADICONALES = "DEVOLUCION_LOADER_ADICONALES";

// PARA EL REPORTE DE DEVOLUCIONES
export const DETALLE = "DEVOLUCION_DETALLE";
export const PAGE = "DEVOLUCION_PAGE";
export const SORT = "DEVOLUCION_SORT";
export const BUSCADOR = "DEVOLUCION_BUSCADOR";
export const CLIENTE = "DEVOLUCION_CLIENTE";
export const USUARIO = "DEVOLUCION_USUARIO";
export const ESTADO = "DEVOLUCION_ESTADO";
export const UUID = "DEVOLUCION_UUID";
export const FECHA_INICIAL = "DEVOLUCION_FECHA_INICIAL";
export const FECHA_FINAL = "DEVOLUCION_FECHA_FINAL";
export const DEVOLUCION = "DEVOLUCION_DEVOLUCION";
export const ESTADO_DESCARGA = "DEVOLUCION_ESTADO_DESCARGA";

const endpoint = "devoluciones";
const endpoint_adicionales = "adiciones_nota_fel";

// ------------------------------------
// Actions
// ------------------------------------

const buscarFactura = () => (dispatch, getStore) => {
    const form = getStore().form.CodigoFacturaForm.values;
    dispatch(getFactura(form.codigo, true));
};

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const {
        ordering,
        search,
        fecha_inicial,
        fecha_final,
        usuario,
        cliente,
        estado,
    } = store.devolucion;
    const params = {
        todos: true,
        page,
        ordering,
        search,
        fecha_final,
        fecha_inicial,
    };
    const uuid = uuidv1();
    dispatch(setUuid(uuid));
    if (usuario) params.usuario_id = usuario.id;
    if (cliente) params.cliente_id = cliente.id;
    if (estado !== null) {
        params.estado = estado;
    }
    api.get(endpoint, params)
        .then((data) => {
            const uuidActual = getStore().devolucion.uuid;
            if (uuid === uuidActual) {
                dispatch(setDevoluciones(data));
                dispatch(setPage(page));
            }
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getFactura = (codigo, redirect = false) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/getFactura`, { codigo })
        .then((data) => {
            if (redirect) {
                dispatch(setElegidos([]));
                dispatch(push(`/devolucion/seleccion_de_producto/${data.id}`));
            }
            dispatch(setFactura(data));
        })
        .catch(() => {
            ToastStore.error(
                "Código de factura no existente, verifica e intenta de nuevo"
            );
            dispatch(push("/devolucion/"));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const elegir = (elegidos, id, seleccion_todos = false) => (dispatch) => {
    if (elegidos.length > 0) {
        // PARA EVITAR QUE VAYAN SIN VALOR SI NO CAMBIA LOS SELECTS
        elegidos.forEach((elegido) => {
            elegido.motivo = 10;
            elegido.ingreso_bodega = true;
            elegido.nota = "";
        });
        dispatch(setElegidos(elegidos));
        dispatch(setSeleccionTodos(seleccion_todos));
        dispatch(push(`/devolucion/seleccion_de_producto/${id}/motivos`));
    } else {
        ToastStore.error(
            "Seleccione al menos un producto que se está procesando para devolución."
        );
    }
};

const setParamElegido = (atr, value, fila) => (dispatch, getStore) => {
    const elegidos = _.cloneDeep(getStore().devolucion.elegidos);
    const row = _.find(elegidos, { key: fila.key });
    if (row) {
        row[atr] = value;
        if (atr == "porcentaje_precio") {
            row.precio_calculado = row.precio * (row[atr] / 100);
            row.precio_calculado = parseFloat(row.precio_calculado.toFixed(2));
        }
    }
    dispatch(setElegidos(elegidos));
};

const generarVale = (id) => (dispatch, getStore) => {
    const store = getStore();
    const { adicionales_seleccionados, elegidos } = store.devolucion;
    const productos = elegidos;
    dispatch(setLoader(true));
    let body = {
        productos: [...productos, ...adicionales_seleccionados],
        concepto: `Factura N° ${id}`,
        venta: id,
    };

    api.post(`${endpoint}`, body)
        .then((data) => {
            if (data.id_nota) {
                Swal(
                    "¡Nota de crédito en proceso!",
                    "Se esta generando la nota de crédito, espere por favor",
                    "info"
                );
                dispatch(consultarEstadoNotaFEL(data.id_nota, id));
            } else {
                dispatch(
                    push(
                        `/devolucion/seleccion_de_producto/${id}/motivos/vale/${data.id}`
                    )
                );
                dispatch(setLoader(false));
            }
        })
        .catch((e) => {
            Swal("ERROR", e.detail, "error");
            dispatch(setLoader(false));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getDevolucion = (id, id_factura) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .then((data) => {
            dispatch(setDevolucion(data));
        })
        .catch(() => {
            dispatch(push(`/devolucion/seleccion_de_producto/${id_factura}`));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const consultarEstadoNotaFEL = (id_nota, id_venta) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("notas_credito_fel/verificar_fel", { id_nota })
        .catch((err) => {
            Swal("ERROR", "Error al generar nota de crédito", "error");
            dispatch(setLoader(false));
        })
        .then((resp) => {
            status = resp.estado_nota;
            if (status === "proceso") {
                setTimeout(() => {
                    dispatch(consultarEstadoNotaFEL(id_nota, id_venta));
                }, 1000);
            } else if (status === "facturado") {
                Swal(
                    "Éxito",
                    "Nota de crédito registrada correctamente",
                    "success"
                ).then(() => {
                    dispatch(
                        push(
                            `/devolucion/seleccion_de_producto/${id_venta}/motivos/vale/${resp.nota.id}`
                        )
                    );
                });
                dispatch(setLoader(false));
            } else {
                const msj = resp.detail
                    ? `Error en la nota de crédito, ${resp.detail}`
                    : "";
                Swal(
                    "ERROR",
                    msj != ""
                        ? msj
                        : "Error en la nota de crédito, intente nuevamente",
                    "error"
                ).then((val) => {
                    dispatch(setLoader(false));
                    // dispatch(forzarFinalizarVenta());
                });
            }
        });
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`, { todos: true })
        .catch((err) => {
            dispatch(push("/devoluciones"));
        })
        .then((data) => {
            if (data) dispatch(setDetalle(data));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.producto.page;
    dispatch(listar(page));
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const devolucion = store.devolucion;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(devolucion.fecha_final);
        if (fecha_final >= fecha_inicial) dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(devolucion.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(listar());
};

export const setUsuario = (value) => (dispatch) => {
    dispatch(_setUsuario(value));
    dispatch(listar());
};

export const setCliente = (value) => (dispatch) => {
    dispatch(_setCliente(value));
    dispatch(listar());
};

export const estadoChange = (estado) => (dispatch) => {
    dispatch(_setEstado(estado));
    dispatch(listar());
};

export const asignaCantiad = (producto, valor) => (dispatch, getStore) => {
    const store = getStore();
    const elegidos = _.cloneDeep(store.devolucion.elegidos);
    let item = _.find(elegidos, { key: producto.key });
    const index = elegidos.indexOf(item);
    const cantidad = valor != null && valor != "" ? parseInt(valor) : 0;
    if (valor == null || valor == "") {
        item.requerido = true;
    }
    if (cantidad < 0) {
        item.requerido = true;
        item.cantidad = 0;
    } else {
        if (cantidad > item.disponible) {
            item.cantidad = item.disponible;
        } else {
            item.cantidad = cantidad;
        }
    }
    elegidos.splice(index, 1, item);
    dispatch(setElegidos(elegidos));
};

//  ADICIONALES
const getAdicionales = (page = 1) => (dispatch) => {
    dispatch(setLoaderAdicionales(true));
    api.get(`${endpoint_adicionales}`, { page })
        .then((data) => {
            dispatch(setDataAdicionales(data));
        })
        .catch((err) => {
            console.error(err);
            // ToastStore.error(
            //     "No fue posible obtener los adicionales"
            // );
        })
        .finally(() => {
            dispatch(setLoaderAdicionales(false));
        });
};
const elegidoAdicional = (id) => (dispatch, getStore) => {
    const store = getStore();
    return !!_.find(store.devolucion.adicionales_seleccionados, { key: id });
};

const precioAdicional = (id) => (dispatch, getStore) => {
    const store = getStore();
    const esta_seleccionado = !!_.find(
        store.devolucion.adicionales_seleccionados,
        { key: id }
    );
    if (esta_seleccionado) {
        const adicional = _.find(store.devolucion.adicionales_seleccionados, {
            key: id,
        });
        return adicional.precio;
    } else {
        return 0;
    }
};

const seleccionarAdicional = (adicional) => (dispatch, getStore) => {
    const store = getStore();
    const { factura } = store.devolucion;
    const adicionales_seleccionados = _.cloneDeep(
        store.devolucion.adicionales_seleccionados
    );
    const temp = _.find(adicionales_seleccionados, { key: adicional.id });
    if (temp) {
        const index = adicionales_seleccionados.indexOf(temp);

        adicionales_seleccionados.splice(index, 1);
        dispatch(setAdicionalesSeleccionados(adicionales_seleccionados));
    } else {
        let data_adicional = {
            id: null,
            key: adicional.id,
            precio: 0,
            porcentaje_precio: 100,
            cantidad: 1,
            nombre: adicional.nombre,
            nota: adicional.nombre,
            tipo: adicional.tipo,
            valor: adicional.valor,
            motivo: `${
                adicional.tipo == 10
                    ? "Monto fijo"
                    : `${adicional.valor}% sobre el total de la factura de la venta`
            }`,
        };
        let precio = 0;
        if (adicional.tipo == 10) {
            precio = parseFloat(
                adicional.valor ? adicional.valor.toFixed(2) : 0
            );
        } else {
            const porcentaje_factura = parseFloat(
                adicional.valor ? adicional.valor.toFixed(2) : 0
            );
            const total_factura = factura
                ? parseFloat(factura.movimiento.total_cobrar_pagar.toFixed(2))
                : 0;
            precio = parseFloat(
                ((porcentaje_factura * total_factura) / 100).toFixed(2)
            );
        }

        data_adicional.precio = precio;
        adicionales_seleccionados.push(data_adicional);
        dispatch(setAdicionalesSeleccionados(adicionales_seleccionados));
    }
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        ordering,
        search,
        fecha_inicial,
        fecha_final,
        usuario,
        cliente,
        estado,
    } = store.devolucion;
    let params = {
        todos: true,
        ordering,
        search,
        fecha_final,
        fecha_inicial,
    };
    if (usuario) params.usuario_id = usuario.id;
    if (cliente) params.cliente_id = cliente.id;
    if (estado !== null) {
        params.estado = estado;
    }

    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

// ------------------------------------
// PureActions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setDevolucion = (devolucion) => ({
    type: DEVOLUCION,
    devolucion,
});

export const setDevoluciones = (data) => ({
    type: DEVOLUCIONES,
    data,
});

export const setDetalle = (detalle) => ({
    type: DETALLE,
    detalle,
});

export const setElegidos = (elegidos) => ({
    type: ELEGIDOS,
    elegidos,
});

export const setFactura = (factura) => ({
    type: FACTURA,
    factura,
});

// PARA EL REPORTE DE DEVOLUCIONES
export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setUuid = (uuid) => ({
    type: UUID,
    uuid,
});

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const _setUsuario = (usuario) => ({
    type: USUARIO,
    usuario,
});

export const _setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});

export const _setEstado = (estado) => ({
    type: ESTADO,
    estado,
});
export const setSeleccionTodos = (seleccion_todos) => ({
    type: SELECCION_TODOS,
    seleccion_todos,
});

//  ADICIONALES
export const setDataAdicionales = (data_adicionales) => ({
    type: DATA_ADICIONALES,
    data_adicionales,
});
export const setAdicionalesSeleccionados = (adicionales_seleccionados) => ({
    type: ADICIONALES_SELECCIONADOS,
    adicionales_seleccionados,
});
export const setLoaderAdicionales = (loader_adicionales) => ({
    type: LOADER_ADICONALES,
    loader_adicionales,
});
const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const actions = {
    buscarFactura,
    getFactura,
    elegir,
    setParamElegido,
    generarVale,
    getDevolucion,
    listar,
    setFecha,
    buscar,
    setUsuario,
    setCliente,
    sortChange,
    estadoChange,
    leer,
    setElegidos,
    asignaCantiad,
    descargarListado,
    //  ADICIONALES
    getAdicionales,
    precioAdicional,
    elegidoAdicional,
    seleccionarAdicional,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [FACTURA]: (state, { factura }) => {
        return {
            ...state,
            factura,
        };
    },
    [ELEGIDOS]: (state, { elegidos }) => {
        return {
            ...state,
            elegidos,
        };
    },
    [DEVOLUCION]: (state, { devolucion }) => {
        return {
            ...state,
            devolucion,
        };
    },
    [DEVOLUCIONES]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [UUID]: (state, { uuid }) => {
        return {
            ...state,
            uuid,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [USUARIO]: (state, { usuario }) => {
        return {
            ...state,
            usuario,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [ESTADO]: (state, { estado }) => {
        return {
            ...state,
            estado,
        };
    },
    [DETALLE]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [SELECCION_TODOS]: (state, { seleccion_todos }) => {
        return {
            ...state,
            seleccion_todos,
        };
    },
    //  ADICIONALES
    [DATA_ADICIONALES]: (state, { data_adicionales }) => {
        return {
            ...state,
            data_adicionales,
        };
    },
    [ADICIONALES_SELECCIONADOS]: (state, { adicionales_seleccionados }) => {
        return {
            ...state,
            adicionales_seleccionados,
        };
    },
    [LOADER_ADICONALES]: (state, { loader_adicionales }) => {
        return {
            ...state,
            loader_adicionales,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    loader: false,
    factura: {
        id: null,
        cliente: {},
        movimiento: {
            detalles: [],
        },
        productos: [],
    },
    elegidos: [],
    devolucion: {
        movimiento: {
            detalles: [],
        },
        cliente: {},
    },
    data: {
        results: [],
        count: 0,
    },
    detalle: {},
    search: "",
    ordering: "",
    page: 1,
    uuid: null,
    cliente: null,
    usuario: null,
    estado: null,
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    seleccion_todos: false,
    data_adicionales: {},
    adicionales_seleccionados: [],
    loader_adicionales: false,
    estado_descarga: false,
};

export default handleActions(reducers, initialState);
