import _ from "lodash";
import React from 'react';
import { icons } from "icons";
import NumberFormat from 'react-number-format';
import SeleccionMetodosForm from './SeleccionMetodosForm'
import { RenderCurrency, RenderNumber, RenderDateTime, RenderDate  } from "Utils/renderField/renderReadField";

export function formatoAcciones(props) {
    return (cell, row) => {
        return (
            <div className="w-100 d-flex align-items-center justify-content-center">
                <button className="btn btn-agregar-venta" onClick={() => {
                    props.agregar(row);
                    props.cambioBuscador();
                }} >
                    <img className="icon-img m-0"src={icons.carreta} alt="Agregar"/>
                </button>
            </div>
        );
    }
}

export function formatoPrecio(cell, row) {
    return <RenderCurrency value={cell} />
}

export function formatoExistencias(cell, row) {
    return <RenderNumber value={row.inventario_calculado} decimalScale={row.producto.a_granel ? 3: 2} />
}
//////////////////////////////////////////////////////
//  Detalle orden online
//////////////////////////////////////////////////////

// ITEM ORDEN ONLINE
const ItemOrden = (props) => {
    //  State
    const { index, item } = props;
    // Bin
    const { quitar, restar, sumar, ingresoCantidad } = props;
    return (
        <div key={index} className="d-flex flex-column justify-content-between container-item">
            <div className="d-flex flex-column">
                {/* ENCABEZADO PRODUCTO */}
                <div className="w-100 d-flex flex-row">
                    {/* NOMBRE Y CODIGO DE PRODUCTO*/}
                    <div className="w-100">
                        <b>{item.producto.nombre}</b> {item.codigo_barras ? item.codigo_barras : ""}
                    </div>

                    {/* BOTON ELIMINAR */}
                    <div className="align-self-start text-right" style={{ width: "20px" }}>
                        <button className="btn-delete" onClick={(e) => {
                            e.stopPropagation();
                            quitar(index);
                        }}>
                            <em className="fa fa-times" />
                        </button>
                    </div>
                </div>
                {/* FIN ENCABEZADO PRODUCTO */}

                {/* BODY PRODUCTO */}
                <div className="d-flex flex1 flex-column justify-content-between">
                    <div className="d-flex justify-content-between align-items-center mt-10" style={{ fontSize: "13px" }}>
                        {/* PRECIO */}
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ flex: "0.6" }}>
                            <div className="width100 text-right font-weight-bold">
                                <RenderCurrency value={item.precio ? item.precio : 0} />
                            </div>
                            {
                                (item.desc_porcentaje > 0) && (
                                    <div className="width100 text-right precio-anterior">
                                        <RenderCurrency value={item.precio_original ? item.precio_original : 0} />
                                    </div>
                                )
                            }
                        </div>
                        &nbsp;&nbsp;&nbsp;

                        {/* CANTIDAD */}
                        <div className="text-center mr-1 flex-row-center" style={{ flex: "1.8" }}>
                            x
                            <div className="d-flex flex-row ml-10 flex1" style={{ height: "30px" }}>
                                <button className="btn btn-item-venta btn-menos d-flex align-items-center justify-content-center" onClick={(e) => {
                                    e.stopPropagation();
                                    restar(index);
                                }} type="button">
                                    {/* <img className="img-icono-cantidades" src={icons.menos} alt="-" /> */}
                                    <i className="fa fa-minus"></i>
                                </button>
                                <NumberFormat
                                    className="form-control venta-cantidad text-right"
                                    decimalScale={item.producto.a_granel ? 3 : 0} fixedDecimalScale={item.producto.a_granel ? true : false}
                                    value={item.cantidad}
                                    style={{ minWidth: "55px", padding: "6px 2px" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    onValueChange={(values) => {
                                        ingresoCantidad(index, values.floatValue);
                                    }}
                                />
                                <button className="btn btn-item-venta btn-mas d-flex align-items-center justify-content-center" onClick={(e) => {
                                    e.stopPropagation();
                                    sumar(index);
                                }} type="button">
                                    {/* <img className="img-icono-cantidades" src={icons.mas} alt="+" /> */}
                                    <i className="fa fa-plus"></i>
                                </button>
                            </div>

                            {/* UNIDAD DE MEDIDA */}
                            <div className="d-flex flex1 align-items-center justify-content-center" style={{ height: "35px" }}>
                                &nbsp;&nbsp;
                                <span className="flex1 text-center" style={{ paddingLeft: "3px" }}>{item.nombre}</span>
                            </div>
                        </div>

                        {/* SUBTOTAL */}
                        <b className="text-right" style={{ flex: "0.6" }}><RenderCurrency value={props.item.precio * props.item.cantidad} /></b>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function DetalleOrdenOnline(props) {
    //  State
    const { orden, seleccionados, desplegar, metodos_pago, metodos_entrega, total, total_envio } = props;
    //  Bind
    const { quitar, restar, sumar, ingresoCantidad, changeDesplegar, isMobile, regresar, editarOrden, cambioBuscador } = props;

    const tiene_nuevos_prod = _.find(seleccionados, function(i){ return i.detalle_id == undefined})

    return (
        <div className="grid-container mt0 border-radius-buttom-squa">
            {/* ENCABEZADO */}
            <div className="w-100 p-3 d-flex flex-column align-items-center justify-content-center container-encabezado-detalle-orden">
                <div className="w-100 d-flex flex-column flex-md-row flex-lg-row justify-content-between">
                    <div className="w-100 flex-1 word-break mr-0 mr-md-1 mr-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Código:</label>
                        {orden.codigo}
                    </div>
                    <div className="w-100 flex-1 word-break ml-0 ml-md-1 ml-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Fecha:</label>
                        <RenderDateTime value={orden.creado} />
                    </div>
                </div>
                <div className="w-100 d-flex flex-column flex-md-row flex-lg-row justify-content-between">
                    <div className="w-100 flex-1 word-break mr-0 mr-md-1 mr-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Nombre:</label>
                        {orden.nombre}
                    </div>
                    <div className="w-100 flex-1 word-break ml-0 ml-md-1 ml-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Apellido:</label>
                        {orden.apellido}
                    </div>
                </div>
                <div className="w-100 d-flex flex-column flex-md-row flex-lg-row justify-content-between">
                    <div className="w-100 flex-1 word-break mr-0 mr-md-1 mr-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Dirección:</label>
                        {orden.direccion}
                    </div>
                    <div className="w-100 flex-1 word-break ml-0 ml-md-1 ml-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Correo:</label>
                        {orden.correo}
                    </div>
                </div>
                <div className="w-100 d-flex flex-column flex-md-row flex-lg-row justify-content-between">
                    <div className="w-100 flex-1 word-break mr-0 mr-md-1 mr-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Departamento:</label>
                        {orden.departamento}
                    </div>
                    <div className="w-100 flex-1 word-break ml-0 ml-md-1 ml-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Municipio:</label>
                        {orden.municipio}
                    </div>
                </div>
                <div className="w-100 d-flex flex-column flex-md-row flex-lg-row justify-content-between">
                    <div className="w-100 flex-1 word-break mr-0 mr-md-1 mr-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Teléfono:</label>
                        {orden.telefono}
                    </div>
                    <div className="w-100 flex-1 word-break ml-0 ml-md-1 ml-lg-1 text-left">
                        <label className="pr-2" htmlFor="">Nit:</label>
                        {orden.nit}
                    </div>
                </div>
            </div>
            {/* FIN ENCABEZADO */}

            {/* METODOS */}
            <div className="container-sections d-flex align-items-center pl-2" onClick={() => changeDesplegar('metodos')}>
                {
                    desplegar != 'metodos' ? (
                        <em className="fa fa-angle-right icono-flechas negro-dark2 mr-2" />
                    ): (
                        <em className="fa fa-angle-down icono-flechas negro-dark2 mr-2" />
                    )
                }
                <span className="font-weight-bold text-uppercase negro-dark2" style={{paddingTop: "3px"}}>Métodos de pago y entrega</span>
            </div>
            <div className={`${desplegar != 'metodos' ? 'container-metodos-orden-online-collapsed': ''} container-productos-metodos-orden-online margin-left-metodos`}>
                <SeleccionMetodosForm
                    metodos_pago={metodos_pago}
                    metodos_entrega={metodos_entrega}/>
            </div>
            {/* FIN METODOS */}

            {/* PRODUCTOS DE LA ORDEN */}
            <div className={`${desplegar != 'productos' ? 'section-productos-collapsed' : ''} container-sections bg-naranja section-productos pl-2 text-left`} onClick={() => changeDesplegar('productos')}>
                {
                    desplegar != 'productos' ? (
                        <em className="fa fa-angle-right icono-flechas negro-dark2 mr-2" />
                    ): (
                        <em className="fa fa-angle-down icono-flechas negro-dark2 mr-2" />
                    )
                }
                <span className="font-weight-bold text-uppercase negro-dark2">PRODUCTOS DE LA ORDEN</span>
            </div>
            <div className={` ${desplegar != 'productos' ? 'container-productos-orden-online-collapsed' : ''} container-productos-metodos-orden-online`}>
                <div className="w-100 font-weight-bold negro-dark2 pl-2 pr-2 pt-1 pb-1 bg-naranja text-left">
                    {desplegar == 'productos' && (<em className="fa fa-angle-down icono-flechas negro-dark2 mr-2" />)}
                    PRODUCTOS DE LA ORDEN
                </div>
                {(seleccionados.length === 0) ? (
                    <h4 className="negro-dark2 mr-2 ml-2">La orden no tiene productos</h4>
                ): (
                    <div className="w-100 margin-left-5-naranja pt-2">
                        {seleccionados.map((item, index) => {
                            return(
                                <ItemOrden
                                    key={index}
                                    index={index}
                                    item={item}
                                    quitar={quitar}
                                    restar={restar}
                                    sumar={sumar}
                                    ingresoCantidad={ingresoCantidad}
                                />
                            )
                        })}
                    </div>
                )}
                {/* {
                    tiene_nuevos_prod && (
                        <div className="w-100">
                            <div className="w-100 font-weight-bold text-white pl-3 pr-2 pt-1 pb-1 bg-container-new-prod-cotizacion">
                                PRODUCTOS AGREGADOS A LA ORDEN
                            </div>
                            {(seleccionados.length === 0) ? (
                                <h4 className="negro-dark2 mr-2 ml-2">Agregue un producto a la venta</h4>
                            ) : (
                                <div className="w-100 margin-left-5-container-new-prod-cotizacion pt-2">
                                    {seleccionados.map((item, index) => {
                                        if (item.detalle_id == undefined) {
                                            return(
                                                <ItemOrden
                                                    key={index}
                                                    index={index}
                                                    item={item}
                                                    quitar={quitar}
                                                    restar={restar}
                                                    sumar={sumar}
                                                    ingresoCantidad={ingresoCantidad}
                                                />
                                            )
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                    )
                } */}
            </div>

            {/* SECCION TOTAL Y ACCIONES */}
            <div className={`align-items-center mt0 padding-5 d-flex justify-content-around border-radius-top-squa border-radius-buttom-squa flex-column  ${!isMobile() ? "bg-blanco" : ""} ${(isMobile() && seleccionados.length <= 0) ? "grid-container-mob" : "bg-blanco"}` }>
                {/* ACCIONES */}
                <div className="padding-10 p-lg-0 d-flex flex-column display-flex-width-100 justify-content-between d-lg-none d-xl-none">
                    <div className={`${(isMobile() && seleccionados.length > 0) ? "buttons-venta" : ""}`}>
                        <div className={`text-center flex1 mb-2 ${(isMobile && seleccionados.length > 0) ? "mr-1" : ""}`}>
                            <button className={`${(isMobile && seleccionados.length <= 0) ? "btn btn-secondary btn-lg btn-block" : "btn btn-secondary btn-lg btn-block btn btn-outline-secondary mb-1 backgr-white fnt-sm-10"}`} type="button" onClick={() => { cambioBuscador() }}>
                                <em className="fa fa-plus fa-lg mr"></em>
                                Agregar Producto
                            </button>
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex flex-column">
                    <div className="flex-row d-flex w-100 mt-2">
                        <h5 className="flex1 ml-2 text-left"><b>Total envío</b></h5>
                        <h5 className="flex1 content-total mr-2 text-right"><b><RenderCurrency value={total_envio} /></b></h5>
                    </div>
                    <div className="flex-row d-flex w-100 padding-bottom-10">
                        <h3 className="flex1 ml-2 text-left"><b>Total</b></h3>
                        <h4 className="flex1 content-total mr-2 text-right"><b><RenderCurrency value={total} /></b></h4>
                    </div>
                </div>
                {/* BOTONES */}
                <div className="venta-btns mt0 container-botones-venta d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-row align-items-center justify-content-center mr-2">
                        <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                            <button style={ {minHeight: "67px", width:"72px"}} className="rounded white-space-normal btn btn-primary d-flex flex-column align-items-center justify-content-center" type="submit" onClick={() => {
                                editarOrden();
                            }}>
                                <img className="m-0 mb-1" src={icons.save}/>
                                Guardar
                            </button>
                        </div>
                    </div>
                    <div className="d-flex m-1 align-items-center justify-content-center ml-2">
                        <button style={{minHeight: "67px", width:"72px"}} className="rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center" type="submit" onClick={(e) => {
                            e.stopPropagation();
                            regresar();
                        }}>
                            <img className="m-0 mb-1" src={icons.anular_cotizacion} />
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
