import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/devolucion/devolucion";
import { print } from "../../../../../redux/modules/print/print";
import Vale from "./Vale";

const ms2p = (state) => {
    let empresa = "";
    try {
        empresa = state.usuario.me.empresa ? state.usuario.me.empresa : "";
    } catch (e) {}
    return {
        ...state.devolucion,
        empresa,
        print_state: state.print,
        utiliza_fel: state.usuario.utiliza_fel,
    };
};

const md2p = {
    ...actions,
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(ms2p, md2p)(Vale);
