import React from "react";
import { icons } from "icons";
import NumberFormat from "react-number-format";

export function formatoReajuste(props) {
    return (cell, producto) => {
        return (
            <div className="d-flex flex-row">
                <button
                    className="btn btn-cantidad-detalle btn-menos"
                    onClick={() => {
                        const reajuste = producto.reajuste
                            ? producto.reajuste
                            : 0;
                        props.asignarReajuste(producto, reajuste - 1);
                    }}
                    type="button"
                >
                    <img src={icons.menos} alt="-" />
                </button>
                <NumberFormat
                    className="form-control bodega-ingreso text-right"
                    placeholder="0"
                    decimalScale={producto.producto.a_granel ? 3 : 0}
                    fixedDecimalScale={
                        producto.producto.a_granel ? true : false
                    }
                    value={producto.reajuste}
                    thousandSeparator={true}
                    prefix={""}
                    style={{ padding: "2px" }}
                    onValueChange={(values) => {
                        let val = "";
                        if (
                            values.value != "" &&
                            values.value != undefined &&
                            values.value != null
                        ) {
                            val = producto.producto.a_granel
                                ? parseFloat(values.value)
                                : parseInt(values.value);
                        }
                        props.asignarReajuste(producto, val);
                    }}
                />
                <button
                    className="btn btn-cantidad-detalle btn-mas"
                    onClick={() => {
                        const reajuste = producto.reajuste
                            ? producto.reajuste
                            : 0;
                        props.asignarReajuste(producto, reajuste + 1);
                    }}
                    type="button"
                >
                    <img src={icons.mas} alt="+" />
                </button>
            </div>
        );
    };
}
