import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/gastos/gastos";
import ListadoGastos from './ListadoGastos';


const ms2p = (state) => {
    return {
        ...state.gastos,
        sucursal: state.usuario.sucursal,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoGastos);
