import React from 'react';
import {
    formatoNombre,
    formatoExistencia,
    buscar,
    formatoProducto,
    formatoAvatar,
    formatoAvatarMovil,
    formatoMarca,
} from "Utils/CamposComunes";
import { formatoAcciones, formatoPrecio, formatoExistencias } from "../../Ventas/Venta/CamposVenta";
import { icons } from "icons";
import { RenderCurrency, RenderNumber } from "Utils/renderField/renderReadField";

class CardProduct extends React.Component {
    render()
    {
        const { cambioBuscador, item, forzar_agregar } = this.props;
        return(
            <div className="d-lg-none d-xl-none">
                <div className="card text-center">
                    <div className="card-header card-listado-prods">
                        <div style={{width:"50%", display:"inline-block"}}>
                            {formatoAvatarMovil(0, item)}
                        </div>
                        <div style={{textAlign:"left", width:"50%", display:"inline-block"}}>
                            <h6>Nombre</h6>
                            <p><b>{item.producto.nombre}</b></p>
                            <p>{item.nombre}</p>
                        </div>
                    </div>
                    <div className="card-listado-prods card-body">
                        <h6><b>Marca</b><p>{item.producto.marca}</p></h6>
                        <h6><b>Precio</b> <p><RenderCurrency value={item.precio} /></p> </h6>
                        <h6><b>Existencia</b> <p>{item.inventario}</p></h6>
                    </div>
                    <div>
                    {

                        (item.existe_descontable || this.props.conf_cotizacion) ? (
                            <button type="button" className="btn btn-outline-primary my-3" style={{backgroundColor: "white", width:"90%" ,border:"1px solid #CF6F44"}} onClick={() => {
                                this.props.agregar(item);
                                this.props.cambioBuscador();
                            }}>
                                <img className="icon-img" src={icons.carreta} alt="Agregar"/>
                                Agregar a la venta
                            </button>
                        ) : (
                            !forzar_agregar && (
                                <div className="rojo my-2">Existencias agotadas</div>
                            )
                        )
                    }
                    {
                        forzar_agregar && (
                            <button type="button" className="btn btn-outline-primary my-3" style={{backgroundColor: "white", width:"90%" ,border:"1px solid #CF6F44"}} onClick={() => {
                                this.props.agregar(item);
                                this.props.cambioBuscador();
                            }}>
                                <img className="icon-img" src={icons.carreta} alt="Agregar"/>
                                Agregar a la venta
                            </button>
                        )
                    }
                    </div>
                </div>
                <br></br>
            </div>
        )
    }
}

export default CardProduct;
