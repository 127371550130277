import { connect } from "react-redux";
import { setNavbarCollapse } from "../../../../redux/modules/dashboard/dashboard";
import Mostrador from "./Mostrador";
import { actions as actionsVenta } from "../../../../redux/modules/venta/venta";
import { push } from "react-router-redux";
import { actions } from "../../../../redux/modules/punto_venta/mostrador";
import { print } from "../../../../redux/modules/print/print";

const ms2p = (state) => {
    let total = 0;
    state.venta.seleccionados.forEach((producto) => {
        if (!producto.es_vale) {
            const precio = producto.precio ? parseFloat(producto.precio) : 0;
            const cantidad = producto.cantidad
                ? parseFloat(producto.cantidad)
                : 0;
            total = total + precio * cantidad;
        } else {
            total -= producto.total_importe;
        }
    });
    return {
        ...state.punto_venta_mostrador,
        sucursal: state.usuario.sucursal,
        tipo_punto_venta: state.usuario.tipo_punto_venta,
        loader_productos: state.venta.loader,
        data_productos: state.venta.data,
        page_productos: state.venta.page,
        search_productos: state.venta.search,
        seleccionados: state.venta.seleccionados,
        ver_buscador: state.venta.ver_buscador,
        ver_cobro: state.venta.ver_cobro,
        cotizacion_cargada: state.venta.cotizacion_cargada,
        venta_permitir_descuento: state.usuario.venta_permitir_descuento,
        pais: state.usuario.me.pais,
        conf_cotizacion: state.usuario.conf_cotizacion,
        total,
        print_state: state.print,
        utiliza_fel: state.usuario.utiliza_fel,
        conf_venta_precio_editable: state.usuario.conf_venta_precio_editable,
        conf_porcentaje_precio: state.usuario.conf_porcentaje_precio,
        me: state.usuario.me,
        conf_empresa: state.usuario,
    };
};
const md2p = {
    // Actions venta
    ...actions,
    listarProductos: (page) => (dispatch) =>
        dispatch(actionsVenta.listar(page)),
    sortChangeProductos: (sortName, sortOrder) => (dispatch) =>
        dispatch(actionsVenta.sortChange(sortName, sortOrder)),
    buscarProductos: (buscar) => (dispatch) =>
        dispatch(actionsVenta.buscar(buscar)),
    agregar: (item) => (dispatch) => dispatch(actionsVenta.agregar(item)),
    desplegarItem: (item, index) => (dispatch) =>
        dispatch(actionsVenta.desplegarItem(item, index)),
    quitar: (item, index) => (dispatch) =>
        dispatch(actionsVenta.quitar(item, index)),
    sumar: (item, index) => (dispatch) =>
        dispatch(actionsVenta.sumar(item, index)),
    restar: (item, index) => (dispatch) =>
        dispatch(actionsVenta.restar(item, index)),
    ingresoCantidad: (cantidad, item, index) => (dispatch) =>
        dispatch(actionsVenta.ingresoCantidad(cantidad, item, index)),
    cambiarDescuento: (item, index, valor) => (dispatch) =>
        dispatch(actionsVenta.cambiarDescuento(item, index, valor)),
    cambiarPrecioFinal: (item, index, valor) => (dispatch) =>
        dispatch(actionsVenta.cambiarPrecioFinal(item, index, valor)),
    cambiarPrecio: (item, index) => (dispatch) =>
        dispatch(actionsVenta.cambiarPrecio(item, index)),
    cambiarPrecioEditable: (index, precio) => (dispatch) =>
        dispatch(actionsVenta.cambiarPrecioEditable(index, precio)),
    agregarPrimer: (search) => (dispatch) =>
        dispatch(actionsVenta.agregarPrimer(search)),
    cambioBuscador: () => (dispatch) => dispatch(actionsVenta.cambioBuscador()),
    // Otros
    setNavbarCollapse: (estado) => (dispatch) =>
        dispatch(setNavbarCollapse(estado)),
    pushRouter: (link) => (dispatch) => dispatch(push(link)),
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(ms2p, md2p)(Mostrador);
