import { api } from "api";
import moment from "moment";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { handleActions } from "redux-actions";

// ------------------------------------
// CONSTANTS
// ------------------------------------
const endpoint = "descuento_recargo_ventas";

export const DATA = "R_DESCUENTO_RECARGO_DATA";
export const PAGE = "R_DESCUENTO_RECARGO_PAGE";
export const TIPO = "R_DESCUENTO_RECARGO_TIPO";
export const SORT = "R_DESCUENTO_RECARGO__SORT";
export const LOADER = "R_DESCUENTO_RECARGO_LOADER";
export const FECHA_FINAL = "R_DESCUENTO_RECARGO_FECHA_FINAL";
export const FECHA_INICIAL = "R_DESCUENTO_RECARGO_FECHA_INICIAL";
export const ESTADO_DESCARGA = "R_DESCUENTO_RECARGO_ESTADO_DESCARGA";

// ------------------------------------
// ACTIONS
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const {
        fecha_inicial,
        fecha_final,
        tipo,
        sort,
    } = store.reporte_descuento_recargo;
    const params = { fecha_final, fecha_inicial, page, tipo, sort };
    api.get(`${endpoint}`, params)
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error, intenta más tarde.", "error");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setData(resp));
                dispatch(setPage(page));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    dispatch(listar());
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_descuento_recargo = store.reporte_descuento_recargo;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(reporte_descuento_recargo.fecha_final);
        if (fecha_final >= fecha_inicial) dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(reporte_descuento_recargo.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(listar());
};

export const tipoChange = (value) => (dispatch) => {
    dispatch(setTipo(value));
    dispatch(listar());
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        fecha_inicial,
        fecha_final,
        tipo,
    } = store.reporte_descuento_recargo;
    const params = { fecha_final, fecha_inicial, tipo };

    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel_reporte`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Descuentos_recargos.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

// ------------------------------------
// PURE ACTIONS
// ------------------------------------
export const setData = (data) => ({
    type: DATA,
    data,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});
export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const setSort = (sort) => ({
    type: SORT,
    sort,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});
const setTipo = (tipo) => ({
    type: TIPO,
    tipo,
});

export const actions = {
    listar,
    setFecha,
    sortChange,
    tipoChange,
    descargarListado,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { sort }) => {
        return {
            ...state,
            sort,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [TIPO]: (state, { tipo }) => {
        return {
            ...state,
            tipo,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------
export const initialState = {
    data: {},
    loader: false,
    page: 1,
    sort: 'id',
    estado_descarga: false,
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    tipo: "",
};

export default handleActions(reducers, initialState);
