import { api } from "api";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form";
import { actions as printActions } from "../print/print";

const endpoint = "cuenta_cobrar";
const endpoint_reimpresion_venta = "historial_ventas/reimpresion_venta";
const endpoint_movimientos = "movimientos";

// ------------------------------------
// Constants
// ------------------------------------

export const DATA = "CUENTA_COBRAR_DATA";
export const SORT = "CUENTA_COBRAR_SORT";
export const PAGE = "CUENTA_COBRAR_PAGE";
export const BUSCADOR = "CUENTA_COBRAR_BUSCADOR";
export const LOADER = "CUENTA_COBRAR_LOADER";
export const SET_IMPRESION = "CUENTA_COBRAR_SET_IMPRESION";
export const SET_MOVIMIENTO_VENTA = "CUENTA_COBRAR_SET_MOVIMIENTO_VENTA";
export const SET_CODIGO_NOTA_CREDITO = "CUENTA_COBRAR_SET_CODIGO_NOTA_CREDITO";
export const SET_NOTA_CREDITO_CARGADA =
    "CUENTA_COBRAR_SET_NOTA_CREDITO_CARGADA";

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const params = store.cuenta_cobrar;
    const { ordering, search } = params;
    api.get(endpoint, {
        page,
        ordering,
        search,
        completo: false,
        activos: true,
    })
        .catch((err) => {})
        .then((data) => {
            if (data) {
                dispatch(seData(data));
            }
            dispatch(setPage(page));
            dispatch(setLoader(false));
        });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.sucursal.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const seleccionImpresionReciboFactura = (id, seccionImpresion) => (
    dispatch
) => {
    dispatch(setLoader(true));
    api.get(endpoint_reimpresion_venta, { id })
        .catch((err) => {
            dispatch(setVentaImpresion(null));
        })
        .then((resp) => {
            if (resp) {
                let data_impresion = resp;
                let vuelto = 0;
                if (data_impresion.length === undefined) {
                    vuelto = data_impresion.vuelto_efectivo
                        ? data_impresion.vuelto_efectivo
                        : 0;
                    data_impresion.monto_efectivo =
                        data_impresion.monto_efectivo + vuelto;
                }
                dispatch(setVentaImpresion(data_impresion));
                dispatch(printActions.print(seccionImpresion));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getMovimientoVenta = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint_movimientos}/${id}`)
        .catch((err) => {
            dispatch(setMovimeintoVenta(null));
        })
        .then((resp) => {
            if (resp) {
                dispatch(setMovimeintoVenta(resp));
                dispatch(initializeForm("CobroCreditoForm", {}));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const realizarAbono = (data) => (dispatch, getStore) => {
    const store = getStore();
    const {
        id,
        saldo,
    } = store.cuenta_cobrar.movimiento_venta.cuenta_cobrarpagar;
    const { nota_credito_cargada } = store.cuenta_cobrar;
    let efectivo = parseFloat(data.efectivo ? data.efectivo : 0);
    let tarjeta = parseFloat(data.tarjeta ? data.tarjeta : 0);
    let cheque = parseFloat(data.cheque ? data.cheque : 0);
    let deposito = parseFloat(data.deposito ? data.deposito : 0);
    let retencion_iva = parseFloat(data.retencion_iva ? data.retencion_iva : 0);
    let exencion_iva = parseFloat(data.exencion_iva ? data.exencion_iva : 0);
    let nota_credito = parseFloat(data.nota_credito ? data.nota_credito : 0);

    const body = {
        id: id,
        efectivo: parseFloat(efectivo.toFixed(2)),
        tarjeta: parseFloat(tarjeta.toFixed(2)),
        cheque: parseFloat(cheque.toFixed(2)),
        deposito: parseFloat(deposito.toFixed(2)),
        retencion_iva: parseFloat(retencion_iva.toFixed(2)),
        exencion_iva: parseFloat(exencion_iva.toFixed(2)),
        nota_credito: parseFloat(nota_credito.toFixed(2)),
        codigo_nota_credito: nota_credito_cargada
            ? nota_credito_cargada.codigo
            : null,
        nota: data.nota ? data.nota : "",
        codigo_venta: data.codigo_venta ? data.codigo_venta : "",
    };
    let total_pago = (
        efectivo +
        tarjeta +
        cheque +
        deposito +
        retencion_iva +
        exencion_iva +
        nota_credito
    ).toFixed(2);

    total_pago = parseFloat(total_pago);
    if (parseFloat(saldo.toFixed(2)) >= total_pago) {
        dispatch(setLoader(true));
        api.post(`${endpoint}/abono`, body)
            .then(() => {
                dispatch(limpiarFormAbono());
                dispatch(
                    push(
                        `/cuentas_por_cobrar/detalle/${
                            getStore().cuenta_cobrar.movimiento_venta.id
                        }`
                    )
                );
            })
            .catch((e) => {
                Swal("ERROR", e.detail, "error");
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    } else {
        Swal(
            "ERROR",
            "El total del abono es mayor al monto total de la cuenta",
            "error"
        );
    }
};

const marcarEntrega = (id) => (dispatch, getStore) => {
    Swal({
        title: "Marcar la entrega del pedido?",
        text: "No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, marcar entrega",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`historial_ventas/${id}/marcar_entrega`)
                .then(() => {
                    ToastStore.success(
                        "Registro de entrega efectuado correctamente."
                    );
                    dispatch(listar(getStore().cuenta_cobrar.page));
                })
                .catch((e) => {
                    Swal("ERROR", e.detail, "error");
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    });
};

export const consultarNotaCredito = (total) => (dispatch, getStore) => {
    const store = getStore();
    const { codigo_nota_credito } = store.cuenta_cobrar;

    api.get("notas_credito_fel/consultar", { codigo_nota_credito })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
            dispatch(setNotaCreditoCargada(null));
        })
        .then((resp) => {
            if (resp) {
                //  Validar si se agrega o no la nota de crédito por el monto total y el total de importe
                // if (resp.total_importe > total) {
                //     Swal(
                //         "Info",
                //         `No es posible agregar la nota de crédito porque el total de la venta (${total}) es menor al monto de la nota de crédito (${resp.total_importe})`,
                //         "info"
                //     );
                // } else {
                // }
                dispatch(setNotaCreditoCargada(resp));
                dispatch(setNotaCredito(resp.total_importe, total));
            } else {
                dispatch(setNotaCreditoCargada(null));
            }
        });
};

export const setNotaCredito = (monto) => (dispatch, getStore) => {
    const store = getStore();
    const values = store.form.CobroCreditoForm.values;

    let nota_credito = 0;
    if (monto) {
        if (monto < 0) {
            nota_credito = 0;
        } else {
            nota_credito = monto;
        }
    }
    dispatch(
        initializeForm("CobroCreditoForm", {
            ...values,
            nota_credito,
        })
    );
};

export const limpiarNotaCredito = () => (dispatch, getStore) => {
    dispatch(setCodigoNotaCredito(""));
    dispatch(setNotaCreditoCargada(null));
    const store = getStore();
    const values = store.form.CobroCreditoForm.values;
    dispatch(
        initializeForm("CobroCreditoForm", {
            ...values,
            nota_credito: 0,
        })
    );
};

export const limpiarFormAbono = () => (dispatch) => {
    dispatch(initializeForm("CobroCreditoForm", {}));
    dispatch(limpiarNotaCredito());
};

// ------------------------------------
// PureActions
// ------------------------------------

export const seData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setVentaImpresion = (venta_impresion) => ({
    type: SET_IMPRESION,
    venta_impresion,
});

export const setMovimeintoVenta = (movimiento_venta) => ({
    type: SET_MOVIMIENTO_VENTA,
    movimiento_venta,
});
export const setCodigoNotaCredito = (codigo_nota_credito) => ({
    type: SET_CODIGO_NOTA_CREDITO,
    codigo_nota_credito,
});
export const setNotaCreditoCargada = (nota_credito_cargada) => ({
    type: SET_NOTA_CREDITO_CARGADA,
    nota_credito_cargada,
});

export const actions = {
    sortChange,
    buscar,
    realizarAbono,
    listar,
    marcarEntrega,
    setMovimeintoVenta,
    seleccionImpresionReciboFactura, // para la impresion del recibo o la factura
    getMovimientoVenta, //  para el recibo del credito
    setNotaCredito,
    setCodigoNotaCredito,
    consultarNotaCredito,
    limpiarNotaCredito,
    limpiarFormAbono,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SET_IMPRESION]: (state, { venta_impresion }) => {
        return {
            ...state,
            venta_impresion,
        };
    },
    [SET_MOVIMIENTO_VENTA]: (state, { movimiento_venta }) => {
        return {
            ...state,
            movimiento_venta,
        };
    },
    [SET_CODIGO_NOTA_CREDITO]: (state, { codigo_nota_credito }) => {
        return {
            ...state,
            codigo_nota_credito,
        };
    },
    [SET_NOTA_CREDITO_CARGADA]: (state, { nota_credito_cargada }) => {
        return {
            ...state,
            nota_credito_cargada,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    item: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "-creado",
    venta_impresion: null,
    movimiento_venta: null,
    codigo_nota_credito: "",
    nota_credito_cargada: null,
};

export default handleActions(reducers, initialState);
