import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/login';
import { getMe } from "../../../../redux/modules/usuario/usuario";
import Login from './Login';


const ms2p = (state) => {
  return {
    ...state.login,
    usuario: state.usuario.me
  };
};

const md2p = { ...actions, getMe: () => dispatch => dispatch(getMe()) };

export default connect(ms2p, md2p)(Login);
