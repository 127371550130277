import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SucursalForm from './SucursalCrearForm'

class SucursalCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
    }

    render(){
        // state

        // bind
        const {crear} = this.props;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <SucursalForm onSubmit={crear}/>
                </div>
            </div>
        )
    }
}

export default SucursalCrear