import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import {
    RenderDateTime,
    RenderMoneda,
    RenderNumero,
    RenderPorcentaje,
} from "Utils/renderField/renderTableField";
import {
    RenderDateTime as RDateTime,
    RenderCurrency,
} from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { Link } from "react-router-dom";
import { opcionesDevolucion } from "../../../../../utility/variables";

class DetalleDevolucion extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        detalle: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render() {
        const { loader, detalle, venta_permitir_descuento } = this.props;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        const movimiento = detalle.movimiento
            ? detalle.movimiento
            : { detalles: [], usuario: {} };
        const cliente = detalle.cliente ? detalle.cliente : {};

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="mb-1 mt-1">
                            <LoadMask loading={loader} light blur>
                                <div className="d-flex flex-column padding-15">
                                    <h3>
                                        <b>Detalle de la devolución</b>
                                    </h3>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Brindado por</b>
                                            {movimiento.usuario.first_name}
                                        </div>
                                        {!!detalle.canjeado_por && (
                                            <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                                <b>Canjeado por</b>
                                                {detalle.canjeado_por}
                                            </div>
                                        )}
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Fecha / hora</b>
                                            <RDateTime
                                                value={movimiento.creado}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Comprador</b>
                                            {cliente.nombre}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>NIT</b>
                                            {cliente.nit ? cliente.nit : "C/F"}
                                        </div>
                                    </div>
                                </div>
                                <BootstrapTable
                                    data={loader ? [] : movimiento.detalles}
                                    hover
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        width="0%"
                                        dataField="id"
                                        isKey
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="nombre"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <div>
                                                    {/* VALIDAR SI ES VALE EL ITEM */}
                                                    {row.es_vale ? (
                                                        <b>
                                                            Descuento (
                                                            {row.vale.codigo})
                                                        </b>
                                                    ) : (
                                                        <span>
                                                            {row.producto ? (
                                                                <b>
                                                                    {
                                                                        row.producto
                                                                    }
                                                                </b>
                                                            ) : (
                                                                <b>
                                                                    {row.nota}
                                                                </b>
                                                            )}
                                                            {row.fraccion ? (
                                                                <div>
                                                                    {row
                                                                        .fraccion
                                                                        .producto
                                                                        .a_granel
                                                                        ? row
                                                                              .fraccion
                                                                              .unidad_de_medida
                                                                              .nombre
                                                                        : row
                                                                              .fraccion
                                                                              .nombre}
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {
                                                                        row.concepto
                                                                    }
                                                                </div>
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        }}
                                    >
                                        Producto
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="precio"
                                        dataAlign="right"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda
                                                    monto={
                                                        row.es_vale
                                                            ? cell * -1
                                                            : cell
                                                    }
                                                />
                                            );
                                        }}
                                    >
                                        Precio
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cantidad"
                                        dataAlign="right"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderNumero
                                                    monto={cell}
                                                    decimalScale={
                                                        row.fraccion
                                                            ? row.fraccion
                                                                  .producto
                                                                  .a_granel
                                                                ? 3
                                                                : 2
                                                            : 0
                                                    }
                                                />
                                            );
                                        }}
                                    >
                                        Cantidad
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="motivo_devolucion"
                                        dataAlign="right"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell) => {
                                            return _.find(opcionesDevolucion, {
                                                value: cell,
                                            })
                                                ? _.find(opcionesDevolucion, {
                                                      value: cell,
                                                  }).label
                                                : "----";
                                        }}
                                    >
                                        Motivo de la devolución
                                    </TableHeaderColumn>
                                    {!!venta_permitir_descuento && (
                                        <TableHeaderColumn
                                            dataField="desc_monto"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderMoneda
                                                        monto={
                                                            cell
                                                                ? cell *
                                                                  row.cantidad
                                                                : 0
                                                        }
                                                    />
                                                );
                                            }}
                                        >
                                            Monto descuento
                                        </TableHeaderColumn>
                                    )}

                                    {!!venta_permitir_descuento && (
                                        <TableHeaderColumn
                                            dataField="desc_porcentaje"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderPorcentaje
                                                        monto={cell ? cell : 0}
                                                    />
                                                );
                                            }}
                                        >
                                            Descuento
                                        </TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        dataField="sub_total"
                                        dataAlign="right"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda monto={cell} />
                                            );
                                        }}
                                    >
                                        Subtotal
                                    </TableHeaderColumn>
                                </BootstrapTable>
                                <div className="border-bottom w-100 pl-3 pr-3 mt-2 d-flex justify-content-between flex-row">
                                    <h3>
                                        <b>Total</b>
                                    </h3>
                                    <h3>
                                        <b>
                                            <RenderCurrency
                                                value={movimiento.total}
                                            />
                                        </b>
                                    </h3>
                                </div>
                            </LoadMask>
                            <div className="d-flex justify-content-center flex-row w-100 mt-3 mb-3">
                                <Link
                                    className="btn btn-tertiary m-1"
                                    to={"/reporte_devoluciones"}
                                >
                                    <i
                                        className="fa fa-arrow-left"
                                        aria-hidden="true"
                                    />{" "}
                                    Regresar
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetalleDevolucion;
