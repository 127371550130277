import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { ToastStore } from "react-toasts";

const endpoint = "bodegas/reporte_baja_salida";

export const DATA = "REP_HISTORIAL_BAJA_SALIDA_DATA";
export const FECHA_INICIAL = "REP_HISTORIAL_BAJA_SALIDA_FECHA_INICIAL";
export const FECHA_FINAL = "REP_HISTORIAL_BAJA_SALIDA_FECHA_FINAL";
export const LOADER = "REP_HISTORIAL_BAJA_SALIDA_LOADER";
export const SORT = "REP_HISTORIAL_BAJA_SALIDA_SORT";
export const SUCURSAL = "REP_HISTORIAL_BAJA_SALIDA_SUCURSAL";
export const TIPO_MOVIMIENTO = "REP_HISTORIAL_BAJA_SALIDA_TIPO_MOVIMIENTO";
export const PAGE = "REP_HISTORIAL_BAJA_SALIDA_PAGE";
export const DESTINO_SALIDA = "REP_HISTORIAL_BAJA_SALIDA_DESTINO_SALIDA";
export const ESTADO_DESCARGA = "REP_HISTORIAL_BAJA_SALIDA_ESTADO_DESCARGA";

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();

    const {
        fecha_inicial,
        fecha_final,
        sort,
        sucursal,
        tipo_movimiento,
        destino_salida,
    } = store.reporte_historial_baja_salida;

    let params = {
        fecha_inicial,
        fecha_final,
        tipo: tipo_movimiento,
        sucursal_rep: sucursal ? sucursal.id : "",
        sort,
    };

    if (tipo_movimiento == 130) {
        params.destino_salida = destino_salida ? destino_salida.id : "";
    }

    api.get(endpoint, params)
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setData(resp));
                dispatch(setPage(page));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.historial_baja_salida ? store.historial_baja_salida.page : 1;
    dispatch(listar(page));
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_historial_baja_salida = store.reporte_historial_baja_salida;
    if (key === "Inicial") {
        const fecha_inicial = moment(value).toDate();
        const fecha_final = moment(
            reporte_historial_baja_salida.fecha_final
        ).toDate();
        if (fecha_final >= fecha_inicial) dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = moment(
            reporte_historial_baja_salida.fecha_inicial
        ).toDate();
        const fecha_final = moment(value).toDate();
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(listar());
};

export const setSucursal = (value) => (dispatch) => {
    dispatch(_setSucursal(value));
    dispatch(listar());
};

export const tipoMovimientoChange = (tipo) => (dispatch) => {
    dispatch(setTipoMovimiento(tipo));
    dispatch(listar());
};

export const setDestinoSalida = (value) => (dispatch) => {
    dispatch(_setDestinoSalida(value));
    dispatch(listar());
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        fecha_inicial,
        fecha_final,
        sort,
        sucursal,
        tipo_movimiento,
        destino_salida,
    } = store.reporte_historial_baja_salida;

    let params = {
        fecha_inicial,
        fecha_final,
        tipo: tipo_movimiento,
        sucursal_rep: sucursal ? sucursal.id : "",
    };

    if (tipo_movimiento == 130) {
        params.destino_salida = destino_salida ? destino_salida.id : "";
    }
    dispatch(setEstadoDescarga(true));
    api.get(`bodegas/descargar_excel_bajas_salidas`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setSort = (sort) => ({
    type: SORT,
    sort,
});

export const _setSucursal = (sucursal) => ({
    type: SUCURSAL,
    sucursal,
});

export const setTipoMovimiento = (tipo_movimiento) => ({
    type: TIPO_MOVIMIENTO,
    tipo_movimiento,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const _setDestinoSalida = (destino_salida) => ({
    type: DESTINO_SALIDA,
    destino_salida,
});
const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const actions = {
    listar,
    setFecha,
    sortChange,
    setSucursal,
    descargarListado,
    setDestinoSalida,
    tipoMovimientoChange,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SORT]: (state, { sort }) => {
        return {
            ...state,
            sort,
        };
    },
    [SUCURSAL]: (state, { sucursal }) => {
        return {
            ...state,
            sucursal,
        };
    },
    [TIPO_MOVIMIENTO]: (state, { tipo_movimiento }) => {
        return {
            ...state,
            tipo_movimiento,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [DESTINO_SALIDA]: (state, { destino_salida }) => {
        return {
            ...state,
            destino_salida,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    loader: false,
    sort: "-creado",
    sucursal: null,
    tipo_movimiento: "",
    page: 1,
    estado_descarga: false,
};

export default handleActions(reducers, initialState);
