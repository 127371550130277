import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { RenderDateTime, RenderMoneda } from "Utils/renderField/renderTableField";

class ListadoRecepcionDespacho extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {
        this.props.listar(this.props.page);
    }

    render() {
        // state
        const { data, loader, search, tab } = this.props;

        // bind
        const { buscar, changeTab } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        return (
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Tabs className="content-tabs" selectedIndex={tab==10 ? 0:1} onSelect={tabIndex => changeTab(tabIndex == 0 ? 10 : 20 )}>
                        <TabList className="tab_list d-flex flex-row justify-content-between">
                            <Tab className="content-tab-venta flex1 text-center fnt-14" style={{marginBottom: "0px"}} selectedClassName="content-tab-venta-active">RECEPCIONES PENDIENTES</Tab>
                            <Tab className="content-tab-venta flex1 text-center fnt-14" style={{marginBottom: "0px"}} selectedClassName="content-tab-venta-active">RECEPCIONES COMPLETADAS</Tab>
                        </TabList>
                        {/* PANEL PENDIENTES */}
                        <TabPanel>
                            <div className="grid-container">
                                <div className="grid-titulo">
                                    <Toolbar titulo={"Listado"} buscar={buscar} search={search} />
                                </div>
                                <LoadMask loading={loader} dark blur>
                                    <BootstrapTable
                                        data={loader ? [] : data.results}
                                        remote pagination hover
                                        fetchInfo={{ dataTotalSize: data.count }}
                                        options={options}
                                    >
                                        <TableHeaderColumn dataField='sucursal__nombre' dataFormat={(cell, row) => <div>{row.sucursal.nombre}</div>} dataSort >Enviado desde</TableHeaderColumn>
                                        <TableHeaderColumn dataField='creado' isKey dataFormat={(cell) => <RenderDateTime fecha={cell} />} dataSort >Fecha</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField='id'
                                            dataAlign="center"
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <Link className="btn btn-secondary" to={`/recepciones_de_despachos/recibir/${cell}`}><em className="fa fa-check"></em> Recibir despacho</Link>
                                                )
                                            }}>
                                            Acciones
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </LoadMask>
                            </div>
                        </TabPanel>
                        {/* PANEL ACEPTADOS */}
                        <TabPanel>
                            <div className="grid-container">
                                <div className="grid-titulo">
                                    <Toolbar titulo={"Listado"} buscar={buscar} search={search} />
                                </div>
                                <LoadMask loading={loader} dark blur>
                                    <BootstrapTable
                                        data={loader ? [] : data.results}
                                        remote pagination hover
                                        fetchInfo={{ dataTotalSize: data.count }}
                                        options={options}
                                    >
                                        <TableHeaderColumn dataField='sucursal__nombre' dataFormat={(cell, row) => <div>{row.sucursal.nombre}</div>} dataSort >Enviado desde</TableHeaderColumn>
                                        <TableHeaderColumn dataField='fecha_recepcion' dataFormat={(cell) => <RenderDateTime fecha={cell} />} dataSort >Fecha recepción</TableHeaderColumn>
                                        <TableHeaderColumn
                                            isKey
                                            dataField='id'
                                            dataAlign="center"
                                            dataFormat={activeFormatter({ ver: "/recepciones_de_despachos/visualizacion" })}>
                                            Acciones
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </LoadMask>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        );
    }
}

ListadoRecepcionDespacho.propTypes = {

};

export default ListadoRecepcionDespacho;
