import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import Select, { Async } from "react-select";
import { api } from "api";
import DatePicker from "Utils/DatePicker";
import moment from "moment/moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { default as GraficaHistorialPrecios } from "../Grafica";
import { TablaHstorialPrecios } from "./ComponentesHistorialPrecios";

class HistorialPrecios extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    onChangeProducto = (producto) => {
        this.props.setProducto(producto);
    };

    onChangeSucursal = (sucursal) => {
        this.props.setSucursal(sucursal);
    };

    onChangePresentacion = (fraccion) => {
        this.props.setFraccion(fraccion);
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.producto !== this.props.producto) {
            const producto = nextProps.producto;
            if (producto) {
                const fraccion = producto.fracciones[0];
                this.props.setFraccion(fraccion);
                this.setState({ fraccion });
            }
        }
    }

    render() {
        const {
            loader,
            sucursal,
            producto,
            fraccion,
            data,
            fecha_inicial,
            fecha_final,
            tab,
            page,
            estado_descarga,
        } = this.props;

        // Bind
        const { changeTab, listar, sortChange, descargarListado } = this.props;

        const getSucursales = (search) => {
            return api
                .get("sucursales", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data.results };
                });
        };

        const getProductos = (search) => {
            return api
                .get("productos", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data.results };
                });
        };

        return (
            <div>
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="mb-1 mt-1">
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    <h3>
                                        <b>Reporte historial de precios</b>
                                    </h3>
                                    <div
                                        className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-end"
                                        style={{ flex: "3" }}
                                    >
                                        {" "}
                                        &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Fecha Inicio</label>
                                            <DatePicker
                                                value={fecha_inicial}
                                                maxDate={new Date()}
                                                onChange={(date) => {
                                                    this.props.setFecha(
                                                        "Inicial",
                                                        date
                                                    );
                                                }}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Fecha Fin</label>
                                            <DatePicker
                                                value={fecha_final}
                                                minDate={
                                                    new Date(
                                                        moment(
                                                            fecha_inicial,
                                                            "YYYY-MM-DD"
                                                        )
                                                    )
                                                }
                                                onChange={(date) => {
                                                    this.props.setFecha(
                                                        "Final",
                                                        date
                                                    );
                                                }}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Sucursal</label>
                                            <Async
                                                className="form-control"
                                                placeholder="Seleccionar sucursal"
                                                onChange={this.onChangeSucursal}
                                                searchPromptText="Escriba para buscar"
                                                valueKey={"id"}
                                                labelKey={"nombre"}
                                                loadOptions={getSucursales}
                                                value={this.props.sucursal}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Producto</label>
                                            <Async
                                                className="form-control"
                                                labelKey="nombre"
                                                valueKey="id"
                                                searchPromptText="Escriba para buscar"
                                                placeholder={
                                                    "Seleccionar producto"
                                                }
                                                loadOptions={getProductos}
                                                onChange={this.onChangeProducto}
                                                value={this.props.producto}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        {producto ? (
                                            <div style={{ flex: "0.2" }}>
                                                <label>Presentacion</label>
                                                <Select
                                                    labelKey="nombre"
                                                    valueKey="id"
                                                    searchPromptText="Escriba para buscar"
                                                    placeholder="Seleccionar presentación"
                                                    className="form-control"
                                                    options={
                                                        producto.fracciones
                                                    }
                                                    value={fraccion}
                                                    onChange={
                                                        this
                                                            .onChangePresentacion
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div style={{ flex: "0.2" }}>
                                                <label>Presentacion</label>

                                                <Select
                                                    labelKey="nombre"
                                                    valueKey="id"
                                                    placeholder="Debe seleccionar producto"
                                                    className="form-control"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Nombre del producto</b>
                                            {producto ? producto.nombre : ""}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Marca</b>
                                            {producto ? producto.marca : ""}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Presentacion</b>
                                            {fraccion && producto
                                                ? fraccion.nombre
                                                : ""}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-3 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Código de barras</b>
                                            {fraccion && producto
                                                ? fraccion.codigo_barras
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                <GraficaHistorialPrecios />
                            </div>
                        </div>
                    </div>
                </div>
                {sucursal && producto && fraccion ? (
                    <div className="row mb-2">
                        <div className="col-12">
                            <div className="grid-container">
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    {/* <div style={{flex: "1"}}>
                                        <h3><b>Listado</b></h3>
                                    </div> */}
                                    <LoadMask loading={loader} light blur>
                                        <Tabs
                                            className="mt-2 content-tabs"
                                            selectedIndex={tab == 30 ? 0 : 1}
                                            onSelect={(tabIndex) =>
                                                changeTab(
                                                    tabIndex == 0 ? 30 : 20
                                                )
                                            }
                                        >
                                            <TabList className="tab_list d-flex flex-row justify-content-between">
                                                <Tab
                                                    className="content-tab-venta flex1 text-center fnt-14"
                                                    style={{
                                                        marginBottom: "0px",
                                                    }}
                                                    selectedClassName="content-tab-venta-active"
                                                >
                                                    LISTADO VENTAS
                                                </Tab>
                                                <Tab
                                                    className="content-tab-venta flex1 text-center fnt-14"
                                                    style={{
                                                        marginBottom: "0px",
                                                    }}
                                                    selectedClassName="content-tab-venta-active"
                                                >
                                                    LISTADO COMPRAS
                                                </Tab>
                                            </TabList>

                                            <TabPanel>
                                                <TablaHstorialPrecios
                                                    data={data}
                                                    loader={loader}
                                                    tipo={tab}
                                                    page={page}
                                                    estado_descarga={
                                                        estado_descarga
                                                    }
                                                    listar={listar}
                                                    sortChange={sortChange}
                                                    descargarListado={
                                                        descargarListado
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <TablaHstorialPrecios
                                                    data={data}
                                                    loader={loader}
                                                    tipo={tab}
                                                    page={page}
                                                    estado_descarga={
                                                        estado_descarga
                                                    }
                                                    listar={listar}
                                                    sortChange={sortChange}
                                                    descargarListado={
                                                        descargarListado
                                                    }
                                                />
                                            </TabPanel>
                                        </Tabs>
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default HistorialPrecios;
