import moment from "moment";
import Select from "react-select";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "Utils/DatePicker";
import LoadMask from "Utils/LoadMask/LoadMask";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
    RenderPorcentaje,
} from "Utils/renderField/renderTableField";

export default class ListadoDescuentoRecargo extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        fecha_inicial: PropTypes.string.isRequired,
        fecha_final: PropTypes.string.isRequired,
    };

    componentWillMount() {
        this.props.listar();
    }

    render() {
        //  State
        const {
            loader,
            page,
            data,
            fecha_inicial,
            fecha_final,
            tipo,
            estado_descarga,
        } = this.props;
        //  Bind
        const { listar, setFecha, tipoChange, descargarListado, sortChange } = this.props;

        //  Const
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        options.onSortChange = sortChange

        const tipos = [
            { value: "", label: "Todos" },
            { value: "descuento", label: "Descuento" },
            { value: "recargo", label: "Recargo" },
        ];

        return (
            <div>
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div style={{ flex: "1" }}>
                                    <h3>
                                        <b>Descuentos y recargos</b>
                                    </h3>
                                </div>
                                {/* FILTROS */}
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3 col-lg-2">
                                    <label>Fecha Inicio</label>
                                    <DatePicker
                                        value={fecha_inicial}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            setFecha("Inicial", date);
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-md-3 col-lg-2">
                                    <label>Fecha Fin</label>
                                    <DatePicker
                                        value={fecha_final}
                                        minDate={
                                            new Date(
                                                moment(
                                                    fecha_inicial,
                                                    "YYYY-MM-DD"
                                                )
                                            )
                                        }
                                        onChange={(date) => {
                                            setFecha("Final", date);
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-md-3 col-lg-2">
                                    <label htmlFor="tipo">Tipo</label>
                                    <div>
                                        <Select
                                            isClearable
                                            placeholder="Seleccionar"
                                            className="form-control"
                                            options={tipos}
                                            valueKey="value"
                                            labelKey="label"
                                            value={tipo}
                                            onChange={(e) => {
                                                tipoChange(e ? e.value : "");
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <LoadMask loading={loader} light blur>
                                <div className="d-flex flex-row content-total-reports w-100 mt-10">
                                    <button
                                        className="ml-3 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em
                                            className={`fa ${
                                                estado_descarga
                                                    ? "fa-spinner fa-pulse"
                                                    : "fa-download"
                                            }`}
                                        ></em>
                                        &nbsp;Descargar Listado
                                    </button>
                                    <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-end w-100 mt-10">
                                        <h3 className="padding-10 margin-0">
                                            <b>
                                                Total
                                                <RenderCurrency
                                                    className="ml-3 "
                                                    value={
                                                        data.total_descuento_recargo
                                                            ? data.total_descuento_recargo
                                                            : 0
                                                    }
                                                />
                                                <span className="mx-3">-</span>
                                                <RenderPorcentaje
                                                    className=""
                                                    monto={
                                                        data.total_porcentaje_descuento_recargo
                                                            ? data.total_porcentaje_descuento_recargo
                                                            : 0
                                                    }
                                                    decimalScale={2}
                                                />
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{
                                        dataTotalSize: data.count,
                                    }}
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataSort
                                        isKey
                                        dataField="id"
                                        width="0%"
                                    >
                                        Id
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="creado"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderDateTime fecha={cell} />
                                            );
                                        }}
                                    >
                                        Fecha
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="cliente_proveedor__nombre"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <span>
                                                    {row.cliente_proveedor
                                                        ? row.cliente_proveedor
                                                              .nombre
                                                        : ""}
                                                </span>
                                            );
                                        }}
                                    >
                                        Cliente
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="codigo_venta"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <span>{row.codigo_venta}</span>
                                            );
                                        }}
                                    >
                                        Código venta
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="total_orginal"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda monto={cell} />
                                            );
                                        }}
                                    >
                                        Monto venta
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="total_cobrar_pagar"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda monto={cell} />
                                            );
                                        }}
                                    >
                                        Monto Final
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="descuento_recargo"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda monto={cell} />
                                            );
                                        }}
                                    >
                                        Diferencia
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        width="80px"
                                        dataField="porcentaje_descuento_recargo"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderPorcentaje
                                                    monto={cell}
                                                    decimalScale={2}
                                                />
                                            );
                                        }}
                                    ></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        width="80px"
                                        dataAlign="center"
                                        dataFormat={(cell, row) => {
                                            return activeFormatter({
                                                ver:
                                                    "/reporte_descuentos_y_recargos",
                                                id_ver: row.id_venta,
                                            })(cell, row);
                                        }}
                                    >
                                        Acciones
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
