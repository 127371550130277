import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes/cuenta_cobrar";
import ListadoCuentaCobrar from "./ListadoCuentaCobrar";


const ms2p = (state) => {
    let total = 0;
    const { data } = state.reporte_cuenta_cobrar;
    if (data.results) {
        data.results.forEach(item => {
            total += item.saldo;
        });
    }
    return {
        ...state.reporte_cuenta_cobrar,
        marcar_entrega: state.usuario.me ? !!state.usuario.me.marcar_entrega : false,
        total
        // factura_personalizada: state.usuario.factura_personalizada,
        // formato_factura: state.usuario.formato_factura,
        // datos_venta
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoCuentaCobrar);
