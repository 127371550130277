import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { icons } from "icons";
import _ from "lodash";
import LoadMask from "Utils/LoadMask/LoadMask";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { PrintContainer, actions as printActions } from "Utils/Print";
import { opcionesDevolucion, opcionesReingreso } from "utility/variables";

class VistaPrevia extends Component {
    static propTypes = {
        elegidos: PropTypes.array.isRequired,
        empresa: PropTypes.string.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {}

    getMotivo(motivo) {
        return _.find(opcionesDevolucion, { value: parseFloat(motivo) }).label;
    }

    render() {
        const {
            elegidos,
            empresa,
            loader,
            adicionales_seleccionados,
        } = this.props;
        let total = 0;
        elegidos.forEach((elegido) => {
            const cantidad = elegido.cantidad
                ? elegido.producto.a_granel
                    ? parseFloat(elegido.cantidad)
                    : parseInt(elegido.cantidad)
                : 0;
            if (elegido.precio_calculado) {
                total += elegido.precio_calculado * cantidad;
            } else {
                total += elegido.precio * cantidad;
            }
        });
        adicionales_seleccionados.forEach((adicional) => {
            total += adicional.precio;
        });
        if (elegidos.length === 0) {
            return (
                <Redirect
                    to={`/devolucion/seleccion_de_producto/${this.props.match.params.id}`}
                />
            );
        }

        return (
            <div className="col-12">
                <div className="grid-container p-1 p-sm-3 p-md-5 d-flex flex-column text-center">
                    <h3 className="mb-3">
                        <strong>
                            ¿Deseas finalizar el proceso y generar el voucher?
                        </strong>
                    </h3>
                    <div className="d-flex flex-row justify-content-center mt-3 mb-4">
                        <Link
                            to={`/devolucion/seleccion_de_producto/${this.props.match.params.id}/motivos`}
                            className="btn btn-tertiary m-1 align-self-center"
                        >
                            <em className="fa fa-arrow-left" /> Atrás
                        </Link>
                        <button
                            disabled={loader}
                            className="btn btn-primary m-1 align-self-center"
                            onClick={() => {
                                this.props.generarVale(
                                    this.props.match.params.id
                                );
                            }}
                        >
                            <i className="fa fa-check" /> Confirmar
                        </button>
                    </div>
                    <div
                        className="print-shadow mr-auto ml-auto"
                        style={{ fontSize: "80%" }}
                    >
                        <div className="d-flex flex-column">
                            <div className="border-dotted ml-5 mr-5 mt-2 mb-5 text-center">
                                <h2 className="m-2">
                                    <strong>XXXX</strong>
                                </h2>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <strong className="mr-2">
                                    VALE DE DEVOLUCIÓN POR
                                </strong>
                                <RenderCurrency
                                    value={total}
                                    className={"font-weight-bold"}
                                />
                            </div>
                            <div className="d-flex flex-row justify-content-between mt-2 mb-2">
                                <div>PRODUCTO</div>
                                <div>TOTAL</div>
                            </div>
                            <div>
                                {elegidos.map((elegido) => {
                                    return (
                                        <div
                                            className="d-flex flex-row justify-content-between mt-2 mb-2"
                                            key={elegido.key}
                                        >
                                            <div className="d-flex flex-column align-items-start">
                                                <div className="text-left">
                                                    {elegido.codigo_barras
                                                        ? `${elegido.codigo_barras} / `
                                                        : ""}
                                                    {elegido.producto.nombre} /{" "}
                                                    {elegido.producto.a_granel
                                                        ? ""
                                                        : elegido.nombre}
                                                </div>
                                                <small>
                                                    {this.getMotivo(
                                                        elegido.motivo
                                                    )}
                                                </small>
                                                {elegido.porcentaje_precio <
                                                100 ? (
                                                    <small className="text-left">
                                                        Precio (
                                                        {
                                                            elegido.porcentaje_precio
                                                        }
                                                        {"% de "}
                                                        <RenderCurrency
                                                            value={
                                                                elegido.precio
                                                            }
                                                        />
                                                        )
                                                        <br />
                                                        <RenderCurrency
                                                            value={
                                                                elegido.precio_calculado
                                                            }
                                                        />{" "}
                                                        X {elegido.cantidad}
                                                    </small>
                                                ) : (
                                                    <small className="text-left">
                                                        <RenderCurrency
                                                            value={
                                                                elegido.precio
                                                            }
                                                        />{" "}
                                                        X {elegido.cantidad}
                                                    </small>
                                                )}
                                            </div>
                                            <RenderCurrency
                                                className={"ml-3"}
                                                value={
                                                    elegido.precio_calculado *
                                                    elegido.cantidad
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                {adicionales_seleccionados.map(
                                    (adicional, index) => {
                                        return (
                                            <div
                                                className="d-flex flex-row justify-content-between mt-2 mb-2"
                                                key={index}
                                            >
                                                <div className="d-flex flex-column align-items-start">
                                                    <div className="text-left">
                                                        {adicional.nombre}
                                                    </div>
                                                    {adicional.tipo == 20 && (
                                                        <small>
                                                            {adicional.valor}%
                                                            sobre el total de la
                                                            factura de la venta
                                                        </small>
                                                    )}
                                                </div>
                                                <RenderCurrency
                                                    className={"ml-3"}
                                                    value={adicional.precio}
                                                />
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <div
                            className="w-100 mb-2"
                            style={{ borderBottom: "dotted 1px" }}
                        />
                        <div className="d-flex flex-row justify-content-between">
                            <div>Vale por devolución</div>
                            <RenderCurrency value={total} />
                        </div>
                        <br />
                        <br />
                        <div className="text-center">{empresa}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VistaPrevia;
