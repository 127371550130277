import React from 'react'
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form'
import { validate, validators, optional } from 'validate-redux-form';
import { renderNumber } from "Utils/renderField/renderField";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { icons } from "icons";

const Form = props => {
    const { handleSubmit, total, pais, actionAyuda } = props;
    return (
        <form onSubmit={handleSubmit} className="row">
            <div className="col-sm-12 apertura-cierre">
                <div className="form-group grid-container">
                    <div style={{ position: "absolute", top: "25px", right: "25px" }}>
                        <button type="button" className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center" style={{ width: "2rem", height: "2rem" }} onClick={actionAyuda}>
                            <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{ margin: "0", height: "1.1rem" }} />
                        </button>
                    </div>
                    <div className="grid-titulo padding-15">Apertura Caja</div>
                    <div className=" padding-15">
                        <div className="row">
                            <div className="col-12 text-lg-right text-md-right text-sm-center apertura-cierre-titulo">Total en efectivo <div><RenderCurrency value={total} className={"apertura-cierre-total"}/></div></div>
                        </div>
                        <div className="row">
                            {
                                pais == "MX" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.billete_1000_mx} alt="" /></div>
                                        <label htmlFor="b1000">Total de billetes</label>
                                        <Field name="b1000" component={renderNumber} type="number" className="form-control" />
                                    </div>
                                )
                            }
                            {
                                pais == "MX" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.billete_500_mx} alt="" /></div>
                                        <label htmlFor="b500">Total de billetes</label>
                                        <Field name="b500" component={renderNumber} type="number" className="form-control" />
                                    </div>
                                )
                            }
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    {pais == "GT" ?
                                        <img className="dinero" src={icons.billete_200} alt="" />
                                        : <img className="dinero" src={icons.billete_200_mx} alt="" />
                                    }
                                </div>
                                <label htmlFor="b200">Total de billetes</label>
                                <Field name="b200" component={renderNumber} type="number" className="form-control" />
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    { pais=="GT" ?
                                        <img className="dinero" src={icons.billete_100} alt=""/>
                                        :<img className="dinero" src={icons.billete_100_mx} alt=""/>
                                    }
                                </div>
                                <label htmlFor="b100">Total de billetes</label>
                                <Field name="b100" component={renderNumber} type="number" className="form-control"/>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    { pais=="GT" ?
                                        <img className="dinero" src={icons.billete_50} alt=""/>
                                        :<img className="dinero" src={icons.billete_50_mx} alt=""/>
                                    }
                                </div>
                                <label htmlFor="b50">Total de billetes</label>
                                <Field name="b50" component={renderNumber} type="number" className="form-control"/>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    { pais=="GT" ?
                                        <img className="dinero" src={icons.billete_20} alt=""/>
                                        :<img className="dinero" src={icons.billete_20_mx} alt=""/>
                                    }
                                </div>
                                <label htmlFor="b20">Total de billetes</label>
                                <Field name="b20" component={renderNumber} type="number" className="form-control"/>
                            </div>
                            {
                                pais=="GT" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.billete_10} alt=""/></div>
                                        <label htmlFor="b10">Total de billetes</label>
                                        <Field name="b10" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                            {
                                pais=="GT" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.billete_5} alt=""/></div>
                                        <label htmlFor="b5">Total de billetes</label>
                                        <Field name="b5" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                            {
                                pais=="GT" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.billete_1} alt=""/></div>
                                        <label htmlFor="b1">Total de billetes</label>
                                        <Field name="b1" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                            {
                                pais=="MX" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.moneda_2000_mx} alt=""/></div>
                                        <label htmlFor="m2000">Total de Monedas</label>
                                        <Field name="m2000" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                            {
                                pais=="MX" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.moneda_1000_mx} alt=""/></div>
                                        <label htmlFor="m1000">Total de Monedas</label>
                                        <Field name="m1000" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                            {
                                pais=="MX" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.moneda_500_mx} alt=""/></div>
                                        <label htmlFor="m500">Total de Monedas</label>
                                        <Field name="m500" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                            {
                                pais=="MX" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.moneda_200_mx} alt=""/></div>
                                        <label htmlFor="m200">Total de Monedas</label>
                                        <Field name="m200" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    { pais=="GT" ?
                                        <img className="dinero" src={icons.moneda_100} alt=""/>
                                        :<img className="dinero" src={icons.moneda_100_mx} alt=""/>
                                    }
                                </div>
                                <label htmlFor="m100">Total de Monedas</label>
                                <Field name="m100" component={renderNumber} type="number" className="form-control"/>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    { pais=="GT" ?
                                        <img className="dinero" src={icons.moneda_50} alt=""/>
                                        :<img className="dinero" src={icons.moneda_50_mx} alt=""/>
                                    }
                                </div>
                                <label htmlFor="m50">Total de Monedas</label>
                                <Field name="m50" component={renderNumber} type="number" className="form-control"/>
                            </div>
                            {
                                pais=="GT" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.moneda_25} alt=""/></div>
                                        <label htmlFor="m25">Total de Monedas</label>
                                        <Field name="m25" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                            {
                                pais=="MX" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.moneda_20_mx} alt=""/></div>
                                        <label htmlFor="m20">Total de Monedas</label>
                                        <Field name="m20" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                <div className="text-center">
                                    { pais=="GT" ?
                                        <img className="dinero" src={icons.moneda_10} alt=""/>
                                        : <img className="dinero" src={icons.moneda_10_mx} alt=""/>
                                    }
                                </div>
                                <label htmlFor="m10">Total de Monedas</label>
                                <Field name="m10" component={renderNumber} type="number" className="form-control"/>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                <div className="text-center">
                                    { pais=="GT" ?
                                        <img className="dinero" src={icons.moneda_5} alt=""/>
                                        :<img className="dinero" src={icons.moneda_5_mx} alt=""/>
                                    }
                                </div>
                                <label htmlFor="m5">Total de Monedas</label>
                                <Field name="m5" component={renderNumber} type="number" className="form-control"/>
                            </div>
                            {
                                pais=="GT" && (
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 form-group">
                                        <div className="text-center"><img className="dinero" src={icons.moneda_1} alt=""/></div>
                                        <label htmlFor="m1">Total de Monedas</label>
                                        <Field name="m1" component={renderNumber} type="number" className="form-control"/>
                                    </div>
                                )
                            }
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-6 text-center col-xs-6 text-lg-right text-md-right text-sm-center">
                                <Link className="btn btn-tertiary" to={"/abrir_caja"}><img src={icons.cancelar} alt=""/>Cancelar</Link>
                            </div>
                            <div className="col-sm-6 text-center col-xs-6 text-lg-left text-md-left text-sm-center">
                                <button type="submit" className="btn btn-primary"><img src={icons.save} alt=""/>Realizar Apertura</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

const AperturaForm = reduxForm({
    // a unique name for the form
    form: 'CajaApertura',
    validate: data => {
        return validate(data, {
        })
    }
})(Form);

export default AperturaForm
