import { connect } from 'react-redux';
import ImpresionProducto from './ImpresionProducto';
import { listaProductosImpresion } from "../../../../redux/modules/bodega/bodega";
import { print } from '../../../../redux/modules/print/print'


const ms2p = (state) => {
    return {
        loader: state.bodega.loader,
        listado_pagina: state.bodega.paginas_impresion.listado_pagina,
        paginas: state.bodega.paginas_impresion.paginas,
        usuario: state.usuario.me.first_name,
        sucursales: state.usuario.me.sucursales,
        id_sucursal: state.usuario.sucursal,
        print_state: state.print   }
}

const md2p = {
    listaProductosImpresion: () => dispatch => dispatch(listaProductosImpresion()),
    print: (name) => dispatch => dispatch(print(name)),
}

// const md2p = { ...actions };

export default connect(ms2p, md2p)(ImpresionProducto);
