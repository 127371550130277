import _ from "lodash";
import React from "react";
import { api } from "api";
import { icons } from "icons";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { ListadoAccion } from "Utils/ListadoAccion";
import { DetalleAccion } from "Utils/DetalleAccion";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import {
    renderField,
    renderTextArea,
    renderCurrency,
    renderSearchSelect,
    renderDatePicker,
} from "Utils/renderField/renderField";
import {
    formatoNombre,
    formatoExistencia,
    formatoAcciones,
    buscar,
    formatoProducto,
} from "Utils/CamposComunes";

import {
    formatoCantidad,
    formatoCosto,
    formatoSubtotal,
} from "./formatoCamposOC";
export const listaProveedores = [];

// VALIDACIONES CUSTOMS
const validate = (values) => {
    const errors = {};

    if (!values.proveedor) errors.proveedor = "Campo requerido";
    if (!values.fecha) errors.fecha = "Campo requerido";
    if (!values.descripcion) errors.descripcion = "Campo requerido";

    return errors;
};
const Form = (props) => {
    // State
    const {
        ver_buscador,
        loader,
        productos,
        page,
        seleccionados,
        total,
    } = props;
    // Bind
    const {
        handleSubmit,
        listar,
        sortChange,
        setVerBuscador,
        asignarDatos,
        limpiarDatosOC,
        quitar,
    } = props;

    const llavesListado = [
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },
        {
            nombre: "inventario",
            sort: false,
            caption: "Existencias",
            format: formatoExistencia,
            align: "right",
            width: "100px",
        },
        {
            nombre: "acciones",
            sort: false,
            caption: "Agregar",
            format: formatoAcciones({
                ...props,
                cambio_buscador: setVerBuscador,
            }),
            align: "center",
            width: "90px",
        },
    ];

    const llavesDetalle = [
        { caption: "Producto", nombre: "producto", format: formatoProducto },
        {
            caption: "Cantidad",
            nombre: "cantidad",
            format: formatoCantidad(props),
        },
        {
            caption: "Costo (Unidad)",
            nombre: "costo",
            format: formatoCosto(props),
        },
        {
            caption: "Subtotal",
            nombre: "subtotal",
            format: formatoSubtotal,
            align: "right",
        },
    ];

    const goBack = () => {
        props.history.goBack();
    };

    // Request Proveedores
    const getProveedores = (search) => {
        return api
            .get("proveedores", { search })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaProveedores, { id: item.id }))
                        listaProveedores.push(item);
                });
                return { options: listaProveedores };
            });
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group grid-container">
                        <div className="grid-titulo padding-15">
                            Nueva orden de compra
                        </div>
                        <div className="pl-lg-5 pr-lg-5 pt-lg-2 pb-lg-2 p-0 pt-1 pb-1">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="proveedor">Proveedor</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field
                                                name="proveedor"
                                                component={renderSearchSelect}
                                                loadOptions={getProveedores}
                                                labelKey="nombre"
                                                valueKey="id"
                                                className="form-control"
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.abrirProveedor();
                                            }}
                                            className="mt-3 mt-lg-0 btn btn-secondary ml-0 mr-0 ml-md-3 mr-md-3"
                                            style={{ height: "31px" }}
                                        >
                                            <i className="fa fa-plus" /> Crear
                                            Proveedor
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="fecha">Fecha</label>
                                    <div className="d-flex flex-column pr-0">
                                        <Field
                                            name="fecha"
                                            component={renderDatePicker}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="num_documento">
                                        Documento
                                    </label>
                                    <div className="d-flex flex-column pr-0">
                                        <Field
                                            name="num_documento"
                                            component={renderField}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="descripcion">Nota</label>
                                    <div className="d-flex flex-column pr-0">
                                        <Field
                                            name="descripcion"
                                            rows={2}
                                            component={renderTextArea}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container row" style={{ margin: "0" }}>
                <div
                    className={`col-lg-6 col-md-12 pr-md-0 ${
                        ver_buscador ? "form-activo" : ""
                    } slide `}
                >
                    <ListadoAccion
                        buscar={buscar(props)}
                        loader={loader}
                        llaves={llavesListado}
                        data={productos}
                        listar={listar}
                        sortChange={sortChange}
                        titulo={
                            <div className="d-flex flex-column flex-lg-row m-2">
                                <span
                                    style={{ fontSize: "15px" }}
                                    className="align-self-end"
                                >
                                    Listado de Productos
                                </span>
                                <Link
                                    className="btn btn-primary align-self-center ml-auto"
                                    to={{
                                        pathname: "/productos/crear",
                                        state: {
                                            desdeRuta:
                                                "/ordenes_de_compra/nueva",
                                        },
                                    }}
                                    onClick={asignarDatos}
                                >
                                    <i className="fa fa-plus" /> Agregar
                                    Producto
                                </Link>
                            </div>
                        }
                        page={page}
                    />
                </div>
                <div
                    className={`col-lg-6 col-md-12 ${
                        !ver_buscador ? "form-activo" : ""
                    } form-trasero`}
                >
                    <DetalleAccion
                        data={seleccionados}
                        titulo={"Lista de Productos"}
                        llaves={llavesDetalle}
                        loader={loader}
                        agregar={setVerBuscador}
                        quitar={quitar}
                    />
                    <div
                        style={{
                            borderRadius: "0",
                            padding: "4px 2px 2px 2px",
                            borderTop: "none",
                        }}
                        className="grid-container bg-primary mt0 d-flex flex-column flex-md-row justify-content-between"
                    >
                        <div
                            className="d-flex justify-content-around align-items-end p-1 p-md-2 flex-column flex-1"
                            style={{ backgroundColor: "#FFF" }}
                        >
                            <div className="text-right">
                                <div>Monto total</div>
                                <h5>
                                    <b>
                                        <RenderCurrency value={total} />
                                    </b>
                                </h5>
                            </div>
                        </div>
                        <div
                            className="d-flex justify-content-around align-items-center p-1 p-md-2 flex-column flex-3"
                            style={{ backgroundColor: "#FFF" }}
                        >
                            <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                <button
                                    type="button"
                                    className="btn btn-tertiary mr-1"
                                    onClick={() => {
                                        limpiarDatosOC();
                                        goBack();
                                    }}
                                >
                                    {" "}
                                    <img src={icons.cancelar} alt="" /> Cancelar
                                </button>
                                <button
                                    className="btn btn-primary ml-1"
                                    type="submit"
                                >
                                    <i className="fa fa-check mr" />
                                    Siguiente
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const FormNuevaOC = reduxForm({
    form: "NuevaOrdenCompra",
    validate,
})(Form);

export default FormNuevaOC;
