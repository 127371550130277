export const tipo_movimientos = {
    INGRESO: 10,
    VENTA: 30,
    COMPRA: 20,
    RETIRO: 40,
    BAJA: 50,
    REAJUSTE: 60,
    DEVOLUCION: 70,
    ABONO_CUENTA: 80,
};

export const niveles_usuario = {
    PROPIETARIO: 100,
    ADMIN: 75,
    BODEGUERO: 50,
    CAJERO: 25,
};

export const opcionesDevolucion = [
    { label: "El producto tenía defectos", value: 10 },
    { label: "Garantía", value: 30 },
    { label: "No cumple con expectativas del cliente", value: 20 },
    { label: "No coincide con la publicidad o empaque", value: 40 },
];

export const opcionesReingreso = [
    { label: "Sí regresa", value: true },
    { label: "No regresa", value: false },
];

// LA DESCRIPCIÓN VA AQUI PORQUE NO LA NECESITAMOS EN BACKEND
// TODO poner una descripcion apropiada
export const acciones_bitacora = [
    { descripcion: "Login", label: "Login" },
    { descripcion: "Crear producto", label: "Crear producto" },
    { descripcion: "Editar producto", label: "Editar producto" },
    { descripcion: "Eliminar producto", label: "Eliminar producto" },
    { descripcion: "Crear usuario", label: "Crear usuario" },
    { descripcion: "Editar usuario", label: "Editar usuario" },
    { descripcion: "Eliminar usuario", label: "Eliminar usuario" },
    { descripcion: "Crear caja", label: "Crear caja" },
    { descripcion: "Editar caja", label: "Editar caja" },
    { descripcion: "Eliminar caja", label: "Eliminar caja" },
    { descripcion: "Anular venta", label: "Anular venta" },
    {
        descripcion: "Devolucion de productos",
        label: "Devolucion de productos",
    },
    {
        descripcion: "Movimiento del inventario",
        label: "Movimiento del inventario",
    },
    { descripcion: "Ingreso de productos", label: "Ingreso de productos" },
];

//  TIPOS DE PAGO
export const pago_total = 100;
export const pago_credito = 200;

//  TIPOS DE UNIDAD DE MEDIDA
export const UNIDAD = 10;
export const CAPACIDAD = 20;
export const PESO = 30;
export const LONGITUD = 40;
export const AREA = 50;
export const VOLUMEN = 60;
export const OTROS = 100;

//  ESTADOS DE FACTURA
export const PROCESO = 10;
export const FACTURADO = 20;
export const ANULADA = 30;
export const ERROR = 40;

// ESTADOS DESPACHO
export const DESPACHO_PENDIENTE = 10;
export const DESPACHO_COMPLETO = 20;

// TIPO PUNTO DE VENTA
export const PVN = "PVN"; // Normal
export const PVMCE = "PVMCE"; // Mostrador-Caja-Entrega

// TIPO METODOS
export const METODO_PAGO = 10;
export const METODO_ENTREGA = 20;
