import { connect } from "react-redux";
import DestinoEditar from "./DestinoEditar";
import {
    leer,
    editar,
} from "../../../../../redux/modules/destino_salida_producto/destino_salida_producto";

const mapStateToProps = (state) => ({
    loader: state.destino_salida_producto.loader,
});

const mapDispatchToProps = {
    leer,
    editar,
};

export default connect(mapStateToProps, mapDispatchToProps)(DestinoEditar);
