import React, { Component } from "react";
import PropTypes from "prop-types";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import LoadMask from "Utils/LoadMask/LoadMask";

export class DetalleAccion extends Component {
    static propTypes = {
        titulo: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        llaves: PropTypes.array.isRequired,
        loader: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const {
            titulo,
            data,
            loader,
            total,
            llaves,
            borderBottom,
        } = this.props;

        // bind
        const { agregar, quitar } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader
            ? "Cargando..."
            : "Seleccione un producto para agregar al listado";

        return (
            <div
                className="grid-container mt0 d-flex justify-content-around flex-column "
                style={{ borderBottom: "none" }}
            >
                <div
                    className="grid-titulo padding-5 backgr-naranja-degradado"
                    style={{
                        borderBottom: "none",
                    }}
                >
                    <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                        <div style={{ flex: "3.5", fontSize: "15px" }}>
                            {titulo}
                        </div>
                        {agregar && (
                            <button
                                className="d-lg-none d-xl-none btn btn-outline-primary mb-1 fnt-sm-10"
                                style={{ flex: "0.5", margin: "0 5px 0 10px " }}
                                type="button"
                                onClick={agregar}
                            >
                                {" "}
                                <i className="fa fa-plus"></i> Agregar
                            </button>
                        )}
                    </div>
                </div>
                <div
                    className="r-encajar backgr-naranja-degradado"
                    style={{ padding: "0 2px" }}
                >
                    <LoadMask loading={loader} light blur radius>
                        <BootstrapTable
                            data={loader ? [] : data}
                            hover
                            options={options}
                            containerStyle={{ backgroundColor: "#FFF" }}
                            bodyStyle={{ minHeight: "200px" }}
                        >
                            <TableHeaderColumn width="0%" isKey dataField="id">
                                ID
                            </TableHeaderColumn>

                            {llaves.map((llave) => {
                                return (
                                    <TableHeaderColumn
                                        key={llave.nombre}
                                        width={llave.width}
                                        dataField={llave.nombre}
                                        dataFormat={llave.format}
                                        dataSort={llave.sort}
                                        dataAlign={llave.align}
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        {llave.caption}
                                    </TableHeaderColumn>
                                );
                            })}

                            {quitar && (
                                <TableHeaderColumn
                                    width="5%"
                                    dataField="id"
                                    dataFormat={(cell, row, data, index) => {
                                        return (
                                            <button
                                                title="Eliminar de la lista"
                                                className="btn-delete fnt-18"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    quitar(row, index);
                                                }}
                                            >
                                                <em className="fa fa-times" />
                                            </button>
                                        );
                                    }}
                                ></TableHeaderColumn>
                            )}
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        );
    }
}
