import React, { Component } from 'react';
import { icons } from "icons";
import PerfilForm from "./PerfilForm"
import CambioContraForm from "./CambioContraForm"

class Perfil extends Component {
    constructor(props) {
        super(props);
        this.state = { editar:false, cambio_perfil: false, cambio_contrasena:false };
        this.onFileChange = this.onFileChange.bind(this);
        this.editarPerfil = this.editarPerfil.bind(this);
    }

    onFileChange(e, file, key) {
        file = file || e.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        if (file) {
            if (!file.type.match(pattern)) {
                alert('Formato inválido');
                return;
            }
            // this.setState({ loaded: false });
            reader.onload = (e) => {
                this.setState({ [key]: file });
            };
            reader.readAsDataURL(file);
        }

    }
    componentWillMount() {
        // this.props.leer(this.props.match.params.id);
    }

    cambioEdicion = () => {
        this.props.editarPerfil();
        this.setState({editar: !this.state.editar, cambio_perfil: !this.state.cambio_perfil});
    }

    cambioEdicionContra = () => {
        // this.props.editarPerfil();
        this.setState({editar: !this.state.editar,cambio_contrasena: !this.state.cambio_contrasena});
    }

    editarPerfil() {
        const fotos = [
            { name: 'foto', file: this.state.foto_perfil }
        ];
        this.props.guardar(fotos, this.cambioEdicion);
    }
    cambioContrasena =  () => {
        // console.log("CAMBIO CONTRA")
        this.props.actualizarContrasena(this.cambioEdicionContra);
    }


    render() {
        // State
        const { editar, cambio_contrasena, cambio_perfil } = this.state;
        const { usuario } = this.props;
        // Bind
        // const { editarPerfil } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="grid-container padding-15">
                        <div className="grid-titulo padding-15">Perfil</div>
                        {
                            editar ? (
                                <div className="grid-cuerpo padding-15">
                                    {
                                        cambio_perfil ? (
                                            <div className="row">
                                                <PerfilForm usuario={usuario} onFileChange={this.onFileChange} editarPerfil={this.editarPerfil} cambioEdicion={this.cambioEdicion}/>
                                            </div>
                                        ): null
                                    }
                                    {
                                        cambio_contrasena ? (
                                            <CambioContraForm cambioEdicionContra={this.cambioEdicionContra} cambioContrasena={this.cambioContrasena}/>
                                        ):null
                                    }

                                </div>
                            )
                            : (
                                <div className="grid-cuerpo padding-15">
                                    {/* IMAGEN */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12 content-drop-zone">
                                            {
                                                usuario.foto_cropped ? (
                                                    <img src={usuario.foto_cropped} width="200" height="200" />
                                                )
                                                : (
                                                    <img src={icons.usuario} width="80" height="80" />
                                                )
                                            }
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">Nombre</label>
                                                    <div className="input-view">
                                                        {usuario.first_name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">Correo</label>
                                                    <div className="input-view">
                                                        {usuario.email}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">Telefono</label>
                                                    <div className="input-view">

                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-center flex-row mt-10">
                                            <button type="button" className="btn btn-primary m-1 align-self-center" onClick={() => {
                                                    this.cambioEdicion();
                                            }}>
                                                <em className="fa fa-pencil"></em> Editar
                                            </button>
                                            <button type="button" className="btn btn-text-secondary m-1 align-self-center" onClick={() => {
                                                this.cambioEdicionContra();
                                            }}>
                                                <em className="fa fa-refresh"></em> Cambiar contraseña
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Perfil;
