import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { ToastStore } from "react-toasts";

const endpoint = "ventas/reporte_productos_mas_vendidos";
const endpoint_grafica = "ventas/reporte_productos_mas_vendidos";

export const DATA = "REPORTE_PROD_MAS_VENDIDOS_DATA";
export const DATA_GRAFICA = "DATA_GRAFICA";
export const FECHA_INICIAL = "REPORTE_PROD_MAS_VENDIDOS_FECHA_INICIAL";
export const FECHA_FINAL = "REPORTE_PROD_MAS_VENDIDOS_FECHA_FINAL";
export const LOADER = "REPORTE_PROD_MAS_VENDIDOS_LOADER";
export const SORT = "REPORTE_PROD_MAS_VENDIDOS_SORT";
export const ID_USUARIO = "REPORTE_PROD_MAS_VENDIDOS_ID_USUARIO";
export const PAGE = "REPORTE_PROD_MAS_VENDIDOS_PAGE";
export const ESTADO_DESCARGA = "REPORTE_PROD_MAS_VENDIDOS_ESTADO_DESCARGA";
export const BUSCADOR = "REPORTE_PROD_MAS_VENDIDOS_BUSCADOR";

export const dataGraficaProdMasVendidos = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const fecha_inicial = store.reporte_productos_mas_vendidos.fecha_inicial;
    const fecha_final = store.reporte_productos_mas_vendidos.fecha_final;
    const id_usuario = store.reporte_productos_mas_vendidos.id_usuario;

    const data_grafica = {
        data: [],
        labels: [],
    };

    api.get(endpoint_grafica, { fecha_inicial, fecha_final, id_usuario })
        .then((response) => {
            if (response) {
                const results = response.results;
                if (results.length) {
                    results.forEach(function (item) {
                        data_grafica.labels.push(
                            item.fraccion_data.producto.nombre +
                                " " +
                                item.fraccion_data.nombre
                        );
                        data_grafica.data.push(item.cantidad);
                    });
                }
                dispatch(setDataGrafica(data_grafica));
            }
        })
        .catch((error) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getReporteProdMasVendidos = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const {
        fecha_inicial,
        fecha_final,
        sort,
        id_usuario,
        search,
    } = store.reporte_productos_mas_vendidos;

    api.get(`${endpoint}`, {
        fecha_inicial,
        fecha_final,
        sort,
        id_usuario,
        page,
        search,
    })
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .then((resp) => {
            if (resp) {
                let listado_productos = [];
                const results = resp.results;
                results.forEach(function (item) {
                    const data_producto = {
                        nombre: item.fraccion_data
                            ? item.fraccion_data.producto.nombre
                            : "",
                        marca: item.fraccion_data
                            ? item.fraccion_data.producto.marca
                            : "",
                        presentacion: item.fraccion_data
                            ? item.fraccion_data.nombre
                            : "",
                        cantidad: item.cantidad,
                        total_ventas: item.total_ventas ? item.total_ventas : 0,
                        imagen: item.fraccion_data
                            ? item.fraccion_data.producto.img_cropped
                            : "",
                        codigo_barras: item.fraccion_data.codigo_barras
                            ? item.fraccion_data.codigo_barras
                            : "",
                        precio_producto: item.fraccion_data.precio?
                            item.fraccion_data.precio
                            : '',
                    };
                    listado_productos.push(data_producto);
                });

                dispatch(
                    setData({ count: resp.count, results: listado_productos })
                );
                dispatch(setPage(page));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    dispatch(getReporteProdMasVendidos());
};

export const idUsuarioChange = (id_usuario) => (dispatch) => {
    dispatch(setIdUsuario(id_usuario));
    dispatch(getReporteProdMasVendidos());
    dispatch(dataGraficaProdMasVendidos());
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_gastos = store.reporte_productos_mas_vendidos;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(reporte_gastos.fecha_final);
        if (fecha_final >= fecha_inicial) dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(reporte_gastos.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(getReporteProdMasVendidos());
    dispatch(dataGraficaProdMasVendidos());
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const fecha_inicial = store.reporte_productos_mas_vendidos.fecha_inicial;
    const fecha_final = store.reporte_productos_mas_vendidos.fecha_final;
    const sort = store.reporte_productos_mas_vendidos.sort;
    const id_usuario = store.reporte_productos_mas_vendidos.id_usuario;

    const params = {
        fecha_inicial,
        fecha_final,
        sort,
        id_usuario,
    };

    dispatch(setEstadoDescarga(true));
    api.get(`ventas/descargar_excel_producto_mas_vendido`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(getReporteProdMasVendidos());
};

// ------------------------------------
// PureActions
// ------------------------------------

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const setDataGrafica = (data_grafica) => ({
    type: DATA_GRAFICA,
    data_grafica,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setSort = (sort) => ({
    type: SORT,
    sort,
});

export const setIdUsuario = (id_usuario) => ({
    type: ID_USUARIO,
    id_usuario,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const actions = {
    getReporteProdMasVendidos,
    dataGraficaProdMasVendidos,
    sortChange,
    idUsuarioChange,
    setFecha,
    descargarListado,
    buscar,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_GRAFICA]: (state, { data_grafica }) => {
        return {
            ...state,
            data_grafica,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SORT]: (state, { sort }) => {
        return {
            ...state,
            sort,
        };
    },
    [ID_USUARIO]: (state, { id_usuario }) => {
        return {
            ...state,
            id_usuario,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    data_grafica: {},
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    loader: false,
    sort: "-cantidad",
    id_usuario: "",
    page: 1,
    estado_descarga: false,
    search: "",
};

export default handleActions(reducers, initialState);
