import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { Async } from "react-select";
import { api } from "api";
import moment from "moment";
import DatePicker from "Utils/DatePicker";

class ListadoAperturaCierre extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        fecha_inicial: PropTypes.string.isRequired,
        fecha_final: PropTypes.string.isRequired,
    };
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.leer(this.props.page);
    }

    render() {
        //  State
        const {
            loader,
            data,
            fecha_inicial,
            fecha_final,
            estado_descarga,
        } = this.props;
        //  Bind
        const { descargarListado } = this.props;
        const options = _.cloneDeep(tableOptions);

        const getUsuarios = (search) => {
            return api
                .get("aperturas_cierres_caja/usuarios", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data };
                });
        };

        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.leer;
        options.onSortChange = this.props.sortChange;

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                            <div style={{ flex: "1" }}>
                                <h3>
                                    <b>Historial de apertura/cierre caja</b>
                                </h3>
                            </div>
                            <div
                                className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                style={{ flex: "3" }}
                            >
                                <div style={{ flex: 1 }}>
                                    <label>Fecha Inicio</label>
                                    <DatePicker
                                        value={fecha_inicial}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            this.props.setFecha(
                                                "Inicial",
                                                date
                                            );
                                        }}
                                    />
                                </div>
                                &nbsp; &nbsp;
                                <div style={{ flex: 1 }}>
                                    <label>Fecha Fin</label>
                                    <DatePicker
                                        value={fecha_final}
                                        minDate={
                                            new Date(
                                                moment(
                                                    fecha_inicial,
                                                    "YYYY-MM-DD"
                                                )
                                            )
                                        }
                                        onChange={(date) => {
                                            this.props.setFecha("Final", date);
                                        }}
                                    />
                                </div>
                                &nbsp; &nbsp;
                                <div style={{ flex: 1 }}>
                                    <label>Vendedor</label>
                                    <Async
                                        className="form-control"
                                        labelKey="first_name"
                                        valueKey="id"
                                        searchPromptText="Escriba para buscar"
                                        loadOptions={getUsuarios}
                                        placeholder={"Vendedor"}
                                        onChange={(e) => {
                                            this.props.setUsuario(e);
                                        }}
                                        value={this.props.usuario}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <div className="d-flex flex-row w-100 p-3">
                                <button
                                    className="btn btn-secondary mb-sm-10 align-self-center"
                                    disabled={estado_descarga}
                                    onClick={() => {
                                        descargarListado();
                                    }}
                                >
                                    <em
                                        className={`fa ${
                                            estado_descarga
                                                ? "fa-spinner fa-pulse"
                                                : "fa-download"
                                        }`}
                                    ></em>
                                    &nbsp;Descargar Listado
                                </button>
                            </div>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataSort
                                    dataField="creado"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return <RenderDateTime fecha={cell} />;
                                    }}
                                >
                                    Fecha Apertura
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataSort
                                    dataField="fecha_cierre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return <RenderDateTime fecha={cell} />;
                                    }}
                                >
                                    Fecha Cierre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataSort
                                    dataField="usuario__first_name"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return row.usuario.first_name;
                                    }}
                                >
                                    Vendedor
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataSort
                                    dataField="total_apertura"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="right"
                                    dataFormat={(cell, row) => {
                                        return <RenderMoneda monto={cell} />;
                                    }}
                                >
                                    Total en apertura
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataSort
                                    dataField="total_efectivo_cierre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="right"
                                    dataFormat={(cell, row) => {
                                        return <RenderMoneda monto={cell} />;
                                    }}
                                >
                                    Total en cierre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    dataAlign="center"
                                    dataFormat={(cell, row) => {
                                        return activeFormatter({
                                            ver:
                                                "/reporte_apertura_cierre_caja",
                                        })(cell, row);
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListadoAperturaCierre;
