import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LoadMask from "Utils/LoadMask/LoadMask";
import { PrintContainer, PrintComponent } from "Utils/Print";
import { Link } from 'react-router-dom';
import Recibo from "Utils/Recibo";

class ResumenReporteGastos extends Component {
    static propTypes = {
        // cierre: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.getGasto(this.props.match.params.id);
    }

    render(){
        //  state
        const {gasto, loader, print_state} = this.props
        //  bind

        return (
            <LoadMask loading={loader} dark blur>
                {print_state.flag && ( <PrintComponent/> )}
                <div className="col-lg-12 col-md-12 col-sm-12 no-print-padding">
                    <div className="col-12 mb1">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.print('gastoCompraprod');
                                //printActions.print('gastoCompraprod')
                                // window.print();
                            }}
                            className="btn btn-secondary mt-3"
                            type={'button'}
                        >
                            <em className="fa fa-print"/> Imprimir
                        </button>
                    </div>
                    <PrintContainer name="gastoCompraprod">
                        <Recibo
                            usuario= {gasto.usuario ? gasto.usuario.first_name: '-----'}
                            movimiento={gasto}
                            sucursal={ gasto.sucursal ? gasto.sucursal: {}}
                            tipo={"Ingreso"}
                            footerText={"Costo Total"}
                        />
                    </PrintContainer>
                    <div className="mt1 text-center">
                        <Link className="btn btn-tertiary m-1" to="/reporte_gastos"><i className="fa fa-arrow-left" aria-hidden="true" /> Regresar</Link>
                    </div>
                </div>
            </LoadMask>
        )
    }
}

export default ResumenReporteGastos;
