import { connect } from 'react-redux';

import Gastos from './Gastos';
import { actions } from "../../../../redux/modules/reportes/gastos";
import { print } from '../../../../redux/modules/print/print'

const ms2p = (state) => ({
    ...state.reporte_gastos,
    paginas: state.reporte_gastos.paginas_impresion.paginas,
    listado_pagina: state.reporte_gastos.paginas_impresion.listado_pagina,
    usuarios_sucursal: state.usuario.usuarios_sucursal,
    usuario: state.usuario.me.first_name,
    sucursales: state.usuario.me.sucursales,
    id_sucursal: state.usuario.sucursal,
    print_state: state.print,
})

const md2p = {
    ...actions,
    print: (name) => dispatch => dispatch(print(name)),
};

export default connect(ms2p, md2p)(Gastos);
