import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/despacho/recepcion_orden_compra";
import RecepcionOrdenCompra from "./RecepcionOrdenCompra";

const ms2p = state => {
    return {
        ...state.recepcion_orden_compra
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(RecepcionOrdenCompra);