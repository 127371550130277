import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes/ventas";
import DetalleVenta from "./DetalleVenta";
import { goBack } from "react-router-redux";


const ms2p = (state) => {
    return {
        ...state.reporte_ventas,
        venta_permitir_descuento: state.usuario.venta_permitir_descuento,
    };
};

const md2p = { ...actions, regresar: () => (dispatch) => {dispatch(goBack())} };

export default connect(ms2p, md2p)(DetalleVenta);
