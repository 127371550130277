import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { Async } from "react-select";
import { api } from "api";
import { icons } from "icons";
import { PrintContainer, actions as printActions } from "Utils/Print";
import Recibo from "Utils/Recibo";
import Factura from "Utils/Factura";
import DatePicker from "Utils/DatePicker";
import moment from "moment";
import { tipo_movimientos } from "variables";

// CONSTANTES PARAL LOS SELECTS ASINCRONOS
const lista_usuarios = [];
const lista_clientes = [];

class ListadoDevoluciones extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        fecha_inicial: PropTypes.string.isRequired,
        fecha_final: PropTypes.string.isRequired,
    };
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.listar();
    }

    render() {
        // state
        const {
            loader,
            data,
            fecha_inicial,
            fecha_final,
            estado,
            factura_personalizada,
            venta_impresion,
            formato_factura,
            datos_venta,
            estado_descarga,
        } = this.props;
        // bind
        const {
            anular,
            estadoChange,
            seleccionImpresion,
            descargarListado,
        } = this.props;
        const options = _.cloneDeep(tableOptions);

        const getUsuarios = (search) => {
            return api
                .get("devoluciones/usuarios", { search, todos: true })
                .catch(() => {})
                .then((data) => {
                    data.forEach((item) => {
                        if (!_.find(lista_usuarios, { id: item.id }))
                            lista_usuarios.push(item);
                    });
                    return { options: lista_usuarios };
                });
        };

        const getCliente = (search) => {
            return api
                .get("clientes", { search })
                .catch(() => {})
                .then((data) => {
                    data.results.forEach((item) => {
                        if (!_.find(lista_clientes, { id: item.id }))
                            lista_clientes.push(item);
                    });
                    return { options: lista_clientes };
                });
        };

        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        let total = 0;
        if (data.results) {
            data.results.forEach((item) => {
                total += item.movimiento.total;
            });
        }
        /* No se esta utilizarndo esta funcion
        Se debe hacer refactor de impresion para imprimir */
        const ReimpresionReciboFactura = (id) => {
            // onClick={this.editar(id)}
            return (
                <img
                    onClick={(e) => {
                        e.preventDefault();
                        seleccionImpresion(
                            id,
                            printActions,
                            "reporteVentasReimpresion"
                        );
                    }}
                    className="action-img"
                    title={`Reimprimir ${
                        factura_personalizada ? "factura" : "recibo"
                    }`}
                    src={icons.imprimir}
                    alt={`Reimprimir ${
                        factura_personalizada ? "factura" : "recibo"
                    }`}
                    style={{ maxWidth: "22px" }}
                />
            );
        };

        return (
            <div>
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div style={{ flex: "1" }}>
                                    <h3>
                                        <b>Historial de devoluciones</b>
                                    </h3>
                                </div>
                                <div
                                    className="d-flex flex-wrap flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div style={{ flex: 1 }}>
                                        <label>Fecha Inicio</label>
                                        <DatePicker
                                            value={fecha_inicial}
                                            maxDate={new Date()}
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Inicial",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: 1 }}>
                                        <label>Fecha Fin</label>
                                        <DatePicker
                                            value={fecha_final}
                                            minDate={
                                                new Date(
                                                    moment(
                                                        fecha_inicial,
                                                        "YYYY-MM-DD"
                                                    )
                                                )
                                            }
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Final",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: 1 }}>
                                        <label>Vendedor</label>
                                        <Async
                                            className="form-control"
                                            labelKey="first_name"
                                            valueKey="id"
                                            searchPromptText="Escriba para buscar"
                                            loadOptions={getUsuarios}
                                            placeholder={"Vendedor"}
                                            onChange={(e) => {
                                                this.props.setUsuario(e);
                                            }}
                                            value={this.props.usuario}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div style={{ flex: 1 }}>
                                        <label>Cliente</label>
                                        <Async
                                            className="form-control"
                                            labelKey="nombre"
                                            valueKey="id"
                                            searchPromptText="Escriba para buscar"
                                            loadOptions={getCliente}
                                            placeholder={"Cliente"}
                                            onChange={(e) => {
                                                this.props.setCliente(e);
                                            }}
                                            value={this.props.cliente}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div className="mt-10">
                                        <label htmlFor="estado">Estado</label>
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row">
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="estado"
                                                            type="radio"
                                                            value="null"
                                                            checked={
                                                                estado === null
                                                            }
                                                            onChange={() => {
                                                                estadoChange(
                                                                    null
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        Todos
                                                    </label>
                                                </div>
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="estado"
                                                            type="radio"
                                                            value="canjeado"
                                                            checked={
                                                                estado ===
                                                                "canjeado"
                                                            }
                                                            onChange={() => {
                                                                estadoChange(
                                                                    "canjeado"
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        Canjeado
                                                    </label>
                                                </div>
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="estado"
                                                            type="radio"
                                                            value="no_canjeado"
                                                            checked={
                                                                estado ===
                                                                "no_canjeado"
                                                            }
                                                            onChange={() => {
                                                                estadoChange(
                                                                    "no_canjeado"
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        No Canjeado
                                                    </label>
                                                </div>
                                                <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                    <label className="negro-dark2 font-weight-normal">
                                                        <input
                                                            name="estado"
                                                            type="radio"
                                                            value="anulada"
                                                            checked={
                                                                estado ===
                                                                "anulada"
                                                            }
                                                            onChange={() => {
                                                                estadoChange(
                                                                    "anulada"
                                                                );
                                                            }}
                                                        />
                                                        <span />
                                                        Anulada
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoadMask loading={loader} light blur>
                                <div className="content-total-reports flex-column flex-md-row flex-lg-row align-items-end justify-content-start justify-content-md-between justify-content-lg-between w-100 mt-10 py-1 py-md-2 py-lg-2">
                                    <button
                                        className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em
                                            className={`fa ${
                                                estado_descarga
                                                    ? "fa-spinner fa-pulse"
                                                    : "fa-download"
                                            }`}
                                        ></em>
                                        &nbsp;Descargar Listado
                                    </button>
                                    <div className="d-flex flex-column flex-md-row flex-lg-row align-items-end justify-content-start justify-content-md-end justify-content-lg-end">
                                        <h3 className="padding-10 margin-0">
                                            <b>Total</b>
                                        </h3>
                                        <h3 className="padding-10 margin-0">
                                            <b>
                                                <RenderCurrency value={total} />
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="creado"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderDateTime
                                                    className={`${
                                                        row.activo == false
                                                            ? "text-underline"
                                                            : ""
                                                    }`}
                                                    fecha={cell}
                                                />
                                            );
                                        }}
                                    >
                                        Fecha
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="usuario__first_name"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <span
                                                    className={`${
                                                        row.activo == false
                                                            ? "text-underline"
                                                            : ""
                                                    }`}
                                                >
                                                    {row.usuario}
                                                </span>
                                            );
                                        }}
                                    >
                                        Brindado por
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="canjeado_por"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            if (!!cell) {
                                                return (
                                                    <span
                                                        className={`${
                                                            row.activo == false
                                                                ? "text-underline"
                                                                : ""
                                                        }`}
                                                    >
                                                        {cell}
                                                    </span>
                                                );
                                            }
                                            return (
                                                <span
                                                    className={`${
                                                        row.activo == false
                                                            ? "text-underline"
                                                            : ""
                                                    }`}
                                                >
                                                    Sin Canjear
                                                </span>
                                            );
                                        }}
                                    >
                                        Aceptado Por
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        isKey
                                        dataField="codigo"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <span
                                                    className={`${
                                                        row.activo == false
                                                            ? "text-underline"
                                                            : ""
                                                    }`}
                                                >
                                                    {cell}
                                                </span>
                                            );
                                        }}
                                    >
                                        Código del Vale
                                    </TableHeaderColumn>
                                    {estado === null && (
                                        <TableHeaderColumn
                                            dataField="canjeado_por"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <div
                                                        className={`${
                                                            row.activo == false
                                                                ? "text-underline"
                                                                : ""
                                                        }`}
                                                    >
                                                        {cell ? (
                                                            <span>SI</span>
                                                        ) : (
                                                            <span>NO</span>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        >
                                            Canjeado
                                        </TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="cliente__nombre"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => {
                                            return (
                                                <span
                                                    className={`${
                                                        row.activo == false
                                                            ? "text-underline"
                                                            : ""
                                                    }`}
                                                >
                                                    {row.cliente}
                                                </span>
                                            );
                                        }}
                                    >
                                        Comprador
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataSort
                                        dataField="movimiento__total"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataAlign="right"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <RenderMoneda
                                                    className={`${
                                                        row.activo == false
                                                            ? "text-underline"
                                                            : ""
                                                    }`}
                                                    monto={row.movimiento.total}
                                                />
                                            );
                                        }}
                                    >
                                        Valor del vale
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        dataFormat={activeFormatter({
                                            ver:
                                                "/reporte_devoluciones/detalle",
                                        })}
                                    >
                                        Acciones
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListadoDevoluciones;
