import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/caja/caja";
import DetalleCaja from "./DetalleCaja";
import { tipo_movimientos } from "variables";
import {
    getMe,
    getCierreCaja,
} from "../../../../redux/modules/usuario/usuario";

const ms2p = (state) => {
    return {
        ...state.caja,
        factura_personalizada: state.usuario.factura_personalizada,
        utiliza_fel: state.usuario.utiliza_fel,
        formato_factura: state.usuario.formato_factura,
        print_state: state.print,
    };
};

const md2p = {
    ...actions,
    getMe: () => (dispatch) => dispatch(getMe()),
    getCierreCaja,
};

export default connect(ms2p, md2p)(DetalleCaja);
