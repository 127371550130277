import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";
import {BreakLine, tableOptions} from "Utils/tableOptions";
import {RenderCurrency} from "Utils/renderField/renderReadField";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {RenderDateTime, RenderMoneda, RenderNumero} from "Utils/renderField/renderTableField";

class ResumenCuentaCobrar extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {
        this.props.getDataResumen();
    }

    render() {
        // state
        const {data_resumen, loader} = this.props
        // bind

        //  OPCIONES
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        // options.onPageChange = data_resumen.clientes_con_creditos ? data_resumen.clientes_con_creditos:[];

        return (
            <div className="row">
                <div className="col-12 col-lg-6 col-md-6 col-sm-12 content-tabla-resumen padding-0 mt15">
                    <LoadMask loading={loader} light blur>
                        <div className="header-tabla-resumen">
                            <h3><b>Top 5 clientes con créditos</b></h3>
                        </div>
                        <BootstrapTable
                            data={loader ? [] : data_resumen.clientes_con_creditos}
                            remote
                            hover
                            options={options}
                            tableHeaderClass="tabla-resumen"
                            tableBodyClass="tabla-resumen"
                        >
                            <TableHeaderColumn
                                width="0%"
                                isKey
                                dataField="cliente_proveedor_id"
                            >
                                id
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='cliente_proveedor__nombre'
                                tdStyle={BreakLine}
                                thStyle={BreakLine}>Nombre del cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='cantidad_pendiente'
                                tdStyle={BreakLine} thStyle={BreakLine}
                                dataFormat={(cell) => <RenderNumero monto={cell} />}
                                dataAlign="right">Cant. de créditos</TableHeaderColumn>
                            <TableHeaderColumn dataField='total'
                                tdStyle={BreakLine} thStyle={BreakLine}
                                dataFormat={(cell) => <RenderMoneda monto={cell} />}
                                dataAlign="right">Total</TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
                <div className="col-12 col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center mt15 content-totales-resumen">
                    <div className="item-total-creditos">
                        <LoadMask loading={loader} light blur>
                            {
                                data_resumen.total_creditos_vencidos && (
                                    <div className="w-100 d-flex flex-row aling-items-center justify-content-center">
                                        <div className="item-texto d-flex align-items-center justify-content-center">
                                            <b>Total créditos vencidos</b>
                                        </div>
                                        <div className="item-total d-flex flex-column align-items-center justify-content-center">
                                            <div className="item-total-cantidad">
                                                <RenderNumero monto={data_resumen.total_creditos_vencidos.cantidad} /> créditos
                                            </div>
                                            <b>
                                                <RenderCurrency
                                                    value={data_resumen.total_creditos_vencidos.total ? data_resumen.total_creditos_vencidos.total:0}/>
                                            </b>
                                        </div>
                                    </div>
                                )
                            }
                        </LoadMask>
                    </div>

                    <div className="item-total-creditos mt15">
                        <LoadMask loading={loader} light blur>
                            {
                                data_resumen.total_creditos_vigentes && (
                                    <div className="w-100 d-flex flex-row aling-items-center justify-content-center">
                                        <div className="item-texto d-flex align-items-center justify-content-center">
                                            <b>Total créditos vigentes</b>
                                        </div>
                                        <div className="item-total d-flex flex-column align-items-center justify-content-center">
                                            <div className="item-total-cantidad">
                                                <RenderNumero monto={data_resumen.total_creditos_vigentes.cantidad} /> créditos
                                            </div>
                                            <b>
                                                <RenderCurrency
                                                    value={data_resumen.total_creditos_vigentes.total ? data_resumen.total_creditos_vigentes.total:0}/>
                                            </b>
                                        </div>
                                    </div>
                                )
                            }
                        </LoadMask>
                    </div>
                    <div className="item-total-creditos mt15">
                        <LoadMask loading={loader} light blur>
                            {
                                data_resumen.total_creditos_por_vencer && (
                                    <div className="w-100 d-flex flex-row aling-items-center justify-content-center">
                                        <div className="item-texto d-flex align-items-center justify-content-center">
                                            <b>Total créditos por vencer</b>
                                        </div>
                                        <div className="item-total d-flex flex-column align-items-center justify-content-center">
                                            <div className="item-total-cantidad">
                                                <RenderNumero monto={data_resumen.total_creditos_por_vencer.cantidad} /> créditos
                                            </div>
                                            <b>
                                                <RenderCurrency
                                                    value={data_resumen.total_creditos_por_vencer.total ? data_resumen.total_creditos_por_vencer.total:0}/>
                                            </b>
                                        </div>
                                    </div>
                                )
                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

ResumenCuentaCobrar.propTypes = {

};

export default ResumenCuentaCobrar;
