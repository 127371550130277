import React from 'react';
import createClass from 'create-react-class';

const colores = ["#66cc44", "#ff5810", "#007df9", "#f30a76", "#8300F7", "#277793", "#b00c25", "#154d77", "#69650f", "#a8802f"];


export const SucursalOption = createClass({
    handleMouseDown (event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    },
    handleMouseEnter (event) {
        this.props.onFocus(this.props.option, event);
    },
    handleMouseMove (event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    },
    render () {
        const { option } = this.props;

        return (
            <div className={"ml-2"}
                 className={`select-option-sucursal ${option.index == 0 ? "select-option-empresa": "select-option-sucursal-"+(option.index+1)}`}
                 onMouseDown={this.handleMouseDown}
                 onMouseEnter={this.handleMouseEnter}
                 onMouseMove={this.handleMouseMove}
            >
                <div className={`option-sucursal d-flex align-items-center ${option.sucursal_activa == option.id ? "option-sucursal-activo": ""}`}>
                    <span className="w-100">{option.nombre}</span>
                </div>
            </div>
        );
    }
});

export const SucursalValue = createClass({
    render () {
        const { value: option } = this.props;
        return (
            <div className="Select-value sucursal-select" style={{height:"27px"}}>
				<span className="Select-value-label negro-dark font-weight-bold w-100 d-flex" style={{fontSize:"15px"}}>
					{option.nombre}&nbsp;&nbsp;<em className="fa fa-caret-down ml-auto align-self-center" />
				</span>
            </div>
        );
    }
});
