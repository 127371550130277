//  GENERAR DESPACHO
export { default as ListadoDespacho } from "./Despachos/Listado";
export { default as CrearDespacho } from "./Despachos/Crear";
export { default as NotaEnvio } from "./NotaEnvio";
export { default as DetalleDespacho } from "./Despachos/Detalle";

//  RECEPCION DESPACHO
export { default as ListadoRecepcionDespacho } from "./RecepcionDespacho/Listado";
export { default as CrearRecepcionDespacho } from "./RecepcionDespacho/Crear";
export { default as NotaRecepcionDespacho } from "./RecepcionDespacho/NotaRecepcionDespacho"
export { default as DetalleRecepcionDespacho } from "./RecepcionDespacho/Detalle";
