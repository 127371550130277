import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import { SeleccionSucursal } from "Utils/SeleccionSucursal";
import IngresoForm from "./IngresoForm";
import ProveedorForm from "../../Proveedores/Crear/ProveedoresCrearForm";
import ModalAyuda from "Utils/Ayuda";
import { icons } from "icons";


class ReajusteProducto extends Component {
    static propTypes = {

    };
    constructor(props) {
        super(props);
        this.state = {
            ver_buscador: false,
            open:false,
            modalAyuda: false,
            itemsBodegaIngreso: [
                // {
                //     src: icons.bodega_ingreso_p1,
                //     alt: "Paso 1"
                // },
                {
                    src: icons.bodega_ingreso_p2,
                    alt: "Paso 1"
                },
                {
                    src: icons.bodega_ingreso_p3,
                    alt: "Paso 2"
                },
                {
                    src: icons.bodega_ingreso_p4,
                    alt: "Paso 3"
                },
                {
                    src: icons.bodega_ingreso_p5,
                    alt: "Paso 4"
                },
                {
                    src: icons.bodega_ingreso_p6,
                    alt: "Paso 5"
                },
                {
                    src: icons.bodega_ingreso_p7,
                    alt: "Paso 6"
                },
                {
                    src: icons.bodega_ingreso_p8,
                    alt: "Paso 7"
                },
                {
                    src: icons.bodega_ingreso_p9,
                    alt: "Paso 8"
                },
                {
                    src: icons.bodega_ingreso_p10,
                    alt: "Paso 9"
                },
                {
                    src: icons.bodega_ingreso_p11,
                    alt: "Paso 10"
                },
                {
                    src: icons.bodega_ingreso_p12,
                    alt: "Paso 11"
                },
            ],
        }
    }
    componentWillMount() {
        this.props.listar();
    }
    cambioBuscador() {
        this.setState({ver_buscador: !this.state.ver_buscador});
    }

    cierre = () => {
        this.setState({open: false});
    };
    abrir = () => {
        this.setState({open: true});
    };
    closeModal = () => {
        this.setState({modalAyuda: false});
    }
    openModal = () => {
        this.setState({modalAyuda: true});
    }

    render() {
        const { sucursal } = this.props;
        const { modalAyuda, itemsBodegaIngreso } = this.state;

        if (sucursal === undefined) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo padding-5">
                                Para registrar un ingreso elija una sucursal
                            </div>
                            <div className="padding-15">
                                <SeleccionSucursal />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <React.Fragment>
                <IngresoForm
                    {...this.props}
                    onSubmit={this.props.efectuarIngreso}
                    ver_buscador={this.state.ver_buscador}
                    cambio_buscador={this.cambioBuscador.bind(this)}
                    abrirProveedor={this.abrir}
                    actionAyuda={this.openModal}
                />
                <Modal classNames={{modal:"modal-sm"}} onClose={this.cierre} showCloseIcon={false} open={this.state.open} >
                    <ProveedorForm cancelar={this.cierre} onSubmit={(data) => {
                        this.cierre();
                        this.props.crearProveedor(data);
                    }} />
                </Modal>
                <ModalAyuda open={modalAyuda} closeModal={this.closeModal} openModal={this.openModal} items={itemsBodegaIngreso}/>
            </React.Fragment>
        );
    }
}

export default ReajusteProducto;
