import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import LoadMask from "Utils/LoadMask/LoadMask";
import { ListadoAccion } from "Utils/ListadoAccion"
import FormRecepcionOC from './RecepcionOrdenCompraForm'
import { RenderDate } from "Utils/renderField/renderReadField"
import { accionProductosRecepcionOC } from '../ComponentesRecepcionOC'
import {
    formatoNombre,
    formatoExistencia,
    buscar
} from "Utils/CamposComunes";
class RecepcionOrdenCompra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open_modal: false
        }
    }

    static propTypes = {
        detalles: PropTypes.array.isRequired,
        loader: PropTypes.bool.isRequired
    }

    componentWillMount() {
        this.props.getOC(this.props.match.params.id)
    }

    abrirModalProductos = () => {
        this.setState({open_modal: true})
        this.props.listarProductos(this.props.page_productos)
    }
    cerrarModalProductos= () => {
        this.setState({open_modal: false})
    }

    agregarProducto = (producto) => {
        this.props.agregarProducto(producto);
        this.setState({open_modal: false})
    }


    render() {
        // State
        const { orden_compra, detalles, loader } = this.props
        const { productos, loader_productos, search_productos, page_productos } = this.props
        // Bind
        const { asignarCantidadRecibido, setNotaProducto, registrarRecepcion, limpiarDatosOC } = this.props;
        const { sortChangeProductos, listarProductos, buscarProductos } = this.props;

        const llavesListado = [
            { nombre: "producto__nombre", sort: true, caption: "Nombre", format: formatoNombre, align: "left" },
            // { nombre: "inventario", sort: false, caption: "Existencias", format: formatoExistencia, align: "right", width: "100px" },
            { nombre: "acciones", sort: false, caption: "Agregar", format: accionProductosRecepcionOC({agregar: this.agregarProducto}), align: "center", width: "90px" },
        ];

        const goBack = () => {
            this.props.history.goBack();
        }

        if (!orden_compra){
            return (
                <div className="grid-container padding-20 text-center">
                    <LoadMask loading={loader} light blur radius>
                        <h2>Orden de compra no encontrada</h2>
                        <button className="btn btn-tertiary" onClick={goBack}> <i className="fa fa-arrow-left" aria-hidden="true" /> Regresar</button>
                    </LoadMask>
                </div>
            );
        }else {
            if (orden_compra.estado_inventario == 300) {
                return (
                    <div className="grid-container padding-20 text-center">
                        <h2>Recepción de orden de compra completado</h2>
                        <button className="btn btn-tertiary" onClick={goBack}> <i className="fa fa-arrow-left" aria-hidden="true" /> Regresar</button>
                    </div>
                )
            }
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container pb-0">
                        <LoadMask loading={loader} light blur radius>
                            <div className="w-100">
                                <div className="px-3 pt-3 d-flex flex-column mb-1">
                                    <div style={{ flex: "1" }}>
                                        <h3>
                                            <b>
                                                Orden de compra No.{" "}
                                                {orden_compra.no_orden}
                                            </b>
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 d-flex flex-column align-items-center">
                                    <div className="w-100 row">
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Proveedor</b>
                                                {orden_compra.proveedor.nombre}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Fecha</b>
                                                <RenderDate
                                                    value={orden_compra.fecha}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Sucursal destino</b>
                                                {
                                                    orden_compra
                                                        .sucursal_destino.nombre
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Documento</b>
                                                {orden_compra.num_documento
                                                    ? orden_compra.num_documento
                                                    : "-----"}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="contenedor-campos-oc border-bottom">
                                                <b>Nota</b>
                                                {orden_compra.descripcion}
                                            </div>
                                        </div>
                                    </div>
                                    <FormRecepcionOC
                                        this={this}
                                        detalles={detalles}
                                        loader={loader}
                                        onSubmit={registrarRecepcion}
                                        limpiarDatosOC={limpiarDatosOC}
                                        setNotaProducto={setNotaProducto}
                                        asignarCantidadRecibido={
                                            asignarCantidadRecibido
                                        }
                                        goBack={goBack}
                                        agregarMasProductos={
                                            this.abrirModalProductos
                                        }
                                    />
                                </div>
                            </div>
                        </LoadMask>
                    </div>
                </div>
                {/* MODAL DE ABONO A CUENTA POR PAGAR */}
                <Modal
                    open={this.state.open_modal}
                    onClose={this.cerrarModalProductos}
                    classNames={{ modal: "modal-sm" }}
                >
                    <div className="p-0" style={{ maxWidth: "500px" }}>
                        <div className="row w-100 container-tabla-productos">
                            <ListadoAccion
                                buscar={buscar({search: search_productos, agregarPrimer: () => {}, buscar: buscarProductos})}
                                loader={loader_productos}
                                llaves={llavesListado}
                                data={productos}
                                listar={listarProductos}
                                sortChange={sortChangeProductos}
                                titulo={
                                    <div className="d-flex flex-column flex-lg-row m-2">
                                        <span
                                            style={{ fontSize: "15px" }}
                                            className="align-self-end"
                                        >
                                            Listado de Productos
                                        </span>
                                    </div>
                                }
                                page={page_productos}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default  RecepcionOrdenCompra;