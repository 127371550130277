import { handleActions } from "redux-actions";
import { push, replace, goBack } from "react-router-redux";
import { change as changeForm, initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";
import { ToastStore } from "react-toasts";

// ------------------------------------
// Constants
// ------------------------------------

export const DATA = "PROVEEDOR_DATA";
export const LOADER = "PROVEEDOR_LOADER";
export const PAGE = "PROVEEDOR_PAGE";
export const BUSCADOR = "PROVEEDOR_BUSCADOR";
export const ESTADO_DESCARGA = "PROVEEDOR_ESTADO_DESCARGA";

const endpoint = "proveedores";

// ------------------------------------
// Actions
// ------------------------------------

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get(`${endpoint}/estado_descarga`, { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = error.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Listado_clientes.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const proveedor = store.proveedor;
    const { ordering, search } = proveedor;
    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel`, { ordering, search })
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

export const leer = (id) => (dispatch, getStore) => {
    api.get(endpoint + "/" + id)
        .catch((error) => {
            Swal("ERROR", error.detail || "Ha ocurrido un error.", "error");
        })
        .then((data) => {
            dispatch(initializeForm("ProveedorCrear", data));
        });
};

export const crear = () => (dispatch, getStore) => {
    const store = getStore();
    const data = store.form.ProveedorCrear.values;
    dispatch(setLoader(true));
    api.post(endpoint, data)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .then((data) => {
            if (data) {
                // ir atras (normalmente hacia el grid)
                ToastStore.success("Nuevo proveedor almacenado");
                dispatch(goBack());
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const editar = () => (dispatch, getStore) => {
    // esta acción se usa para crear un cliente
    // desde el CRUD de clientes, no confundir con crear()
    const store = getStore();
    const data = store.form.ProveedorCrear.values;
    const id = data.id;

    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}/`, data)
        .then((data) => {
            ToastStore.success("Proveedor actualizado");
            dispatch(goBack());
        })
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const proveedor = store.proveedor;
    const { ordering, search } = proveedor;
    api.get(endpoint, { page, ordering, search })
        .catch((err) => {})
        .then((data) => {
            if (data) {
                dispatch(setData(data));
            }
            dispatch(setPage(page));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.cliente.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`)
        .then((data) => {
            if (data) {
                ToastStore.success("Registro eliminado.");
                dispatch(listar());
            }
        })
        .catch((error) => {
            console.log("error!", error);
            Swal(
                "ERROR",
                error.detail ||
                    "Ha ocurrido un error, espere un momento y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

// ------------------------------------
// PureActions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

const setPage = (page) => ({
    type: PAGE,
    page,
});

const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const actions = {
    crear,
    editar,
    leer,
    listar,
    buscar,
    sortChange,
    setPage,
    setBuscador,
    eliminar,
    descargarListado,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    cliente: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "",
    estado_descarga: false,
};

export default handleActions(reducers, initialState);
