import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import OCTipoPagoForm from './OCTipoPagoForm'

export default class OCTipoPago extends Component {
    static propTypes = {
    }

    render() {

        if (!this.props.seleccionados.length) {
            return (<Redirect to="/ordenes_de_compra/nueva" />);
        }
        return (
            <div>
                <OCTipoPagoForm {...this.props} initialValues={{...this.props.datos_tipo_pago}}  onSubmit={this.props.nuevaOrdenCompra}/>
            </div>
        )
    }
}
