import Tooltip from 'rc-tooltip';
import React, { Component } from 'react'
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { ValeItem } from 'Utils/Venta/ItemEditableDetalleVenta'
const EncabezadoItem = (props) => {
    return (
        <div className="w-100 d-flex flex-row">
            {/* NOMBRE Y CODIGO DE PRODUCTO O VALE */}
            <div className="w-100">
                <b>{ props.item.es_vale ? "" : props.item.producto.nombre}</b> {props.item.codigo_barras ? props.item.codigo_barras : ""}
            </div>
            {/* FIN NOMBRE Y CODIGO DE PRODUCTO O VALE */}

            {/* BOTON ELIMINAR */}
            <div className="align-self-start text-right" style={{ width: "20px" }}>
                <button className="btn-delete" onClick={(e) => {
                    e.stopPropagation();
                    props.quitar(props.item, props.index);
                }}>
                    <em className="fa fa-times" />
                </button>
            </div>
            {/* FIN BOTON ELIMINAR */}
        </div>
    )
}

const ProductoItem = (props) => {
    return (
        <div className="d-flex flex1 flex-column justify-content-between">
            <div className="d-flex justify-content-between align-items-center mt-10" style={{ fontSize: "13px" }}>
                {
                    (props.item.producto.inventariable && props.item.cantidad_total > props.item.inventario_total && props.mostrar_alerta_inventario) ? (
                        <div className="d-flex flex-row align-items-center justify-content-center" style={{ flex: "0.3" }}>
                            <Tooltip placement="top" overlay={
                                <div>
                                    <strong>Se ha excedido el inventario</strong>
                                    <br />
                                    <strong>Disponible: {props.item.inventario_total}</strong>
                                </div>}>
                                <em className="text-danger fa fa-exclamation-triangle opacityPulse-css" />
                            </Tooltip>
                        </div>
                    ) : (<div style={{ flex: "0.3" }}></div>)
                }
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ flex: "0.6" }}>
                    <div className="width100 text-right font-weight-bold">
                        <RenderCurrency value={props.item.precio ? props.item.precio : 0} />
                    </div>
                    {
                        (props.item.desc_porcentaje > 0) && (
                            <div className="width100 text-right precio-anterior">
                                <RenderCurrency value={props.item.precio_original ? props.item.precio_original : 0} />
                            </div>
                        )
                    }
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className="text-center mr-1 flex-row-center" style={{ flex: "1.8" }}>
                    x
                    <div className="d-flex flex-row ml-10 flex1" style={{ height: "30px" }}>
                        <div className="width100 pl-1 pr-1 text-right font-weight-bold">
                            {props.item.cantidad}
                        </div>
                    </div>
                    {/* UNIDAD DE MEDIDA */}
                    <div className="d-flex flex1 align-items-center justify-content-center" style={{ height: "35px" }}>
                        &nbsp;&nbsp;
                        <span className="flex1 text-center" style={{ paddingLeft: "3px" }}>{props.item.nombre}</span>
                    </div>
                </div>
                <b className="text-right" style={{ flex: "0.6" }}><RenderCurrency value={props.item.precio * props.item.cantidad} /></b>
            </div>
        </div>
    )
}

export default class ItemDetalleVenta extends Component {
    render() {
        // State
        const { index, item, mostrar_alerta_inventario } = this.props;
        // bind
        const { quitar } = this.props
        return (
            <div key={index} className="d-flex flex-column justify-content-between container-item">
                <div className="d-flex flex-column">
                    <EncabezadoItem item={item} index={index} quitar={quitar}/>
                    {item.es_vale ? (
                        <ValeItem item={item}/>
                    ) : (
                        <ProductoItem
                            item={item}
                            mostrar_alerta_inventario={mostrar_alerta_inventario}
                        />
                    )}
                </div>
            </div>
        )
    }
}
