import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/cuenta_cobrar/cuenta_cobrar";
import ListadoCuentaCobrar from "./ListadoCuentaCobrar";
import { goBack } from "react-router-redux";

const ms2p = (state) => {
    let saldo_inicial = 0;
    let pendiente = 0;
    let codigo_venta = ''
    try {
        const {
            efectivo,
            tarjeta,
            cheque,
            deposito,
            nota_credito,
            retencion_iva,
            exencion_iva,
        } = state.form.CobroCreditoForm.values;
        saldo_inicial = parseFloat(
            state.cuenta_cobrar.movimiento_venta.cuenta_cobrarpagar.saldo.toFixed(
                2
            )
        );
        let total_pago = (
            parseFloat(efectivo ? parseFloat(efectivo).toFixed(2) : 0) +
            parseFloat(tarjeta ? parseFloat(tarjeta).toFixed(2) : 0) +
            parseFloat(cheque ? parseFloat(cheque).toFixed(2) : 0) +
            parseFloat(deposito ? parseFloat(deposito).toFixed(2) : 0) +
            parseFloat(
                retencion_iva ? parseFloat(retencion_iva).toFixed(2) : 0
            ) +
            parseFloat(exencion_iva ? parseFloat(exencion_iva).toFixed(2) : 0) +
            parseFloat(nota_credito ? parseFloat(nota_credito).toFixed(2) : 0)
        ).toFixed(2);
        total_pago = parseFloat(total_pago);
        pendiente = parseFloat((saldo_inicial - total_pago).toFixed(2));
        try{
            codigo_venta = state.cuenta_cobrar.movimiento_venta.codigo_venta
        }catch(e){
            codigo_venta = ''
        }
    } catch (e) {
        saldo_inicial = state.cuenta_cobrar.movimiento_venta
            ? parseFloat(
                  state.cuenta_cobrar.movimiento_venta.cuenta_cobrarpagar.saldo.toFixed(
                      2
                  )
              )
            : 0;
        pendiente = saldo_inicial;
    }

    return {
        ...state.cuenta_cobrar,
        sucursal: state.usuario.sucursal,
        factura_personalizada: state.usuario.factura_personalizada,
        formato_factura: state.usuario.formato_factura,
        marcar_entrega: state.usuario.me
            ? !!state.usuario.me.marcar_entrega
            : false,
        saldo_inicial,
        pendiente,
        codigo_venta,
        tipo_punto_venta: state.usuario.tipo_punto_venta,
        print_state: state.print,
        utiliza_fel: state.usuario.utiliza_fel,
    };
};

const md2p = {
    ...actions,
    regresar: () => (dispatch) => {
        dispatch(goBack());
    },
};

export default connect(ms2p, md2p)(ListadoCuentaCobrar);
