import PropTypes from 'prop-types'
import FormNuevaOC from './DatosOCForm'
import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import LoadMask from 'Utils/LoadMask/LoadMask'
import { SeleccionSucursal } from "Utils/SeleccionSucursal";
import ProveedorForm from "../../Proveedores/Crear/ProveedoresCrearForm";

class CrearOrdenCompra extends Component {
    static propTypes = {
        setVerBuscador: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    componentWillMount() {
        this.props.listar();
    }

    cerrar = () => {
        this.setState({open: false});
    };

    abrir = () => {
        this.setState({open: true});
    };

    render() {
        // State
        const { sucursal } = this.props;

        if (sucursal === undefined) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo padding-5">
                                Para registrar una orden de compra elija una sucursal
                            </div>
                            <div className="padding-15">
                                <SeleccionSucursal />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <React.Fragment>
                <FormNuevaOC
                    {...this.props}
                    initialValues={this.props.datos}
                    abrirProveedor={this.abrir}
                    onSubmit={this.props.irTipoPago}/>
                
                <Modal classNames={{modal:"modal-sm"}} onClose={this.cerrar} showCloseIcon={false} open={this.state.open} >
                    <LoadMask loading={this.props.loader} dark blur>
                        <ProveedorForm cancelar={this.cerrar} onSubmit={(data) => {
                            this.cerrar();
                            this.props.crearProveedor(data);
                        }} />
                    </LoadMask>
                </Modal>
            </React.Fragment>
        )
    }
}

export default CrearOrdenCompra
