import { connect } from 'react-redux';
import GraficaBalanceGeneral from './GraficaBalanceGeneral';
import { actions } from "../../../../../redux/modules/reportes/balance_general";


const ms2p = (state) => {
    return {
        data_grafica: state.balance_general.data_grafica,
        pais: state.usuario.me.pais,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(GraficaBalanceGeneral);
