import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/devolucion/devolucion";
import ListadoDevoluciones from "./ListadoDevoluciones";


const ms2p = (state) => {
    return {
        ...state.devolucion,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoDevoluciones);
