import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";

import login from "./modules/login";
import { reducer as formReducer } from "redux-form";
import caja from "./modules/caja/caja";
import bodega from "./modules/bodega/bodega";
import gastos from "./modules/gastos/gastos";
import usuario from "./modules/usuario/usuario";
import sucursal from "./modules/sucursal/sucursal";
import producto from "./modules/producto/producto";
import categoria from "./modules/categoria/categoria";
import configuracion from "./modules/configuracion/configuracion";
import destino_salida_producto from "./modules/destino_salida_producto/destino_salida_producto";
import cliente from "./modules/cliente/cliente";
import proveedor from "./modules/proveedor/proveedor";
import venta from "./modules/venta/venta";
import devolucion from "./modules/devolucion/devolucion";
import bitacora from "./modules/bitacora/bitacora";
import usuarios from "./modules/usuarios/usuarios";
import balance_general from "./modules/reportes/balance_general";
import reporte_gastos from "./modules/reportes/gastos";
import mejores_clientes from "./modules/reportes/mejores_clientes";
import apertura_cierre from "./modules/reportes/apertura_cierre";
import reporte_ventas from "./modules/reportes/ventas";
import reporte_bodega from "./modules/reportes/bodega";
import inversion from "./modules/reportes/inversion";
import ganancia_bruta from "./modules/reportes/ganancia_bruta";
import reporte_cuenta_cobrar from "./modules/reportes/cuenta_cobrar";
import reporte_historial_baja_salida from "./modules/reportes/historial_baja_salida";
import dashboard from "./modules/dashboard/dashboard";
import cuenta_cobrar from "./modules/cuenta_cobrar/cuenta_cobrar";
import print from "./modules/print/print";

import reporte_productos_mas_vendidos from "./modules/reportes/productos_mas_vendidos";
import reporte_historial_precios from "./modules/reportes/historial_precios";
import punto_venta from "./modules/punto_venta/punto_venta";
import existencias_sucursal from "./modules/venta/existencias_sucursal";
import despacho from "./modules/despacho/despacho";
import recepcion_despacho from "./modules/despacho/recepcion_despacho";
import reporte_despachos from "./modules/reportes/despachos";
import reporte_movimientos from "./modules/reportes/movimientos";
import reporte_descuento_recargo from "./modules/reportes/descuento_recargo";
import punto_venta_mostrador from "./modules/punto_venta/mostrador";
import punto_venta_caja from "./modules/punto_venta/caja";
import cotizaciones from "./modules/cotizacion/cotizacion";
import ordenes_compra from "./modules/orden_compra/orden_compra";
import recepcion_orden_compra from "./modules/despacho/recepcion_orden_compra";
import ordenes_online from "./modules/orden_online/orden_online";
import adicionales_nota_fel from "./modules/adicionales_nota_fel/adicionales_nota_fel";

export default combineReducers({
    login,
    caja,
    bodega,
    gastos,
    usuario,
    usuarios,
    sucursal,
    producto,
    categoria,
    cliente,
    proveedor,
    venta,
    punto_venta,
    punto_venta_mostrador,
    punto_venta_caja,
    devolucion,
    bitacora,
    balance_general,
    reporte_gastos,
    reporte_productos_mas_vendidos,
    apertura_cierre,
    reporte_ventas,
    reporte_bodega,
    inversion,
    ganancia_bruta,
    reporte_cuenta_cobrar,
    reporte_historial_baja_salida,
    mejores_clientes,
    dashboard,
    configuracion,
    destino_salida_producto,
    routing,
    form: formReducer,
    cuenta_cobrar,
    existencias_sucursal,
    despacho,
    recepcion_despacho,
    reporte_despachos,
    cotizaciones,
    ordenes_compra,
    recepcion_orden_compra,
    reporte_historial_precios,
    print,
    reporte_movimientos,
    ordenes_online,
    adicionales_nota_fel,
    reporte_descuento_recargo,
});
