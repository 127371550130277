import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {activeFormatter} from "Utils/Acciones/Acciones";
import {tableOptions} from "Utils/tableOptions";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import {Link} from 'react-router-dom';

class CajasListadoPaginado extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {
        this.props.listarPaginado(this.props.page);
    }

    render() {
        // state
        const { data_cajas, loader, page, search } = this.props;
        // bind
        const { eliminar, listarPaginado, sortChange, buscar } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = page;
        options.onPageChange = listarPaginado;
        options.onSortChange = sortChange;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt">
                        <Link className="btn btn-primary" to="/configuracion/cajas/crear"><em className="fa fa-plus"></em> Agregar Caja</Link>
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar titulo={"Cajas"} buscar={buscar} search={search} />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data_cajas.results}
                                remote pagination hover
                                fetchInfo={{ dataTotalSize: data_cajas.count }}
                                options={options}
                            >
                                <TableHeaderColumn dataField='nombre' dataSort >Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataField='sucursal__nombre' dataSort dataFormat={(cell, row) => <span>{row.sucursal.nombre}</span>}>Sucursal</TableHeaderColumn>
                                {/* <TableHeaderColumn dataField='telefono'>Teléfono</TableHeaderColumn>
                                <TableHeaderColumn dataField='contacto'>Contacto</TableHeaderColumn> */}
                                <TableHeaderColumn
                                    dataField='id' isKey dataAlign="center" width="20%"
                                    dataFormat={
                                        (cell, row) => {
                                            if (row.sucursal === null)
                                                return "";
                                            return activeFormatter({ editar: '/configuracion/cajas/editar', eliminar })(cell, row)
                                        }
                                    }
                                >Acciones</TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

CajasListadoPaginado.propTypes = {

};

export default CajasListadoPaginado;
