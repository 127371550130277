import React from "react";
import { connect } from "react-redux";
import ListadoDestinos from "./ListadoDestinos";
import { actions } from "../../../../../redux/modules/destino_salida_producto/destino_salida_producto";

const mapStateToProps = (state) => ({
    ...state.destino_salida_producto,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListadoDestinos);
