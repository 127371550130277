import { connect } from 'react-redux';

import GraficaVentas from './GraficaVentas';
import { actions } from "../../../../../redux/modules/reportes/ventas";

const ms2p = (state) => {
    return {
        ...state.reporte_ventas,
        pais: state.usuario.me.pais
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(GraficaVentas);
